import { Col, Row, Spin } from 'antd';
import { HTMLAttributes, useContext, useEffect, useState } from 'react';
import { WaiPieChart } from './pie-chart';
import { upperSnakeToCapitalized } from '../utils/upper-snake-to-capitalized';
import { colors } from '../constants/colors';
import { WaiStackedBarChart } from './stacked-100-bar-chart';
import { WaiHorizontalBarChart } from './horizontal-bar-chart';
import { useMedia } from '../hooks/use-media.hook';
import monitoringService from '../services/monitoring.service';
import { useQuery } from '@tanstack/react-query';
import { NavigationContext } from '../contexts/navigation.context';
import { useTranslation } from 'react-i18next';

export const LoggersDashboardGraphs: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		setSelectedMeasurementPoint?: any;
	}
> = ({ setSelectedMeasurementPoint }) => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { selectedNetwork } = navigationContext;

	const [selectedDevice, setSelectedDevice] = useState<any>();

	const largeScreen = useMedia('xxxxl');

	// load graphs for loggrs
	const { data: graphs, isLoading: loadingGraphs } = useQuery<any>({
		queryKey: ['loggers-dashboard-graphs', selectedNetwork],
		queryFn: () => monitoringService.getDashboardLoggersGraphs(selectedNetwork),
		enabled: !!selectedNetwork
	});
	// get data for selected device logger
	const { data: logger } = useQuery<any>({
		queryKey: ['logger', selectedDevice],
		queryFn: () => selectedDevice && monitoringService.getDevice(selectedDevice),
		enabled: !!selectedDevice && !!selectedNetwork
	});
	// get MP of the selected device logger
	useEffect(() => {
		setSelectedMeasurementPoint(logger?.measurement_point);
	}, [logger]);
	return (
		<Spin spinning={loadingGraphs}>
			{graphs ? (
				<>
					<Row gutter={[16, 16]} className={'mb-3'} justify="space-evenly">
						<Col xs={24} md={12} xxl={largeScreen ? 4 : 12}>
							<WaiPieChart
								title={graphs.active_devices_graph.title}
								data={Object.keys(graphs.active_devices_graph.data).map(key => {
									return {
										name: upperSnakeToCapitalized(key),
										value: graphs.active_devices_graph.data[key]
									};
								})}
								key={graphs.active_devices_graph}
								height={10}
								graphHeight={120}
								fieldName={graphs.active_devices_graph.title}
								colorPie={[colors.HEALTHY, colors.INACTIVE]}
								hint={graphs.active_devices_graph.hint}
							/>
						</Col>
						<Col xs={24} md={12} xxl={largeScreen ? 4 : 12}>
							<WaiPieChart
								title={graphs.vendor_graph.title}
								data={Object.keys(graphs.vendor_graph?.data).map(key => {
									return {
										name: upperSnakeToCapitalized(key),
										value: graphs.vendor_graph?.data[key]
									};
								})}
								key={graphs.vendor_graph}
								height={10}
								graphHeight={120}
								fieldName={graphs.vendor_graph.title}
								hint={graphs.vendor_graph.hint}
							/>
						</Col>
						<Col xs={24} md={12} xxl={largeScreen ? 4 : 12}>
							<WaiPieChart
								title={graphs.recently_logged_graph.title}
								data={Object.keys(graphs.recently_logged_graph.data).map(key => {
									return {
										name: upperSnakeToCapitalized(key),
										value: graphs.recently_logged_graph.data[key]
									};
								})}
								key={graphs.recently_logged_graph}
								height={10}
								graphHeight={120}
								fieldName={graphs.recently_logged_graph.title}
								colorPie={[colors.HEALTHY, colors.WARNING]}
								hint={graphs.recently_logged_graph.hint}
							/>
						</Col>
						<Col xs={24} md={12} xxl={largeScreen ? 4 : 12}>
							<WaiPieChart
								title={graphs.signal_analysis_graph.title}
								data={Object.keys(graphs.signal_analysis_graph?.data).map(key => {
									return {
										name: upperSnakeToCapitalized(key),
										value: graphs.signal_analysis_graph?.data[key]
									};
								})}
								key={graphs.signal_analysis_graph}
								height={10}
								graphHeight={120}
								fieldName={graphs.signal_analysis_graph.title}
								colorPie={[colors.INACTIVE, colors.ERROR, colors.WARNING, colors.HEALTHY]}
								hint={graphs.signal_analysis_graph.hint}
							/>
						</Col>
						<Col xs={24} md={12} xxl={largeScreen ? 4 : 12}>
							<WaiPieChart
								title={graphs.battery_analysis_graph.title}
								data={Object.keys(graphs.battery_analysis_graph.data).map(key => {
									return {
										name: upperSnakeToCapitalized(key),
										value: graphs.battery_analysis_graph.data[key]
									};
								})}
								key={graphs.battery_analysis_graph}
								height={10}
								graphHeight={120}
								fieldName={graphs.battery_analysis_graph.title}
								colorPie={[colors.INACTIVE, colors.ERROR, colors.WARNING, colors.HEALTHY]}
								hint={graphs.battery_analysis_graph.hint}
							/>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col xs={24} lg={12} xl={24} xxl={12}>
							<WaiStackedBarChart
								title={graphs.low_battery_graph.title}
								data={{
									series: [
										{
											name: t('Charged'),
											data: graphs.low_battery_graph.data.map(ele => ele.battery_rate)
										},
										{
											name: t('Discharged'),
											data: graphs.low_battery_graph.data.map(ele => 100 - ele.battery_rate)
										}
									],
									categories: graphs.low_battery_graph.data.map(ele => ele.device.device_id),
									tooltip: graphs.low_battery_graph.data.map(ele => ele.battery.hint),
									devices: graphs.low_battery_graph.data.map(ele => ele.device.uuid)
								}}
								key={graphs.low_battery_graph}
								height={21}
								graphHeight={300}
								fieldName={graphs.low_battery_graph.title}
								colorsBar={[colors.ERROR, colors.LIGHT_GREY]}
								hint={graphs.low_battery_graph.hint}
								setSelectedDevice={setSelectedDevice}
								noDataText={t('NoLowBatteryDevices')}
							/>
						</Col>
						<Col xs={24} lg={12} xl={24} xxl={12}>
							<WaiHorizontalBarChart
								title={graphs.bad_signal_graph.title}
								data={{
									series: graphs.bad_signal_graph.data.map(ele => ele.signal_rate),
									categories: graphs.bad_signal_graph.data.map(ele => ele.device.device_id),
									tooltip: graphs.bad_signal_graph.data.map(ele => ele.signal.hint),
									devices: graphs.bad_signal_graph.data.map(ele => ele.device.uuid)
								}}
								key={graphs.bad_signal_graph}
								height={21}
								graphHeight={300}
								fieldName={graphs.bad_signal_graph.title}
								colorsBar={[colors.ERROR, colors.LIGHT_GREY]}
								hint={graphs.bad_signal_graph.hint}
								setSelectedDevice={setSelectedDevice}
								noDataText={t('NoLowSignalDevices')}
							/>
						</Col>
					</Row>
				</>
			) : null}
		</Spin>
	);
};
