import styled from 'styled-components';
import { ScreenSize } from '../../../constants/screen-size';

export const WaiLogoText = styled.h4<{ size?: 'small' | 'large'; label: any }>`
	color:  ${({ label }) => label.logoTextColor};
	font-family: niva-bold;
	font-size: ${({ size }) => (size === 'small' ? '1rem' : '2rem')}
	margin-bottom: 0;
	line-height: 0.9;
	padding:0;
	margin:0;
	@media (max-width: ${ScreenSize.XXL}) {
		font-size: ${({ size }) => (size === 'small' ? '1.3rem' : '1.5rem')}
	}
`;

export const WaiLogoSubText = styled.p<{ size?: 'small' | 'large'; label: any }>`
	color: ${({ label }) => label.logoSubTextColor};
	margin-inline: 4px;
	padding-bottom: 3px;
	margin: 0;
	font-size: ${({ size }) => (size === 'small' ? '0.8em' : '1rem')};
	@media (max-width: ${ScreenSize.XXL}) {
		font-size: ${({ size }) => (size === 'small' ? '0.6rem' : '0.8rem')};
	}
`;

export const WaiLogoImg = styled.img<{ direction?: 'horizontal' | 'vertical'; size?: 'small' | 'large'; label: any }>`
	color:  ${({ label }) => label.logoImgColor};
	width: 55px;
	display: inline-block;
	margin-bottom:${({ direction }) => (direction === 'horizontal' ? '0px' : '10px')};
	margin-right: 10px;
	import styled from 'styled-components';
	transition: all 0.4s;
	@media (max-width: ${ScreenSize.XXL}) {
		width: 30px;
	}

`;
