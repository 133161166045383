import { Card, Col, Empty, Row, Spin } from 'antd';
import { HTMLAttributes, useEffect, useState } from 'react';
import { useMedia } from '../hooks/use-media.hook';
import { useTranslation } from 'react-i18next';
import { WaiNumericalChart } from './numerical-chart';
import { Disabled, GraphCard } from '../components';
import { SpanType } from '../types';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { colors } from '../constants/colors';
import dayjs from 'dayjs';
import { ChartTable } from '../components/chart-table/chart-table';

export const WaterProductionReadingsCards: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		water_production_cards: any[];
		lastReadingsDate: any;
		OnDateSelect: (date?: any) => void;
		spanTpye: SpanType;
		title?: string;
		graphHeight: number;
		showTableChart?: boolean;
		deactivated?: boolean;
		deactivatedMsg?: string;
		linkToActivate?: string;
		accessToActivateChart?: boolean;
	}
> = ({
	water_production_cards,
	lastReadingsDate,
	OnDateSelect,
	spanTpye,
	title,
	graphHeight,
	showTableChart,
	deactivated,
	deactivatedMsg,
	linkToActivate,
	accessToActivateChart,
	...restProps
}) => {
	const { t } = useTranslation();
	const largeScreen = useMedia('xxxxl');

	const [displayDate, setDisplayDate] = useState<any>();
	const [chartView, setChartView] = useState<any>('table');
	const [dataObjects, setDataObjects] = useState<any[]>();

	// set max. numbers of cards for each page
	const [pagination, setPagination] = useState<any>({
		min: 0,
		max: 9
	});

	// check if large screen then add more cards
	useEffect(() => {
		if (largeScreen) {
			setPagination({ min: 0, max: 12 });
		}
	}, [largeScreen]);

	useEffect(() => {
		if (water_production_cards.length > 0) {
			setDataObjects(
				water_production_cards.map((card, idx: number) => {
					const valueKey = card.current ? 'current_value' : 'value';
					return {
						name: card.name,
						time: displayDate ?? lastReadingsDate,
						[valueKey]: card.value ? `${parseFloat(card.value).toFixed(2)} ${card.unit}` : undefined
					};
				})
			);
		}
	}, [water_production_cards]);

	return (
		<Spin spinning={false}>
			<Row gutter={[16, 16]}>
				<Col xs={24}>
					<GraphCard
						height={48}
						title={title ?? t('WaterProductionReadingsCards')}
						lastReadingsDates={{
							day: lastReadingsDate
						}}
						setDisplayDate={setDisplayDate}
						displayDate={displayDate}
						OnDateSelect={OnDateSelect}
						spanType={SpanType.DAY}
						titleBold={true}
						showCustomSelectorDate
						showTableChart={showTableChart}
						chartView={chartView}
						setChartView={setChartView}
						deactivated={deactivated}
						deactivatedMsg={deactivatedMsg}
						linkToActivate={linkToActivate}
						accessToActivateChart={accessToActivateChart}
						{...restProps}
					>
						<div
							className="d-flex justify-content-between align-items-center mt-2 mb-2"
							style={{ height: '100%' }}
							dir="ltr"
						>
							<div>
								<AiOutlineLeft
									size={'2rem'}
									style={{ strokeWidth: '30px' }}
									color={colors.GRAPH_PURPLE}
									className={'mb-4'}
									role={'button'}
									onClick={() => {
										OnDateSelect &&
											OnDateSelect({
												start: displayDate
													? dayjs(displayDate)
															.subtract(1, spanTpye)
															.startOf(spanTpye)
															.format('YYYY-MM-DD')
													: dayjs(lastReadingsDate)
															.subtract(1, spanTpye)
															.startOf(spanTpye)
															.format('YYYY-MM-DD')
											});
										setDisplayDate(
											displayDate
												? dayjs(displayDate).subtract(1, spanTpye).format('YYYY-MM-DD')
												: dayjs(lastReadingsDate).subtract(1, spanTpye).format('YYYY-MM-DD')
										);
									}}
								/>
							</div>
							<div
								style={{
									overflowY: 'auto',
									maxHeight: '45rem',
									overflowX: 'hidden',
									width: '100%',
									height: '100%'
								}}
							>
								{chartView === 'table' ? (
									<ChartTable
										style={{ height: graphHeight }}
										dataObjects={dataObjects}
										horizontal={true}
									/>
								) : (
									<Row gutter={[16, 16]}>
										{water_production_cards
											?.slice(pagination?.min, pagination?.max)
											.map((card, idx: number) => {
												return (
													<Col
														xs={24}
														sm={12}
														lg={8}
														xl={12}
														xxl={largeScreen ? 6 : 8}
														key={idx}
													>
														<Card
															style={{ height: '100%' }}
															className="shadow-sm bg-body rounded"
															bodyStyle={{ padding: '5px' }}
														>
															<WaiNumericalChart
																number={
																	card?.value ? (
																		card.value > 9999 ? (
																			parseFloat(card.value).toFixed(0)
																		) : card.value > 999 ? (
																			parseFloat(card.value).toFixed(1)
																		) : (
																			parseFloat(card.value).toFixed(2)
																		)
																	) : (
																		<Empty description={t('NoAvailableData')} />
																	)
																}
																title={card?.name}
																unit={(card?.value && card?.unit) || undefined}
																maxWidth="100%"
																empty={card?.value ? false : true}
																dateText={
																	card?.current ? t('CurrentReading') : undefined
																}
															/>
														</Card>
													</Col>
												);
											})}
									</Row>
								)}
							</div>
							<div>
								{(displayDate
									? dayjs(displayDate).endOf(spanTpye)
									: dayjs(lastReadingsDate).endOf(spanTpye)) >= dayjs().endOf(spanTpye) ? (
									<Disabled tooltipTitle={t('CannotChooseFutureDate', { ns: 'validation' })}>
										<AiOutlineRight
											size={'2rem'}
											style={{ strokeWidth: '30px' }}
											color={colors.GRAPH_PURPLE}
											className={'mb-4'}
											role={'button'}
											onClick={() => {
												OnDateSelect &&
													OnDateSelect({
														start: displayDate
															? dayjs(displayDate)
																	.add(1, spanTpye)
																	.startOf(spanTpye)
																	.format('YYYY-MM-DD')
															: dayjs(lastReadingsDate)
																	.add(1, spanTpye)
																	.startOf(spanTpye)
																	.format('YYYY-MM-DD')
													});
												setDisplayDate(
													displayDate
														? dayjs(displayDate).add(1, spanTpye).format('YYYY-MM-DD')
														: dayjs(lastReadingsDate).add(1, spanTpye).format('YYYY-MM-DD')
												);
											}}
										/>
									</Disabled>
								) : (
									<AiOutlineRight
										size={'2rem'}
										style={{ strokeWidth: '30px' }}
										color={colors.GRAPH_PURPLE}
										className={'mb-4'}
										role={'button'}
										onClick={() => {
											OnDateSelect &&
												OnDateSelect({
													start: displayDate
														? dayjs(displayDate)
																.add(1, spanTpye)
																.startOf(spanTpye)
																.format('YYYY-MM-DD')
														: dayjs(lastReadingsDate)
																.add(1, spanTpye)
																.startOf(spanTpye)
																.format('YYYY-MM-DD')
												});
											setDisplayDate(
												displayDate
													? dayjs(displayDate).add(1, spanTpye).format('YYYY-MM-DD')
													: dayjs(lastReadingsDate).add(1, spanTpye).format('YYYY-MM-DD')
											);
										}}
									/>
								)}
							</div>
						</div>
					</GraphCard>
				</Col>
			</Row>
		</Spin>
	);
};
