import { Spin, Table, Tag } from 'antd';
import { HTMLAttributes, useContext, useEffect, useState } from 'react';
import { NavigationContext } from '../contexts/navigation.context';
import { upperSnakeToCapitalized } from '../utils/upper-snake-to-capitalized';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import monitoringService from '../services/monitoring.service';
import { formatFilters } from '../utils/get-table-filters';
import { formatSorter } from '../utils/get-table-sorter';
import { useTranslation } from 'react-i18next';

export const LoggersDashboardTable: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		setSelectedMeasurementPoint?: any;
	}
> = ({ setSelectedMeasurementPoint }) => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { selectedNetwork } = navigationContext;

	const [tableSorters, setTableSorters] = useState<any>({});
	const [tableFilters, setTableFilters] = useState<any>({});
	const [selectedDevice, setSelectedDevice] = useState<any>([]);

	// load dashboard loggers table
	const {
		data: loggersTable,
		isLoading: loadingTable,
		refetch: refetchTable
	} = useQuery<any>({
		queryKey: ['loggers-dashboard-tables', tableSorters, tableFilters, selectedNetwork],
		queryFn: () =>
			monitoringService.getDashboardLoggersTable(selectedNetwork, {
				sorters: { ...tableSorters },
				filters: { ...tableFilters }
			}),
		enabled: !!selectedNetwork
	});
	// get data for selected device logger
	const { data: logger } = useQuery<any>({
		queryKey: ['logger', selectedDevice[0]],
		queryFn: () => monitoringService.getDevice(selectedDevice[0]),
		enabled: selectedDevice.length > 0
	});
	// get MP for the selected device logger
	useEffect(() => {
		setSelectedMeasurementPoint(logger?.measurement_point);
	}, [logger]);
	// get vendors for the loggers table
	const vendors =
		loggersTable &&
		loggersTable.reduce((acc, deivce) => {
			if (!acc.includes(deivce.vendor)) {
				acc.push(deivce.vendor);
			}
			return acc;
		}, []);

	// filter loggers table with: vendor, is_active
	const onMeasurementPointsFilter = async (filters: any) => {
		if (JSON.stringify(filters) === JSON.stringify(tableFilters)) return;
		{
			await setTableFilters(filters);
			await refetchTable();
		}
	};
	// sort loggers table with: clock_drift, signal_rate, battery_rate, last_online, last_reading
	const onMeasurementPointsSort = async (sorters: any) => {
		if (JSON.stringify(sorters) === JSON.stringify(tableSorters)) return;
		{
			await setTableSorters(sorters);
			await refetchTable();
		}
	};
	// handle sorting and filtering
	const handleTableChange = (newPagination: any, filters: any, sorters: any) => {
		onMeasurementPointsFilter && onMeasurementPointsFilter(formatFilters(filters));
		onMeasurementPointsSort && onMeasurementPointsSort(formatSorter(sorters));
	};

	const tableColumns: any = [
		{
			title: t('Device'),
			dataIndex: 'device_id',
			key: 'device_id',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Vendor'),
			dataIndex: 'vendor',
			key: 'vendor',
			align: 'center',
			filters:
				vendors &&
				vendors?.length &&
				vendors.map(vendor => ({
					text: upperSnakeToCapitalized(vendor),
					value: vendor
				})),
			filterMultiple: false,
			render: (text: string) => upperSnakeToCapitalized(text)
		},
		{
			title: t('Active'),
			dataIndex: 'is_active',
			key: 'is_active',
			align: 'center',
			filters: [
				{ text: t('Active'), value: true },
				{ text: t('NotActive'), value: false }
			],
			filterMultiple: false,
			render: (check: boolean) =>
				check ? <AiFillCheckCircle className="text-success" /> : <AiFillCloseCircle className="text-danger" />
		},
		{
			title: t('clockDrift'),
			dataIndex: 'clock_drift',
			key: 'clock_drift',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: number) => (text === null ? '-' : text)
		},
		{
			title: t('signalRate'),
			dataIndex: 'signal_rate',
			key: 'signal_rate',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: number) => (text === null ? '-' : text)
		},

		{
			title: t('batteryRate'),
			dataIndex: 'battery_rate',
			key: 'battery_rate',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: number) => (text === null ? '-' : text)
		},
		{
			title: t('lastOnline'),
			dataIndex: 'last_online',
			key: 'last_online',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: Date) => (text ? dayjs(text).format('dddd, MMMM D, YYYY h:mm A') : '-')
		},
		{
			title: t('lastReading'),
			dataIndex: 'last_reading',
			key: 'last_reading',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: Date) => (text ? dayjs(text).format('dddd, MMMM D, YYYY h:mm A') : '-')
		},
		{
			title: t('Alerts'),
			dataIndex: 'alerts',
			key: 'alerts',
			align: 'center',
			render: (tags: any, id: number) => {
				let color: string = 'red-inverse';
				const generatedTags = Object.entries(tags).map(([key, value], idx) => {
					if (value) {
						if (key === t('major_outage')) {
							color = 'magenta';
						} else if (key === t('low_battery')) {
							color = 'orange';
						} else if (key === t('clock_drift')) {
							color = 'yellow';
						} else if (key === t('bad_signal')) {
							color = 'error';
						}
						return (
							<Tag color={color} key={idx} className={'m-1'}>
								{upperSnakeToCapitalized(key)}
							</Tag>
						);
					}
				});
				return !generatedTags.every(item => item === undefined) ? generatedTags : '-';
			}
		}
	];
	return (
		<Spin spinning={loadingTable}>
			<Table
				tableLayout="auto"
				scroll={{ x: true }}
				columns={tableColumns}
				dataSource={loggersTable}
				size="small"
				showSorterTooltip
				rowKey="id"
				onChange={handleTableChange}
				onRow={(record: any, idx) => {
					return {
						onMouseOver: e => {
							setSelectedDevice(record?.id);
						}
					};
				}}
			/>
		</Spin>
	);
};
