import configProxy from '../config/config';
import { IFoundationDto, IUserDto, IUserPermissionDto } from '../dtos';
import ResourceManager from '../network/resource-manager';
import { Identifiable } from '../types';

class UserService extends ResourceManager {
	async createFoundation(data: IFoundationDto) {
		return await this.postResource<IFoundationDto, Identifiable<IFoundationDto>>(
			configProxy.URIs.foundation_management_uri,
			data
		);
	}
	async getFoundations(): Promise<Identifiable<IFoundationDto>[] | null> {
		return await this.getResource(configProxy.URIs.foundation_management_uri);
	}
	async getFoundation(foundationId: string): Promise<any> {
		return await this.getResource(configProxy.URIs.foundation_management_uri + `/${foundationId}/`);
	}
	async editFoundation(foundationId: string, data: IFoundationDto): Promise<Identifiable<IFoundationDto>[] | null> {
		return await this.patchResource(configProxy.URIs.foundation_management_uri + `/${foundationId}/`, data);
	}
	async deleteFoundation(foundationId: string): Promise<any> {
		return await this.deleteResource(configProxy.URIs.foundation_management_uri + `/${foundationId}/`);
	}
	async createUser(data: IUserDto) {
		return await this.postResource<IUserDto, Identifiable<IUserDto>>(configProxy.URIs.user_management_uri, data);
	}
	async getUsers(): Promise<Identifiable<IUserDto>[] | null> {
		return await this.getResource(configProxy.URIs.user_management_uri);
	}
	async getUser(userId: string): Promise<any> {
		return await this.getResource(configProxy.URIs.user_management_uri + `/${userId}/`);
	}
	async editUser(userId: string, data: IUserDto): Promise<Identifiable<IUserDto>[] | null> {
		return await this.patchResource(configProxy.URIs.user_management_uri + `/${userId}/`, data);
	}
	async deleteUser(userId: string): Promise<any> {
		return await this.deleteResource(configProxy.URIs.user_management_uri + `/${userId}/`);
	}
	async getPermissions(params?: any, options?: any): Promise<Identifiable<IUserPermissionDto>[] | null> {
		return await this.getResource(configProxy.URIs.user_permissions_uri, {
			...(options || {}),
			params
		});
	}
	async getUserPermission(userPermission: string): Promise<Identifiable<IUserPermissionDto>[] | null> {
		return await this.getResource(configProxy.URIs.user_permission_uri.replace(/:userPermission/g, userPermission));
	}
	async createUserPermission(data: IUserPermissionDto) {
		return await this.postResource<IUserPermissionDto, Identifiable<IUserPermissionDto>>(
			configProxy.URIs.user_permissions_uri,
			data
		);
	}
	async editUserPermission(
		userPermission: string,
		data: IUserPermissionDto
	): Promise<Identifiable<IUserPermissionDto>[] | null> {
		return await this.putResource(
			configProxy.URIs.user_permission_uri.replace(/:userPermission/g, userPermission),
			data
		);
	}
	async deleteUserPermission(userPermission: string): Promise<Identifiable<IUserPermissionDto>[] | null> {
		return await this.deleteResource(
			configProxy.URIs.user_permission_uri.replace(/:userPermission/g, userPermission)
		);
	}
	async getApplications(): Promise<Identifiable<any>[] | null> {
		return await this.getResource(configProxy.URIs.applications_management_uri);
	}
	async createApplication(data: any) {
		return await this.postResource<any, any>(configProxy.URIs.applications_management_uri, data);
	}
	async getApplication(applicationId: string): Promise<any> {
		return await this.getResource(
			configProxy.URIs.application_management_uri.replace(/:applicationId/g, applicationId)
		);
	}
	async editApplication(applicationId: string, data: any): Promise<any> {
		return await this.patchResource(
			configProxy.URIs.application_management_uri.replace(/:applicationId/g, applicationId),
			data
		);
	}
	async deleteApplication(applicationId: string): Promise<any> {
		return await this.deleteResource(
			configProxy.URIs.application_management_uri.replace(/:applicationId/g, applicationId)
		);
	}
	async getRights(): Promise<Identifiable<any>[] | null> {
		return await this.getResource(configProxy.URIs.rights_management_uri);
	}
	async getSubsystems(): Promise<Identifiable<any>[] | null> {
		return await this.getResource(configProxy.URIs.subsystems_management_uri);
	}
	async createSubsystem(data: any) {
		return await this.postResource<any, any>(configProxy.URIs.subsystems_management_uri, data);
	}
	async getSubsystem(subsystemId: string): Promise<any> {
		return await this.getResource(configProxy.URIs.subsystem_management_uri.replace(/:subsystemId/g, subsystemId));
	}
	async editSubsystem(subsystemId: string, data: any): Promise<any> {
		return await this.patchResource(
			configProxy.URIs.subsystem_management_uri.replace(/:subsystemId/g, subsystemId),
			data
		);
	}
	async deleteSubsystem(subsystemId: string): Promise<any> {
		return await this.deleteResource(
			configProxy.URIs.subsystem_management_uri.replace(/:subsystemId/g, subsystemId)
		);
	}
}

export default new UserService();
