import configProxy from '../config/config';
import ResourceManager from '../network/resource-manager';

class ConfigService extends ResourceManager {
	async getAvgZonePressureConfig(zoneId: string) {
		const url = configProxy.URIs.avg_zone_pressure_config_uri.replace(/:zoneId/g, zoneId);
		return await this.getResource<any>(url);
	}
	async editAvgZonePressureConfig(zoneId: string, data: any) {
		const url = configProxy.URIs.avg_zone_pressure_config_uri.replace(/:zoneId/g, zoneId);
		return await this.putResource(url, data);
	}
	async createAvgZoneConfigProfile(zoneId: string, data: any) {
		const url = configProxy.URIs.avg_zone_pressure_config_uri.replace(/:zoneId/g, zoneId);
		return await this.postResource(url, data);
	}
	async editAvgZoneConfigProfile(zoneId: string, profileId: string, data: any) {
		const url = configProxy.URIs.avg_zone_pressure_config_uri.replace(/:zoneId/g, zoneId) + `${profileId}/`;
		return await this.putResource(url, data);
	}
	async deleteAvgZoneConfigProfile(zoneId: string, profileId: string) {
		const url = configProxy.URIs.avg_zone_pressure_config_uri.replace(/:zoneId/g, zoneId) + `${profileId}/`;
		return await this.deleteResource(url);
	}
	async getZoneFlowAnalysisConfig(zoneId: string) {
		const url = configProxy.URIs.zone_flowrate_config_uri.replace(/:zoneId/g, zoneId);
		return await this.getResource<any>(url);
	}
	async editZoneFlowAnalysisConfig(zoneId: string, data: any) {
		const url = configProxy.URIs.zone_flowrate_config_uri.replace(/:zoneId/g, zoneId);
		return await this.putResource(url, data);
	}
	async getZoneMinNinghtlyFlowConfig(zoneId: string) {
		const url = configProxy.URIs.zone_min_nightly_flow_config_uri.replace(/:zoneId/g, zoneId);
		return await this.getResource<any>(url);
	}
	async editZoneMinNinghtlyFlowConfig(zoneId: string, data: any) {
		const url = configProxy.URIs.zone_min_nightly_flow_config_uri.replace(/:zoneId/g, zoneId);
		return await this.putResource(url, data);
	}
	async getZoneWaterConsumptionConfig(zoneId: string) {
		const url = configProxy.URIs.zone_water_consumption_config_uri.replace(/:zoneId/g, zoneId);
		return await this.getResource<any>(url);
	}
	async editZoneWaterConsumptionConfig(zoneId: string, data: any) {
		const url = configProxy.URIs.zone_water_consumption_config_uri.replace(/:zoneId/g, zoneId);
		return await this.putResource(url, data);
	}
	async getZoneLeakageConfig(zoneId: string) {
		const url = configProxy.URIs.zone_leakage_config.replace(/:zoneId/g, zoneId);
		return await this.getResource<any>(url);
	}
	async editZoneLeakageConfig(zoneId: string, data: any) {
		const url = configProxy.URIs.zone_leakage_config.replace(/:zoneId/g, zoneId);
		return await this.putResource(url, data);
	}
	async getZoneLeakageParameters(zoneId: string) {
		const url = configProxy.URIs.zone_leakage_parameters.replace(/:zoneId/g, zoneId);
		return await this.getResource<any>(url);
	}
	async editZoneLeakageParameters(zoneId: string, data: any) {
		const url = configProxy.URIs.zone_leakage_parameters.replace(/:zoneId/g, zoneId);
		return await this.putResource(url, data);
	}
	async getSiteFlowAnalysisConfig(siteId: string) {
		const url = configProxy.URIs.site_flowrate_config_uri.replace(/:siteId/g, siteId);
		return await this.getResource<any>(url);
	}
	async editSiteFlowAnalysisConfig(siteId: string, data: any) {
		const url = configProxy.URIs.site_flowrate_config_uri.replace(/:siteId/g, siteId);
		return await this.putResource(url, data);
	}
}

export default new ConfigService();
