import ResourceManager from '../network/resource-manager';
import configProxy from '../config/config';
import { IDataSource } from '../types';

class MonitoringService extends ResourceManager {
	async getNetworks(params?: any, options?: any) {
		return await this.getResource<any>(configProxy.URIs.networks_uri, {
			...(options || {}),
			params
		});
	}
	async createNetwork(data: any) {
		return await this.postResource<any, any>(configProxy.URIs.networks_uri, data);
	}
	async editNetwork(networkId: string, data: any) {
		return await this.patchResource<any, any>(configProxy.URIs.network_uri.replace(/:networkId/g, networkId), data);
	}
	async deleteNetwork(networkId: string) {
		return await this.deleteResource<any>(configProxy.URIs.network_uri.replace(/:networkId/g, networkId));
	}
	async getNetwork(networkId: string) {
		const url = configProxy.URIs.network_uri.replace(/:networkId/g, networkId);
		return await this.getResource<any>(url);
	}
	async getNetworkGisLayers(networkId: string) {
		const url = configProxy.URIs.network_gis_layers_uri.replace(/:networkId/g, networkId);
		return await this.getResource<any>(url);
	}
	async getNetworkGisLayer(networkId: string, gisLayerId: string) {
		const url = configProxy.URIs.network_gis_layers_uri
			.replace(/:networkId/g, networkId)
			.replace(/:gisLayerId/g, gisLayerId);
		return await this.getResource<any>(url);
	}
	async getZonesAll(params?: any, options?: any) {
		return await this.getResource<any>(configProxy.URIs.zones_uri_new, {
			...(options || {}),
			params
		});
	}
	async getZonesWithCommercialSites(params?: any, options?: any) {
		return await this.getResource<any>(configProxy.URIs.zones_with_commercial_sites, {
			...(options || {}),
			params
		});
	}
	async getZones(networkId: string) {
		const url = configProxy.URIs.zone_uri_new.replace(/:networkId/g, networkId);
		return await this.getResource<any>(url);
	}
	async getZone(zoneId: string) {
		const url = configProxy.URIs.zone_uri_new.replace(/:zoneId/g, zoneId);
		return await this.getResource<any>(url);
	}
	async createZone(data: any) {
		return await this.postResource<any, any>(configProxy.URIs.zones_uri_new, data);
	}
	async editZone(zoneId: string, data: any) {
		return await this.patchResource<any, any>(configProxy.URIs.zone_uri_new.replace(/:zoneId/g, zoneId), data);
	}
	async deleteZone(zoneId: string) {
		return await this.deleteResource<any>(configProxy.URIs.zone_uri_new.replace(/:zoneId/g, zoneId));
	}
	async addZoneFiles(zoneId: string, data: any) {
		return await this.postResource<any, any>(configProxy.URIs.zone_files_uri.replace(/:zoneId/g, zoneId), data);
	}
	async getZoneFiles(zoneId: string) {
		return await this.getResource<any>(configProxy.URIs.zone_files_uri.replace(/:zoneId/g, zoneId));
	}
	async getZoneGisLayers(zoneId: string) {
		const url = configProxy.URIs.zone_gis_layers_uri.replace(/:zoneId/g, zoneId);
		return await this.getResource<any>(url);
	}
	async getZoneGisLayer(zoneId: string, gisLayerId: string) {
		const url = configProxy.URIs.zone_gis_layers_uri
			.replace(/:zoneId/g, zoneId)
			.replace(/:gisLayerId/g, gisLayerId);
		return await this.getResource<any>(url);
	}
	async getCommercialMeterGroups(params?: any, options?: any) {
		return await this.getResource<any>(configProxy.URIs.commercial_meter_groups, {
			...(options || {}),
			params
		});
	}
	async getCommercialMeterGroup(commercialGroupId: string) {
		const url = configProxy.URIs.commercial_meter_group.replace(/:commercialGroupId/g, commercialGroupId);
		return await this.getResource<any>(url);
	}
	async editCommercialMeterGroupInfo(commercialGroupId: string, data: any) {
		const url = configProxy.URIs.commercial_meter_group.replace(/:commercialGroupId/g, commercialGroupId);
		return await this.patchResource<any, any>(url, data);
	}
	async deleteCommercialMeterGroup(commercialGroupId: string) {
		const url = configProxy.URIs.commercial_meter_group.replace(/:commercialGroupId/g, commercialGroupId);
		return await this.deleteResource<any>(url);
	}
	async addCommercialMeterGroupDataConfirm(networkId: string, data: any) {
		return await this.postResource<any, any>(
			configProxy.URIs.network_commercial_meter_data_confirmation.replace(/:networkId/g, networkId),
			data
		);
	}
	async addCommercialMeterGroupData(networkId: string, data: any) {
		return await this.postResource<any, any>(
			configProxy.URIs.network_commercial_meter_data.replace(/:networkId/g, networkId),
			data
		);
	}
	async editCommercialMeterGroupDataConfirm(commercialGroupId: string, data: any) {
		return await this.postResource<any, any>(
			configProxy.URIs.commercial_meter_data_confirmation.replace(/:commercialGroupId/g, commercialGroupId),
			data
		);
	}
	async editCommercialMeterGroupData(commercialGroupId: string, data: any) {
		return await this.putResource<any, any>(
			configProxy.URIs.commercial_meter_data.replace(/:commercialGroupId/g, commercialGroupId),
			data
		);
	}
	async getMeasurementPointsAll(params?: any, options?: any) {
		return await this.getResource<any>(configProxy.URIs.get_measurement_points_new, {
			...(options || {}),
			params
		});
	}
	async getMeasurementPointNew(measurementPointId: string) {
		const url = configProxy.URIs.get_measurement_point_new.replace(/:measurementPointId/g, measurementPointId);
		return await this.getResource<any>(url);
	}
	async createMeasurementPoint(data: any) {
		return await this.postResource<any, any>(configProxy.URIs.get_measurement_points_new, data);
	}

	async editMeasurementPoint(measurementPointId: string, data: any) {
		return await this.patchResource<any, any>(
			configProxy.URIs.get_measurement_point_new.replace(/:measurementPointId/g, measurementPointId),
			data
		);
	}
	async deleteMeasurementPoint(measurementPointId: string) {
		return await this.deleteResource<any>(
			configProxy.URIs.get_measurement_point_new.replace(/:measurementPointId/g, measurementPointId)
		);
	}
	async getMeasurementPoints(networkId: string, zoneId: string) {
		const url = configProxy.URIs.get_measurement_points
			.replace(/:networkId/g, networkId)
			.replace(/:zoneId/g, zoneId);
		return await this.getResource<any>(url);
	}
	async getMeasurementPoint(measurementPointId: string) {
		const url = configProxy.URIs.get_measurement_point.replace(/:measurementPointId/g, measurementPointId);
		return await this.getResource<any>(url);
	}
	async getMeasurementPointLatestReadings(measurementPointId: string) {
		const url = configProxy.URIs.get_measurement_point_latest_readings.replace(
			/:measurementPointId/g,
			measurementPointId
		);
		return await this.getResource<any>(url);
	}
	async getMeasurementPointImages(measurementPointId: string) {
		return await this.getResource<any>(
			configProxy.URIs.measurement_point_images_uri.replace(/:measurementPointId/g, measurementPointId)
		);
	}
	async addMeasurementPointImages(measurementPointId: string, data: any) {
		return await this.postResource<any, any>(
			configProxy.URIs.measurement_point_images_uri.replace(/:measurementPointId/g, measurementPointId),
			data
		);
	}
	async getMeasurementPointFiles(measurementPointId: string) {
		return await this.getResource<any>(
			configProxy.URIs.measurement_point_files_uri.replace(/:measurementPointId/g, measurementPointId)
		);
	}
	async addMeasurementPointFiles(measurementPointId: string, data: any) {
		return await this.postResource<any, any>(
			configProxy.URIs.measurement_point_files_uri.replace(/:measurementPointId/g, measurementPointId),
			data
		);
	}
	async getCommercialSites(params?: any, options?: any) {
		return await this.getResource<any>(configProxy.URIs.commercial_sites, {
			...(options || {}),
			params
		});
	}
	async getCommercialSite(siteId: string) {
		const url = configProxy.URIs.commercial_site.replace(/:siteId/g, siteId);
		return await this.getResource<any>(url);
	}
	async editCommercialSite(siteId: string, data) {
		const url = configProxy.URIs.commercial_site.replace(/:siteId/g, siteId);
		return await this.patchResource<any, any>(url, data);
	}
	async deleteCommercialSite(siteId: string) {
		const url = configProxy.URIs.commercial_site.replace(/:siteId/g, siteId);
		return await this.deleteResource<any>(url);
	}
	async getDataSources(params?: any, options?: any) {
		return await this.getResource<any>(configProxy.URIs.get_data_sources, {
			...(options || {}),
			params
		});
	}
	async getDataSource(dataSourceId: string) {
		const url = configProxy.URIs.get_data_source.replace(/:dataSourceId/g, dataSourceId);
		return await this.getResource<any>(url);
	}
	async createDataSource(data: any) {
		return await this.postResource<any, any>(configProxy.URIs.get_data_sources, data);
	}
	async editDataSource(dataSourceId: string, data: any) {
		return await this.patchResource<any, any>(
			configProxy.URIs.get_data_source.replace(/:dataSourceId/g, dataSourceId),
			data
		);
	}
	async deleteDataSource(dataSourceId: string) {
		return await this.deleteResource<any>(configProxy.URIs.get_data_source.replace(/:dataSourceId/g, dataSourceId));
	}
	async getCommercialDataSources(params?: any, options?: any) {
		return await this.getResource<any>(configProxy.URIs.commercial_data_sources, {
			...(options || {}),
			params
		});
	}
	async getDevices(params?: any, options?: any) {
		return await this.getResource<any>(configProxy.URIs.get_devices, {
			...(options || {}),
			params
		});
	}
	async getDevice(deviceId: string) {
		const url = configProxy.URIs.get_device.replace(/:loggerId/g, deviceId);
		return await this.getResource<IDataSource>(url);
	}
	async createDevice(data: any) {
		return await this.postResource<any, any>(configProxy.URIs.get_devices, data);
	}
	async editDevice(deviceId: string, data: any) {
		return await this.patchResource<any, any>(configProxy.URIs.get_device.replace(/:loggerId/g, deviceId), data);
	}
	async deleteDevice(deviceId: string) {
		return await this.deleteResource<any>(configProxy.URIs.get_device.replace(/:loggerId/g, deviceId));
	}
	async getDashboardLoggersGraphs(networkId: string, params?: any, options?: any) {
		const url = configProxy.URIs.get_dashboard_loggers_graphs.replace(/:networkId/g, networkId);
		return await this.getResource<any>(url, {
			...(options || {}),
			params
		});
	}
	async getDashboardLoggersTable(networkId: string, params?: any, options?: any) {
		const url = configProxy.URIs.get_dashboard_loggers_table.replace(/:networkId/g, networkId);
		return await this.getResource<any>(url, {
			...(options || {}),
			params
		});
	}
	async deleteImage(imageId: string) {
		return await this.deleteResource<any>(configProxy.URIs.image_uri.replace(/:imageId/g, imageId));
	}
	async deleteFile(fileId: string) {
		return await this.deleteResource<any>(configProxy.URIs.file_uri.replace(/:fileId/g, fileId));
	}
}

export default new MonitoringService();
