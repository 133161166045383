import { Checkbox, Col, Form, FormProps, Row, Select, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../hooks/use-query';
import userManagementService from '../../services/user-management.service';
import { errorHandler } from '../../utils/error-handler';
import { notifySuccess } from '../../utils/notification-messages';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { NavigationContext } from '../../contexts/navigation.context';
import { upperSnakeToCapitalized } from '../../utils';
import { SubmitCanelButtons } from '../submit-cancel-buttons';

export const PermissionForm: React.FC<FormProps> = () => {
	const { t, i18n } = useTranslation();
	const userId = useQueryParam('userId');
	const scopeId = useQueryParam('scopeId');
	const appId = useQueryParam('appId');
	const [form] = Form.useForm();
	const navigationContext = useContext(NavigationContext);
	const { networks } = navigationContext;
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const { Option, OptGroup } = Select;

	// get applications
	const { data: users, isLoading: loadingUsers } = useQuery<any>({
		queryKey: ['users'],
		queryFn: () => userManagementService.getUsers()
	});

	// get subsystems
	const { data: subsystems, isLoading: loadingSubsystems } = useQuery<any>({
		queryKey: ['subsystems'],
		queryFn: () => userManagementService.getSubsystems()
	});

	// get applications
	const { data: applications, isLoading: loadingApplications } = useQuery<any>({
		queryKey: ['applications'],
		queryFn: () => userManagementService.getApplications()
	});

	// get app permission for editing
	const {
		data: permission,
		isLoading: loadingPermission,
		isFetching: fetchingPermission,
		refetch: refechPermission
	} = useQuery<any>({
		queryKey: ['app-permissions', userId, scopeId, appId],
		queryFn: () =>
			userManagementService.getPermissions({
				user: userId,
				scope: scopeId,
				app: appId
			}),
		enabled: !!userId && !!scopeId && !!appId
	});

	// fill data of the form when editing
	useEffect(() => {
		if (permission && permission.length > 0 && appId && scopeId) {
			const data = {
				...permission[0],
				app: appId,
				scope: scopeId
			};

			form.setFieldsValue({
				...data
			});
		}
		if (userId) {
			form.setFieldsValue({
				user: userId
			});
		}
	}, [permission, appId, scopeId, userId]);

	const onFinish = async (values: any) => {
		setSubmitting(true);
		Object.keys(values).forEach(key => {
			if (values[key] === '' || values[key] === undefined || values[key] === null) {
				delete values[key];
			}
		});
		if (permission && permission.length > 0) {
			try {
				const resp = await userManagementService.editUserPermission(permission[0].id, values);
				if (!resp) throw new Error(t('CouldntEditPermission', { ns: 'validation' }));
				queryClient.refetchQueries(['user-permissions', userId]);
				notifySuccess(t('editedSuccessfully'));
				navigate(`${AppRoutes.ADMINISTRATION}?tabSelected=users`);
			} catch (e: any) {
				errorHandler(e);
			}
		} else {
			try {
				const resp = await userManagementService.createUserPermission(values);
				if (!resp) throw new Error(t('CouldntAddPermission', { ns: 'validation' }));
				refechPermission();
				queryClient.refetchQueries(['user-permissions', userId]);
				notifySuccess(t('addedSuccessfully'));
				navigate(`${AppRoutes.ADMINISTRATION}?tabSelected=users`);
			} catch (e: any) {
				errorHandler(e);
			}
		}
		setSubmitting(false);
	};
	const handleCancel = () => {
		navigate(`${AppRoutes.ADMINISTRATION}?tabSelected=users`);
	};

	return (
		<div
			style={{
				paddingLeft: i18n.language === 'en' ? '1rem' : '',
				paddingRight: i18n.language === 'en' ? '' : '1rem',
				paddingTop: '2rem'
			}}
		>
			<Row gutter={24} className="justify-content-center">
				<Col xs={24} md={14} lg={10}>
					<Row>
						<h3 className="mb-3">{t('Permission')}</h3>
					</Row>
					<Row>
						<Col xs={24}>
							<Spin
								spinning={
									loadingUsers ||
									!!!networks ||
									loadingSubsystems ||
									loadingApplications ||
									(loadingPermission && fetchingPermission) ||
									submitting
								}
							>
								<Form
									form={form}
									layout="vertical"
									onFinish={onFinish}
									initialValues={{
										rights: {
											read: false,
											create: false,
											update: false,
											delete: false
										}
									}}
								>
									<Form.Item
										name="user"
										label={t('User')}
										rules={[{ required: true, message: t('PleaseAddUser', { ns: 'validation' }) }]}
									>
										<Select
											placeholder={t('User')}
											options={users?.map(user => ({
												value: user.id,
												label: upperSnakeToCapitalized(user.username)
											}))}
											disabled={!!userId}
										/>
									</Form.Item>
									<Form.Item
										name="scope"
										label={t('Network')}
										rules={[
											{ required: true, message: t('PleaseAddUserNetwork', { ns: 'validation' }) }
										]}
									>
										<Select
											placeholder={t('Network')}
											options={networks?.map(network => ({
												value: network.id,
												label: upperSnakeToCapitalized(
													i18n.language === 'en'
														? network.name_en
														: network?.name_ar || network.name_en
												)
											}))}
											disabled={!!scopeId}
										/>
									</Form.Item>
									<Form.Item
										name="app"
										label={t('App')}
										rules={[
											{
												required: true,
												message: t('PleaseAddUserApplication', { ns: 'validation' })
											}
										]}
									>
										<Select placeholder={t('Application')} disabled={!!appId}>
											{subsystems &&
												applications &&
												subsystems.map(subsystem => {
													const filteredApps = applications.filter(
														app => app.sub_system.id === subsystem.id
													);

													return (
														<OptGroup
															label={upperSnakeToCapitalized(subsystem.name)}
															key={subsystem.id}
														>
															{filteredApps.map(app => (
																<Option value={app.id} key={app.id}>
																	{upperSnakeToCapitalized(app.name)}
																</Option>
															))}
														</OptGroup>
													);
												})}
										</Select>
									</Form.Item>
									<Form.Item label={t('Rights')}>
										<Row gutter={16}>
											<Col xs={6}>
												<Form.Item valuePropName="checked" name={['rights', 'read']}>
													<Checkbox>{t('Read')}</Checkbox>
												</Form.Item>
											</Col>
											<Col xs={6}>
												<Form.Item valuePropName="checked" name={['rights', 'create']}>
													<Checkbox>{t('CreateNew')}</Checkbox>
												</Form.Item>
											</Col>
											<Col xs={6}>
												<Form.Item valuePropName="checked" name={['rights', 'update']}>
													<Checkbox>{t('Update')}</Checkbox>
												</Form.Item>
											</Col>
											<Col xs={6}>
												<Form.Item valuePropName="checked" name={['rights', 'delete']}>
													<Checkbox>{t('Delete')}</Checkbox>
												</Form.Item>
											</Col>
										</Row>
									</Form.Item>
									<Form.Item className="d-flex justify-content-end">
										<SubmitCanelButtons handleCancel={handleCancel} handleSubmit={form.submit} />
									</Form.Item>
								</Form>
							</Spin>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};
