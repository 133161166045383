import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { ScreenWidths } from '../../../hooks/use-media.hook';

export const SideDrawerContainer = styled.aside<{ media: keyof typeof ScreenWidths; englishSelected: any; label: any }>`
	position: fixed;
	padding-top: 71px;
	width: 230px;
	flex-shrink: 0;
	height: 100vh;
	left: ${({ englishSelected }) => (englishSelected ? 0 : 100)};
	top: 0;
	z-index: 1100;
	background-color: ${({ label }) => label.sliderColor};
	box-sizing: border-box;
	transition: all 0.3s ease-in-out;
	overflow-y: auto;
	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px ${colors.BLACK_RGBA3};
		width: 6px;
		height: 6px;
	}

	::-webkit-scrollbar-thumb {
		background-color: ${colors.INACTIVE};
		/* outline: 1px solid ${colors.DARK_GREY3} */
		/* opacity: 0.2; */
	}

	&.open {
		left: ${({ englishSelected }) => (englishSelected ? 0 : '80%')};
	}

	&.closed {
		left: ${({ englishSelected }) => (englishSelected ? '-100%' : '200%')};
	}

	&.not-collapsed {
	}

	.sidedrawer-children {
		z-index: 2000;
	}

	@media (min-width: ${({ media }) => ScreenWidths[media]}) {
		width: 300px;
		z-index: 1100;

		&.closed {
			left: ${({ englishSelected }) => (englishSelected ? '0' : '88.9%')};
		}
		&.not-collapsed {
			width: 220px;
		}
	}
`;
