import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Col, Form, FormProps, Input, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../hooks/use-query';
import userManagementService from '../../services/user-management.service';
import { errorHandler } from '../../utils/error-handler';
import { notifySuccess } from '../../utils/notification-messages';
import { SubmitCanelButtons } from '../submit-cancel-buttons';

export const SubsystemForm: React.FC<FormProps> = () => {
	const { t, i18n } = useTranslation();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const subsytemId = useQueryParam('subsystemId');
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	// load subsystem data
	const {
		data: subsystem,
		isLoading: loadingSubsytem,
		isFetching: fetechingSubsystem,
		refetch: refetcSubsystem
	} = useQuery({
		queryKey: ['subsystem', subsytemId],
		queryFn: () => subsytemId && userManagementService.getSubsystem(subsytemId),
		enabled: !!subsytemId
	});

	useEffect(() => {
		if (subsystem) {
			form.setFieldsValue({
				...subsystem
			});
		}
	}, [subsystem]);

	const onFinish = async (values: any) => {
		setSubmitting(true);
		Object.keys(values).forEach(key => {
			if (values[key] === '' || values[key] === undefined || values[key] === null) {
				delete values[key];
			}
		});

		if (subsytemId) {
			try {
				const resp = await userManagementService.editSubsystem(subsytemId, values);
				if (!resp) throw new Error(t('couldntEditSubsystem', { ns: 'validation' }));
				notifySuccess(t('editedSuccessfully'));
				queryClient.refetchQueries(['subsystems']);
				refetcSubsystem();
				navigate(`${AppRoutes.ADMINISTRATION}?tabSelected=superAdmin&&subTabSelected=subsystems`);
			} catch (e: any) {
				errorHandler(e);
			}
		} else {
			try {
				const resp = await userManagementService.createSubsystem(values);
				if (!resp) throw new Error(t('couldntAddSubsystem', { ns: 'validation' }));
				notifySuccess(t('addedSuccessfully'));
				queryClient.refetchQueries(['subsystems']);
				navigate(`${AppRoutes.ADMINISTRATION}?tabSelected=superAdmin&&subTabSelected=subsystems`);
			} catch (e: any) {
				errorHandler(e);
			}
		}
		setSubmitting(false);
	};
	const handleCancel = () => {
		navigate(`${AppRoutes.ADMINISTRATION}?tabSelected=superAdmin&&subTabSelected=subsystems`);
	};

	return (
		<div
			style={{
				paddingLeft: i18n.language === 'en' ? '1rem' : '',
				paddingRight: i18n.language === 'en' ? '' : '1rem',
				paddingTop: '2rem'
			}}
		>
			<Row gutter={24} className="justify-content-center">
				<Col xs={24} md={14} lg={10}>
					<Row>
						<h3 className="mb-3">{t('Subsystem')}</h3>
					</Row>
					<Row>
						<Col xs={24}>
							<Spin spinning={(loadingSubsytem && fetechingSubsystem) || submitting}>
								<Form form={form} layout="vertical" onFinish={onFinish}>
									<Form.Item
										name="name"
										label={t('Name')}
										rules={[
											{ required: true, message: t('addSubsystemName', { ns: 'validation' }) }
										]}
									>
										<Input type="text" placeholder={t('subsystemName')} />
									</Form.Item>

									<Form.Item
										name="displayName"
										label={t('DisplayName')}
										rules={[
											{
												required: true,
												message: t('addSubsystemDisplayName', { ns: 'validation' })
											}
										]}
									>
										<Input type="text" placeholder={t('subsystemDisplayName')} />
									</Form.Item>
									<Form.Item className="d-flex justify-content-end">
										<SubmitCanelButtons handleCancel={handleCancel} handleSubmit={form.submit} />
									</Form.Item>
								</Form>
							</Spin>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};
