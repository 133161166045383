import { Col, Layout, Menu, Row, Tabs } from 'antd';
import React, { useState } from 'react';
import { DataSourcesTable } from '../components/data-sources-table';
import { DevicesTable } from '../components/devices-table';
import { MeasurementPointsTable } from '../components/measurement-points-table';
import { NetworksTable } from '../components/networks-table';
import { ZonesTable } from '../components/zones-table';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../hooks/use-query';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import { AlertsConfigTable } from '../components/alerts-config-table';

export const ConfigurationPage: React.FC = () => {
	const { t } = useTranslation();
	const tabSelected = useQueryParam('tabSelected');

	const currentSearchParams = new URLSearchParams(window.location.search);
	const [alertsTabSelected, setAlertsTabSelected] = useState<any>('networks-alerts');

	const alertsConfigItems = [
		{
			label: t('NetworksAlerts'),
			key: 'networks-alerts',
			children: <AlertsConfigTable selectedTable={'network'} />
		},
		{
			label: t('ZonesAlerts'),
			key: 'zones-alerts-config',
			children: <AlertsConfigTable selectedTable={'zone'} />
		},
		{
			label: t('SitesAlerts'),
			key: 'sites-alerts',
			children: <AlertsConfigTable selectedTable={'measurement_point'} />
		},
		{
			label: t('DataSourcesAlerts'),
			key: 'data-sources-alerts',
			children: <AlertsConfigTable selectedTable={'data_source'} />
		}
	];
	const tabItems = [
		{ label: t('Networks'), key: 'networks', children: <NetworksTable /> },
		{
			label: t('Zones'),
			key: 'zones',
			children: <ZonesTable />
		},
		{ label: t('MeasurementPoints'), key: 'sites', children: <MeasurementPointsTable /> },
		{ label: t('DataSources'), key: 'data-sources', children: <DataSourcesTable /> },
		{ label: t('Devices'), key: 'devices', children: <DevicesTable /> },
		{
			label: t('Alerts'),
			key: 'alerts',
			children: (
				<Layout style={{ minHeight: '80vh' }}>
					<Sider>
						<Menu
							mode="inline"
							defaultSelectedKeys={['networks-alerts']}
							defaultOpenKeys={['networks-alerts']}
							style={{ height: '100%', borderRight: 0 }}
							onClick={({ key }) => setAlertsTabSelected(key)}
							items={alertsConfigItems?.map(item => ({ key: item.key, label: item.label }))}
						/>
					</Sider>
					<Layout>
						<Content style={{ padding: '16px' }}>
							{alertsConfigItems.find(
								item => item.key === (alertsTabSelected ? alertsTabSelected : 'networks-alerts')
							)?.children || null}
						</Content>
					</Layout>
				</Layout>
			)
		}
	];

	return (
		<>
			<Row className="d-flex justify-content-center">
				<Col xs={22}>
					<Tabs
						centered
						defaultActiveKey={tabSelected ? tabSelected : 'networks'}
						items={tabItems}
						onChange={key => {
							// add selected tab to sreach params
							currentSearchParams.set('tabSelected', key);
							const newUrl = `${window.location.pathname}?${currentSearchParams.toString()}`;
							window.history.pushState({ path: newUrl }, '', newUrl);
						}}
					/>
				</Col>
			</Row>
		</>
	);
};
