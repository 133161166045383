import { useQuery } from '@tanstack/react-query';
import { Breadcrumb, Col, Collapse, Row, Spin, Switch } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { TiHomeOutline } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import { ZoneAnalysisConfiguration, ZoneForm } from '../components';
import { colors } from '../constants/colors';
import { AppRoutes } from '../constants/routes';
import { MonitoringMap } from '../containers';
import { NavigationContext } from '../contexts/navigation.context';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../hooks/use-query';
import monitoringService from '../services/monitoring.service';
import { upperSnakeToCapitalized } from '../utils';
import { ZoneLeakageConfiguration } from '../components/zone-leakage-configuration';
import { AlertsConfiguration } from '../components/alerts-config';
import alertsService from '../services/alerts.service';

const { Panel } = Collapse;

export const ZoneConfigurationPage: React.FC<any> = () => {
	const { t, i18n } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { errorHandler, refetchNetworks, refetchZones, refetchAllZones } = navigationContext;

	const [selectedLocation, setSelectedLocation] = useState<any>({ latitude: undefined, longitude: undefined });
	const [activatedZone, setActivatedZone] = useState<boolean | null>(null);
	const [activePanel, setActivePanel] = useState<string[]>(['zone_basic_info']);

	const zoneId = useQueryParam('zoneId');
	const panelSelected = useQueryParam('panelSelected');
	const navigate = useNavigate();

	// get selected zone data
	const {
		data: zoneData,
		isLoading: loadingZoneData,
		refetch: refetchZone
	} = useQuery({
		queryKey: ['zone', zoneId],
		queryFn: () => zoneId && monitoringService.getZone(zoneId),
		enabled: !!zoneId
	});

	// load zone alerts configuration
	const {
		data: alertsConfig,
		isLoading: loadingAlertsConfig,
		refetch: refetchAlertsConfig
	} = useQuery<any>({
		queryKey: ['zone-alerts-config', zoneId],
		queryFn: () => zoneId && alertsService.getZoneAlertConfigs(zoneId),
		enabled: !!zoneId
	});

	// load alert types for zone
	const { data: alertTypes, isLoading: loadingAlertTypes } = useQuery<any>({
		queryKey: ['zone-alert-types'],
		queryFn: () => alertsService.getAlertTypes({ entity: 'zone' }),
		enabled: true
	});

	// open panel if is selected
	useEffect(() => {
		if (panelSelected) {
			setActivePanel([panelSelected]);
		}
	}, []);

	// activate/deactivate zone immediately when switching activation key
	useEffect(() => {
		if (zoneId && zoneData && activatedZone !== null) {
			if (activatedZone !== zoneData.is_active) {
				(async () => {
					try {
						const resp = await monitoringService.editZone(zoneId, {
							is_active: activatedZone
						});
						if (!resp) throw new Error(t('couldntEditZone', { ns: 'validation' }));
						refetchZone();
						refetchNetworks();
						refetchZones();
						refetchAllZones();
					} catch (e: any) {
						errorHandler(e);
					}
				})();
			}
		}
	}, [activatedZone, zoneId]);

	// zoom to default zone location
	useEffect(() => {
		if (zoneData) {
			setSelectedLocation({
				latitude: zoneData.latitude,
				longitude: zoneData.longitude
			});
			setActivatedZone(zoneData.is_active);
		}
	}, [zoneData]);

	return (
		<div
			style={{
				paddingLeft: i18n.language === 'en' ? '1rem' : '',
				paddingRight: i18n.language === 'en' ? '' : '1rem',
				paddingTop: '2rem'
			}}
		>
			<Spin spinning={!!zoneId && (loadingZoneData || loadingAlertTypes || loadingAlertsConfig)}>
				<Breadcrumb
					style={{ fontSize: '1rem' }}
					className="mb-3"
					items={[
						{
							title: (
								<div
									className="d-flex align-items-center"
									role="button"
									onClick={() => navigate(`${AppRoutes.CONFIGURATION}?tabSelected=zones`)}
								>
									<TiHomeOutline className={i18n.language === 'en' ? 'me-1' : 'ms-1'} />
									<span>{t('ZonesConfiguration')}</span>
								</div>
							)
						},
						{
							title: (
								<div style={{ fontWeight: 'bold' }} className="d-flex align-items-center">
									{t('ConfigurationName', {
										name: zoneData
											? i18n.language === 'en'
												? upperSnakeToCapitalized(zoneData.name_en)
												: zoneData.name_ar
											: t('Zone')
									})}
									<Switch
										checked={activatedZone ?? false}
										checkedChildren={t('Active')}
										unCheckedChildren={t('Inactive')}
										onChange={(value, event) => {
											event.stopPropagation();
											setActivatedZone(value);
										}}
										style={{ backgroundColor: activatedZone ? colors.HEALTHY : colors.ERROR }}
										className={'mx-2'}
										size="small"
									/>
								</div>
							)
						}
					]}
					separator=">"
				/>
				<Row gutter={24}>
					<Col xs={24} md={14}>
						<Collapse
							accordion
							activeKey={activePanel}
							onChange={(e: any) => {
								setActivePanel(e);
							}}
							size="large"
						>
							<Panel key={'zone_basic_info'} header={t('ZoneBasicInfo')} style={{ fontWeight: 'bold' }}>
								<ZoneForm
									selectedLocation={selectedLocation}
									zoneData={zoneData}
									activatedZone={activatedZone}
									setSelectedLocation={setSelectedLocation}
									setActivePanel={setActivePanel}
								/>
							</Panel>
							{zoneId ? (
								<>
									<Panel
										key={'zone_analysis_config'}
										header={t('ZoneAnaysisConfiguration')}
										style={{ fontWeight: 'bold' }}
									>
										<ZoneAnalysisConfiguration />
									</Panel>
									<Panel
										key={'zone_leakage_config'}
										header={t('ZoneLeakageConfiguration')}
										style={{ fontWeight: 'bold' }}
									>
										<ZoneLeakageConfiguration />
									</Panel>
									<Panel
										key={'zone_alerts_config'}
										header={t('ZoneAlertsConfiguration')}
										style={{ fontWeight: 'bold' }}
									>
										<AlertsConfiguration
											alertsConfig={alertsConfig}
											refechAlertsConfig={refetchAlertsConfig}
											alertTypes={alertTypes}
											createAlertConfig={async values =>
												zoneId && (await alertsService.createZoneAlertConfig(zoneId, values))
											}
										/>
									</Panel>
								</>
							) : null}
						</Collapse>
					</Col>
					<Col xs={24} md={10}>
						<MonitoringMap
							style={{
								height: '590px',
								width: 'auto',
								borderRadius: '13px',
								zIndex: 50
							}}
							selectedZone={zoneId}
							zones={zoneData && [zoneData]}
							pointMarker={
								selectedLocation.latitude && selectedLocation.longitude
									? [selectedLocation.latitude, selectedLocation.longitude]
									: undefined
							}
						/>
					</Col>
				</Row>
			</Spin>
		</div>
	);
};
