export const formatFilters = (filter: any) => {
	const keys: string[] = Object.keys(filter);
	const values: any[] = Object.values(filter);
	const filterObject: any = {};
	keys.forEach((key, idx) => {
		if (values[idx] && values[idx].length) {
			filterObject[key] = values[idx][0];
		}
	});

	return filterObject;
};
