import { Tooltip } from 'antd';
import { HTMLAttributes, useContext } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { colors } from '../../constants/colors';
import { AuthenticationContext } from '../../contexts';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { UserRights, UserApps } from '../../types';
import { hasRight } from '../../utils/has-right';
import { ComponentGuard, Premium } from '../component-guard';
import { TableControlItem, TableControlsContainer } from './styles/table-controls';

export const TableControls: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		networkId?: any;
		onEdit?: () => void;
		onRemove?: () => void;
		userApp: UserApps;
		notShowEdit?: boolean;
		notShowDelete?: boolean;
		admin?: Boolean;
	}
> = ({ onEdit, onRemove, networkId, userApp, notShowEdit = false, notShowDelete = false, admin }) => {
	const { t } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { applicationIdMap } = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user, configurationPermessions } = authContext;

	const editControlChildren = (
		<Tooltip title={t('Edit', { ns: 'common' })}>
			<TableControlItem onClick={() => onEdit && onEdit()}>
				<FaRegEdit color={colors.WAI_BLUE} />
			</TableControlItem>
		</Tooltip>
	);

	const deleteControlChildren = (
		<Tooltip title={t('Remove', { ns: 'common' })}>
			<TableControlItem onClick={() => onRemove && onRemove()}>
				<RiDeleteBinLine color={colors.ERROR} />
			</TableControlItem>
		</Tooltip>
	);

	return (
		<>
			<TableControlsContainer>
				{!notShowEdit ? (
					<ComponentGuard
						allowed={
							!!admin ||
							hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(userApp),
								UserRights.EDIT,
								networkId
							)
						}
						fallback={() => <Premium showIcon={false} children={editControlChildren} />}
					>
						{editControlChildren}
					</ComponentGuard>
				) : null}
				{!notShowDelete ? (
					<ComponentGuard
						allowed={
							!!admin ||
							hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(userApp),
								UserRights.DELETE,
								networkId
							)
						}
						fallback={() => <Premium showIcon={false} children={deleteControlChildren} />}
					>
						{deleteControlChildren}
					</ComponentGuard>
				) : null}
			</TableControlsContainer>
		</>
	);
};
