import { BsChatDotsFill } from 'react-icons/bs';
import { MdDashboard, MdShowChart } from 'react-icons/md';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { VscSettingsGear } from 'react-icons/vsc';
import { useLocation } from 'react-router';
import { Sidebar } from '../components';
import { SidebarFooter, SidebarHeader } from '../components/sidebar/sidebar';
import { colors } from '../constants/colors';
import { useTranslation } from 'react-i18next';
import { ISidebarItem } from '../types/sidebar-items';

export const WaiSidebar = ({ sidebarVisible, onSidebarClose }) => {
	const { t } = useTranslation();
	let resource = useLocation().pathname;

	const menuItems: ISidebarItem[] = [
		{
			name: t('dashboard'),
			icon: <MdDashboard />,
			key: 'dashboard'
		},
		{
			name: t('Activity'),
			icon: <MdShowChart />,
			key: 'activity',
			tags: [{ bgColor: colors.PINK, textColor: colors.WHITE, text: 'Soon' }],
			disabled: true
		},
		{
			name: t('Chat', { ns: 'common' }),
			icon: <BsChatDotsFill />,
			key: 'chat',
			tags: [{ bgColor: colors.PINK, textColor: colors.WHITE, text: 'Soon' }],
			disabled: true
		},
		{
			name: t('Settings'),
			icon: <VscSettingsGear />,
			key: 'settings',
			tags: [{ bgColor: colors.PINK, textColor: colors.WHITE, text: 'Soon' }],
			disabled: true
		},
		{
			name: t('Logout', { ns: 'common' }),
			icon: <RiLogoutBoxLine />,
			key: 'logout'
		}
	];
	return (
		<Sidebar
			menuItems={menuItems}
			selected={resource}
			visible={sidebarVisible}
			close={onSidebarClose}
			collapseOnRouteChange
			header={(collapsed: boolean) => <SidebarHeader collapsed={collapsed} />}
			footer={(collapsed: boolean) => <SidebarFooter collapsed={collapsed} />}
		/>
	);
};
