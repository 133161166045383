import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const WaiWarningContainer = styled.div<{ englishSelected: boolean }>`
	border-radius: 20px;
	background-color: ${colors.BLACK_RGBA2};
	width: 80%;
	display: flex;
	padding: 4px 10px;
	align-items: center;
	position: fixed;
	bottom: 5px;
	z-index: 70;
	// ${({ englishSelected }) => (englishSelected ? 'margin-left: 10%;' : 'margin-right: 10%;')};
	margin-left: 5%;
	font-size: 0.75rem;
`;

export const WaiWarningIconContainer = styled.div`
	background-color: ${colors.ERROR};
	border-radius: 50%;
	border: 1px solid ${colors.WHITE};
	outline: 2px solid ${colors.ERROR};
	margin-right: 10px;
	padding: 4px;
	color: ${colors.WHITE};
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const WaiWarningLabel = styled.span`
	font-weight: bold;
	margin-right: 5px;
`;

export const WaiWarningMessage = styled.span`
	font-weight: 400;
	font-color: ${colors.GREY};
`;
