import { useQueries, useQuery } from '@tanstack/react-query';
import { Col, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import { HTMLAttributes, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import chartsService from '../services/charts.service';
import monitoringService from '../services/monitoring.service';
import { DataSources, GraphSpan, PressureUnit, SpanType } from '../types';
import { clearArrayUndefined, dateToQuery, mostRecentDate } from '../utils';
import { Sites } from '../types/sites';
import { WaterProductionReadingsCards } from './water-production-readings-tab';
import { WaiTimeSeriesChart } from './wai-time-series';
import { TimeSeriesBarGrouped } from './time-series-bar-grouped';

export const NetworkSitesWaterProductionTab: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
	const { t, i18n } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { networks, selectedNetwork, measurementPoints } = navigationContext;

	const [dailyWaterProductionSiteChartTimeSpan, setDailyWaterProductionSiteChartTimeSpan] = useState<{
		date: string;
	}>({
		date: dayjs().format('YYYY-MM-DD')
	});
	const [pressureChartTimeSpan, setPressureChartTimeSpan] = useState<{ start: string; end: string }>({
		start: dateToQuery(dayjs().subtract(7, 'day')),
		end: dateToQuery(dayjs())
	});
	const [waterMeterLogsChartTimeSpan, setWaterMeterLogsChartTimeSpan] = useState<{ start: string; end: string }>({
		start: dateToQuery(dayjs().subtract(7, 'day')),
		end: dateToQuery(dayjs())
	});
	const [chartTimeSpchanWaterProductionBreakout, setChartTimeSpanWaterProductionBreakout] = useState<{
		start?: string;
		date?: string;
	}>({
		date: dayjs().format('YYYY-MM-DD')
	});
	const [spanType, setSpanType] = useState<SpanType>(SpanType.DAY);
	const [pressureDataSources, setPressureDataSources] = useState<any>([]);

	// Load daily sites water production daily readings chart
	const dailySitesWaterProductionReadings = useQueries<any>({
		queries: (measurementPoints || [])
			.filter(site => site.type === Sites.WATER_STATION)
			.map((site: any) => {
				return {
					queryKey: [
						'site-daily-water-production-readings',
						site.id,
						dailyWaterProductionSiteChartTimeSpan?.date
					],
					queryFn: () => {
						if (dailyWaterProductionSiteChartTimeSpan?.date === dayjs().format('YYYY-MM-DD')) {
							// Load sites water production current readings
							return chartsService.getSiteCurrentWaterProductionReading(site?.id);
						} else {
							return chartsService.getSiteDailyProduction(site.id, {
								start:
									dailyWaterProductionSiteChartTimeSpan?.date.length &&
									dayjs(dailyWaterProductionSiteChartTimeSpan.date).format('YYYY-MM-DD'),
								span_type: SpanType.DAY
							});
						}
					},

					enabled: !!site.id && !!selectedNetwork && !!dailyWaterProductionSiteChartTimeSpan?.date
				};
			})
	});

	// Pressure sensors data sources chart:
	// 1. Loading all data sources for selected network
	const { data: networkDataSources } = useQuery<any>({
		queryKey: ['data-sources', selectedNetwork],
		queryFn: () => monitoringService.getDataSources({ filters: { network: selectedNetwork } }),
		enabled: !!selectedNetwork
	});
	// 2. Get network pressure data sources
	useEffect(() => {
		if (networkDataSources) {
			const dataSourcesPressure = clearArrayUndefined(
				networkDataSources
					.filter(source => source.type === DataSources.PRESSURE)
					.filter(source => {
						return measurementPoints.some(
							site => site.id === source.measurement_point && site.type === 'water_station'
						);
					})
			);
			setPressureDataSources(dataSourcesPressure);
		}
	}, [networkDataSources]);
	// 3. Last network pressure data sources readings
	const pressureLastReadings = useQueries<any>({
		queries: (pressureDataSources || []).map((dataSource: any) => {
			return {
				queryKey: ['data-source-last-log-date', dataSource.id],
				queryFn: () => chartsService.getDataSourceLastLogDate(dataSource.id),
				enabled: !!dataSource.id && !!selectedNetwork
			};
		})
	});
	// 4. Network pressures data sources readings relative to pressureChartTimeSpan
	const pressures = useQueries<any>({
		queries: (pressureDataSources || []).map((dataSource: any) => ({
			queryKey: ['data-source-logs', dataSource.id, pressureChartTimeSpan],
			queryFn: () =>
				chartsService.getDSLogs(dataSource.id, {
					start: pressureChartTimeSpan?.start.length ? pressureChartTimeSpan?.start : undefined,
					end: pressureChartTimeSpan?.end.length ? pressureChartTimeSpan?.end : undefined,
					aggregate: pressureChartTimeSpan?.start.length
						? dayjs(pressureChartTimeSpan?.end).diff(pressureChartTimeSpan?.start, 'd') === 0
							? undefined
							: 1
						: 1
				}),
			enabled: !!dataSource.id && !!selectedNetwork
		}))
	});

	// Water meter readings chart:
	// 1. Last water meters readings
	const waterMeterLastReadings = useQueries<any>({
		queries: (measurementPoints || [])
			.filter(site => site.type === Sites.WATER_STATION)
			.map((site: any) => {
				return {
					queryKey: ['site-water-meter-last-reading', site.id],
					queryFn: () => chartsService.getSiteWaterMeterLastReading(site.id),
					enabled: !!site.id && !!selectedNetwork
				};
			})
	});
	// 2. Water meters readings relative to waterProducedChartTimeSpan
	const waterMeters = useQueries<any>({
		queries: (measurementPoints || [])
			.filter(site => site.type === Sites.WATER_STATION)
			.map((site: any) => {
				return {
					queryKey: ['site-water-meter-logs', site.id, waterMeterLogsChartTimeSpan],
					queryFn: () =>
						chartsService.getSiteWaterMeterLogs(site.id, {
							start:
								waterMeterLogsChartTimeSpan?.start.length &&
								dayjs(waterMeterLogsChartTimeSpan.start).format('YYYY-MM-DD'),

							end:
								waterMeterLogsChartTimeSpan?.end.length &&
								dayjs(waterMeterLogsChartTimeSpan.end).format('YYYY-MM-DD'),
							aggregate: waterMeterLogsChartTimeSpan?.start.length
								? dayjs(waterMeterLogsChartTimeSpan?.end).diff(
										waterMeterLogsChartTimeSpan?.start,
										'd'
								  ) === 0
									? undefined
									: 1
								: 1
						}),

					enabled: !!site.id && !!selectedNetwork
				};
			})
	});

	// Water production breakout chart:
	// 1. Load network water productions readings relative to bar chart over a period of days, months or years
	const {
		data: waterProductionBreakoutChart,
		isLoading: loadingWaterProductionBreakoutChart,
		isFetching: fetchingWaterProductionBreakoutChart
	} = useQuery<any>({
		queryKey: [
			'water-production-breakout',
			spanType,
			chartTimeSpchanWaterProductionBreakout?.date,
			selectedNetwork
		],
		queryFn: () =>
			chartsService.getNetworkWaterBreakout(selectedNetwork, {
				span_type: spanType,
				start: chartTimeSpchanWaterProductionBreakout?.date
					? dayjs(chartTimeSpchanWaterProductionBreakout.date).startOf(spanType).format('YYYY-MM-DD')
					: dayjs().startOf(spanType).format('YYYY-MM-DD')
			}),

		enabled: !!selectedNetwork
	});

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col xs={24}>
					<Spin
						spinning={
							!dailySitesWaterProductionReadings?.every(card => card.isSuccess) || !measurementPoints
						}
					>
						<WaterProductionReadingsCards
							title={t('SitesDailyWaterProductionReadings')}
							graphHeight={550}
							spanTpye={SpanType.DAY}
							showTableChart
							lastReadingsDate={dayjs().format('YYYY-MM-DD')}
							water_production_cards={
								dailySitesWaterProductionReadings?.every(card => card.isSuccess)
									? dailySitesWaterProductionReadings?.map((card: any, idx: number) => {
											return {
												name:
													measurementPoints?.length > 0 &&
													measurementPoints.find(site => site.id === card?.data.id)?.name_en,
												value:
													dailyWaterProductionSiteChartTimeSpan?.date ===
													dayjs().format('YYYY-MM-DD')
														? (card?.data && card?.data?.value) ?? undefined
														: card?.data?.data?.length > 0
														? card?.data?.data[0].value
														: undefined,
												unit: card?.data?.unit,
												current:
													dailyWaterProductionSiteChartTimeSpan?.date ===
													dayjs().format('YYYY-MM-DD')
														? true
														: false,
												id: card?.data?.id
											};
									  })
									: []
							}
							OnDateSelect={(date: any) => setDailyWaterProductionSiteChartTimeSpan(date)}
						/>
					</Spin>
				</Col>
				{pressureDataSources?.length > 0 ? (
					<Col xs={24}>
						<Spin
							spinning={
								!pressures?.every(pressure => pressure.isSuccess)
								// ||
								// !pressureLastReadings?.every(pressure => pressure.isSuccess)
							}
						>
							<WaiTimeSeriesChart
								IDs={clearArrayUndefined([
									...pressureDataSources.map((source: any) => {
										const measurementPoint =
											measurementPoints &&
											measurementPoints.find(point => point.id === source.measurement_point);
										return {
											id: source.id,
											name:
												measurementPoint &&
												`${
													i18n.language === 'en'
														? measurementPoint.name_en || measurementPoint.id
														: measurementPoint.name_ar ||
														  measurementPoint.name_en ||
														  measurementPoint.id
												} ${source.stream_direction.toLowerCase()} ${source.type.toLowerCase()}`
										};
									})
								])}
								flags={{ aggregate: true }}
								title={t('NetworkPressureAnalysis')}
								height={45}
								graphHeight={550}
								dataObjects={
									pressures &&
									pressures.length &&
									pressureDataSources.map((dataSource: any, idx: number) => {
										const measurementPoint =
											measurementPoints &&
											measurementPoints.find(point => point.id === dataSource.measurement_point);
										const dataObj: any = {
											id: dataSource.id,
											name:
												measurementPoint &&
												`${
													i18n.language === 'en'
														? measurementPoint.name_en || measurementPoint.id
														: measurementPoint.name_ar ||
														  measurementPoint.name_en ||
														  measurementPoint.id
												} ${dataSource.stream_direction.toLowerCase()} ${dataSource.type.toLowerCase()}`,
											data: pressures[idx].data ?? [],
											allData: [],
											unit: PressureUnit.BAR
										};
										if (idx === 0)
											dataObj['yAxis'] = {
												position: 'left',
												legend: t('PressureInBAR')
											};
										return dataObj;
									})
								}
								lastReadingDate={mostRecentDate(
									pressureLastReadings?.map((item: any) => item.data?.time)
								)}
								showSpanSelector
								showCustomSpanSelector
								onSpanSelect={(span: any) => setPressureChartTimeSpan(span)}
								showTabular
								showTimeStep
								sourceName={networks.find((network: any) => network.id === selectedNetwork)?.name_en}
							/>
						</Spin>
					</Col>
				) : null}
				<Col xs={24}>
					<Spin spinning={!waterMeters.every(pressure => pressure.isSuccess) || !networks}>
						<WaiTimeSeriesChart
							IDs={clearArrayUndefined([
								waterMeters &&
									waterMeters.every(meter => meter.isSuccess) &&
									clearArrayUndefined([
										...waterMeters.map((meter: any, idx: number) => {
											const measurementPoint =
												measurementPoints &&
												measurementPoints.find(point => point.id === meter.data.id);
											return {
												id: meter.data.id,
												name:
													measurementPoint && i18n.language === 'en'
														? measurementPoint.name_en || measurementPoint.id
														: measurementPoint.name_ar ||
														  measurementPoint.name_en ||
														  measurementPoint.id
											};
										})
									])
							])}
							flags={{ aggregate: true }}
							title={t('WaterMetersReadings')}
							height={45}
							graphHeight={550}
							showSpanSelector
							showCustomSpanSelector
							showTabular
							showTimeStep
							dataObjects={
								waterMeters &&
								waterMeters.every(meter => meter.isSuccess) &&
								waterMeters.map((meter: any, idx: number) => {
									const measurementPoint =
										measurementPoints &&
										measurementPoints.find(point => point.id === meter.data.id);
									const dataObj: any = {
										id: meter.data.id,
										name:
											measurementPoint &&
											`${
												i18n.language === 'en'
													? measurementPoint.name_en || measurementPoint.id
													: measurementPoint.name_ar ||
													  measurementPoint.name_en ||
													  measurementPoint.id
											}`,
										data: meter.data.data ?? [],
										allData: [],
										unit: meter.data.unit ?? 'L/sec'
									};
									if (idx === 0)
										dataObj['yAxis'] = {
											position: 'left',
											legend: t('legendTitleUnit', {
												title: meter.data.name,
												in: !meter.data.name ? '' : t('in'),
												name: t('L/sec')
											})
										};
									return dataObj;
								})
							}
							lastReadingDate={mostRecentDate(
								waterMeterLastReadings?.map((item: any) => item.data?.time)
							)}
							onSpanSelect={(span: any) => setWaterMeterLogsChartTimeSpan(span)}
						/>
					</Spin>
				</Col>
				<Col xs={24}>
					<Spin spinning={loadingWaterProductionBreakoutChart}>
						<TimeSeriesBarGrouped
							IDs={[
								{
									id: selectedNetwork,
									name: waterProductionBreakoutChart?.name
								}
							]}
							height={50}
							graphHeight={550}
							showCustomSelectorDate
							showSpanSelector
							spanType={spanType}
							setSpanType={setSpanType}
							OnDateSelect={(date: any) => setChartTimeSpanWaterProductionBreakout(date)}
							customTimeUnits={[GraphSpan.DAY, GraphSpan.MONTH, GraphSpan.YEAR]}
							lastReadingsDates={{
								day: dayjs().format('YYYY-MM-DD'),

								month: dayjs().startOf('M').format('YYYY-MM-DD'),
								year: dayjs().startOf('year').format('YYYY-MM-DD')
							}}
							groupedChart={true}
							dataObjects={
								waterProductionBreakoutChart &&
								waterProductionBreakoutChart.data.length > 0 &&
								waterProductionBreakoutChart.data
									.map((site: any, idx: number) => {
										if (
											site.id === '20fbd011-7eee-4e3e-be52-e145ec9fa264' ||
											site.id === '2eda2b2d-cb89-41b3-98b9-cda91becc473'
										)
											site.data = site.data.map((s: any) => {
												s.value = Math.random();
												return s;
											});
										return site;
									})
									.filter(site => site.id !== '10f59a83-f222-401b-a38e-31ad84733bda')
									.map((site: any, idx: number) => {
										const measurementPoint =
											measurementPoints && measurementPoints.find(point => point.id === site.id);
										const dataObj: any = {
											id: site.id,
											name:
												measurementPoint &&
												`${
													i18n.language === 'en'
														? measurementPoint.name_en || measurementPoint.id
														: measurementPoint.name_ar ||
														  measurementPoint.name_en ||
														  measurementPoint.id
												}`,
											data: site.data ?? [],
											allData: [],
											unit: waterProductionBreakoutChart?.unit ?? undefined
										};
										if (idx === 0)
											dataObj['yAxis'] = {
												position: 'left',
												legend: t('legendTitleUnit', {
													title: waterProductionBreakoutChart?.name,
													in: !waterProductionBreakoutChart?.unit ? '' : t('in'),
													name: waterProductionBreakoutChart?.unit ?? ''
												})
											};
										return dataObj;
									})
							}
							title={t('NetworkWaterProductionBreakoutbySite')}
							showTimeStep
							showTableChart
						/>
					</Spin>
				</Col>
			</Row>
		</>
	);
};
