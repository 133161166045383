import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Form, Modal, Table, TableProps } from 'antd';
import { useContext, useState } from 'react';
import { AiFillCheckCircle, AiFillCloseCircle, AiOutlinePlus } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { transformDataSourcesToRecord } from '../../mappers/data-source-to-record';
import monitoringService from '../../services/monitoring.service';
import { IDataSourceRecord, UserApps } from '../../types';
import { notifySuccess } from '../../utils/notification-messages';
import { upperSnakeToCapitalized } from '../../utils/upper-snake-to-capitalized';
import { DataSourceForm } from '../data-source-form';
import { TableControls } from '../table-controls';
import { modalConfirm } from '../modal-confirm';

export const DataSourcesSummaryTable: React.FC<
	TableProps<IDataSourceRecord> & {
		siteData: any;
		refetchSiteData: any;
	}
> = ({ siteData, refetchSiteData }) => {
	const { t } = useTranslation();
	const [dataSource, setDataSource] = useState<any>([]);
	const [visibleModal, setVisibleModal] = useState<boolean>(false);

	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	const [form] = Form.useForm();
	const queryClient = useQueryClient();

	// load data sources for measurement point
	const {
		data: dataSources,
		isLoading: loadingDataSources,
		refetch: refetchDataSources
	} = useQuery<any>({
		queryKey: ['data-sources', siteData?.id],
		queryFn: () =>
			siteData?.id && monitoringService.getDataSources({ filters: { measurement_point: siteData.id } }),
		enabled: !!siteData?.id
	});

	const tableColumns: any = [
		{
			title: t('DataSource'),
			dataIndex: 'name_en',
			key: 'name_en',
			align: 'center' as 'center',
			render: (text: string) => text
		},
		{
			title: t('Channel'),
			dataIndex: 'channel',
			key: 'channel',
			align: 'center' as 'center',
			render: (num: number) => num
		},
		,
		{
			title: t('Type'),
			dataIndex: 'type',
			key: 'type',
			align: 'center' as 'center',
			render: (text: string) => upperSnakeToCapitalized(text)
		},
		{
			title: t('StreamDirection'),
			dataIndex: 'stream_direction',
			key: 'stream_direction',
			align: 'center' as 'center',
			render: (text: string) => upperSnakeToCapitalized(text)
		},

		{
			title: t('Active'),
			dataIndex: 'is_active',
			key: 'is_active',
			align: 'center' as 'center',
			render: (check: boolean) =>
				check ? <AiFillCheckCircle className="text-success" /> : <AiFillCloseCircle className="text-danger" />
		},
		{
			title: t('Primary'),
			dataIndex: 'is_primary',
			key: 'is_primary',
			align: 'center' as 'center',
			render: (check: boolean) =>
				check ? <AiFillCheckCircle className="text-success" /> : <AiFillCloseCircle className="text-danger" />
		}
	];

	tableColumns.push({
		title: t('Actions'),
		key: 'actions',
		align: 'center' as 'center',
		render: (_: any, record: IDataSourceRecord) => (
			<TableControls
				networkId={record.network}
				onEdit={() => {
					setDataSource(record);
					setVisibleModal(true);
				}}
				onRemove={() => onRemove(record.id)}
				userApp={UserApps.DATA_SOURCE}
			/>
		)
	});

	// confirm deletion data source
	const onRemove = (id: string) => {
		try {
			modalConfirm({
				content: t('YouAreAboutToDeleteDataSource'),
				onOk: async () => {
					await monitoringService.deleteDataSource(id);
					refetchSiteData();
					refetchDataSources();
					queryClient.refetchQueries(['data-sources']);
					notifySuccess(t('RemovedSuccessfully'));
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	// confirm edition or creation data source
	const onConfirm = () => {
		try {
			modalConfirm({
				onOk: async () => {
					form.submit();
				},
				onCancel: () => {
					setVisibleModal(true);
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	return (
		<>
			<Table
				tableLayout="auto"
				scroll={{ x: true }}
				columns={tableColumns}
				dataSource={dataSources?.length && siteData && transformDataSourcesToRecord(dataSources)}
				size="small"
				showSorterTooltip
				rowKey="id"
				pagination={false}
				loading={loadingDataSources}
			/>
			<Button
				onClick={() => {
					form.resetFields();
					setDataSource(undefined);
					setVisibleModal(true);
				}}
				className={'my-2 float-end'}
				type="link"
				icon={<AiOutlinePlus />}
			>
				{t('AddNewDataSource')}
			</Button>
			<Modal
				open={visibleModal}
				title={t('DataSource')}
				onOk={() => {
					setVisibleModal(false);
					onConfirm();
				}}
				onCancel={() => {
					setVisibleModal(false);
				}}
			>
				<DataSourceForm
					form={form}
					dataSource={dataSource}
					refetchSiteData={refetchSiteData}
					siteData={siteData}
				/>
			</Modal>
		</>
	);
};
