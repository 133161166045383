import { useQuery } from '@tanstack/react-query';
import { Button, Modal, Spin, Table, TableColumnsType, TableProps } from 'antd';
import { useContext, useState } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { colors } from '../../constants/colors';
import { AppRoutes } from '../../constants/routes';
import { AuthenticationContext } from '../../contexts';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { IUserDto } from '../../dtos';
import userManagementService from '../../services/user-management.service';
import { UserApps, UserRights, UserSubSystemsApps } from '../../types';
import { hasRight } from '../../utils';
import { notifySuccess } from '../../utils/notification-messages';
import { ComponentGuard } from '../component-guard';
import { TableControls } from '../table-controls';
import { UserPermissionsTable } from '../user-permissions-table';
import { modalConfirm } from '../modal-confirm';

export const UsersTable: React.FC<TableProps<any>> = () => {
	const { t } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { errorHandler, applicationIdMap } = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user, administrationPermessions } = authContext;
	const [visibleRights, setVisibleRights] = useState<boolean>(false);
	const [selectedUser, setSelectedUser] = useState<string>();
	const navigate = useNavigate();

	// get users
	const {
		data: users,
		isLoading: usersLoading,
		refetch: refetchUsers
	} = useQuery({
		queryKey: ['users'],
		queryFn: () => userManagementService.getUsers()
	});

	const userTableColumns: TableColumnsType<IUserDto> = [
		{
			title: t('Name'),
			dataIndex: 'fullname',
			key: 'fullname',
			align: 'center',
			render: (text: string) => text
		},

		{
			title: t('Email'),
			dataIndex: 'email',
			key: 'email',
			align: 'center',
			render: (text: string) => text ?? t('NA', { ns: 'common' })
		},
		{
			title: t('PhoneNumbe'),
			dataIndex: 'phonenumber',
			key: 'phonenumber',
			align: 'center',
			render: (text: string) => text ?? t('NA', { ns: 'common' })
		},
		{
			title: t('username'),
			dataIndex: 'username',
			key: 'username',
			align: 'center',
			render: (text: string) => text ?? t('NA', { ns: 'common' })
		},
		{
			title: t('nid'),
			dataIndex: 'nid',
			key: 'nid',
			align: 'center',
			render: (text: string) => text ?? t('NA', { ns: 'common' })
		},
		{
			title: t('Rights'),
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			render: (text: string) => (
				<MdOutlineAdminPanelSettings
					size={20}
					color={colors.WAI_BLUE}
					role="button"
					onClick={() => {
						setSelectedUser(text);
						setVisibleRights(true);
					}}
				/>
			)
		}
	];

	userTableColumns.push({
		title: t('Actions'),
		key: 'actions',
		align: 'center',
		render: (_: any, record: any) => (
			<TableControls
				onEdit={() => navigate(`${AppRoutes.USERS_CONFIGURATION}?userId=${record.id}`)}
				onRemove={() => onRemove(record.id)}
				userApp={UserApps.USERS}
				admin={true}
			/>
		)
	});

	const onRemove = (id: string) => {
		try {
			modalConfirm({
				content: t('YouAreAboutToDeleteUser'),
				onOk: async () => {
					await userManagementService.deleteUser(id);
					notifySuccess(t('RemovedSuccessfully'));
					refetchUsers();
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	return (
		<>
			<Spin spinning={usersLoading}>
				<Table
					tableLayout="auto"
					scroll={{ x: true }}
					columns={userTableColumns}
					dataSource={users ?? undefined}
					size="small"
					showSorterTooltip
					rowKey="id"
				/>
			</Spin>
			<ComponentGuard
				allowed={hasRight(
					!!user?.user_data?.is_superAdmin,
					administrationPermessions,
					applicationIdMap.get(UserApps.USERS),
					UserRights.CREATE
				)}
			>
				<div className="d-flex justify-content-end mt-3">
					<Link to={AppRoutes.USERS_CONFIGURATION}>
						<Button type="primary">{t('AddUser')}</Button>
					</Link>
				</div>
			</ComponentGuard>
			<Modal
				key={selectedUser}
				open={visibleRights}
				title={t('Rights')}
				footer={[
					<ComponentGuard
						allowed={hasRight(
							!!user?.user_data?.is_superAdmin,
							administrationPermessions,
							applicationIdMap.get(UserApps.USER_PERMISSIONS),
							UserRights.CREATE
						)}
						key={selectedUser}
					>
						<Link to={`${AppRoutes.USER_PERMISSION}?userId=${selectedUser}`}>
							<Button
								icon={<AiOutlineEdit color={colors.WAI_BLUE} />}
								style={{ color: colors.WAI_BLUE, borderColor: colors.WAI_BLUE }}
								className={'mx-2'}
							>
								{t('Add', { ns: 'common' })}
							</Button>
						</Link>
					</ComponentGuard>,
					<Button
						key={selectedUser + '-'}
						type="primary"
						onClick={() => {
							setVisibleRights(false);
						}}
					>
						{t('Close', { ns: 'common' })}
					</Button>
				]}
				onCancel={() => {
					setVisibleRights(false);
				}}
			>
				<UserPermissionsTable selectedUser={selectedUser} />
			</Modal>
		</>
	);
};
