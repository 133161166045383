import configProxy from '../config/config';
import ResourceManager from '../network/resource-manager';
import { IAlertStats } from '../types/alerts';

class AlertsService extends ResourceManager {
	async getAlerts(params?: any, options?: any) {
		return await this.getResource(configProxy.URIs.get_alerts, {
			...(options || {}),
			params
		});
	}
	async getAllAlerts(params?: any, options?: any) {
		return await this.getResource(configProxy.URIs.get_all_alerts, {
			...(options || {}),
			params
		});
	}
	async getAlertsNew(params?: any, options?: any) {
		return await this.getResource(configProxy.URIs.get_alerts_new, {
			...(options || {}),
			params
		});
	}
	async getAlertNew(alertId: string) {
		const url = configProxy.URIs.get_alert_new.replace(/:alertId/g, alertId);
		return await this.getResource<any>(url);
	}
	async getAlert(alertId: string) {
		const url = configProxy.URIs.get_alert.replace(/:alertId/g, alertId);
		return await this.getResource<any>(url);
	}
	async editAlertNew(alertId: string, data: any) {
		const url = configProxy.URIs.get_alert_new.replace(/:alertId/g, alertId);
		return await this.patchResource<any, any>(url, data);
	}
	async getNetworksAlertsStats(networkId: string) {
		const url = configProxy.URIs.get_network_alert_stats.replace(/:networkId/g, networkId);
		return await this.getResource<IAlertStats>(url);
	}
	async getZonesAlertsStats(zoneId: string) {
		const url = configProxy.URIs.get_zone_alert_stats.replace(/:zoneId/g, zoneId);
		return await this.getResource<IAlertStats>(url);
	}
	async getAlertsStats(params?: any, options?: any) {
		return await this.getResource(configProxy.URIs.get_alert_stats, {
			...(options || {}),
			params
		});
	}
	async getAlertTypes(params?: any, options?: any) {
		return await this.getResource(configProxy.URIs.get_alert_type, {
			...(options || {}),
			params
		});
	}
	async getNetworkAlertConfigs(networkId: string) {
		const url = configProxy.URIs.network_alert_configs.replace(/:networkId/g, networkId);
		return await this.getResource<any>(url);
	}
	async createNetworkAlertConfig(networkId: string, data: any) {
		const url = configProxy.URIs.network_alert_configs.replace(/:networkId/g, networkId);
		return await this.postResource<any, any>(url, data);
	}
	async getZoneAlertConfigs(zoneId: string) {
		const url = configProxy.URIs.zone_alert_configs.replace(/:zoneId/g, zoneId);
		return await this.getResource<any>(url);
	}
	async createZoneAlertConfig(zoneId: string, data: any) {
		const url = configProxy.URIs.zone_alert_configs.replace(/:zoneId/g, zoneId);
		return await this.postResource<any, any>(url, data);
	}
	async getSitesAlertConfigs(siteId: string) {
		const url = configProxy.URIs.site_alert_configs.replace(/:siteId/g, siteId);
		return await this.getResource<any>(url);
	}
	async createSiteAlertConfig(siteId: string, data: any) {
		const url = configProxy.URIs.site_alert_configs.replace(/:siteId/g, siteId);
		return await this.postResource<any, any>(url, data);
	}
	async getDataSourcesAlertConfigs(dataSourceId: string) {
		const url = configProxy.URIs.data_source_alert_configs.replace(/:dataSourceId/g, dataSourceId);
		return this.getResource<any>(url);
	}
	async createDataSourceAlertConfig(dataSourceId: string, data: any) {
		const url = configProxy.URIs.data_source_alert_configs.replace(/:dataSourceId/g, dataSourceId);
		return this.postResource<any, any>(url, data);
	}
	async getAlertConfigs(params?: any, options?: any) {
		return await this.getResource(configProxy.URIs.alert_configs, {
			...(options || {}),
			params
		});
	}
	async getAlertConfig(alertId: string) {
		const url = configProxy.URIs.alert_configs.replace(/:alertId/g, alertId);
		return this.getResource<any>(url);
	}
	async editAlertConfig(alertId: string, data: any) {
		const url = configProxy.URIs.alert_config.replace(/:alertId/g, alertId);
		return this.putResource<any, any>(url, data);
	}
	async deleteAlertConfig(alertId: string) {
		const url = configProxy.URIs.alert_config.replace(/:alertId/g, alertId);
		return this.deleteResource<any>(url);
	}
}

export default new AlertsService();
