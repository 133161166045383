import { useState, useEffect } from 'react';

export function useFullScreen(element, deps = null) {
	const [isFullcreen, setFullScreen] = useState(false);

	const exitHandler = e => {
		if (
			!(
				document.webkitIsFullScreen ||
				document.mozFullScreen ||
				document.fullscreenElement ||
				document.mozFullScreenElement ||
				document.webkitFullscreenElement
			)
		) {
			setFullScreen(false);
		}
	};

	useEffect(
		() => {
			document.addEventListener('fullscreenchange', exitHandler, false);
			document.addEventListener('mozfullscreenchange', exitHandler, false);
			document.addEventListener('MSFullscreenChange', exitHandler, false);
			document.addEventListener('webkitfullscreenchange', exitHandler, false);
			return () => {
				document.removeEventListener('fullscreenchange', exitHandler);
				document.removeEventListener('mozfullscreenchange', exitHandler);
				document.removeEventListener('MSFullscreenChange', exitHandler);
				document.removeEventListener('webkitfullscreenchange', exitHandler);
			};
		},
		Array.isArray(deps) ? deps : []
	);

	const toggleFullScreen = () => {
		if (isFullcreen) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
			setFullScreen(false);
		} else {
			if (element.requestFullscreen) {
				element.requestFullscreen();
			} else if (element.mozRequestFullScreen) {
				element.mozRequestFullScreen();
			} else if (element.webkitRequestFullscreen) {
				element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
			} else if (element.msRequestFullscreen) {
				element.msRequestFullscreen();
			}
			setFullScreen(true);
		}
	};

	return [isFullcreen, toggleFullScreen];
}
