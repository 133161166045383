import { useQuery } from '@tanstack/react-query';
import { Breadcrumb, Col, Row, Spin, Tabs } from 'antd';
import { useState } from 'react';
import { MonitoringMap } from '../containers';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../hooks/use-query';
import monitoringService from '../services/monitoring.service';
import { AppRoutes } from '../constants/routes';
import { TiHomeOutline } from 'react-icons/ti';
import { upperSnakeToCapitalized } from '../utils';
import { useNavigate } from 'react-router-dom';
import { CommercialSiteForm } from '../components';

export const CommercialSiteConfigurationPage: React.FC<any> = () => {
	const { t, i18n } = useTranslation();

	const [selectedLocation, setSelectedLocation] = useState<any>({ latitude: undefined, longitude: undefined });

	const commercialSiteId = useQueryParam('commercialSiteId');
	const navigate = useNavigate();

	// load commercial meter site data
	const {
		data: commercialSiteData,
		isLoading: loadingCommercialSiteData,
		isFetching: fetchingCommercialSiteData,
		refetch: refetchCommercialSiteData
	} = useQuery<any>({
		queryKey: ['commercial-site', commercialSiteId],
		queryFn: () => commercialSiteId && monitoringService.getCommercialSite(commercialSiteId),
		enabled: !!commercialSiteId
	});

	const tabItems = [
		{
			label: t('CommercialSiteForm'),
			key: 'commercial-site-form',
			children: (
				<CommercialSiteForm
					setSelectedLocation={setSelectedLocation}
					selectedLocation={selectedLocation}
					commercialSiteData={commercialSiteData}
					refetchCommercialSiteData={refetchCommercialSiteData}
				/>
			)
		}
	];

	return (
		<>
			<div
				style={{
					paddingLeft: i18n.language === 'en' ? '1rem' : '',
					paddingRight: i18n.language === 'en' ? '' : '1rem',
					paddingTop: '2rem'
				}}
			>
				<Breadcrumb
					className="mb-3"
					style={{ fontSize: '1rem' }}
					items={[
						{
							title: (
								<div
									className="d-flex align-items-center"
									role="button"
									onClick={() => navigate(`${AppRoutes.CONFIGURATION}?tabSelected=zones`)}
								>
									<TiHomeOutline className={i18n.language === 'en' ? 'me-1' : 'ms-1'} />
									<span>{t('ConfigurationName', { name: t('Zones') })}</span>
								</div>
							)
						},
						{
							title: (
								<div style={{ fontWeight: 'bold' }} className="d-flex align-items-center">
									{t('ConfigurationName', {
										name: `${t('CommercialSite')}  ${
											commercialSiteData
												? i18n.language === 'en'
													? upperSnakeToCapitalized(commercialSiteData.name_en)
													: commercialSiteData.name_ar
												: ''
										}`
									})}
								</div>
							)
						}
					]}
					separator=">"
				/>
				<Row gutter={24}>
					<Col xs={24} md={14}>
						<Spin spinning={loadingCommercialSiteData && fetchingCommercialSiteData}>
							<Tabs type="card" style={{ fontWeight: '500' }} items={tabItems} />
						</Spin>
					</Col>
					<Col xs={24} md={10}>
						<MonitoringMap
							style={{
								height: '590px',
								width: 'auto',
								borderRadius: '13px',
								zIndex: 50
							}}
							pointMarker={
								selectedLocation.latitude && selectedLocation.longitude
									? [selectedLocation.latitude, selectedLocation.longitude]
									: undefined
							}
						/>
					</Col>
				</Row>
			</div>
		</>
	);
};
