import { Col, Empty, Row, Spin } from 'antd';
import { useContext, useState } from 'react';
import { useQuery, useQueries } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import chartsService from '../services/charts.service';
import { FlowRateType, GraphSpan, SpanType } from '../types';
import { FlowRateUnit } from '../types/flow-rate-unit';
import { clearArrayUndefined } from '../utils/clean-array-undefined';
import { WaiNumericalChart } from './numerical-chart';
import { WaiTimeSeriesChart } from './wai-time-series';
import { mean } from 'stats-lite';
import { capitalizeWords } from '../utils/capitalize-string-words';
import { customSpanSelector } from '../types/custom-span-selector';

export const MeasurementPointAnalysisMNFTab: React.FC<{
	flowRateSource: any;
	calculatedFlowRateSource: any;
	measurementPointName: string;
}> = ({ flowRateSource, calculatedFlowRateSource, measurementPointName }) => {
	const { t } = useTranslation();

	const [chartDateMinFlow, setChartDateMinFlow] = useState<{ start?: string; date?: string }>();
	const [chartTimeSpanMinFlow, setChartTimeSpanMinFlow] = useState<{ start: string; end: string }>();
	const [spanType, setSpanType] = useState<SpanType>(SpanType.DAY);

	const navigationContext = useContext(NavigationContext);
	const { selectedMeasurementPoint } = navigationContext;

	// Last MNF readings for day, week, month
	const minNightlyFlowLastDates: any = useQueries<any>({
		queries: (Object.keys(customSpanSelector) as (keyof typeof customSpanSelector)[]).map((span: any) => {
			return {
				queryKey: ['MNFLastReading', selectedMeasurementPoint, span],
				queryFn: () =>
					chartsService.getMeasurementPointMinNightlyFlow(selectedMeasurementPoint, {
						last: true,
						span_type: span.toLowerCase()
					}),
				enabled: !!selectedMeasurementPoint && !!span
			};
		})
	});
	// MNF Reading relative to numerical chart
	const { data: minNightlyFlowDateChart, isLoading: loadingMNFDate } = useQuery<any>({
		queryKey: [
			'MNFData',
			selectedMeasurementPoint,
			spanType,
			chartDateMinFlow,
			minNightlyFlowLastDates[Object.values(SpanType).indexOf(spanType)]?.data
		],
		queryFn: () =>
			chartsService.getMeasurementPointMinNightlyFlow(selectedMeasurementPoint, {
				start:
					spanType === SpanType.DAY
						? undefined
						: chartDateMinFlow?.start
						? dayjs(chartDateMinFlow.start).format('YYYY-MM-DD')
						: minNightlyFlowLastDates[Object.values(SpanType).indexOf(spanType)]?.data?.start &&
						  dayjs(minNightlyFlowLastDates[Object.values(SpanType).indexOf(spanType)]?.data?.start).format(
								'YYYY-MM-DD'
						  ),
				date:
					spanType !== SpanType.DAY
						? undefined
						: chartDateMinFlow?.date
						? dayjs(chartDateMinFlow.date).format('YYYY-MM-DD')
						: minNightlyFlowLastDates[0]?.data?.data?.length
						? dayjs(minNightlyFlowLastDates[0]?.data?.data[0]?.time).format('YYYY-MM-DD')
						: undefined,
				span_type: spanType
			}),
		enabled: (!!chartDateMinFlow || !!minNightlyFlowLastDates) && !!selectedMeasurementPoint && !!spanType
	});

	// All flowrate Readings
	const {
		data: allFlowRateLogs,
		isLoading: loadingAllFlowRateLogs,
		isFetching: fetchingAllFlowRateLogs
	} = useQuery<any>({
		queryKey: ['data-source-logs', flowRateSource?.id ?? calculatedFlowRateSource?.id],
		queryFn: () =>
			chartsService.getDSLogs(flowRateSource?.id ?? calculatedFlowRateSource?.id, {
				aggregate: 1
			}),
		enabled: !!flowRateSource?.id || !!calculatedFlowRateSource?.id
	});
	// MNF Readings relative to line chart over a period of days
	const { data: minNightlyFlowTimeSpanChart } = useQuery<any>({
		queryKey: ['MNFData', chartTimeSpanMinFlow, selectedMeasurementPoint],
		queryFn: () =>
			chartsService.getMeasurementPointMinNightlyFlow(selectedMeasurementPoint, {
				start: chartTimeSpanMinFlow ? dayjs(chartTimeSpanMinFlow.start).format('YYYY-MM-DD') : undefined,
				end: chartTimeSpanMinFlow ? dayjs(chartTimeSpanMinFlow.end).format('YYYY-MM-DD') : undefined,
				span_type: SpanType.DAY
			}),
		enabled: !!selectedMeasurementPoint
	});
	// Flowrate Readings relative to line chart over a period of days
	const { data: flowrateTimeSpanChart, isLoading: loadingMNFChart } = useQuery<any>({
		queryKey: ['data-source-logs', chartTimeSpanMinFlow, flowRateSource?.id ?? calculatedFlowRateSource?.id],
		queryFn: () =>
			chartsService.getDSLogs(flowRateSource?.id ?? calculatedFlowRateSource?.id, {
				start: chartTimeSpanMinFlow?.start,
				end: chartTimeSpanMinFlow?.end,
				aggregate:
					chartTimeSpanMinFlow?.start &&
					dayjs(chartTimeSpanMinFlow?.end).diff(chartTimeSpanMinFlow?.start, 'd') === 1
						? undefined
						: 1,
				mnf: true
			}),
		enabled: !!flowRateSource?.id || !!calculatedFlowRateSource?.id
	});

	return (
		<>
			<Row>
				<Col xs={24} className={'mb-2'}>
					<Spin spinning={loadingMNFDate}>
						<WaiNumericalChart
							number={
								minNightlyFlowDateChart && minNightlyFlowDateChart?.data?.length > 0 ? (
									parseFloat(minNightlyFlowDateChart?.data[0].value).toFixed(2)
								) : minNightlyFlowDateChart?.average ? (
									parseFloat(minNightlyFlowDateChart?.average).toFixed(2)
								) : (
									<Empty description={t('NoAvailableData')} />
								)
							}
							title={
								(spanType === SpanType.DAY &&
									minNightlyFlowLastDates[0]?.data &&
									capitalizeWords(minNightlyFlowLastDates[0]?.data.name)) ||
								t('AverageMinimumNightlyFlow')
							}
							unit={
								(((minNightlyFlowDateChart?.data?.length > 0 &&
									minNightlyFlowDateChart?.data[0]?.value) ||
									minNightlyFlowDateChart?.average) &&
									minNightlyFlowDateChart?.unit) ||
								undefined
							}
							maxWidth="100%"
							dateText={
								spanType === SpanType.DAY && minNightlyFlowLastDates[0].data?.data.length > 0
									? chartDateMinFlow?.date ===
											dayjs(minNightlyFlowLastDates[0].data.data[0].time).format('YYYY-MM-DD') ||
									  chartDateMinFlow?.date === undefined
										? t('LastReadingAtDate', {
												date: dayjs(minNightlyFlowLastDates[0].data?.data[0].time).format(
													'Do MMM YYYY'
												)
										  })
										: t('ReadingAtDate', {
												date: dayjs(chartDateMinFlow?.date).format('Do MMM YYYY')
										  })
									: spanType === SpanType.WEEK && minNightlyFlowLastDates[1].data?.start
									? chartDateMinFlow?.start === minNightlyFlowLastDates[1].data.start
										? t('LastReadingAtDate', {
												date: `${dayjs(minNightlyFlowLastDates[1].data.start).format(
													'DD MMM'
												)}:${dayjs(minNightlyFlowLastDates[1].data.start)
													.endOf(SpanType.WEEK)
													.format('DD MMM YYYY')}`
										  })
										: t('ReadingAtDate', {
												date: `${dayjs(chartDateMinFlow?.start).format('DD MMM')}:${dayjs(
													chartDateMinFlow?.start
												)
													.endOf(SpanType.WEEK)
													.format('DD MMM YYYY')}`
										  })
									: minNightlyFlowLastDates[2].data?.start &&
									  (chartDateMinFlow?.start === minNightlyFlowLastDates[2].data.start
											? t('LastReadingAtDate', {
													date: dayjs(minNightlyFlowLastDates[2].data.start).format(
														'MMMM YYYY'
													)
											  })
											: t('ReadingAtDate', {
													date: dayjs(chartDateMinFlow?.start).format('MMMM YYYY')
											  }))
							}
							lastReadingsDates={{
								day:
									minNightlyFlowLastDates[0]?.data?.data &&
									minNightlyFlowLastDates[0]?.data?.data[0]?.time,

								week: minNightlyFlowLastDates[1]?.data?.start,
								month: minNightlyFlowLastDates[2]?.data?.start
							}}
							showCustomSelectorDate
							OnDateSelect={(date: any) => setChartDateMinFlow(date)}
							showSpanSelector
							spanType={spanType}
							setSpanType={setSpanType}
							percentage={minNightlyFlowDateChart?.preceding_reading?.percentage_change || undefined}
							increase={
								minNightlyFlowDateChart?.preceding_reading?.trend_direction === 'up' ? true : false
							}
							positive={
								minNightlyFlowDateChart?.preceding_reading?.trend_direction === 'up' ? false : true
							}
							customTimeUnits={[GraphSpan.DAY, GraphSpan.WEEK, GraphSpan.MONTH]}
							sourceName={measurementPointName}
						/>
					</Spin>
				</Col>
				<Col xs={24}>
					<Spin spinning={loadingMNFChart || (loadingAllFlowRateLogs && fetchingAllFlowRateLogs)}>
						<WaiTimeSeriesChart
							IDs={[
								{
									id: selectedMeasurementPoint,
									name: FlowRateType.MNF
								}
							]}
							title={
								!!calculatedFlowRateSource?.id
									? t('MinimumNightlyFlowRelativeToCalculatedFlowRate')
									: t('MinimumNightlyFlowRelativeToFlowRate')
							}
							height={55}
							graphHeight={550}
							showBrush
							showTabular
							onSpanSelect={(span: any) => setChartTimeSpanMinFlow(span)}
							lastReadingDate={
								minNightlyFlowLastDates[0]?.data?.data?.length &&
								minNightlyFlowLastDates[0]?.data.data[0]?.time
							}
							statistics={clearArrayUndefined(
								minNightlyFlowTimeSpanChart
									? [
											{
												name: t('Minimum'),
												value: Math.min(
													...minNightlyFlowTimeSpanChart.data.map((log: any) => +log.value)
												),
												unit: FlowRateUnit.CMD
											},
											{
												name: t('Maximum'),
												value: Math.max(
													...minNightlyFlowTimeSpanChart.data.map((log: any) => +log.value)
												),
												unit: FlowRateUnit.CMD
											},
											{
												name: t('Average'),
												value: mean(
													minNightlyFlowTimeSpanChart.data.map((log: any) => +log.value)
												),
												unit: FlowRateUnit.CMD
											}
									  ]
									: []
							)}
							minMax={
								allFlowRateLogs
									? {
											min: Math.min(...allFlowRateLogs.map((log: any) => +log.value)),
											max: Math.max(...allFlowRateLogs.map((log: any) => +log.value))
									  }
									: undefined
							}
							dataObjects={clearArrayUndefined([
								{
									id: minNightlyFlowTimeSpanChart?.id,
									name: t('value'),
									data: flowrateTimeSpanChart ?? [],
									allData: allFlowRateLogs ?? [],
									unit: FlowRateUnit.CMH,
									yAxis: {
										position: 'left',
										legend: t('FlowRateInCMH')
									}
								}
							])}
							showSpanSelector
							showCustomSpanSelector={true}
							alerts={
								minNightlyFlowTimeSpanChart
									? minNightlyFlowTimeSpanChart.data.map((mnf: any) => ({
											start: mnf.timestamp,
											end: mnf.timestamp,
											message: 'MNF'
									  }))
									: []
							}
							alarmLevels={clearArrayUndefined([
								{
									min: flowRateSource?.min_value_alarm,
									max: flowRateSource?.max_value_alarm,
									type: t('FlowRate')
								},
								{
									min: calculatedFlowRateSource?.min_value_alarm,
									max: calculatedFlowRateSource?.max_value_alarm,
									type: t('CalculatedFlowRate')
								}
							])}
							showTimeStep
							sourceName={measurementPointName}
						/>
					</Spin>
				</Col>
			</Row>
		</>
	);
};
