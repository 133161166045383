import { useTranslation } from 'react-i18next';
import { NetworkForm } from '../components';
import { useEffect, useState } from 'react';
import { useQueryParam } from '../hooks/use-query';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import monitoringService from '../services/monitoring.service';
import { Breadcrumb, Col, Collapse, Row, Spin } from 'antd';
import { AppRoutes } from '../constants/routes';
import { TiHomeOutline } from 'react-icons/ti';
import { upperSnakeToCapitalized } from '../utils';
import { MonitoringMap } from '../containers';
import alertsService from '../services/alerts.service';
import { AlertsConfiguration } from '../components/alerts-config';

export const NetworkConfigurationPage: React.FC<any> = () => {
	const { t, i18n } = useTranslation();

	const [selectedLocation, setSelectedLocation] = useState<any>({ latitude: undefined, longitude: undefined });
	const [activatedNetwork, setActivatedNetwork] = useState<boolean | null>(null);
	const [activePanel, setActivePanel] = useState<string[]>(['network_basic_info']);

	const networkId = useQueryParam('networkId');
	const panelSelected = useQueryParam('panelSelected');
	const navigate = useNavigate();

	// get selected network data
	const {
		data: networkData,
		isLoading: loadingNetworkData,
		refetch: refetchNetwork
	} = useQuery({
		queryKey: ['network', networkId],
		queryFn: () => networkId && monitoringService.getNetwork(networkId),
		enabled: !!networkId
	});

	// load network alerts configuration
	const {
		data: alertsConfig,
		isLoading: loadingAlertsConfig,
		refetch: refetchAlertsConfig
	} = useQuery<any>({
		queryKey: ['network-alerts-config', networkId],
		queryFn: () => networkId && alertsService.getNetworkAlertConfigs(networkId),
		enabled: !!networkId
	});

	// load alert types for network
	const { data: alertTypes, isLoading: loadingAlertTypes } = useQuery<any>({
		queryKey: ['network-alert-types'],
		queryFn: () => alertsService.getAlertTypes({ entity: 'network' }),
		enabled: true
	});

	// open panel if is selected
	useEffect(() => {
		if (panelSelected) {
			setActivePanel([panelSelected]);
		}
	}, []);

	// zoom to default network location
	useEffect(() => {
		if (networkData) {
			setSelectedLocation({
				latitude: networkData.latitude,
				longitude: networkData.longitude
			});
			setActivatedNetwork(networkData.is_active);
		}
	}, [networkData]);

	return (
		<div
			style={{
				paddingLeft: i18n.language === 'en' ? '1rem' : '',
				paddingRight: i18n.language === 'en' ? '' : '1rem',
				paddingTop: '2rem'
			}}
		>
			<Spin spinning={!!networkId && loadingNetworkData && loadingAlertTypes && loadingAlertsConfig}>
				<Breadcrumb
					style={{ fontSize: '1rem' }}
					className="mb-3"
					items={[
						{
							title: (
								<div
									className="d-flex align-items-center"
									role="button"
									onClick={() => navigate(`${AppRoutes.CONFIGURATION}?tabSelected=networks`)}
								>
									<TiHomeOutline className={i18n.language === 'en' ? 'me-1' : 'ms-1'} />
									<span>{t('NetworksConfiguration')}</span>
								</div>
							)
						},
						{
							title: (
								<div style={{ fontWeight: 'bold' }} className="d-flex align-items-center">
									{t('ConfigurationName', {
										name: networkData
											? i18n.language === 'en'
												? upperSnakeToCapitalized(networkData.name_en)
												: networkData.name_ar
											: t('Network')
									})}
								</div>
							)
						}
					]}
					separator=">"
				/>
				<Row gutter={24}>
					<Col xs={24} md={14}>
						<Collapse
							accordion
							activeKey={activePanel}
							onChange={(e: any) => {
								setActivePanel(e);
							}}
							size="large"
						>
							<Collapse.Panel
								key={'network_basic_info'}
								header={t('NetworkBasicInfo')}
								style={{ fontWeight: 'bold' }}
							>
								<NetworkForm
									selectedLocation={selectedLocation}
									setSelectedLocation={setSelectedLocation}
									networkData={networkData}
									refetchNetwork={refetchNetwork}
									activatedNetwork={activatedNetwork}
									setActivePanel={setActivePanel}
								/>
							</Collapse.Panel>
							{networkId ? (
								<>
									<Collapse.Panel
										key={'network_alerts_config'}
										header={t('NetworkAlertsConfiguration')}
										style={{ fontWeight: 'bold' }}
									>
										<AlertsConfiguration
											alertsConfig={alertsConfig}
											refechAlertsConfig={refetchAlertsConfig}
											alertTypes={alertTypes}
											createAlertConfig={async values =>
												networkId &&
												(await alertsService.createNetworkAlertConfig(networkId, values))
											}
										/>
									</Collapse.Panel>
								</>
							) : null}
						</Collapse>
					</Col>
					<Col xs={24} md={10}>
						<MonitoringMap
							style={{
								height: '590px',
								width: 'auto',
								borderRadius: '13px',
								zIndex: 50
							}}
							selectedNetwork={networkId}
							networks={networkData && [networkData]}
							pointMarker={
								selectedLocation.latitude && selectedLocation.longitude
									? [selectedLocation.latitude, selectedLocation.longitude]
									: undefined
							}
						/>
					</Col>
				</Row>
			</Spin>
		</div>
	);
};
