import dayjs from 'dayjs';
import { colors } from '../../constants/colors';
import { useTranslation } from 'react-i18next';
import { DataSources } from '../../types/data-sources';
import { upperSnakeToCapitalized } from '../../utils/upper-snake-to-capitalized';

export const DataSourcesMeasurementPoint: React.FC<{ dataSources: any }> = ({ dataSources }) => {
	const { t, i18n } = useTranslation();

	return (
		<div className="d-flex  flex-column bd-highlight justify-content-between" style={{ height: '60%' }}>
			<div className="d-flex flex-column bd-highlight mt-2">
				<div className={`d-flex align-items-start justify-content-start mx-2 my-2 bd-highlight`}>
					<div
						style={{
							color: colors.GREY,
							fontSize: '1rem'
						}}
						className={'d-flex flex-column align-items-start justify-content-start'}
					>
						{dataSources?.length > 0 && dataSources?.find(source => source.last_reading !== null) ? (
							<>
								<span>
									{t('LastReadingAt')} :{' '}
									{dayjs
										.max(
											dataSources &&
												dataSources
													?.filter(source => source.last_reading !== null)
													.map(source => {
														return (
															source?.last_reading?.time &&
															dayjs(source?.last_reading?.time)
														);
													})
										)
										?.format('MMM D, YYYY h:mm a')}
								</span>
							</>
						) : (
							<span>{t('NoReadDataYet')}</span>
						)}
					</div>
				</div>
				{dataSources &&
					dataSources?.map((source: any, idx: number) => {
						if (source?.type !== DataSources.NOISE)
							return (
								<div className="d-flex justify-content-between  align-items-center pe-2 mb-1" key={idx}>
									<h6 style={{}} className="mx-2 text-left mb-0">
										{source.type === DataSources.PRESSURE &&
											upperSnakeToCapitalized(source.stream_direction)
												.replace('stream', ' ')
												.replace('Stream', '')}

										{i18n.language === 'en'
											? source.type === DataSources.ACCUMULATED_FLOW
												? 'Acc Flowrate'
												: source.type === DataSources.CALCULATED_FLOW_RATE
												? 'Calc Flowrate'
												: upperSnakeToCapitalized(source.type)
											: upperSnakeToCapitalized(source.type)}
									</h6>
									{source?.last_reading ? (
										<div className="d-flex justify-content-end">
											<h5
												style={{
													fontSize: '1.5rem',
													color: colors.GRAPH_PURPLE,
													fontWeight: 400
												}}
												className="mx-3 mb-0"
											>
												{source?.last_reading?.value &&
													parseFloat(source?.last_reading?.value).toFixed(3)}
											</h5>
											<span style={{ color: colors.GREY, marginTop: '0.4rem' }}>
												{source?.log_unit === t('UNITLESS') ? '' : source?.log_unit}
											</span>
										</div>
									) : null}
								</div>
							);
					})}
			</div>
		</div>
	);
};
