import { useQuery } from '@tanstack/react-query';
import { Button, Spin, Table, TableProps } from 'antd';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { AuthenticationContext } from '../../contexts';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { transformNetworksToRecord } from '../../mappers/network-to-record';
import monitoringService from '../../services/monitoring.service';
import { UserSubSystemsApps, UserRights, UserApps } from '../../types';
import { INetworkRecord } from '../../types/network-record';
import { formatSorter } from '../../utils/get-table-sorter';
import { hasRight } from '../../utils/has-right';
import { notifySuccess } from '../../utils/notification-messages';
import { ComponentGuard } from '../component-guard';
import { TableControls } from '../table-controls';
import { modalConfirm } from '../modal-confirm';

export const NetworksTable: React.FC<TableProps<INetworkRecord>> = () => {
	const { t, i18n } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const {
		selectedNetwork,
		setSelectedMeasurementPoint,
		setSelectedZone,
		refetchNetworks,
		refetchAllZones,
		refetchAllMeasurementPoints,
		setSelectedNetwork,
		errorHandler,
		applicationIdMap
	} = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user, configurationPermessions } = authContext;
	const [networksSorters, setNetworksSorters] = useState<any>({});
	const navigate = useNavigate();

	// load networks
	const {
		data: networks,
		refetch: refetchNetworksConfig,
		isLoading: loadingNetworks
	} = useQuery<any>({
		queryKey:
			networksSorters && Object.keys(networksSorters)?.length > 0 ? ['networks', networksSorters] : ['networks'],
		queryFn: () =>
			monitoringService.getNetworks(
				networksSorters && Object.keys(networksSorters)?.length > 0
					? { sorters: { ...networksSorters } }
					: undefined
			)
	});

	// sort networks with: created_at, subscriber_no
	const onNetworksSort = async (sorters: any) => {
		if (JSON.stringify(sorters) === JSON.stringify(networksSorters)) return;
		{
			const sortedValues = Object.fromEntries(
				Object.entries(sorters).filter(([_, value]) => value !== undefined)
			);
			if (Object.keys(sortedValues).length > 0) {
				await setNetworksSorters(sortedValues);
				await refetchNetworksConfig();
			} else {
				await setNetworksSorters(undefined);
				await refetchNetworksConfig();
			}
		}
	};
	const handleTableChange = (newPagination: any, filters: any, sorters: any) => {
		onNetworksSort && onNetworksSort(formatSorter(sorters));
	};

	const tableColumns: any = [
		{
			title: t('Network'),
			dataIndex: i18n.language === 'en' ? 'name_en' : 'name_ar',
			key: i18n.language === 'en' ? 'name_en' : 'name_ar',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Description'),
			dataIndex: i18n.language === 'en' ? 'description_en' : 'description_ar',
			key: i18n.language === 'en' ? 'description_en' : 'description_ar',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Latitude'),
			dataIndex: 'latitude',
			key: 'latitude',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Longitude'),
			dataIndex: 'longitude',
			key: 'longitude',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('subscriber_no'),
			dataIndex: 'subscriber_no',
			key: 'subscriber_no',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: string) => text
		},
		{
			title: t('DateCreated'),
			dataIndex: 'created_at',
			key: 'created_at',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: Date) => dayjs(text).format('dddd, MMMM D, YYYY h:mm A')
		}
	];

	tableColumns.push({
		title: t('Actions'),
		key: 'actions',
		align: 'center',
		render: (_: any, record: INetworkRecord) => {
			return (
				<TableControls
					networkId={record.id.replace(/-/g, '')}
					onEdit={() => navigate(`${AppRoutes.NETWORK_CONFIGURATION}?networkId=${record.id}`)}
					onRemove={() => onRemove(record.id)}
					userApp={UserApps.NETWORK}
				/>
			);
		}
	});

	const onRemove = (id: string) => {
		try {
			modalConfirm({
				content: t('YouAreAboutToDeleteNetwork'),
				onOk: async () => {
					await monitoringService.deleteNetwork(id);
					if (id === selectedNetwork) {
						await setSelectedNetwork(undefined);
						await setSelectedZone(undefined);
						await setSelectedMeasurementPoint(undefined);
					}
					notifySuccess(t('RemovedSuccessfully'));
					refetchNetworks();
					refetchAllZones();
					refetchAllMeasurementPoints();
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};
	return (
		<>
			<Spin spinning={loadingNetworks}>
				<Table
					tableLayout="auto"
					scroll={{ x: true }}
					columns={tableColumns}
					dataSource={networks ? transformNetworksToRecord(networks) : []}
					size="small"
					showSorterTooltip
					rowKey="id"
					onChange={handleTableChange}
				/>
			</Spin>
			<ComponentGuard
				allowed={hasRight(
					!!user?.user_data?.is_superAdmin,
					configurationPermessions,
					applicationIdMap.get(UserApps.NETWORK),
					UserRights.CREATE
				)}
			>
				<div className="d-flex justify-content-end mt-3">
					<Link to={AppRoutes.NETWORK_CONFIGURATION}>
						<Button type="primary">{t('AddNetwork')}</Button>
					</Link>
				</div>
			</ComponentGuard>
		</>
	);
};
