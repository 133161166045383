import { useContext, useEffect, useState } from 'react';
import { Form, Input, InputNumber, Radio, Switch, Row, Col, Button, Select, Spin, Upload, DatePicker } from 'antd';
import { AiOutlinePlus, AiOutlineUpload } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { UserRights, UserApps } from '../../types';
import { hasRight } from '../../utils/has-right';
import { AuthenticationContext } from '../../contexts';
import { colors } from '../../constants/colors';
import { SubmitCanelButtons } from '../submit-cancel-buttons';
import { upperSnakeToCapitalized } from '../../utils';
import { StationOperationalStatus, StationType } from '../../types/water-station';
import { Sites } from '../../types/sites';
import dayjs from 'dayjs';

export const WaterStationForm: React.FC<any> = ({
	measurementPointData,
	setSelectedLocation,
	selectedLocation,
	changeNetwork,
	loadingZonesNetwork,
	zonesNetwork,
	selectedNetwork,
	beforeImageUpload,
	handleImageChange,
	handlePreview,
	beforeFileUpload,
	handleFileChange,
	imageList,
	setImageList,
	fileList,
	setFileList,
	deletedImages,
	setDeletedImages,
	deletedFiles,
	setDeletedFiles,
	activatedPoint,
	setActivatedPoint,
	onFinish
}) => {
	const { t, i18n } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { networks, applicationIdMap } = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user, configurationPermessions } = authContext;

	const [isFormChanged, setIsFormChanged] = useState<boolean>(false);

	const [form] = Form.useForm();

	// set values fields for the selected point
	useEffect(() => {
		if (measurementPointData && selectedNetwork) {
			resetFields();
		}
	}, [measurementPointData, selectedNetwork]);

	// reset fields
	const resetFields = () => {
		if (measurementPointData && selectedNetwork) {
			form.setFieldsValue({
				...measurementPointData,
				network: selectedNetwork,
				installation_date: dayjs(measurementPointData.installation_date)
			});
		}
		setIsFormChanged(false);
	};

	return (
		<>
			<Form
				form={form}
				layout="vertical"
				onFinish={values => onFinish(values, Sites.WATER_STATION)}
				initialValues={{
					is_active: false,
					water_station: {
						station_type: 'PUMPING_STATION',
						operational_status: 'NORMAL',
						capacity: 0,
						capacity_unit: 'CMPH'
					}
				}}
				onValuesChange={() => setIsFormChanged(true)}
			>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item
							name="network"
							label={t('RelatedNetwork')}
							rules={[{ required: true, message: t('SelectRelatedNetwork', { ns: 'validation' }) }]}
						>
							<Select
								style={{ fontWeight: 'normal' }}
								placeholder={t('RelatedNetwork')}
								options={
									networks &&
									networks
										.filter((network: any) =>
											measurementPointData
												? hasRight(
														!!user?.user_data?.is_superAdmin,
														configurationPermessions,
														applicationIdMap.get(UserApps.MEASUREMENT_POINT),
														UserRights.EDIT,
														network.id
												  )
												: hasRight(
														!!user?.user_data?.is_superAdmin,
														configurationPermessions,
														applicationIdMap.get(UserApps.MEASUREMENT_POINT),
														UserRights.CREATE,
														network.id
												  )
										)
										.map((network: any) => {
											return {
												value: network.id,
												label:
													i18n.language === 'en'
														? network.name_en
														: network?.name_ar || network.name_en
											};
										})
								}
								onChange={changeNetwork}
							/>
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Spin spinning={!!selectedNetwork && loadingZonesNetwork}>
							<Form.Item
								name="zone"
								label={t('RelatedZones')}
								rules={[{ required: true, message: t('SelectRelatedZones', { ns: 'validation' }) }]}
							>
								<Select
									style={{ fontWeight: 'normal' }}
									mode="multiple"
									allowClear
									placeholder={t('RelatedZones')}
									options={
										zonesNetwork &&
										zonesNetwork.map((zone: any) => {
											return {
												value: zone.id,
												label:
													i18n.language === 'en'
														? zone.name_en
														: zone?.name_ar || zone.name_en
											};
										})
									}
								/>
							</Form.Item>
						</Spin>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item
							name="name_en"
							label={t('EnglishName')}
							rules={[
								{
									required: true,
									message: t('AddMeasuremenPointNameInEnglish', { ns: 'validation' })
								}
							]}
						>
							<Input type="text" placeholder={t('MeasuremenPointNameInEnglish')} />
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item
							name="name_ar"
							label={t('ArabicName')}
							rules={[
								{
									required: true,
									message: t('AddMeasuremenPointNameInArabic', { ns: 'validation' })
								}
							]}
						>
							<Input type="text" placeholder={t('MeasuremenPointNameInArabic')} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item name="description_en" label={t('EnglishDescription')}>
							<Input.TextArea maxLength={255} placeholder={t('DescribePointInEnglish')} />
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item name="description_ar" label={t('ArabicDescription')}>
							<Input.TextArea maxLength={255} placeholder={t('DescribePointInArabic')} />
						</Form.Item>
					</Col>
				</Row>
				<Form.Item label={t('LatitudeAndLongitude')} style={{ marginBottom: 0 }}>
					<Row className="w-100">
						<Col xs={11}>
							<Form.Item
								name="latitude"
								rules={[{ required: true, message: t('PleaseAddLatitude', { ns: 'validation' }) }]}
							>
								<InputNumber
									placeholder={t('Latitude')}
									style={{ width: '100%' }}
									max={90}
									onChange={val => setSelectedLocation({ ...selectedLocation, latitude: val })}
								/>
							</Form.Item>
						</Col>
						<Col xs={{ span: 12, offset: 1 }}>
							<Form.Item
								name="longitude"
								rules={[{ required: true, message: t('PleaseAddLongitude', { ns: 'validation' }) }]}
							>
								<InputNumber
									placeholder={t('Longitude')}
									style={{ width: '100%' }}
									max={90}
									onChange={val => setSelectedLocation({ ...selectedLocation, longitude: val })}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form.Item>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item name="site_manager_name" label={t('siteManagerName')}>
							<Input type="text" placeholder={t('siteManagerName')} />
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item name="site_manager_phone" label={t('siteManagerPhone')}>
							<Input type="text" placeholder={t('siteManagerPhone')} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item name={['water_station', 'capacity']} label={t('capacity')}>
							<Input type="number" step={0.0001} placeholder={t('capacity')} />
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item name={['water_station', 'capacity_unit']} label={t('capacity_unit')}>
							<Input type="text" placeholder={t('capacity_unit')} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item name={['water_station', 'station_type']} label={t('station_type')}>
							<Select
								options={Object.values(StationType).map(type => {
									return {
										value: type,
										label: upperSnakeToCapitalized(type)
									};
								})}
							/>
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item name={['water_station', 'operational_status']} label={t('operational_status')}>
							<Select
								options={Object.values(StationOperationalStatus).map(status => {
									return {
										value: status,
										label: upperSnakeToCapitalized(status)
									};
								})}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item name="installation_date" label={t('installationDate')}>
							<DatePicker style={{ width: '100%' }} />
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item
							name="hydraulic_placement"
							label={t('HydraulicPlacement')}
							rules={[
								{
									required: true,
									message: t('PleaseSelectHydraulicPlacement', { ns: 'validation' })
								}
							]}
						>
							<Radio.Group style={{ fontWeight: 'normal' }}>
								<Radio value={t('INLET')}> {t('Inlet')} </Radio>
								<Radio value={t('OUTLET')}> {t('Outlet')} </Radio>
								<Radio value={t('MID')}> {t('Mid')} </Radio>
							</Radio.Group>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item name="is_active" label={t('ActivateMeasurementPoint')} valuePropName="checked">
							<Switch
								checked={activatedPoint ?? false}
								checkedChildren={t('Active')}
								unCheckedChildren={t('Inactive')}
								onChange={(value, event) => {
									event.stopPropagation();
									setActivatedPoint(value);
								}}
								style={{ backgroundColor: activatedPoint ? colors.HEALTHY : colors.ERROR }}
								className={'mx-2'}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item label={t('UploadImages')}>
							<Upload
								listType="picture-card"
								accept=".jpg , .jpeg, .png, .webp"
								fileList={imageList}
								beforeUpload={beforeImageUpload}
								onChange={handleImageChange}
								onPreview={handlePreview}
								onRemove={(file: any) => {
									if (file?.oldImage) {
										setDeletedImages([...deletedImages, file.uid]);
									}
									const index = imageList.indexOf(file);
									const newFileList = imageList.slice();
									newFileList.splice(index, 1);
									setImageList(newFileList);
								}}
								customRequest={({ file, onSuccess }) => {
									setTimeout(() => {
										onSuccess && onSuccess('ok');
									}, 0);
								}}
							>
								<div>
									<AiOutlinePlus />
									<div style={{ marginTop: 8 }}>{t('Upload')}</div>
								</div>
							</Upload>
							<p style={{ color: colors.INACTIVE }}>{t('SupportImages')}</p>
							<p style={{ color: colors.INACTIVE }}> {t('MaxSize')}</p>
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item label={t('UploadFiles')}>
							<Upload
								listType="text"
								accept=".pdf , .docx , .txt , .csv , .xlsx"
								fileList={fileList}
								beforeUpload={beforeFileUpload}
								onChange={handleFileChange}
								onRemove={(file: any) => {
									if (file?.oldFile) {
										setDeletedFiles([...deletedFiles, file.uid]);
									}
									const index = fileList.indexOf(file);
									const newFileList = fileList.slice();
									newFileList.splice(index, 1);
									setFileList(newFileList);
								}}
								customRequest={({ file, onSuccess }) => {
									setTimeout(() => {
										onSuccess && onSuccess('ok');
									}, 0);
								}}
							>
								<Button icon={<AiOutlineUpload />} type={'primary'} ghost>
									{t('Upload')}
								</Button>
								<div className="my-1">
									<small style={{ color: colors.INACTIVE, display: 'block' }}>
										{t('SupportFiles')}
									</small>
									<small style={{ color: colors.INACTIVE, display: 'block' }}>{t('MaxSize')}</small>
								</div>
							</Upload>
						</Form.Item>
					</Col>
				</Row>
				<div className="d-flex justify-content-end">
					<SubmitCanelButtons
						handleCancel={() => resetFields()}
						handleSubmit={form.submit}
						reset={true}
						disabled={!isFormChanged}
					/>
				</div>
			</Form>
		</>
	);
};
