import { Col, Empty, Row, Spin } from 'antd';
import { useContext, useState } from 'react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import chartsService from '../services/charts.service';
import { customSpanSelector, GraphSpan, SpanType, ZoneAnalysisFeatures } from '../types';
import dayjs from 'dayjs';
import { FlowRateUnit } from '../types/flow-rate-unit';
import { clearArrayUndefined } from '../utils/clean-array-undefined';
import { TimeSeriesBar } from './time-series-bar';
import { TimeSeriesMixed } from './time-series-mixed';
import { WaiNumericalChart } from './numerical-chart';
import { upperSnakeToCapitalized } from '../utils';
import { colors } from '../constants/colors';

export const ZoneLeakageAnalysisTab: React.FC<any> = ({ zoneName }) => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { selectedZone } = navigationContext;

	const [spanTypeNumericalChart, setSpanTypeNumericalChart] = useState<SpanType>(SpanType.DAY);
	const [spanTypeMixedChart, setSpanTypeMixedChart] = useState<SpanType>(SpanType.DAY);
	const [chartDateLeakageMNF, setChartDateLeakageMNF] = useState<{ start?: string; date?: string }>();
	const [chartTimeSpanLeakageMNF, setChartTimeSpanLeakageMNF] = useState<{
		start: string;
		end: string;
	}>();
	const [chartTimeSpanLeakageUFL, setChartTimeSpanLeakageUFL] = useState<{
		start: string;
		end: string;
	}>();

	// Last zone leakage mnf readings for day, week, month
	const lastDataLeakageMNFDates: any = useQueries<any>({
		queries: (Object.keys(customSpanSelector) as (keyof typeof customSpanSelector)[]).map((span: any) => {
			return {
				queryKey: ['zone-leakage-mnf-last', selectedZone, span],
				queryFn: () =>
					chartsService.getZoneLeakageMNF(selectedZone, {
						last: true,
						span_type: span.toLowerCase()
					}),
				enabled: !!selectedZone && !!span
			};
		})
	});
	// All zone leakage mnf readings regarding the for days, weeks, months calcutations
	const {
		data: allDataLeakageMNF,
		isLoading: loadingAllDataLeakageMNF,
		isFetching: fetchingAllDataLeakageMNF
	} = useQuery<any>({
		queryKey: ['zone-leakage-mnf', selectedZone, spanTypeMixedChart],
		queryFn: () => chartsService.getZoneLeakageMNF(selectedZone, { span_type: spanTypeMixedChart }),
		enabled: !!selectedZone && !!spanTypeMixedChart
	});
	// zone leakage mnf reading relative to numerical chart
	const {
		data: leakageMNFDateChart,
		isLoading: loadingLeakageMNFDateChart,
		isFetching: fetchingLeakageMNFDateChart
	} = useQuery<any>({
		queryKey: [
			'zone-leakage-mnf',
			selectedZone,
			spanTypeNumericalChart,
			chartDateLeakageMNF?.date ??
				lastDataLeakageMNFDates[Object.values(SpanType).indexOf(spanTypeNumericalChart)]?.data?.date
		],
		queryFn: () =>
			chartsService.getZoneLeakageMNF(selectedZone, {
				date: chartDateLeakageMNF?.date
					? dayjs(chartDateLeakageMNF.date).format('YYYY-MM-DD')
					: lastDataLeakageMNFDates[Object.values(SpanType).indexOf(spanTypeNumericalChart)]?.data?.date
					? dayjs(
							lastDataLeakageMNFDates[Object.values(SpanType).indexOf(spanTypeNumericalChart)].data.date
					  ).format('YYYY-MM-DD')
					: undefined,

				span_type: spanTypeNumericalChart
			}),
		enabled:
			!!selectedZone &&
			!!spanTypeNumericalChart &&
			!!(
				chartDateLeakageMNF?.date ??
				lastDataLeakageMNFDates[Object.values(SpanType).indexOf(spanTypeNumericalChart)]?.data?.date
			)
	});
	// zone legkage mnf readings relative to mixed chart over a period of days, weeks, months
	const {
		data: dataLeakageMNF,
		isLoading: loadingDataLeakageMNF,
		isFetching: fetchingDataLeakageMNF
	} = useQuery<any>({
		queryKey: ['zone-leakage-mnf', selectedZone, chartTimeSpanLeakageMNF, spanTypeMixedChart],
		queryFn: () =>
			chartsService.getZoneLeakageMNF(selectedZone, {
				start: chartTimeSpanLeakageMNF?.start
					? dayjs(chartTimeSpanLeakageMNF.start).format('YYYY-MM-DD')
					: undefined,
				end: chartTimeSpanLeakageMNF?.end ? dayjs(chartTimeSpanLeakageMNF.end).format('YYYY-MM-DD') : undefined,
				span_type: spanTypeMixedChart
			}),
		enabled:
			!!selectedZone && !!chartTimeSpanLeakageMNF?.start && !!chartTimeSpanLeakageMNF?.end && !!spanTypeMixedChart
	});

	// zone leakage ufl last reading regarding the days period
	const {
		data: lastDataLeakageUFL,
		isLoading: loadingLastDataLeakageUFL,
		isFetching: fetchingLastDataLeakageUFL
	} = useQuery<any>({
		queryKey: ['zone-leakage-ufl-last', selectedZone],
		queryFn: () => chartsService.getZoneLeakageUFL(selectedZone, { last: true }),
		enabled: !!selectedZone
	});
	// All zone leakage ufl readings regarding the days period
	const {
		data: allDataLeakageUFL,
		isLoading: loadingAllDataLeakageUFL,
		isFetching: fetchingAllDataLeakageUFL
	} = useQuery<any>({
		queryKey: ['zone-leakage-ufl', selectedZone],
		queryFn: () =>
			chartsService.getZoneLeakageUFL(selectedZone, {
				span_type: 'day'
			}),
		enabled: !!selectedZone
	});
	// zone legkage ufl readings relative to bar chart over a period of days
	const {
		data: dataLeakageUFL,
		isLoading: loadingDataLeakageUFL,
		isFetching: fetchingDataLeakageUFL
	} = useQuery<any>({
		queryKey: ['zone-leakage-ufl', selectedZone, chartTimeSpanLeakageUFL],
		queryFn: () =>
			chartsService.getZoneLeakageUFL(selectedZone, {
				start: chartTimeSpanLeakageUFL?.start
					? dayjs(chartTimeSpanLeakageUFL.start).format('YYYY-MM-DD')
					: undefined,
				end: chartTimeSpanLeakageUFL?.end ? dayjs(chartTimeSpanLeakageUFL.end).format('YYYY-MM-DD') : undefined,
				span_type: 'day'
			}),
		enabled: !!selectedZone
	});

	return (
		<>
			<Row>
				<Col xs={24} className={'mb-2'}>
					<Spin spinning={loadingLeakageMNFDateChart && fetchingLeakageMNFDateChart}>
						<WaiNumericalChart
							title={t('NightlowBreakdown')}
							otherText={
								leakageMNFDateChart && leakageMNFDateChart.length > 0 ? (
									<div className="d-flex justify-content-center align-items-center flex-column">
										<div className="d-flex justify-content-center align-items-center">
											<span
												style={{ fontSize: '1.5rem', color: colors.GRAPH_PURPLE }}
												className="mx-3"
											>
												{t('MinimumNightlyFlow')}
											</span>
											<span style={{ fontSize: '1rem', color: colors.GREY }}>
												{leakageMNFDateChart[0].minimum_nightly_flow
													? `${parseFloat(
															leakageMNFDateChart[0].minimum_nightly_flow
													  ).toFixed(2)} ${leakageMNFDateChart[0].unit ?? ''}`
													: '-'}
											</span>
										</div>
										<div className="d-flex justify-content-center align-items-center">
											<span
												style={{ fontSize: '1.5rem', color: colors.GRAPH_GREEN }}
												className="mx-3"
											>
												{t('NormalNightlyUse')}
											</span>
											<span style={{ fontSize: '1rem', color: colors.GREY }}>
												{leakageMNFDateChart[0].normal_night_use
													? `${parseFloat(leakageMNFDateChart[0].normal_night_use).toFixed(
															2
													  )} ${leakageMNFDateChart[0].unit ?? ''}`
													: '-'}
											</span>
										</div>
										<div className="d-flex justify-content-center align-items-center">
											<span
												style={{ fontSize: '1.5rem', color: colors.WARNING }}
												className="mx-3"
											>
												{t('BackgroundLeakage')}
											</span>
											<span style={{ fontSize: '1rem', color: colors.GREY }}>
												{leakageMNFDateChart[0].background_leakage
													? `${parseFloat(leakageMNFDateChart[0].background_leakage).toFixed(
															2
													  )} ${leakageMNFDateChart[0].unit ?? ''}`
													: '-'}
											</span>
										</div>
										<div className="d-flex justify-content-center align-items-center">
											<span
												style={{ fontSize: '1.5rem', color: colors.HEALTHY }}
												className="mx-3"
											>
												{t('UnaccountedForLeakage')}
											</span>
											<span style={{ fontSize: '1rem', color: colors.GREY }}>
												{leakageMNFDateChart[0].unaccounted_for_leakage
													? `${parseFloat(
															leakageMNFDateChart[0].unaccounted_for_leakage
													  ).toFixed(2)} ${leakageMNFDateChart[0].unit ?? ''}`
													: '-'}
											</span>
										</div>
										<span style={{ fontSize: '1rem', fontWeight: 'bolder', color: colors.GREY }}>
											{upperSnakeToCapitalized(
												spanTypeMixedChart === SpanType.DAY
													? t('DailyNightlowBreakdown')
													: spanTypeMixedChart === SpanType.WEEK
													? t('WeeklyNightlowBreakdown')
													: t('MonthlyNightlowBreakdown')
											)}{' '}
											{spanTypeNumericalChart !== SpanType.DAY ? 'statistics' : null}
										</span>
									</div>
								) : (
									<Empty description={t('NoAvailableData')} />
								)
							}
							maxWidth="100%"
							lastReadingsDates={{
								day: lastDataLeakageMNFDates[0]?.data?.date,
								week: lastDataLeakageMNFDates[1]?.data?.date,
								month: lastDataLeakageMNFDates[2]?.data?.date
							}}
							showCustomSelectorDate
							OnDateSelect={(date: any) => {
								setChartDateLeakageMNF(date);
							}}
							showSpanSelector
							spanType={spanTypeNumericalChart}
							setSpanType={setSpanTypeNumericalChart}
							customTimeUnits={[GraphSpan.DAY, GraphSpan.WEEK, GraphSpan.MONTH]}
							sourceName={zoneName}
						/>
					</Spin>
				</Col>
				<Col xs={24}>
					<Spin
						spinning={
							!selectedZone ||
							(loadingAllDataLeakageMNF && fetchingAllDataLeakageMNF) ||
							(loadingDataLeakageMNF && fetchingDataLeakageMNF)
						}
					>
						<TimeSeriesMixed
							IDs={[
								{
									id: selectedZone,
									name: ZoneAnalysisFeatures.leakageMNF
								}
							]}
							title={
								spanTypeMixedChart === SpanType.DAY
									? t('DailyNightlowBreakdown')
									: spanTypeMixedChart === SpanType.WEEK
									? t('WeeklyNightlowBreakdown')
									: t('MonthlyNightlowBreakdown')
							}
							sourceName={zoneName} // for the download title name
							height={55}
							graphHeight={550}
							showBrush
							showTabular
							onSpanSelect={(span: any) => setChartTimeSpanLeakageMNF(span)}
							lastReadingDate={lastDataLeakageMNFDates[0]?.data?.date}
							dataObjects={clearArrayUndefined([
								{
									id: lastDataLeakageMNFDates[0]?.data?.id,
									name: t('MinimumNightlyFlow'),
									data:
										dataLeakageMNF?.map(ele => {
											return {
												time: ele.date,
												value: ele.minimum_nightly_flow
											};
										}) ?? [],

									allData:
										allDataLeakageMNF?.map(ele => {
											return {
												time: ele.date,
												value: ele.unaccounted_for_leakage
											};
										}) ?? [],
									unit: lastDataLeakageMNFDates[0]?.data?.unit,
									yAxis: {
										position: 'left',
										legend: `${upperSnakeToCapitalized(t('MinimumNightlyFlow'))} 
										(${lastDataLeakageMNFDates[0]?.data?.unit})`
									}
								},
								{
									id: lastDataLeakageMNFDates[0]?.data?.id + '1',
									name: t('NormalNightlyUse'),
									data:
										dataLeakageMNF?.map(ele => {
											return {
												time: ele.date,
												value: ele.normal_night_use
											};
										}) ?? [],
									allData: [],
									unit: lastDataLeakageMNFDates[0]?.data?.unit,
									type: 'bar'
								},
								{
									id: lastDataLeakageMNFDates[0]?.data?.id + '2',
									name: t('BackgroundLeakage'),
									data:
										dataLeakageMNF?.map(ele => {
											return {
												time: ele.date,
												value: ele.background_leakage
											};
										}) ?? [],
									allData: [],
									unit: lastDataLeakageMNFDates[0]?.data?.unit,
									type: 'bar'
								},
								{
									id: lastDataLeakageMNFDates[0]?.data?.id + '3',
									name: t('UnaccountedForLeakage'),
									data:
										dataLeakageMNF?.map(ele => {
											return {
												time: ele.date,
												value: ele.unaccounted_for_leakage
											};
										}) ?? [],
									allData: [],
									unit: lastDataLeakageMNFDates[0]?.data?.unit,
									type: 'bar'
								}
							])}
							showSpanSelector
							showCustomSpanSelector={true}
							showTimeStep={true}
							spanType={spanTypeMixedChart}
							setSpanType={setSpanTypeMixedChart}
							customTimeUnits={[GraphSpan.DAY, GraphSpan.WEEK, GraphSpan.MONTH]}
							customRange={true}
						/>
					</Spin>
				</Col>
				<Col xs={24}>
					<Spin
						spinning={
							!selectedZone ||
							(loadingLastDataLeakageUFL && fetchingLastDataLeakageUFL) ||
							(loadingAllDataLeakageUFL && fetchingAllDataLeakageUFL) ||
							(loadingDataLeakageUFL && fetchingDataLeakageUFL)
						}
					>
						<TimeSeriesBar
							IDs={[
								{
									id: selectedZone,
									name: ZoneAnalysisFeatures.UFL
								}
							]}
							title={t('LeakageUFL')}
							sourceName={zoneName} // for the download title name
							height={55}
							graphHeight={550}
							showBrush
							showTabular
							onSpanSelect={(span: any) => setChartTimeSpanLeakageUFL(span)}
							lastReadingDate={lastDataLeakageUFL?.date}
							dataObjects={clearArrayUndefined([
								{
									id: lastDataLeakageUFL?.id,
									name: t('value'),
									data:
										dataLeakageUFL?.map(ele => {
											return {
												time: ele.date,
												value: ele.unaccounted_for_leakage
											};
										}) ?? [],
									allData:
										allDataLeakageUFL?.map(ele => {
											return {
												time: ele.date,
												value: ele.unaccounted_for_leakage
											};
										}) ?? [],
									unit: FlowRateUnit.CMD,
									yAxis: {
										position: 'left',
										legend: `${upperSnakeToCapitalized(t('LeakageUFL'))} ${
											lastDataLeakageUFL?.unit ? `(${lastDataLeakageUFL?.unit})` : ''
										}`
									}
								}
							])}
							showSpanSelector
							showCustomSpanSelector={true}
							showTimeStep={true}
						/>
					</Spin>
				</Col>
			</Row>
		</>
	);
};
