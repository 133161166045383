import { Segmented } from 'antd';
import { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NetworkZonesWaterProductionTab } from './network-zones-water-production-tab';
import { NetworkSitesWaterProductionTab } from './network-sites-water-production-tab';

export const NetworkWaterProductionTab: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
	const { t } = useTranslation();

	const [selectedSegment, setSelectedSegment] = useState<any>('zones');
	return (
		<>
			<Segmented
				options={[
					{ label: t('Zones'), value: 'zones' },
					{ label: t('Sites'), value: 'sites' }
				]}
				onChange={(value: any) => setSelectedSegment(value)}
				className="my-2"
				value={selectedSegment}
			/>
			{selectedSegment === 'zones' ? <NetworkZonesWaterProductionTab /> : <NetworkSitesWaterProductionTab />}
		</>
	);
};
