import { useContext, useState } from 'react';
import { Form, Row, Col, DatePicker, message, Select, Card, Spin, Steps, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { confirmUploadExcelFile } from '../../utils/handle-upload-excel-organization';
import monitoringService from '../../services/monitoring.service';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { AppRoutes } from '../../constants/routes';
import dayjs from 'dayjs';
import { CommercialMeterGroupUploadFileConfirmation } from '../commercial-meter-group-upload-file-confirmation';
import { hasRight } from '../../utils';
import { UserApps, UserRights } from '../../types';
import { AuthenticationContext } from '../../contexts';
import { LoadingOutlined } from '@ant-design/icons';
import { modalConfirm } from '../modal-confirm';
import { CommercialMeterFileUploadButton } from '../commercial-meter-file-upload-button';

export const UploadCommercialMeterGroupFile: React.FC<{
	commercialMeterGroupId?: any;
	selectedUploadData: any;
	setSelectedUploadData: any;
}> = ({ commercialMeterGroupId, selectedUploadData, setSelectedUploadData }) => {
	const { t, i18n } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { networks, errorHandler, refetchNetworks, refetchAllZones, refetchZones, applicationIdMap } =
		navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user, configurationPermessions } = authContext;

	const [selectedNetwork, setSelectedNetwork] = useState<any>();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [savedValues, setSavedValues] = useState<any>(undefined);
	const [excelData, setExcelData] = useState<any>();
	const [fileList, setFileList] = useState<any>([]);
	const [uploadedLogs, setUploadedLogs] = useState<any>(undefined);
	const [confirmLogs, setConfirmLogs] = useState<any>();

	const [currentStep, setCurrentStep] = useState<number>(0);

	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [form] = Form.useForm();

	const changeNetwork = (value: string) => {
		try {
			setSelectedNetwork && setSelectedNetwork(value);
		} catch (e: any) {
			errorHandler(e);
		}
	};

	// confirm before save adding commercial meter group
	const onConfirm = async (values: any) => {
		if (!fileList.length) {
			message.error(t('PleaseUploadFile', { ns: 'validation' }));
			return;
		}
		setSubmitting(true);
		setSavedValues(values);
		values.date = dayjs(values.date).startOf('M').format('YYYY-MM-DD');
		values.data = excelData;
		try {
			const resp: any = await confirmUploadExcelFile(values, commercialMeterGroupId, selectedNetwork);
			setSelectedUploadData(resp?.site_updates);
			setUploadedLogs(resp?.logs);
			setConfirmLogs({ successful: resp?.no_of_successful_logs, failed: resp?.no_of_failed_logs });

			// reset upload form and go to next step
			setFileList([]);
			form.resetFields();
			setCurrentStep(1);
		} catch (e: any) {
			errorHandler({ message: e.message_en } || e);
		}
		setSubmitting(false);
	};

	// submit edition or creation for commercial meter group
	const onFinishUploadFile = async (values: any) => {
		setSubmitting(true);

		// upload site data
		if (commercialMeterGroupId) {
			try {
				const resp = await monitoringService.editCommercialMeterGroupData(commercialMeterGroupId, values);
				if (!resp) throw new Error(t('couldntUpdateCommercialMeterGroup', { ns: 'validation' }));
				queryClient.refetchQueries(['sites']);
				message
					.success(resp?.message_en, 2)
					.then(() => message.success(t('NumberOfSuccessfulLogs') + resp?.no_of_successful_logs, 2))
					.then(() => message.success(t('NumberOfFailedLogs') + resp?.no_of_failed_logs, 2));
				navigate(`${AppRoutes.CONFIGURATION}?tabSelected=zones`);
			} catch (e: any) {
				errorHandler({ message: e.message_en } || e);
				setCurrentStep(0);
			}
		} else {
			try {
				const resp = await monitoringService.addCommercialMeterGroupData(selectedNetwork, values);
				if (!resp) throw new Error(t('couldntAddCommercialMeterGroup', { ns: 'validation' }));
				refetchNetworks();
				refetchAllZones();
				refetchZones();
				queryClient.refetchQueries(['sites']);
				message
					.success(resp?.message_en, 2)
					.then(() => message.success(t('NumberOfSuccessfulLogs') + resp?.no_of_successful_logs, 2))
					.then(() => message.success(t('NumberOfFailedLogs') + resp?.no_of_failed_logs, 2));
				navigate(`${AppRoutes.CONFIGURATION}?tabSelected=zones`);
			} catch (e: any) {
				errorHandler({ message: e.message_en } || e);
				setCurrentStep(0);
			}
		}
		setSubmitting(false);
	};
	const handleCancel = () => {
		navigate(`${AppRoutes.CONFIGURATION}?tabSelected=zones`);
	};
	return (
		<>
			<Card style={{ fontWeight: 'bold' }}>
				<Spin spinning={!!!networks}>
					<Steps
						current={currentStep}
						items={[
							{
								title: !commercialMeterGroupId ? t('CreateCommercialMeterGroup') : t('UploadFile'),
								subTitle: !commercialMeterGroupId ? t('withUploadedFile') : undefined,
								icon: submitting ? <LoadingOutlined /> : undefined
							},
							{
								title: t('Logs'),
								disabled: currentStep === 0
							},
							{
								title: t('SitesImportingStatus'),
								disabled: currentStep === 0
							}
						]}
						className="mb-2"
					/>
					{currentStep === 0 ? (
						<div className="mt-3">
							<Form
								form={form}
								onFinish={val => {
									onConfirm(val);
								}}
								layout="vertical"
							>
								<Row gutter={[16, 16]}>
									<Col xs={24} md={12}>
										<Row gutter={[16, 16]}>
											{!commercialMeterGroupId ? (
												<Col xs={12}>
													<Form.Item
														label={t('RelatedNetwork')}
														name="network"
														rules={[
															{
																required: true,
																message: t('SelectRelatedNetwork', { ns: 'validation' })
															}
														]}
													>
														<Select
															style={{ fontWeight: 'normal' }}
															placeholder={t('RelatedNetwork')}
															options={
																networks &&
																networks
																	.filter((network: any) =>
																		hasRight(
																			!!user?.user_data?.is_superAdmin,
																			configurationPermessions,
																			applicationIdMap.get(UserApps.ZONE),
																			UserRights.CREATE,
																			network.id
																		)
																	)
																	.map((network: any) => {
																		return {
																			value: network.id,
																			label:
																				i18n.language === 'en'
																					? network.name_en
																					: network?.name_ar ||
																					  network.name_en
																		};
																	})
															}
															onChange={changeNetwork}
															disabled={!!commercialMeterGroupId}
														/>
													</Form.Item>
												</Col>
											) : null}
											<Col xs={12}>
												<Form.Item
													name={'date'}
													label={t('Date')}
													rules={[
														{
															required: true,
															message: t('PleaseSelectDate', { ns: 'validation' })
														}
													]}
												>
													<DatePicker picker="month" style={{ width: '100%' }} />
												</Form.Item>
											</Col>
										</Row>
									</Col>

									<Col xs={24} md={12}>
										<Form.Item
											label={t('UploadFile')}
											rules={[
												{ required: true, message: t('PleaseUploadFile', { ns: 'validation' }) }
											]}
										>
											<CommercialMeterFileUploadButton
												fileList={fileList}
												setFileList={setFileList}
												setExcelData={setExcelData}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</div>
					) : (
						<CommercialMeterGroupUploadFileConfirmation
							currentStep={currentStep}
							submitting={submitting}
							selectedUploadData={selectedUploadData}
							uploadedLogs={uploadedLogs}
						/>
					)}
					<div style={{ marginTop: 24 }} className="d-flex justify-content-end">
						<Button type="ghost" onClick={handleCancel}>
							{t('Cancel', { ns: 'common' })}
						</Button>
						{currentStep > 0 && (
							<Button style={{ margin: '0 8px 0 0' }} onClick={() => setCurrentStep(currentStep - 1)}>
								{t('Previous')}
							</Button>
						)}
						{currentStep < 2 && (
							<Button
								type="primary"
								onClick={() => {
									if (currentStep === 0) {
										form.submit();
									} else {
										setCurrentStep(currentStep + 1);
									}
								}}
								disabled={submitting}
							>
								{t('Next')}
							</Button>
						)}
						{currentStep === 2 && (
							<Button
								type="primary"
								onClick={() => {
									try {
										modalConfirm({
											content: (
												<>
													<p>
														{t('NumberOfSuccessfulLogs')}{' '}
														{confirmLogs?.no_of_successful_logs}
													</p>
													<p>
														{t('NumberOfFailedLogs')} {confirmLogs?.no_of_failed_logs}
													</p>
												</>
											),
											onOk: async () => {
												await onFinishUploadFile(savedValues);
											}
										});
									} catch (e: any) {
										errorHandler(e);
									}
								}}
								loading={submitting}
							>
								{t('Save', { ns: 'common' })}
							</Button>
						)}
					</div>
				</Spin>
			</Card>
		</>
	);
};
