import { Col, Empty, Row } from 'antd';
import dayjs from 'dayjs';
import { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { Disabled, GraphCard } from '../components';
import { colors } from '../constants/colors';
import { SpanType } from '../types';
import { WaiPieChart } from './pie-chart';

export const CumulativeReadingsPieChart: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		cumulativeReadings: any[];
		lastReadingDate: any;
		OnDateSelect?: (date?: any) => void;
		title?: string;
	}
> = ({ cumulativeReadings, lastReadingDate, OnDateSelect, title }) => {
	const { t } = useTranslation();
	const [displayDate, setDisplayDate] = useState<any>();

	return (
		<Row gutter={[16, 16]}>
			<Col xs={24}>
				<GraphCard
					height={25}
					title={title ?? t('CumulativeReadings')}
					className="mb-4"
					lastReadingsDates={{
						month: lastReadingDate,
						day: lastReadingDate,
						week: lastReadingDate
					}}
					showCustomSelectorDate
					displayDate={displayDate}
					setDisplayDate={setDisplayDate}
					OnDateSelect={OnDateSelect}
					spanType={SpanType.MONTH}
				>
					<div className="d-flex justify-content-between align-items-center" style={{ height: '80%' }}>
						<div>
							<AiOutlineLeft
								size={'2rem'}
								style={{ strokeWidth: '30px' }}
								color={colors.GRAPH_PURPLE}
								className={'mb-4'}
								role={'button'}
								onClick={() => {
									setDisplayDate(
										displayDate
											? dayjs(displayDate).subtract(1, 'M').format('YYYY-MM-DD')
											: dayjs(lastReadingDate).subtract(1, 'M').format('YYYY-MM-DD')
									);
								}}
							/>
						</div>

						<div style={{ width: '90%' }}>
							{cumulativeReadings && Object.keys(cumulativeReadings).some(key => key !== 'الإجمالي') ? (
								<WaiPieChart
									maxWidth={'100%'}
									data={Object.entries(cumulativeReadings)
										.filter(([key, value]) => key !== 'الإجمالي')
										.map(([key, value]) => ({
											name: key,
											value
										}))}
								/>
							) : (
								<Empty description={t('NoData')} />
							)}
						</div>
						<div>
							{(displayDate ? dayjs(displayDate).endOf('M') : dayjs(lastReadingDate).endOf('M')) >=
							dayjs().endOf('M') ? (
								<Disabled tooltipTitle={t('CannotChooseFutureDate', { ns: 'validation' })}>
									<AiOutlineRight
										size={'2rem'}
										style={{ strokeWidth: '30px' }}
										color={colors.GRAPH_PURPLE}
										className={'mb-4'}
										role={'button'}
										onClick={() => {
											setDisplayDate(
												displayDate
													? dayjs(displayDate).add(1, 'M').format('YYYY-MM-DD')
													: dayjs(lastReadingDate).add(1, 'M').format('YYYY-MM-DD')
											);
										}}
									/>
								</Disabled>
							) : (
								<AiOutlineRight
									size={'2rem'}
									style={{ strokeWidth: '30px' }}
									color={colors.GRAPH_PURPLE}
									className={'mb-4'}
									role={'button'}
									onClick={() => {
										setDisplayDate(
											displayDate
												? dayjs(displayDate).add(1, 'M').format('YYYY-MM-DD')
												: dayjs(lastReadingDate).add(1, 'M').format('YYYY-MM-DD')
										);
									}}
								/>
							)}
						</div>
					</div>
				</GraphCard>
			</Col>
		</Row>
	);
};
