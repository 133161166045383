import { useQueries } from '@tanstack/react-query';
import { ConfigProvider, Spin } from 'antd';
import 'antd/dist/reset.css';
import ar_EG from 'antd/es/locale/ar_EG';
import en_US from 'antd/es/locale/en_US';
import 'bootstrap/dist/css/bootstrap.min.css';
import dayjs from 'dayjs';
import 'dayjs/locale/ar';
import 'dayjs/locale/en';
import minMax from 'dayjs/plugin/minMax';
import weekday from 'dayjs/plugin/weekday';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import './components/chatbot/styles/botpress-styles.css';
import waiWhiteLabel from './constants/wai-white-label';
import { AppRouter } from './containers';
import { AuthenticationContext } from './contexts';
import { BrandingLabelContext } from './contexts/branding-label';
import { GlobalStyles } from './global-styles';
import './leaflet.css';
import authService from './services/auth.service';
import userManagementService from './services/user-management.service';
import { parseJwt } from './utils/parse-jwt';

// localize English/Arabic data
i18n.use(initReactI18next)
	.use(LanguageDetector)
	.use(Backend)
	.init({
		lng: localStorage.getItem('i18nextLng') === 'ar' ? 'ar' : 'en' ?? 'en',
		fallbackLng: 'en',
		ns: ['translation', 'common', 'validation'],
		defaultNS: 'translation',
		interpolation: {
			escapeValue: false // avoid escaping HTML entities
		}
	});

// Set Saturday as the start of the week and localize for english/arabic
dayjs.extend(weekday);
dayjs.locale(localStorage.getItem('i18nextLng') === 'ar' ? 'ar' : 'en' ?? 'en');
dayjs.updateLocale(localStorage.getItem('i18nextLng') === 'ar' ? 'ar' : 'en' ?? 'en', {
	weekStart: 6
});

// extend min and max plugin
dayjs.extend(minMax);

function App() {
	const [authState, setAuthState] = useState<{ authenticated: boolean; token: string; user: any }>({
		authenticated: false,
		token: '',
		user: null
	});

	const setAuthenticated = (state: boolean, token?: string) => {
		if (token) authService.setAccessToken(token);
		setAuthState({
			authenticated: state,
			token: token || authState.token,
			user: token ? parseJwt(token) : parseJwt(authState.token)
		});
	};

	const addUserRights = (rights: any) => {
		setAuthState(prevState => ({
			...prevState,
			user: {
				...prevState.user,
				user_apps: [...prevState.user.user_apps, ...rights]
			}
		}));
	};
	useEffect(() => {
		(async () => {
			const auth = authService.isAuthenticated();
			setAuthenticated(auth.authenticated, auth.token);
		})();
	}, []);

	const queryKeys = [
		['monitoring-permissions', authState.user?.user_id],
		['alert-permissions', authState.user?.user_id],
		['analysis-permissions', authState.user?.user_id],
		['configuration-permissions', authState.user?.user_id],
		['control-permissions', authState.user?.user_id],
		['administration-permissions', authState.user?.user_id]
	];

	const queries = queryKeys.map(queryKey => {
		return {
			queryKey,
			queryFn: () =>
				userManagementService.getPermissions({
					user: authState.user?.user_id,
					subsystem: authState.user?.sub_systems?.find(
						sub =>
							sub.app__sub_system__displayName.toLowerCase() === queryKey[0].split('-')[0].toLowerCase()
					)?.id
				}),
			enabled: !!authState?.authenticated && !!authState?.user
		};
	});

	const queryResults = useQueries({ queries });

	const [
		monitoringPermessions,
		alertPermessions,
		analysisPermessions,
		configurationPermessions,
		controlPermessions,
		administrationPermessions
	] = queryResults.map(result => result.data);

	const [
		loadingMonitoringPermessions,
		loadingAlertPermessions,
		loadingAnalysisPermessions,
		loadingConfigurationPermessions,
		loadingControlPermessions,
		loadingAdministrationPermessions
	] = queryResults.map(result => result.isLoading);

	const [
		fetchingMonitoringPermessions,
		fetchingAlertPermessions,
		fetchingAnalysisPermessions,
		fetchingConfigurationPermessions,
		fetchingControlPermessions,
		fetchingAdministrationPermessions
	] = queryResults.map(result => result.isFetching);

	// loading page until get permissions
	if (
		authService.isAuthenticated().authenticated &&
		(!!!authState?.user ||
			(!!authState?.user &&
				((!monitoringPermessions && !(loadingMonitoringPermessions && fetchingMonitoringPermessions)) ||
					(!alertPermessions && !(loadingAlertPermessions && fetchingAlertPermessions)) ||
					(!analysisPermessions && !(loadingAnalysisPermessions && fetchingAnalysisPermessions)) ||
					(!configurationPermessions &&
						!(loadingConfigurationPermessions && fetchingConfigurationPermessions)) ||
					(!controlPermessions && !(loadingControlPermessions && fetchingControlPermessions)) ||
					(!administrationPermessions &&
						!(loadingAdministrationPermessions && fetchingAdministrationPermessions)))))
	) {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh'
				}}
			>
				<Spin size="large" />
			</div>
		);
	}

	return (
		<>
			<GlobalStyles label={waiWhiteLabel} language={i18n.language} />
			<BrandingLabelContext.Provider
				value={{
					label: waiWhiteLabel
				}}
			>
				<AuthenticationContext.Provider
					value={{
						authenticated: authState.authenticated,
						setAuthenticated,
						user: authState.user,
						addUserRights,
						monitoringPermessions,
						alertPermessions,
						analysisPermessions,
						configurationPermessions,
						controlPermessions,
						administrationPermessions
					}}
				>
					<ConfigProvider
						locale={i18n.language === 'en' ? en_US : ar_EG}
						direction={i18n.language === 'en' ? 'ltr' : 'rtl'}
						theme={{
							token: {
								//TODO: need to change to the sitesSideBaront
								//	fontFamily: "'Amiri', serif !important;"
							}
						}}
					>
						<AppRouter />
					</ConfigProvider>
				</AuthenticationContext.Provider>
			</BrandingLabelContext.Provider>
		</>
	);
}

export default App;

{
	/* <button onClick={() => window.botpressWebChat.sendEvent({ type: 'show' })}>Open Chat</button> */
}
