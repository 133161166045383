import { Table, TableProps } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { WaiAnalysisWidgetContainer, WaiAnalysisWidgetTitle } from '../components';
import { TableTag, TableTagType } from '../components/table-tag';
import { useTranslation } from 'react-i18next';
import { transformAlertsToRecord } from '../mappers/alert-to-record';
import { AlertPriority, AlertStatus, IAlertRecord } from '../types/alerts';
import { upperSnakeToCapitalized } from '../utils/upper-snake-to-capitalized';

const priorityToTagMapper = {
	[AlertPriority.URGENT]: 'error',
	[AlertPriority.HIGH]: 'warning',
	[AlertPriority.NORMAL]: 'ghost',
	[AlertPriority.INTERMEDIATE]: 'progress',
	[AlertPriority.LOW]: 'ghost'
};

const statusToTagMapper = {
	[AlertStatus.CANCELLED]: 'error',
	[AlertStatus.OPEN]: 'progress',
	[AlertStatus.CLOSED]: 'ghost',
	[AlertStatus.PENDING]: 'warning',
	[AlertStatus.RESOLVED]: 'success'
};

export const AlertsTableSummary: React.FC<
	TableProps<IAlertRecord> & {
		alerts: IAlertRecord[];
		loading?: boolean;
		measurementPointId?: string;
		zoneId?: string;
		networkId?: string;
	}
> = ({ alerts, loading, measurementPointId, zoneId, networkId }) => {
	const { t, i18n } = useTranslation();
	const tableColumns: any = [
		{
			title: <span className="text-align-center">{t('Name')}</span>,
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('AlertID'),
			dataIndex: 'alertId',
			key: 'alertId',
			align: 'center',
			render: (text: string) => (text.length === 0 ? t('NA', { ns: 'common' }) : text)
		},
		{
			title: t('Source'),
			dataIndex: 'source',
			key: 'source',
			align: 'center',
			render: (text: string) => upperSnakeToCapitalized(text)
		},
		{
			title: t('Priority'),
			dataIndex: 'priority',
			key: 'priority',
			align: 'center',
			render: (text: AlertPriority) =>
				text && (
					<TableTag type={priorityToTagMapper[text] as TableTagType}>
						{t(text.toLowerCase()).toUpperCase()}
					</TableTag>
				)
		},
		{
			title: t('AlertType'),
			dataIndex: 'alert_type',
			key: 'alert_type',
			align: 'center',
			render: (text: string) => upperSnakeToCapitalized(text)
		},
		{
			title: t('DateOfFirstEvent'),
			dataIndex: 'alertStart',
			key: 'alertStart',
			align: 'center',
			render: (text: Date) => dayjs(text).format('dddd, MMMM D, YYYY h:mm A')
		},
		{
			title: t('DateOfLastEvent'),
			dataIndex: 'alertEnd',
			key: 'alertEnd',
			align: 'center',
			render: (text: Date) => dayjs(text).format('dddd, MMMM D, YYYY h:mm A')
		},
		{
			title: t('ZonesAffected'),
			dataIndex: 'affected_zones',
			key: 'affected_zones',
			align: 'center',
			render: (text: []) => text || t('NA', { ns: 'common' })
		},
		{
			title: t('NetworksAffected'),
			dataIndex: 'affected_networks',
			key: 'affected_networks',
			align: 'center',
			render: (text: []) => text || t('NA', { ns: 'common' })
		},
		{
			title: t('Status', { ns: 'common' }),
			dataIndex: 'alert_status',
			key: 'alert_status',
			align: 'center',
			render: (text: AlertStatus) =>
				text && (
					<TableTag type={statusToTagMapper[text] as TableTagType}>
						{t(text.toLowerCase()).toUpperCase()}
					</TableTag>
				)
		}
		//no endpoints to edit or delete, also no ui for a form to edit from
	];
	return (
		<WaiAnalysisWidgetContainer>
			<div className="d-flex justify-content-between">
				<WaiAnalysisWidgetTitle>{t('recentAlerts')}</WaiAnalysisWidgetTitle>
				<Link
					to={
						measurementPointId
							? `/dashboard/alerts?measurementPoint=${measurementPointId}`
							: zoneId
							? `/dashboard/alerts?zone=${zoneId}`
							: networkId
							? `/dashboard/alerts?network=${networkId}`
							: '/dashboard/alerts'
					}
				>
					{t('seeAll')}
				</Link>
			</div>
			<Table
				tableLayout="auto"
				scroll={{ x: true }}
				columns={tableColumns}
				dataSource={transformAlertsToRecord(alerts, i18n.language === 'en') as IAlertRecord[]}
				pagination={false}
				size="small"
				loading={loading}
				rowKey="id"
			/>
		</WaiAnalysisWidgetContainer>
	);
};
