import { Col, Empty, Row, Spin, Tabs } from 'antd';
import { useContext, useEffect, useState } from 'react';
import {
	AnalysisTab,
	ComponentGuard,
	DocumentsCard,
	ImageGallery,
	MeasurementPointSpecs,
	MeasurementPointStaticInformation,
	Premium,
	WaiOperationStatus
} from '../components';
import { PDFViewer } from '../components/pdf-viewer';
import { WaiMaintenanceStatus } from '../components/wai-valve-maintenance';
import { WaiValveStatus } from '../components/wai-valve-status';
import configProxy from '../config/config';
import { colors } from '../constants/colors';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import { useMedia } from '../hooks/use-media.hook';
import alertsService from '../services/alerts.service';
import monitoringService from '../services/monitoring.service';
import { DataSources, PressureType, UserApps } from '../types';
import { AlertsTableSummary } from './alerts-table-summary';
import { LatestReadingsTable } from './latest-readings-table';
import { MonitoringMap } from './map';
import { FiCornerDownRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useQueryParam } from '../hooks/use-query';
import { MeasurementPointAnalysisDataSourcesTab } from './measurement-point-analysis-data-sources-tab';
import { MeasurementPointAnalysisMNFTab } from './measurement-point-analysis-mnf-tab';
import { UseQueryResult, useQueries } from '@tanstack/react-query';
import { clearArrayUndefined, hasApp } from '../utils';
import { AuthenticationContext } from '../contexts';
import { TbGauge } from 'react-icons/tb';
import { BsWater } from 'react-icons/bs';
import { TiFlowSwitch } from 'react-icons/ti';
import { Sites } from '../types/sites';
import { SiteAnalysisWaterConsumptionTab } from './site-analysis-water-consumption-tab';
import { SiteAnalysisWaterProductionTab } from './site-analysis-water-production-tab';
import { GiWaterTower } from 'react-icons/gi';

export const MeasurementPointAnalysis: React.FC<any> = () => {
	const { t, i18n } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const {
		networks,
		selectedNetwork,
		zones,
		selectedZone,
		onMeasurementPointSelect,
		selectedMeasurementPoint,
		applicationIdMap,
		measurementPoints
	} = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user } = authContext;

	const [pdfVisible, setPdfVisible] = useState<boolean>(false);
	const [pdfUrl, setPdfUrl] = useState<string>('');

	const media = configProxy.defaultExpandScreenSize;
	const desktopView = useMedia(media);
	const selectedTab = useQueryParam('selectedTab');
	const { analysisPermessions } = useContext(AuthenticationContext);

	// on change measurement point change the search params to the selected measurement point
	useEffect(() => {
		onMeasurementPointSelect(selectedMeasurementPoint);
	}, [selectedMeasurementPoint]);

	// loading data for selected measurement point
	const [
		{
			data: localSelectedMeasurementPoint,
			isLoading: loadingLocalSelectedMeasurementPoint,
			isFetching: fetchingingLocalSelectedMeasurementPoint
		},
		{
			data: selectedMeasurementPointLatestReadings,
			isLoading: loadingSelectedMeasurementPointLatestReadings,
			isFetching: fetchingSelectedMeasurementPointLatestReadings
		},
		{ data: dataSources, isLoading: loadingDataSources, isFetching: fetchingingDataSources },
		{ data: alerts, isLoading: loadingAlerts, isFetching: fetchingAlerts },
		{ data: images, isLoading: loadingImages, isFetching: fetchingImages },
		{ data: files, isLoading: loadingFiles, isFetching: fetchingFiles }
	]: UseQueryResult<any>[] = useQueries({
		queries: [
			{
				queryKey: ['localSelectedMeasurementPoint', selectedMeasurementPoint],
				queryFn: () => monitoringService.getMeasurementPoint(selectedMeasurementPoint),
				enabled: !!selectedMeasurementPoint
			},
			{
				queryKey: ['selectedMeasurementPointLatestReadings', selectedMeasurementPoint],
				queryFn: () => monitoringService.getMeasurementPointLatestReadings(selectedMeasurementPoint),
				enabled: !!selectedMeasurementPoint
			},
			{
				queryKey: ['data-sources', selectedMeasurementPoint],
				queryFn: () =>
					monitoringService.getDataSources({ filters: { measurement_point: selectedMeasurementPoint } }),
				enabled: !!selectedMeasurementPoint
			},
			{
				queryKey: ['alerts', selectedMeasurementPoint],
				queryFn: () =>
					alertsService.getAlertsNew({
						'page': 1,
						'page_size': 5,
						'measurement-point-id': selectedMeasurementPoint,
						'sorters': { alertEnd: -1 }
					}),
				enabled: !!selectedMeasurementPoint
			},
			{
				queryKey: ['images', selectedMeasurementPoint],
				queryFn: () => monitoringService.getMeasurementPointImages(selectedMeasurementPoint),
				enabled: !!selectedMeasurementPoint
			},
			{
				queryKey: ['files', selectedMeasurementPoint],
				queryFn: () => monitoringService.getMeasurementPointFiles(selectedMeasurementPoint),
				enabled: !!selectedMeasurementPoint
			}
		]
	});

	const hasPressureApp = hasApp(
		!!user?.user_data?.is_superAdmin,
		analysisPermessions,
		applicationIdMap.get(UserApps.PRESSURE),
		selectedNetwork
	);
	const hasWaterConsumptionApp = hasApp(
		!!user?.user_data?.is_superAdmin,
		analysisPermessions,
		applicationIdMap.get(UserApps.WATER_CONSUMPTION),
		selectedNetwork
	);
	const hasWaterProductionApp = hasApp(
		!!user?.user_data?.is_superAdmin,
		analysisPermessions,
		applicationIdMap.get(UserApps.WATER_PRODUCTION),
		selectedNetwork
	);
	const hasMNFApp = hasApp(
		!!user?.user_data?.is_superAdmin,
		analysisPermessions,
		applicationIdMap.get(UserApps.MINIMUM_NIGHTLY_FLOW),
		selectedNetwork
	);
	const pressureTabChildren = <AnalysisTab icon={<TbGauge size={20} />} title={t('DataSources')} />;
	const waterConsumptionChildren = <AnalysisTab icon={<BsWater size={20} />} title={t('WaterConsumption')} />;
	const waterProductiontionChildren = <AnalysisTab icon={<GiWaterTower size={20} />} title={t('WaterProduction')} />;
	const mnfChildren = <AnalysisTab icon={<TiFlowSwitch size={20} />} title={t('MinimumNightlyFlow')} />;

	const tabItems = clearArrayUndefined([
		{
			label: (
				<ComponentGuard
					allowed={hasPressureApp}
					fallback={() => <Premium showIcon children={pressureTabChildren} />}
				>
					{pressureTabChildren}
				</ComponentGuard>
			),
			key: 'data_sources',
			children: hasPressureApp ? (
				<MeasurementPointAnalysisDataSourcesTab
					pressureSource={
						dataSources &&
						dataSources.find(
							(src: any) =>
								src.type === DataSources.PRESSURE &&
								src.stream_direction !== PressureType.UPSTREAM &&
								src.stream_direction !== PressureType.DOWNSTREAM
						)
					}
					flowRateSource={dataSources && dataSources.find((src: any) => src.type === DataSources.FLOW_RATE)}
					calculatedFlowRateSource={
						dataSources && dataSources.find((src: any) => src.type === DataSources.CALCULATED_FLOW_RATE)
					}
					noiseSource={dataSources && dataSources.find((src: any) => src.type === DataSources.NOISE)}
					upstreamPressureSource={
						dataSources &&
						dataSources.find(
							(src: any) =>
								src.type === DataSources.PRESSURE && src.stream_direction === PressureType.UPSTREAM
						)
					}
					downstreamPressureSource={
						dataSources &&
						dataSources.find(
							(src: any) =>
								src.type === DataSources.PRESSURE && src.stream_direction === PressureType.DOWNSTREAM
						)
					}
					accumulatedFlowSource={
						dataSources && dataSources.find((src: any) => src.type === DataSources.ACCUMULATED_FLOW)
					}
					measurementPointName={localSelectedMeasurementPoint && localSelectedMeasurementPoint?.name_en}
				/>
			) : (
				<Empty />
			),
			disabled: !hasPressureApp
		},
		!!dataSources?.find(
			(src: any) => src.type === DataSources.FLOW_RATE || src.type === DataSources.CALCULATED_FLOW_RATE
		) &&
			!!measurementPoints?.find(
				point => point.id === selectedMeasurementPoint && point.type !== Sites.WATER_STATION
			) && {
				label: (
					<ComponentGuard
						allowed={hasWaterConsumptionApp}
						fallback={() => <Premium showIcon children={waterConsumptionChildren} />}
					>
						{waterConsumptionChildren}
					</ComponentGuard>
				),
				key: 'water_consumption',
				children: hasWaterConsumptionApp ? (
					<SiteAnalysisWaterConsumptionTab
						siteName={localSelectedMeasurementPoint && localSelectedMeasurementPoint?.name_en}
					/>
				) : (
					<Empty />
				),
				disabled: !hasWaterConsumptionApp
			},
		!!dataSources?.find(
			(src: any) => src.type === DataSources.FLOW_RATE || src.type === DataSources.CALCULATED_FLOW_RATE
		) &&
			!!measurementPoints?.find(
				point => point.id === selectedMeasurementPoint && point.type === Sites.WATER_STATION
			) && {
				label: (
					<ComponentGuard
						allowed={hasWaterProductionApp}
						fallback={() => <Premium showIcon children={waterProductiontionChildren} />}
					>
						{waterProductiontionChildren}
					</ComponentGuard>
				),
				key: 'water_production',
				children: hasWaterProductionApp ? (
					<SiteAnalysisWaterProductionTab
						siteName={localSelectedMeasurementPoint && localSelectedMeasurementPoint?.name_en}
					/>
				) : (
					<Empty />
				),
				disabled: !hasWaterProductionApp
			},
		!!dataSources?.find(
			(src: any) => src.type === DataSources.FLOW_RATE || src.type === DataSources.CALCULATED_FLOW_RATE
		) && {
			label: (
				<ComponentGuard allowed={hasMNFApp} fallback={() => <Premium showIcon children={mnfChildren} />}>
					{mnfChildren}
				</ComponentGuard>
			),
			key: 'minimum_nightly_flow',
			children: hasMNFApp ? (
				<MeasurementPointAnalysisMNFTab
					flowRateSource={dataSources && dataSources.find((src: any) => src.type === DataSources.FLOW_RATE)}
					calculatedFlowRateSource={
						dataSources && dataSources.find((src: any) => src.type === DataSources.CALCULATED_FLOW_RATE)
					}
					measurementPointName={localSelectedMeasurementPoint && localSelectedMeasurementPoint?.name_en}
				/>
			) : (
				<Empty />
			),
			disabled: !hasMNFApp
		}
	]);

	return (
		<Spin
			spinning={
				!!!selectedMeasurementPoint ||
				(loadingLocalSelectedMeasurementPoint && fetchingingLocalSelectedMeasurementPoint) ||
				(loadingSelectedMeasurementPointLatestReadings && fetchingSelectedMeasurementPointLatestReadings) ||
				(loadingDataSources && fetchingingDataSources) ||
				(loadingImages && fetchingImages) ||
				(loadingFiles && fetchingFiles)
			}
		>
			{localSelectedMeasurementPoint && (
				<h3 className="mb-3">
					{i18n.language === 'en'
						? `${localSelectedMeasurementPoint.valve ? t('Valve') : t('MeasurementPoint')} ${t('Analysis')}`
						: `${t('Analysis')} ${
								localSelectedMeasurementPoint.valve ? t('Valve') : t('MeasurementPoint')
						  }`}
				</h3>
			)}
			{desktopView ? null : (
				<Row>
					<Col xs={24} xl={12} xxl={10}>
						<MonitoringMap
							style={{
								height: '590px',
								width: 'auto',
								borderRadius: '13px',
								zIndex: 50
							}}
							networks={networks}
							selectedNetwork={selectedNetwork}
							zones={zones}
							selectedZone={selectedZone}
							selectedMeasurementPoint={selectedMeasurementPoint ?? undefined}
							// showGisToggler={false}
						/>
					</Col>
				</Row>
			)}
			<Row gutter={24}>
				{localSelectedMeasurementPoint ? (
					<>
						<Col xs={24} xl={12} xxl={14}>
							<Row gutter={8}>
								<Col xl={9}>
									<MeasurementPointStaticInformation point={localSelectedMeasurementPoint} />
								</Col>
								<Col lg={12} xl={5}>
									{localSelectedMeasurementPoint.valve ? (
										<WaiValveStatus
											type={localSelectedMeasurementPoint.valve.status}
											className="mb-4"
										/>
									) : null}
								</Col>
								<Col lg={12} xl={5}>
									{localSelectedMeasurementPoint.valve ? (
										<WaiOperationStatus
											className="mb-4"
											operation={localSelectedMeasurementPoint.valve.operation_status}
										/>
									) : null}
								</Col>
								<Col lg={12} xl={5}>
									{localSelectedMeasurementPoint.valve ? (
										<WaiMaintenanceStatus
											className="mb-4"
											maintenanceReminder={
												localSelectedMeasurementPoint.valve.maintenance_reminder_status
											}
										/>
									) : null}
								</Col>
							</Row>

							{selectedMeasurementPoint && selectedMeasurementPoint.length ? (
								<Row>
									<Tabs
										defaultActiveKey={selectedTab || undefined}
										type="card"
										style={{ fontWeight: '500' }}
										className={'w-100'}
										items={tabItems}
										tabBarExtraContent={
											<Link
												to={`/dashboard/advanced-analysis`}
												media={media}
												style={{ color: colors.GRAPH_PURPLE, fontWeight: 'bold' }}
											>
												<FiCornerDownRight
													style={i18n.language === 'en' ? {} : { transform: 'scaleX(-1)' }}
													className="me-2"
												/>

												{t('goToPlace', { place: t('AdvancedAnalysis') })}
											</Link>
										}
									/>
								</Row>
							) : null}
							<Row className="mt-4">
								<AlertsTableSummary
									measurementPointId={selectedMeasurementPoint}
									alerts={alerts?.results || []}
									className="w-100"
									loading={loadingAlerts}
								/>
							</Row>
						</Col>
					</>
				) : (
					<Col xs={24}>
						<h1>{t('PleaseSelectMeasurementPoint', { ns: 'validation' })}</h1>
					</Col>
				)}
				<Col xs={24} xl={12} xxl={10}>
					{desktopView ? (
						<MonitoringMap
							style={{
								height: '590px',
								width: 'auto',
								borderRadius: '13px',
								zIndex: 50
							}}
							networks={networks}
							selectedNetwork={selectedNetwork}
							zones={zones}
							selectedZone={selectedZone}
							selectedMeasurementPoint={selectedMeasurementPoint ?? undefined}
							// showGisToggler={false}
						/>
					) : null}
					{localSelectedMeasurementPoint ? (
						<>
							<Row gutter={24} className="mt-4">
								<Col xs={12}>
									<ImageGallery images={images} />
								</Col>
								<Col xs={12}>
									<DocumentsCard
										documents={files}
										onDocumentClick={url => {
											setPdfUrl(url);
											setPdfVisible(true);
										}}
									/>
								</Col>
							</Row>
							<Row className="my-4">
								<MeasurementPointSpecs
									point={localSelectedMeasurementPoint && localSelectedMeasurementPoint}
								/>
							</Row>
							<Row className="my-4">
								<LatestReadingsTable
									latestReadings={selectedMeasurementPointLatestReadings}
									className="w-100"
								/>
							</Row>
						</>
					) : null}
				</Col>
			</Row>
			<PDFViewer
				onCancel={() => {
					setPdfVisible(false);
					setPdfUrl('');
				}}
				visible={pdfVisible}
				url={pdfUrl}
			/>
		</Spin>
	);
};
