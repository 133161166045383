import { useContext, useEffect, useState } from 'react';
import { Form, Switch, Spin, Row, Col, TimePicker, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../hooks/use-query';
import { useQuery } from '@tanstack/react-query';
import { notifySuccess } from '../../utils/notification-messages';
import { NavigationContext } from '../../contexts/navigation.context';
import { colors } from '../../constants/colors';
import dayjs from 'dayjs';
import { modalConfirm } from '../modal-confirm';
import { SubmitCanelButtons } from '../submit-cancel-buttons';
import configService from '../../services/config.service';

export const SiteFlowAnalysisConfiguration: React.FC<any> = () => {
	const { t } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	const [activateMNF, setActivateMNF] = useState<boolean>(false);
	const [activateWaterConsumption, setActivateWaterConsumption] = useState<boolean>(false);
	const [activateWaterProduction, setActivateWaterProduction] = useState<boolean>(false);
	const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const siteId = useQueryParam('measurementPointId');
	const [form] = Form.useForm();

	// load selected site flow analysis configuration data
	const {
		data: siteFlowAnalysisConfig,
		isLoading: loadingSiteFlowAnalysisConfig,
		refetch: refetchSiteFlowAnalysisConfig
	} = useQuery({
		queryKey: ['site-flow-analysis-config', siteId],
		queryFn: () => siteId && configService.getSiteFlowAnalysisConfig(siteId),
		enabled: !!siteId
	});

	// fill the selected data for site flow analysis configuration
	useEffect(() => {
		if (siteFlowAnalysisConfig) {
			resetFields();
		}
	}, [siteFlowAnalysisConfig]);

	// on finish site analysis configuration
	const onFinish = async (values: any) => {
		setSubmitting(true);
		values.minimum_nightly_flow.start_time = dayjs(values.minimum_nightly_flow.start_time).format('HH:mm:ss');
		values.minimum_nightly_flow.end_time = dayjs(values.minimum_nightly_flow.end_time).format('HH:mm:ss');
		try {
			const resp = siteId && (await configService.editSiteFlowAnalysisConfig(siteId, values));
			if (!resp) throw new Error(t('couldntEditSiteAnalysisConfig', { ns: 'validation' }));
			refetchSiteFlowAnalysisConfig();
			notifySuccess(t('editedSuccessfully'));
		} catch (e: any) {
			errorHandler(e);
		}
		setSubmitting(false);
	};

	// confirm before submition
	const onConfirm = values => {
		try {
			modalConfirm({
				onOk: async () => {
					await onFinish(values);
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	// reset fields
	const resetFields = () => {
		setActivateMNF(siteFlowAnalysisConfig.minimum_nightly_flow.is_active);
		setActivateWaterConsumption(siteFlowAnalysisConfig.water_consumption.is_active);
		if (siteFlowAnalysisConfig.water_production) {
			setActivateWaterProduction(siteFlowAnalysisConfig.water_production.is_active);
		}
		form.setFieldsValue({
			...siteFlowAnalysisConfig,
			minimum_nightly_flow: {
				is_active: siteFlowAnalysisConfig.minimum_nightly_flow.is_active,
				start_time: dayjs(siteFlowAnalysisConfig.minimum_nightly_flow.start_time, 'HH:mm:ss'),
				end_time: dayjs(siteFlowAnalysisConfig.minimum_nightly_flow.end_time, 'HH:mm:ss')
			}
		});
		setIsFormChanged(false);
	};

	return (
		<Spin spinning={!siteId || loadingSiteFlowAnalysisConfig || !siteFlowAnalysisConfig || submitting}>
			<Form form={form} layout="vertical" onFinish={onConfirm} onValuesChange={() => setIsFormChanged(true)}>
				<Form.Item label={t('MinimumNightlyFlow')}>
					<Form.Item name={['minimum_nightly_flow', 'is_active']} valuePropName="checked">
						<Switch
							checkedChildren={t('Active')}
							unCheckedChildren={t('Inactive')}
							onChange={value => {
								setActivateMNF(value);
							}}
							style={{ backgroundColor: activateMNF ? colors.HEALTHY : colors.ERROR }}
							disabled={!siteFlowAnalysisConfig.is_active}
						/>
					</Form.Item>
					<Row gutter={16}>
						<Col xs={12}>
							<Form.Item
								name={['minimum_nightly_flow', 'start_time']}
								label={t('startTime')}
								rules={[{ required: true, message: t('PleaseSelectTime', { ns: 'validation' }) }]}
							>
								<TimePicker />
							</Form.Item>
						</Col>
						<Col xs={12}>
							<Form.Item
								name={['minimum_nightly_flow', 'end_time']}
								label={t('endTime')}
								rules={[{ required: true, message: t('PleaseSelectTime', { ns: 'validation' }) }]}
							>
								<TimePicker />
							</Form.Item>
						</Col>
					</Row>
				</Form.Item>
				<Divider />

				<Form.Item
					name={['water_consumption', 'is_active']}
					valuePropName="checked"
					label={t('WaterConsumption')}
				>
					<Switch
						checked={activateWaterConsumption}
						checkedChildren={t('Active')}
						unCheckedChildren={t('Inactive')}
						onChange={value => setActivateWaterConsumption(value)}
						style={{ backgroundColor: activateWaterConsumption ? colors.HEALTHY : colors.ERROR }}
						disabled={!siteFlowAnalysisConfig.is_active}
					/>
				</Form.Item>
				{siteFlowAnalysisConfig.water_production ? (
					<Form.Item
						name={['water_production', 'is_active']}
						valuePropName="checked"
						label={t('WaterProduction')}
					>
						<Switch
							checked={activateWaterProduction}
							checkedChildren={t('Active')}
							unCheckedChildren={t('Inactive')}
							onChange={value => setActivateWaterProduction(value)}
							style={{ backgroundColor: activateWaterProduction ? colors.HEALTHY : colors.ERROR }}
							disabled={!siteFlowAnalysisConfig.is_active}
						/>
					</Form.Item>
				) : null}
				<Form.Item className="d-flex justify-content-end mt-3">
					<SubmitCanelButtons
						handleCancel={() => resetFields()}
						handleSubmit={form.submit}
						reset={true}
						disabled={!isFormChanged}
					/>
				</Form.Item>
			</Form>
		</Spin>
	);
};
