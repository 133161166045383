import config from './';
import URIs from './uris';

const env = process.env.REACT_APP_ENV || 'development';

const configProxy = new Proxy(config[env], {
	set(target, p, v) {
		throw new Error(`Config is immutable object, can't modify config object.`);
	},
	get(target: any, p: string) {
		if (p === 'URIs') {
			return target[p] || URIs;
		}
		const s = p && p.split('_').pop();
		const suffix = s && s.toLowerCase();
		const url = suffix === 'url' || suffix === 'uri' || false;
		if (!url) return target[p];
		return target[p] || (target.URIs && target.URIs[p]) || URIs[p] || undefined;
	}
});

export function getConfig() {
	return configProxy;
}

export default configProxy;
