import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { WaiButton, WaiInput } from '../components';
import { colors } from '../constants/colors';
import { AuthenticationContext } from '../contexts';
import { BrandingLabelContext } from '../contexts/branding-label';
import { useTranslation } from 'react-i18next';
import authService from '../services/auth.service';
import { WaiLogo } from './wai-logo';

interface ISigninFormState {
	username: string | null | undefined;
	password: string | null | undefined;
}

export const Signin: React.FC<{ onLoginSuccessRedirect?: string }> = ({ onLoginSuccessRedirect = '/' }) => {
	const { t } = useTranslation();
	const [form] = useForm<ISigninFormState>();
	const { setAuthenticated } = useContext(AuthenticationContext);
	const labelContext = useContext(BrandingLabelContext);
	const { label } = labelContext;

	const [loading, setLoading] = useState<boolean>(false);
	const [errorMsg, setFormErrorMsg] = useState<string>();
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100vw',
				height: '100vh',
				backgroundColor: label.signinBackgroundColor,
				padding: '60px 40px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<Form
				style={{
					minWidth: '500px',
					padding: '40px 70px',
					borderRadius: '15px',
					backgroundColor: label.signinCardBackgroundColor
				}}
				className="d-flex flex-column align-items-center justify-content-center"
				name="signin"
				form={form}
				initialValues={{ username: '', password: '' }}
				onFinish={async values => {
					setLoading(true);
					try {
						var data = await authService.signin(values.username!, values.password!);
					} catch (e: any) {
						return setFormErrorMsg(t('InvalidCredentials', { ns: 'validation' }));
					} finally {
						setLoading(false);
					}

					setAuthenticated(true, data.access);
					const referer = location.state?.referer || onLoginSuccessRedirect;
					return navigate(referer);
				}}
			>
				<WaiLogo />
				{errorMsg && (
					<div
						style={{ backgroundColor: colors.RED_RGBA2, padding: '10px', color: colors.RED }}
						className="w-100 mb-3 d-flex justify-content-center"
					>
						{errorMsg}
					</div>
				)}
				<Form.Item
					className="w-100"
					name="username"
					rules={[{ required: true, message: t('PleaseInputYourUsername', { ns: 'validation' }) }]}
				>
					<WaiInput placeholder={t('username')} type="text" />
				</Form.Item>

				<Form.Item
					className="w-100"
					name="password"
					rules={[{ required: true, message: t('PleaseInputYourPassword', { ns: 'validation' }) }]}
				>
					<WaiInput placeholder={t('Password')} type="password" />
				</Form.Item>

				<Form.Item>
					<WaiButton htmlType="submit" loading={loading} style={{ width: '200px' }} label={label}>
						{t('Login', { ns: 'common' })}
					</WaiButton>
				</Form.Item>
			</Form>
		</div>
	);
};
