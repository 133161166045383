import { useQueries, useQuery } from '@tanstack/react-query';
import { Col, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import { HTMLAttributes, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import chartsService from '../services/charts.service';
import monitoringService from '../services/monitoring.service';
import { SpanType } from '../types';
import { WaterProductionReadingsCards } from './water-production-readings-tab';

export const NetworkZonesWaterProductionTab: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { selectedNetwork, measurementPoints } = navigationContext;

	const [dailyWaterProductionZoneChartTimeSpan, setDailyWaterProductionZoneChartTimeSpan] = useState<{
		date: string;
	}>({
		date: dayjs().format('YYYY-MM-DD')
	});

	// Load zones that have water production analysis module
	const { data: zonesWaterProduction, isLoading: loadingZonesWaterProduction } = useQuery<any>({
		queryKey: ['zones-water-production', selectedNetwork],
		queryFn: () => monitoringService.getZonesAll({ water_production: true, network: selectedNetwork }),
		enabled: !!selectedNetwork
	});

	// Load daily zones water production daily readings chart
	const dailyZonesWaterProductionReadings = useQueries<any>({
		queries: (zonesWaterProduction || []).map((zone: any) => {
			return {
				queryKey: [
					'zone-daily-water-production-readings',
					zone.id,
					dailyWaterProductionZoneChartTimeSpan?.date
				],
				queryFn: () => {
					if (dailyWaterProductionZoneChartTimeSpan?.date === dayjs().format('YYYY-MM-DD')) {
						// Load zones water production current readings
						return chartsService.getZoneCurrentWaterProductionReading(zone?.id);
					} else {
						return chartsService.getZoneDailyProduction(zone.id, {
							start:
								dailyWaterProductionZoneChartTimeSpan?.date.length &&
								dayjs(dailyWaterProductionZoneChartTimeSpan.date).format('YYYY-MM-DD'),
							span_type: SpanType.DAY
						});
					}
				},
				enabled: !!zone.id && !!selectedNetwork && !!dailyWaterProductionZoneChartTimeSpan?.date
			};
		})
	});

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col xs={24}>
					<Spin
						spinning={
							!dailyZonesWaterProductionReadings?.every(card => card.isSuccess) || !measurementPoints
						}
					>
						<WaterProductionReadingsCards
							title={t('ZonesDailyWaterProductionReadings')}
							graphHeight={550}
							spanTpye={SpanType.DAY}
							showTableChart
							lastReadingsDate={dayjs().format('YYYY-MM-DD')}
							water_production_cards={
								dailyZonesWaterProductionReadings?.every(card => card.isSuccess)
									? dailyZonesWaterProductionReadings?.map((card: any, idx: number) => {
											return {
												name:
													zonesWaterProduction?.length > 0 &&
													zonesWaterProduction.find(zone => zone.id === card?.data.id)
														?.name_en,
												value:
													dailyWaterProductionZoneChartTimeSpan?.date ===
													dayjs().format('YYYY-MM-DD')
														? (card?.data && card?.data?.value) ?? undefined
														: card?.data?.data?.length > 0
														? card?.data?.data[0].value
														: undefined,
												unit: card?.data?.unit,
												current:
													dailyWaterProductionZoneChartTimeSpan?.date ===
													dayjs().format('YYYY-MM-DD')
														? true
														: false,
												id: card?.data?.id
											};
									  })
									: []
							}
							OnDateSelect={(date: any) => setDailyWaterProductionZoneChartTimeSpan(date)}
						/>
					</Spin>
				</Col>
			</Row>
		</>
	);
};
