import { useContext, useEffect, useState } from 'react';
import { FormProps, Collapse, Switch, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { ZoneFlowAnalysisConfiguration } from '../zone-flow-analysis-configuration';
import { AverageZonePressureConfiguration } from '../average-zone-pressure-configuration';
import { colors } from '../../constants/colors';
import { NavigationContext } from '../../contexts/navigation.context';
import { useQueryParam } from '../../hooks/use-query';
import { useQuery } from '@tanstack/react-query';
import configService from '../../services/config.service';

const { Panel } = Collapse;

export const ZoneAnalysisConfiguration: React.FC<FormProps> = () => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	const [activateFlowAnalysis, setActivateFlowAnalysis] = useState<boolean | null>(null);
	const [activateAverageZonePressure, setActivateAverageZonePressure] = useState<boolean | null>(null);
	const [activePanel, setActivePanel] = useState<string[]>([]);
	const [loadingActivateFlowAnalysis, setLoadingActivateFlowAnalysis] = useState<boolean>(false);
	const [loadingActivateAverageZonePressure, setLoadingActivateAverageZonePressure] = useState<boolean>(false);

	const zoneId = useQueryParam('zoneId');
	const analysisPanelSelected = useQueryParam('analysisPanelSelected');

	// load selected zone flow analysis configuration data
	const {
		data: zoneFlowAnalysisConfig,
		isLoading: loadingZoneFlowAnalysisConfig,
		refetch: refetchZoneFlowAnalysisConfig
	} = useQuery({
		queryKey: ['zone-flow-analysis-config', zoneId],
		queryFn: () => zoneId && configService.getZoneFlowAnalysisConfig(zoneId),
		enabled: !!zoneId
	});

	// load selected average zone pressure profiles data
	const {
		data: averageZonePressureConfig,
		isLoading: loadingAverageZonePressureConfig,
		refetch: refechAverageZonePressureConfig
	} = useQuery({
		queryKey: ['avg-zone-pressure-config', zoneId],
		queryFn: () => zoneId && configService.getAvgZonePressureConfig(zoneId),
		enabled: !!zoneId
	});

	// open flow analysis config panel if is selected
	useEffect(() => {
		if (analysisPanelSelected) {
			setActivePanel([analysisPanelSelected]);
		}
	}, []);

	// set activation/deactivation default data for flow analysis
	useEffect(() => {
		if (zoneFlowAnalysisConfig) {
			setActivateFlowAnalysis(zoneFlowAnalysisConfig.is_active);
		}
	}, [zoneFlowAnalysisConfig]);

	// set activation/deactivation default data for average zone pressure
	useEffect(() => {
		if (averageZonePressureConfig) {
			setActivateAverageZonePressure(averageZonePressureConfig.is_active);
		}
	}, [averageZonePressureConfig]);

	// activate/deactivate flow analysis immediately when switching activation key
	useEffect(() => {
		(async () => {
			if (activateFlowAnalysis !== null && zoneFlowAnalysisConfig) {
				if (zoneFlowAnalysisConfig.is_active !== activateFlowAnalysis) {
					try {
						setLoadingActivateFlowAnalysis(true);
						const resp = await (zoneId &&
							configService.editZoneFlowAnalysisConfig(zoneId, {
								is_active: activateFlowAnalysis
							}));
						if (!resp) throw new Error(t('couldntEditZoneAnalysisConfig', { ns: 'validation' }));
						refetchZoneFlowAnalysisConfig();
					} catch (e: any) {
						errorHandler(e);
					} finally {
						setLoadingActivateFlowAnalysis(false);
					}
				}
			}
		})();
	}, [activateFlowAnalysis, zoneFlowAnalysisConfig]);

	// activate/deactivate average zone pressure immediately when switching activation key
	useEffect(() => {
		(async () => {
			if (activateFlowAnalysis !== null && averageZonePressureConfig) {
				if (averageZonePressureConfig.is_active !== activateAverageZonePressure) {
					try {
						setLoadingActivateAverageZonePressure(true);
						const resp = await (zoneId &&
							configService.editAvgZonePressureConfig(zoneId, {
								is_active: activateAverageZonePressure
							}));
						if (!resp) throw new Error(t('couldntEditZoneAnalysisConfig', { ns: 'validation' }));
						refechAverageZonePressureConfig();
					} catch (e: any) {
						errorHandler(e);
					} finally {
						setLoadingActivateAverageZonePressure(false);
					}
				}
			}
		})();
	}, [activateAverageZonePressure]);

	return (
		<>
			<Spin spinning={loadingZoneFlowAnalysisConfig || loadingAverageZonePressureConfig}>
				<Collapse
					accordion
					activeKey={activePanel}
					onChange={(e: any) => {
						setActivePanel(e);
					}}
					className="my-2"
				>
					<Panel
						header={t('FlowAnalysis')}
						key={'flow_analysis'}
						extra={
							<Switch
								checked={activateFlowAnalysis ?? false}
								checkedChildren={t('Active')}
								unCheckedChildren={t('Inactive')}
								onChange={(value, event) => {
									event.stopPropagation();
									setActivateFlowAnalysis(value);
								}}
								style={{ backgroundColor: activateFlowAnalysis ? colors.HEALTHY : colors.ERROR }}
							/>
						}
					>
						<Spin spinning={loadingActivateFlowAnalysis}>
							<ZoneFlowAnalysisConfiguration />
						</Spin>
					</Panel>
					<Panel
						header={t('AverageZonePressure')}
						key={'average_zone_pressure'}
						extra={
							<Switch
								checked={activateAverageZonePressure ?? false}
								checkedChildren={t('Active')}
								unCheckedChildren={t('Inactive')}
								onChange={(value, event) => {
									event.stopPropagation();
									setActivateAverageZonePressure(value);
								}}
								style={{ backgroundColor: activateAverageZonePressure ? colors.HEALTHY : colors.ERROR }}
							/>
						}
					>
						<Spin spinning={loadingActivateAverageZonePressure}>
							<AverageZonePressureConfiguration />
						</Spin>
					</Panel>
				</Collapse>
			</Spin>
		</>
	);
};
