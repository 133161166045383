import { Col, Popover, Row, Segmented, Switch, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { HTMLAttributes, useContext, useEffect, useState } from 'react';
import { FaTable } from 'react-icons/fa';
import configProxy from '../../config/config';
import { colors } from '../../constants/colors';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { useMedia } from '../../hooks/use-media.hook';
import { TimeStep, SpanType } from '../../types';
import { ChartCustomSpan } from '../../types/charts';
import { DataSources } from '../../types/data-sources';
import { GraphSpan } from '../../types/graph-span';
import { PressureUnit } from '../../types/pressure-unit';
import { dateToQuery } from '../../utils/date-to-query';
import { GraphDataTable } from '../graph-data-table';
import { GraphCustomSelectorDate, GraphSpanCustomSelector, GraphSpanSelector } from './graph-span-selector';
import { GraphUnitSelector } from './graph-unit-selector';
import { GraphCardContainer, GraphCardGraph, GraphCardSubtitle, GraphCardTitle } from './styles/graph-card';
import updateLocale from 'dayjs/plugin/updateLocale';
import { Link } from 'react-router-dom';
import { FiCornerDownRight } from 'react-icons/fi';
import { BsQuestion } from 'react-icons/bs';
import { upperSnakeToCapitalized } from '../../utils';
import { AverageZonePressureProfile } from '../average-zone-pressure-profile';
import { TbTableExport } from 'react-icons/tb';
import { ExportTableData } from '../export-table-data';

dayjs.extend(updateLocale);

export interface IGraphCardProps {
	title?: string;
	subtitle?: string;
	maxWidth?: string;
	onShowToggle?: any;
	height?: number;
	unit?: string;
	onUnitChange?: (unit: PressureUnit[]) => void;
	showSpanSelector?: boolean;
	showUnitSelector?: boolean;
	showTabular?: boolean;
	data?: any;
	dataObjects?: any;
	actualDataTimeSpan?: ChartCustomSpan;
	lastReadingDate?: Date;
	lastReadingsDates?: any;
	emphasizable?: boolean;
	emphasized?: boolean;
	onSpanSelect?: (span?: any) => void;
	OnDateSelect?: (date?: any) => void;
	onEmphasize?: (value?: boolean, fieldName?: string) => void;
	fieldName?: string;
	chartTableDataSource?: { id: string[]; valve: boolean; dataType: DataSources };
	showCustomSpanSelector?: boolean;
	showCustomSelectorDate?: boolean;
	defaultSelectedSpan?: GraphSpan;
	statistics?: any;
	IDs?: any;
	flags?: any;
	setDisplayDate?: any;
	displayDate?: any;
	showTimeStep?: boolean;
	setTimeStep?: any;
	analysisRedirectUri?: string;
	spanType?: any;
	setSpanType?: any;
	customTimeUnits?: any;
	setDisplayTimeSpan?: any;
	displayTimeSpan?: any;
	hint?: string;
	sourceName?: string;
	differentKeys?: boolean;
	customBrushSpan?: boolean;
	setCustomBrushSpan?: any;
	deactivated?: boolean;
	deactivatedMsg?: string;
	linkToActivate?: string;
	accessToActivateChart?: boolean;
	profile?: any;
	customRange?: boolean;
	showExportTable?: boolean;
	CommercialGroupId?: string;
	selectedId?: string;
	titleBold?: boolean;
	showTableChart?: boolean;
	graphHeight?: number;
	chartView?: string;
	setChartView?: any;
}

export const GraphCard: React.FC<HTMLAttributes<HTMLDivElement> & IGraphCardProps> = ({
	title,
	titleBold,
	subtitle,
	children,
	maxWidth,
	height,
	showSpanSelector,
	onSpanSelect,
	OnDateSelect,
	showUnitSelector,
	actualDataTimeSpan,
	showTabular,
	datatype,
	unit,
	onUnitChange,
	lastReadingDate,
	lastReadingsDates,
	chartTableDataSource,
	emphasizable,
	onEmphasize,
	dataObjects,
	emphasized,
	fieldName,
	showCustomSpanSelector,
	showCustomSelectorDate,
	defaultSelectedSpan,
	statistics,
	IDs,
	flags,
	setDisplayDate,
	displayDate,
	showTimeStep,
	setTimeStep,
	analysisRedirectUri,
	spanType,
	setSpanType,
	customTimeUnits,
	setDisplayTimeSpan,
	displayTimeSpan,
	hint,
	sourceName,
	differentKeys,
	customBrushSpan,
	setCustomBrushSpan,
	deactivated = false,
	deactivatedMsg,
	linkToActivate,
	accessToActivateChart = false,
	profile,
	customRange = false,
	showExportTable,
	CommercialGroupId,
	selectedId,
	showTableChart,
	chartView,
	setChartView,
	graphHeight,
	...restProps
}) => {
	const { t, i18n } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { setPressureUnit, pressureUnit } = navigationContext;
	const [customSpan, setCustomSpan] = useState<boolean>(false);
	const [timeSpan, setTimeSpan] = useState<{ start?: string; end?: string; date?: string } | undefined>();
	const [selectedTimeUnit, setSelectedTimeUnit] = useState<GraphSpan>(GraphSpan.RESET);
	const [selectedUnit, setSelectedUnit] = useState<PressureUnit>();
	const [tabularDataVisible, setTabularDataVisible] = useState<boolean>(false);
	const [profileInfoVisible, setProfileInfoVisible] = useState<boolean>(false);
	const [visibleExportTable, setVisibleExportTable] = useState<any>(undefined);
	const [tabularDta, setTabularData] = useState();
	const media = configProxy.defaultExpandScreenSize;
	const desktopView = useMedia(media);

	// make by default day time unit selected if customTimeUnitUnits
	useEffect(() => {
		if (customTimeUnits && customTimeUnits?.length) setSelectedTimeUnit(customTimeUnits[0]);
	}, []);

	// for date charts (not range date)
	useEffect(() => {
		if (displayDate) {
			if (lastReadingDate) {
				OnDateSelect && OnDateSelect(displayDate);
			} else if (lastReadingsDates) {
				OnDateSelect &&
					OnDateSelect({
						start: dayjs(displayDate).startOf(spanType).format('YYYY-MM-DD'),
						date: dayjs(displayDate).startOf(spanType).format('YYYY-MM-DD')
					});
			}
		}
	}, [displayDate, lastReadingDate, lastReadingsDates]);

	// by defualt make timeseries charts in reset time period
	useEffect(() => {
		if (lastReadingDate) {
			if (customRange) {
				setTimeSpan({
					start: dateToQuery(dayjs(lastReadingDate).startOf('year')),
					end: dateToQuery(dayjs(lastReadingDate).endOf('year'))
				});
			} else if (spanType && spanType === SpanType.DAY) {
				setTimeSpan({
					start: dateToQuery(dayjs(lastReadingDate)),
					end: dateToQuery(dayjs(lastReadingDate))
				});
			} else {
				setTimeSpan({
					start: dateToQuery(dayjs(lastReadingDate).subtract(7, 'day')),
					end: dateToQuery(dayjs(lastReadingDate))
				});
			}
		}
	}, [lastReadingDate, spanType, selectedId]);

	// select range for timeseries charts
	useEffect(() => {
		if (selectedTimeUnit === GraphSpan.ALL) {
			onSpanSelect && onSpanSelect();
			onUnitChange && onUnitChange(pressureUnit[1]);
		} else if (
			timeSpan?.start &&
			timeSpan.end &&
			(dayjs(timeSpan.end).isAfter(dayjs(timeSpan.start)) || dayjs(timeSpan.end).isSame(dayjs(timeSpan.start)))
		) {
			onSpanSelect && onSpanSelect(timeSpan);
			onUnitChange && onUnitChange(pressureUnit[1]);
		}
	}, [timeSpan, timeSpan?.start, timeSpan?.end, selectedTimeUnit]);

	// handle the visibility triggering of tabular data
	const handleTabularVisibleChange = (visible: boolean) => {
		setTabularDataVisible(visible);
	};

	// set set data for tabular data
	useEffect(() => {
		if (dataObjects?.length) {
			if (differentKeys) {
				const expandKeys: any = [];
				for (const key in dataObjects[0].data[0]) {
					if (key !== 'time') {
						const newData = dataObjects[0].data.map(item => ({
							time: item.time,
							value: item[key]
						}));

						expandKeys.push({
							name: key,
							data: newData
						});
					}
				}
				dataObjects = expandKeys;
			}
			setTabularData(dataObjects);
		}
	}, [dataObjects, differentKeys]);

	return (
		<GraphCardContainer
			maxWidth={maxWidth}
			height={height}
			englishSelected={i18n.language === 'en'}
			{...restProps}
			{...restProps}
		>
			{deactivated ? (
				<div
					style={{
						position: 'absolute',
						transform: 'translate(-50%, -50%)',
						top: '50%',
						left: '50%',
						zIndex: '1',
						width: '100%'
					}}
					className={'d-flex flex-column align-items-center p-3 text-center'}
				>
					<h6>{deactivatedMsg ? deactivatedMsg : t('ThisChartIsDeactivated')}</h6>
					{accessToActivateChart ? (
						<h6>
							<Link
								to={linkToActivate ?? `/dashboard/configuration`}
								media={media}
								style={{ color: colors.GRAPH_PURPLE, fontWeight: 'bold' }}
							>
								<FiCornerDownRight style={i18n.language === 'en' ? {} : { transform: 'scaleX(-1)' }} />
								{t('activateChart')}
							</Link>{' '}
							{t('toView')}
						</h6>
					) : null}
				</div>
			) : null}
			<div
				style={{
					opacity: deactivated ? 0.5 : 1,
					pointerEvents: deactivated ? 'none' : 'initial',
					filter: deactivated ? 'blur(30px)' : 'none'
				}}
			>
				<Row
					className="d-flex justify-content-between align-items-center mx-2"
					dir={i18n.language === 'en' ? 'ltr' : 'rtl'}
				>
					<Col
						xs={
							analysisRedirectUri || emphasizable || showCustomSpanSelector || showCustomSelectorDate
								? 12
								: 24
						}
					>
						<div>
							<div className={`d-flex justify-content-start align-items-start mb-2`}>
								{title ? (
									<>
										<GraphCardTitle
											titleBold={titleBold ?? false}
											englishSelected={i18n.language === 'en'}
										>
											{title}
										</GraphCardTitle>
										{hint && hint?.length > 0 ? (
											<Tooltip title={hint}>
												<div className="m-0 p-0 d-flex align-items-center">
													<BsQuestion
														style={
															i18n.language === 'en' ? {} : { transform: 'scaleX(-1)' }
														}
														color={colors.INACTIVE}
														className={'mx-1'}
													/>
												</div>
											</Tooltip>
										) : null}
										{showExportTable ? (
											<>
												<Tooltip title={t('Export')}>
													<div className="m-0 p-0 d-flex align-items-center">
														<TbTableExport
															style={{
																color: colors.GRAPH_PURPLE,
																cursor: 'pointer'
															}}
															size={20}
															className={i18n.language === 'en' ? 'ms-2' : 'me-2'}
															onClick={() =>
																setVisibleExportTable(prevState => !prevState)
															}
														/>
													</div>
												</Tooltip>
												{CommercialGroupId ? (
													<ExportTableData
														downloadTitle={title}
														id={CommercialGroupId}
														initialDate={displayDate || lastReadingsDates?.month}
														visibleExportTable={visibleExportTable}
														setVisibleExportTable={setVisibleExportTable}
														sourceName={sourceName}
													/>
												) : null}
											</>
										) : null}
									</>
								) : null}

								{showTabular ? (
									<Popover
										overlayStyle={{ zIndex: 5000 }}
										open={tabularDataVisible}
										trigger="click"
										onOpenChange={handleTabularVisibleChange}
										content={
											<GraphDataTable
												chartTableDataSource={chartTableDataSource}
												downloadTitle={title}
												dataObjects={tabularDta}
												unit={unit}
												onCancel={() => setTabularDataVisible(false)}
												IDs={IDs}
												flags={flags}
												initialTimeRange={displayTimeSpan?.start ? displayTimeSpan : timeSpan}
												initialDate={displayDate || timeSpan?.start}
												sourceName={sourceName}
												differentKeys={differentKeys}
											/>
										}
									>
										<Tooltip title={t('TabularData')}>
											<div className="m-0 p-0 d-flex align-items-center">
												<FaTable
													style={{
														color: colors.GRAPH_PURPLE,
														cursor: 'pointer'
													}}
													className={i18n.language === 'en' ? 'ms-2' : 'me-2'}
													onClick={() => setTabularDataVisible(prevState => !prevState)}
												/>
											</div>
										</Tooltip>
									</Popover>
								) : null}
							</div>
							<div className="d-flex">
								{showSpanSelector ? (
									<GraphSpanSelector
										style={
											i18n.language === 'en'
												? { marginLeft: '13px', marginRight: '10%' }
												: { marginLeft: '10%', marginRight: '13px' }
										}
										selectedTimeUnit={
											(customSpan || customBrushSpan) && !customRange
												? null
												: defaultSelectedSpan ?? selectedTimeUnit
										}
										setTimeStep={setTimeStep}
										showTimeStep={showTimeStep}
										setSpanType={setSpanType}
										customGraphSpan={customTimeUnits || undefined}
										onSpanChange={(span: GraphSpan) => {
											setCustomSpan(false);
											setCustomBrushSpan && setCustomBrushSpan(false);
											setSelectedTimeUnit(span);
											setTimeSpan(
												span === GraphSpan.ALL
													? undefined
													: span === GraphSpan.RESET
													? {
															start: dateToQuery(
																dayjs(lastReadingDate).subtract(7, 'day')
															),
															end: dateToQuery(dayjs(lastReadingDate))
													  }
													: customRange
													? {
															start: dateToQuery(
																dayjs(
																	lastReadingsDates
																		? lastReadingsDates[span]
																		: lastReadingDate
																).startOf('year')
															),
															end: dateToQuery(
																dayjs(
																	lastReadingsDates
																		? lastReadingsDates[span]
																		: lastReadingDate
																).endOf('year')
															)
													  }
													: {
															start: dateToQuery(
																dayjs(
																	lastReadingsDates
																		? lastReadingsDates[span]
																		: lastReadingDate
																).startOf(span)
															),
															end: dateToQuery(
																dayjs(
																	lastReadingsDates
																		? lastReadingsDates[span]
																		: lastReadingDate
																).endOf(span)
															)
													  }
											);
											setDisplayTimeSpan &&
												setDisplayTimeSpan(
													span === GraphSpan.ALL
														? undefined
														: span === GraphSpan.RESET
														? {
																start: dayjs(lastReadingDate).subtract(7, 'day'),
																end: dateToQuery(dayjs(lastReadingDate))
														  }
														: customRange
														? {
																start: dayjs(
																	lastReadingsDates
																		? lastReadingsDates[span]
																		: lastReadingDate
																)
																	.startOf('year')
																	.format('YYYY-MM-DD'),
																end: dayjs(
																	lastReadingsDates
																		? lastReadingsDates[span]
																		: lastReadingDate
																)
																	.endOf('year')
																	.format('YYYY-MM-DD')
														  }
														: {
																start: dayjs(
																	lastReadingsDates
																		? lastReadingsDates[span]
																		: lastReadingDate
																)
																	.startOf(span)
																	.format('YYYY-MM-DD'),
																end: dayjs(
																	lastReadingsDates
																		? lastReadingsDates[span]
																		: lastReadingDate
																)
																	.endOf(span)
																	.format('YYYY-MM-DD')
														  }
												);

											setDisplayDate &&
												setDisplayDate(
													span === GraphSpan.ALL
														? undefined
														: span === GraphSpan.RESET
														? dateToQuery(dayjs(lastReadingDate))
														: dayjs(
																lastReadingsDates
																	? lastReadingsDates[span]
																	: lastReadingDate
														  )
																.endOf(span)
																.format('YYYY-MM-DD')
												);
										}}
									/>
								) : null}
								{showUnitSelector ? (
									<GraphUnitSelector
										selectedUnit={selectedUnit || (unit as PressureUnit)}
										onUnitChange={(unit: PressureUnit[]) => {
											setSelectedUnit(unit[1]);
											setPressureUnit(unit);
											onUnitChange && onUnitChange(unit);
										}}
									/>
								) : null}
							</div>
						</div>
					</Col>
					<Col xs={12} md={12}>
						{analysisRedirectUri ? (
							<div className="d-flex justify-content-end mx-2">
								<Link
									to={analysisRedirectUri}
									media={media}
									style={{ color: colors.GRAPH_PURPLE, fontWeight: 'bold' }}
								>
									<FiCornerDownRight
										className="me-2"
										style={i18n.language === 'en' ? {} : { transform: 'scaleX(-1)' }}
									/>
									{t('viewAnalytics')}
								</Link>
							</div>
						) : null}
						{emphasizable ? (
							<div className="d-flex justify-content-end align-items-center ">
								<span style={{ fontSize: '0.8rem' }}>{t('EmpahsizeOnMap')}</span>
								<Switch
									className="mx-2"
									size={desktopView ? 'default' : 'small'}
									checked={emphasized}
									defaultChecked
									onChange={checked => onEmphasize && onEmphasize(checked, fieldName)}
								/>
							</div>
						) : null}

						{showCustomSpanSelector ? (
							<GraphSpanCustomSelector
								selectedSpan={
									customSpan
										? timeSpan
										: (actualDataTimeSpan?.start &&
												actualDataTimeSpan?.end &&
												actualDataTimeSpan) ??
										  timeSpan
								}
								spanType={spanType}
								setCustomSpan={setCustomSpan}
								onCustomSpanChange={vals => {
									setTimeSpan({
										...timeSpan,
										start: dateToQuery(vals[0]),
										end: dateToQuery(vals[1])
									});
									setCustomSpan(true);
									setTimeStep(undefined);
									!customRange && setSelectedTimeUnit(GraphSpan.RESET);
									setDisplayTimeSpan({ start: dateToQuery(vals[0]), end: dateToQuery(vals[1]) });
								}}
								setDisplayTimeSpan={setDisplayTimeSpan}
								displayTimeSpan={displayTimeSpan}
								customSpan={customSpan}
							/>
						) : null}
						{showCustomSelectorDate ? (
							<GraphCustomSelectorDate
								selectedDate={timeSpan?.start || lastReadingsDates}
								onCustomDateChange={dateString => {
									setTimeSpan({
										...timeSpan,
										start: dateString,
										date: spanType === SpanType.DAY ? dateString : undefined
									});
									setDisplayDate(dateString);
								}}
								setDisplayDate={setDisplayDate}
								displayDate={displayDate}
								spanType={spanType}
							/>
						) : null}
					</Col>
				</Row>
				{statistics ? (
					<>
						<Row
							className={`d-flex justify-content-between align-items-center  mb-2 ${
								i18n.language === 'en' ? 'ps-3' : 'pe-3'
							}`}
						>
							{statistics.map((stat: any, idx: any) =>
								Number.isFinite(stat.value) ? (
									<div key={idx} className="d-flex align-items-center col-4">
										<div
											style={{
												width: '10px',
												height: '10px',
												backgroundColor: stat.color || colors.GRAPH_PURPLE,
												marginInline: '5px'
											}}
										></div>

										<div
											key={stat.name}
											style={{ color: stat.color || colors.GRAPH_PURPLE, fontWeight: 'bold' }}
										>
											{stat.name} : {parseFloat(stat.value).toFixed(3)} {stat.unit}
										</div>
									</div>
								) : null
							)}
						</Row>
						{statistics?.length ? (
							<div className="d-flex justify-content-center text-muted">
								({t('StatisticsAreShownForTimePeriod')})
							</div>
						) : (
							<div className="d-flex justify-content-center text-muted m-3">{t('NoData')}</div>
						)}
					</>
				) : null}
				{profile ? (
					<div className="d-flex justify-content-center mt-1" style={{ width: '100%' }}>
						<span style={{ fontWeight: 'bold' }} className={'mx-1'}>
							{upperSnakeToCapitalized(profile.name)}
						</span>
						{t('profileIsUsedForTheseCalculations')}
						<Popover
							overlayStyle={{ zIndex: 5000 }}
							open={profileInfoVisible}
							onOpenChange={e => setProfileInfoVisible(e)}
							trigger="click"
							content={
								<>
									{accessToActivateChart ? (
										<div className={'mb-2'}>
											<Link
												to={linkToActivate ?? `/dashboard/configuration`}
												media={media}
												style={{ color: colors.GRAPH_PURPLE, fontWeight: 'bold' }}
											>
												<FiCornerDownRight
													style={i18n.language === 'en' ? {} : { transform: 'scaleX(-1)' }}
													className="me-2"
												/>

												{t('EditAverageZonePressureConfiguration')}
											</Link>
										</div>
									) : null}
									<AverageZonePressureProfile profile={profile} />
								</>
							}
						>
							<Tooltip title={t('MoreInformation')}>
								<div className="m-0 p-0 d-flex align-items-center">
									<BsQuestion
										style={i18n.language === 'en' ? {} : { transform: 'scaleX(-1)' }}
										color={colors.INACTIVE}
										className={'mx-1'}
									/>
								</div>
							</Tooltip>
						</Popover>
					</div>
				) : null}
				{subtitle ? <GraphCardSubtitle>{subtitle}</GraphCardSubtitle> : null}
				<GraphCardGraph height={height}>
					{showTableChart ? (
						<div className="d-flex justify-content-center my-2">
							<Segmented
								options={[
									{ label: t('Table'), value: 'table' },
									{
										label: t('Chart'),
										value: 'chart'
									}
								]}
								onChange={value => setChartView(value)}
								className="mt-3"
								value={chartView}
							/>
						</div>
					) : null}
					{children}
				</GraphCardGraph>
			</div>
		</GraphCardContainer>
	);
};
