import { DownloadOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { useFullScreen } from '../../hooks/fullscreen.hook';
import { usePDFViewer } from '../../hooks/react-pdf-hook';
import { ControlButton, DocumentContainer, PDFSlider, PDFViewerControls } from './styles/pdf-viewer';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MAX_SCALE = 2;
const MIN_SCALE = 0.5;

const DownloadButton = styled(Button)`
	font-weight: 600;
	color: ${colors.WHITE};
	background-color: ${colors.BLACK_RGBA1};
	padding: 10px 15px;
	border-radius: 5px;
	width: fit-content;
	transition: all 0.5s;
	display: flex;
	align-items: center;
	:hover {
		color: ${colors.GRAPH_PURPLE};
		background-color: ${colors.LIGHT_PURPLE_RGB2};
	}
`;

export function PDFViewer({ url, visible, onCancel, title, downloadable = true }: any) {
	const { t } = useTranslation();

	const pdf = usePDFViewer(url, [url]);
	const documentRef = useRef<any>();
	const [currentPage, changePage] = useState(1);
	const [pageWidth, setPageWidth] = useState();
	const [scale, setScale] = useState(1);
	const [isFullScreen, toggleFullScreen] = useFullScreen(documentRef.current, [pdf.file] as any);

	const changePageAndScroll = (newPage: number) => {
		changePage(newPage);
		documentRef?.current?.firstChild.firstChild.children[newPage - 1].scrollIntoView(true);
	};

	const onPageEnter = (entries: any) => {
		for (const ent of entries) {
			if (ent.isIntersecting) {
				return changePage(parseInt(ent.target.dataset.pageNumber));
			}
		}
	};

	useEffect(() => {
		if (!pdf.numberPages || !documentRef.current) return;
		const observer = new IntersectionObserver(onPageEnter, {
			root: documentRef.current,
			rootMargin: '0px',
			threshold: 0.5
		});
		Array.from(documentRef.current.firstChild.firstChild.children).forEach((page: any) => observer.observe(page));
		documentRef.current.focus();
		setPageWidth(documentRef.current.firstChild.firstChild.clientWidth);
	}, [pdf.numberPages]);

	useEffect(() => {
		if (isFullScreen) {
			setPageWidth(documentRef.current.firstChild.firstChild.clientWidth);
		}
		setScale(1);
	}, [isFullScreen]);

	const nextPage = () => changePageAndScroll(currentPage < pdf.numberPages! ? currentPage + 1 : currentPage);
	const prevPage = () => changePageAndScroll(currentPage > 1 ? currentPage - 1 : currentPage);
	const zoomIn = () => setScale(scale >= MAX_SCALE ? scale : scale + 0.25);
	const zoomOut = () => setScale(scale <= MIN_SCALE ? scale : scale - 0.25);
	const downloadDoc = () => {
		const anchor = document.createElement('a');
		anchor.href = url;
		anchor.download = 'doc.pdf';
		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);
	};
	documentRef.current && documentRef.current.focus();
	const controlsRenderer = (showOnHover = false, isFullScreen = false) => (
		<PDFViewerControls showOnHover={showOnHover} isFullScreen={isFullScreen}>
			<PDFSlider
				defaultValue={1}
				min={pdf.numberPages == 1 ? 0 : 1}
				max={pdf.numberPages}
				onChange={(e: any) => changePage(e)}
				value={currentPage}
				disabled={pdf.numberPages === 1}
			/>
			<div className="d-flex justify-content-center">
				<ControlButton onClick={zoomIn} tooltip="Zoom-In">
					<ZoomInOutlined />
				</ControlButton>
				<ControlButton onClick={zoomOut} tooltip="Zoom-Out">
					<ZoomOutOutlined />
				</ControlButton>
				<ControlButton onClick={prevPage} disabled={currentPage === 1} tooltip={t('Previous')}>
					&lt;
				</ControlButton>
				<span className="mx-2">{`${currentPage} of ${pdf.numberPages}`}</span>
				<ControlButton onClick={nextPage} disabled={currentPage === pdf.numberPages} tooltip={t('Next')}>
					&gt;
				</ControlButton>
				{downloadable && isFullScreen ? (
					<ControlButton>
						<DownloadOutlined style={{ fontSize: '18px' }} onClick={downloadDoc} />
					</ControlButton>
				) : null}
			</div>
		</PDFViewerControls>
	);

	const keyPressHandler = (e: any) => {
		if (e.key === 'ArrowRight') nextPage();
		if (e.key === 'ArrowLeft') prevPage();
	};

	return (
		<Modal
			className="ta-modal"
			open={visible}
			width="700px"
			onCancel={onCancel}
			footer={pdf.numberPages ? controlsRenderer() : null}
			title={
				<div className="d-flex align-items-center justify-content-between">
					<span>{title}</span>
					<div style={{ marginRight: '40px' }}>
						{downloadable && (
							<DownloadButton onClick={downloadDoc}>
								<span
									style={{
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<DownloadOutlined style={{ fontSize: '18px' }} />
									<span className="p-1">{t('Download')}</span>
								</span>
							</DownloadButton>
						)}
					</div>
				</div>
			}
			style={{ top: '10px', overflow: 'auto' }}
			bodyStyle={{
				overflow: 'hidden',
				height: '78vh',
				padding: '0'
			}}
		>
			<DocumentContainer
				tabIndex={-1}
				onKeyDown={keyPressHandler}
				ref={documentRef}
				className={isFullScreen ? 'fullscreen' : ''}
			>
				<Document
					file={pdf.file}
					renderMode="canvas"
					onLoadSuccess={({ numPages }) => pdf.setNumberPages(numPages)}
					className="document"
				>
					<div className="pages w-100">
						{pdf.numberPages &&
							Array.from(new Array(pdf.numberPages), (el, index) => (
								<Page
									key={`page_${index + 1}`}
									pageNumber={index + 1}
									width={isFullScreen ? undefined : pageWidth}
									scale={scale}
									// className="mb-2 d-flex justify-content-center"
								/>
							))}
					</div>
					{pdf.numberPages && isFullScreen && controlsRenderer(true, true)}
				</Document>
			</DocumentContainer>
		</Modal>
	);
}
