import { Col, Pagination, Row, Segmented, Spin } from 'antd';
import { HTMLAttributes, useContext, useEffect, useState } from 'react';
import { NavigationContext } from '../contexts/navigation.context';
import { MPInfoNew } from '../components/MP-info-new';
import { useMedia } from '../hooks/use-media.hook';
import { Sites } from '../types/sites';
import { useTranslation } from 'react-i18next';
import { upperSnakeToCapitalized } from '../utils';

export const NetworkAnalysisMeasurementPointsTab: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		setSelectedMeasurementPoint?: any;
	}
> = ({ setSelectedMeasurementPoint }) => {
	const { t } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { measurementPoints } = navigationContext;
	const largeScreen = useMedia('xxxxl');

	// set selected type sites
	const [sitesTypeSeleted, setSitesTypeSeleted] = useState<any>();

	// set max. numbers of cards for each page
	const [pagination, setPagination] = useState<any>({
		min: 0,
		max: 9
	});

	// add all sites to siteTypeSelected at first
	useEffect(() => {
		if (measurementPoints) {
			setSitesTypeSeleted(measurementPoints);
		}
	}, [measurementPoints]);

	// check if large screen then add more cards
	useEffect(() => {
		if (largeScreen) {
			setPagination({ min: 0, max: 12 });
		}
	}, [largeScreen]);

	const numEachPage = largeScreen ? 12 : 9;

	// handle cards viewing when changing page number
	const handleChange = (value: any) => {
		setPagination({
			min: (value - 1) * numEachPage,
			max: value * numEachPage
		});
	};

	return (
		<Spin spinning={!(measurementPoints?.length > 0) && !(sitesTypeSeleted?.length > 0)}>
			{measurementPoints?.length > 0 && sitesTypeSeleted?.length > 0 ? (
				<>
					<Segmented
						options={[
							{ label: t('All'), value: 'all' },
							{
								label: upperSnakeToCapitalized(t('NetworkPointsSites')),
								value: Sites.NETWORK_MEASUREMENT_POINT
							},
							measurementPoints.find(point => point.type === Sites.VALVE) && {
								label: upperSnakeToCapitalized(t('ValveSites')),
								value: Sites.VALVE
							},
							measurementPoints.find(point => point.type === Sites.WATER_STATION) && {
								label: upperSnakeToCapitalized(t('WaterStationSites')),
								value: Sites.WATER_STATION
							}
						].filter(option => option)}
						onChange={(value: any) => {
							if (value === 'all') {
								setSitesTypeSeleted(measurementPoints);
							} else {
								setSitesTypeSeleted(measurementPoints.filter(point => point.type === value));
							}
							handleChange(1);
						}}
						className="my-2"
					/>
					<Row gutter={[16, 16]}>
						{sitesTypeSeleted?.slice(pagination?.min, pagination?.max).map(measurementPoint => {
							return (
								<Col
									xs={24}
									sm={12}
									lg={8}
									xl={12}
									xxl={largeScreen ? 6 : 8}
									key={measurementPoint?.id}
								>
									<MPInfoNew
										measurementPoint={measurementPoint}
										onMouseOver={() =>
											setSelectedMeasurementPoint &&
											setSelectedMeasurementPoint(measurementPoint?.id)
										}
									/>
								</Col>
							);
						})}
					</Row>
					<Row className="d-flex justify-content-end mt-3">
						<Pagination
							defaultCurrent={1}
							defaultPageSize={numEachPage}
							onChange={handleChange}
							total={sitesTypeSeleted?.length}
						/>
					</Row>
				</>
			) : null}
		</Spin>
	);
};
