import { useQuery } from '@tanstack/react-query';
import { Button, Spin, Table, TableColumnsType, TableProps } from 'antd';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { IFoundationDto } from '../../dtos';
import userManagementService from '../../services/user-management.service';
import { Identifiable, UserApps } from '../../types';
import { notifySuccess } from '../../utils/notification-messages';
import { ComponentGuard } from '../component-guard';
import { TableControls } from '../table-controls';
import { modalConfirm } from '../modal-confirm';

export const FoundationsTable: React.FC<TableProps<Identifiable<IFoundationDto>>> = () => {
	const { t } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;
	const navigate = useNavigate();

	// get all foundations
	const {
		data: foundations,
		isLoading: foundationsLoading,
		refetch: refetchFoundations
	} = useQuery({
		queryKey: ['foundations'],
		queryFn: () => userManagementService.getFoundations()
	});

	const tableColumns: TableColumnsType<IFoundationDto> = [
		{
			title: t('Foundation'),
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			render: (text: string) => text
		},

		{
			title: t('NumberOfNetworks'),
			dataIndex: 'no_of_networks',
			key: 'no_of_networks',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('NumberOfZones'),
			dataIndex: 'no_of_zones',
			key: 'no_of_zones',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('NumberOfMeasurementPoints'),
			dataIndex: 'no_of_measurement_points',
			key: 'no_of_measurement_points',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('NumberOfDataSources'),
			dataIndex: 'no_of_data_sources',
			key: 'no_of_data_sources',
			align: 'center',
			render: (text: string) => text
		}
	];

	tableColumns.push({
		title: t('Actions'),
		key: 'actions',
		align: 'center',
		render: (_: any, record: any) => (
			<TableControls
				onEdit={() => navigate(`${AppRoutes.FOUNDATION_CONFIGURATION}?foundationId=${record.id}`)}
				onRemove={() => onRemove(record.id)}
				userApp={UserApps.FOUNDATIONS}
			/>
		)
	});

	const onRemove = (id: string) => {
		try {
			modalConfirm({
				content: t('YouAreAboutToDeleteFoundation'),
				onOk: async () => {
					await userManagementService.deleteFoundation(id);
					notifySuccess(t('RemovedSuccessfully'));
					refetchFoundations();
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};
	return (
		<>
			<Spin spinning={foundationsLoading}>
				<Table
					tableLayout="auto"
					scroll={{ x: true }}
					columns={tableColumns}
					dataSource={foundations ?? undefined}
					size="small"
					showSorterTooltip
					rowKey="id"
				/>
			</Spin>
			<ComponentGuard allowed={true}>
				<div className="d-flex justify-content-end mt-3">
					<Link to={AppRoutes.FOUNDATION_CONFIGURATION}>
						<Button type="primary">{t('addFoundation')}</Button>
					</Link>
				</div>
			</ComponentGuard>
		</>
	);
};
