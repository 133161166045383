import { Col, Empty, Row, Spin, Tabs } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AnalysisTab, ComponentGuard, DocumentsCard, Premium, ZoneStaticInformation } from '../components';
import configProxy from '../config/config';
import { colors } from '../constants/colors';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import { useMedia } from '../hooks/use-media.hook';
import { MonitoringMap } from './map';
import { FiCornerDownRight } from 'react-icons/fi';
import { ZoneAnalysisPressureTab } from './zone-analysis-pressure-tab';
import { ZoneAnalysisWaterConsumptionTab } from './zone-analysis-water-consumption-tab';
import { ZoneAnalysisMNFTab } from './zone-analysis-mnf-tab';
import { UserApps } from '../types';
import { useQueryParam } from '../hooks/use-query';
import { useQuery } from '@tanstack/react-query';
import alertsService from '../services/alerts.service';
import { AlertsTableSummary } from './alerts-table-summary';
import { AuthenticationContext } from '../contexts';
import { clearArrayUndefined, hasApp } from '../utils';
import { TbGauge } from 'react-icons/tb';
import { TiFlowSwitch } from 'react-icons/ti';
import { BsWater } from 'react-icons/bs';
import monitoringService from '../services/monitoring.service';
import { PDFViewer } from '../components/pdf-viewer';
import { GiLeak, GiWaterTower } from 'react-icons/gi';
import { ZoneLeakageAnalysisTab } from './zone-leakage-analysis-tab';
import configService from '../services/config.service';
import { ZoneAnalysisWaterProductionTab } from './zone-analysis-water-production-tab';
import { Sites } from '../types/sites';

export const ZoneAnalysis: React.FC<any> = () => {
	const { t, i18n } = useTranslation();

	const [selectedZoneObject, setSelectedZoneObject] = useState<any>();
	const [pdfVisible, setPdfVisible] = useState<boolean>(false);
	const [pdfUrl, setPdfUrl] = useState<string>('');

	const navigationContext = useContext(NavigationContext);
	const { measurementPoints, zones, selectedZone, networks, selectedNetwork, applicationIdMap } = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user } = authContext;

	const media = configProxy.defaultExpandScreenSize;
	const desktopView = useMedia(media);
	const selectedTab = useQueryParam('selectedTab');

	const { analysisPermessions } = useContext(AuthenticationContext);

	// loading alerts for selected zone
	const { data: alerts, isLoading: loadingAlerts }: any = useQuery({
		queryKey: ['alerts', selectedZone],
		queryFn: () =>
			alertsService.getAlertsNew({
				'page': 1,
				'page_size': 5,
				'zone-id': selectedZone,
				'sorters': { alertEnd: -1 }
			}),
		enabled: !!selectedZone
	});
	// load zone files
	const { data: files, isLoading: loadingFiles } = useQuery({
		queryKey: ['files', selectedZone],
		queryFn: () => monitoringService.getZoneFiles(selectedZone),
		enabled: !!selectedZone
	});
	// load zone parameters
	const { data: zoneParameters, isLoading: loadingZoneParameters } = useQuery({
		queryKey: ['zone-parameters', selectedZone],
		queryFn: () => configService.getZoneLeakageParameters(selectedZone),
		enabled: !!selectedZone
	});
	// get zone data
	useEffect(() => {
		if (selectedZone && zones) {
			const zone = zones?.find((zone: any) => zone.id === selectedZone);
			setSelectedZoneObject(zone);
		}
	}, [selectedZone, zones]);

	const hasPressureApp = hasApp(
		!!user?.user_data?.is_superAdmin,
		analysisPermessions,
		applicationIdMap.get(UserApps.PRESSURE),
		selectedNetwork
	);
	const hasWaterConsumptionApp = hasApp(
		!!user?.user_data?.is_superAdmin,
		analysisPermessions,
		applicationIdMap.get(UserApps.WATER_CONSUMPTION),
		selectedNetwork
	);
	const hasWaterProductionApp = hasApp(
		!!user?.user_data?.is_superAdmin,
		analysisPermessions,
		applicationIdMap.get(UserApps.WATER_PRODUCTION),
		selectedNetwork
	);
	const hasMNFApp = hasApp(
		!!user?.user_data?.is_superAdmin,
		analysisPermessions,
		applicationIdMap.get(UserApps.MINIMUM_NIGHTLY_FLOW),
		selectedNetwork
	);
	const hasLeakageApp = hasApp(
		!!user?.user_data?.is_superAdmin,
		analysisPermessions,
		applicationIdMap.get(UserApps.LEAKAGE_ANALYSIS),
		selectedNetwork
	);

	const pressureTabChildren = (
		<AnalysisTab
			icon={<TbGauge size={20} className={i18n.language === 'en' ? '' : 'ms-1'} />}
			title={t('Pressure')}
		/>
	);
	const waterConsumptionChildren = (
		<AnalysisTab
			icon={<BsWater size={20} className={i18n.language === 'en' ? '' : 'ms-1'} />}
			title={t('WaterConsumption')}
		/>
	);
	const waterProductionChildren = (
		<AnalysisTab
			icon={<GiWaterTower size={20} className={i18n.language === 'en' ? '' : 'ms-1'} />}
			title={t('WaterProduction')}
		/>
	);
	const mnfChildren = (
		<AnalysisTab
			icon={<TiFlowSwitch size={20} className={i18n.language === 'en' ? '' : 'ms-1'} />}
			title={t('MinimumNightlyFlow')}
		/>
	);
	const leakageChildren = (
		<AnalysisTab
			icon={<GiLeak size={20} className={i18n.language === 'en' ? '' : 'ms-1'} />}
			title={t('LeakageAnalysis')}
		/>
	);

	const tabItems = clearArrayUndefined([
		{
			label: (
				<ComponentGuard
					allowed={hasPressureApp}
					fallback={() => <Premium showIcon children={pressureTabChildren} />}
				>
					{pressureTabChildren}
				</ComponentGuard>
			),
			key: 'pressure',
			children: hasPressureApp ? (
				<ZoneAnalysisPressureTab
					zoneName={
						selectedZoneObject &&
						(i18n.language === 'en' ? selectedZoneObject?.name_en : selectedZoneObject?.name_ar)
					}
				/>
			) : (
				<Empty />
			),
			disabled: !hasPressureApp
		},
		!!measurementPoints?.find(site => site.type === Sites.WATER_STATION) && {
			label: (
				<ComponentGuard
					allowed={hasWaterProductionApp}
					fallback={() => <Premium showIcon children={waterProductionChildren} />}
				>
					{waterProductionChildren}
				</ComponentGuard>
			),
			key: 'water_production',
			children: hasWaterProductionApp ? (
				<ZoneAnalysisWaterProductionTab
					zoneName={
						selectedZoneObject &&
						(i18n.language === 'en' ? selectedZoneObject?.name_en : selectedZoneObject?.name_ar)
					}
				/>
			) : (
				<Empty />
			),
			disabled: !hasWaterProductionApp
		},
		{
			label: (
				<ComponentGuard
					allowed={hasWaterConsumptionApp}
					fallback={() => <Premium showIcon children={waterConsumptionChildren} />}
				>
					{waterConsumptionChildren}
				</ComponentGuard>
			),
			key: 'water_consumption',
			children: hasWaterConsumptionApp ? (
				<ZoneAnalysisWaterConsumptionTab
					zoneName={
						selectedZoneObject &&
						(i18n.language === 'en' ? selectedZoneObject?.name_en : selectedZoneObject?.name_ar)
					}
				/>
			) : (
				<Empty />
			),
			disabled: !hasWaterConsumptionApp
		},
		{
			label: (
				<ComponentGuard allowed={hasMNFApp} fallback={() => <Premium showIcon children={mnfChildren} />}>
					{mnfChildren}
				</ComponentGuard>
			),
			key: 'minimum_nightly_flow',
			children: hasMNFApp ? (
				<ZoneAnalysisMNFTab
					zoneName={
						selectedZoneObject &&
						(i18n.language === 'en' ? selectedZoneObject?.name_en : selectedZoneObject?.name_ar)
					}
				/>
			) : (
				<Empty />
			),
			disabled: !hasMNFApp
		},
		{
			label: (
				<ComponentGuard
					allowed={hasLeakageApp}
					fallback={() => <Premium showIcon children={leakageChildren} />}
				>
					{leakageChildren}
				</ComponentGuard>
			),
			key: 'leakage_analysis',
			children: hasLeakageApp ? (
				<ZoneLeakageAnalysisTab
					zoneName={
						selectedZoneObject &&
						(i18n.language === 'en' ? selectedZoneObject?.name_en : selectedZoneObject?.name_ar)
					}
				/>
			) : (
				<Empty />
			),
			disabled: !hasLeakageApp
		}
	]);

	return selectedZone ? (
		<Spin spinning={loadingAlerts || loadingFiles || loadingZoneParameters}>
			<h3 className="mb-3">{t('ZoneAnalysis')}</h3>
			<Row gutter={24}>
				<Col xs={24} xl={12} xxl={14}>
					<Row>
						<Col xl={24}>
							<ZoneStaticInformation zone={selectedZoneObject} zoneParameters={zoneParameters} />
						</Col>
						{desktopView ? null : (
							<Col xs={24} className="mb-4">
								<MonitoringMap
									style={{
										height: '590px',
										width: 'auto',
										borderRadius: '13px',
										zIndex: 50
									}}
									networks={networks}
									selectedNetwork={selectedNetwork}
									zones={zones}
									selectedZone={selectedZone}
									// showGisToggler={false}
								/>
							</Col>
						)}
						<Col>
							<Tabs
								defaultActiveKey={selectedTab || undefined}
								type="card"
								style={{ fontWeight: '500' }}
								tabBarExtraContent={
									<Link
										to={`/dashboard/advanced-analysis`}
										media={media}
										style={{ color: colors.GRAPH_PURPLE, fontWeight: 'bold' }}
									>
										<FiCornerDownRight
											style={i18n.language === 'en' ? {} : { transform: 'scaleX(-1)' }}
											className="me-2"
										/>
										{t('goToPlace', { place: t('AdvancedAnalysis') })}
									</Link>
								}
								items={tabItems}
							/>
						</Col>
					</Row>
					<Row className="mt-4">
						<AlertsTableSummary
							zoneId={selectedZone}
							alerts={alerts?.results || []}
							className="w-100"
							loading={loadingAlerts}
						/>
					</Row>
				</Col>
				<Col xs={24} xl={12} xxl={10}>
					{desktopView ? (
						<MonitoringMap
							style={{
								height: '590px',
								width: 'auto',
								borderRadius: '13px',
								zIndex: 50
							}}
							networks={networks}
							selectedNetwork={selectedNetwork}
							zones={zones}
							selectedZone={selectedZone}
							// showGisToggler={false}
						/>
					) : null}
					{selectedZoneObject ? (
						<Row gutter={24} className="mt-4">
							<Col xs={12}>
								<DocumentsCard
									documents={files}
									onDocumentClick={url => {
										setPdfUrl(url);
										setPdfVisible(true);
									}}
								/>
							</Col>
						</Row>
					) : null}
				</Col>
			</Row>
			<PDFViewer
				onCancel={() => {
					setPdfVisible(false);
					setPdfUrl('');
				}}
				visible={pdfVisible}
				url={pdfUrl}
			/>
		</Spin>
	) : null;
};
