import { UserRights } from '../types';

export const hasRight = (
	superAdmin: boolean,
	permissions: any,
	appId: string,
	right: UserRights,
	networkId?: string,
	secondRight?: UserRights
): boolean => {
	if (superAdmin) {
		return true;
	}
	const permission = permissions?.find((permission: any) => {
		if (permission.app === appId && (permission.rights[right] || (secondRight && permission.rights[secondRight]))) {
			if ((!networkId && right === UserRights.CREATE) || (!networkId && right === UserRights.READ))
				return permission;
			else if (networkId && permission.scope.replace(/-/g, '') === networkId.replace(/-/g, '')) {
				return permission;
			}
		}
	});
	return !!permission;
};
