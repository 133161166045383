import { useContext, useEffect, useState } from 'react';
import { Form, Input, InputNumber, Select, Row, Col, Button, Spin, Modal, Space, Tag, Upload, Popconfirm } from 'antd';
import { AiOutlineUpload } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import monitoringService from '../../services/monitoring.service';
import { useQueryParam } from '../../hooks/use-query';
import { notifySuccess } from '../../utils/notification-messages';
import { hasRight } from '../../utils/has-right';
import { UserRights, UserApps, Zones } from '../../types';
import { AuthenticationContext } from '../../contexts';
import { AiOutlinePlus } from 'react-icons/ai';
import { upperSnakeToCapitalized } from '../../utils/upper-snake-to-capitalized';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { colors } from '../../constants/colors';
import { UploadProps } from 'antd/es/upload';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AppRoutes } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { modalConfirm } from '../modal-confirm';
import { SubmitCanelButtons } from '../submit-cancel-buttons';
import { GISLayerForm } from '../gis-layer-form';

export const ZoneForm: React.FC<any> = ({
	selectedLocation,
	setSelectedLocation,
	zoneData,
	activatedZone,
	setActivePanel
}) => {
	const { t, i18n } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { networks, errorHandler, refetchNetworks, refetchZones, refetchAllZones, applicationIdMap } =
		navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user, configurationPermessions } = authContext;

	const [networksData, setNetworksData] = useState<any>([]);
	const [gisLayers, setGisLayers] = useState<any>([]);
	const [visibleGISModal, setVisibleGISModal] = useState<boolean>(false);
	const [fileList, setFileList] = useState<any[]>([]);
	const [deletedFiles, setDeletedFiles] = useState<string[]>([]);
	const [editFileUid, setEditFileUid] = useState<string | null>(null);
	const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const zoneId = useQueryParam('zoneId');
	const type = useQueryParam('type');
	const [form] = Form.useForm();
	const [gisForm] = Form.useForm();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	// load zone files
	const {
		data: zoneFiles,
		isLoading: loadingZoneFiles,
		isFetching: fetchingZoneFiles,
		refetch: refechZoneFiles
	} = useQuery<any>({
		queryKey: ['files', zoneId],
		queryFn: () => zoneId && monitoringService.getZoneFiles(zoneId),
		enabled: !!zoneId
	});
	// get gis Layers when editing zone
	const {
		data: gisLayersData,
		isLoading: loadingGisLayers,
		isFetching: fetchingGisLayers,
		refetch: refechGisLayers
	} = useQuery<any>({
		queryKey: ['gis-layers', zoneId],
		queryFn: () => zoneId && monitoringService.getZoneGisLayers(zoneId),
		enabled: !!zoneId
	});

	// load zone data in the form and map
	useEffect(() => {
		if (
			zoneData &&
			zoneId &&
			!(loadingGisLayers && fetchingGisLayers) &&
			!(loadingZoneFiles && fetchingZoneFiles)
		) {
			resetFields();
		}
		// TODO: hide old gis layers until adding editing layers on map
		// gisLayersData = gisLayersData.map(layer => {
		// 	return {
		// 		...layer,
		// 		geojson: JSON.stringify(layer.geojson)
		// 	};
		// });
	}, [zoneData, zoneFiles, zoneId, loadingGisLayers, fetchingGisLayers, loadingZoneFiles, fetchingZoneFiles]);

	// get networks that user can create or edit it
	useEffect(() => {
		let networksUser =
			networks &&
			networks.filter(
				(network: any) =>
					hasRight(
						!!user?.user_data?.is_superAdmin,
						configurationPermessions,
						applicationIdMap.get(UserApps.ZONE),
						UserRights.CREATE,
						network.id
					) ||
					hasRight(
						!!user?.user_data?.is_superAdmin,
						configurationPermessions,
						applicationIdMap.get(UserApps.ZONE),
						UserRights.EDIT,
						network.id
					)
			);
		setNetworksData(networksUser);
	}, [networks, user, configurationPermessions, applicationIdMap]);

	// check before uploading file for the stype and ize of that file
	const beforeFileUpload = file => {
		const isLt5M = file.size / 1024 / 1024 < 5;
		const allowedFileTypes = [
			'application/pdf',
			'application/msword',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'text/plain',
			'text/csv',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		];
		if (!isLt5M) {
			errorHandler({ message: t('FileMustBeSmallerThanSize', { ns: 'validation' }) });
		}
		if (!allowedFileTypes.includes(file.type)) {
			errorHandler({ message: t('FileTypeNotSupported', { ns: 'validation' }) });
		}
		return isLt5M && allowedFileTypes.includes(file.type);
	};

	// handle changing of files
	const handleFileChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		// check for the size of file before adding it into fileList
		if (newFileList.length > 0) {
			const newFile: any = newFileList[newFileList.length - 1];
			if (!newFile?.oldFile) {
				const isLt5M = newFile.size / 1024 / 1024 < 5;
				if (!isLt5M) return false;
			}
		}
		setFileList(newFileList);
	};

	// handle editing name of file
	const handleEditFileName = (uid, newName) => {
		const updatedFileList = fileList.map(file => {
			if (file.uid === uid) {
				return { ...file, name: newName };
			}
			return file;
		});
		setFileList(updatedFileList);
	};

	// handle deleting file
	const handleDeleteFile = uid => {
		setEditFileUid(null);
		const updatedFileList = fileList.filter(file => file.uid !== uid);
		setFileList(updatedFileList);
	};

	// track changing GIS layers from the default data saved
	useEffect(() => {
		if (zoneData && !(loadingGisLayers && fetchingGisLayers)) {
			if (JSON.stringify(gisLayersData) !== JSON.stringify(gisLayers)) {
				setIsFormChanged(true);
			} else {
				setIsFormChanged(false);
			}
		} else {
			if (gisLayers.length > 0) {
				setIsFormChanged(true);
			}
		}
	}, [gisLayers, zoneData, loadingGisLayers, fetchingGisLayers]);

	// track changing files from the default data saved
	useEffect(() => {
		if (zoneData) {
			if (
				JSON.stringify(
					zoneFiles?.map(file => {
						return { oldFile: true };
					})
				) !==
				JSON.stringify(
					fileList.map(file => {
						return { oldFile: file.oldFile };
					})
				)
			) {
				setIsFormChanged(true);
			} else {
				setIsFormChanged(false);
			}
		} else {
			if (fileList.length > 0) {
				setIsFormChanged(true);
			}
		}
	}, [fileList, zoneData]);

	// on finish creating/editing zone
	const onFinish = async (values: any) => {
		setSubmitting(true);
		Object.keys(values).forEach(key => {
			if (values[key] === '' || values[key] === undefined || values[key] === null) {
				delete values[key];
			}
		});
		values['gis_layers'] = gisLayers?.map(ele => {
			if (typeof ele.geojson === 'string') {
				try {
					ele.geojson = JSON.parse(ele.geojson);
				} catch (error) {
					console.error('Invalid JSON string:', error);
				}
			}
			return ele;
		});
		if (zoneId) {
			try {
				const resp = await monitoringService.editZone(zoneId, values);
				if (!resp) throw new Error(t('couldntEditZone', { ns: 'validation' }));
				// upload new files to zone if any exist
				const newFiles = fileList.filter(file => !file?.oldFile);
				if (newFiles.length > 0) {
					const fd_files = new FormData();
					for (const file of newFiles) {
						fd_files.append('file', file.originFileObj);
					}
					const respFiles = await monitoringService.addZoneFiles(zoneData.id, fd_files);
					if (!respFiles) throw new Error(t('couldntAddZoneFiles', { ns: 'validation' }));
				}
				// delete files for zone if any exist in deletedFiles
				if (deletedFiles.length > 0) {
					await deletedFiles.map(fileId => monitoringService.deleteFile(fileId));
				}
				queryClient.refetchQueries(['zone', zoneId]);
				refechGisLayers();
				refechZoneFiles();
				refetchNetworks();
				refetchZones();
				refetchAllZones();
				setActivePanel([]);
				notifySuccess(t('editedSuccessfully'));
			} catch (e: any) {
				errorHandler(e);
			}
		} else {
			values['is_active'] = activatedZone ?? false;
			try {
				const resp = await monitoringService.createZone(values);
				if (!resp) throw new Error(t('couldntAddZone', { ns: 'validation' }));
				// upload files for the new zone if any exist
				if (fileList.length > 0) {
					const fd_files = new FormData();
					for (const file of fileList) {
						fd_files.append('file', file.originFileObj);
					}
					const respFiles = await monitoringService.addZoneFiles(resp.id, fd_files);
					if (!respFiles) throw new Error(t('couldntAddZoneFiles', { ns: 'validation' }));
				}
				refetchNetworks();
				refetchZones();
				refetchAllZones();
				setActivePanel([]);
				notifySuccess(t('addedSuccessfully'));
				navigate(`${AppRoutes.CONFIGURATION}?tabSelected=zones`);
			} catch (e: any) {
				errorHandler(e?.detail ? { message: e.detail } : e);
			}
		}
		setSubmitting(false);
	};

	// confirm before submition
	const onConfirm = values => {
		try {
			modalConfirm({
				onOk: async () => {
					await onFinish(values);
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	// reset fields
	const resetFields = () => {
		if (
			zoneData &&
			zoneId &&
			!(loadingGisLayers && fetchingGisLayers) &&
			!(loadingZoneFiles && fetchingZoneFiles)
		) {
			// fill form with the selected data
			form.setFieldsValue({
				...zoneData
			});
			setSelectedLocation({ latitude: zoneData.latitude, longitude: zoneData.longitude });
			setGisLayers(gisLayersData);
			setFileList(
				zoneFiles.map(file => {
					return { url: file.file, uid: file.id, oldFile: true };
				})
			);
		} else {
			form.resetFields();
			setSelectedLocation({ latitude: undefined, longitude: undefined });
			setGisLayers([]);
			setFileList([]);
		}
		setIsFormChanged(false);
	};

	return (
		<>
			<Spin
				spinning={
					submitting || (loadingZoneFiles && fetchingZoneFiles) || (loadingGisLayers && fetchingGisLayers)
				}
			>
				<Form
					form={form}
					layout="vertical"
					onFinish={onConfirm}
					initialValues={{ is_active: false, type: type ?? Zones.DMA }}
					onValuesChange={() => setIsFormChanged(true)}
				>
					<Form.Item
						name="network"
						label={t('RelatedNetwork')}
						rules={[{ required: true, message: t('SelectRelatedNetwork', { ns: 'validation' }) }]}
					>
						<Select
							placeholder={t('RelatedNetwork')}
							options={
								networksData &&
								networksData.map((network: any) => {
									return {
										value: network.id,
										label:
											i18n.language === 'en'
												? network.name_en
												: network?.name_ar || network.name_en
									};
								})
							}
						/>
					</Form.Item>
					<Row gutter={16}>
						<Col xs={12}>
							<Form.Item
								name="name_en"
								label={t('EnglishName')}
								rules={[{ required: true, message: t('AddZoneNameInEnglish', { ns: 'validation' }) }]}
							>
								<Input type="text" placeholder={t('ZoneNameInEnglish')} />
							</Form.Item>
						</Col>
						<Col xs={12}>
							<Form.Item
								name="name_ar"
								label={t('ArabicName')}
								rules={[{ required: true, message: t('AddZoneNameInArabic', { ns: 'validation' }) }]}
							>
								<Input type="text" placeholder={t('ZoneNameInArabic')} />
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						name="description_en"
						label={t('EnglishDescription')}
						rules={[{ required: true, message: t('AddZoneDescriptionInEnglish', { ns: 'validation' }) }]}
					>
						<Input.TextArea maxLength={255} placeholder={t('DescribeZoneInEnglish')} />
					</Form.Item>
					<Form.Item
						name="description_ar"
						label={t('ArabicDescription')}
						rules={[{ required: true, message: t('AddZoneDescriptionInArabic', { ns: 'validation' }) }]}
					>
						<Input.TextArea maxLength={255} placeholder={t('DescribeZoneInArabic')} />
					</Form.Item>
					<Form.Item label={t('LatitudeAndLongitude')} style={{ marginBottom: 0 }}>
						<Row className="w-100">
							<Col xs={11}>
								<Form.Item
									name="latitude"
									rules={[{ required: true, message: t('PleaseAddLatitude', { ns: 'validation' }) }]}
								>
									<InputNumber
										placeholder={t('Latitude')}
										style={{ width: '100%' }}
										max={90}
										onChange={val => setSelectedLocation({ ...selectedLocation, latitude: val })}
									/>
								</Form.Item>
							</Col>
							<Col xs={{ span: 12, offset: 1 }}>
								<Form.Item
									name="longitude"
									rules={[{ required: true, message: t('PleaseAddLongitude', { ns: 'validation' }) }]}
								>
									<InputNumber
										placeholder={t('Longitude')}
										style={{ width: '100%' }}
										max={90}
										onChange={val => setSelectedLocation({ ...selectedLocation, longitude: val })}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Form.Item>
					<Row gutter={16}>
						<Col xs={12}>
							<Form.Item
								name="subscriber_no"
								label={t('SubscriberNumber')}
								rules={[{ required: true, message: t('AddSubscriberNumber', { ns: 'validation' }) }]}
							>
								<InputNumber placeholder={t('SubscriberNumber')} style={{ width: '100%' }} />
							</Form.Item>
						</Col>
						<Col xs={12}>
							<Form.Item name="type" label={t('Type')}>
								<Select
									options={Object.values(Zones).map(zoneType => {
										return {
											value: zoneType,
											label: t(zoneType)
										};
									})}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col xs={12}>
							<Form.Item label={t('GISLayers')} style={{ marginBottom: 0 }}>
								<Button
									onClick={() => setVisibleGISModal(true)}
									type={'primary'}
									ghost
									icon={<AiOutlinePlus />}
									className={'mb-3'}
								>
									{t('AddGISLayer')}
								</Button>
							</Form.Item>
							<Space size={[0, 8]} wrap>
								{gisLayers &&
									gisLayers.map((layer: any, idx: number) => {
										return (
											<Tag key={idx}>
												{layer.name_en ? `${upperSnakeToCapitalized(layer.name_en)}-` : null}
												{upperSnakeToCapitalized(layer.type)}
											</Tag>
										);
									})}
							</Space>
						</Col>
						<Col xs={12}>
							<Form.Item label={t('UploadFiles')}>
								<Upload
									listType="text"
									fileList={fileList}
									accept=".pdf , .docx , .txt , .csv , .xlsx"
									beforeUpload={beforeFileUpload}
									onChange={handleFileChange}
									onRemove={(file: any) => {
										if (file?.oldFile) {
											setDeletedFiles([...deletedFiles, file.uid]);
										}
										const index = fileList.indexOf(file);
										const newFileList = fileList.slice();
										newFileList.splice(index, 1);
										setFileList(newFileList);
									}}
									customRequest={({ file, onSuccess }) => {
										setTimeout(() => {
											onSuccess && onSuccess('ok');
										}, 0);
									}}
									showUploadList={false}
								>
									<Button icon={<AiOutlineUpload />} type={'primary'} ghost>
										{t('Upload')}
									</Button>
									<div className="my-1">
										<small style={{ color: colors.INACTIVE, display: 'block' }}>
											{t('SupportFiles')}
										</small>
										<small style={{ color: colors.INACTIVE, display: 'block' }}>
											{t('MaxSize')}
										</small>
									</div>
								</Upload>
								{fileList.map(file => (
									<div key={file.uid} className="d-flex justify-content-between align-items-center">
										<div style={{ width: '80%' }}>
											{editFileUid === file.uid ? (
												<Input
													value={file.name}
													onChange={e => {
														handleEditFileName(file.uid, e.target.value);
													}}
													onBlur={() => setEditFileUid(null)}
													onPressEnter={() => setEditFileUid(null)}
													width={'100%'}
												/>
											) : (
												file.name
											)}
										</div>
										<div
											style={{ width: '20%' }}
											className="d-flex justify-content-end align-items-center"
										>
											<Button
												icon={<FaRegEdit size={20} color={colors.WAI_BLUE} />}
												type="link"
												onClick={() => setEditFileUid(file.uid)}
											/>
											<Space>
												<Popconfirm
													title={t('AreYouSureToDeleteThisFile', { ns: 'validation' })}
													onConfirm={() => handleDeleteFile(file.uid)}
													okText={t('Yes', { ns: 'common' })}
													cancelText={t('No', { ns: 'common' })}
												>
													<Button
														type="link"
														icon={<RiDeleteBinLine size={20} color={colors.ERROR} />}
													/>
												</Popconfirm>
											</Space>
										</div>
									</div>
								))}
							</Form.Item>
						</Col>
					</Row>
					<div className="d-flex justify-content-end">
						<SubmitCanelButtons
							handleCancel={() => resetFields()}
							handleSubmit={form.submit}
							reset={true}
							disabled={!isFormChanged}
						/>
					</div>
					{/* <Form.List name="gis_layers">
						{(fields, { add, remove }) => {
							return (
								<div>
									{fields.map((field, idx) => (
										<div key={field.key}>
											<Row className="w-100" key={idx}>
												<Col xs={8}>
													<Form.Item
														name={[idx, 'type']}
														rules={[
															{
																required: true,
																message: t('SelectGISLayerType', { ns: 'validation' })
															}
														]}
													>
														<Select
															placeholder={t('GISLayerType')}
															options={Object.values(GISLayerType).map(type => {
																return {
																	value: type,
																	label: upperSnakeToCapitalized(type)
																};
															})}
														/>
													</Form.Item>
												</Col>
												<Col
													xs={{ span: 15, offset: 1 }}
													className={'mb-2 d-flex justify-content-between'}
												>
													<Form.Item
														name={[idx, 'geojson']}
														rules={[
															{
																required: true,
																message: t('AddJSONGISLayer', { ns: 'validation' })
															}
														]}
														style={{ width: '100%' }}
													>
														<TextArea
															placeholder={t('AddInJSONFormat')}
															autoSize={{ minRows: 8, maxRows: 8 }}
															onChange={() => setJSONFormatError(false)}
														/>
													</Form.Item>
													<Tooltip title={t('Remove', { ns: 'common' })}>
														<TableControlItem
															onClick={() => {
																remove(field.name);
															}}
														>
															<RiDeleteBinLine color={colors.ERROR} />
														</TableControlItem>
													</Tooltip>
												</Col>
											</Row>
										</div>
									))}
									{JSONFormatError ? (
										<p className="text-danger"> {t('AddRightJSONFormat')}</p>
									) : null}
									<Button onClick={() => add()} type="link" icon={<AiOutlinePlus />}>
										{t('AddGISLayer')}
									</Button>
								</div>
							);
						}}
					</Form.List> */}
				</Form>
				<Modal
					title={t('AddGISLayer')}
					open={visibleGISModal}
					onCancel={() => {
						setVisibleGISModal(false);
						gisForm.resetFields();
					}}
					onOk={() => gisForm.submit()}
				>
					<GISLayerForm
						gisForm={gisForm}
						onGISLayerFinish={async values => {
							setGisLayers([...gisLayers, values]);
							setVisibleGISModal(false);
						}}
					/>
				</Modal>
			</Spin>
		</>
	);
};
