import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import produce from 'immer';
import { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { WaiWarning } from '../components';
import { colors } from '../constants/colors';
import { GraphsCarousel, MonitoringMap } from '../containers';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import alertsService from '../services/alerts.service';
import chartsService from '../services/charts.service';
import monitoringService from '../services/monitoring.service';
import { SpanType, UserApps, UserRights } from '../types';
import { ChartCustomSpan, chartTypes, IChart } from '../types/charts';
import { DataSources, FlowRateType, PressureType } from '../types/data-sources';
import { PressureUnit } from '../types/pressure-unit';
import { ValveControlType, ValveMaintenanceReminder, ValveOperationStatus } from '../types/valve';
import { Zones } from '../types/zones';
import { clearArrayUndefined } from '../utils/clean-array-undefined';
import { upperSnakeToCapitalized } from '../utils/upper-snake-to-capitalized';
import { Sites } from '../types/sites';
import configService from '../services/config.service';
import { AppRoutes } from '../constants/routes';
import { hasRight } from '../utils';
import { AuthenticationContext } from '../contexts';

export type selectedChartConfigObject = {
	fieldName?: string;
	valueColors: { value?: string; color?: string }[];
	is_valve: boolean;
};

export const MonitoringPage: React.FC = () => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const {
		networks,
		selectedNetwork,
		zones,
		selectedZone,
		selectedCommercialMeterGroup,
		selectedMeasurementPoint,
		setSelectedMeasurementPoint,
		pressureUnit,
		pressureRange,
		setPressureRange,
		measurementPoints,
		applicationIdMap
	} = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user, configurationPermessions } = authContext;

	const [loading, setLoading] = useState<boolean>(false);
	const [loadingCharts, setLoadingCharts] = useState<boolean>(false);
	const [networkCharts, setNetworkCharts] = useState<IChart[]>([]);
	const [zoneCharts, setZoneCharts] = useState<IChart[]>([]);
	const [charts, setCharts] = useState<IChart[]>([]);
	const [alerts, setAlerts] = useState<any>();
	const [selectedChartInfo, setSelectedChartInfo] = useState<selectedChartConfigObject>();
	// REVIEW: review the use of forceUpdate
	const [, forceUpdate] = useReducer(x => x + 1, 0);

	const queryClient = useQueryClient();

	// selected site data
	const { data: measurementPointAlerts } = useQuery<any>({
		queryKey: ['alerts', selectedMeasurementPoint],
		queryFn: () =>
			alertsService.getAlertsNew({
				'page': 1,
				'page_size': 5,
				'measurement-point-id': selectedMeasurementPoint,
				'sorters': { alertEnd: -1 }
			}),
		enabled: !!selectedMeasurementPoint
	});
	const { data: dataSources } = useQuery<any>({
		queryKey: ['data-sources', selectedMeasurementPoint],
		queryFn: () =>
			monitoringService.getDataSources({
				filters: { measurement_point: selectedMeasurementPoint }
			}),
		enabled: !!selectedMeasurementPoint
	});
	const dataSourcesLastLogDate: any = useQueries<any>({
		queries: (
			(dataSources &&
				dataSources?.filter(
					src =>
						!(
							(src.type === DataSources.PRESSURE &&
								(src.stream_direction === PressureType.UPSTREAM ||
									src.stream_direction === PressureType.DOWNSTREAM)) ||
							src.type === DataSources.NOISE
						)
				)) ||
			[]
		).map((src: any) => {
			return {
				queryKey: ['data-source-last-log-date', src.id],
				queryFn: () => chartsService.getDataSourceLastLogDate(src.id),
				enabled: !!src.id
			};
		})
	});
	const { data: valveLastLogDate } = useQuery<any>({
		queryKey: [
			'data-source-last-log-date',
			dataSources?.find(
				(src: any) => src.type === DataSources.PRESSURE && src.stream_direction === PressureType.UPSTREAM
			)?.id
		],
		queryFn: () =>
			chartsService.getDataSourceLastLogDate(
				dataSources?.find(
					(src: any) => src.type === DataSources.PRESSURE && src.stream_direction === PressureType.UPSTREAM
				)?.id
			),
		enabled:
			!!selectedMeasurementPoint &&
			!!dataSources?.find(
				(src: any) => src.type === DataSources.PRESSURE && src.stream_direction === PressureType.UPSTREAM
			)
	});
	const [{ data: upStreamPressureSource }, { data: downStreamPressureSource }]: any = useQueries({
		queries: [
			{
				queryKey: [
					'data-source-logs',
					dataSources?.find(
						(src: any) =>
							src.type === DataSources.PRESSURE && src.stream_direction === PressureType.UPSTREAM
					)?.id,
					{
						start: valveLastLogDate?.time && dayjs(valveLastLogDate.time).subtract(7, 'days').format(),
						end: valveLastLogDate?.time && dayjs(valveLastLogDate.time).format()
					}
				],
				queryFn: () =>
					chartsService.getDSLogs(
						dataSources?.find(
							(src: any) =>
								src.type === DataSources.PRESSURE && src.stream_direction === PressureType.UPSTREAM
						)?.id,
						{
							start: valveLastLogDate?.time && dayjs(valveLastLogDate.time).subtract(7, 'days').format(),
							end: valveLastLogDate?.time && dayjs(valveLastLogDate.time).format(),
							aggregate: 1
						}
					),
				enabled:
					!!selectedMeasurementPoint &&
					!!dataSources?.find(
						(src: any) =>
							src.type === DataSources.PRESSURE && src.stream_direction === PressureType.UPSTREAM
					)?.id &&
					!!valveLastLogDate
			},
			{
				queryKey: [
					'data-source-logs',
					dataSources?.find(
						(src: any) =>
							src.type === DataSources.PRESSURE && src.stream_direction === PressureType.DOWNSTREAM
					)?.id,
					{
						start: valveLastLogDate?.time && dayjs(valveLastLogDate.time).subtract(7, 'days').format(),
						end: valveLastLogDate?.time && dayjs(valveLastLogDate.time).format()
					}
				],
				queryFn: () =>
					chartsService.getDSLogs(
						dataSources?.find(
							(src: any) =>
								src.type === DataSources.PRESSURE && src.stream_direction === PressureType.DOWNSTREAM
						)?.id,
						{
							start: valveLastLogDate?.time && dayjs(valveLastLogDate.time).subtract(7, 'days').format(),
							end: valveLastLogDate?.time && dayjs(valveLastLogDate.time).format(),
							aggregate: 1
						}
					),
				enabled:
					!!selectedMeasurementPoint &&
					!!dataSources?.find(
						(src: any) =>
							src.type === DataSources.PRESSURE && src.stream_direction === PressureType.DOWNSTREAM
					)?.id &&
					!!valveLastLogDate
			}
		]
	});
	const { data: mpWaterConsumptionLast } = useQuery<any>({
		queryKey: ['water-consumption-last', selectedMeasurementPoint],
		queryFn: () =>
			chartsService.getMeasurementPointDailyConsumption(selectedMeasurementPoint, {
				last: true,
				span_type: 'day'
			}),
		enabled:
			!!selectedMeasurementPoint &&
			!!dataSources?.find((src: any) => src.type === DataSources.FLOW_RATE) &&
			!!measurementPoints?.find(
				point => point.id === selectedMeasurementPoint && point.type === Sites.NETWORK_MEASUREMENT_POINT
			)
	});
	const { data: mpWaterConsumptionAll } = useQuery<any>({
		queryKey: ['water-consumption-all', selectedMeasurementPoint],
		queryFn: () =>
			chartsService.getMeasurementPointDailyConsumption(selectedMeasurementPoint, {
				span_type: 'day'
			}),
		enabled:
			!!selectedMeasurementPoint &&
			!!dataSources?.find((src: any) => src.type === DataSources.FLOW_RATE) &&
			!!measurementPoints?.find(
				point => point.id === selectedMeasurementPoint && point.type === Sites.NETWORK_MEASUREMENT_POINT
			)
	});
	const { data: mpMinimumNightlyFlowLast } = useQuery<any>({
		queryKey: ['minimum-nightly-flow-last', selectedMeasurementPoint],
		queryFn: () =>
			selectedMeasurementPoint &&
			chartsService.getMeasurementPointMinNightlyFlow(selectedMeasurementPoint, {
				last: true,
				span_type: 'day'
			}),
		enabled:
			!!selectedMeasurementPoint &&
			!!dataSources?.find((src: any) => src.type === DataSources.FLOW_RATE) &&
			!!measurementPoints?.find(
				point => point.id === selectedMeasurementPoint && point.type === Sites.NETWORK_MEASUREMENT_POINT
			)
	});
	const { data: mpMinimumNightlyFlowAll } = useQuery<any>({
		queryKey: ['minimum-nightly-flow-all', selectedMeasurementPoint],
		queryFn: () =>
			chartsService.getMeasurementPointMinNightlyFlow(selectedMeasurementPoint, {
				span_type: 'day'
			}),
		enabled:
			!!selectedMeasurementPoint &&
			!!dataSources?.find((src: any) => src.type === DataSources.FLOW_RATE) &&
			!!measurementPoints?.find(
				point => point.id === selectedMeasurementPoint && point.type === Sites.NETWORK_MEASUREMENT_POINT
			)
	});
	const { data: mpWaterProductionLast } = useQuery<any>({
		queryKey: ['water-production-last', selectedMeasurementPoint],
		queryFn: () =>
			chartsService.getSiteDailyProduction(selectedMeasurementPoint, {
				last: true,
				span_type: 'day'
			}),
		enabled:
			!!selectedMeasurementPoint &&
			!!dataSources?.find((src: any) => src.type === DataSources.FLOW_RATE) &&
			!!measurementPoints?.find(
				point => point.id === selectedMeasurementPoint && point.type === Sites.WATER_STATION
			)
	});
	const { data: mpWaterProductionAll } = useQuery<any>({
		queryKey: ['water-production-all', selectedMeasurementPoint],
		queryFn: () =>
			chartsService.getSiteDailyProduction(selectedMeasurementPoint, {
				span_type: 'day'
			}),
		enabled:
			!!selectedMeasurementPoint &&
			!!dataSources?.find((src: any) => src.type === DataSources.FLOW_RATE) &&
			!!measurementPoints?.find(
				point => point.id === selectedMeasurementPoint && point.type === Sites.WATER_STATION
			)
	});

	// selected zone data
	const { data: zoneAlerts } = useQuery<any>({
		queryKey: ['alerts', selectedZone],
		queryFn: () =>
			alertsService.getAlertsNew({
				'page': 1,
				'page_size': 5,
				'zone-id': selectedZone,
				'sorters': { alertEnd: -1 }
			}),
		enabled: !!selectedZone
	});
	const { data: zoneFlowAnalysisConfig } = useQuery({
		queryKey: ['zone-flow-analysis-config', selectedZone],
		queryFn: () => selectedZone && configService.getZoneFlowAnalysisConfig(selectedZone),
		enabled: !!selectedZone
	});
	const { data: zoneDataSourcesTypes } = useQuery<any>({
		queryKey: ['zone-data-sources-types', selectedZone],
		queryFn: () => chartsService.getZoneDSTypeCount(selectedZone),
		enabled: !!selectedZone
	});
	const { data: zoneDataSourcesActivation } = useQuery<any>({
		queryKey: ['zone-data-sources-activation', selectedZone],
		queryFn: () => chartsService.getZoneDSActiveCount(selectedZone),
		enabled: !!selectedZone
	});
	const { data: zoneSitesActivation } = useQuery<any>({
		queryKey: ['zone-sites-activation', selectedZone],
		queryFn: () => chartsService.getZoneSitesActiveCount(selectedZone),
		enabled: !!selectedZone
	});
	const { data: AverageZonePressure } = useQuery<any>({
		queryKey: ['average-zone-pressure', selectedZone],
		queryFn: () => chartsService.getAverageZonePressure(selectedZone),
		enabled: !!selectedZone
	});
	const { data: zoneWaterConsmputionLast } = useQuery<any>({
		queryKey: ['zone-water-consumption-last', selectedZone],
		queryFn: () =>
			chartsService.getZoneDailyConsumption(selectedZone, {
				last: true,
				span_type: 'day'
			}),
		enabled: !!selectedZone
	});
	const { data: zoneWaterConsmputionAll } = useQuery<any>({
		queryKey: ['zone-water-consumption-all', selectedZone],
		queryFn: () =>
			chartsService.getZoneDailyConsumption(selectedZone, {
				span_type: 'day'
			}),
		enabled: !!selectedZone
	});
	const { data: zoneWaterProductionLast } = useQuery<any>({
		queryKey: ['water-production-last-reading', selectedZone],
		queryFn: () =>
			chartsService.getZoneDailyProduction(selectedZone, {
				last: true,
				span_type: 'day'
			}),
		enabled: !!selectedZone && !!measurementPoints?.find(site => site.type === Sites.WATER_STATION)
	});
	const { data: zoneWaterProductionAll } = useQuery<any>({
		queryKey: ['water-production-all-data', selectedZone],
		queryFn: () =>
			chartsService.getZoneDailyProduction(selectedZone, {
				span_type: 'day'
			}),
		enabled: !!selectedZone && !!measurementPoints?.find(site => site.type === Sites.WATER_STATION)
	});
	const { data: zoneMinimumNightlyFlowLast } = useQuery<any>({
		queryKey: ['zone-minimum-nightly-flow-last', selectedZone],
		queryFn: () =>
			chartsService.getZoneMinNightlyFlow(selectedZone, {
				last: true,
				span_type: 'day'
			}),
		enabled: !!selectedZone
	});
	const { data: zoneMinimumNightlyFlowAll } = useQuery<any>({
		queryKey: ['zone-minimum-nightly-flow-all', selectedZone],
		queryFn: () => chartsService.getZoneMinNightlyFlow(selectedZone, { span_type: 'day' }),
		enabled: !!selectedZone
	});

	// selected network data
	const { data: networkAlerts } = useQuery<any>({
		queryKey: ['alerts', selectedNetwork],
		queryFn: () =>
			alertsService.getAlertsNew({
				'page': 1,
				'page_size': 5,
				'network-id': selectedNetwork,
				'sorters': { alertEnd: -1 }
			}),
		enabled: !!selectedNetwork
	});
	const { data: networkDataSourcesActivation } = useQuery<any>({
		queryKey: ['network-data-sources-activation', selectedNetwork],
		queryFn: () => chartsService.getNetworkDSAcitveInactiveCount(selectedNetwork),
		enabled: !!selectedNetwork
	});
	const { data: networkSitesActivation } = useQuery<any>({
		queryKey: ['network-sites-activation', selectedNetwork],
		queryFn: () => chartsService.getNetworkSitesAcitveInactiveCount(selectedNetwork),
		enabled: !!selectedNetwork
	});
	const { data: networkZonesActivation } = useQuery<any>({
		queryKey: ['network-zones-activation', selectedNetwork],
		queryFn: () => chartsService.getNetworkZonesAcitveInactiveCount(selectedNetwork),
		enabled: !!selectedNetwork
	});
	const { data: networkDataSourcesTypes } = useQuery<any>({
		queryKey: ['network-data-sources-types', selectedNetwork],
		queryFn: () => chartsService.getNetworkDSTypeCount(selectedNetwork),
		enabled: !!selectedNetwork
	});
	const { data: networkZonesTypes } = useQuery<any>({
		queryKey: ['network-zones-types', selectedNetwork],
		queryFn: () => chartsService.getNetworkZonesTypeCount(selectedNetwork),
		enabled: !!selectedNetwork
	});
	const { data: networkValvesTypes } = useQuery<any>({
		queryKey: ['network-valves-types', selectedNetwork],
		queryFn: () => chartsService.getNetworkValvesTypeCount(selectedNetwork),
		enabled: !!selectedNetwork
	});
	const { data: networkValvesMaintenanceStats } = useQuery<any>({
		queryKey: ['network-valves-maintenance-stats', selectedNetwork],
		queryFn: () => chartsService.getNetworkValvesMaintenanceStats(selectedNetwork),
		enabled: !!selectedNetwork
	});
	const { data: networkValvesStatusStats } = useQuery<any>({
		queryKey: ['network-valves-status-stats', selectedNetwork],
		queryFn: () => chartsService.getNetworkValvesStatusStats(selectedNetwork),
		enabled: !!selectedNetwork
	});

	const pieChartsFormatter = (
		charts: any[],
		title: string,
		colorPie?: any[],
		dataKeys?: any,
		emphasizable?: boolean,
		fieldName?: string,
		is_valve?: boolean
	) => {
		const chartObject = charts[0];
		delete chartObject['total'];
		delete chartObject['network_id'];
		const data: any[] = [];
		Object.keys(chartObject).forEach(key => {
			data.push({ name: upperSnakeToCapitalized(key), value: chartObject[key] });
		});
		const chart: IChart = {
			title,
			fieldName,
			data,
			type: chartTypes.PIE,
			colorPie: colorPie,
			emphasizable: emphasizable || false,
			dataKeys: dataKeys || [{ name: t('value'), color: colors.PRIMARY_WARM, radius: 20 }],
			is_valve: is_valve || false
		};
		return chart;
	};

	const barChartsFormatter = (
		readings: any,
		title: string,
		dataKeys?: any,
		emphasizable?: boolean,
		fieldName?: string,
		is_valve?: boolean
	) => {
		const readingData: any = [];
		Object.keys(readings).forEach(key => {
			let removed = key.replace('_count', '');
			readingData.push({ name: upperSnakeToCapitalized(removed), value: readings[key], color: dataKeys[key] });
		});
		const chart: IChart = {
			title,
			fieldName,
			data: readingData,
			type: chartTypes.BAR,
			emphasizable: emphasizable || false,
			dataKeys: dataKeys || [{ name: t('value'), color: colors.PRIMARY_WARM, radius: 20 }],
			is_valve: is_valve || false
		};
		return chart;
	};

	const transformAlertsWarnings = (alerts: any) => {
		const messages = alerts.map((alert: any) => {
			return `${upperSnakeToCapitalized(alert.priority)} | ${upperSnakeToCapitalized(
				alert.title_en
			)} | ${upperSnakeToCapitalized(alert.alert_type)} `;
		});
		return messages;
	};

	// get average zone pressure according to span date
	const fetchAverageZonePressure = async (id, span) => {
		return await chartsService.getAverageZonePressure(id, {
			start: span?.start ? dayjs(span?.start).format('YYYY-MM-DD') : undefined
		});
	};

	// get data source logs according to span date
	const fetchDSLogs = async (id, span) => {
		return await chartsService.getDSLogs(id, { ...span, aggregate: 1 });
	};

	const onLineChartSpanChange = useCallback(async (id, span, name) => {
		if (!id) return;
		setLoadingCharts(true);
		try {
			let newData: any = [];
			if (name === t('AverageZonePressure')) {
				newData = await queryClient.fetchQuery(['average-zone-pressure', id, span], () =>
					fetchAverageZonePressure(id, span)
				);
				newData = [(newData && Object.values(newData)[0]) || undefined];
			} else {
				const valvePressureIds = id.split(',');
				if (valvePressureIds.length === 2) {
					newData = await queryClient.fetchQuery(
						['data-source-logs', valvePressureIds[0], valvePressureIds[1], span],
						() =>
							Promise.all([
								fetchDSLogs(valvePressureIds[0], span),
								fetchDSLogs(valvePressureIds[1], span)
							])
					);
				} else {
					newData = await queryClient.fetchQuery(['data-source-logs', id, span], () => fetchDSLogs(id, span));
					newData = [newData];
				}
			}
			setCharts(
				produce(draft => {
					const changedChart = draft?.find(d => d.id === id);
					if (changedChart) {
						changedChart.data = newData[0];
						changedChart.dataSecond = newData[1] || undefined;
					}
				})
			);
			//REVIEW: review forceUpdate function
			forceUpdate(); // force re-render
		} catch (error) {
			console.error('Error fetching chart data:', error);
		} finally {
			setLoadingCharts(false);
		}
	}, []);

	const onChartSelect = async (fieldName?: string, coloredData?: any[], is_valve?: boolean) => {
		if (fieldName !== undefined && charts && coloredData) {
			const configObject: selectedChartConfigObject = {
				fieldName,
				valueColors: coloredData.map((d: any, i: number) => {
					return { value: d.name, color: d.color, radius: d.radius };
				}),
				is_valve: is_valve || false
			};
			setSelectedChartInfo(configObject);
		} else setSelectedChartInfo(undefined);
	};

	//REVIEW: review this useEffect (used to track charts state)
	useEffect(() => {}, [charts]);

	// set pressure range
	useEffect(() => {
		switch (pressureUnit[1]) {
			case PressureUnit.BAR:
				setPressureRange([0, 10]);
				break;
			case PressureUnit.METER:
				setPressureRange([0, 100]);
				break;
			case PressureUnit.PSI:
				setPressureRange([0, 145]);
				break;
		}
	}, [setPressureRange, pressureUnit]);

	// set alerts
	useEffect(() => {
		if (selectedMeasurementPoint) {
			setAlerts(measurementPointAlerts ? transformAlertsWarnings(measurementPointAlerts.results) : undefined);
		} else if (selectedZone) {
			setAlerts(zoneAlerts ? transformAlertsWarnings(zoneAlerts.results) : undefined);
		} else if (selectedNetwork) {
			setAlerts(networkAlerts ? transformAlertsWarnings(networkAlerts.results) : undefined);
		}
	}, [selectedNetwork, selectedZone, selectedMeasurementPoint]);

	// set selected measurement point
	const onMeasurementPointClick = (point: string) => {
		setSelectedMeasurementPoint(point);
		onChartSelect();
	};

	// retun to previous charts when popup MP closed
	const onMeasurementPointClose = () => {
		setCharts(
			selectedMeasurementPoint
				? charts
				: selectedZone || selectedCommercialMeterGroup
				? zoneCharts
				: networkCharts
		);
		onChartSelect();
	};

	// set chart for selected measuremet point
	useEffect(() => {
		if (selectedMeasurementPoint) {
			onChartSelect();
			setLoading(true);
			let logObjects: IChart[] = [];
			const point = measurementPoints && measurementPoints.find(point => point.id === selectedMeasurementPoint);

			// load charts for selected measuremnt point data sources logs
			if (dataSourcesLastLogDate && dataSourcesLastLogDate.every(src => src.isSuccess)) {
				const dataSourcesInfo =
					dataSources &&
					dataSources
						.filter(
							(src: any) =>
								src.type !== DataSources.NOISE &&
								!(
									src.type === DataSources.PRESSURE &&
									(src.stream_direction === PressureType.UPSTREAM ||
										src.stream_direction === PressureType.DOWNSTREAM)
								)
						)
						?.map((source: any) => {
							return {
								id: source.id,
								type: source.type,
								sourceType: source.type,
								streamDirection: source.stream_direction,
								range: source.type === DataSources.PRESSURE ? pressureRange : undefined,
								unit: source.type === DataSources.PRESSURE ? pressureUnit[1] : source.log_unit,
								name: upperSnakeToCapitalized(source.type)
							};
						});
				if (dataSourcesInfo && dataSourcesInfo.length > 0) {
					logObjects.push(
						...dataSourcesInfo.map((src: any, idx: number) => {
							return {
								id: src.id,
								title: src.name,
								sourceType: src.sourceType,
								range: src.range,
								unit: src.unit,
								analysisRedirectUri: '/dashboard/analysis?selectedTab=data_sources',
								dataKeys: [{ name: t('value'), unit: src.log_unit, color: colors.GRAPH_PURPLE }],
								data: [],
								type: chartTypes.LINE,
								lastReadingDate:
									dataSourcesLastLogDate?.length && dataSourcesLastLogDate[idx].data.time,
								flags: { aggregate: true },
								showSpanSelector: true,
								sourceName: point?.name_en
							};
						})
					);
				}
			}

			// load charts for selected site that has flow rate data soucre (not water station)
			if (
				mpMinimumNightlyFlowAll &&
				mpMinimumNightlyFlowLast &&
				mpWaterConsumptionAll &&
				mpWaterConsumptionLast
			) {
				mpWaterConsumptionLast?.data.length &&
					logObjects.push({
						id: selectedMeasurementPoint,
						type: chartTypes.NUMERICAL,
						name: FlowRateType.DWC,
						analysisRedirectUri: '/dashboard/analysis?selectedTab=water_consumption',
						data: [parseFloat(mpWaterConsumptionLast.data[0].value).toFixed(2)],
						title: t('DailyWaterConsumption'),
						dateText: t('LastReadingAtDate', {
							date: dayjs(mpWaterConsumptionLast.data[0].time).format('Do MMM YYYY')
						}),
						unit: <span>{mpWaterConsumptionLast.unit}</span>,
						allData: [mpWaterConsumptionAll],
						showTabular: true,
						percentage: mpWaterConsumptionLast?.preceding_reading?.percentage_change || undefined,
						increase: mpWaterConsumptionLast?.preceding_reading?.trend_direction === 'up' ? true : false,
						positive: mpWaterConsumptionLast?.preceding_reading?.trend_direction === 'up' ? false : true,
						lastReadingDate:
							mpWaterConsumptionLast?.data?.length > 0 && mpWaterConsumptionLast.data[0].time,
						spanType: SpanType.DAY,
						sourceName: point?.name_en
					});

				mpMinimumNightlyFlowLast?.data.length &&
					logObjects.push({
						id: selectedMeasurementPoint,
						type: chartTypes.NUMERICAL,
						analysisRedirectUri: '/dashboard/analysis?selectedTab=minimum_nightly_flow',
						name: FlowRateType.MNF,
						data: [parseFloat(mpMinimumNightlyFlowLast.data[0].value).toFixed(2)],
						title: t('MinimumNightlyFlow'),
						dateText: t('LastReadingAtDate', {
							date: dayjs(mpMinimumNightlyFlowLast.data[0].time).format('Do MMM YYYY')
						}),
						unit: <span>{mpMinimumNightlyFlowLast.unit}</span>,
						allData: [mpMinimumNightlyFlowAll],
						showTabular: true,
						percentage: mpMinimumNightlyFlowLast?.preceding_reading?.percentage_change || undefined,
						increase: mpMinimumNightlyFlowLast?.preceding_reading?.trend_direction === 'up' ? true : false,
						positive: mpMinimumNightlyFlowLast?.preceding_reading?.trend_direction === 'up' ? false : true,
						lastReadingDate:
							mpMinimumNightlyFlowLast?.data?.length > 0 && mpMinimumNightlyFlowLast.data[0].time,
						spanType: SpanType.DAY,
						sourceName: point?.name_en
					});
			}
			// load charts for selected water station site
			if (mpWaterProductionLast && mpWaterProductionAll) {
				mpWaterProductionLast?.data.length &&
					logObjects.push({
						id: selectedMeasurementPoint,
						type: chartTypes.NUMERICAL,
						analysisRedirectUri: '/dashboard/analysis?selectedTab=water_production',
						name: FlowRateType.DWP,
						data: [parseFloat(mpWaterProductionLast.data[0].value).toFixed(2)],
						title: t('WaterProduction'),
						dateText: t('LastReadingAtDate', {
							date: dayjs(mpWaterProductionLast.data[0].time).format('Do MMM YYYY')
						}),
						unit: <span>{mpWaterProductionLast.unit}</span>,
						allData: [mpWaterProductionAll],
						showTabular: true,
						percentage: mpWaterProductionLast?.preceding_reading?.percentage_change || undefined,
						increase: mpWaterProductionLast?.preceding_reading?.trend_direction === 'up' ? true : false,
						positive: mpWaterProductionLast?.preceding_reading?.trend_direction === 'up' ? false : true,
						lastReadingDate: mpWaterProductionLast?.data?.length > 0 && mpWaterProductionLast.data[0].time,
						spanType: SpanType.DAY,
						sourceName: point?.name_en
					});
			}

			// load charts for selected measuremnt point that has valve data soucre
			if (valveLastLogDate && upStreamPressureSource && downStreamPressureSource) {
				const upstreamPressure =
					dataSources &&
					dataSources.find(
						(src: any) =>
							src.type === DataSources.PRESSURE && src.stream_direction === PressureType.UPSTREAM
					)?.id;
				const downstreamPressure =
					dataSources &&
					dataSources.find(
						(src: any) =>
							src.type === DataSources.PRESSURE && src.stream_direction === PressureType.DOWNSTREAM
					)?.id;
				logObjects.push({
					id: `${upstreamPressure},${downstreamPressure}`,
					idSecond: downstreamPressure.id,
					title: t('upstream'),
					titleSecond: t('downstream'),
					data: upStreamPressureSource ?? [],
					dataSecond: downStreamPressureSource ?? [],
					type: chartTypes.LINE,
					sourceType: DataSources.PRESSURE,
					range: pressureRange,
					lastReadingDate: valveLastLogDate.time,
					unit: pressureUnit[1],
					dataKeys: [
						{
							name: t('upstream'),
							unit: upstreamPressure.log_unit,
							color: colors.GRAPH_PURPLE
						},
						{
							name: t('downstream'),
							unit: downstreamPressure.log_unit,
							color: colors.GRAPH_GREEN
						}
					],
					flags: { aggregate: true },
					showSpanSelector: true,
					sourceName: point.name_en
				});
			}

			setCharts(logObjects);
			setLoading(false);
		}
	}, [
		selectedMeasurementPoint,
		mpMinimumNightlyFlowAll,
		mpMinimumNightlyFlowLast,
		mpWaterConsumptionAll,
		mpWaterConsumptionLast,
		mpWaterProductionAll,
		mpWaterProductionLast,
		valveLastLogDate,
		upStreamPressureSource,
		downStreamPressureSource,
		dataSourcesLastLogDate && dataSourcesLastLogDate.every(src => src.isSuccess)
	]);

	// set charts for selected zone
	useEffect(() => {
		if (selectedZone && !selectedCommercialMeterGroup && !selectedMeasurementPoint) {
			onChartSelect();
			setLoading(true);
			if (
				!!zoneDataSourcesTypes &&
				!!zoneDataSourcesActivation &&
				!!zoneSitesActivation &&
				!!AverageZonePressure &&
				zoneMinimumNightlyFlowAll &&
				zoneMinimumNightlyFlowLast &&
				zoneWaterConsmputionAll &&
				zoneWaterConsmputionLast
			) {
				const dataSourcesTypeCount: any = barChartsFormatter(
					zoneDataSourcesTypes,
					t('dataSourcesTypeCount'),
					Object.keys(zoneDataSourcesTypes)
						.map(key =>
							[
								{ name: DataSources.PRESSURE, color: colors.GRAPH_PURPLE, radius: 25 },
								{ name: DataSources.FLOW_RATE, color: colors.GRAPH_BLUE, radius: 22 },
								{ name: 'ACC_FLOW', color: colors.DARK_BLUE, radius: 20 },
								{ name: 'CALC_FLOW_RATE', color: colors.CYAN, radius: 18 },
								{ name: DataSources.NOISE, color: colors.WARNING, radius: 15 }
							].find(item => item.name === key)
						)
						.filter(item => item !== undefined),
					true,
					'data_sources',
					false
				);

				const dataSourcesActiveInactive = pieChartsFormatter(
					[zoneDataSourcesActivation],
					t('ActiveInactiveDataSources'),
					[colors.HEALTHY, colors.INACTIVE],
					[
						{ name: true, color: colors.HEALTHY, radius: 20 },
						{ name: false, color: colors.INACTIVE, radius: 20 }
					],
					true,
					'is_active',
					false
				);
				const sitesActiveInactive = pieChartsFormatter(
					[zoneSitesActivation],
					t('ActiveInactiveSites'),
					[colors.HEALTHY, colors.INACTIVE],
					[
						{ name: true, color: colors.HEALTHY, radius: 20 },
						{ name: false, color: colors.INACTIVE, radius: 20 }
					],
					true,
					'is_active_sites',
					false
				);

				let averageZonePressureLast = AverageZonePressure && Object.values(AverageZonePressure)[0];

				const zoneName = zones?.find((zone: any) => zone.id === selectedZone).name_en;

				setZoneCharts(perCharts => [
					...perCharts,
					{
						id: selectedZone,
						type: chartTypes.LINE,
						data: averageZonePressureLast ?? [],
						title: t('AverageZonePressure'),
						legend: t('Pressure'),
						dataKeys: [{ name: t('value'), color: colors.PRIMARY_WARM }],
						timeFormat: 'HH:mm',
						analysisRedirectUri: '/dashboard/analysis?selectedTab=pressure',
						unit: PressureUnit.BAR,
						flags: { datePicker: true },
						dateText: t('LastReadingAtDate', {
							date:
								averageZonePressureLast && averageZonePressureLast?.length > 0
									? dayjs(averageZonePressureLast[0]?.time).format('Do MMM YYYY')
									: averageZonePressureLast &&
									  dayjs(Object.keys(averageZonePressureLast)?.[0]).format('Do MMM YYYY')
						}),
						lastReadingDate:
							averageZonePressureLast && averageZonePressureLast?.length > 0
								? dayjs(averageZonePressureLast[0]?.time)
								: averageZonePressureLast && dayjs(Object.keys(averageZonePressureLast)?.[0]),
						spanType: SpanType.DAY,
						showSpanSelector: false,
						sourceName: zoneName
					}
				]);

				setZoneCharts(
					clearArrayUndefined([
						{
							id: selectedZone,
							type: chartTypes.LINE,
							data: averageZonePressureLast,
							title: t('AverageZonePressure'),
							legend: t('Pressure'),
							dataKeys: [{ name: t('value'), color: colors.PRIMARY_WARM }],
							timeFormat: 'HH:mm',
							analysisRedirectUri: '/dashboard/analysis?selectedTab=pressure',
							unit: PressureUnit.BAR,
							flags: { datePicker: true },
							dateText: t('LastReadingAtDate', {
								date:
									averageZonePressureLast && averageZonePressureLast?.length > 0
										? dayjs(averageZonePressureLast[0]?.time).format('Do MMM YYYY')
										: averageZonePressureLast &&
										  dayjs(Object.keys(averageZonePressureLast)?.[0]).format('Do MMM YYYY')
							}),
							lastReadingDate:
								averageZonePressureLast && averageZonePressureLast?.length > 0
									? dayjs(averageZonePressureLast[0]?.time)
									: averageZonePressureLast && dayjs(Object.keys(averageZonePressureLast)?.[0]),
							spanType: SpanType.DAY,
							showSpanSelector: false,
							sourceName: zoneName
						},
						!!measurementPoints?.find(site => site.type === Sites.WATER_STATION) && {
							id: selectedZone,
							type: chartTypes.NUMERICAL,
							name: FlowRateType.ZDWP,
							data: zoneWaterProductionLast &&
								zoneWaterProductionLast.data?.length && [
									parseFloat(zoneWaterProductionLast.data[0].value).toFixed(2)
								],
							title: t('WaterProduction'),
							dateText: t('LastReadingAtDate', {
								date:
									zoneWaterProductionLast?.data.length &&
									dayjs(zoneWaterProductionLast.data[0].time).format('Do MMM YYYY')
							}),
							analysisRedirectUri: '/dashboard/analysis?selectedTab=water_production',
							unit: <span>{zoneWaterProductionLast?.unit}</span>,
							allData: [zoneWaterProductionAll],
							showTabular: true,
							percentage: zoneWaterProductionLast?.preceding_reading?.percentage_change || undefined,
							increase:
								zoneWaterProductionLast?.preceding_reading?.trend_direction === 'up' ? true : false,
							positive:
								zoneWaterProductionLast?.preceding_reading?.trend_direction === 'up' ? false : true,
							lastReadingDate:
								zoneWaterProductionLast?.data?.length > 0 && zoneWaterProductionLast.data[0].time,
							spanType: SpanType.DAY,
							sourceName: zoneName,
							deactivated: !(zoneFlowAnalysisConfig && zoneFlowAnalysisConfig.water_production.is_active),
							deactivatedMsg: t('WaterProductionAnalysisDeactivated'),
							linkToActivate:
								AppRoutes.ZONE_CONFIGURATION +
								`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=flow_analysis`,
							accessToActivateChart: hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.ZONE),
								UserRights.EDIT
							)
						},
						{
							id: selectedZone,
							type: chartTypes.NUMERICAL,
							name: FlowRateType.ZDWC,
							data: zoneWaterConsmputionLast &&
								zoneWaterConsmputionLast.data?.length && [
									parseFloat(zoneWaterConsmputionLast.data[0].value).toFixed(2)
								],
							title: t('DailyWaterConsumption'),
							dateText: t('LastReadingAtDate', {
								date:
									zoneWaterConsmputionLast?.data?.length &&
									dayjs(zoneWaterConsmputionLast.data[0].time).format('Do MMM YYYY')
							}),
							analysisRedirectUri: '/dashboard/analysis?selectedTab=water_consumption',

							unit: <span>{zoneWaterConsmputionLast?.unit}</span>,
							allData: [zoneWaterConsmputionAll],
							showTabular: true,
							percentage: zoneWaterConsmputionLast?.preceding_reading?.percentage_change || undefined,
							increase:
								zoneWaterConsmputionLast?.preceding_reading?.trend_direction === 'up' ? true : false,
							positive:
								zoneWaterConsmputionLast?.preceding_reading?.trend_direction === 'up' ? false : true,
							lastReadingDate:
								zoneWaterConsmputionLast?.data?.length > 0 &&
								dayjs(zoneWaterConsmputionLast.data[0].time),
							spanType: SpanType.DAY,
							sourceName: zoneName,
							deactivated: !(
								zoneFlowAnalysisConfig && zoneFlowAnalysisConfig.water_consumption.is_active
							),
							deactivatedMsg: t('WaterConsumptionAnalysisDeactivated'),
							linkToActivate:
								AppRoutes.ZONE_CONFIGURATION +
								`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=flow_analysis`,
							accessToActivateChart: hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.ZONE),
								UserRights.EDIT
							)
						},
						{
							id: selectedZone,
							type: chartTypes.NUMERICAL,
							name: FlowRateType.ZMNF,
							data: zoneMinimumNightlyFlowLast &&
								zoneMinimumNightlyFlowLast?.data?.length && [
									parseFloat(zoneMinimumNightlyFlowLast.data[0].value).toFixed(2)
								],
							title: t('MinimumNightlyFlow'),
							analysisRedirectUri: '/dashboard/analysis?selectedTab=minimum_nightly_flow',

							dateText: t('LastReadingAtDate', {
								date:
									zoneMinimumNightlyFlowLast?.data?.length &&
									dayjs(zoneMinimumNightlyFlowLast.data[0].time).format('Do MMM YYYY')
							}),
							unit: <span>{zoneMinimumNightlyFlowLast?.unit}</span>,
							allData: [zoneMinimumNightlyFlowAll],
							showTabular: true,
							percentage: zoneMinimumNightlyFlowLast?.preceding_reading?.percentage_change || undefined,
							increase:
								zoneMinimumNightlyFlowLast?.preceding_reading?.trend_direction === 'up' ? true : false,
							positive:
								zoneMinimumNightlyFlowLast?.preceding_reading?.trend_direction === 'up' ? false : true,
							lastReadingDate:
								zoneMinimumNightlyFlowLast?.data?.length > 0 &&
								dayjs(zoneMinimumNightlyFlowLast.data[0].time),
							spanType: SpanType.DAY,
							sourceName: zoneName,
							deactivated: !(
								zoneFlowAnalysisConfig && zoneFlowAnalysisConfig.minimum_nightly_flow.is_active
							),
							deactivatedMsg: t('MinNightlyFlowAnalysisDeactivated'),
							linkToActivate:
								AppRoutes.ZONE_CONFIGURATION +
								`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=flow_analysis`,
							accessToActivateChart: hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.ZONE),
								UserRights.EDIT
							)
						},
						dataSourcesTypeCount,
						dataSourcesActiveInactive,
						sitesActiveInactive
					])
				);
				setCharts(
					clearArrayUndefined([
						{
							id: selectedZone,
							type: chartTypes.LINE,
							data: averageZonePressureLast,
							title: t('AverageZonePressure'),
							legend: t('Pressure'),
							dataKeys: [{ name: t('value'), color: colors.PRIMARY_WARM }],
							timeFormat: 'HH:mm',
							analysisRedirectUri: '/dashboard/analysis?selectedTab=pressure',
							unit: PressureUnit.BAR,
							flags: { datePicker: true },
							dateText: t('LastReadingAtDate', {
								date:
									averageZonePressureLast && averageZonePressureLast?.length > 0
										? dayjs(averageZonePressureLast[0]?.time).format('Do MMM YYYY')
										: averageZonePressureLast &&
										  dayjs(Object.keys(averageZonePressureLast)?.[0]).format('Do MMM YYYY')
							}),
							lastReadingDate:
								averageZonePressureLast && averageZonePressureLast?.length > 0
									? dayjs(averageZonePressureLast[0]?.time)
									: averageZonePressureLast && dayjs(Object.keys(averageZonePressureLast)?.[0]),
							spanType: SpanType.DAY,
							showSpanSelector: false,
							sourceName: zoneName
						},
						!!measurementPoints?.find(site => site.type === Sites.WATER_STATION) && {
							id: selectedZone,
							type: chartTypes.NUMERICAL,
							name: FlowRateType.ZDWP,
							data: zoneWaterProductionLast &&
								zoneWaterProductionLast.data?.length && [
									parseFloat(zoneWaterProductionLast.data[0].value).toFixed(2)
								],
							title: t('WaterProduction'),
							dateText: t('LastReadingAtDate', {
								date:
									zoneWaterProductionLast?.data?.length &&
									dayjs(zoneWaterProductionLast.data[0].time).format('Do MMM YYYY')
							}),
							analysisRedirectUri: '/dashboard/analysis?selectedTab=water_production',
							unit: <span>{zoneWaterProductionLast?.unit}</span>,
							allData: [zoneWaterProductionAll],
							showTabular: true,
							percentage: zoneWaterProductionLast?.preceding_reading?.percentage_change || undefined,
							increase:
								zoneWaterProductionLast?.preceding_reading?.trend_direction === 'up' ? true : false,
							positive:
								zoneWaterProductionLast?.preceding_reading?.trend_direction === 'up' ? false : true,
							lastReadingDate:
								zoneWaterProductionLast?.data?.length > 0 && zoneWaterProductionLast.data[0].time,
							spanType: SpanType.DAY,
							sourceName: zoneName,
							deactivated: !(zoneFlowAnalysisConfig && zoneFlowAnalysisConfig.water_production.is_active),
							deactivatedMsg: t('WaterProductionAnalysisDeactivated'),
							linkToActivate:
								AppRoutes.ZONE_CONFIGURATION +
								`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=flow_analysis`,
							accessToActivateChart: hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.ZONE),
								UserRights.EDIT
							)
						},
						{
							id: selectedZone,
							type: chartTypes.NUMERICAL,
							name: FlowRateType.ZDWC,
							data: zoneWaterConsmputionLast &&
								zoneWaterConsmputionLast?.data?.length && [
									parseFloat(zoneWaterConsmputionLast.data[0].value).toFixed(2)
								],
							title: t('DailyWaterConsumption'),
							dateText: t('LastReadingAtDate', {
								date:
									zoneWaterConsmputionLast?.data?.length &&
									dayjs(zoneWaterConsmputionLast.data[0].time).format('Do MMM YYYY')
							}),
							analysisRedirectUri: '/dashboard/analysis?selectedTab=water_consumption',

							unit: <span>{zoneWaterConsmputionLast?.unit}</span>,
							allData: [zoneWaterConsmputionAll],
							showTabular: true,
							percentage: zoneWaterConsmputionLast?.preceding_reading?.percentage_change || undefined,
							increase:
								zoneWaterConsmputionLast?.preceding_reading?.trend_direction === 'up' ? true : false,
							positive:
								zoneWaterConsmputionLast?.preceding_reading?.trend_direction === 'up' ? false : true,
							lastReadingDate:
								zoneWaterConsmputionLast?.data?.length > 0 &&
								dayjs(zoneWaterConsmputionLast.data[0].time),
							spanType: SpanType.DAY,
							sourceName: zoneName,
							deactivated: !(
								zoneFlowAnalysisConfig && zoneFlowAnalysisConfig.water_consumption.is_active
							),
							deactivatedMsg: t('WaterConsumptionAnalysisDeactivated'),
							linkToActivate:
								AppRoutes.ZONE_CONFIGURATION +
								`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=flow_analysis`,
							accessToActivateChart: hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.ZONE),
								UserRights.EDIT
							)
						},
						{
							id: selectedZone,
							type: chartTypes.NUMERICAL,
							name: FlowRateType.ZMNF,
							data: zoneMinimumNightlyFlowLast &&
								zoneMinimumNightlyFlowLast.data.length && [
									parseFloat(zoneMinimumNightlyFlowLast.data[0].value).toFixed(2)
								],
							title: t('MinimumNightlyFlow'),
							analysisRedirectUri: '/dashboard/analysis?selectedTab=minimum_nightly_flow',

							dateText: t('LastReadingAtDate', {
								date:
									zoneMinimumNightlyFlowLast?.data?.length &&
									dayjs(zoneMinimumNightlyFlowLast.data[0].time).format('Do MMM YYYY')
							}),
							unit: <span>{zoneMinimumNightlyFlowLast?.unit}</span>,
							allData: [zoneMinimumNightlyFlowAll],
							showTabular: true,
							percentage: zoneMinimumNightlyFlowLast?.preceding_reading?.percentage_change || undefined,
							increase:
								zoneMinimumNightlyFlowLast?.preceding_reading?.trend_direction === 'up' ? true : false,
							positive:
								zoneMinimumNightlyFlowLast?.preceding_reading?.trend_direction === 'up' ? false : true,
							lastReadingDate:
								zoneMinimumNightlyFlowLast?.data?.length > 0 &&
								dayjs(zoneMinimumNightlyFlowLast.data[0].time),
							spanType: SpanType.DAY,
							sourceName: zoneName,
							deactivated: !(
								zoneFlowAnalysisConfig && zoneFlowAnalysisConfig.minimum_nightly_flow.is_active
							),
							deactivatedMsg: t('MinNightlyFlowAnalysisDeactivated'),
							linkToActivate:
								AppRoutes.ZONE_CONFIGURATION +
								`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=flow_analysis`,
							accessToActivateChart: hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.ZONE),
								UserRights.EDIT
							)
						},
						dataSourcesTypeCount,
						dataSourcesActiveInactive,
						sitesActiveInactive
					])
				);

				setLoading(false);
			}
		}
	}, [
		selectedZone,
		selectedCommercialMeterGroup,
		selectedMeasurementPoint,
		zoneDataSourcesTypes,
		AverageZonePressure,
		selectedZone,
		zoneMinimumNightlyFlowAll,
		zoneMinimumNightlyFlowLast,
		zoneWaterConsmputionAll,
		zoneWaterConsmputionLast
	]);

	// TODO: replace with new charts for commercial meter group
	// set charts for selected commercial meter group
	useEffect(() => {
		if (selectedCommercialMeterGroup) {
			{
				setZoneCharts([]);
				setCharts([]);
				onChartSelect();
			}
		}
	}, [selectedCommercialMeterGroup]);
	// set charts for selected network
	useEffect(() => {
		if (selectedNetwork && !selectedZone && !selectedCommercialMeterGroup && !selectedMeasurementPoint) {
			setLoading(true);
			onChartSelect();
			if (
				networkDataSourcesActivation &&
				networkSitesActivation &&
				networkZonesActivation &&
				networkDataSourcesTypes &&
				networkZonesTypes &&
				networkValvesTypes &&
				networkValvesMaintenanceStats &&
				networkValvesStatusStats
			) {
				const dataSourcesActiveInactive = pieChartsFormatter(
					[networkDataSourcesActivation],
					t('ActiveInactiveDataSources'),
					[colors.HEALTHY, colors.INACTIVE],
					[
						{ name: true, color: colors.HEALTHY, radius: 20 },
						{ name: false, color: colors.INACTIVE, radius: 20 }
					],
					true,
					'is_active',
					false
				);
				const sitesActiveInactive = pieChartsFormatter(
					[networkSitesActivation],
					t('ActiveInactiveSites'),
					[colors.HEALTHY, colors.INACTIVE],
					[
						{ name: true, color: colors.HEALTHY, radius: 20 },
						{ name: false, color: colors.INACTIVE, radius: 20 }
					],
					true,
					'is_active_sites',
					false
				);

				const zonesActiveInactive = pieChartsFormatter([networkZonesActivation], t('ActiveInactiveZones'));

				const dataSourcesTypeCount = barChartsFormatter(
					networkDataSourcesTypes,
					t('dataSourcesTypeCount'),
					Object.keys(networkDataSourcesTypes)
						.map(key =>
							[
								{ name: DataSources.PRESSURE, color: colors.GRAPH_PURPLE, radius: 25 },
								{ name: DataSources.FLOW_RATE, color: colors.GRAPH_BLUE, radius: 22 },
								{ name: 'ACC_FLOW', color: colors.DARK_BLUE, radius: 20 },
								{ name: 'CALC_FLOW_RATE', color: colors.CYAN, radius: 18 },
								{ name: DataSources.NOISE, color: colors.WARNING, radius: 15 }
							].find(item => item.name === key)
						)
						.filter(item => item !== undefined),
					true,
					'data_sources',
					false
				);
				const zonesTypeCount = barChartsFormatter(
					networkZonesTypes,
					t('NetworkZoneTypeCount'),
					Object.keys(networkZonesTypes)
						.map(key =>
							[
								{ name: Zones.DMA, color: colors.GRAPH_PURPLE, radius: 25 },
								{ name: Zones.PMA, color: colors.GRAPH_GREEN, radius: 20 },
								{ name: Zones.MEGA, color: colors.GRAPH_BLUE, radius: 15 }
							].find(item => item.name === key)
						)
						.filter(item => item !== undefined),
					false,
					'zones',
					false
				);

				const valvesMaintenanceStatsDataObject = networkValvesMaintenanceStats.reduce((obj, valve) => {
					obj[valve.name] = valve.value;
					return obj;
				}, {});

				const valvesMaintenanceStats = barChartsFormatter(
					valvesMaintenanceStatsDataObject,
					t('valveMaintenanceReminder'),
					Object.keys(valvesMaintenanceStatsDataObject)
						.map(key =>
							[
								{ name: ValveMaintenanceReminder.NORMAL, color: colors.GRAPH_PURPLE, radius: 20 },
								{ name: ValveMaintenanceReminder.APPROACHING, color: colors.GRAPH_GREEN, radius: 20 },
								{ name: ValveMaintenanceReminder.OVERDUE, color: colors.GRAPH_BLUE, radius: 20 },
								{ name: ValveMaintenanceReminder.OTHER, color: colors.WARNING, radius: 20 }
							].find(item => item.name === key)
						)
						.filter(item => item !== undefined),
					true,
					'maintenance_reminder_status',
					true
				);

				const valveStatsObject = networkValvesStatusStats.reduce((obj, valve) => {
					obj[valve.name] = valve.value;
					return obj;
				}, {});
				const colorsPie: any[] = [];
				// add colors relative to existing values
				Object.keys(valveStatsObject).forEach(key => {
					if (key === ValveOperationStatus.DATA_PROBLEM) {
						colorsPie.push(colors.ERROR_DARK);
					} else if (key === ValveOperationStatus.NEEDS_INVESTIGATION) {
						colorsPie.push(colors.WARNING);
					} else if (key === ValveOperationStatus.NORMAL) {
						colorsPie.push(colors.HEALTHY);
					} else if (key === ValveOperationStatus.OPERATION_ERROR) {
						colorsPie.push(colors.ERROR);
					} else if (key === ValveOperationStatus.OTHER) {
						colorsPie.push(colors.INACTIVE);
					}
				});
				const valvesStatusStats = pieChartsFormatter(
					[valveStatsObject],
					t('valveCondition'),
					colorsPie,
					[
						{
							name: ValveOperationStatus.DATA_PROBLEM,
							color: colors.ERROR_DARK,
							radius: 20
						},
						{
							name: ValveOperationStatus.NEEDS_INVESTIGATION,
							color: colors.WARNING,
							radius: 20
						},
						{ name: ValveOperationStatus.NORMAL, color: colors.HEALTHY, radius: 20 },
						{ name: ValveOperationStatus.OPERATION_ERROR, color: colors.ERROR, radius: 20 },
						{
							name: ValveOperationStatus.OTHER,
							color: colors.INACTIVE,
							radius: 20
						}
					],
					true,
					'operation_status',
					true
				);

				const valveCountObject = networkValvesTypes.reduce((obj, valve) => {
					obj[valve.name] = valve.value;
					return obj;
				}, {});
				const valvesTypeCount = barChartsFormatter(
					valveCountObject,
					t('valveType'),
					Object.keys(valveCountObject)
						.map(key =>
							[
								{ name: ValveControlType.PRV, color: colors.GRAPH_PURPLE, radius: 20 },
								{ name: ValveControlType.PSV, color: colors.GRAPH_GREEN, radius: 20 },
								{ name: ValveControlType.FLV, color: colors.GRAPH_BLUE, radius: 20 },
								{ name: ValveControlType.OTHER, color: colors.WARNING, radius: 20 }
							].find(item => item.name === key)
						)
						.filter(item => item !== undefined),
					true,
					'control_type',
					true
				);

				setNetworkCharts(
					clearArrayUndefined([
						valvesMaintenanceStats,
						valvesStatusStats,
						valvesTypeCount,
						dataSourcesActiveInactive,
						sitesActiveInactive,
						zonesActiveInactive,
						dataSourcesTypeCount,
						zonesTypeCount
					])
				);
				setCharts(
					clearArrayUndefined([
						valvesMaintenanceStats,
						valvesStatusStats,
						valvesTypeCount,
						dataSourcesActiveInactive,
						sitesActiveInactive,
						zonesActiveInactive,
						dataSourcesTypeCount,
						zonesTypeCount
					])
				);
				setLoading(false);
			}
		}
	}, [
		selectedNetwork,
		selectedZone,
		selectedCommercialMeterGroup,
		selectedMeasurementPoint,
		networkDataSourcesActivation,
		networkZonesActivation,
		networkDataSourcesTypes,
		networkZonesTypes,
		networkValvesTypes,
		networkValvesMaintenanceStats,
		networkValvesStatusStats
	]);

	return (
		<Spin spinning={loading} style={{ position: 'fixed', zIndex: 200 }}>
			<MonitoringMap
				networks={networks}
				selectedNetwork={selectedNetwork}
				zones={zones}
				selectedZone={selectedZone ?? selectedCommercialMeterGroup}
				onMeasurementPointClick={onMeasurementPointClick}
				onMeasurementPointClose={onMeasurementPointClose}
				selectedMeasurementPoint={selectedMeasurementPoint}
				selectedChartInfo={selectedChartInfo}
				onChartSelect={onChartSelect}
			/>
			<GraphsCarousel
				charts={charts}
				onChartSelect={onChartSelect}
				onLineChartSpanChange={onLineChartSpanChange}
				selectedChartInfo={selectedChartInfo}
				loadingCharts={loadingCharts}
			/>
			<WaiWarning messages={alerts} />
		</Spin>
	);
};
