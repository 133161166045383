import configProxy from '../config/config';
import ResourceManager from '../network/resource-manager';
import { IDataSourceLog } from '../types';

class ChartsService extends ResourceManager {
	async getNetworkDSAcitveInactiveCount(networkId: string) {
		const url = configProxy.URIs.get_network_data_sources_active_inactive_count.replace(/:networkId/g, networkId);
		return await this.getResource(url);
	}
	async getNetworkSitesAcitveInactiveCount(networkId: string) {
		const url = configProxy.URIs.get_network_sites_active_inactive_count.replace(/:networkId/g, networkId);
		return await this.getResource(url);
	}
	async getNetworkDSTypeCount(networkId: string) {
		const url = configProxy.URIs.get_network_data_sources_type_count.replace(/:networkId/g, networkId);
		return await this.getResource(url);
	}
	async getNetworkZonesAcitveInactiveCount(networkId: string) {
		const url = configProxy.URIs.get_network_zones_active_inactive_count.replace(/:networkId/g, networkId);
		return await this.getResource(url);
	}
	async getNetworkZonesTypeCount(networkId: string) {
		const url = configProxy.URIs.get_network_zones_type_count.replace(/:networkId/g, networkId);
		return await this.getResource(url);
	}
	async getNetworkValvesMaintenanceStats(networkId: string) {
		const url = configProxy.URIs.get_network_valve_maintenance_statistics.replace(/:networkId/g, networkId);
		return await this.getResource(url);
	}
	async getNetworkValvesStatusStats(networkId: string) {
		const url = configProxy.URIs.get_network_valve_status_statistics.replace(/:networkId/g, networkId);
		return await this.getResource(url);
	}
	async getNetworkValvesTypeCount(networkId: string) {
		const url = configProxy.URIs.get_network_valve_type_statistics.replace(/:networkId/g, networkId);
		return await this.getResource(url);
	}
	async getNetworkWaterBreakout(networkId: string, params?: any, options?: any) {
		const url = configProxy.URIs.network_water_breakout.replace(/:networkId/g, networkId);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getNetworkCommercialFirstDate(networkId: string) {
		const url = configProxy.URIs.network_commercial_first_reading_date.replace(/:networkId/g, networkId);
		return await this.getResource<any>(url);
	}
	async getNetworkCommercialLastDate(networkId: string) {
		const url = configProxy.URIs.network_commercial_last_reading_date.replace(/:networkId/g, networkId);
		return await this.getResource<any>(url);
	}
	async getNetworkCumulativeMonthlyReadingsCount(networkId: string, params?: any, options?: any) {
		const url = configProxy.URIs.network_culumative_monthly_readings_count.replace(/:networkId/g, networkId);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getNetworkCommercialCumulativeAvgReadings(networkId: string, params?: any, options?: any) {
		return await this.getResource<any>(
			configProxy.URIs.network_commercial_cumulative_avg_readings.replace(/:networkId/g, networkId),
			{
				...(options || {}),
				params
			}
		);
	}
	async getNetworkCommercialCumulativeReadings(networkId: string, params?: any, options?: any) {
		return await this.getResource<any>(
			configProxy.URIs.network_commercial_cumulative_readings.replace(/:networkId/g, networkId),
			{
				...(options || {}),
				params
			}
		);
	}
	async getZoneDSActiveCount(zoneId: string) {
		const url = configProxy.URIs.get_zone_data_source_active_count.replace(/:zoneId/g, zoneId);
		return await this.getResource(url);
	}
	async getZoneSitesActiveCount(zoneId: string) {
		const url = configProxy.URIs.get_zone_site_active_count.replace(/:zoneId/g, zoneId);
		return await this.getResource(url);
	}
	async getZoneDSTypeCount(zoneId: string) {
		const url = configProxy.URIs.get_zone_data_source_type_count.replace(/:zoneId/g, zoneId);
		return await this.getResource(url);
	}
	async getZoneLogs(zoneId: string, params?: any, options?: any) {
		const url = configProxy.URIs.get_zone_logs.replace(/:zoneId/g, zoneId);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getZoneDailyConsumption(zoneId: string, params?: any, options?: any) {
		const url = configProxy.URIs.get_zone_daily_consumption.replace(/:zoneId/g, zoneId);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getZoneDailyProduction(zoneId: string, params?: any, options?: any) {
		const url = configProxy.URIs.zone_daily_production.replace(/:zoneId/g, zoneId);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getZoneMinNightlyFlow(zoneId: string, params?: any, options?: any) {
		const url = configProxy.URIs.get_zone_min_nightly_flow.replace(/:zoneId/g, zoneId);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getZoneLeakageStatus(zoneId: string) {
		const url = configProxy.URIs.zone_leakage_status.replace(/:zoneId/g, zoneId);
		return await this.getResource<any>(url);
	}
	async getZoneLeakageData(zoneId: string, params?: any, options?: any) {
		const url = configProxy.URIs.zone_leakage_data.replace(/:zoneId/g, zoneId);
		return await this.getResource<any>(url, {
			...(options || {}),
			params
		});
	}
	async getZoneLeakageMNF(zoneId: string, params?: any, options?: any) {
		const url = configProxy.URIs.zone_leakage_mnf.replace(/:zoneId/g, zoneId);
		return await this.getResource<any>(url, {
			...(options || {}),
			params
		});
	}
	async getZoneLeakageUFL(zoneId: string, params?: any, options?: any) {
		const url = configProxy.URIs.zone_leakage_ufl.replace(/:zoneId/g, zoneId);
		return await this.getResource<any>(url, {
			...(options || {}),
			params
		});
	}
	async getAverageZonePressure(zoneId: string, params?: any, options?: any) {
		const url = configProxy.URIs.get_average_zone_pressure.replace(/:zoneId/g, zoneId);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getAverageZonePressureStats(zoneId: string, params?: any, options?: any) {
		const url = configProxy.URIs.get_average_zone_pressure_stats.replace(/:zoneId/g, zoneId);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getZoneCurrentWaterProductionReading(zoneId: string, params?: any, options?: any) {
		const url = configProxy.URIs.zone_current_water_production_reading.replace(/:zoneId/g, zoneId);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getZoneWaterBreakout(zoneId: string, params?: any, options?: any) {
		const url = configProxy.URIs.zone_water_breakout.replace(/:zoneId/g, zoneId);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getCommercialMeterGroupFirstDate(commercialGroupId: string) {
		const url = configProxy.URIs.commercial_meter_group_first_reading_date.replace(
			/:commercialGroupId/g,
			commercialGroupId
		);
		return await this.getResource<any>(url);
	}
	async getCommercialMeterGroupLastDate(commercialGroupId: string) {
		const url = configProxy.URIs.commercial_meter_group_last_reading_date.replace(
			/:commercialGroupId/g,
			commercialGroupId
		);
		return await this.getResource<any>(url);
	}
	async getCommercialMeterCumulativeMonthlyReadingsCount(commercialGroupId: string, params?: any, options?: any) {
		const url = configProxy.URIs.commercial_meter_group_culumative_monthly_readings_count.replace(
			/:commercialGroupId/g,
			commercialGroupId
		);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getCommercialMeterGroupCumulativeAvgReadings(commercialGroupId: string, params?: any, options?: any) {
		return await this.getResource<any>(
			configProxy.URIs.commercial_meter_group_cumulative_avg_readings.replace(
				/:commercialGroupId/g,
				commercialGroupId
			),
			{
				...(options || {}),
				params
			}
		);
	}
	async getCommercialMeterGroupCumulativeReadings(commercialGroupId: string, params?: any, options?: any) {
		return await this.getResource<any>(
			configProxy.URIs.commercial_meter_group_cumulative_readings.replace(
				/:commercialGroupId/g,
				commercialGroupId
			),
			{
				...(options || {}),
				params
			}
		);
	}
	async getCommercialMeterGroupMonthlyData(commercialGroupId: string, params?: any, options?: any) {
		return await this.getResource<any>(
			configProxy.URIs.commercial_meter_group_monthly_data.replace(/:commercialGroupId/g, commercialGroupId),
			{
				...(options || {}),
				params
			}
		);
	}
	async getMeasurementPointDailyConsumption(measurementPointId: string, params?: any, options?: any) {
		const url = configProxy.URIs.get_measurement_point_daily_consumption.replace(
			/:measurementPointId/g,
			measurementPointId
		);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getSiteDailyProduction(siteId: string, params?: any, options?: any) {
		const url = configProxy.URIs.site_daily_production.replace(/:siteId/g, siteId);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getMeasurementPointMinNightlyFlow(measurementPointId: string, params?: any, options?: any) {
		const url = configProxy.URIs.get_measurement_point_min_nightly_flow.replace(
			/:measurementPointId/g,
			measurementPointId
		);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getCommercialSiteCumulativeMonthlyReadingsCount(siteId: string, params?: any, options?: any) {
		const url = configProxy.URIs.commercial_site_culumative_monthly_readings_count.replace(/:siteId/g, siteId);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getCommercialSiteCumulativeAvgReadings(siteId: string, params?: any, options?: any) {
		return await this.getResource<any>(
			configProxy.URIs.commercial_site_cumulative_avg_readings.replace(/:siteId/g, siteId),
			{
				...(options || {}),
				params
			}
		);
	}
	async getCommercialSiteCumulativeReadings(siteId: string, params?: any, options?: any) {
		return await this.getResource<any>(
			configProxy.URIs.commercial_site_cumulative_readings.replace(/:siteId/g, siteId),
			{
				...(options || {}),
				params
			}
		);
	}
	async getSiteWaterMeterLastReading(siteId: string, params?: any, options?: any) {
		return await this.getResource<any>(configProxy.URIs.site_water_meter_last_reading.replace(/:siteId/g, siteId), {
			...(options || {}),
			params
		});
	}
	async getSiteCurrentWaterProductionReading(siteId: string, params?: any, options?: any) {
		const url = configProxy.URIs.site_current_water_production_reading.replace(/:siteId/g, siteId);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getSiteWaterMeterLogs(siteId: string, params?: any, options?: any) {
		const url = configProxy.URIs.site_water_meter_logs.replace(/:siteId/g, siteId);
		return await this.getResource(url, {
			...(options || {}),
			params
		});
	}
	async getDataSourceLastLogs(dataSourceId: string) {
		const url = configProxy.URIs.get_data_source_last_logs.replace(/:dataSourceId/g, dataSourceId);
		return await this.getResource<any>(url);
	}
	async getDataSourceLastLogDate(dataSourceId: string) {
		const url = configProxy.URIs.get_data_source_last_log_date.replace(/:dataSourceId/g, dataSourceId);
		return await this.getResource<any>(url);
	}
	async getDSLogs(dataSourceId: string, params?: any, options?: any) {
		const url = configProxy.URIs.get_data_sources_logs.replace(/:dataSourceId/g, dataSourceId);
		return await this.getResource<IDataSourceLog[]>(url, {
			...(options || {}),
			params
		});
	}
}

export default new ChartsService();
