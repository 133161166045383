import { Col, Row, Spin } from 'antd';
import { useContext, useState } from 'react';
import { NavigationContext } from '../contexts/navigation.context';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { CumulativeReadingsCardsChart } from './cumulative-readings-cards-chart';
import { CumulativeReadingsPieChart } from './cumulative-readings-pie-chart';
import { CumulativeAvgReadingCardsChart } from './cumulative-avg-reading-card-chart';
import chartsService from '../services/charts.service';
import dayjs from 'dayjs';

export const CommercialMeterGroupAnalysisCumulativeReadingsTab: React.FC = () => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { selectedCommercialMeterGroup } = navigationContext;

	const [cumulativeReadingsDateCount, setCumulativeReadingsDateCount] = useState<{ start?: string; date?: string }>();
	const [cumulativeAvgReadingsDate, setCumulativeAvgReadingsDate] = useState<{
		start?: string;
		date?: string;
	}>();
	const [cumulativeReadingsDate, setCumulativeReadingsDate] = useState<{
		start?: string;
		date?: string;
	}>();

	// get first and last readings date for commercial meter group cumulative readings
	const { data: firstReadingsDate, isLoading: loadingFirstReadingsDate } = useQuery({
		queryKey: ['commercial-meter-group-first-reading', selectedCommercialMeterGroup],
		queryFn: () => chartsService.getCommercialMeterGroupFirstDate(selectedCommercialMeterGroup),
		enabled: !!selectedCommercialMeterGroup
	});
	const { data: lastReadingsDate, isLoading: loadingLastReadingsDate } = useQuery({
		queryKey: ['commercial-meter-group-last-reading', selectedCommercialMeterGroup],
		queryFn: () => chartsService.getCommercialMeterGroupLastDate(selectedCommercialMeterGroup),
		enabled: !!selectedCommercialMeterGroup
	});

	// get commercial meter group cumulative readings count per month
	const {
		data: cumulativeMonthlyReadingsCount,
		isLoading: loadingCumulativeMonthlyReadingsCount,
		isFetching: fetchingCumulativeMonthlyReadingsCount
	} = useQuery<any>({
		queryKey: [
			'commercial-meter-group-cumulative-monthly-readings-count',
			selectedCommercialMeterGroup,
			cumulativeReadingsDateCount
		],
		queryFn: () =>
			chartsService.getCommercialMeterCumulativeMonthlyReadingsCount(selectedCommercialMeterGroup, {
				month: cumulativeReadingsDateCount
					? dayjs(cumulativeReadingsDateCount.start).startOf('M').format('YYYY-MM-DD')
					: dayjs(lastReadingsDate?.date).startOf('M').format('YYYY-MM-DD')
			}),

		enabled: !!selectedCommercialMeterGroup && (!!cumulativeReadingsDateCount || !!lastReadingsDate)
	});

	// loading commercial meter group cumulative readings
	const {
		data: commercialCumulativeReadingsTotal,
		isLoading: loadingCommercialCumulativeReadingsTotal,
		isFetching: fetchingCommercialCumulativeReadingsTotal
	} = useQuery<any>({
		queryKey: [
			'commercial-meter-group-cumulative-readings',
			selectedCommercialMeterGroup,
			cumulativeReadingsDate,
			lastReadingsDate
		],
		queryFn: () =>
			chartsService.getCommercialMeterGroupCumulativeReadings(selectedCommercialMeterGroup, {
				month: cumulativeReadingsDate?.start
					? dayjs(cumulativeReadingsDate.start).format('YYYY-MM-DD')
					: lastReadingsDate?.date && dayjs(lastReadingsDate?.date).format('YYYY-MM-DD')
			}),

		enabled: !!selectedCommercialMeterGroup && (!!cumulativeReadingsDate || !!lastReadingsDate)
	});

	// loading commercial meter group cumulative readings
	const {
		data: commercialCumulativeAvgReadings,
		isLoading: loadingCommercialCumulativeAvgReadings,
		isFetching: fetchingCommercialCumulativeAvgReadings
	} = useQuery<any>({
		queryKey: [
			'commercial-meter-group-cumulative-avg-readings',
			selectedCommercialMeterGroup,
			cumulativeAvgReadingsDate,
			lastReadingsDate
		],
		queryFn: () =>
			chartsService.getCommercialMeterGroupCumulativeAvgReadings(selectedCommercialMeterGroup, {
				month: cumulativeAvgReadingsDate?.start
					? dayjs(cumulativeAvgReadingsDate.start).format('YYYY-MM-DD')
					: lastReadingsDate?.date && dayjs(lastReadingsDate?.date).format('YYYY-MM-DD')
			}),

		enabled: !!selectedCommercialMeterGroup && (!!cumulativeAvgReadingsDate || !!lastReadingsDate)
	});

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col xs={12}>
					<Spin
						spinning={
							(loadingCumulativeMonthlyReadingsCount && fetchingCumulativeMonthlyReadingsCount) ||
							loadingFirstReadingsDate ||
							loadingLastReadingsDate
						}
					>
						<CumulativeReadingsPieChart
							cumulativeReadings={cumulativeMonthlyReadingsCount && cumulativeMonthlyReadingsCount.data}
							OnDateSelect={(date: any) => setCumulativeReadingsDateCount(date)}
							lastReadingDate={lastReadingsDate && lastReadingsDate.date}
							title={cumulativeMonthlyReadingsCount?.name}
						/>
					</Spin>
				</Col>
				<Col xs={12}>
					<Spin
						spinning={
							(loadingCommercialCumulativeAvgReadings && fetchingCommercialCumulativeAvgReadings) ||
							loadingFirstReadingsDate ||
							loadingLastReadingsDate
						}
					>
						<CumulativeAvgReadingCardsChart
							data={commercialCumulativeAvgReadings}
							OnDateSelect={(date: any) => setCumulativeAvgReadingsDate(date)}
							lastReadingDate={lastReadingsDate && lastReadingsDate.date}
						/>
					</Spin>
				</Col>
				<Col xs={24}>
					<Spin
						spinning={
							(loadingCommercialCumulativeReadingsTotal && fetchingCommercialCumulativeReadingsTotal) ||
							loadingFirstReadingsDate ||
							loadingLastReadingsDate
						}
					>
						<CumulativeReadingsCardsChart
							cumulativeReadings={commercialCumulativeReadingsTotal}
							OnDateSelect={(date: any) => setCumulativeReadingsDate(date)}
							firstReadingsDate={firstReadingsDate && firstReadingsDate.date}
							lastReadingsDate={lastReadingsDate && lastReadingsDate.date}
							title={t('CommercialMeterGroupMonthlyCumulativeReadings')}
						/>
					</Spin>
				</Col>
			</Row>
		</>
	);
};
