import { ReactNode } from 'react';

interface IAnalysisTabProbs {
	title: string;
	icon: ReactNode;
}

export const AnalysisTab: React.FC<IAnalysisTabProbs> = ({ title, icon, ...restProps }) => {
	return (
		<div {...restProps} className="d-flex align-items-center">
			{icon}{' '}
			<span className="ms-1" style={{ fontWeight: '600' }}>
				{title}
			</span>
		</div>
	);
};
