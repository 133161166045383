import { useQuery } from '@tanstack/react-query';
import { Col, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { AlertsCard } from '../components';
import config from '../config/config';
import { colors } from '../constants/colors';
import { AlertsTable, MapSummary } from '../containers';
import { WaiTimeSeriesChart } from '../containers/wai-time-series';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import { useMedia } from '../hooks/use-media.hook';
import { useQueryParam as useQueryCustom } from '../hooks/use-query';
import { transformAlertsToRecord } from '../mappers/alert-to-record';
import alertsService from '../services/alerts.service';
import chartsService from '../services/charts.service';
import monitoringService from '../services/monitoring.service';
import { AlertCardType, IAlertRecord } from '../types/alerts';
import { DataSources } from '../types/data-sources';
import { dateToQuery } from '../utils/date-to-query';
import { upperSnakeToCapitalized } from '../utils/upper-snake-to-capitalized';

export const AlertsPage = () => {
	const { t, i18n } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { networks, selectedNetwork, zones, selectedZone, selectedCommercialMeterGroup, selectedMeasurementPoint } =
		navigationContext;
	const [alertsPagination, setAlertPagination] = useState<{
		current?: number;
		pageSize?: number;
		total?: number;
	}>();
	const measurementPointQuery = useQueryCustom('point');
	const [alertsSorters, setAlertsSorters] = useState<any>({});
	const [alertsFilters, setAlertsFilters] = useState<any>({});
	const [selectedRow, setSelectedRow] = useState<string[]>([]);
	const [selectedMeasurementPointRow, setSelectedMeasurementPointRow] = useState<any>();
	const [selectedAlert, setSelectedAlert] = useState<any>();
	const [chart, setChart] = useState<any>();

	const xl = useMedia('xl');
	const lg = useMedia('lg');
	const md = useMedia('md');

	// load alerts statistics
	const {
		data: alertStats,
		isLoading: loadingAlertStats,
		refetch: refetchAlertStats
	} = useQuery<any>({
		queryKey: [
			'alerts-statistics',
			measurementPointQuery ?? (selectedZone || selectedCommercialMeterGroup) ?? selectedNetwork
		],
		queryFn: () =>
			alertsService.getAlertsStats(
				measurementPointQuery
					? { measurement_point: measurementPointQuery }
					: selectedZone || selectedCommercialMeterGroup
					? { zone: selectedZone || selectedCommercialMeterGroup }
					: selectedNetwork
					? { network: selectedNetwork }
					: undefined
			),
		enabled: !!measurementPointQuery || !!selectedZone || !!selectedCommercialMeterGroup || !!selectedNetwork
	});

	// load alerts table
	const {
		data: alerts,
		isLoading: loadingAlerts,
		isFetching: fetchingAlerts,
		refetch: refetchAlerts
	} = useQuery<any>({
		queryKey: [
			'alerts',
			measurementPointQuery ?? selectedZone ?? selectedCommercialMeterGroup ?? selectedNetwork,
			alertsSorters,
			alertsFilters,
			alertsPagination?.current ?? 1,
			alertsPagination?.pageSize ?? config.default_page_size
		],
		queryFn: () =>
			alertsService.getAlertsNew({
				'page': alertsPagination?.current ?? 1,
				'page_size': alertsPagination?.pageSize ?? config.default_page_size,
				'measurement-point-id': measurementPointQuery,
				'zone-id': measurementPointQuery ? undefined : selectedZone ?? selectedCommercialMeterGroup,
				'network-id': measurementPointQuery
					? selectedNetwork
					: selectedZone ?? selectedCommercialMeterGroup
					? undefined
					: selectedNetwork,
				'sorters': { ...alertsSorters },
				'filters': { ...alertsFilters }
			}),
		enabled: !!measurementPointQuery || !!selectedZone || !!selectedCommercialMeterGroup || !!selectedNetwork
	});

	// get data-source data for selected alert
	const { data: dataSource, isLoading: loadingDataSource } = useQuery<any>({
		queryKey: ['data-source', selectedAlert?.data_source],
		queryFn: () => monitoringService.getDataSource(selectedAlert?.data_source),
		enabled: !!selectedAlert?.data_source
	});

	// load data-source logs for selected alert
	const { data: dataSourceLogs, isLoading: loadingDataSourceLogs } = useQuery<any>({
		queryKey: ['data-source-logs', selectedAlert?.data_source, selectedAlert?.alertStart, selectedAlert?.alertEnd],
		queryFn: () =>
			chartsService.getDSLogs(selectedAlert?.data_source, {
				start: selectedAlert?.alertStart && dateToQuery(dayjs(selectedAlert?.alertStart).subtract(12, 'hour')),
				end: selectedAlert?.alertEnd && dateToQuery(dayjs(selectedAlert?.alertEnd).add(12, 'hour')),
				aggregate: 1
			}),
		enabled: !!selectedAlert?.data_source
	});

	const onAlertPagination = (pagination: any) => {
		if (JSON.stringify(pagination) === JSON.stringify(alertsPagination)) return;
		else setAlertPagination(pagination);
	};

	const onAlertSort = (sorters: any) => {
		if (JSON.stringify(sorters) === JSON.stringify(alertsSorters)) return;
		{
			setAlertPagination(undefined);
			setAlertsSorters(sorters);
		}
	};

	const onAlertFilter = (filters: any) => {
		if (JSON.stringify(filters) === JSON.stringify(alertsFilters)) return;
		{
			setAlertPagination(undefined);
			setAlertsFilters(filters);
		}
	};

	const onAlertClick = (alert: any) => {
		setSelectedAlert(alert);
	};

	const onAlertReset = () => {
		setChart(null);
		setSelectedRow([]);
	};

	// reset selected row if selected alerts changed
	useEffect(() => {
		if (selectedNetwork || selectedZone || selectedCommercialMeterGroup || measurementPointQuery) {
			onAlertReset();
		}
	}, [selectedNetwork, selectedZone || selectedCommercialMeterGroup, measurementPointQuery]);

	// get chart for selected dataSource alert
	useEffect(() => {
		if (dataSource && dataSourceLogs) {
			let logObject = {
				id: selectedAlert.data_source,
				title: dataSource && upperSnakeToCapitalized(dataSource?.type),
				dataKeys: [{ name: t('value'), unit: dataSource && dataSource?.log_unit, color: colors.GRAPH_PURPLE }],
				minReferenceValue: dataSource && dataSource?.min_value_alarm,
				maxReferenceValue: dataSource && dataSource?.max_value_alarm,
				data: dataSourceLogs && dataSourceLogs,
				type: dataSource && dataSource?.type,
				alert: {
					start: selectedAlert.alertStart,
					end: selectedAlert.alertEnd,
					message: selectedAlert.alertMsg
				},
				alarmLevels: [
					dataSource && {
						min: dataSource?.min_value_alarm,
						max: dataSource?.max_value_alarm,
						type: dataSource?.type
					}
				]
			};
			setChart(logObject);
		}
	}, [dataSource, dataSourceLogs, selectedAlert]);
	return (
		<div
			style={{
				paddingLeft: i18n.language === 'en' ? '1rem' : '',
				paddingRight: i18n.language === 'en' ? '' : '1rem',
				paddingTop: '2rem'
			}}
		>
			<Row gutter={30}>
				<Col xs={24} lg={24} xl={16} className="mb-2">
					<Spin spinning={(loadingAlertStats && fetchingAlerts) || (loadingAlerts && fetchingAlerts)}>
						{alertStats && (
							<div className="d-flex justify-content-between justify-content-lg-start flex-wrap mb-3">
								<AlertsCard type={AlertCardType.OPEN}>{alertStats.open_count}</AlertsCard>
								<AlertsCard type={AlertCardType.RESOLVED}>{alertStats.resolved_count}</AlertsCard>
								<AlertsCard type={AlertCardType.INTERMEDIATE}>
									{alertStats.intermediate_count}
								</AlertsCard>
								<AlertsCard type={AlertCardType.URGENT}>{alertStats.urgent_count}</AlertsCard>
							</div>
						)}
						<AlertsTable
							alerts={
								(alerts?.results &&
									(transformAlertsToRecord(
										alerts.results,
										i18n.language === 'en'
									) as IAlertRecord[])) ||
								[]
							}
							pagination={
								alertsPagination ?? {
									current: 1,
									pageSize: config.default_page_size,
									total: alerts?.count
								}
							}
							loading={loadingAlerts}
							onAlertClick={onAlertClick}
							onAlertReset={onAlertReset}
							onAlertSort={onAlertSort}
							onAlertFilter={onAlertFilter}
							onAlertPagination={onAlertPagination}
							selectedRow={selectedRow}
							setSelectedRow={setSelectedRow}
							setSelectedMeasurementPointRow={setSelectedMeasurementPointRow}
							refetchAlerts={refetchAlerts}
							refetchAlertStats={refetchAlertStats}
						/>
					</Spin>
				</Col>

				<Col xs={24} lg={24} xl={8}>
					<MapSummary
						style={{ height: '350px', width: 'auto', borderRadius: '13px', zIndex: 50 }}
						networks={networks}
						selectedNetwork={selectedNetwork}
						zones={zones}
						selectedZone={selectedZone ?? selectedCommercialMeterGroup}
						selectedMeasurementPoint={selectedMeasurementPoint ?? selectedMeasurementPointRow ?? undefined}
					/>
					<Col xs={24} className="mt-5">
						{chart ? (
							<Spin spinning={loadingDataSource || loadingDataSourceLogs}>
								<WaiTimeSeriesChart
									height={xl ? 21 : lg ? 46 : md ? 38 : 29}
									title={chart.title}
									dataObjects={[
										{
											id: chart?.id,
											name: chart.title,
											data: chart?.data ?? [],
											unit: chart.dataKeys.unit,
											yAxis: {
												position: i18n.language === 'en' ? 'left' : 'right',
												legend:
													chart.type === DataSources.PRESSURE
														? t('PressureInBAR')
														: chart.type === DataSources.FLOW_RATE
														? t('FlowRateInCMH')
														: undefined
											},

											allData: [],
											show: true
										}
									]}
									showCustomSpanSelector={false}
									alerts={[chart.alert]}
									chartTableDataSource={chart?.chartTableDataSource}
									showBrush={false}
									alarmLevels={chart?.alarmLevels}
								/>
							</Spin>
						) : null}
					</Col>
				</Col>
			</Row>
		</div>
	);
};
