import { Col, Empty, Row, Spin } from 'antd';
import { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { NavigationContext } from '../contexts/navigation.context';
import chartsService from '../services/charts.service';
import { FlowRateType, SpanType, customSpanSelector, GraphSpan } from '../types';
import { FlowRateUnit } from '../types/flow-rate-unit';
import { clearArrayUndefined } from '../utils/clean-array-undefined';
import { WaiNumericalChart } from './numerical-chart';
import { TimeSeriesBar } from './time-series-bar';
import { useQuery, useQueries } from '@tanstack/react-query';
import { mean } from 'stats-lite';
import { capitalizeWords } from '../utils/capitalize-string-words';
import { useTranslation } from 'react-i18next';

export const SiteAnalysisWaterConsumptionTab: React.FC<{
	siteName: any;
}> = ({ siteName }) => {
	const { t } = useTranslation();
	const [chartDateWaterConsumption, setChartDateWaterConsumption] = useState<{ start?: string; date?: string }>();
	const [chartTimeSpanWaterConsumption, setChartTimeSpanWaterConsumption] = useState<{
		start: string;
		end: string;
	}>();
	const [spanType, setSpanType] = useState<SpanType>(SpanType.DAY);

	const navigationContext = useContext(NavigationContext);
	const { selectedMeasurementPoint } = navigationContext;

	// Last water consumption readings for day, week, month
	const waterConsumptionLastDates: any = useQueries<any>({
		queries: (Object.keys(customSpanSelector) as (keyof typeof customSpanSelector)[]).map((span: any) => {
			return {
				queryKey: ['water-consumption-last', selectedMeasurementPoint, span],
				queryFn: () =>
					chartsService.getMeasurementPointDailyConsumption(selectedMeasurementPoint, {
						last: true,
						span_type: span.toLowerCase()
					}),
				enabled: !!selectedMeasurementPoint && !!span
			};
		})
	});
	// All water consumption readings regarding the days period
	const { data: waterConsumptionAllData } = useQuery<any>({
		queryKey: ['water-consumption-all-data', selectedMeasurementPoint],
		queryFn: () =>
			chartsService.getMeasurementPointDailyConsumption(selectedMeasurementPoint, {
				span_type: 'day'
			}),
		enabled: !!selectedMeasurementPoint
	});
	// Water consumption reading relative to numerical chart
	const { data: waterConsumptionDateChart, isLoading: loadingWaterDate } = useQuery<any>({
		queryKey: [
			'water-consumption-data',
			selectedMeasurementPoint,
			spanType,
			chartDateWaterConsumption,
			waterConsumptionLastDates[Object.values(SpanType).indexOf(spanType)]?.data
		],
		queryFn: () =>
			chartsService.getMeasurementPointDailyConsumption(selectedMeasurementPoint, {
				start:
					spanType === SpanType.DAY
						? undefined
						: chartDateWaterConsumption?.start
						? dayjs(chartDateWaterConsumption.start).format('YYYY-MM-DD')
						: waterConsumptionLastDates[Object.values(SpanType).indexOf(spanType)]?.data?.start &&
						  dayjs(
								waterConsumptionLastDates[Object.values(SpanType).indexOf(spanType)]?.data?.start
						  ).format('YYYY-MM-DD'),
				date:
					spanType !== SpanType.DAY
						? undefined
						: chartDateWaterConsumption?.date
						? dayjs(chartDateWaterConsumption.date).format('YYYY-MM-DD')
						: waterConsumptionLastDates[0]?.data?.data?.length
						? dayjs(waterConsumptionLastDates[0]?.data?.data[0]?.time).format('YYYY-MM-DD')
						: undefined,
				span_type: spanType
			}),
		enabled:
			(!!chartDateWaterConsumption || !!waterConsumptionLastDates) && !!selectedMeasurementPoint && !!spanType
	});
	// water consumtion readings relative to bar chart over a period of days
	const { data: waterConsumptionTimeSpanChart, isLoading: loadingWaterChart } = useQuery<any>({
		queryKey: ['water-consumption-data', chartTimeSpanWaterConsumption, selectedMeasurementPoint],
		queryFn: () =>
			chartsService.getMeasurementPointDailyConsumption(selectedMeasurementPoint, {
				start: chartTimeSpanWaterConsumption
					? dayjs(chartTimeSpanWaterConsumption.start).format('YYYY-MM-DD')
					: undefined,
				end: chartTimeSpanWaterConsumption
					? dayjs(chartTimeSpanWaterConsumption.end).format('YYYY-MM-DD')
					: undefined,
				span_type: 'day'
			}),
		enabled: !!selectedMeasurementPoint
	});

	return (
		<>
			<Row>
				<Col xs={24} className="mb-2">
					<Spin spinning={loadingWaterDate}>
						<WaiNumericalChart
							number={
								waterConsumptionDateChart && waterConsumptionDateChart?.data?.length > 0 ? (
									parseFloat(waterConsumptionDateChart?.data[0].value).toFixed(2)
								) : waterConsumptionDateChart?.average ? (
									parseFloat(waterConsumptionDateChart?.average).toFixed(2)
								) : (
									<Empty description={t('NoAvailableData')} />
								)
							}
							title={
								(spanType === SpanType.DAY &&
									waterConsumptionLastDates[0]?.data &&
									capitalizeWords(waterConsumptionLastDates[0]?.data.name)) ||
								t('AverageWaterConsumption')
							}
							unit={
								(((waterConsumptionDateChart?.data?.length > 0 &&
									waterConsumptionDateChart?.data[0]?.value) ||
									waterConsumptionDateChart?.average) &&
									waterConsumptionDateChart?.unit) ||
								undefined
							}
							maxWidth="100%"
							dateText={
								spanType === SpanType.DAY && waterConsumptionLastDates[0].data?.data.length > 0
									? chartDateWaterConsumption?.date ===
											dayjs(waterConsumptionLastDates[0].data.data[0].time).format(
												'YYYY-MM-DD'
											) || chartDateWaterConsumption?.date === undefined
										? t('LastReadingAtDate', {
												date: dayjs(waterConsumptionLastDates[0].data?.data[0].time).format(
													'Do MMM YYYY'
												)
										  })
										: t('ReadingAtDate', {
												date: dayjs(chartDateWaterConsumption?.date).format('Do MMM YYYY')
										  })
									: spanType === SpanType.WEEK && waterConsumptionLastDates[1].data?.start
									? chartDateWaterConsumption?.start === waterConsumptionLastDates[1].data.start
										? t('LastReadingAtDate', {
												date: `${dayjs(waterConsumptionLastDates[1].data.start).format(
													'DD MMM'
												)}:${dayjs(waterConsumptionLastDates[1].data.start)
													.endOf(SpanType.WEEK)
													.format('DD MMM YYYY')}`
										  })
										: t('ReadingAtDate', {
												date: `${dayjs(chartDateWaterConsumption?.start).format(
													'DD MMM'
												)}:${dayjs(chartDateWaterConsumption?.start)
													.endOf(SpanType.WEEK)
													.format('DD MMM YYYY')}`
										  })
									: waterConsumptionLastDates[2].data?.start &&
									  (chartDateWaterConsumption?.start === waterConsumptionLastDates[2].data.start
											? t('LastReadingAtDate', {
													date: dayjs(waterConsumptionLastDates[2].data.start).format(
														'MMMM YYYY'
													)
											  })
											: t('ReadingAtDate', {
													date: dayjs(chartDateWaterConsumption?.start).format('MMMM YYYY')
											  }))
							}
							lastReadingsDates={{
								day:
									waterConsumptionLastDates[0]?.data?.data &&
									waterConsumptionLastDates[0]?.data?.data[0]?.time,

								week: waterConsumptionLastDates[1]?.data?.start,
								month: waterConsumptionLastDates[2]?.data?.start
							}}
							showCustomSelectorDate
							OnDateSelect={(date: any) => setChartDateWaterConsumption(date)}
							showSpanSelector
							spanType={spanType}
							setSpanType={setSpanType}
							percentage={waterConsumptionDateChart?.preceding_reading?.percentage_change || undefined}
							increase={
								waterConsumptionDateChart?.preceding_reading?.trend_direction === 'up' ? true : false
							}
							positive={
								waterConsumptionDateChart?.preceding_reading?.trend_direction === 'up' ? false : true
							}
							customTimeUnits={[GraphSpan.DAY, GraphSpan.WEEK, GraphSpan.MONTH]}
							sourceName={siteName}
						/>
					</Spin>
				</Col>
				<Col xs={24}>
					<Spin spinning={loadingWaterChart}>
						<TimeSeriesBar
							IDs={[
								{
									id: selectedMeasurementPoint,
									name: FlowRateType.DWC
								}
							]}
							height={55}
							graphHeight={550}
							showBrush
							showTabular
							onSpanSelect={(span: any) => setChartTimeSpanWaterConsumption(span)}
							lastReadingDate={
								waterConsumptionLastDates[0]?.data?.data?.length &&
								waterConsumptionLastDates[0]?.data.data[0]?.time
							}
							dataObjects={clearArrayUndefined([
								{
									id: waterConsumptionTimeSpanChart?.id,
									name: t('value'),
									data: waterConsumptionTimeSpanChart?.data ?? [],
									allData: waterConsumptionAllData?.data ?? [],
									unit: FlowRateUnit.CMD,
									yAxis: {
										position: 'left',
										legend: t('WaterConsumptionInCMD')
									}
								}
							])}
							showSpanSelector
							title={t('WaterConsumption')}
							showCustomSpanSelector={true}
							statistics={clearArrayUndefined(
								waterConsumptionTimeSpanChart
									? [
											{
												name: t('Minimum'),
												value: Math.min(
													...waterConsumptionTimeSpanChart?.data.map((log: any) => +log.value)
												),
												unit: FlowRateUnit.CMD
											},
											{
												name: t('Maximum'),
												value: Math.max(
													...waterConsumptionTimeSpanChart?.data.map((log: any) => +log.value)
												),
												unit: FlowRateUnit.CMD
											},
											{
												name: t('Average'),
												value: mean(
													waterConsumptionTimeSpanChart?.data.map((log: any) => +log.value)
												),
												unit: FlowRateUnit.CMD
											}
									  ]
									: []
							)}
							minMax={
								waterConsumptionAllData && {
									min: Math.min(...waterConsumptionAllData.data.map((log: any) => +log.value)),
									max: Math.max(...waterConsumptionAllData.data.map((log: any) => +log.value))
								}
							}
							showTimeStep
							nameSource={siteName}
						/>
					</Spin>
				</Col>
			</Row>
		</>
	);
};
