export const jsonToCSV = jsonArray => {
	const array = typeof jsonArray !== 'object' ? JSON.parse(jsonArray) : jsonArray;

	// Flatten nested objects
	function flattenObject(obj, parentKey = '') {
		if (obj === null) return {}; // if object is null, return empty object
		return Object.keys(obj).reduce((acc, key) => {
			const prefixedKey = parentKey ? `${parentKey}.${key}` : key;
			if (typeof obj[key] === 'object') {
				Object.assign(acc, flattenObject(obj[key], prefixedKey));
			} else {
				acc[prefixedKey] = obj[key];
			}
			return acc;
		}, {});
	}

	const flattenedArray = array.map(item => flattenObject(item));

	// Extract headers
	const headers = new Set();
	flattenedArray.forEach(item => {
		Object.keys(item).forEach(key => {
			headers.add(key);
		});
	});

	const headerArray: any = Array.from(headers);
	const csvRows: any = [];
	csvRows.push(headerArray.join(','));

	flattenedArray.forEach(item => {
		const row = headerArray.map(header => `"${item[header] || ''}"`);
		csvRows.push(row.join(','));
	});

	return csvRows.join('\n');
};
