import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const DescriptionCardContainer = styled.div`
	background-color: ${colors.WHITE};
	padding: 15px 10px;
	border-radius: 5px;
	// min-width: 320px;
	width: 100%;
`;

export const DescriptionCardTitle = styled.h2<{ englishSelected: boolean }>`
	color: #383874;
	color: ${colors.PURPLE};
	font-size: 1.5rem;
	margin-bottom: 25px;
	${({ englishSelected }) => (englishSelected ? '' : 'text-align: right;')};
`;

export const DescriptionCardInfoLabel = styled.span`
	font-weight: 600;
	margin-inline: 5px;
`;

export const DescriptionCardInfoText = styled.p`
	margin: 0;
	font-size: 0.9rem;
`;
