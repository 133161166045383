import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DescriptionCard } from '../description-card';

export const ZoneStaticInformation: React.FC<HTMLAttributes<HTMLDivElement> & { zone: any; zoneParameters?: any }> = ({
	zone,
	zoneParameters
}) => {
	const { t, i18n } = useTranslation();
	const [items, setItems] = useState<{ label: string; value: string }[]>([{ label: '', value: '' }]);

	useEffect(() => {
		if (zone) {
			const info = [
				{
					label: t('Type'),
					value: zone.type
				},
				{
					label: t('SunscriberNo'),
					value: zone.subscriber_no
				}
			];
			if (zoneParameters) {
				const paramInfo: any[] = [
					{
						label: t('LengthOfMmains'),
						value: zoneParameters.length_of_mains
					},
					{
						label: t('NoOfConnections'),
						value: zoneParameters.no_of_connections
					},
					{
						label: t('NoOfProperties'),
						value: zoneParameters.no_of_properties
					},
					{
						label: t('EstimatedPopulation'),
						value: zoneParameters.estimated_population
					},
					{
						label: t('NoOfSmallNonDomesticUsers'),
						value: zoneParameters.no_of_small_non_domestic_users
					},
					{
						label: t('NoOfLargeNonDomesticUsers'),
						value: zoneParameters.no_of_large_non_domestic_users
					}
				];
				info.push(...paramInfo);
			}
			setItems(info);
		}
	}, [zone, zoneParameters]);

	return zone ? (
		<DescriptionCard
			className="mb-3"
			title={i18n.language === 'en' ? zone.name_en : zone?.name_ar || zone.name_en}
			items={items}
		/>
	) : null;
};
