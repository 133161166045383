import { GraphSpan } from './graph-span';

export enum SpanType {
	DAY = 'day',
	WEEK = 'week',
	MONTH = 'month',
	YEAR = 'year'
}

export const SpanTypeMapper = {
	[GraphSpan.DAY]: 'day',
	[GraphSpan.WEEK]: 'week',
	[GraphSpan.MONTH]: 'month',
	[GraphSpan.YEAR]: 'year'
};
