import { useEffect, useState } from 'react';
import { Button, List, Collapse, Table, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { CommercialMeterGroupTable } from '../commercial-meter-group-table';
import { upperSnakeToCapitalized } from '../../utils';

export const CommercialMeterGroupUploadFileConfirmation: React.FC<{
	submitting: boolean;
	selectedUploadData: any;
	uploadedLogs: any;
	currentStep: number;
}> = ({ submitting, selectedUploadData, uploadedLogs, currentStep }) => {
	const { t } = useTranslation();

	const [confirmDataDisplay, setConfirmDatadisplay] = useState<any>(null);
	const [totalUpdatesData, setTotalUpdatesData] = useState<any>(null);

	const [activateSitesUpdates, setActivateSitesUpdates] = useState<any[]>([]);
	const [activateSitesCreated, setActivateSitesCreated] = useState<any[]>([]);
	const [activateSiteCreatedData, setActivateSiteCreatedData] = useState<any[]>([]);
	const [expandedType, setExpandedType] = useState<any>([]);
	const [expandedFailedType, setExpandedFailedType] = useState<any>([]);

	useEffect(() => {
		if (selectedUploadData) {
			setConfirmDatadisplay({
				sites_updated: selectedUploadData.sites_updated?.slice(0, 5) || [],
				sites_failed: selectedUploadData.sites_failed?.slice(0, 5) || []
			});
			setTotalUpdatesData({
				sites_updated: selectedUploadData.sites_updated?.length || null,
				sites_failed: selectedUploadData.sites_failed?.length || null
			});
		}
	}, [selectedUploadData]);

	useEffect(() => {
		setActivateSitesUpdates([]);
		setActivateSitesCreated([]);
		setActivateSiteCreatedData([]);
		setExpandedType(['successful']);
		setExpandedFailedType([]);
	}, [currentStep]);

	const typesColumns: any = [
		{
			title: t('Type'),
			dataIndex: 'type',
			key: 'type',
			align: 'center',
			render: (text: string) => upperSnakeToCapitalized(text)
		}
	];

	const failedTypesColumns: any = [
		{
			title: t('FailedType'),
			dataIndex: 'failed_type',
			key: 'failed_type',
			align: 'left',
			render: (text: string) => upperSnakeToCapitalized(text)
		}
	];

	return (
		<>
			<div className="mt-3">
				<div style={{ overflowY: 'auto', height: '65vh', overflowX: 'hidden' }} className=" mt-3">
					{currentStep === 1 && !!uploadedLogs ? (
						<div style={{ overflowY: 'auto', maxHeight: '65vh', overflowX: 'hidden' }}>
							<Table
								tableLayout="auto"
								scroll={{ x: true }}
								columns={typesColumns}
								dataSource={[
									{ type: 'failed', data: uploadedLogs?.failed },
									{ type: 'successful', data: uploadedLogs?.successful }
								]}
								size="small"
								rowKey="type"
								pagination={false}
								expandable={{
									expandedRowKeys: expandedType,
									onExpand: (expanded, record) => {
										if (expanded) {
											setExpandedType([record.type]);
										} else {
											setExpandedType([]);
										}
									},
									expandedRowRender: record => {
										if (record.type === 'successful') {
											return (
												<CommercialMeterGroupTable
													style={{
														overflowY: 'auto',
														maxHeight: '65vh',
														overflowX: 'hidden'
													}}
													loading={false}
													tableData={record.data}
												/>
											);
										} else {
											const dataSource: any[] = [
												record.data['sites_failed'].length > 0 && {
													failed_type: 'sites_failed',
													data: record.data['sites_failed']
												},
												record.data['data_sources_failed'].length > 0 && {
													failed_type: 'data_sources_failed',
													data: record.data['data_sources_failed']
												},
												record.data['data_validation_errors'].length > 0 && {
													failed_type: 'data_validation_errors',
													data: record.data['data_validation_errors']
												},
												record.data['logs_failed'].length > 0 && {
													failed_type: 'logs_failed',
													data: record.data['logs_failed']
												}
											].filter(Boolean);
											return (
												<div
													style={{
														overflowY: 'auto',
														maxHeight: '65vh',
														overflowX: 'hidden'
													}}
												>
													<Card>
														<Table
															key={record.type}
															tableLayout="auto"
															scroll={{ x: true }}
															columns={failedTypesColumns}
															dataSource={dataSource}
															size="small"
															rowKey="failed_type"
															pagination={false}
															expandable={{
																expandedRowKeys: expandedFailedType,
																onExpand: (expanded, record) => {
																	if (expanded) {
																		setExpandedFailedType([record.failed_type]);
																	} else {
																		setExpandedFailedType([]);
																	}
																},
																expandedRowRender: record => {
																	return (
																		<div
																			key={record.failed_type}
																			style={{
																				overflowY: 'auto',
																				maxHeight: '65vh',
																				overflowX: 'hidden'
																			}}
																		>
																			<CommercialMeterGroupTable
																				loading={false}
																				tableData={record.data}
																				tableColor={'error'}
																			/>
																		</div>
																	);
																},
																expandRowByClick: true
															}}
														/>
													</Card>
												</div>
											);
										}
									},
									expandRowByClick: true
								}}
							/>
						</div>
					) : null}
					{currentStep === 2 &&
						(confirmDataDisplay?.sites_updated?.length > 0 ||
						confirmDataDisplay?.sites_failed?.length > 0 ? (
							<Collapse
								accordion
								activeKey={activateSitesUpdates}
								onChange={(e: any) => {
									setActivateSitesUpdates(e);
								}}
								key={'sites_updated_collapse'}
							>
								{confirmDataDisplay?.sites_updated?.length > 0 ? (
									<Collapse.Panel
										key={'sites_updated'}
										header={t('TheFollowingNameWillBeUpdated', {
											name: `${totalUpdatesData?.sites_updated} site`
										})}
									>
										<List
											loading={submitting}
											itemLayout="horizontal"
											loadMore={
												confirmDataDisplay.sites_updated.length <
													selectedUploadData?.sites_updated?.length - 1 && (
													<div
														style={{
															textAlign: 'center',
															marginTop: 12,
															height: 32,
															lineHeight: '32px'
														}}
													>
														<Button
															onClick={() => {
																setConfirmDatadisplay({
																	...confirmDataDisplay,
																	sites_updated: [
																		...confirmDataDisplay.sites_updated,
																		...selectedUploadData.sites_updated.slice(
																			confirmDataDisplay.sites_updated.length,
																			confirmDataDisplay.sites_updated.length +
																				5 <
																				selectedUploadData.sites_updated
																					.length -
																					1
																				? confirmDataDisplay.sites_updated
																						.length + 5
																				: selectedUploadData.sites_updated
																						.length - 1
																		)
																	]
																});
															}}
														>
															{t('Load', { ns: 'common' })}
														</Button>
													</div>
												)
											}
											dataSource={confirmDataDisplay.sites_updated}
											renderItem={(site: any, idx: number) => (
												<Collapse
													accordion
													activeKey={activateSitesCreated}
													onChange={(e: any) => {
														setActivateSitesCreated(e);
														setActivateSiteCreatedData([]);
													}}
													key="sites_updated_data_collapse"
												>
													<Collapse.Panel key={`site_${idx}`} header={site.site}>
														<Collapse
															accordion
															activeKey={activateSiteCreatedData}
															onChange={(e: any) => {
																setActivateSiteCreatedData(e);
															}}
														>
															{site.data_sources_created?.length > 0 ? (
																<Collapse.Panel
																	key={'data_sources_created'}
																	header={t('TheFollowingNameWillBeUpdated', {
																		name: `${site.data_sources_created.length} data source`
																	})}
																>
																	<CommercialMeterGroupTable
																		loading={false}
																		tableData={site.data_sources_created}
																	/>
																</Collapse.Panel>
															) : null}
															{site.data_sources_failed?.length > 0 ? (
																<Collapse.Panel
																	key={'data_sources_failed'}
																	header={t('TheFollowingNameFailed', {
																		name: `${site.data_sources_failed.length} data source`
																	})}
																>
																	<CommercialMeterGroupTable
																		loading={false}
																		tableData={site.data_sources_failed}
																		tableColor={'error'}
																	/>
																</Collapse.Panel>
															) : null}
														</Collapse>
													</Collapse.Panel>
												</Collapse>
											)}
										/>
									</Collapse.Panel>
								) : null}

								{confirmDataDisplay?.sites_failed?.length > 0 ? (
									<Collapse.Panel
										key={'sites_failed'}
										header={t('NameFailed', {
											name: `${totalUpdatesData?.sites_failed} site`
										})}
									>
										<List
											loading={submitting}
											itemLayout="horizontal"
											loadMore={
												confirmDataDisplay.sites_failed.length <
													selectedUploadData?.sites_failed?.length - 1 && (
													<div
														style={{
															textAlign: 'center',
															marginTop: 12,
															height: 32,
															lineHeight: '32px'
														}}
													>
														<Button
															onClick={() => {
																setConfirmDatadisplay({
																	...confirmDataDisplay,
																	sites_failed: [
																		...confirmDataDisplay.sites_failed,
																		...selectedUploadData.sites_failed.slice(
																			confirmDataDisplay.sites_failed.length,
																			confirmDataDisplay.sites_failed.length + 5 <
																				selectedUploadData.sites_failed.length -
																					1
																				? confirmDataDisplay.sites_failed
																						.length + 5
																				: selectedUploadData.sites_failed
																						.length - 1
																		)
																	]
																});
															}}
														>
															{t('Load', { ns: 'common' })}
														</Button>
													</div>
												)
											}
											dataSource={confirmDataDisplay.sites_failed}
											renderItem={(site: any, idx: number) => (
												<Collapse
													accordion
													activeKey={activateSitesCreated}
													onChange={(e: any) => {
														setActivateSitesCreated(e);
														setActivateSiteCreatedData([]);
													}}
													key="sites_failed_data_collapse"
												>
													<Collapse.Panel key={`site_${idx}`} header={site.site}>
														<Collapse
															accordion
															activeKey={activateSiteCreatedData}
															onChange={(e: any) => {
																setActivateSiteCreatedData(e);
															}}
														>
															{site.data_sources_failed?.length > 0 ? (
																<Collapse.Panel
																	key={'data_sources_failed'}
																	header={t('TheFollowingNameFailed', {
																		name: `${site.data_sources_failed.length} data source`
																	})}
																>
																	<CommercialMeterGroupTable
																		loading={false}
																		tableData={site.data_sources_failed}
																		tableColor={'error'}
																	/>
																</Collapse.Panel>
															) : null}
														</Collapse>
													</Collapse.Panel>
												</Collapse>
											)}
										/>
									</Collapse.Panel>
								) : null}
							</Collapse>
						) : (
							<div
								className="d-flex justify-content-center align-items-center"
								style={{ height: '100%' }}
							>
								<h5 className="text-center">{t('NoSitesUpdates')} </h5>
							</div>
						))}
				</div>
			</div>
		</>
	);
};
