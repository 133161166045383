import { INetworkRecord } from '../types/network-record';

export const transformNetworksToRecord = (networks: any): INetworkRecord[] => {
	return networks.map((network: any) => {
		return {
			id: network.id,
			name_en: network.name_en,
			name_ar: network?.name_ar || network.name_en,
			description_en: network.description_en,
			description_ar: network?.description_ar || network.description_en,
			latitude: network.latitude,
			longitude: network.longitude,
			created_at: network.created_at,
			subscriber_no: network.subscriber_no
		};
	});
};
