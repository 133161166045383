import { IZoneRecord } from '../types/zone-record';

export const transformZonesToRecord = (zones: any): IZoneRecord[] => {
	return zones.map((zone: any) => {
		return {
			id: zone.id,
			name_en: zone.name_en,
			name_ar: zone?.name_ar || zone.name_en,
			description_en: zone.description_en,
			description_ar: zone?.description_ar || zone.description_en,
			latitude: zone.latitude,
			longitude: zone.longitude,
			created_at: zone.created_at,
			network: zone.network,
			type: zone.type,
			measurement_points: zone?.measurement_points
		};
	});
};
