import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { GraphCard } from '../components';
import { colors } from '../constants/colors';
import { useTranslation } from 'react-i18next';
import { ChartCustomSpan, TimeStep, TimeStepMapper } from '../types';
import { clearArrayUndefined } from '../utils/clean-array-undefined';
import { dateToQuery } from '../utils/date-to-query';
const COLORS = [
	colors.GRAPH_PURPLE,
	colors.GRAPH_GREEN,
	colors.WARNING,
	colors.HEALTHY,
	colors.ERROR,
	colors.GRAPH_RED,
	colors.GRAPH_CYAN
];
const getOptions: ({}: any) => ApexOptions = ({ t = {}, noDataText = '', rangeDate }) => {
	return {
		chart: {
			id: 'analysis-chart',
			type: 'rangeArea',
			stacked: false,

			zoom: {
				type: 'x',
				enabled: true,
				autoScaleYaxis: false
			}
		},

		colors: COLORS,
		stroke: { width: 2, curve: 'smooth' },
		dataLabels: {
			enabled: false
		},
		markers: {
			size: 0
		},
		noData: { text: noDataText ? noDataText : t('NoAvailableDataDuringThisTimeSpan', { ns: 'validation' }) },
		tooltip: {
			shared: true,
			y: {
				formatter(val, opts) {
					return val ? val.toFixed(3).toString() : '';
				}
			},
			x: {
				formatter(val, opts) {
					return (
						rangeDate?.start &&
						rangeDate?.end &&
						`${dayjs(rangeDate?.start).format('DD MMM')} - ${dayjs(rangeDate?.end).format('DD MMM')}`
					);
				}
			}
		},
		xaxis: {
			type: 'datetime',
			tooltip: { enabled: false },
			labels: {
				datetimeUTC: false
			}
		},
		yaxis: {
			labels: {
				formatter(val) {
					return val.toFixed(3);
				}
			}
		}
	};
};

export const WaiRangeChart: React.FC<any> = ({
	dataObjects,
	showSpanSelector,
	showUnitSelector,
	showTabular,
	chartTableDataSource,
	showCustomSpanSelector,
	showCustomSelectorDate,
	height,
	showBrush,
	title,
	graphHeight,
	alerts,
	alarmLevels,
	statistics,
	onSpanSelect,
	OnDateSelect,
	lastReadingDate,
	minMax,
	noDataText,
	IDs,
	flags,
	changeDate,
	customTimeUnits,
	dateText,
	analysisRedirectUri,
	showTimeStep,
	spanType,
	rangeDate,
	sourceName,
	profile,
	...restProps
}) => {
	const { t } = useTranslation();
	const [series, setSeries] = useState<any>([]);
	const [units, setUnits] = useState<any>();
	const [yAxis, setYAxis] = useState<any>();
	const [cleanDataObjects, setCleanDataObjects] = useState<any>([]);
	const [alertsWithVal, setAlertsWithVal] = useState<any>();
	const [chartNote, setChartNote] = useState<string>('');
	const [displayDate, setDisplayDate] = useState<any>();
	const [displayTimeSpan, setDisplayTimeSpan] = useState<{ start: any; end: any }>();
	const [timeStep, setTimeStep] = useState<TimeStep | undefined>(undefined);
	const [firstDate, setFirstDate] = useState<any>();
	const [lastDate, setLastDate] = useState<any>();

	useEffect(() => {
		if (dataObjects && dataObjects.length) {
			setCleanDataObjects(dataObjects.filter((d: any) => !!d.id));
		} else {
			setCleanDataObjects([]);
		}
	}, [dataObjects, dataObjects?.length]);

	useEffect(() => {
		if (timeStep && timeStep.length) {
			onSpanSelect &&
				onSpanSelect({
					start:
						displayDate && displayDate?.start
							? dateToQuery(dayjs(displayDate?.start).startOf(timeStep))
							: lastReadingDate && dateToQuery(dayjs(lastReadingDate).startOf(timeStep)),
					end:
						displayDate && displayDate?.start
							? dateToQuery(dayjs(displayDate?.start).endOf(timeStep))
							: lastReadingDate && dateToQuery(dayjs(lastReadingDate).endOf(timeStep))
				});
		}
	}, [timeStep, timeStep?.length]);
	useEffect(() => {
		const cleanAlerts: any = [];
		if (cleanDataObjects && cleanDataObjects?.[0]?.data?.length && alerts?.length) {
			alerts.forEach((alert: any) => {
				const startVal = +cleanDataObjects[0].data.find((d: any) => {
					return d.time === alert.start;
				})?.value;
				const endVal = +cleanDataObjects[0].data.find((d: any) => {
					return d.time === alert.end;
				})?.value;

				cleanAlerts.push({ ...alert, startVal, endVal });
			});
			setAlertsWithVal(cleanAlerts);
		} else {
			setAlertsWithVal([]);
		}
	}, [cleanDataObjects, cleanDataObjects?.length, alerts?.length]);

	useEffect(() => {
		setSeries(
			clearArrayUndefined(
				cleanDataObjects.map((dataObject: any) => {
					if (dataObject.data?.length) {
						const line = {
							name: dataObject.linePropName,
							type: 'line',
							data: dataObject.data.map((d: any) => {
								return {
									x: d.time,
									y: +d[dataObject.linePropName],
									unit: dataObject.unit
								};
							})
						};
						const range = {
							name: `${dataObject.rangePropName.min} ${dataObject.rangePropName.max}`,
							type: 'rangeArea',
							data: dataObject.data.map((d: any) => {
								return {
									x: d.time,
									y: [+d[dataObject.rangePropName.min], +d[dataObject.rangePropName.max]],
									unit: dataObject.unit
								};
							})
						};

						return [line, range];
					}
				})
			)
		);
	}, [cleanDataObjects, cleanDataObjects?.length]);

	useEffect(() => {
		setUnits(
			clearArrayUndefined(
				cleanDataObjects.map((dataObject: any) => {
					if (dataObject.data?.length) return dataObject.unit;
				})
			)
		);
	}, [cleanDataObjects, cleanDataObjects?.length]);

	useEffect(() => {
		setYAxis(
			clearArrayUndefined(
				cleanDataObjects.map((dataObject: any) => {
					if (dataObject.data?.length && dataObject?.yAxis?.position) return dataObject.yAxis;
				})
			)
		);
	}, [cleanDataObjects, cleanDataObjects?.length]);

	useEffect(() => {
		if (customTimeUnits && customTimeUnits?.length) {
			setTimeStep(TimeStepMapper[customTimeUnits[0]]);
		}
	}, [customTimeUnits]);
	const options = getOptions({
		t: t,
		noDataText: noDataText,
		rangeDate: rangeDate
	});

	return (
		<GraphCard
			className="mb-4"
			title={title || t('Analysis')}
			height={height}
			showSpanSelector={showSpanSelector}
			showUnitSelector={showUnitSelector}
			onSpanSelect={onSpanSelect}
			OnDateSelect={OnDateSelect}
			showTabular={showTabular}
			dataObjects={cleanDataObjects}
			actualDataTimeSpan={
				cleanDataObjects && cleanDataObjects?.length && cleanDataObjects[0] && cleanDataObjects[0]?.data
					? ({
							start: firstDate && firstDate,
							end: lastDate && lastDate
					  } as ChartCustomSpan)
					: ({
							start:
								displayTimeSpan && displayTimeSpan?.start
									? dateToQuery(dayjs(displayTimeSpan?.start))
									: lastReadingDate && dateToQuery(dayjs(lastReadingDate)),
							end:
								displayTimeSpan && displayTimeSpan?.end
									? dateToQuery(dayjs(displayTimeSpan?.end))
									: lastReadingDate && dateToQuery(dayjs(lastReadingDate))
					  } as ChartCustomSpan)
			}
			actualDataDate={
				cleanDataObjects &&
				cleanDataObjects.length &&
				cleanDataObjects[0].data &&
				cleanDataObjects[0].data.length
					? cleanDataObjects[0].data[cleanDataObjects[0].data.length - 1].time
					: undefined
			}
			chartTableDataSource={chartTableDataSource}
			showCustomSpanSelector={showCustomSpanSelector}
			showCustomSelectorDate={showCustomSelectorDate}
			statistics={statistics}
			lastReadingDate={lastReadingDate}
			IDs={IDs}
			flags={flags}
			showTimeStep={showTimeStep}
			timeStep={timeStep}
			setTimeStep={setTimeStep}
			analysisRedirectUri={analysisRedirectUri}
			setDisplayDate={setDisplayDate}
			displayDate={displayDate}
			setDisplayTimeSpan={setDisplayTimeSpan}
			displayTimeSpan={displayTimeSpan}
			customTimeUnits={customTimeUnits}
			spanType={spanType}
			sourceName={sourceName}
			differentKeys={true}
			profile={profile}
			{...restProps}
		>
			<div style={{ height: '100%', width: '100%' }} dir="ltr" className="d-flex flex-column pe-2">
				{timeStep && timeStep?.length ? (
					<>
						<div className="d-flex  align-items-center ">
							<AiOutlineLeft
								role={'button'}
								size={'2rem'}
								onClick={() => {
									if (showCustomSelectorDate) {
										OnDateSelect &&
											OnDateSelect(
												displayDate
													? dayjs(displayDate).subtract(1, timeStep).format('YYYY-MM-DD')
													: lastReadingDate &&
															dayjs(lastReadingDate)
																.subtract(1, timeStep)
																.format('YYYY-MM-DD')
											);

										setDisplayDate &&
											setDisplayDate(
												displayDate
													? dayjs(displayDate).subtract(1, timeStep).format('YYYY-MM-DD')
													: lastReadingDate &&
															dayjs(lastReadingDate)
																.subtract(1, timeStep)
																.format('YYYY-MM-DD')
											);
									} else {
										setDisplayTimeSpan &&
											setDisplayTimeSpan({
												start: displayTimeSpan?.start
													? dayjs(displayTimeSpan?.start)
															.subtract(1, timeStep)
															.startOf(timeStep)
															.format('YYYY-MM-DD')
													: lastReadingDate &&
													  dayjs(lastReadingDate)
															.subtract(1, timeStep)
															.startOf(timeStep)
															.format('YYYY-MM-DD'),
												end: displayTimeSpan?.start
													? dayjs(displayTimeSpan?.start)
															.subtract(1, timeStep)
															.endOf(timeStep)
															.format('YYYY-MM-DD')
													: lastReadingDate &&
													  dayjs(lastReadingDate)
															.subtract(1, timeStep)
															.endOf(timeStep)
															.format('YYYY-MM-DD')
											});
										onSpanSelect &&
											onSpanSelect({
												start: displayTimeSpan?.start
													? dateToQuery(
															dayjs(displayTimeSpan?.start)
																.subtract(1, timeStep)
																.startOf(timeStep)
													  )
													: lastReadingDate &&
													  dateToQuery(
															dayjs(lastReadingDate)
																.subtract(1, timeStep)
																.startOf(timeStep)
													  ),
												end: displayTimeSpan?.start
													? dateToQuery(
															dayjs(displayTimeSpan?.start)
																.subtract(1, timeStep)
																.endOf(timeStep)
													  )
													: lastReadingDate &&
													  dateToQuery(
															dayjs(lastReadingDate).subtract(1, timeStep).endOf(timeStep)
													  )
											});
									}
								}}
							/>
							<div style={{ width: '100%' }}>
								<Chart
									options={options}
									type="rangeBar"
									series={series[0] || []}
									height={graphHeight}
								/>
							</div>
							<AiOutlineRight
								size={'2rem'}
								role={'button'}
								onClick={() => {
									if (showCustomSelectorDate) {
										OnDateSelect &&
											OnDateSelect(
												displayDate
													? dayjs(displayDate).add(1, timeStep).format('YYYY-MM-DD')
													: lastReadingDate &&
															dayjs(lastReadingDate).add(1, timeStep).format('YYYY-MM-DD')
											);
										setDisplayDate &&
											setDisplayDate(
												displayDate
													? dayjs(displayDate).add(1, timeStep).format('YYYY-MM-DD')
													: lastReadingDate &&
															dayjs(lastReadingDate).add(1, timeStep).format('YYYY-MM-DD')
											);
									} else {
										setDisplayTimeSpan &&
											setDisplayTimeSpan({
												start: displayTimeSpan?.start
													? dayjs(displayTimeSpan?.start)
															.add(1, timeStep)
															.startOf(timeStep)
															.format('YYYY-MM-DD')
													: lastReadingDate &&
													  dayjs(lastReadingDate)
															.add(1, timeStep)
															.startOf(timeStep)
															.format('YYYY-MM-DD'),
												end: displayTimeSpan?.start
													? dayjs(displayTimeSpan?.start)
															.add(1, timeStep)
															.endOf(timeStep)
															.format('YYYY-MM-DD')
													: lastReadingDate &&
													  dayjs(lastReadingDate)
															.add(1, timeStep)
															.endOf(timeStep)
															.format('YYYY-MM-DD')
											});
										onSpanSelect &&
											onSpanSelect({
												start: displayTimeSpan?.start
													? dateToQuery(
															dayjs(displayTimeSpan?.start)
																.add(1, timeStep)
																.startOf(timeStep)
													  )
													: lastReadingDate &&
													  dateToQuery(
															dayjs(lastReadingDate).add(1, timeStep).startOf(timeStep)
													  ),
												end: displayTimeSpan?.start
													? dateToQuery(
															dayjs(displayTimeSpan?.start)
																.add(1, timeStep)
																.endOf(timeStep)
													  )
													: lastReadingDate &&
													  dateToQuery(
															dayjs(lastReadingDate).add(1, timeStep).endOf(timeStep)
													  )
											});
									}
								}}
							/>
						</div>
					</>
				) : (
					<Chart options={options} type="rangeBar" series={series[0] || []} height={graphHeight} />
				)}
			</div>
		</GraphCard>
	);
};
