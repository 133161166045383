import { Col, Empty, Row, Tabs } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AnalysisTab, ComponentGuard, NetworkStaticInformation, Premium } from '../components';
import configProxy from '../config/config';
import { colors } from '../constants/colors';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import { useMedia } from '../hooks/use-media.hook';
import { MapSummary } from './map-summary';
import { FiCornerDownRight } from 'react-icons/fi';
import { UserApps, Zones } from '../types';
import { useQueryParam } from '../hooks/use-query';
import { useQuery } from '@tanstack/react-query';
import alertsService from '../services/alerts.service';
import { AlertsTableSummary } from './alerts-table-summary';
import { NetworkAnalysisMeasurementPointsTab } from './network-analysis-measurement-points-tab';
import { NetworkAnalysisLoggersDashboardTab } from './network-analysis-loggers-dashboard-tab';
import { hasApp } from '../utils';
import { AuthenticationContext } from '../contexts';
import { TbMap2 } from 'react-icons/tb';
import { GiMovementSensor, GiWaterTower } from 'react-icons/gi';
import { MdOutlineDvr } from 'react-icons/md';
import { NetworkAnalysisCumulativeReadingsTab } from './network-analysis-cumulative-readings-tab';
import { NetworkWaterProductionTab } from './network-water-production-tab';
import { Sites } from '../types/sites';

export const NetworkAnalysis: React.FC<any> = () => {
	const { t, i18n } = useTranslation();

	const [selectedNetworkObject, setSelectedNetworkObject] = useState<any>();
	const [selectedMeasurementPoint, setSelectedMeasurementPoint] = useState<any>();

	const navigationContext = useContext(NavigationContext);
	const { networks, selectedNetwork, zones, measurementPoints, applicationIdMap } = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user, analysisPermessions } = authContext;

	const media = configProxy.defaultExpandScreenSize;
	const desktopView = useMedia(media);
	const selectedTab = useQueryParam('selectedTab');

	// loading selected netwrok data
	useEffect(() => {
		const network = networks?.find((zone: any) => zone.id === selectedNetwork);
		setSelectedNetworkObject(network);
	}, [selectedNetwork, networks]);

	// loading alerts for selected network
	const { data: alerts, isLoading: loadingAlerts }: any = useQuery({
		queryKey: ['alerts', selectedNetwork],
		queryFn: () =>
			alertsService.getAlertsNew({
				'page': 1,
				'page_size': 5,
				'network-id': selectedNetwork,
				'sorters': { alertEnd: -1 }
			}),
		enabled: !!selectedNetwork
	});

	const hasDevicesDashboardApp = hasApp(
		!!user?.user_data?.is_superAdmin,
		analysisPermessions,
		applicationIdMap.get(UserApps.DEVICES_STATUS_DASHBOARD),
		selectedNetwork
	);
	const hasWaterProductionApp = hasApp(
		!!user?.user_data?.is_superAdmin,
		analysisPermessions,
		applicationIdMap.get(UserApps.WATER_PRODUCTION),
		selectedNetwork
	);
	const devicesDashboardTabChildren = (
		<AnalysisTab icon={<GiMovementSensor size={20} />} title={t('LoggersDashboard')} />
	);
	const waterProductionChildren = (
		<AnalysisTab
			icon={<GiWaterTower size={20} className={i18n.language === 'en' ? '' : 'ms-1'} />}
			title={t('WaterProduction')}
		/>
	);

	const tabItems = [
		{
			label: <AnalysisTab icon={<TbMap2 size={20} />} title={t('MeasurementPoints')} />,
			key: 'sites',
			children: <NetworkAnalysisMeasurementPointsTab setSelectedMeasurementPoint={setSelectedMeasurementPoint} />
		},
		{
			label: (
				<ComponentGuard
					allowed={hasDevicesDashboardApp}
					fallback={() => <Premium showIcon children={devicesDashboardTabChildren} />}
				>
					{devicesDashboardTabChildren}
				</ComponentGuard>
			),
			key: 'loggers-dashboard',
			children: <NetworkAnalysisLoggersDashboardTab setSelectedMeasurementPoint={setSelectedMeasurementPoint} />,
			disabled: !hasDevicesDashboardApp
		}
	];

	if (measurementPoints?.find(site => site.type === Sites.WATER_STATION)) {
		tabItems.push({
			label: (
				<ComponentGuard
					allowed={hasWaterProductionApp}
					fallback={() => <Premium showIcon children={waterProductionChildren} />}
				>
					{waterProductionChildren}
				</ComponentGuard>
			),
			key: 'water-production',
			children: hasWaterProductionApp ? <NetworkWaterProductionTab /> : <Empty />,
			disabled: !hasWaterProductionApp
		});
	}

	if (zones?.find(zone => zone.type === Zones.COMMERCIAL_METER)) {
		tabItems.push({
			label: <AnalysisTab icon={<MdOutlineDvr size={20} />} title={t('CumulativeReadings')} />,
			key: 'network-commercial-cumulative-readings',
			children: <NetworkAnalysisCumulativeReadingsTab />
		});
	}

	return (
		<>
			<h3 className="mb-3">{t('NetworkAnalysis')}</h3>
			<Row gutter={24}>
				<Col xs={24} xl={12} xxl={14}>
					<Row>
						<Col xl={9}>
							<NetworkStaticInformation
								network={selectedNetworkObject}
								setSelectedMeasurementPoint={setSelectedMeasurementPoint}
							/>
						</Col>
						{desktopView ? null : (
							<Col xs={24} className="mb-4">
								<MapSummary
									style={{
										height: '590px',
										width: 'auto',
										borderRadius: '13px',
										zIndex: 50
									}}
									networks={networks}
									selectedNetwork={selectedNetwork}
									zones={zones}
									selectedMeasurementPoint={selectedMeasurementPoint ?? undefined}
								/>
							</Col>
						)}
						<Col xs={24}>
							<Tabs
								defaultActiveKey={selectedTab || undefined}
								type="card"
								style={{ fontWeight: '500' }}
								tabBarExtraContent={
									<Link
										to={`/dashboard/advanced-analysis`}
										media={media}
										style={{ color: colors.GRAPH_PURPLE, fontWeight: 'bold' }}
									>
										<FiCornerDownRight
											style={i18n.language === 'en' ? {} : { transform: 'scaleX(-1)' }}
											className="me-2"
										/>

										{t('goToPlace', { place: t('AdvancedAnalysis') })}
									</Link>
								}
								items={tabItems}
							/>
						</Col>
					</Row>
					<Row className="mt-4">
						<AlertsTableSummary
							zoneId={selectedNetwork}
							alerts={alerts?.results || []}
							className="w-100"
							loading={loadingAlerts}
						/>
					</Row>
				</Col>
				<Col xs={24} xl={12} xxl={10}>
					{desktopView ? (
						<MapSummary
							style={{
								height: '590px',
								width: 'auto',
								borderRadius: '13px',
								zIndex: 50
							}}
							networks={networks}
							selectedNetwork={selectedNetwork}
							zones={zones}
							selectedMeasurementPoint={selectedMeasurementPoint ?? undefined}
						/>
					) : null}
				</Col>
			</Row>
		</>
	);
};
