import { useQueries, useQuery } from '@tanstack/react-query';
import { Checkbox } from 'antd';
import L, { circleMarker, LatLngExpression, Map } from 'leaflet';
import { HTMLAttributes, useContext, useEffect, useState } from 'react';
import { CircleMarker, GeoJSON, MapContainer, Marker, Popup, TileLayer, useMap, ZoomControl } from 'react-leaflet';
import { useLocation } from 'react-router-dom';
import valveError from '../assets/img/valve-error.png';
import valveHealthy from '../assets/img/valve-healthy.png';
import valveInactive from '../assets/img/valve-inactive.png';
import valveWarning from '../assets/img/valve-warning.png';
import stationError from '../assets/img/station-error.svg';
import stationHealthy from '../assets/img/station-healthy.svg';
import stationInactive from '../assets/img/station-inactive.svg';
import stationWarning from '../assets/img/station-warning.svg';
import commercialMeterGroupHealthy from '../assets/img/commercial-meter-group-healthy.svg';
import commercialMeterGroupInactive from '../assets/img/commercial-meter-group-inactive.svg';
import commercialSiteHealthy from '../assets/img/commercial-site-healthy.svg';
import commercialSiteInactive from '../assets/img/commercial-site-inactive.svg';
import { GisToggler, MPInfoNew } from '../components';
import configProxy from '../config/config';
import { colors } from '../constants/colors';
import { AppRoutes } from '../constants/routes';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import { useMedia } from '../hooks/use-media.hook';
import { selectedChartConfigObject } from '../pages/monitoring';
import monitoringService from '../services/monitoring.service';
import { GISLayerType } from '../types/gis';
import { ValveOperationStatus } from '../types/valve';
import { StationOperationalStatus } from '../types/water-station';
import { DataSources, Zones } from '../types';
import { useQueryParam } from '../hooks/use-query';

const valveOperationStatusUrlMapper = {
	[ValveOperationStatus.NORMAL]: valveHealthy,
	[ValveOperationStatus.NEEDS_INVESTIGATION]: valveWarning,
	[ValveOperationStatus.OPERATION_ERROR]: valveError,
	[ValveOperationStatus.DATA_PROBLEM]: valveError,
	[ValveOperationStatus.OTHER]: valveInactive
};
const stationOperationStatusUrlMapper = {
	[StationOperationalStatus.NORMAL]: stationHealthy,
	[StationOperationalStatus.NEEDS_INVESTIGATION]: stationWarning,
	[StationOperationalStatus.OPERATION_ERROR]: stationError,
	[StationOperationalStatus.OTHER]: stationInactive
};

const valveIcon = (status: ValveOperationStatus, size: [number, number]) => {
	return L.icon({
		iconUrl: valveOperationStatusUrlMapper[status],
		iconSize: size
	});
};
const stationIcon = (status: StationOperationalStatus, size: [number, number]) => {
	return L.icon({
		iconUrl: stationOperationStatusUrlMapper[status],
		iconSize: size
	});
};
const commercialCommercialMeterGroupIcon = (status: any, size: [number, number]) => {
	return L.icon({
		iconUrl: status ? commercialMeterGroupHealthy : commercialMeterGroupInactive,
		iconSize: size
	});
};

const commercialSiteIcon = (status: any, size: [number, number]) => {
	return L.icon({
		iconUrl: status ? commercialSiteHealthy : commercialSiteInactive,
		iconSize: size
	});
};
const markerIcon = new L.Icon({
	iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	tooltipAnchor: [16, -28]
});

//TODO: split selected commercial meter group from selected zone in the future
export const MonitoringMap: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		networks?: any;
		selectedNetwork?: any;
		zones?: any;
		selectedZone?: any;
		style?: any;
		showGisToggler?: boolean;
		onMeasurementPointClick?: (point: any) => any;
		onMeasurementPointClose?: () => any;
		selectedMeasurementPoint?: string;
		selectedChartInfo?: selectedChartConfigObject;
		onChartSelect?: (fieldName?: string, coloredData?: any[], is_valve?: boolean) => void;
		pointMarker?: LatLngExpression;
	}
> = ({
	networks,
	selectedNetwork,
	zones,
	selectedZone,
	style,
	showGisToggler = true,
	onMeasurementPointClick,
	onMeasurementPointClose,
	selectedMeasurementPoint,
	selectedChartInfo,
	onChartSelect,
	pointMarker
}) => {
	const { t, i18n } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { sitesSideBar } = navigationContext;

	const [emphasizedPoints, setEmphasizedPoints] = useState<any[]>();

	const [circleMarkerRadius, setCircleMarkerRadius] = useState<number>(5);
	const [mapIconSize, setMapIconSize] = useState<[number, number]>([40, 40]);
	const [valveIconSize, setValveIconSize] = useState<[number, number]>([10, 10]);
	const [stationIconSize, setStationIconSize] = useState<[number, number]>([10, 10]);
	const [commercialMeterIconSize, setCommercialMeterIconSize] = useState<[number, number]>([10, 10]);
	const [commercialSiteIconSize, setCommercialSiteIconSize] = useState<[number, number]>([10, 10]);

	const [networkToShow, setNetworkToShow] = useState<any>();
	const [zonesToShow, setZonesToShow] = useState<any>([]);

	const [networkPipesGisLayers, setNetworkPipesGisLayers] = useState<any>([]);
	const [networkPipesGisLayersVisible, setNetworkPipesGisLayersVisible] = useState<boolean>(true);

	const [networkJunctionsGisLayers, setNetworkJunctionsGisLayers] = useState<any>([]);
	const [networkJunctionsGisLayersVisible, setNetworkJunctionsGisLayersVisible] = useState<boolean>(true);

	const [zonePipesGisLayers, setZonePipesGisLayers] = useState<any>([]);
	const [zonePipesGisLayersVisible, setZonePipesGisLayersVisible] = useState<boolean>(true);

	const [zoneJunctionsGisLayers, setZoneJunctionsGisLayers] = useState<any>([]);
	const [zoneJunctionsGisLayersVisible, setZoneJunctionsGisLayersVisible] = useState<boolean>(true);

	const [zoneBoundaryGisLayers, setZoneBoundaryGisLayers] = useState<any>([]);
	const [zoneBoundaryGisLayersVisible, setZoneBoundaryGisLayersVisible] = useState<boolean>(true);
	const [commercialMeterGroupsVisible, setCommercialMeterGroupsVisible] = useState<boolean>(true);

	const [networkStiesVisible, setNetworkStiesVisible] = useState<boolean>(true);
	const [valveStiesVisible, setValveStiesVisible] = useState<boolean>(true);
	const [waterStationSitesVisible, setWaterStationSitesVisible] = useState<boolean>(true);
	const [commercialStiesVisible, setCommercialStiesVisible] = useState<boolean>(true);

	const [viewProperties, setViewProperties] = useState<any>({ location: [26.820553, 30.802498], zoom: 5 });
	const [mapInstance, setMapInstance] = useState<Map>();
	const [lastOpenedPopup, setLastOpenedPopup] = useState<any>(null);

	const selectedZoneQuery = useQueryParam('zone');

	const xxl = useMedia('xxl');
	const location = useLocation().pathname;

	const MapInstance = () => {
		const mapInstance = useMap();
		setMapInstance(mapInstance);
		return null;
	};

	// resize sites radius when zooming
	mapInstance?.on('zoomend', () => {
		const currentZoom = mapInstance.getZoom();
		if (currentZoom >= 16) {
			setCircleMarkerRadius(15);
			setValveIconSize([80, 80]);
			setStationIconSize([40, 40]);
			setCommercialMeterIconSize([50, 50]);
			setCommercialSiteIconSize([40, 40]);
		} else if (currentZoom >= 15) {
			setCircleMarkerRadius(8);
			setValveIconSize([70, 70]);
			setStationIconSize([35, 35]);
			setCommercialMeterIconSize([45, 45]);
			setCommercialSiteIconSize([35, 35]);
		} else if (currentZoom >= 14) {
			setCircleMarkerRadius(5);
			setValveIconSize([60, 60]);
			setStationIconSize([30, 30]);
			setCommercialMeterIconSize([40, 40]);
			setCommercialSiteIconSize([30, 30]);
		} else if (currentZoom >= 11) {
			setCircleMarkerRadius(3);
			setValveIconSize([40, 40]);
			setStationIconSize([20, 20]);
			setCommercialMeterIconSize([30, 30]);
			setCommercialSiteIconSize([25, 25]);
		} else {
			setCircleMarkerRadius(2);
			setValveIconSize([30, 30]);
			setStationIconSize([15, 15]);
			setCommercialMeterIconSize([20, 20]);
			setCommercialSiteIconSize([15, 15]);
		}
	});

	// Track the last opened popup for site
	const onMarkerHover = (marker: any) => {
		if (lastOpenedPopup) {
			lastOpenedPopup.closePopup();
		}
		marker.openPopup();
		setLastOpenedPopup(marker);
	};

	// Loading all gis layers for selected netorks zone or selected zone
	const zonesGisLayers = useQueries<any>({
		queries: (zonesToShow || []).map((zone: any) => {
			return {
				queryKey: ['gis-layers', zone.id],
				queryFn: () => monitoringService.getZoneGisLayers(zone.id),
				enabled: !!zone
			};
		})
	});

	// load all gis layers for selected network
	const { data: networkGisLayers } = useQuery<any>({
		queryKey: ['gis-layers', selectedNetwork],
		queryFn: () => monitoringService.getNetworkGisLayers(selectedNetwork),

		enabled: !!selectedNetwork
	});

	// Loading all data sources for all measurement points in network/zone
	const mpDataSources = useQueries<any>({
		queries: (sitesSideBar || []).map((point: any) => {
			return {
				queryKey: ['data-sources', point.id],
				queryFn: () => monitoringService.getDataSources({ filters: { measurement_point: point.id } }),
				enabled: !!point
			};
		})
	});

	// set emphasized points
	useEffect(() => {
		const selectedPoints: any = [];
		if (selectedChartInfo && selectedChartInfo.fieldName) {
			if (selectedChartInfo.fieldName === 'data_sources') {
				sitesSideBar.forEach((p: any, idx: number) => {
					selectedChartInfo.valueColors.forEach((s: any) => {
						if (
							mpDataSources[idx].isSuccess &&
							mpDataSources[idx].data &&
							(mpDataSources[idx].data as [])
								.map((data: any) => {
									return data.type;
								})
								.includes(
									s.value === 'CALC_FLOW_RATE'
										? DataSources.CALCULATED_FLOW_RATE
										: s.value === 'ACC_FLOW'
										? DataSources.ACCUMULATED_FLOW
										: s.value
								)
						) {
							selectedPoints.push({
								measurementPointId: p.id,
								color: s.color,
								radius: s.radius
							});
						}
					});
				});
			} else {
				(selectedChartInfo.is_valve
					? sitesSideBar.filter((p: any) => p.valve)
					: selectedChartInfo.fieldName === 'is_active'
					? sitesSideBar.filter((p: any) => p.data_sources.length > 0)
					: sitesSideBar
				).forEach((p: any) => {
					selectedChartInfo.valueColors.forEach((s: any) => {
						if (selectedChartInfo.fieldName === 'is_active') {
							const checkDataSource = p.data_sources.find(
								data_source => data_source[selectedChartInfo.fieldName!] === s.value
							);
							if (checkDataSource) {
								selectedPoints.push({
									measurementPointId: p.id,
									color: s.color,
									radius: s.radius
								});
							}
						}
						if (selectedChartInfo.fieldName === 'is_active_sites') {
							if (p.is_active === s.value) {
								selectedPoints.push({
									measurementPointId: p.id,
									color: s.color,
									radius: s.radius
								});
							}
						}
						if (
							selectedChartInfo.is_valve
								? p['valve'][selectedChartInfo.fieldName!] === s.value
								: p[selectedChartInfo.fieldName!] === s.value
						) {
							selectedPoints.push({
								measurementPointId: p.id,
								color: s.color,
								radius: s.radius
							});
						}
					});
				});
			}
			setEmphasizedPoints(selectedPoints);
		} else {
			setEmphasizedPoints(undefined);
		}
	}, [selectedChartInfo]);

	useEffect(() => {
		if (selectedMeasurementPoint) {
			onChartSelect && onChartSelect(undefined);
			setEmphasizedPoints([]);
			if (lastOpenedPopup) {
				lastOpenedPopup?.closePopup();
			}
		}
	}, [selectedMeasurementPoint]);

	useEffect(() => {
		if (networks && networks.length && selectedNetwork)
			setNetworkToShow(networks.find((n: any) => n.id === selectedNetwork));
	}, [networks, selectedNetwork]);

	useEffect(() => {
		if (selectedZone) setZonesToShow(zones?.filter((zone: any) => zone.id === selectedZone));
		else setZonesToShow(zones);
	}, [zones, selectedZone]);

	useEffect(() => {
		if (selectedMeasurementPoint) {
			onMeasurementPointClick && onMeasurementPointClick(selectedMeasurementPoint);
			onChartSelect && onChartSelect(undefined);
			setEmphasizedPoints([]);
			if (lastOpenedPopup) {
				lastOpenedPopup?.closePopup();
			}
		} else onMeasurementPointClose && onMeasurementPointClose();
	}, [selectedMeasurementPoint, sitesSideBar]);

	// set network gis layers for selected network
	useEffect(() => {
		if (networkGisLayers && networkGisLayers.length) {
			setNetworkPipesGisLayers(networkGisLayers.filter((l: any) => l.type === GISLayerType.PIPES));
			setNetworkJunctionsGisLayers(networkGisLayers.filter((l: any) => l.type === GISLayerType.VALVES));
		}
	}, [networkGisLayers]);

	// set zone/zones gis layers for selected zone or network
	useEffect(() => {
		if (zonesToShow && zonesToShow.length > 0) {
			if (zonesGisLayers && zonesGisLayers.every(zone => zone.isSuccess)) {
				const gisLayers = configureZonesGisLayers(
					zonesToShow,
					zonesGisLayers.map(zone => {
						return zone.data;
					})
				);
				gisLayers && setZonePipesGisLayers(gisLayers.filter((l: any) => l.type === GISLayerType.PIPES));
				gisLayers && setZoneJunctionsGisLayers(gisLayers.filter((l: any) => l.type === GISLayerType.VALVES));
				gisLayers && setZoneBoundaryGisLayers(gisLayers.filter((l: any) => l.type === GISLayerType.BOUNDARY));
			}
		}
	}, [zonesToShow, zonesGisLayers && zonesGisLayers.every(zone => zone.isSuccess)]);

	const configureZonesGisLayers = (zones: any[], zonesGisLayers: any[]) => {
		return zones.reduce((agg: any, zone: any, idx: number) => {
			if (zonesGisLayers[idx] && zonesGisLayers[idx].length) {
				let layers = zonesGisLayers[idx];
				layers = layers.map((l: any) => {
					l.zoneName = i18n.language === 'en' ? zone.name_en : zone?.name_ar || zone.name_en;
					return l;
				});
				return [...agg, ...layers];
			} else return agg;
		}, []);
	};

	useEffect(() => {
		let location: LatLngExpression;
		if (pointMarker) {
			setViewProperties({ location: pointMarker, zoom: 13 });
		} else if (selectedMeasurementPoint && sitesSideBar) {
			const measurementPoint = sitesSideBar.find((point: any) => point?.id === selectedMeasurementPoint);
			location = [measurementPoint.latitude, measurementPoint.longitude];
			setViewProperties({ location, zoom: 18 });
		} else if (selectedZone) {
			const zone = zones?.find((z: any) => z?.id === selectedZone);
			if (zone) {
				const location = [zone.latitude, zone.longitude];
				setViewProperties({ location, zoom: 15 });
			}
		} else if (networkToShow) {
			location = [networkToShow.latitude, networkToShow.longitude];
			setViewProperties({ location, zoom: 13 });
		}
	}, [networkToShow, selectedZone, selectedMeasurementPoint, pointMarker, zones, networks, sitesSideBar]);

	useEffect(() => {
		mapInstance && mapInstance.setView(viewProperties.location, viewProperties.zoom, { animate: true });
	}, [viewProperties.location, viewProperties.zoom]);

	const renderSelectedMeasurementPoints = (point: any, selectedsitesSideBar?: any[], radiusAdded?: number) => {
		let radius = radiusAdded
			? circleMarkerRadius + radiusAdded
			: point.is_valve
			? circleMarkerRadius + 12
			: circleMarkerRadius + 7;
		let selectedColor = colors.GRAPH_PURPLE;
		if (selectedsitesSideBar && selectedsitesSideBar.length) {
			return selectedsitesSideBar.map((mp: any, idx: number) => {
				//NOTE: change this if needed to commercial site
				if (mp.measurementPointId === point.id && point.type !== Zones.COMMERCIAL_METER) {
					selectedColor = mp.color || colors.GRAPH_PURPLE;
					radius = mp.radius || 17;
					return (
						<CircleMarker
							key={idx}
							center={[point.latitude, point.longitude]}
							radius={radius}
							pathOptions={{
								// color: point.is_active ? selectedColor : colors.GREY,
								color: selectedColor,

								fillOpacity: 0
							}}
							eventHandlers={{
								click: () => onMeasurementPointClick && onMeasurementPointClick(point.id),
								popupclose: () => onMeasurementPointClose && onMeasurementPointClose()
							}}
						>
							<Popup minWidth={320}>
								<MPInfoNew measurementPoint={point} />
							</Popup>
						</CircleMarker>
					);
				}
			});
		}
		return null;
	};

	const desktopView = useMedia(configProxy.defaultExpandScreenSize);

	return xxl === undefined ? null : (
		<MapContainer
			center={viewProperties.location}
			zoom={viewProperties.zoom}
			doubleClickZoom
			scrollWheelZoom={desktopView}
			style={style || { height: '100vh', width: xxl ? '88.7vw' : '100vw', zIndex: 50, position: 'fixed' }}
			zoomControl={false}
		>
			<ZoomControl position={i18n.language === 'en' ? 'topleft' : 'topright'} />
			{showGisToggler ? (
				<div className={`leaflet-top ${i18n.language === 'en' ? 'leaflet-right' : 'leaflet-left'}`}>
					<GisToggler
						alwaysVisible={location === AppRoutes.MONITORING}
						className="leaflet-control leaflet-bar"
						content={
							//TODO: Refactor this to a separate container
							<div className="d-flex flex-column" style={{ width: '15rem' }}>
								{networkPipesGisLayers?.length ? (
									<Checkbox
										className="mb-2 mx-0"
										style={{ fontSize: '0.9rem' }}
										checked={networkPipesGisLayersVisible}
										onChange={e => setNetworkPipesGisLayersVisible(e.target.checked)}
									>
										{t('NetworkPipes')}
									</Checkbox>
								) : null}
								{networkJunctionsGisLayers?.length ? (
									<Checkbox
										className="mb-2 mx-0"
										style={{ fontSize: '0.9rem' }}
										checked={networkJunctionsGisLayersVisible}
										onChange={e => setNetworkJunctionsGisLayersVisible(e.target.checked)}
									>
										{t('NetworkJunctions')}
									</Checkbox>
								) : null}
								{zonePipesGisLayers?.length ? (
									<Checkbox
										className="mb-2 mx-0"
										style={{ fontSize: '0.9rem' }}
										checked={zonePipesGisLayersVisible}
										onChange={e => setZonePipesGisLayersVisible(e.target.checked)}
									>
										{t('ZonePipes')}
									</Checkbox>
								) : null}
								{zoneJunctionsGisLayers?.length ? (
									<Checkbox
										className="mb-2 mx-0"
										style={{ fontSize: '0.9rem' }}
										checked={zoneJunctionsGisLayersVisible}
										onChange={e => setZoneJunctionsGisLayersVisible(e.target.checked)}
									>
										{t('ZoneJunctions')}
									</Checkbox>
								) : null}
								{zoneBoundaryGisLayers?.length ? (
									<Checkbox
										className="mb-2 mx-0"
										style={{ fontSize: '0.9rem' }}
										checked={zoneBoundaryGisLayersVisible}
										onChange={e => setZoneBoundaryGisLayersVisible(e.target.checked)}
									>
										{t('ZoneBoundaries')}
									</Checkbox>
								) : null}
								{zones?.length &&
								!selectedZoneQuery &&
								zones.find(zone => zone.type === Zones.COMMERCIAL_METER) ? (
									<Checkbox
										className="mb-2 mx-0"
										style={{ fontSize: '0.9rem' }}
										checked={commercialMeterGroupsVisible}
										onChange={e => setCommercialMeterGroupsVisible(e.target.checked)}
									>
										{t('CommercialMeterGroups')}
									</Checkbox>
								) : null}
								<Checkbox
									className="mb-2 mx-0"
									style={{ fontSize: '0.9rem' }}
									onChange={e => {
										setNetworkStiesVisible(e.target.checked);
										setValveStiesVisible(e.target.checked);
										setWaterStationSitesVisible(e.target.checked);
										setCommercialStiesVisible(e.target.checked);
									}}
									checked={
										networkStiesVisible &&
										valveStiesVisible &&
										waterStationSitesVisible &&
										commercialStiesVisible
									}
									indeterminate={
										!(
											networkStiesVisible &&
											valveStiesVisible &&
											waterStationSitesVisible &&
											commercialStiesVisible
										) &&
										(networkStiesVisible ||
											valveStiesVisible ||
											waterStationSitesVisible ||
											commercialStiesVisible)
									}
								>
									{t('Sites')}
								</Checkbox>

								<div style={{ marginLeft: 10 }} className="d-flex flex-column">
									{sitesSideBar?.length &&
									sitesSideBar.find((point: any) => point.type === Zones.COMMERCIAL_METER) ? (
										<Checkbox
											className="mb-2 mx-0"
											style={{ fontSize: '0.9rem' }}
											checked={commercialStiesVisible}
											onChange={e => setCommercialStiesVisible(e.target.checked)}
										>
											{t('CommercialSites')}
										</Checkbox>
									) : null}

									{sitesSideBar?.length &&
									sitesSideBar.find(
										(point: any) =>
											point.valve === null &&
											point.water_station === null &&
											point.type !== Zones.COMMERCIAL_METER
									) ? (
										<Checkbox
											className="mb-2 mx-0"
											style={{ fontSize: '0.9rem' }}
											checked={networkStiesVisible}
											onChange={e => setNetworkStiesVisible(e.target.checked)}
										>
											{t('NetworkPointsSites')}
										</Checkbox>
									) : null}
									{sitesSideBar?.length && sitesSideBar.find((point: any) => point.valve !== null) ? (
										<Checkbox
											className="mb-2 mx-0"
											style={{ fontSize: '0.9rem' }}
											checked={valveStiesVisible}
											onChange={e => setValveStiesVisible(e.target.checked)}
										>
											{t('ValveSites')}
										</Checkbox>
									) : null}
									{sitesSideBar?.length &&
									sitesSideBar.find((point: any) => point.water_station !== null) ? (
										<Checkbox
											className="mb-2 mx-0"
											style={{ fontSize: '0.9rem' }}
											checked={waterStationSitesVisible}
											onChange={e => setWaterStationSitesVisible(e.target.checked)}
										>
											{t('WaterStationSites')}
										</Checkbox>
									) : null}
								</div>
							</div>
						}
					/>
				</div>
			) : null}
			{networkPipesGisLayers && networkPipesGisLayers.length && networkPipesGisLayersVisible
				? networkPipesGisLayers.map((layer: any) => {
						return <GeoJSON key={layer.id} data={layer.geojson} />;
				  })
				: null}
			{networkJunctionsGisLayers && networkJunctionsGisLayers.length && networkJunctionsGisLayersVisible
				? networkJunctionsGisLayers.map((layer: any) => {
						return (
							<GeoJSON
								key={layer.id}
								data={layer.geojson}
								pointToLayer={(_, latlng) =>
									circleMarker(latlng, { radius: 2, color: colors.BLACK, fillColor: colors.BLACK })
								}
							/>
						);
				  })
				: null}
			{zonePipesGisLayers && zonePipesGisLayers.length && zonePipesGisLayersVisible
				? zonePipesGisLayers.map((layer: any) => {
						return <GeoJSON key={layer.id} data={layer.geojson} />;
				  })
				: null}
			{zoneJunctionsGisLayers && zoneJunctionsGisLayers.length && zoneJunctionsGisLayersVisible
				? zoneJunctionsGisLayers.map((layer: any) => {
						return (
							<GeoJSON
								key={layer.id}
								data={layer.geojson}
								pointToLayer={(_, latlng) =>
									circleMarker(latlng, { radius: 2, color: colors.BLACK, fillColor: colors.BLACK })
								}
							/>
						);
				  })
				: null}
			{zoneBoundaryGisLayers && zoneBoundaryGisLayers.length && zoneBoundaryGisLayersVisible
				? zoneBoundaryGisLayers.map((layer: any) => {
						return (
							<GeoJSON
								key={layer.id}
								data={layer.geojson}
								pathOptions={{ color: colors.PURPLE2 }}
								pointToLayer={(_, latlng) =>
									circleMarker(latlng, { radius: 2, color: colors.BLACK, fillColor: colors.BLACK })
								}
								eventHandlers={{
									mouseover: e => {
										const popup = L.popup({
											minWidth: 320
										}).setContent(layer.zoneName);
										e.target.bindPopup(popup).openPopup(e.latlng);

										if (lastOpenedPopup) {
											lastOpenedPopup?.closePopup();
										}
									}
								}}
							>
								<Popup minWidth={320}>{layer.zoneName}</Popup>
							</GeoJSON>
						);
				  })
				: null}
			{zones?.length && !selectedZoneQuery && commercialMeterGroupsVisible
				? zones
						.filter(zone => zone.type === Zones.COMMERCIAL_METER)
						.map((zone: any) => {
							return (
								<div key={zone.id}>
									<Marker
										position={[zone.latitude, zone.longitude]}
										icon={commercialCommercialMeterGroupIcon(
											zone.is_active,
											commercialMeterIconSize
										)}
										eventHandlers={{
											mouseover: e => {
												const popup = L.popup({
													minWidth: 320
												}).setContent(zone.name_en);

												e.target.bindPopup(popup).openPopup(e.latlng);
											}
										}}
									/>
								</div>
							);
						})
				: null}
			{sitesSideBar?.length
				? sitesSideBar.map((point: any) => {
						return point.valve !== null && point.valve !== undefined && valveStiesVisible ? (
							<div key={point.id}>
								<Marker
									position={[point.latitude, point.longitude]}
									icon={valveIcon(point.valve.operation_status, valveIconSize)}
									eventHandlers={{
										click: () => onMeasurementPointClick && onMeasurementPointClick(point.id),
										popupclose: () => onMeasurementPointClose && onMeasurementPointClose(),
										mouseover: e => onMarkerHover(e.target)
									}}
								>
									<Popup minWidth={320}>
										<MPInfoNew measurementPoint={point} />
									</Popup>
								</Marker>
								{renderSelectedMeasurementPoints(
									point,
									selectedMeasurementPoint
										? [{ measurementPointId: selectedMeasurementPoint }]
										: emphasizedPoints && emphasizedPoints.length
										? emphasizedPoints
										: [],
									emphasizedPoints && emphasizedPoints.length ? 20 : undefined
								)}
							</div>
						) : point.water_station !== null &&
						  point.water_station !== undefined &&
						  waterStationSitesVisible ? (
							<div key={point.id}>
								<Marker
									position={[point.latitude, point.longitude]}
									icon={stationIcon(point.water_station.operational_status, stationIconSize)}
									eventHandlers={{
										click: () => onMeasurementPointClick && onMeasurementPointClick(point.id),
										popupclose: () => onMeasurementPointClose && onMeasurementPointClose(),
										mouseover: e => onMarkerHover(e.target)
									}}
								>
									<Popup minWidth={320}>
										<MPInfoNew measurementPoint={point} />
									</Popup>
								</Marker>
								{renderSelectedMeasurementPoints(
									point,
									selectedMeasurementPoint
										? [{ measurementPointId: selectedMeasurementPoint }]
										: emphasizedPoints && emphasizedPoints.length
										? emphasizedPoints
										: [],
									emphasizedPoints && emphasizedPoints.length ? 20 : undefined
								)}
							</div>
						) : point.type === Zones.COMMERCIAL_METER && commercialStiesVisible ? (
							<div key={point.id}>
								<Marker
									position={[point.latitude, point.longitude]}
									icon={commercialSiteIcon(point.is_active, commercialSiteIconSize)}
									eventHandlers={{
										mouseover: e => {
											const popup = L.popup({
												minWidth: 320
											}).setContent(point.name_en);
											e.target.bindPopup(popup).openPopup(e.latlng);

											if (lastOpenedPopup) {
												lastOpenedPopup?.closePopup();
											}
										}
									}}
								>
									<Popup minWidth={320}>
										<MPInfoNew measurementPoint={point} />
									</Popup>
								</Marker>
								{renderSelectedMeasurementPoints(
									point,
									selectedMeasurementPoint
										? [{ measurementPointId: selectedMeasurementPoint }]
										: emphasizedPoints && emphasizedPoints.length
										? emphasizedPoints
										: [],
									emphasizedPoints && emphasizedPoints.length ? 20 : undefined
								)}
							</div>
						) : (point.valve === null || point.valve === undefined) &&
						  (point.water_station === null || point.water_station === undefined) &&
						  point.type !== Zones.COMMERCIAL_METER &&
						  networkStiesVisible ? (
							<div key={point.id}>
								<CircleMarker
									center={[point.latitude, point.longitude]}
									radius={circleMarkerRadius}
									pathOptions={{
										color: point.is_active ? colors.HEALTHY : colors.GREY,
										fillColor: point.is_active ? colors.HEALTHY : colors.GREY,
										fillOpacity: 1
									}}
									eventHandlers={{
										click: () => onMeasurementPointClick && onMeasurementPointClick(point.id),
										popupclose: () => onMeasurementPointClose && onMeasurementPointClose(),
										mouseover: e => onMarkerHover(e.target)
									}}
								>
									<Popup minWidth={320} autoClose={false}>
										<MPInfoNew measurementPoint={point} />
									</Popup>
								</CircleMarker>
								{renderSelectedMeasurementPoints(
									point,
									selectedMeasurementPoint
										? [{ measurementPointId: selectedMeasurementPoint }]
										: emphasizedPoints && emphasizedPoints.length
										? emphasizedPoints
										: [],
									emphasizedPoints && emphasizedPoints.length ? 20 : undefined
								)}
							</div>
						) : null;
				  })
				: null}
			{pointMarker ? <Marker icon={markerIcon} position={pointMarker} /> : null}

			<MapInstance />
			<TileLayer attribution={configProxy.map_attribution} url={configProxy.map_tile_url} />
		</MapContainer>
	);
};
