import { Button, Col, Form, FormProps, Input, Row, Select, Spin, Tooltip } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../hooks/use-query';
import userManagementService from '../../services/user-management.service';
import { errorHandler } from '../../utils/error-handler';
import { notifySuccess } from '../../utils/notification-messages';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { UserRoles } from '../../types';
import { upperSnakeToCapitalized } from '../../utils';
import { NavigationContext } from '../../contexts/navigation.context';
import { RiDeleteBinLine } from 'react-icons/ri';
import { colors } from '../../constants/colors';
import { AiOutlinePlus } from 'react-icons/ai';
import { AuthenticationContext } from '../../contexts';
import { SubmitCanelButtons } from '../submit-cancel-buttons';

export const UserForm: React.FC<FormProps> = () => {
	const { t, i18n } = useTranslation();
	const userId = useQueryParam('userId');
	const [form] = Form.useForm();
	const navigationContext = useContext(NavigationContext);
	const { networks } = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user } = authContext;
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [submitting, setSubmitting] = useState<boolean>(false);

	// load user data
	const {
		data: userData,
		isLoading: loadingUserData,
		isFetching: fetchingUserData,
		refetch: refetchUserData
	} = useQuery({
		queryKey: ['user', userId],
		queryFn: () => userId && userManagementService.getUser(userId),
		enabled: !!userId
	});

	// get all foundations
	const { data: foundations } = useQuery({
		queryKey: ['foundations'],
		queryFn: () => userManagementService.getFoundations()
	});

	// fill form with data
	useEffect(() => {
		if (userData) {
			form.setFieldsValue({
				...userData
			});
		}
	}, [userData]);

	// generate non-duplicated options for roles
	const generateRoleOptions = fields => {
		const selectedRoles = {};
		for (const field of fields) {
			const selectedRole = form.getFieldValue(['roles', field.name, 'role']);
			if (selectedRole) {
				selectedRoles[selectedRole] = true;
			}
		}
		const options: any = [];
		if (user?.user_data.is_superAdmin) {
			options.push({
				value: UserRoles.ADMIN,
				label: upperSnakeToCapitalized(UserRoles.ADMIN)
			});
		}
		options.push(
			{
				value: UserRoles.OPERATOR,
				label: upperSnakeToCapitalized(UserRoles.OPERATOR)
			},
			{
				value: UserRoles.ANALYST,
				label: upperSnakeToCapitalized(UserRoles.ANALYST)
			}
		);

		// Remove selected roles from options
		return options.filter(option => !selectedRoles[option.value]);
	};

	// generate non-duplicated options for scopes
	const generateScopesOptions = (fields, currentFieldName) => {
		const selectedScopes = {};
		for (const field of fields) {
			if (field.name !== currentFieldName) {
				const selectedScopesField = form.getFieldValue(['roles', field.name, 'scopes']);
				if (selectedScopesField && selectedScopesField.length > 0) {
					selectedScopesField.forEach(scope => {
						selectedScopes[scope] = true;
					});
				}
			}
		}
		const options = networks?.map((network: any) => ({
			value: network.id,
			label: i18n.language === 'en' ? network.name_en : network?.name_ar || network.name_en
		}));

		// Remove selected scopes from options
		return options && options.filter(option => !selectedScopes[option.value]);
	};

	// generate customscopes options
	const generateCustomScopesOptions = (fields, currentFieldName) => {
		const selectedScopes = {};
		for (const field of fields) {
			if (field.name !== currentFieldName) {
				const selectedScopesField = form.getFieldValue(['roles', field.name, 'scopes']);
				if (selectedScopesField && selectedScopesField.length > 0) {
					selectedScopesField.forEach(scope => {
						selectedScopes[scope] = true;
					});
				}
			}
		}
		const options = userData?.roles
			?.find(e => e.role === UserRoles.CUSTOM)
			.scopes?.map((scope: any) => ({
				value: scope,
				label: networks?.find(network => network.id === scope)?.name_en
			}));

		// Remove selected scopes from options
		return options && options.filter(option => !selectedScopes[option.value]);
	};

	const onFinish = async (values: any) => {
		setSubmitting(true);
		Object.keys(values).forEach(key => {
			if (values[key] === '' || values[key] === undefined || values[key] === null) {
				delete values[key];
			}
		});
		if (userId) {
			try {
				const resp = await userManagementService.editUser(userId, values);
				if (!resp) throw new Error(t('CouldntEditUser', { ns: 'validation' }));
				refetchUserData();
				queryClient.refetchQueries(['users']);
				notifySuccess(t('editedSuccessfully'));
				navigate(`${AppRoutes.ADMINISTRATION}?tabSelected=users`);
			} catch (e: any) {
				errorHandler(e);
			}
		} else {
			try {
				const resp = await userManagementService.createUser(values);
				if (!resp) throw new Error(t('CouldntAddUser', { ns: 'validation' }));
				queryClient.refetchQueries(['users']);
				notifySuccess(t('addedSuccessfully'));
				navigate(`${AppRoutes.ADMINISTRATION}?tabSelected=users`);
			} catch (e: any) {
				errorHandler(e);
			}
		}
		setSubmitting(false);
	};
	const handleCancel = () => {
		navigate(`${AppRoutes.ADMINISTRATION}?tabSelected=users`);
	};

	return (
		<div
			style={{
				paddingLeft: i18n.language === 'en' ? '1rem' : '',
				paddingRight: i18n.language === 'en' ? '' : '1rem',
				paddingTop: '2rem'
			}}
		>
			<Row gutter={24} className="justify-content-center">
				<Col xs={24} md={14} lg={10}>
					<Row>
						<h3 className="mb-3">{t('User')}</h3>
					</Row>
					<Row>
						<Col xs={24}>
							<Spin
								spinning={
									submitting || !networks || !foundations || (loadingUserData && fetchingUserData)
								}
							>
								<Form form={form} layout="vertical" onFinish={onFinish}>
									<Form.Item
										name="username"
										label={t('UserName')}
										rules={[
											{ required: true, message: t('PleaseAddUserName', { ns: 'validation' }) }
										]}
									>
										<Input type="text" placeholder={t('UserName')} />
									</Form.Item>

									<Form.Item
										name="password"
										label={t('Password')}
										rules={[
											{
												required: true,
												message: t('PleaseAddUserPassword', { ns: 'validation' })
											}
										]}
									>
										<Input.Password placeholder={t('Password')} />
									</Form.Item>

									<Form.Item
										name="fullname"
										label={t('FullName')}
										rules={[
											{
												required: true,
												message: t('PleaseAddUserFullName', { ns: 'validation' })
											}
										]}
									>
										<Input type="text" placeholder={t('userFullName')} />
									</Form.Item>

									<Form.Item name="nid" label={t('NationalID')}>
										<Input type="number" placeholder={t('userNationalId')} />
									</Form.Item>

									<Form.Item
										name="email"
										label={t('Email')}
										rules={[
											{ required: true, message: t('PleaseAddUserEmail', { ns: 'validation' }) },
											{ type: 'email', message: t('notAValidEmail', { ns: 'validation' }) }
										]}
									>
										<Input type="email" placeholder={t('userEmail')} />
									</Form.Item>

									<Form.Item
										name="foundation"
										label={t('Foundation')}
										rules={[
											{
												required: true,
												message: t('PleaseAddUserFoundation', { ns: 'validation' })
											}
										]}
									>
										<Select
											placeholder={t('selectUserFoundation')}
											options={foundations?.map(foundation => ({
												label: foundation.name,
												value: foundation.id
											}))}
										/>
									</Form.Item>

									<Form.Item label={t('Roles')}>
										<Form.List name="roles">
											{(fields, { add, remove }) => {
												// add a role option by default
												if (fields.length === 0) {
													add();
												}
												return (
													<div>
														{fields?.map((field, idx) => (
															<div key={field.key}>
																<Row className="w-100" key={idx}>
																	<Col xs={8}>
																		<Form.Item
																			name={[field.name, 'role']}
																			rules={[
																				{
																					required: true,
																					message: t('PleaseSelectRole', {
																						ns: 'validation'
																					})
																				}
																			]}
																		>
																			<Select
																				placeholder={t('UserRole')}
																				options={
																					form.getFieldValue([
																						'roles',
																						field.name,
																						'role'
																					]) === UserRoles.CUSTOM
																						? [
																								{
																									label: UserRoles.CUSTOM,
																									value: UserRoles.CUSTOM
																								}
																						  ]
																						: generateRoleOptions(fields)
																				}
																				disabled={
																					form.getFieldValue([
																						'roles',
																						field.name,
																						'role'
																					]) === 'Custom'
																						? true
																						: false
																				}
																			/>
																		</Form.Item>
																	</Col>
																	<Col
																		xs={{ span: 15, offset: 1 }}
																		className={
																			'mb-2 d-flex justify-content-between'
																		}
																	>
																		<Form.Item
																			name={[field.name, 'scopes']}
																			rules={[
																				{
																					required: true,
																					message: t('PleaseSelectScopes', {
																						ns: 'validation'
																					})
																				}
																			]}
																			style={{ width: '100%' }}
																		>
																			<Select
																				mode="multiple"
																				allowClear
																				placeholder={t('Networks')}
																				options={
																					form.getFieldValue([
																						'roles',
																						field.name,
																						'role'
																					]) === UserRoles.CUSTOM
																						? generateCustomScopesOptions(
																								fields,
																								field.name
																						  )
																						: generateScopesOptions(
																								fields,
																								field.name
																						  )
																				}
																			/>
																		</Form.Item>
																		<Tooltip title={t('Remove', { ns: 'common' })}>
																			<Button
																				onClick={() => {
																					remove(field.name);
																				}}
																				type="link"
																				icon={
																					<RiDeleteBinLine
																						size={20}
																						color={colors.ERROR}
																					/>
																				}
																				disabled={idx === 0}
																			/>
																		</Tooltip>
																	</Col>
																</Row>
															</div>
														))}

														{
															//remove add button when selected all role options
															(user?.user_data.is_superAdmin &&
																fields.length ===
																	(userData &&
																	userData?.roles?.some(
																		item => item.role === 'Custom'
																	)
																		? 4
																		: 3)) ||
															(!user?.user_data.is_superAdmin &&
																fields.length ===
																	(userData &&
																	userData?.roles?.some(
																		item => item.role === 'Custom'
																	)
																		? 3
																		: 2)) ? null : (
																<Button
																	onClick={() => {
																		// Check if all fields in roles are filled
																		try {
																			for (const field of fields) {
																				if (
																					!form.getFieldValue([
																						'roles',
																						field.name,
																						'role'
																					]) ||
																					!form.getFieldValue([
																						'roles',
																						field.name,
																						'scopes'
																					]) ||
																					form.getFieldValue([
																						'roles',
																						field.name,
																						'scopes'
																					]).length === 0
																				) {
																					throw new Error(
																						'You should fill the added roles first!'
																					);
																				}
																			}
																			add();
																		} catch (e: any) {
																			errorHandler(e);
																		}
																	}}
																	type="link"
																	icon={<AiOutlinePlus />}
																>
																	{t('AddAdditionalRole')}
																</Button>
															)
														}
													</div>
												);
											}}
										</Form.List>
									</Form.Item>

									<Form.Item className="d-flex justify-content-end">
										<SubmitCanelButtons handleCancel={handleCancel} handleSubmit={form.submit} />
									</Form.Item>
								</Form>
							</Spin>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};
