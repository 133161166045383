const config: { [k: string]: { [k: string]: any } } = {
	development: {
		app_name: 'aguamenti',
		default_page_size: 10,
		defaultExpandScreenSize: 'xl',
		base_url: 'https://aguamenti-qa-api.wai-techs.com',
		api_url: 'https://aguamenti-qa-api.wai-techs.com',
		// Remove the chatbot_websocket_url from here to disable the chatbots
		chatbot_websocket_url: 'wss://20.228.206.54:8000/',
		chatbot_botpress_url: 'https://aguamenti-qa-ai.wai-techs.com',
		chatbot_botpress_id: 'wai_wisdom',
		map_attribution: "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
		map_tile_url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
	},
	testing: {
		app_name: 'aguamenti',
		default_page_size: 10,
		defaultExpandScreenSize: 'xl',
		base_url: 'http://52.170.7.13:8000/api',
		api_url: 'http://52.170.7.13:8000/api',
		// Remove the chatbot_websocket_url from here to disable the chatbots
		chatbot_websocket_url: 'wss://20.228.206.54:8000/',
		chatbot_botpress_url: 'https://aguamenti-qa-ai.wai-techs.com',
		chatbot_botpress_id: 'wai_wisdom',
		map_attribution: "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
		map_tile_url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
	},
	production: {
		app_name: 'aguamenti',
		default_page_size: 10,
		defaultExpandScreenSize: 'xl',
		base_url: 'http://52.170.7.13:8000/api',
		api_url: 'http://52.170.7.13:8000/api',
		// Remove the chatbot_websocket_url from here to disable the chatbots
		chatbot_websocket_url: 'wss://20.228.206.54:8000/',
		chatbot_botpress_url: 'https://aguamenti-qa-ai.wai-techs.com',
		chatbot_botpress_id: 'wai_wisdom',
		map_attribution: "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
		map_tile_url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
	},
	production_eshara: {
		app_name: 'aguamenti',
		default_page_size: 10,
		defaultExpandScreenSize: 'xl',
		base_url: 'http://172.22.11.59:8000',
		api_url: 'http://172.22.11.59:8000',
		map_attribution: "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
		map_tile_url: 'http://172.22.11.59:3500/tile/{z}/{x}/{y}.png'
	}
};

export default config;
