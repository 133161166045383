import { Empty } from 'antd';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineFilePdf } from 'react-icons/ai';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { WaiAnalysisWidgetContainer, WaiAnalysisWidgetTitle } from '../wai-analysis-widget';

const DocumentCardElement = styled.span`
	font-weight: 600;
	font-size: 1.2rem;
	cursor: pointer;
	display: flex;
	align-items: center;
`;

export const DocumentsCard: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		documents: { name: string; file: string }[];
		onDocumentClick: (url: string) => void;
	}
> = ({ documents, onDocumentClick }) => {
	const { t } = useTranslation();

	return (
		<WaiAnalysisWidgetContainer>
			<WaiAnalysisWidgetTitle className="text-center">{t('Documents')}</WaiAnalysisWidgetTitle>
			{documents && documents.length ? (
				documents.map(d => (
					<DocumentCardElement
						key={d.file}
						onClick={() => onDocumentClick(d.file)}
						style={{ marginBottom: '10px' }}
					>
						<div style={{ marginInline: '5px' }}>
							<AiOutlineFilePdf color={colors.RED} size={30} />
						</div>
						<div>{d.name}</div>
					</DocumentCardElement>
				))
			) : (
				<Empty description={t('NoData')} />
			)}
		</WaiAnalysisWidgetContainer>
	);
};
