import { Layout } from 'antd';
import React from 'react';

const { Content } = Layout;

export const AuthLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<>
			<Content style={{ paddingTop: '4.5rem', paddingLeft: ' 5.4rem', paddingRight: '2rem', overflow: 'hidden' }}>
				{children}
			</Content>
		</>
	);
};
