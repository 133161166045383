import { HTMLAttributes, useContext } from 'react';
import companyLogo from '../assets/img/company-logo.png';
import { WaiLogoImg, WaiLogoSubText, WaiLogoText } from '../components';
import { BrandingLabelContext } from '../contexts/branding-label';

export const WaiLogo: React.FC<
	HTMLAttributes<HTMLDivElement> & { direction?: 'horizontal' | 'vertical'; size?: 'small' | 'large' }
> = ({ direction, size }) => {
	const labelContext = useContext(BrandingLabelContext);
	const { label } = labelContext;
	return (
		<div
			className={
				direction === 'vertical'
					? 'd-flex flex-column align-items-center justify-content-center'
					: 'd-flex align-items-end justify-content-center mb-4 mb-xxxl-5'
			}
		>
			<WaiLogoImg src={companyLogo} alt="Wai Logo" label={label} size={size} direction={direction} />
			<div className={direction === 'vertical' ? 'd-flex flex-column align-items-center' : 'd-flex flex-column'}>
				<WaiLogoText label={label} size={size}>
					{label.logoText}
				</WaiLogoText>
				<WaiLogoSubText label={label} size={size}>
					{label.logoSubText}
				</WaiLogoSubText>
			</div>
		</div>
	);
};
