import { Popover } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const WaiPopOver = styled(Popover)`
	cursor: pointer;
	border: 1px solid ${colors.CYAN_WARM};
	background-color: ${colors.WHITE_RGBA3};
	padding: 3px;
	border-radius: 5px;
	z-index: 1000;
	transform: translate(0, 0);
`;
