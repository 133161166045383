import { Modal, ModalProps } from 'antd';
import i18n from 'i18next';

interface CustomModalProps extends ModalProps {
	content?: any;
}

export const modalConfirm = (other: CustomModalProps) => {
	Modal.confirm({
		title: i18n.t('AreYouSure', { ns: 'common' }),
		type: 'confirm',
		okText: i18n.t('Yes', { ns: 'common' }),
		cancelText: i18n.t('No', { ns: 'common' }),
		maskClosable: true,
		centered: true,
		...other
	});
};
