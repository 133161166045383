import { Col, DatePicker, Form, Modal, Radio, Row, TableProps } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import chartsService from '../../services/charts.service';
import { downloadFilePDF } from '../../utils';
import { downloadFile } from '../../utils/download-file';
import { errorHandler } from '../../utils/error-handler';
import { jsonToCSV } from '../../utils/jsonToCSV';

export const ExportTableData: React.FC<
	TableProps<any> & {
		id: string;
		downloadTitle: string;
		initialDate: any;
		setVisibleExportTable: any;
		visibleExportTable: any;
		sourceName: any;
	}
> = ({ id, downloadTitle, initialDate, setVisibleExportTable, visibleExportTable, sourceName }) => {
	const { t } = useTranslation();
	const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
	const [noData, setNoData] = useState<boolean>(false);
	const [form] = Form.useForm();

	const handleSubmit = async (values: any) => {
		try {
			setLoadingDownload(true);
			let dataObjects: any;

			// NOTE: for now this table is for export vitual commercial meter group monthly
			const resp =
				id &&
				(await chartsService.getCommercialMeterGroupMonthlyData(id, {
					date: dayjs(values.date).format('YYYY-MM-DD')
				}));
			dataObjects = resp?.data;

			// download PDF
			if (values.exportFile) {
				if (dataObjects) {
					downloadFilePDF({
						data: dataObjects,
						fileName: sourceName ? `${downloadTitle}_${sourceName}` : downloadTitle || 'Table',
						lang: 'ar',
						t: t
					});
					setVisibleExportTable(undefined);
				} else {
					setNoData(true);
				}
			}
			// download CSV
			else {
				let data = jsonToCSV(dataObjects);
				if (data) {
					downloadFile({
						data: data,
						fileName: sourceName ? `${downloadTitle}_${sourceName}` : downloadTitle || 'Table',
						fileType: 'text/csv'
					});
					setVisibleExportTable(undefined);
				} else {
					setNoData(true);
				}
			}
			setLoadingDownload(false);
		} catch (e: any) {
			errorHandler(e);
		}
	};
	useEffect(() => {
		if (initialDate) {
			form.setFieldsValue({
				date: dayjs(dayjs(initialDate).format('YYYY-MM-DD'))
			});
		}
	}, [initialDate]);

	return (
		<Modal
			open={visibleExportTable}
			title={t('Export')}
			okText={t('Download')}
			onCancel={() => setVisibleExportTable(undefined)}
			onOk={form.submit}
			confirmLoading={loadingDownload}
			forceRender
			centered
		>
			<Form layout="vertical" form={form} onFinish={handleSubmit} style={{ width: '100%' }}>
				<Row>
					<Col xs={24}>
						<Form.Item
							label={t('SetectDate')}
							name="date"
							rules={[{ required: true, message: t('AddDate', { ns: 'validation' }) }]}
							initialValue={initialDate ? dayjs(dayjs(initialDate).format('YYYY-MM-DD')) : undefined}
						>
							<DatePicker onChange={() => setNoData(false)} style={{ width: '100%' }} picker={'month'} />
						</Form.Item>
					</Col>
					<Col xs={24}>
						<Form.Item label={t('pdfOrCsv')} initialValue={true} name="exportFile">
							<Radio.Group>
								<Radio value={true}>{t('PDF')}</Radio>
								<Radio value={false}>{t('CSV')}</Radio>
							</Radio.Group>
						</Form.Item>
					</Col>
					{noData ? <p className="text-danger"> {t('noDataOnThisDate')}</p> : null}
				</Row>
			</Form>
		</Modal>
	);
};
