import { Slider, Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

const _ControlButton = styled.button`
	color: inherit;
	background-color: inherit;
	font-weight: 700;
	width: 44px;
	height: 44px;
	border: none;
	outline: none;
	transition: 0.3s all ease-in-out;
	border-radius: 4px;
	&:enabled:hover {
		background-color: ${colors.LIGHT_GREY3};
	}
	&:focus {
		outline: none !important;
		border: none;
	}
	&:disabled {
		color: ${colors.BLACK2_RGBA3};
	}
`;

export const ControlButton = ({ tooltip, ...rest }: any) =>
	rest.disabled ? (
		<_ControlButton {...rest} />
	) : (
		<Tooltip title={rest.disabled ? null : tooltip}>
			<_ControlButton {...rest} />
		</Tooltip>
	);

export const PDFViewerControls = styled.span.attrs({ className: 'viewer-controls' })<{
	showOnHover: boolean;
	isFullScreen: boolean;
}>`
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: ${colors.WHITE};
	font-size: 20px;
	line-height: 40px;
	color: ${colors.DARK_GREY2};
	// box-shadow: 0 30px 40px 0 ${colors.DARK_BLUE_RGBA2};
	border-radius: 4px;
	opacity: ${({ showOnHover }) => (showOnHover ? 0 : 1)};
	transition: 0.5s opacity ease-in-out;
	/* border: 1px solid ${colors.LIGHT_GREY4}; */
	position:  ${({ isFullScreen }) => (isFullScreen ? 'fixed' : 'initial')};
	bottom: ${({ isFullScreen }) => (isFullScreen ? 0 : 'initial')};
	left: ${({ isFullScreen }) => (isFullScreen ? '50%' : 'initial')}
	transform: ${({ isFullScreen }) => (isFullScreen ? 'translateX(-50%)' : 'initial')};
	&:hover {
		opacity: 1;
	}
`;

export const DocumentContainer = styled.div`
	margin: 0 auto;
	border: 1px solid ${colors.LIGHT_GREY5};
	background-color: ${colors.BLACK_RGBA3};
	width: 100%;
	height: 100%;
	text-align: center;
	overflow: auto;

	& .document div:hover + .viewer-controls {
		opacity: 1;
	}

	/* &::fullscreen .document:hover .viewer-controls {
		opacity: 1;
	} */

	&::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px ${colors.BLACK_RGBA3};
		width: 3px;
		height: 3px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${colors.DARK_GREY3};
		/* outline: 1px solid ${colors.DARK_GREY3}; */
		border-radius: 20%;
		/* opacity: 0.2; */
	}

	& .document {
		/* width: 100vw; */
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	& .pages canvas {
		max-width: 100%;
	}

	&:fullscreen {
		overflow: scroll !important;
	}
	&:-ms-fullscreen {
		overflow: scroll !important;
	}
	&:-webkit-full-screen {
		overflow: scroll !important;
	}
	&:-moz-full-screen {
		overflow: scroll !important;
	}
`;

export const PDFSlider = styled(Slider)`
	margin: 5px auto 0 auto;
	width: 90%;
	.ant-slider-rail {
		background-color: ${colors.BLACK_RGBA2};
	}

	.ant-slider-track,
	.ant-slider-disabled .ant-slider-track {
		background-color: ${colors.GRAPH_PURPLE} !important;
	}

	.ant-slider-handle {
		border: solid 2px ${colors.GRAPH_PURPLE};

		&:focus {
			background-color: ${colors.GRAPH_PURPLE};
		}
	}

	.ant-slider-dot {
		border: solid 2px ${colors.BLACK_RGBA3};
		&-active {
			border: solid 2px ${colors.GRAPH_PURPLE};
		}
	}

	&:hover {
		.ant-slider-rail {
			background-color: ${colors.BLACK_RGBA3};
		}

		.ant-slider-handle {
			border: solid 2px ${colors.LIGHT_GREEN};
		}
		.ant-slider-track {
			background-color: ${colors.LIGHT_GREEN};
		}
	}
`;
