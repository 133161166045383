import { AxiosRequestConfig } from 'axios';
import { waiApiClient } from './http-client';
import jwt_decode from 'jwt-decode';
export default class ResourceManager {
	async getResource<T>(url: string, options: AxiosRequestConfig | undefined = undefined): Promise<T | null> {
		let response = await waiApiClient.get(url, options);
		if (response.status === 500) return null;
		return response.data;
	}

	async postResource<T, L>(url: string, data: T): Promise<L | null> {
		let response = await waiApiClient.post(url, data);
		if (response.status === 500) return null;
		return response.data;
	}

	async putResource<T, L>(url: string, data: T): Promise<L | null> {
		let response = await waiApiClient.put(url, data);
		if (response.status === 500) return null;
		return response.data;
	}

	async patchResource<T, L>(url: string, data: T): Promise<L | null> {
		let response = await waiApiClient.patch(url, data);
		if (response.status === 500) return null;
		return response.data;
	}

	async deleteResource<L>(url: string): Promise<L | null> {
		let response = await waiApiClient.delete(url);
		if (response.status === 500) return null;
		return response.data;
	}
}
