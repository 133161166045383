import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { BsClockHistory } from 'react-icons/bs';
import { FcAlarmClock } from 'react-icons/fc';
import { MdOutlineVerifiedUser } from 'react-icons/md';
import { colors } from '../../constants/colors';
import { ValveMaintenanceReminder } from '../../types/valve';
import { upperSnakeToCapitalized } from '../../utils/upper-snake-to-capitalized';
import { WaiAnalysisWidgetContainer, WaiAnalysisWidgetTitle } from '../wai-analysis-widget';

const maintainenceStatusColorMapper = {
	[ValveMaintenanceReminder.NORMAL]: colors.HEALTHY,
	[ValveMaintenanceReminder.APPROACHING]: colors.WARNING,
	[ValveMaintenanceReminder.OVERDUE]: colors.ERROR
};

const maintenanceStatusIconMapper = {
	[ValveMaintenanceReminder.NORMAL]: <MdOutlineVerifiedUser size={60} />,
	[ValveMaintenanceReminder.APPROACHING]: <BsClockHistory size={60} />,
	[ValveMaintenanceReminder.OVERDUE]: <FcAlarmClock size={60} />
};

export const WaiMaintenanceStatus: React.FC<
	HTMLAttributes<HTMLDivElement> & { maintenanceReminder: ValveMaintenanceReminder }
> = ({ maintenanceReminder, ...restProps }) => {
	const { t } = useTranslation();

	return (
		<WaiAnalysisWidgetContainer {...restProps}>
			<WaiAnalysisWidgetTitle>{t('maintenanceStatus')}</WaiAnalysisWidgetTitle>
			<div
				className="d-flex flex-column align-items-center justify-content-center "
				style={{ color: maintainenceStatusColorMapper[maintenanceReminder] }}
			>
				{maintenanceStatusIconMapper[maintenanceReminder]}
				<h5 style={{ color: 'inherit', marginTop: '10px' }}>{upperSnakeToCapitalized(maintenanceReminder)}</h5>
			</div>
		</WaiAnalysisWidgetContainer>
	);
};
