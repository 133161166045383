import { IDataSourceRecord } from '../types';

export const transformDataSourcesToRecord = (dataSources: any): IDataSourceRecord[] => {
	return dataSources.map((dataSource: any) => {
		return {
			id: dataSource.id,
			name_en: dataSource.name_en,
			channel: dataSource.channel,
			stream_direction: dataSource.stream_direction,
			type: dataSource.type,
			log_unit: dataSource.log_unit,
			is_primary: dataSource.is_primary,
			is_active: dataSource.is_active,
			created_at: dataSource.created_at,
			zones: dataSource.zones,
			network: dataSource.network,
			measurement_point: dataSource.measurement_point
		};
	});
};
