import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';
import { GraphCard } from '../components';
import { colors } from '../constants/colors';
import { useTranslation } from 'react-i18next';

const COLORS = [colors.GRAPH_PURPLE, colors.GRAPH_GREEN, colors.GRAPH_BLUE, colors.WARNING];

const getBarchartOptions = (
	englishSelected,
	categories,
	series,
	tooltipData,
	devices,
	setSelectedDevice,
	colorsBar,
	noDataText,
	t
): ApexOptions => {
	return {
		chart: {
			type: 'bar',
			stacked: true,
			stackType: '100%'
		},
		xaxis: {
			categories: categories,
			tooltip: { enabled: false }
		},
		yaxis: {
			opposite: !englishSelected
		},
		plotOptions: {
			bar: {
				horizontal: false
			}
		},
		tooltip: {
			shared: true,
			intersect: false,
			custom(options) {
				const dataPointIndex = options.dataPointIndex;
				const tooltipDataXaxis = tooltipData[dataPointIndex];
				const category = categories[dataPointIndex];
				const chartColors = options.w.config.colors;
				const yAxis0 = series[0];
				const yAxis1 = series[1];
				const device = devices[dataPointIndex];
				setSelectedDevice(device);

				return (
					'<div style="display:flex;flex-direction:column; ">' +
					`<div style="margin-bottom:5px;background-color:${colors.LIGHT_GREY2}; padding:8px;5px;">` +
					`${category}` +
					'</div>' +
					'<div style=padding:10px;>' +
					`<div style="display:flex; align-items:center;margin-bottom:5px;" ><div style="background-color:${chartColors[0]}; width:12px;height:12px;border-radius:50%;margin-right:5px;"></div>
					${yAxis0.name}: ${yAxis0.data[dataPointIndex]} % </div>` +
					'<br/>' +
					`<div style="display:flex; align-items:center;margin-bottom:5px;" ><div style="background-color:${chartColors[1]}; width:12px;height:12px;border-radius:50%;margin-right:5px;"></div>
					  ${yAxis1.name}: ${yAxis1.data[dataPointIndex]} % </div>` +
					`<div style="display:flex;align-items:center;margin-top:30px;">

				${
					tooltipDataXaxis
						? `<span style='display:block;width:100%;border-top: 1px solid ${colors.INACTIVE}; padding-top: 5px; color: ${colors.INACTIVE}'>* ${tooltipDataXaxis} </span>
						`
						: ''
				}
					</div>` +
					'</div>' +
					'</div>'
				);
			}
		},
		legend: {
			position: 'bottom',
			horizontalAlign: 'center'
		},
		colors: colorsBar ?? COLORS,
		noData: { text: noDataText ? noDataText : t('NoDataFound') }
	};
};

export const WaiStackedBarChart: React.FC<any> = ({
	data,
	title,
	fieldName,
	height,
	graphHeight,
	colorsBar,
	hint,
	setSelectedDevice,
	noDataText,
	...restProps
}) => {
	const { t, i18n } = useTranslation();

	return (
		<GraphCard title={title} fieldName={fieldName} height={height || 20} hint={hint} {...restProps}>
			<div style={{ width: '100%' }}>
				<Chart
					type="bar"
					options={getBarchartOptions(
						i18n.language === 'en',
						data?.categories,
						data?.series,
						data?.tooltip,
						data?.devices,
						setSelectedDevice,
						colorsBar,
						noDataText,
						t
					)}
					series={data?.series}
					height={graphHeight || 300}
				/>
			</div>
		</GraphCard>
	);
};
