import { IAlertRecord } from '../types/alerts';

export const transformAlertsToRecord = (alerts: any, englishSelected: boolean): IAlertRecord[] => {
	return alerts.map((alert: any) => ({
		id: alert.id,
		name: alert.title_en,
		alertId: alert.alert_id,
		source: alert.source,
		priority: alert.priority,
		alert_type: alert.alert_type,
		affected_zones: englishSelected
			? alert.affected_zones.map((zone: any) => zone.name_en).join(', ')
			: alert.affected_zones.map((zone: any) => zone?.name_ar || zone.name_en).join(', '),
		affected_networks: englishSelected
			? alert.affected_networks.map((network: any) => network.name_en).join(', ')
			: alert.affected_networks.map((network: any) => network?.name_ar || network.name_e).join(', '),
		alert_status: alert.status,
		data_source: alert.abnormal_events[0]?.data_sources[0]?.id,
		measurementPointId: alert.abnormal_events[0]?.data_sources[0]?.measurement_point.id,
		dataSourceType: alert.abnormal_events[0]?.data_sources[0]?.type,
		alertStart: alert.first_event_date,
		alertEnd: alert.last_event_date,
		alertMsg: alert.title_en
	}));
};
