import styled from 'styled-components';
import { Select } from 'antd';

export const WaiSelector = styled(Select).attrs(() => ({
	dropdownStyle: {
		boxShadow: 'none',
		paddingBottom: '1rem',
		background: 'transparent',
		display: 'block',
		zIndex: 201
	}
}))<{ label: any }>`
	width: 100%;
	color: ${({ label }) => label.sidebarSelectorColor};
	font-weight: 600;
	&.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border: 2px solid ${({ label }) => label.sidebarSelectorColor};
		border-radius: 8px;
		width: 100%;
		border-radius: 15px;
		background-color: transparent;
	}
	.ant-select-selection-placeholder,
	.ant-select-arrow {
		color: ${({ label }) => label.sidebarSelectorColor};
	}
	.ant-select-clear {
		background-color: ${({ label }) => label.sidebarSelectorColor};
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.ant-select-dropdown-placement-topLeft {
		top: auto !important;
	}

	&.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
	&.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
		font-weight: 600;
	}
	&.ant-select-single.ant-select-open .ant-select-selection-item {
		color: ${({ label }) => label.sidebarSelectorColor};
	}
`;
