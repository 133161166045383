import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space, Spin, Tag } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { useQueryParam } from '../../hooks/use-query';
import monitoringService from '../../services/monitoring.service';
import { notifySuccess } from '../../utils/notification-messages';
import { upperSnakeToCapitalized } from '../../utils';
import { AiOutlinePlus } from 'react-icons/ai';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import userManagementService from '../../services/user-management.service';
import { SubmitCanelButtons } from '../submit-cancel-buttons';
import { modalConfirm } from '../modal-confirm';
import { GISLayerForm } from '../gis-layer-form';

export const NetworkForm: React.FC<any> = ({
	selectedLocation,
	setSelectedLocation,
	networkData,
	refetchNetwork,
	activatedNetwork,
	setActivePanel
}) => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { networks, refetchNetworks, errorHandler } = navigationContext;

	const [submitting, setSubmitting] = useState<boolean>(false);
	const [gisLayers, setGisLayers] = useState<any>([]);
	const [visibleGISModal, setVisibleGISModal] = useState<boolean>(false);
	const [isFormChanged, setIsFormChanged] = useState<boolean>(false);

	const networkId = useQueryParam('networkId');
	const [form] = Form.useForm();
	const [gisForm] = Form.useForm();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	// load foundations
	const { data: foundations, isLoading: loadingFoundations } = useQuery<any>({
		queryKey: ['foundations'],
		queryFn: () => userManagementService.getFoundations()
	});

	// get gis Layers when editing network
	const {
		data: gisLayersData,
		isLoading: loadingGisLayers,
		isFetching: fetchingGisLayers,
		refetch: refechGisLayers
	} = useQuery<any>({
		queryKey: ['gis-layers', networkId],
		queryFn: () => networkId && monitoringService.getNetworkGisLayers(networkId),
		enabled: !!networkId
	});

	// load network data in the form and map
	useEffect(() => {
		if (networkData && !(loadingGisLayers && fetchingGisLayers)) {
			resetFields();
		}
	}, [networkData, loadingGisLayers, fetchingGisLayers]);

	// // load network data in the form and map
	// useEffect(() => {
	// 	(async () => {
	// 		if (networkId && networks?.length > 0 && !loadingGisLayers) {
	// 			try {
	// 				const networkDataToEdit = networks.find((network: any) => network.id === networkId);

	// 				networkDataToEdit.gis_layers =
	// 					gisLayers &&
	// 					gisLayers.map(layer => {
	// 						return {
	// 							...layer,
	// 							geojson: JSON.stringify(layer.geojson)
	// 						};
	// 					});
	// 				setSelectedLocation({
	// 					latitude: networkDataToEdit.latitude,
	// 					longitude: networkDataToEdit.longitude
	// 				});
	// 				form.setFieldsValue({
	// 					...networkDataToEdit
	// 				});
	// 			} catch (e: any) {
	// 				errorHandler(e);
	// 			}
	// 		}
	// 	})();
	// }, [networkId, networks, loadingGisLayers, gisLayers]);

	// track changing GIS layers from the default data saved
	useEffect(() => {
		if (networkData && networkId && !(loadingGisLayers && fetchingGisLayers)) {
			if (JSON.stringify(gisLayersData) !== JSON.stringify(gisLayers)) {
				setIsFormChanged(true);
			} else {
				setIsFormChanged(false);
			}
		} else {
			if (gisLayers.length > 0) {
				setIsFormChanged(true);
			}
		}
	}, [gisLayers, networkData, gisLayersData, loadingGisLayers, fetchingGisLayers]);

	const onFinish = async (values: any) => {
		setSubmitting(true);
		Object.keys(values).forEach(key => {
			if (values[key] === '' || values[key] === undefined || values[key] === null) {
				delete values[key];
			}
		});
		values['gis_layers'] = gisLayers?.map(ele => {
			if (typeof ele.geojson === 'string') {
				try {
					ele.geojson = JSON.parse(ele.geojson);
				} catch (error) {
					console.error('Invalid JSON string:', error);
				}
			}
			return ele;
		});
		if (networkId) {
			try {
				const resp = await monitoringService.editNetwork(networkId, values);
				if (!resp) throw new Error(t('couldntEditNetwork', { ns: 'validation' }));
				const networkIdx = networks.findIndex((n: any) => n.id === resp.id);
				let updatedNetworks = [...networks];
				updatedNetworks.splice(networkIdx, 1, resp);
				refetchNetwork && refetchNetwork();
				refetchNetworks();
				refechGisLayers();
				setActivePanel([]);
				notifySuccess(t('editedSuccessfully'));
			} catch (e: any) {
				errorHandler(e);
			}
		} else {
			values['is_active'] = activatedNetwork ?? false;
			try {
				const resp = await monitoringService.createNetwork(values);
				if (!resp) throw new Error(t('couldntAddNetwork', { ns: 'validation' }));
				refetchNetworks();
				queryClient.refetchQueries(['configuration-permissions']);
				refetchNetworks();
				refechGisLayers();
				setActivePanel([]);
				notifySuccess(t('addedSuccessfully'));
				navigate(`${AppRoutes.CONFIGURATION}?tabSelected=networks`);
			} catch (e: any) {
				errorHandler(e?.detail ? { message: e.detail } : e);
			}
		}
		setSubmitting(false);
	};

	// confirm before submition
	const onConfirm = values => {
		try {
			modalConfirm({
				onOk: async () => {
					await onFinish(values);
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	// reset fields
	const resetFields = () => {
		if (networkData && !(loadingGisLayers && fetchingGisLayers)) {
			// fill form with the selected data
			form.setFieldsValue({
				...networkData
			});
			setSelectedLocation({ latitude: networkData.latitude, longitude: networkData.longitude });
			setGisLayers(gisLayersData);
		} else {
			form.resetFields();
			setSelectedLocation({ latitude: undefined, longitude: undefined });
			setGisLayers([]);
		}
		setIsFormChanged(false);
	};

	return (
		<>
			<Spin
				spinning={
					submitting ||
					loadingFoundations ||
					!!(networkId && !networks) ||
					(loadingGisLayers && fetchingGisLayers)
				}
			>
				<Form
					form={form}
					layout="vertical"
					onFinish={onConfirm}
					initialValues={{ is_active: false }}
					onValuesChange={() => setIsFormChanged(true)}
				>
					<Form.Item
						name="foundation"
						label={t('Foundation')}
						rules={[{ required: true, message: t('addFoundation', { ns: 'validation' }) }]}
					>
						<Select
							placeholder={t('Foundation')}
							options={
								foundations &&
								foundations.map((foundation: any) => {
									return {
										value: foundation.id,
										label: foundation.name
									};
								})
							}
						/>
					</Form.Item>
					<Row className="w-100">
						<Col xs={11}>
							<Form.Item
								name="name_en"
								label={t('EnglishName')}
								rules={[
									{
										required: true,
										message: t('AddNetworkNameInEnglish', { ns: 'validation' })
									}
								]}
							>
								<Input type="text" placeholder={t('NetworkNameInEnglish')} />
							</Form.Item>
						</Col>
						<Col xs={{ span: 12, offset: 1 }}>
							<Form.Item
								name="name_ar"
								label={t('ArabicName')}
								rules={[
									{
										required: true,
										message: t('AddNetworkNameInArabic', { ns: 'validation' })
									}
								]}
							>
								<Input type="text" placeholder={t('NetworkNameInArabic')} />
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						name="description_en"
						label={t('EnglishDescription')}
						rules={[
							{
								required: true,
								message: t('AddNetworkDescriptionInEnglish', { ns: 'validation' })
							}
						]}
					>
						<Input.TextArea maxLength={255} placeholder={t('DescribeNetworkInEnglish')} />
					</Form.Item>
					<Form.Item
						name="description_ar"
						label={t('ArabicDescription')}
						rules={[
							{
								required: true,
								message: t('AddNetworkDescriptionInArabic', { ns: 'validation' })
							}
						]}
					>
						<Input.TextArea maxLength={255} placeholder={t('DescribeNetworkInArabic')} />
					</Form.Item>
					<Form.Item label={t('LatitudeAndLongitude')} style={{ marginBottom: 0 }}>
						<Row className="w-100">
							<Col xs={11}>
								<Form.Item
									name="latitude"
									rules={[
										{
											required: true,
											message: t('PleaseAddLatitude', { ns: 'validation' })
										}
									]}
								>
									<InputNumber
										placeholder={t('Latitude')}
										max={90}
										onChange={val => setSelectedLocation({ ...selectedLocation, latitude: val })}
										style={{ width: '100%' }}
									/>
								</Form.Item>
							</Col>
							<Col xs={{ span: 12, offset: 1 }}>
								<Form.Item
									name="longitude"
									rules={[
										{
											required: true,
											message: t('PleaseAddLongitude', { ns: 'validation' })
										}
									]}
								>
									<InputNumber
										placeholder={t('Longitude')}
										max={90}
										onChange={val => setSelectedLocation({ ...selectedLocation, longitude: val })}
										style={{ width: '100%' }}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Form.Item>
					<Form.Item
						name="subscriber_no"
						label={t('SubscriberNumber')}
						rules={[{ required: true, message: t('SubscriberNumber', { ns: 'validation' }) }]}
					>
						<InputNumber placeholder={t('SubscriberNumber')} style={{ width: '100%' }} />
					</Form.Item>
					{/* <Form.List name="gis_layers">
								{(fields, { add, remove }) => {
									return (
										<div>
											{fields.map((field, idx) => (
												<div key={field.key}>
													<Row className="w-100" key={idx}>
														<Col xs={8}>
															<Form.Item
																name={[idx, 'type']}
																rules={[
																	{
																		required: true,
																		message: t('SelectGISLayerType', { ns: 'validation' })
																	}
																]}
															>
																<Select placeholder={t('GISLayerType')}>
																	<Select.Option value={GISLayerType.BOUNDARY}>
																		{upperSnakeToCapitalized(GISLayerType.BOUNDARY)}
																	</Select.Option>
																	<Select.Option value={GISLayerType.PIPES}>
																		{upperSnakeToCapitalized(GISLayerType.PIPES)}
																	</Select.Option>
																	<Select.Option value={GISLayerType.VALVES}>
																		{upperSnakeToCapitalized(GISLayerType.VALVES)}
																	</Select.Option>
																	<Select.Option
																		value={GISLayerType.ISOLATION_VALVES}
																	>
																		{upperSnakeToCapitalized(
																			GISLayerType.ISOLATION_VALVES
																		)}
																	</Select.Option>
																	<Select.Option value={GISLayerType.STEP_VALVES}>
																		{upperSnakeToCapitalized(
																			GISLayerType.STEP_VALVES
																		)}
																	</Select.Option>
																	<Select.Option value={GISLayerType.STATIONS}>
																		{upperSnakeToCapitalized(GISLayerType.STATIONS)}
																	</Select.Option>
																</Select>
															</Form.Item>
														</Col>
														<Col
															xs={{ span: 15, offset: 1 }}
															className={'mb-2 d-flex justify-content-between'}
														>
															<Form.Item
																name={[idx, 'geojson']}
																rules={[
																	{
																		required: true,
																		message: t('AddJSONGISLayer', { ns: 'validation' })
																	}
																]}
																style={{ width: '100%' }}
															>
																<TextArea
																	placeholder={t('AddInJSONFormat')}
																	autoSize={{ minRows: 8, maxRows: 8 }}
																	onChange={() => setJSONFormatError(false)}
																/>
															</Form.Item>
															<Tooltip title={t('Remove', { ns: 'common' })}>
																<TableControlItem
																	onClick={() => {
																		remove(field.name);
																	}}
																>
																	<RiDeleteBinLine color={colors.ERROR} />
																</TableControlItem>
															</Tooltip>
														</Col>
													</Row>
												</div>
											))}
											{JSONFormatError ? (
												<p className="text-danger"> {t('AddRightJSONFormat')}</p>
											) : null}
											<Button onClick={() => add()} type="link" icon={<AiOutlinePlus />}>
												{t('AddGISLayer')}
											</Button>
										</div>
									);
								}}
							</Form.List> */}
					<Row gutter={16}>
						<Col xs={12}>
							<Form.Item label={t('GISLayers')} style={{ marginBottom: 0 }}>
								<Button
									onClick={() => setVisibleGISModal(true)}
									type={'primary'}
									ghost
									icon={<AiOutlinePlus />}
									className={'mb-3'}
								>
									{t('AddGISLayer')}
								</Button>
							</Form.Item>
							<Space size={[0, 8]} wrap>
								{gisLayers &&
									gisLayers.map((layer: any, idx: number) => {
										return (
											<Tag key={idx}>
												{layer.name_en ? `${upperSnakeToCapitalized(layer.name_en)}-` : null}
												{upperSnakeToCapitalized(layer.type)}
											</Tag>
										);
									})}
							</Space>
						</Col>
					</Row>
					<div className="d-flex justify-content-end">
						<SubmitCanelButtons
							handleCancel={() => resetFields()}
							handleSubmit={form.submit}
							reset={true}
							disabled={!isFormChanged}
						/>
					</div>
				</Form>
				<Modal
					title={t('AddGISLayer')}
					open={visibleGISModal}
					centered
					onCancel={() => {
						setVisibleGISModal(false);
						gisForm.resetFields();
					}}
					onOk={() => gisForm.submit()}
				>
					<GISLayerForm
						gisForm={gisForm}
						onGISLayerFinish={async values => {
							setGisLayers([...gisLayers, values]);
							setVisibleGISModal(false);
						}}
					/>
				</Modal>
			</Spin>
		</>
	);
};
