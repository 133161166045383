import { useQuery } from '@tanstack/react-query';
import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import configProxy from '../config/config';
import { AppRoutes } from '../constants/routes';
import { ChatBot } from '../components/chatbot';
import { WaiNavbar, WaiSidebar } from '../containers';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import { useMedia } from '../hooks/use-media.hook';
import monitoringService from '../services/monitoring.service';
import userManagementService from '../services/user-management.service';
import { PressureUnit, Zones } from '../types';
import { errorHandler } from '../utils/error-handler';
import BotpressChat from '../components/chatbot/botpress-chat';
import { IoChatboxEllipsesSharp } from 'react-icons/io5';
import { colors } from '../constants/colors';

const { Content } = Layout;

export const DashboardLayout: React.FC<{ children: React.ReactNode; showSidebar?: boolean }> = ({
	showSidebar = true,
	children
}) => {
	const { i18n } = useTranslation();
	const [selectedNetwork, setSelectedNetwork] = useState<any>();
	const [selectedZone, setSelectedZone] = useState<any>();
	const [selectedCommercialMeterGroup, setSelectedCommercialMeterGroup] = useState<any>();
	const [selectedMeasurementPoint, setSelectedMeasurementPoint] = useState<any>();
	const [pressureUnit, setPressureUnit] = useState<PressureUnit[]>([PressureUnit.BAR, PressureUnit.BAR]);
	const [pressureRange, setPressureRange] = useState([0, 10]);
	const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
	let media = configProxy.defaultExpandScreenSize;
	let desktopView = useMedia(media);
	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();

	// get all subsystems
	const { data: subsystems } = useQuery<any>({
		queryKey: ['subsystems'],
		queryFn: () => userManagementService.getSubsystems()
	});

	// get all applications
	const { data: applications } = useQuery<any>({
		queryKey: ['applications'],
		queryFn: () => userManagementService.getApplications()
	});

	// load all networks
	const { data: networks, refetch: refetchNetworks } = useQuery({
		queryKey: ['networks'],
		queryFn: () => monitoringService.getNetworks()
	});

	//load all zones
	const { data: allZones, refetch: refetchAllZones } = useQuery({
		queryKey: ['zones'],
		queryFn: () => monitoringService.getZonesAll()
	});

	// load zones for selected network
	const { data: zones, refetch: refetchZones } = useQuery({
		queryKey: ['zones', selectedNetwork],
		queryFn: () => monitoringService.getZonesAll({ network: selectedNetwork }),
		enabled: !!selectedNetwork
	});

	//load all measurement points
	const { data: allMeasurementPoints, refetch: refetchAllMeasurementPoints } = useQuery({
		queryKey: ['sites'],
		queryFn: () => monitoringService.getMeasurementPointsAll()
	});

	// Load measurement points for selected network/zone
	const { data: measurementPoints } = useQuery({
		queryKey: ['sites', selectedZone ? selectedZone : selectedNetwork],
		queryFn: () => {
			return monitoringService.getMeasurementPointsAll(
				selectedZone
					? {
							zone: selectedZone
					  }
					: {
							network: selectedNetwork
					  }
			);
		},
		enabled: !!selectedNetwork || !!selectedZone
	});

	// Load measurement points for selected network/zone for side bar only
	// NOTE: it will contain commercial sites only when select commercial meter group from zones
	const { data: sitesSideBar } = useQuery({
		queryKey: [
			'sites-side-bar',
			selectedCommercialMeterGroup ? selectedCommercialMeterGroup : selectedZone ? selectedZone : selectedNetwork
		],
		queryFn: () => {
			return monitoringService.getMeasurementPointsAll(
				selectedCommercialMeterGroup || selectedZone
					? {
							'zone': selectedCommercialMeterGroup ?? selectedZone,
							'side-bar': true
					  }
					: {
							network: selectedNetwork
					  }
			);
		},
		enabled: !!selectedNetwork || !!selectedCommercialMeterGroup || !!selectedZone
	});

	// assigin params to searchParams related to selected elements
	useEffect(() => {
		if (selectedNetwork) searchParams.set('network', selectedNetwork);
		if (selectedZone) searchParams.set('zone', selectedZone);
		if (selectedCommercialMeterGroup) searchParams.set('commercialMeterGroup', selectedCommercialMeterGroup);
		if (selectedMeasurementPoint) searchParams.set('point', selectedMeasurementPoint);
		setSearchParams(searchParams);
	}, [location.pathname]);

	// assign selected network from searchParams
	useEffect(() => {
		(async () => {
			try {
				let selNetwork;
				if (networks?.length) {
					selNetwork = networks[0]?.id;
					const networkQuery = searchParams.get('network');
					const zoneQuery = searchParams.get('zone');
					const commercialMeterGroupQuery = searchParams.get('commercialMeterGroup');
					const pointQuery = searchParams.get('point');
					if (networkQuery) {
						selNetwork = networkQuery;
					} else {
						searchParams.set('network', selNetwork);
						setSearchParams(searchParams);
					}
					setSelectedNetwork(selNetwork);
					refetchZones();
					if (zoneQuery) setSelectedZone(zoneQuery);
					if (commercialMeterGroupQuery) setSelectedCommercialMeterGroup(commercialMeterGroupQuery);
					if (pointQuery) setSelectedMeasurementPoint(pointQuery);
				}
			} catch (e: any) {
				errorHandler(e);
			}
		})();
	}, [networks]);

	// on select network
	const onNetworkSelect = async (networkId: string) => {
		try {
			setSelectedNetwork(networkId);
			setSelectedZone(undefined);
			setSelectedCommercialMeterGroup(undefined);
			setSelectedMeasurementPoint(undefined);
			searchParams.set('network', networkId);
			searchParams.delete('zone');
			searchParams.delete('commercialMeterGroup');
			searchParams.delete('point');
			setSearchParams(searchParams);
		} catch (e: any) {
			errorHandler(e);
		}
	};

	// on select zone
	const onZoneSelect = async (zoneId: string) => {
		if (!zoneId) {
			searchParams.delete('zone');
			searchParams.delete('commercialMeterGroup');
			setSelectedZone(undefined);
			setSelectedCommercialMeterGroup(undefined);
			setSelectedMeasurementPoint(undefined);
			setSearchParams(searchParams);
			return;
		}
		try {
			const zone = zones.find(zone => zone.id === zoneId);
			if (zone.type === Zones.COMMERCIAL_METER) {
				setSelectedCommercialMeterGroup(zoneId);
				searchParams.set('commercialMeterGroup', zoneId);
				setSelectedZone(undefined);
				searchParams.delete('zone');
			} else {
				setSelectedZone(zoneId);
				searchParams.set('zone', zoneId);
				setSelectedCommercialMeterGroup(undefined);
				searchParams.delete('commercialMeterGroup');
			}
			setSelectedMeasurementPoint(undefined);
			searchParams.delete('point');
			setSearchParams(searchParams);
		} catch (e: any) {
			errorHandler(e);
		}
	};

	// on select measurement point
	const onMeasurementPointSelect = async (pointId: string) => {
		if (!pointId) {
			searchParams.delete('point');
			setSearchParams(searchParams);
			return setSelectedMeasurementPoint(undefined);
		}
		try {
			setSelectedMeasurementPoint(pointId);
			searchParams.set('point', pointId);
			setSearchParams(searchParams);
		} catch (e: any) {
			errorHandler(e);
		}
	};

	// setup mapper for applications with names and IDs
	const applicationIdMap = new Map();
	applications?.forEach(item => {
		applicationIdMap.set(item.name, item.id);
	});

	return (
		<NavigationContext.Provider
			value={{
				subsystems,
				applications,
				applicationIdMap,
				networks,
				selectedNetwork,
				refetchNetworks,
				setSelectedNetwork,
				onNetworkSelect,
				zones,
				allZones,
				selectedZone,
				selectedCommercialMeterGroup,
				refetchZones,
				refetchAllZones,
				setSelectedZone,
				setSelectedCommercialMeterGroup,
				onZoneSelect,
				measurementPoints,
				allMeasurementPoints,
				selectedMeasurementPoint,
				refetchAllMeasurementPoints,
				setSelectedMeasurementPoint,
				onMeasurementPointSelect,
				sitesSideBar,
				pressureUnit,
				setPressureUnit,
				pressureRange,
				setPressureRange,
				errorHandler
			}}
		>
			<WaiNavbar onBurgerClick={() => setSidebarVisible(true)} />
			{showSidebar ? (
				<WaiSidebar sidebarVisible={sidebarVisible} onSidebarClose={() => setSidebarVisible(false)} />
			) : null}
			<Content
				style={{
					paddingTop: desktopView ? '4.5rem' : 0,
					paddingLeft: !showSidebar
						? '2rem'
						: desktopView
						? i18n.language === 'en'
							? '13.8rem'
							: '2rem'
						: 0,
					paddingRight: !showSidebar ? '2rem' : desktopView ? (i18n.language === 'en' ? '2rem' : '14rem') : 0,
					overflow: 'hidden'
				}}
			>
				{children}
			</Content>
			{location.pathname !== AppRoutes.ADVANCED_ANALYSIS ? (
				configProxy.chatbot_botpress_url ? (
					<>
						<IoChatboxEllipsesSharp
							style={{
								position: 'fixed',
								right: '1rem',
								bottom: '1rem',
								zIndex: 10000,
								cursor: 'pointer'
							}}
							size={50}
							color={colors.DARK_BLUE}
							onClick={() => {
								window.botpressWebChat.sendEvent({ type: 'show' });
							}}
						/>
						<BotpressChat />
					</>
				) : (
					false
				)
			) : (
				false
			)}
		</NavigationContext.Provider>
	);
};
