export enum ValveStatus {
	'OPEN' = 'OPEN',
	'CLOSED' = 'CLOSED',
	'NO_VALVE' = 'NO_VALVE'
}

export enum ValveOperationStatus {
	'NORMAL' = 'NORMAL',
	'NEEDS_INVESTIGATION' = 'NEEDS_INVESTIGATION',
	'OPERATION_ERROR' = 'OPERATION_ERROR',
	'DATA_PROBLEM' = 'DATA_PROBLEM',
	'OTHER' = 'OTHER'
}

export enum ValveManufacturer {
	SIERRA = 'SIERRA',
	OTHER = 'OTHER'
}

export enum ValveControlType {
	PRV = 'PRV',
	PSV = 'PSV',
	FLV = 'FLV',
	OTHER = 'OTHER'
}

export enum ValveMaintenanceReminder {
	NORMAL = 'NORMAL',
	APPROACHING = 'APPROACHING',
	OVERDUE = 'OVERDUE',
	OTHER = 'OTHER'
}

export enum ValveStreamDirection {
	UPSTREAM = 'UPSTREAM',
	DownSTREAM = 'DownSTREAM'
}
