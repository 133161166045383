export enum DataSourcesCount {
	BATTERY_LEVEL_COUNT = 'battery_level_count',
	FLOW_RATE_COUNT = 'flow_rate_count',
	NOISE_COUNT = 'noise_count',
	PRESSURE_COUNT = 'pressure_count'
}

export enum DataSources {
	BATTERY_LEVEL = 'BATTERY_LEVEL',
	FLOW_RATE = 'FLOW_RATE',
	CALCULATED_FLOW_RATE = 'CALCULATED_FLOW_RATE',
	NOISE = 'NOISE',
	PRESSURE = 'PRESSURE',
	ACCUMULATED_FLOW = 'ACCUMULATED_FLOW'
}

export enum PressureType {
	UPSTREAM = 'UPSTREAM',
	DOWNSTREAM = 'DOWNSTREAM',
	STREAM = 'STREAM'
}

export enum FlowRateType {
	ZMNF = 'ZMNF',
	ZDWC = 'ZDWC',
	ZDWP = 'ZDWP',
	MNF = 'MNF',
	DWC = 'DWC',
	DWP = 'DWP'
}

export enum StreamDirection {
	UPSTREAM = 'UPSTREAM',
	DOWNSTREAM = 'DOWNSTREAM',
	STREAM = 'STREAM'
}

export interface IDataSourceLog {
	time: string;
	value: number;
}
