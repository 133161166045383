import React from 'react';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import { FaMapMarkedAlt } from 'react-icons/fa';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { ScreenWidths } from '../../../hooks/use-media.hook';

const Toggle = styled.div<{ media: keyof typeof ScreenWidths }>`
	width: 40px;
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	padding: 10px 0;
	box-sizing: border-box;
	cursor: pointer;
	@media (min-width: ${props => ScreenWidths[props.media]}) {
		display: none;
	}
	div {
		width: 90%;
		height: 2px;
		background-color: ${colors.WHITE};
		margin: 4px 0;
	}
`;

export const SideDrawerBurger = props => {
	return (
		<Toggle onClick={props.onClick} media={props.media}>
			<FaMapMarkedAlt size={35} color={colors.WHITE} />
		</Toggle>
	);
};
