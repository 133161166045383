import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Spin, Switch, Table, TableProps } from 'antd';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { AiFillCheckCircle, AiFillCloseCircle, AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { AuthenticationContext } from '../../contexts';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { transformDataSourcesToRecord } from '../../mappers/data-source-to-record';
import monitoringService from '../../services/monitoring.service';
import { DataSources, IDataSourceRecord, PressureType, UserRights, UserApps } from '../../types';
import { formatFilters } from '../../utils/get-table-filters';
import { formatSorter } from '../../utils/get-table-sorter';
import { hasRight } from '../../utils/has-right';
import { notifySuccess } from '../../utils/notification-messages';
import { upperSnakeToCapitalized } from '../../utils/upper-snake-to-capitalized';
import { ComponentGuard } from '../component-guard';
import { TableControls } from '../table-controls';
import { modalConfirm } from '../modal-confirm';

export const DataSourcesTable: React.FC<TableProps<IDataSourceRecord>> = () => {
	const { t, i18n } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { errorHandler, networks, applicationIdMap } = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user, configurationPermessions } = authContext;
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [dataSourcesSorters, setDataSourcesSorters] = useState<any>({});
	const [dataSourcesFilters, setDataSourcesFilters] = useState<any>({});

	// load measurementPoints
	const { data: measurementPoints } = useQuery({
		queryKey: ['sites'],
		queryFn: () => monitoringService.getMeasurementPointsAll()
	});

	// load zones
	const { data: allZones } = useQuery<any>({
		queryKey: ['zones'],
		queryFn: () => monitoringService.getZonesAll()
	});

	// load dataSources
	const {
		data: dataSources,
		isLoading: loadingDataSources,
		refetch: refetchDataSources
	} = useQuery<any>({
		queryKey:
			(dataSourcesSorters && Object.keys(dataSourcesSorters)?.length) ||
			(dataSourcesFilters && Object.keys(dataSourcesFilters)?.length)
				? ['data-sources', dataSourcesSorters, dataSourcesFilters]
				: ['data-sources'],
		queryFn: () =>
			monitoringService.getDataSources(
				(dataSourcesSorters && Object.keys(dataSourcesSorters)?.length) ||
					(dataSourcesFilters && Object.keys(dataSourcesFilters)?.length)
					? {
							sorters: { ...dataSourcesSorters },
							filters: { ...dataSourcesFilters }
					  }
					: undefined
			)
	});

	// filter dataSources with: network, zone, measurement_point, type, stream_direction, is_primary
	const onDataSourcesFilter = async (filters: any) => {
		if (JSON.stringify(filters) === JSON.stringify(dataSourcesFilters)) return;
		{
			await setDataSourcesFilters(filters);
			await refetchDataSources();
		}
	};

	// sort ataSources with: created_at
	const onDataSourcesSort = async (sorters: any) => {
		if (JSON.stringify(sorters) === JSON.stringify(dataSourcesSorters)) return;
		const sortedValues = Object.fromEntries(Object.entries(sorters).filter(([_, value]) => value !== undefined));
		if (Object.keys(sortedValues).length > 0) {
			await setDataSourcesSorters(sortedValues);
			await refetchDataSources();
		} else {
			await setDataSourcesSorters(undefined);
			await refetchDataSources();
		}
	};

	const handleTableChange = (newPagination: any, filters: any, sorters: any) => {
		onDataSourcesFilter && onDataSourcesFilter(formatFilters(filters));
		onDataSourcesSort && onDataSourcesSort(formatSorter(sorters));
	};

	const tableColumns: any = [
		{
			title: t('DataSource'),
			dataIndex: 'name_en',
			key: 'name_en',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Channel'),
			dataIndex: 'channel',
			key: 'channel',
			align: 'center',
			render: (text: string) => text
		},
		,
		{
			title: t('Type'),
			dataIndex: 'type',
			key: 'type',
			align: 'center',
			filters: Object.keys(DataSources).map(key => ({
				text: upperSnakeToCapitalized(key),
				value: key
			})),
			filterMultiple: false,
			render: (text: string) => upperSnakeToCapitalized(text)
		},
		{
			title: t('StreamDirection'),
			dataIndex: 'stream_direction',
			key: 'stream_direction',
			align: 'center',
			filters: Object.keys(PressureType).map(key => ({
				text: upperSnakeToCapitalized(key),
				value: key
			})),
			filterMultiple: false,
			render: (text: string) => upperSnakeToCapitalized(text)
		},
		{
			title: t('LogUnit'),
			dataIndex: 'log_unit',
			key: 'log_unit',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Status', { ns: 'common' }),
			dataIndex: 'is_primary',
			key: 'is_primary',
			align: 'center',
			filters: [
				{ text: t('Primary'), value: true },
				{ text: t('NotPrimary'), value: false }
			],
			filterMultiple: false,
			render: (check: boolean) =>
				check ? <AiFillCheckCircle className="text-success" /> : <AiFillCloseCircle className="text-danger" />
		},

		{
			title: t('RelatedMeasurementPoint'),
			dataIndex: 'measurement_point',
			key: 'measurement_point',
			align: 'center',
			filters:
				measurementPoints &&
				measurementPoints.map(measurementPoint => ({
					text: measurementPoint?.name_en.length > 0 ? measurementPoint?.name_en : measurementPoint.id,
					value: measurementPoint.id
				})),
			filterMultiple: false,
			render: (pointId: string) => {
				const matchedPoint = measurementPoints && measurementPoints.find(point => point.id === pointId);
				return matchedPoint ? matchedPoint.name_en || matchedPoint.id : '';
			}
		},
		{
			title: t('RelatedZone'),
			dataIndex: 'zones',
			key: 'zones',
			align: 'center',
			filters:
				allZones &&
				allZones.map(zone => ({
					text: i18n.language === 'en' ? zone.name_en : zone.name_ar,
					value: zone.id
				})),
			filterMultiple: false,
			render: (zonesIDs: string[]) => {
				const matchedZones = allZones && allZones.filter(zone => zonesIDs && zonesIDs.includes(zone.id));
				return matchedZones.length > 0
					? matchedZones.map((zone: any) => (i18n.language === 'en' ? zone.name_en : zone.name_ar)).join(', ')
					: '';
			}
		},
		{
			title: t('RelatedNetwork'),
			dataIndex: 'network',
			key: 'network',
			align: 'center',
			filters:
				networks &&
				networks.map(network => ({
					text: i18n.language === 'en' ? network.name_en : network.name_ar,
					value: network.id
				})),
			filterMultiple: false,
			render: (networkId: string) => {
				const matchedNetwork = networks && networks.find(network => network.id === networkId);
				return matchedNetwork ? (i18n.language === 'en' ? matchedNetwork.name_en : matchedNetwork.name_ar) : '';
			}
		},
		{
			title: t('DateCreated'),
			dataIndex: 'created_at',
			key: 'created_at',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: Date) => dayjs(text).format('dddd, MMMM D, YYYY h:mm A')
		},
		{
			title: t('Active'),
			dataIndex: 'is_active',
			key: 'is_active',
			align: 'center',
			filters: [
				{ text: t('Active'), value: true },
				{ text: t('NotActive'), value: false }
			],
			filterMultiple: false,
			render: (check: boolean) =>
				check ? <AiFillCheckCircle className="text-success" /> : <AiFillCloseCircle className="text-danger" />
		},
		{
			title: t('ActivateDecativate'),
			dataIndex: 'is_active',
			key: 'is_active',
			align: 'center',
			render: (check: boolean, record: IDataSourceRecord) => (
				<Switch
					defaultChecked={check}
					checkedChildren={<AiOutlineCheck />}
					unCheckedChildren={<AiOutlineClose />}
					onChange={async () => {
						await monitoringService.editDataSource(record.id, {
							is_active: !check
						});
						await refetchDataSources();
					}}
				/>
			)
		}
	];
	tableColumns.push({
		title: t('Actions'),
		key: 'actions',
		align: 'center',
		render: (_: any, record: IDataSourceRecord) => (
			<TableControls
				networkId={record.network}
				onEdit={() => {
					navigate(`${AppRoutes.DATA_SOURCE_CONFIGURATION}?data-source=${record.id}`);
				}}
				onRemove={() => onRemove(record.id, record.measurement_point)}
				userApp={UserApps.DATA_SOURCE}
			/>
		)
	});

	const onRemove = (id: string, measurementPointId: string) => {
		try {
			modalConfirm({
				content: t('YouAreAboutToDeleteDataSource'),
				onOk: async () => {
					await monitoringService.deleteDataSource(id);
					refetchDataSources();
					queryClient.refetchQueries(['sites']);
					queryClient.refetchQueries(['site', measurementPointId]);
					notifySuccess(t('RemovedSuccessfully'));
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	return (
		<>
			<Spin spinning={loadingDataSources}>
				<Table
					tableLayout="auto"
					scroll={{ x: true }}
					columns={tableColumns}
					dataSource={dataSources && measurementPoints ? transformDataSourcesToRecord(dataSources) : []}
					size="small"
					showSorterTooltip
					rowKey="id"
					onChange={handleTableChange}
				/>
			</Spin>

			<ComponentGuard
				allowed={hasRight(
					!!user?.user_data?.is_superAdmin,
					configurationPermessions,
					applicationIdMap.get(UserApps.DATA_SOURCE),
					UserRights.CREATE
				)}
			>
				<div className="d-flex justify-content-end mt-3">
					<Link to={AppRoutes.DATA_SOURCE_CONFIGURATION}>
						<Button type="primary">{t('AddDataSource')}</Button>
					</Link>
				</div>
			</ComponentGuard>
		</>
	);
};
