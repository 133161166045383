import { Button, Form, Modal, Table, TableProps, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { BiAnalyse } from 'react-icons/bi';
import { RiListSettingsLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { TableTag, TableTagType } from '../components/table-tag';
import { colors } from '../constants/colors';
import { AppRoutes } from '../constants/routes';
import { AuthenticationContext } from '../contexts';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import { UserApps, UserRights, UserSubSystemsApps } from '../types';
import { AlertPriority, AlertStatus, IAlertRecord } from '../types/alerts';
import { hasRight } from '../utils';
import { formatFilters } from '../utils/get-table-filters';
import { formatSorter } from '../utils/get-table-sorter';
import { hasSubSystem } from '../utils/has-sub-system';
import { upperSnakeToCapitalized } from '../utils/upper-snake-to-capitalized';
import { AlertForm } from './alert-form';
import { modalConfirm } from '../components/modal-confirm';

const priorityToTagMapper = {
	[AlertPriority.URGENT]: 'error',
	[AlertPriority.HIGH]: 'warning',
	[AlertPriority.NORMAL]: 'ghost',
	[AlertPriority.INTERMEDIATE]: 'progress',
	[AlertPriority.LOW]: 'ghost'
};

const statusToTagMapper = {
	[AlertStatus.CANCELLED]: 'error',
	[AlertStatus.OPEN]: 'progress',
	[AlertStatus.CLOSED]: 'ghost',
	[AlertStatus.PENDING]: 'warning',
	[AlertStatus.RESOLVED]: 'success'
};

export const AlertsTable: React.FC<
	TableProps<IAlertRecord> & {
		alerts: IAlertRecord[];
		onAlertClick?: (alert: any) => any;
		onAlertReset?: () => any;
		onAlertSort?: (sorters: any) => any;
		onAlertFilter?: (filter: any) => any;
		onAlertPagination?: (filter: any) => any;
		fetchAlerts?: (page: number, pageSize: number) => any;
		selectedRow: any;
		setSelectedRow: any;
		setSelectedMeasurementPointRow: any;
		refetchAlerts: () => any;
		refetchAlertStats: () => any;
	}
> = ({
	alerts,
	pagination,
	fetchAlerts,
	loading,
	onAlertClick,
	onAlertSort,
	onAlertPagination,
	onAlertFilter,
	onAlertReset,
	selectedRow,
	setSelectedRow,
	setSelectedMeasurementPointRow,
	refetchAlerts,
	refetchAlertStats
}) => {
	const { t } = useTranslation();
	const authContext = useContext(AuthenticationContext);
	const { user, alertPermessions, analysisPermessions } = authContext;
	const navigationContext = useContext(NavigationContext);
	const { selectedNetwork, errorHandler, onMeasurementPointSelect, applicationIdMap } = navigationContext;
	const [visibleActionsForm, setVisibleActionsForm] = useState<boolean>(false);
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const tableColumns: any = [
		{
			title: <span className="text-align-center">{t('Name')}</span>,
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			render: (text: string) => upperSnakeToCapitalized(text)
		},
		{
			title: t('AlertID'),
			dataIndex: 'alertId',
			key: 'alertId',
			align: 'center',
			render: (text: string) => (text.length === 0 ? t('NA', { ns: 'common' }) : text)
		},
		{
			title: t('Source'),
			dataIndex: 'source',
			key: 'source',
			align: 'center',
			render: (text: string) => upperSnakeToCapitalized(text)
		},
		{
			title: t('Priority'),
			dataIndex: 'priority',
			key: 'priority',
			align: 'center',
			filters: Object.keys(AlertPriority).map(key => ({
				text: upperSnakeToCapitalized(t(key.toLowerCase())),
				value: key.toLowerCase()
			})),
			filterMultiple: false,
			render: (text: AlertPriority) =>
				text && (
					<TableTag type={priorityToTagMapper[text] as TableTagType}>
						{t(text.toLowerCase()).toUpperCase()}
					</TableTag>
				)
		},
		{
			title: t('AlertType'),
			dataIndex: 'alert_type',
			key: 'alert_type',
			align: 'center',
			render: (text: string) => upperSnakeToCapitalized(text)
		},
		{
			title: t('DateOfFirstEvent'),
			dataIndex: 'alertStart',
			key: 'alertStart',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: Date) => dayjs(text).format('dddd, MMMM D, YYYY h:mm A')
		},
		{
			title: t('DateOfLastEvent'),
			dataIndex: 'alertEnd',
			key: 'alertEnd',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: Date) => dayjs(text).format('dddd, MMMM D, YYYY h:mm A')
		},
		{
			title: t('ZonesAffected'),
			dataIndex: 'affected_zones',
			key: 'affected_zones',
			align: 'center',
			render: (text: []) => text || t('NA', { ns: 'common' })
		},
		{
			title: t('NetworksAffected'),
			dataIndex: 'affected_networks',
			key: 'affected_networks',
			align: 'center',
			render: (text: []) => text || t('NA', { ns: 'common' })
		},
		{
			title: t('Status', { ns: 'common' }),
			dataIndex: 'alert_status',
			key: 'alert_status',
			align: 'center',
			filters: Object.keys(AlertStatus).map(key => ({
				text: upperSnakeToCapitalized(t(key.toLowerCase())),
				value: key.toLowerCase()
			})),
			filterMultiple: false,
			render: (text: AlertStatus) =>
				text && (
					<TableTag type={statusToTagMapper[text] as TableTagType}>
						{t(text.toLowerCase()).toUpperCase()}
					</TableTag>
				)
		}
	];
	if (
		hasRight(
			!!user?.user_data?.is_superAdmin,
			alertPermessions,
			applicationIdMap.get(UserApps.ALERTS),
			UserRights.EDIT,
			selectedNetwork
		) ||
		hasRight(
			!!user?.user_data?.is_superAdmin,
			alertPermessions,
			applicationIdMap.get(UserApps.ALERTS),
			UserRights.DELETE,
			selectedNetwork
		)
	)
		tableColumns.push({
			title: t('Actions'),
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			render: (text: string) => (
				<RiListSettingsLine
					size={25}
					color={colors.WAI_BLUE}
					role="button"
					onClick={() => {
						setSelectedRow([text]);
						setVisibleActionsForm(true);
					}}
				/>
			)
		});
	if (
		hasSubSystem(user, UserSubSystemsApps.ANALYSIS) &&
		!!analysisPermessions?.find(permission => permission.scope === selectedNetwork)
	) {
		tableColumns.push({
			title: t('Analysis'),
			key: 'actions',
			align: 'center',
			render: (record: any) => (
				<Link to={`${AppRoutes.ANALYSIS}?point=${record.measurementPointId}`}>
					<Tooltip title={t('Analyze')}>
						<BiAnalyse
							color={colors.WAI_BLUE}
							size={25}
							onClick={() => {
								handleMeasurementPointSelect(record.measurementPointId);
							}}
						/>
					</Tooltip>
				</Link>
			)
		});
	}
	const handleTableChange = (newPagination: any, filters: any, sorters: any) => {
		onAlertFilter && onAlertFilter(formatFilters(filters));
		onAlertSort && onAlertSort(formatSorter(sorters));
		onAlertPagination && onAlertPagination(newPagination);
	};
	const handleMeasurementPointSelect = (id: any) => {
		onMeasurementPointSelect && onMeasurementPointSelect(id);
	};

	// confirm edition for actions of alert
	const onConfirm = () => {
		try {
			modalConfirm({
				onOk: () => {
					form.submit();
				},
				onCancel: () => {
					setVisibleActionsForm(true);
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	return (
		<>
			{selectedRow.length ? (
				<Button
					onClick={() => {
						setSelectedRow([]);
						setSelectedMeasurementPointRow();
						onAlertReset && onAlertReset();
					}}
					size="large"
					type="link"
					style={{ marginBottom: '5px' }}
				>
					{t('Reset', { ns: 'common' })}
				</Button>
			) : null}
			<Table
				onRow={(record: any, idx) => {
					return {
						onClick: e => {
							setSelectedRow([record.id]);
							setSelectedMeasurementPointRow(record.measurementPointId);
							onAlertClick && onAlertClick(record);
						}
					};
				}}
				tableLayout="auto"
				scroll={{ x: true }}
				columns={tableColumns}
				dataSource={alerts}
				pagination={{ ...pagination }}
				size="small"
				loading={loading || submitting}
				showSorterTooltip
				onChange={handleTableChange}
				rowKey="id"
				rowSelection={{
					type: 'radio',
					selectedRowKeys: selectedRow
				}}
			/>
			<Modal
				key={selectedRow[0]}
				open={visibleActionsForm}
				title={t('EditActions')}
				centered={true}
				onOk={() => {
					setVisibleActionsForm(false);
					onConfirm();
				}}
				onCancel={() => {
					setVisibleActionsForm(false);
					setSelectedRow([]);
					setSelectedMeasurementPointRow();
					onAlertReset && onAlertReset();
				}}
			>
				<AlertForm
					form={form}
					AlertId={selectedRow.length > 0 && selectedRow[0]}
					setVisibleActionsForm={setVisibleActionsForm}
					refetchAlerts={refetchAlerts}
					refetchAlertStats={refetchAlertStats}
					submitting={submitting}
					setSubmitting={setSubmitting}
				/>
			</Modal>
		</>
	);
};
