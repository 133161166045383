const waiWhiteLabel = {
	dashboardColor: '#ebf0f5 ',
	sliderColor: '#002d5a',
	sliderImgBGColor: 'rgba(255, 255, 255, 0)',
	navLinkColor: '#02bbfe',
	navLinkActiveColor: '#fff',
	signinBackgroundColor: '#BAE4E8',
	signinCardBackgroundColor: '#002D5B',
	buttonColor: '#2887f2',
	sidebarSelectorColor: '#2887f2',
	logoTextColor: '#fff',
	logoSubTextColor: '#fff',
	logoImgColor: '#fff',
	sidebarSelectorOptionColor: '#fff',
	sidebarSelecedSelectorColor: '#2887f2',
	sidebarSelecedSelectorBGColor: '#B2F1FF',
	sidebarSelectorOptionHoverColor: 'rgba(0,0,0,0.1)',
	logoText: 'Wai',
	logoSubText: 'technologies'
};
export default waiWhiteLabel;
