import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Form, Table, TableProps } from 'antd';
import { useContext, useState } from 'react';
import { AiFillCheckCircle, AiFillCloseCircle, AiOutlinePlus } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { useQueryParam } from '../../hooks/use-query';
import monitoringService from '../../services/monitoring.service';
import { UserApps } from '../../types';
import { IDeviceRecord } from '../../types/device-record';
import { notifySuccess } from '../../utils/notification-messages';
import { upperSnakeToCapitalized } from '../../utils/upper-snake-to-capitalized';
import { DeviceForm } from '../device-form';
import { TableControls } from '../table-controls';
import { modalConfirm } from '../modal-confirm';

export const DevicesSummaryTable: React.FC<
	TableProps<IDeviceRecord> & {
		siteData: any;
	}
> = ({ siteData }) => {
	const { t } = useTranslation();

	const [device, setDevice] = useState<any>([]);
	const [visibleModal, setVisibleModal] = useState<boolean>(false);

	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	const [form] = Form.useForm();
	const queryClient = useQueryClient();
	const networkId = useQueryParam('networkId');

	const {
		data: devices,
		isLoading: loadingDevices,
		refetch: refetchDevices
	} = useQuery<any>({
		queryKey: ['devices', siteData?.id],
		queryFn: () =>
			monitoringService.getDevices({
				filters: { measurement_point: siteData.id }
			}),
		enabled: !!siteData?.id
	});

	const tableColumns: any = [
		{
			title: t('Device'),
			dataIndex: 'device_id',
			key: 'device_id',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Vendor'),
			dataIndex: 'vendor',
			key: 'vendor',
			align: 'center',
			render: (text: string) => upperSnakeToCapitalized(text)
		},
		{
			title: t('StreamDirection'),
			dataIndex: 'stream_direction',
			key: 'stream_direction',
			align: 'center',
			render: (text: string) => upperSnakeToCapitalized(text)
		},
		{
			title: t('Active'),
			dataIndex: 'is_active',
			key: 'is_active',
			align: 'center',
			render: (check: boolean) =>
				check ? <AiFillCheckCircle className="text-success" /> : <AiFillCloseCircle className="text-danger" />
		},
		{
			title: t('Online'),
			dataIndex: 'is_online',
			key: 'is_online',
			align: 'center',
			render: (check: boolean) =>
				check ? <AiFillCheckCircle className="text-success" /> : <AiFillCloseCircle className="text-danger" />
		}
	];

	tableColumns.push({
		title: t('Actions'),
		key: 'actions',
		align: 'center' as 'center',
		render: (_: any, record: IDeviceRecord) => (
			<TableControls
				networkId={networkId}
				onEdit={() => {
					setDevice(record);
					setVisibleModal(true);
				}}
				onRemove={() => onRemove(record.id)}
				userApp={UserApps.DEVICES}
			/>
		)
	});

	// confirm deletion device logger
	const onRemove = (id: string) => {
		try {
			modalConfirm({
				content: t('YouAreAboutToDeleteDevice'),
				onOk: async () => {
					await monitoringService.deleteDevice(id);
					queryClient.refetchQueries(['devices']);
					notifySuccess(t('RemovedSuccessfully'));
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	// confirm edition or creation device logger
	const onConfirm = async () => {
		try {
			await form.validateFields();
			modalConfirm({
				onOk: () => {
					form.submit();
				},
				onCancel: () => {
					setVisibleModal(true);
				}
			});
			setVisibleModal(false);
		} catch (e: any) {
			errorHandler(e);
		}
	};

	return (
		<>
			<Table
				tableLayout="auto"
				scroll={{ x: true }}
				columns={tableColumns}
				dataSource={devices || []}
				size="small"
				showSorterTooltip
				rowKey="id"
				pagination={false}
				loading={loadingDevices}
			/>

			{/* <Button
				onClick={() => {
					form.resetFields();
					setDevice(undefined);
					setVisibleModal(true);
				}}
				className={'my-2 float-end'}
				type="link"
				icon={<AiOutlinePlus />}
			>
				{t('AddNewDeviceLogger')}
			</Button> 
			<Modal
				open={visibleModal}
				title={t('Device')}
				onOk={() => {
					onConfirm();
				}}
				centered
				onCancel={() => {
					setVisibleModal(false);
				}}
			>
				<DeviceSelectForm form={form} device={device} measurementPointId={siteData.id} />
			</Modal> */}
		</>
	);
};
