import { useEffect } from 'react';
import configProxy from '../../config/config';

const BotpressChat = () => {
	useEffect(() => {
		// Load Botpress WebChat script
		const script = document.createElement('script');
		script.src = 'https://aguamenti-qa-ai.wai-techs.com/assets/modules/channel-web/inject.js';
		script.async = true;
		document.body.appendChild(script);

		script.onload = () => {
			window.botpressWebChat.init({
				host: configProxy.chatbot_botpress_url,
				botId: configProxy.chatbot_botpress_id,
				hideWidget: true,
				extraStylesheet: '/assets/modules/channel-web/botpress-custom-styles.css' // Add this line
			});
		};

		return () => {
			// Cleanup: remove the script when the component unmounts
			document.body.removeChild(script);
			window.botpressWebChat.sendEvent({ type: 'hide' });
			};
	}, []);

	return <div id="webchat" />;
};

export default BotpressChat;
