import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { ScreenWidths } from '../../../hooks/use-media.hook';

export const WaiNavLink = styled(NavLink)<{ media: keyof typeof ScreenWidths; label: any }>`
	color: ${({ label }) => label.navLinkColor};
	margin-right: 20px;
	text-decoration: none;
	font-weight: 1000;
	font-size: 1rem;
	padding: 21px 5px;
	transition: border 0.1s;
	&:hover,
	&.active {
		color: ${({ label }) => label.navLinkActiveColor};
		border-bottom: 5px solid ${({ label }) => label.navLinkColor};
	}

	@media (min-width: ${({ media }) => ScreenWidths[media]}) {
	}
`;

export const WaiNavSubLink = styled(NavLink)<{ label: any }>`
	margin-right: 15px;
	text-decoration: none;
	font-weight: 600;
	padding: 20px 0;
	&:hover {
		color: ${({ label }) => label.navLinkColor} !important;
	}
`;

export const UserProfile = styled.div`
	color: ${colors.WHITE};
	text-decoration: none;
	font-weight: 500;
	font-size: 1rem;
	padding: 0 15px;
	cursor: pointer;
`;
