import { PopoverProps } from 'antd';
import React, { useState } from 'react';
import { BsStack } from 'react-icons/bs';
import { colors } from '../../constants/colors';
import { useTranslation } from 'react-i18next';
import { WaiPopOver } from './styles/gis_toggler';

export const GisToggler: React.FC<PopoverProps & { alwaysVisible: boolean }> = ({
	content,
	alwaysVisible = false,
	...restProps
}) => {
	const { t, i18n } = useTranslation();
	const [visibility, setVisibility] = useState<boolean>(false);

	return (
		<WaiPopOver
			open={visibility || alwaysVisible}
			title={t('GISToggles')}
			content={content}
			trigger="hover"
			placement={i18n.language === 'en' ? 'bottomRight' : 'bottomLeft'}
			onOpenChange={setVisibility}
			{...restProps}
		>
			<span className="d-block">
				<BsStack size={20} color={colors.CYAN_WARM} />
			</span>
		</WaiPopOver>
	);
};
