import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';
import { GraphCard } from '../components';
import configProxy from '../config/config';
import { colors } from '../constants/colors';
import { useTranslation } from 'react-i18next';
import { useMedia } from '../hooks/use-media.hook';

const COLORS = [
	colors.GRAPH_GREEN,
	colors.GRAPH_PURPLE,
	colors.GRAPH_BLUE,
	colors.WAI_BLUE,
	colors.HEALTHY,
	colors.WARNING,
	colors.INACTIVE
];
const getBarchartOptions = (englishSelected: boolean, data, colorPie: string[]): ApexOptions => {
	return {
		chart: {
			//width: 380,
			type: 'donut'
		},
		plotOptions: {
			pie: {
				donut: {
					labels: {
						show: true,
						total: {
							showAlways: true,
							show: true
						}
					}
				}
			}
		},
		labels: data.map(d => d.name),
		colors: colorPie ?? COLORS,
		yaxis: {
			opposite: !englishSelected
		},
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200
					},
					legend: {
						position: 'bottom'
					}
				}
			}
		]
	};
};

export const WaiPieChart: React.FC<any> = ({
	data,
	title,
	dataKeys,
	colorPie,
	emphasizable,
	onChartSelect,
	fieldName,
	height,
	graphHeight,
	hint,
	...restProps
}) => {
	const { i18n } = useTranslation();
	const media = configProxy.defaultExpandScreenSize;
	const desktopView = useMedia(media);

	// useEffect(() => {
	// 	if (data && data.length) {
	// 		let prepData: any;
	// 		if (colorPie && colorPie.length) {
	// 			prepData = data.map((d: any, idx: number) => {
	// 				d.color = colorPie[idx % colorPie.length];
	// 				return d;
	// 			});
	// 		} else {
	// 			prepData = data.map((d: any, idx: number) => {
	// 				d.color = COLORS && COLORS[idx % COLORS.length];
	// 				return d;
	// 			});
	// 		}
	// 	}
	// }, [data]);

	const onEmphasize = (value?: any, field?: string) => {
		if (value && field && onChartSelect) {
			onChartSelect(fieldName, dataKeys);
		} else onChartSelect && onChartSelect();
	};

	return (
		<GraphCard
			emphasizable={emphasizable}
			onEmphasize={onEmphasize}
			title={title}
			fieldName={fieldName}
			hint={hint}
			{...restProps}
			height={height || 14}
		>
			<Chart
				type="donut"
				options={getBarchartOptions(i18n.language === 'en', data, colorPie)}
				series={data?.map(d => d?.value) || []}
				height={graphHeight || 200}
			/>
		</GraphCard>
	);
};
