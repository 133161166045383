import { Col, Row, Spin } from 'antd';
import { useContext, useState } from 'react';
import { NavigationContext } from '../contexts/navigation.context';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import chartsService from '../services/charts.service';
import { useTranslation } from 'react-i18next';
import { CumulativeReadingsCardsChart } from './cumulative-readings-cards-chart';
import { CumulativeReadingsPieChart } from './cumulative-readings-pie-chart';
import { CumulativeAvgReadingCardsChart } from './cumulative-avg-reading-card-chart';

export const NetworkAnalysisCumulativeReadingsTab: React.FC = () => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { selectedNetwork } = navigationContext;

	const [cumulativeReadingsDateCount, setCumulativeReadingsDateCount] = useState<{ start?: string; date?: string }>();
	const [cumulativeAvgReadingsDate, setCumulativeAvgReadingsDate] = useState<{
		start?: string;
		date?: string;
	}>();
	const [cumulativeReadingsDate, setCumulativeReadingsDate] = useState<{
		start?: string;
		date?: string;
	}>();

	// get first and last readings date for network cumulative readings
	const { data: firstReadingsDate, isLoading: loadingFirstReadingsDate } = useQuery({
		queryKey: ['network-commercial-first-reading', selectedNetwork],
		queryFn: () => chartsService.getNetworkCommercialFirstDate(selectedNetwork),
		enabled: !!selectedNetwork
	});
	const { data: lastReadingsDate, isLoading: loadingLastReadingsDate } = useQuery({
		queryKey: ['network-commercial-last-reading', selectedNetwork],
		queryFn: () => chartsService.getNetworkCommercialLastDate(selectedNetwork),
		enabled: !!selectedNetwork
	});

	// get network cumulative readings count per month
	const {
		data: cumulativeMonthlyReadingsCount,
		isLoading: loadingCumulativeMonthlyReadingsCount,
		isFetching: fetchingCumulativeMonthlyReadingsCount
	} = useQuery<any>({
		queryKey: ['cumulative-monthly-readings-count', selectedNetwork, cumulativeReadingsDateCount],
		queryFn: () =>
			chartsService.getNetworkCumulativeMonthlyReadingsCount(selectedNetwork, {
				month: cumulativeReadingsDateCount
					? dayjs(cumulativeReadingsDateCount.start).startOf('M').format('YYYY-MM-DD')
					: dayjs(lastReadingsDate?.date).startOf('M').format('YYYY-MM-DD')
			}),

		enabled: !!selectedNetwork && (!!cumulativeReadingsDateCount || !!lastReadingsDate)
	});

	// loading network commercial meter group cumulative readings
	const {
		data: commercialCumulativeReadings,
		isLoading: loadingCommercialCumulativeReadings,
		isFetching: fetchingCommercialCumulativeReadings
	} = useQuery<any>({
		queryKey: ['network-commercial-cumulative-readings', selectedNetwork, cumulativeReadingsDate, lastReadingsDate],
		queryFn: () =>
			chartsService.getNetworkCommercialCumulativeReadings(selectedNetwork, {
				month: cumulativeReadingsDate?.start
					? dayjs(cumulativeReadingsDate.start).format('YYYY-MM-DD')
					: lastReadingsDate?.date && dayjs(lastReadingsDate?.date).format('YYYY-MM-DD')
			}),

		enabled: !!selectedNetwork && (!!cumulativeReadingsDate || !!lastReadingsDate)
	});

	// loading network commercial meter group cumulative readings
	const {
		data: commercialCumulativeAvgReadings,
		isLoading: loadingCommercialCumulativeAvgReadings,
		isFetching: fetchingCommercialCumulativeAvgReadings
	} = useQuery<any>({
		queryKey: [
			'network-commercial-cumulative-avg-readings',
			selectedNetwork,
			cumulativeAvgReadingsDate,
			lastReadingsDate
		],
		queryFn: () =>
			chartsService.getNetworkCommercialCumulativeAvgReadings(selectedNetwork, {
				month: cumulativeAvgReadingsDate?.start
					? dayjs(cumulativeAvgReadingsDate.start).format('YYYY-MM-DD')
					: lastReadingsDate?.date && dayjs(lastReadingsDate?.date).format('YYYY-MM-DD')
			}),

		enabled: !!selectedNetwork && (!!cumulativeAvgReadingsDate || !!lastReadingsDate)
	});

	return (
		<Row gutter={[16, 16]}>
			<Col xs={12}>
				<Spin
					spinning={
						(loadingCumulativeMonthlyReadingsCount && fetchingCumulativeMonthlyReadingsCount) ||
						loadingFirstReadingsDate ||
						loadingLastReadingsDate
					}
				>
					<CumulativeReadingsPieChart
						cumulativeReadings={cumulativeMonthlyReadingsCount && cumulativeMonthlyReadingsCount.data}
						OnDateSelect={(date: any) => setCumulativeReadingsDateCount(date)}
						lastReadingDate={lastReadingsDate && lastReadingsDate.date}
						title={cumulativeMonthlyReadingsCount?.name}
					/>
				</Spin>
			</Col>
			<Col xs={12}>
				<Spin
					spinning={
						(loadingCommercialCumulativeAvgReadings && fetchingCommercialCumulativeAvgReadings) ||
						loadingFirstReadingsDate ||
						loadingLastReadingsDate
					}
				>
					<CumulativeAvgReadingCardsChart
						data={commercialCumulativeAvgReadings}
						OnDateSelect={(date: any) => setCumulativeAvgReadingsDate(date)}
						lastReadingDate={lastReadingsDate && lastReadingsDate.date}
					/>
				</Spin>
			</Col>
			<Col xs={24}>
				<Spin
					spinning={
						(loadingCommercialCumulativeReadings && fetchingCommercialCumulativeReadings) ||
						loadingFirstReadingsDate ||
						loadingLastReadingsDate
					}
				>
					<CumulativeReadingsCardsChart
						cumulativeReadings={commercialCumulativeReadings}
						OnDateSelect={(date: any) => setCumulativeReadingsDate(date)}
						lastReadingsDate={lastReadingsDate && lastReadingsDate.date}
						firstReadingsDate={firstReadingsDate && firstReadingsDate.date}
						title={t('NetworkCommercialMerterGroupsMonthlyCumulativeReadings')}
					/>
				</Spin>
			</Col>
		</Row>
	);
};
