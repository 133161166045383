import { HTMLAttributes, useContext } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { WaiBarChart, WaiPieChart } from '.';
import { GraphsCarouselContainer, WaiCarousel } from '../components/carousel/styles/carousel';
import configProxy from '../config/config';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import { useMedia } from '../hooks/use-media.hook';
import { selectedChartConfigObject } from '../pages/monitoring';
import { SpanType } from '../types';
import { ChartCustomSpan, chartTypes, IChart } from '../types/charts';
import { clearArrayUndefined } from '../utils/clean-array-undefined';
import { WaiNumericalChart } from './numerical-chart';
import { WaiTimeSeriesChart } from './wai-time-series';
import { Spin } from 'antd';

export const GraphsCarousel: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		loadingCharts: boolean;
		charts?: IChart[];
		selectedChartInfo?: selectedChartConfigObject;
		onLineChartSpanChange?: (id?: string, span?: ChartCustomSpan, name?: any) => void;
		onChartSelect?: (fieldName?: string, coloredData?: any[], is_valve?: boolean) => void;
	}
> = ({ loadingCharts, charts, onLineChartSpanChange, onChartSelect, selectedChartInfo, ...restProps }) => {
	const { t, i18n } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { onMeasurementPointSelect } = navigationContext;
	const xxl = useMedia('xxl');
	const md = useMedia('md');
	const media = configProxy.defaultExpandScreenSize;
	const desktopView = useMedia(media);

	const measurementToUnitMapper = {
		[t('Pressure')]: t('Bar'),
		[t('flowRate')]: t('CMH'),
		[t('Noise')]: ''
	};

	return charts && charts.length ? (
		<GraphsCarouselContainer englishSelected={i18n.language === 'en'} {...restProps}>
			<Spin spinning={loadingCharts}>
				<WaiCarousel
					dots={false}
					arrows
					prevArrow={<BsChevronLeft size={20} />}
					nextArrow={<BsChevronRight size={20} />}
					slidesToShow={Math.min(charts.length, xxl ? 3 : md ? 2 : 1)}
					slidesToScroll={xxl ? 3 : md ? 2 : 1}
				>
					{charts.map((chart: IChart, idx: number) =>
						chart.type === chartTypes.PIE && chart.data && chart.data.length ? (
							<WaiPieChart
								title={chart.title}
								data={chart.data}
								key={idx}
								dataKeys={chart.dataKeys}
								analysisRedirectUri={chart?.analysisRedirectUri}
								colorPie={chart.colorPie}
								height={15}
								emphasizable={chart.emphasizable}
								fieldName={chart.fieldName}
								emphasized={chart.fieldName === selectedChartInfo?.fieldName}
								maxWidth={desktopView ? '98%' : '98%'}
								onChartSelect={(fieldName, coloredData) => {
									// onMeasurementPointSelect(undefined);
									onChartSelect && onChartSelect(fieldName, coloredData, chart?.is_valve || false);
								}}
							/>
						) : chart.type === chartTypes.BAR && chart.data && chart.data.length ? (
							<WaiBarChart
								emphasizable={chart.emphasizable}
								fieldName={chart.fieldName}
								title={chart.title}
								analysisRedirectUri={chart?.analysisRedirectUri}
								data={chart.data || []}
								key={idx}
								emphasized={chart.fieldName === selectedChartInfo?.fieldName}
								dataKeys={chart.dataKeys}
								maxWidth="98%"
								height={15}
								onChartSelect={(fieldName, coloredData) => {
									// onMeasurementPointSelect(undefined);
									onChartSelect && onChartSelect(fieldName, coloredData, chart?.is_valve || false);
								}}
							/>
						) : chart.type === chartTypes.LINE ? (
							<WaiTimeSeriesChart
								key={idx}
								dateText={chart?.dateText}
								IDs={
									chart.id?.split(',')
										? chart.id?.split(',').map((id, idx) => {
												return {
													id: id,
													name: idx === 0 ? chart.title : chart.titleSecond
												};
										  })
										: [
												{
													id: chart.id,
													name: chart.name
												}
										  ]
								}
								flags={chart.flags}
								maxWidth="98%"
								height={15}
								graphHeight={chart?.dateText ? 145 : 190}
								showBrush={false}
								analysisRedirectUri={chart?.analysisRedirectUri}
								showTabular
								onSpanSelect={(span: ChartCustomSpan) => {
									// onMeasurementPointSelect(undefined);
									onLineChartSpanChange && onLineChartSpanChange(chart.id, span, chart.title);
								}}
								title={chart.title === t('upstream') ? t('valvePressure') : chart.title}
								lastReadingDate={chart.lastReadingDate}
								dataObjects={clearArrayUndefined([
									{
										id: chart?.id ?? chart.title,
										name: chart.title,
										data: chart.data || [],
										allData: chart.allData || [],
										unit: chart.unit === t('UNITLESS') ? '' : chart.unit,
										yAxis: {
											position: 'left',
											legend: t('legendTitleUnit', {
												title: chart.legend || chart.title,
												in: chart.unit === t('UNITLESS') ? '' : t('in'),
												name: chart.unit === t('UNITLESS') ? '' : chart.unit
											})
										}
									},
									chart?.dataSecond && {
										id: chart?.titleSecond,
										name: chart?.titleSecond,
										data: chart.dataSecond || [],
										allData: chart.allData || [],
										unit: chart?.unitSecond
											? chart.unitSecond === t('UNITLESS')
												? ''
												: chart.unitSecond
											: chart.unit === t('UNITLESS')
											? ''
											: chart.unit
									}
								])}
								showSpanSelector={chart?.showSpanSelector || false}
								showCustomSpanSelector={false}
								spanType={chart?.spanType}
								sourceName={chart?.sourceName}
								deactivated={chart?.deactivated}
								deactivatedMsg={chart?.deactivatedMsg}
								linkToActivate={chart?.linkToActivate}
								accessToActivateChart={chart?.accessToActivateChart}
							/>
						) : chart.type === chartTypes.NUMERICAL ? (
							<WaiNumericalChart
								key={idx}
								changeDailyData={chart?.changeDailyData}
								IDs={[
									{
										id: chart.id,
										name: chart.name
									}
								]}
								flags={chart?.flags}
								number={chart?.data && chart.data[0]}
								dateText={chart.dateText || ''}
								title={chart?.title}
								height={15}
								analysisRedirectUri={chart?.analysisRedirectUri}
								unit={chart?.unit}
								maxWidth="98%"
								countRate={chart.countRate || false}
								dataObjects={chart.allData || []}
								showTabular={chart.showTabular || false}
								showCustomSelectorDate={chart.showCustomSelectorDate || false}
								percentage={chart.percentage || undefined}
								increase={chart?.increase}
								positive={chart?.positive}
								lastReadingDate={chart.lastReadingDate}
								spanType={chart?.spanType || SpanType.DAY}
								sourceName={chart?.sourceName}
								deactivated={chart?.deactivated}
								deactivatedMsg={chart?.deactivatedMsg}
								linkToActivate={chart?.linkToActivate}
								accessToActivateChart={chart?.accessToActivateChart}
							/>
						) : null
					)}
				</WaiCarousel>
			</Spin>
		</GraphsCarouselContainer>
	) : null;
};
