import { Card, Col, List, Popover, Row, Statistic } from 'antd';
import dayjs from 'dayjs';
import { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { BsQuestion } from 'react-icons/bs';
import { Disabled, GraphCard } from '../components';
import { colors } from '../constants/colors';
import { SpanType } from '../types';

export const CumulativeAvgReadingCardsChart: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		data: any;
		lastReadingDate: any;
		OnDateSelect: (date?: any) => void;
	}
> = ({ data, lastReadingDate, OnDateSelect, ...restProps }) => {
	const { t } = useTranslation();
	const [displayDate, setDisplayDate] = useState<any>();

	return (
		<Row gutter={[16, 16]}>
			<Col xs={24}>
				<GraphCard
					height={25}
					title={data?.name ?? t('CumulativeReadings')}
					lastReadingsDates={{
						month: lastReadingDate
					}}
					setDisplayDate={setDisplayDate}
					displayDate={displayDate}
					OnDateSelect={OnDateSelect}
					spanType={SpanType.MONTH}
					hint={data?.hint}
					showCustomSelectorDate
					{...restProps}
				>
					<div className="d-flex justify-content-between align-items-center" style={{ height: '80%' }}>
						<div>
							<AiOutlineLeft
								size={'2rem'}
								style={{ strokeWidth: '30px' }}
								color={colors.GRAPH_PURPLE}
								className={'mb-4'}
								role={'button'}
								onClick={() => {
									OnDateSelect &&
										OnDateSelect({
											start: displayDate
												? dayjs(displayDate).subtract(1, 'M').startOf('M').format('YYYY-MM-DD')
												: dayjs(lastReadingDate)
														.subtract(1, 'M')
														.startOf('M')
														.format('YYYY-MM-DD')
										});
									setDisplayDate(
										displayDate
											? dayjs(displayDate).subtract(1, 'M').format('YYYY-MM-DD')
											: dayjs(lastReadingDate).subtract(1, 'M').format('YYYY-MM-DD')
									);
								}}
							/>
						</div>

						<div
							style={{
								overflowY: 'auto',
								maxHeight: '44rem',
								overflowX: 'hidden'
							}}
						>
							{data ? (
								<Row gutter={[16, 16]}>
									<Col xs={24}>
										<Card bordered={false} style={{ height: '100%' }} className="text-center">
											<Row gutter={[16, 16]} className="d-flex justify-content-between">
												<Col xs={12}>
													<Popover
														title={
															<List
																size="small"
																header={<div>{t('MonthsCalculated')}</div>}
																bordered
																dataSource={data?.months_with_readings.map(month => {
																	return (
																		<p style={{ fontWeight: 'lighter' }}>
																			{dayjs(month).format('MMM YYYY')}
																		</p>
																	);
																})}
																renderItem={(item: any) => (
																	<List.Item>{item}</List.Item>
																)}
																locale={{ emptyText: t('NoMonthsCalculated') }}
															/>
														}
													>
														<Statistic
															title={
																<>
																	<BsQuestion
																		style={{ transform: 'scaleX(-1)' }}
																		color={colors.INACTIVE}
																		className={'mx-1'}
																	/>
																	{t('متوسط')}
																</>
															}
															value={`${(data?.average).toFixed(3)}${data?.unit}`}
														/>
													</Popover>
												</Col>
												<Col xs={12}>
													<Statistic
														title={t('TotalMonth')}
														value={`${(data?.total).toFixed(3)}${data?.unit}`}
													/>
												</Col>
												<Col xs={12}>
													<Statistic
														title={t('NumberOfCustomers')}
														value={`${data?.number_of_customers}`}
													/>
												</Col>
												<Col xs={12}>
													<Statistic
														title={t('NumberOfUnits')}
														value={`${data?.number_of_units}`}
													/>
												</Col>
											</Row>
										</Card>
									</Col>
								</Row>
							) : null}
						</div>
						<div>
							{(displayDate ? dayjs(displayDate).endOf('M') : dayjs(lastReadingDate).endOf('M')) >=
							dayjs().endOf('M') ? (
								<Disabled tooltipTitle={t('CannotChooseFutureDate', { ns: 'validation' })}>
									<AiOutlineRight
										size={'2rem'}
										style={{ strokeWidth: '30px' }}
										color={colors.GRAPH_PURPLE}
										className={'mb-4'}
										role={'button'}
										onClick={() => {
											OnDateSelect &&
												OnDateSelect({
													start: displayDate
														? dayjs(displayDate)
																.add(1, 'M')
																.startOf('M')
																.format('YYYY-MM-DD')
														: dayjs(lastReadingDate)
																.add(1, 'M')
																.startOf('M')
																.format('YYYY-MM-DD')
												});
											setDisplayDate(
												displayDate
													? dayjs(displayDate).add(1, 'M').format('YYYY-MM-DD')
													: dayjs(lastReadingDate).add(1, 'M').format('YYYY-MM-DD')
											);
										}}
									/>
								</Disabled>
							) : (
								<AiOutlineRight
									size={'2rem'}
									style={{ strokeWidth: '30px' }}
									color={colors.GRAPH_PURPLE}
									className={'mb-4'}
									role={'button'}
									onClick={() => {
										OnDateSelect &&
											OnDateSelect({
												start: displayDate
													? dayjs(displayDate).add(1, 'M').startOf('M').format('YYYY-MM-DD')
													: dayjs(lastReadingDate)
															.add(1, 'M')
															.startOf('M')
															.format('YYYY-MM-DD')
											});
										setDisplayDate(
											displayDate
												? dayjs(displayDate).add(1, 'M').format('YYYY-MM-DD')
												: dayjs(lastReadingDate).add(1, 'M').format('YYYY-MM-DD')
										);
									}}
								/>
							)}
						</div>
					</div>
				</GraphCard>
			</Col>
		</Row>
	);
};
