import { useQueries } from '@tanstack/react-query';
import L, { circleMarker, LatLngExpression, Map } from 'leaflet';
import { HTMLAttributes, useContext, useEffect, useState } from 'react';
import { CircleMarker, GeoJSON, MapContainer, Marker, Popup, TileLayer, useMap, ZoomControl } from 'react-leaflet';
import valveError from '../assets/img/valve-error.png';
import valveHealthy from '../assets/img/valve-healthy.png';
import valveInactive from '../assets/img/valve-inactive.png';
import valveWarning from '../assets/img/valve-warning.png';
import stationError from '../assets/img/station-error.svg';
import stationHealthy from '../assets/img/station-healthy.svg';
import stationInactive from '../assets/img/station-inactive.svg';
import stationWarning from '../assets/img/station-warning.svg';
import commercialMeterGroupHealthy from '../assets/img/commercial-meter-group-healthy.svg';
import commercialMeterGroupInactive from '../assets/img/commercial-meter-group-inactive.svg';
import { MPInfoNew } from '../components';
import configProxy from '../config/config';
import { colors } from '../constants/colors';
import { useTranslation } from 'react-i18next';
import { useMedia } from '../hooks/use-media.hook';
import { NavigationContext } from '../contexts/navigation.context';
import monitoringService from '../services/monitoring.service';
import { GISLayerType } from '../types/gis';
import { ValveOperationStatus } from '../types/valve';
import { StationOperationalStatus } from '../types/water-station';
import { Zones } from '../types';
import { useQueryParam } from '../hooks/use-query';

const valveOperationStatusUrlMapper = {
	[ValveOperationStatus.NORMAL]: valveHealthy,
	[ValveOperationStatus.NEEDS_INVESTIGATION]: valveWarning,
	[ValveOperationStatus.OPERATION_ERROR]: valveError,
	[ValveOperationStatus.DATA_PROBLEM]: valveError,
	[ValveOperationStatus.OTHER]: valveInactive
};
const stationOperationStatusUrlMapper = {
	[StationOperationalStatus.NORMAL]: stationHealthy,
	[StationOperationalStatus.NEEDS_INVESTIGATION]: stationWarning,
	[StationOperationalStatus.OPERATION_ERROR]: stationError,
	[StationOperationalStatus.OTHER]: stationInactive
};

const valveIcon = (status: ValveOperationStatus, size: [number, number]) => {
	return L.icon({
		iconUrl: valveOperationStatusUrlMapper[status],
		iconSize: size
	});
};
const stationIcon = (status: StationOperationalStatus, size: [number, number]) => {
	return L.icon({
		iconUrl: stationOperationStatusUrlMapper[status],
		iconSize: size
	});
};
const commercialCommercialMeterGroupIcon = (status: any, size: [number, number]) => {
	return L.icon({
		iconUrl: status ? commercialMeterGroupHealthy : commercialMeterGroupInactive,
		iconSize: size
	});
};

const markerIcon = new L.Icon({
	iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	tooltipAnchor: [16, -28]
});

export const MapSummary: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		networks?: any;
		selectedNetwork?: any;
		zones?: any;
		selectedZone?: any;
		selectedMeasurementPoint?: string;
		pointMarker?: LatLngExpression;
		style?: any;
	}
> = ({ networks, selectedNetwork, zones, selectedZone, selectedMeasurementPoint, pointMarker, style }) => {
	const { i18n } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { measurementPoints } = navigationContext;

	const xxl = useMedia('xxl');
	const desktopView = useMedia(configProxy.defaultExpandScreenSize);

	const [circleMarkerRadius, setCircleMarkerRadius] = useState<number>(5);
	const [networkToShow, setNetworkToShow] = useState<any>();
	const [zonesToShow, setZonesToShow] = useState<any>([]);
	const [zoneBoundaryGisLayers, setZoneBoundaryGisLayers] = useState<any>([]);
	const [viewProperties, setViewProperties] = useState<any>({ location: [26.820553, 30.802498], zoom: 5 });
	const [mapInstance, setMapInstance] = useState<Map>();

	const [valveIconSize, setValveIconSize] = useState<[number, number]>([10, 10]);
	const [stationIconSize, setStationIconSize] = useState<[number, number]>([10, 10]);
	const [commercialMeterIconSize, setCommercialMeterIconSize] = useState<[number, number]>([10, 10]);

	const selectedZoneQuery = useQueryParam('zone');

	const MapInstance = () => {
		const mapInstance = useMap();
		setMapInstance(mapInstance);
		return null;
	};

	// set view for the map
	useEffect(() => {
		mapInstance && mapInstance.setView(viewProperties.location, viewProperties.zoom, { animate: true });
	}, [viewProperties.location, viewProperties.zoom]);

	// resize sites radius when zooming
	mapInstance?.on('zoomend', () => {
		const currentZoom = mapInstance.getZoom();
		if (currentZoom >= 16) {
			setCircleMarkerRadius(15);
			setValveIconSize([80, 80]);
			setStationIconSize([40, 40]);
			setCommercialMeterIconSize([50, 50]);
		} else if (currentZoom >= 15) {
			setCircleMarkerRadius(8);
			setValveIconSize([70, 70]);
			setStationIconSize([35, 35]);
			setCommercialMeterIconSize([45, 45]);
		} else if (currentZoom >= 14) {
			setCircleMarkerRadius(5);
			setValveIconSize([60, 60]);
			setStationIconSize([30, 30]);
			setCommercialMeterIconSize([40, 40]);
		} else if (currentZoom >= 11) {
			setCircleMarkerRadius(3);
			setValveIconSize([40, 40]);
			setStationIconSize([20, 20]);
			setCommercialMeterIconSize([30, 30]);
		} else {
			setCircleMarkerRadius(2);
			setValveIconSize([30, 30]);
			setStationIconSize([15, 15]);
			setCommercialMeterIconSize([20, 20]);
		}
	});

	// Loading all gis layers for selected netorks zone or selected zone
	const zonesGisLayers = useQueries<any>({
		queries: (zonesToShow || []).map((zone: any) => {
			return {
				queryKey: ['gis-layers', zone.id],
				queryFn: () => monitoringService.getZoneGisLayers(zone.id),
				enabled: !!zone
			};
		})
	});

	// get selected network info
	useEffect(() => {
		if (networks && networks.length && selectedNetwork)
			setNetworkToShow(networks.find((n: any) => n.id === selectedNetwork));
	}, [networks, selectedNetwork]);

	// get selected zones info
	useEffect(() => {
		if (selectedZone) setZonesToShow(zones?.filter((zone: any) => zone.id === selectedZone));
		else setZonesToShow(zones);
	}, [zones, selectedZone]);

	// add location and zoom level for the map
	useEffect(() => {
		(async () => {
			let location: LatLngExpression;
			if (networkToShow) {
				location = [networkToShow.latitude, networkToShow.longitude];
				await setViewProperties({ location, zoom: 13 });
			}
		})();
	}, [networkToShow]);

	// get zones boundaries
	useEffect(() => {
		if (zonesToShow && zonesToShow.length > 0) {
			if (zonesGisLayers && zonesGisLayers.every(zone => zone.isSuccess)) {
				const gisLayers = configureZonesGisLayers(
					zonesToShow,
					zonesGisLayers.map(zone => {
						return zone.data;
					})
				);
				gisLayers && setZoneBoundaryGisLayers(gisLayers.filter((l: any) => l.type === GISLayerType.BOUNDARY));
			}
		}
	}, [zonesToShow, zonesGisLayers && zonesGisLayers.every(zone => zone.isSuccess)]);

	// extract gis layers from areas (zone or network)
	const configureZonesGisLayers = (zones: any[], zonesGisLayers: any[]) => {
		return zones.reduce((agg: any, zone: any, idx: number) => {
			if (zonesGisLayers[idx] && zonesGisLayers[idx].length) {
				let layers = zonesGisLayers[idx];
				layers = layers.map((l: any) => {
					l.zoneName = i18n.language === 'en' ? zone.name_en : zone?.name_ar || zone.name_en;
					return l;
				});
				return [...agg, ...layers];
			} else return agg;
		}, []);
	};

	// focus on selecting MP and return MP info when select any of MPs
	const renderSelectedMeasurementPoints = (point: any, selectedMeasurementPoints?: any[]) => {
		let radius = circleMarkerRadius;
		let selectedColor = colors.GRAPH_PURPLE;
		if (selectedMeasurementPoints && selectedMeasurementPoints.length) {
			return selectedMeasurementPoints.map((mp: any, idx: number) => {
				//NOTE: change this if needed to commercial site
				if (mp.measurementPointId === point.id && point.type !== Zones.COMMERCIAL_METER) {
					selectedColor = mp.color || colors.GRAPH_PURPLE;
					radius = mp.radius || 10;
					return (
						<CircleMarker
							key={idx}
							center={[point.latitude, point.longitude]}
							radius={radius}
							pathOptions={{
								color: selectedColor,
								fillOpacity: 0
							}}
						>
							<Popup minWidth={320}>
								<MPInfoNew measurementPoint={point} />
							</Popup>
						</CircleMarker>
					);
				}
			});
		}
		return null;
	};

	return xxl === undefined ? null : (
		<MapContainer
			center={viewProperties.location}
			zoom={viewProperties.zoom}
			doubleClickZoom
			scrollWheelZoom={desktopView}
			style={style || { height: '100vh', width: xxl ? '88.7vw' : '100vw', zIndex: 50, position: 'fixed' }}
			zoomControl={false}
		>
			<ZoomControl position={i18n.language === 'en' ? 'topleft' : 'topright'} />
			{zoneBoundaryGisLayers && zoneBoundaryGisLayers.length
				? zoneBoundaryGisLayers.map((layer: any) => {
						return (
							<GeoJSON
								key={layer.id}
								data={layer.geojson}
								pathOptions={{ color: colors.PURPLE2 }}
								pointToLayer={(_, latlng) =>
									circleMarker(latlng, { radius: 2, color: colors.BLACK, fillColor: colors.BLACK })
								}
								eventHandlers={{
									mouseover: e => {
										const popup = L.popup({
											minWidth: 320
										}).setContent(layer.zoneName);

										e.target.bindPopup(popup).openPopup(e.latlng);
									},
									mouseout: e => {
										e.target.closePopup();
									}
								}}
							>
								<Popup minWidth={320}>{layer.zoneName}</Popup>
							</GeoJSON>
						);
				  })
				: null}
			{zones?.length && !selectedZoneQuery
				? zones
						.filter(zone => zone.type === Zones.COMMERCIAL_METER)
						.map((zone: any) => {
							return (
								<div key={zone.id}>
									<Marker
										position={[zone.latitude, zone.longitude]}
										icon={commercialCommercialMeterGroupIcon(
											zone.is_active,
											commercialMeterIconSize
										)}
										eventHandlers={{
											mouseover: e => {
												const popup = L.popup({
													minWidth: 320
												}).setContent(zone.name_en);

												e.target.bindPopup(popup).openPopup(e.latlng);
											},
											mouseout: e => {
												e.target.closePopup();
											}
										}}
									/>
								</div>
							);
						})
				: null}
			{measurementPoints?.length
				? measurementPoints.map((point: any) =>
						point.valve !== null && point.valve !== undefined ? (
							<div key={point.id}>
								<Marker
									position={[point.latitude, point.longitude]}
									icon={valveIcon(point.valve.operation_status, valveIconSize)}
								>
									<Popup minWidth={320}>
										<MPInfoNew measurementPoint={point} />
									</Popup>
								</Marker>
								{renderSelectedMeasurementPoints(
									point,
									selectedMeasurementPoint ? [{ measurementPointId: selectedMeasurementPoint }] : []
								)}
							</div>
						) : point.water_station !== null && point.water_station !== undefined ? (
							<div key={point.id}>
								<Marker
									position={[point.latitude, point.longitude]}
									icon={stationIcon(point.water_station.operational_status, stationIconSize)}
								>
									<Popup minWidth={320}>
										<MPInfoNew measurementPoint={point} />
									</Popup>
								</Marker>
								{renderSelectedMeasurementPoints(
									point,
									selectedMeasurementPoint ? [{ measurementPointId: selectedMeasurementPoint }] : []
								)}
							</div>
						) : (point.valve === null || point.valve !== undefined) &&
						  (point.water_station === null || point.water_station !== undefined) ? (
							<div key={point.id}>
								<CircleMarker
									center={[point.latitude, point.longitude]}
									radius={circleMarkerRadius}
									pathOptions={{
										color: point.is_active ? colors.HEALTHY : colors.GREY,
										fillColor: point.is_active ? colors.HEALTHY : colors.GREY,
										fillOpacity: 1
									}}
								>
									<Popup minWidth={320} autoClose={false}>
										<MPInfoNew measurementPoint={point} />
									</Popup>
								</CircleMarker>
								{renderSelectedMeasurementPoints(
									point,
									selectedMeasurementPoint ? [{ measurementPointId: selectedMeasurementPoint }] : []
								)}
							</div>
						) : null
				  )
				: null}

			{pointMarker ? <Marker icon={markerIcon} position={pointMarker} /> : null}

			<MapInstance />
			<TileLayer attribution={configProxy.map_attribution} url={configProxy.map_tile_url} />
		</MapContainer>
	);
};
