import { Col, Empty, Row, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useQuery, useQueries } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import {
	FlowRateType,
	SpanType,
	customSpanSelector,
	GraphSpan,
	UserRights,
	UserApps,
	DataSources,
	PressureUnit
} from '../types';
import dayjs from 'dayjs';
import { stdev, mean } from 'stats-lite';
import { ChartStats } from '../enum';
import { FlowRateUnit } from '../types/flow-rate-unit';
import { clearArrayUndefined } from '../utils/clean-array-undefined';
import { WaiNumericalChart } from './numerical-chart';
import { TimeSeriesBar } from './time-series-bar';
import { colors } from '../constants/colors';
import { capitalizeWords } from '../utils/capitalize-string-words';
import { AppRoutes } from '../constants/routes';
import { dateToQuery, hasRight, mostRecentDate } from '../utils';
import { AuthenticationContext } from '../contexts';
import chartsService from '../services/charts.service';
import configService from '../services/config.service';
import { WaterProductionReadingsCards } from './water-production-readings-tab';
import { Sites } from '../types/sites';
import { WaiTimeSeriesChart } from './wai-time-series';
import monitoringService from '../services/monitoring.service';
import { TimeSeriesBarGrouped } from './time-series-bar-grouped';

export const ZoneAnalysisWaterProductionTab: React.FC<any> = ({ zoneName }) => {
	const { t, i18n } = useTranslation();

	const [chartDateWaterProduction, setChartDateWaterProduction] = useState<{ start?: string; date?: string }>();
	const [chartTimeSpanWaterProduction, setChartTimeSpanWaterProduction] = useState<{
		start: string;
		end: string;
	}>();
	const [dailyWaterProductionSiteChartTimeSpan, setDailyWaterProductionSiteChartTimeSpan] = useState<{
		date: string;
	}>({
		date: dayjs().format('YYYY-MM-DD')
	});
	const [pressureChartTimeSpan, setPressureChartTimeSpan] = useState<{ start: string; end: string }>({
		start: dateToQuery(dayjs().subtract(7, 'day')),
		end: dateToQuery(dayjs())
	});
	const [waterMeterLogsChartTimeSpan, setWaterMeterLogsChartTimeSpan] = useState<{ start: string; end: string }>({
		start: dateToQuery(dayjs().subtract(7, 'day')),
		end: dateToQuery(dayjs())
	});
	const [chartTimeSpchanWaterProductionBreakout, setChartTimeSpanWaterProductionBreakout] = useState<{
		start?: string;
		date?: string;
	}>({
		date: dayjs().format('YYYY-MM-DD')
	});
	const [spanType, setSpanType] = useState<SpanType>(SpanType.DAY);
	const [pressureDataSources, setPressureDataSources] = useState<any>([]);

	const navigationContext = useContext(NavigationContext);
	const { selectedZone, zones, measurementPoints, applicationIdMap } = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user, configurationPermessions } = authContext;

	//TODO: will use it later when added water production module in zone configuration
	// load selected zone flow analysis configuration
	const { data: zoneFlowAnalysisConfig, isLoading: loadingZoneFlowAnalysisConfig } = useQuery({
		queryKey: ['zone-flow-analysis-config', selectedZone],
		queryFn: () => selectedZone && configService.getZoneFlowAnalysisConfig(selectedZone),
		enabled: !!selectedZone
	});

	// Last water production readings for day, week, month
	const waterProductionionLastDates: any = useQueries<any>({
		queries: (Object.keys(customSpanSelector) as (keyof typeof customSpanSelector)[]).map((span: any) => {
			return {
				queryKey: ['water-production-last-reading', selectedZone, span],
				queryFn: () =>
					chartsService.getZoneDailyProduction(selectedZone, {
						last: true,
						span_type: span.toLowerCase()
					}),
				enabled: !!selectedZone && !!span
			};
		})
	});

	// All water production readings regarding the days period
	const { data: waterProductionAllData } = useQuery<any>({
		queryKey: ['water-production-all-data', selectedZone],
		queryFn: () =>
			chartsService.getZoneDailyProduction(selectedZone, {
				span_type: 'day'
			}),
		enabled: !!selectedZone
	});

	// Load daily sites water production daily readings chart
	const dailySitesWaterProductionReadings = useQueries<any>({
		queries: (measurementPoints || [])
			.filter(site => site.type === Sites.WATER_STATION)
			.map((site: any) => {
				return {
					queryKey: [
						'site-daily-water-production-readings',
						site.id,
						dailyWaterProductionSiteChartTimeSpan?.date
					],
					queryFn: () => {
						if (dailyWaterProductionSiteChartTimeSpan?.date === dayjs().format('YYYY-MM-DD')) {
							// Load sites water production current readings
							return chartsService.getSiteCurrentWaterProductionReading(site?.id);
						} else {
							return chartsService.getSiteDailyProduction(site.id, {
								start:
									dailyWaterProductionSiteChartTimeSpan?.date.length &&
									dayjs(dailyWaterProductionSiteChartTimeSpan.date).format('YYYY-MM-DD'),
								span_type: SpanType.DAY
							});
						}
					},

					enabled: !!site.id && !!selectedZone && !!dailyWaterProductionSiteChartTimeSpan?.date
				};
			})
	});

	// Water production reading relative to numerical chart
	const {
		data: waterProductionDateChart,
		isLoading: loadingWaterDate,
		isFetching: fetchingWaterDate
	} = useQuery<any>({
		queryKey: [
			'water-production-data',
			selectedZone,
			spanType,
			chartDateWaterProduction,
			waterProductionionLastDates[Object.values(SpanType).indexOf(spanType)]?.data
		],
		queryFn: () =>
			chartsService.getZoneDailyProduction(selectedZone, {
				start:
					spanType === SpanType.DAY
						? undefined
						: chartDateWaterProduction?.start
						? dayjs(chartDateWaterProduction.start).format('YYYY-MM-DD')
						: waterProductionionLastDates[Object.values(SpanType).indexOf(spanType)]?.data?.start &&
						  dayjs(
								waterProductionionLastDates[Object.values(SpanType).indexOf(spanType)]?.data?.start
						  ).format('YYYY-MM-DD'),
				date:
					spanType !== SpanType.DAY
						? undefined
						: chartDateWaterProduction?.date
						? dayjs(chartDateWaterProduction.date).format('YYYY-MM-DD')
						: waterProductionionLastDates[0]?.data?.data?.length
						? dayjs(waterProductionionLastDates[0]?.data?.data[0]?.time).format('YYYY-MM-DD')
						: dayjs().subtract(1, 'days').startOf(spanType).format('YYYY-MM-DD'),
				span_type: spanType
			}),
		enabled: (!!chartDateWaterProduction || !!waterProductionionLastDates) && !!selectedZone && !!spanType
	});

	// water production readings relative to bar chart over a period of days
	const {
		data: waterProductionTimeSpanChart,
		isLoading: loadingWaterChart,
		isFetching: fetchingWaterChart
	} = useQuery<any>({
		queryKey: ['water-production-data', chartTimeSpanWaterProduction, selectedZone],
		queryFn: () =>
			chartsService.getZoneDailyProduction(selectedZone, {
				start: chartTimeSpanWaterProduction
					? dayjs(chartTimeSpanWaterProduction.start).format('YYYY-MM-DD')
					: dayjs().subtract(8, 'days').format('YYYY-MM-DD'),
				end: chartTimeSpanWaterProduction
					? dayjs(chartTimeSpanWaterProduction.end).format('YYYY-MM-DD')
					: dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
				span_type: 'day'
			}),
		enabled: !!selectedZone
	});

	// Pressure sensors data sources chart:
	// 1. Loading all data sources for selected zone
	const { data: zoneDataSources } = useQuery<any>({
		queryKey: ['data-sources', selectedZone],
		queryFn: () => monitoringService.getDataSources({ filters: { zones: selectedZone } }),
		enabled: !!selectedZone
	});
	// 2. Get zone pressure data sources
	useEffect(() => {
		if (zoneDataSources) {
			const dataSourcesPressure = clearArrayUndefined(
				zoneDataSources
					.filter(source => source.type === DataSources.PRESSURE)
					.filter(source => {
						return measurementPoints.some(
							site => site.id === source.measurement_point && site.type === 'water_station'
						);
					})
			);
			setPressureDataSources(dataSourcesPressure);
		}
	}, [zoneDataSources]);
	// 3. Last zone pressure data sources readings
	const pressureLastReadings = useQueries<any>({
		queries: (pressureDataSources || []).map((dataSource: any) => {
			return {
				queryKey: ['data-source-last-log-date', dataSource.id],
				queryFn: () => chartsService.getDataSourceLastLogDate(dataSource.id),
				enabled: !!dataSource.id && !!selectedZone
			};
		})
	});
	// 4. Zone pressures data sources readings relative to pressureChartTimeSpan
	const pressures = useQueries<any>({
		queries: (pressureDataSources || []).map((dataSource: any) => ({
			queryKey: ['data-source-logs', dataSource.id, pressureChartTimeSpan],
			queryFn: () =>
				chartsService.getDSLogs(dataSource.id, {
					start: pressureChartTimeSpan?.start.length ? pressureChartTimeSpan?.start : undefined,
					end: pressureChartTimeSpan?.end.length ? pressureChartTimeSpan?.end : undefined,
					aggregate: pressureChartTimeSpan?.start.length
						? dayjs(pressureChartTimeSpan?.end).diff(pressureChartTimeSpan?.start, 'd') === 0
							? undefined
							: 1
						: 1
				}),
			enabled: !!dataSource.id && !!selectedZone
		}))
	});

	// Water meter readings chart:
	// 1. Last water meters readings
	const waterMeterLastReadings = useQueries<any>({
		queries: (measurementPoints || [])
			.filter(site => site.type === Sites.WATER_STATION)
			.map((site: any) => {
				return {
					queryKey: ['site-water-meter-last-reading', site.id],
					queryFn: () => chartsService.getSiteWaterMeterLastReading(site.id),
					enabled: !!site.id && !!selectedZone
				};
			})
	});
	// 2. Water meters readings relative to waterProducedChartTimeSpan
	const waterMeters = useQueries<any>({
		queries: (measurementPoints || [])
			.filter(site => site.type === Sites.WATER_STATION)
			.map((site: any) => {
				return {
					queryKey: ['site-water-meter-logs', site.id, waterMeterLogsChartTimeSpan],
					queryFn: () =>
						chartsService.getSiteWaterMeterLogs(site.id, {
							start:
								waterMeterLogsChartTimeSpan?.start.length &&
								dayjs(waterMeterLogsChartTimeSpan.start).format('YYYY-MM-DD'),

							end:
								waterMeterLogsChartTimeSpan?.end.length &&
								dayjs(waterMeterLogsChartTimeSpan.end).format('YYYY-MM-DD'),
							aggregate: waterMeterLogsChartTimeSpan?.start.length
								? dayjs(waterMeterLogsChartTimeSpan?.end).diff(
										waterMeterLogsChartTimeSpan?.start,
										'd'
								  ) === 0
									? undefined
									: 1
								: 1
						}),

					enabled: !!site.id && !!selectedZone
				};
			})
	});

	// Water production breakout chart:
	// 1. Load Zone water productions readings relative to bar chart over a period of days, months or years
	const {
		data: waterProductionBreakoutChart,
		isLoading: loadingWaterProductionBreakoutChart,
		isFetching: fetchingWaterProductionBreakoutChart
	} = useQuery<any>({
		queryKey: ['water-production-breakout', spanType, chartTimeSpchanWaterProductionBreakout?.date, selectedZone],
		queryFn: () =>
			chartsService.getZoneWaterBreakout(selectedZone, {
				span_type: spanType,
				start: chartTimeSpchanWaterProductionBreakout?.date
					? dayjs(chartTimeSpchanWaterProductionBreakout.date).startOf(spanType).format('YYYY-MM-DD')
					: dayjs().startOf(spanType).format('YYYY-MM-DD')
			}),

		enabled: !!selectedZone
	});

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col xs={24} className={'mb-2'}>
					<Spin spinning={(loadingWaterDate && fetchingWaterDate) || loadingZoneFlowAnalysisConfig}>
						<WaiNumericalChart
							number={
								waterProductionDateChart && waterProductionDateChart?.data?.length > 0 ? (
									parseFloat(waterProductionDateChart?.data[0].value).toFixed(2)
								) : waterProductionDateChart?.average ? (
									parseFloat(waterProductionDateChart?.average).toFixed(2)
								) : (
									<Empty description={t('NoAvailableData')} />
								)
							}
							title={
								(spanType === SpanType.DAY &&
									waterProductionionLastDates[0]?.data &&
									capitalizeWords(waterProductionionLastDates[0]?.data.name)) ||
								t('WaterProduction')
							}
							unit={
								(((waterProductionDateChart?.data?.length > 0 &&
									waterProductionDateChart?.data[0]?.value) ||
									waterProductionDateChart?.average) &&
									waterProductionDateChart?.unit) ||
								undefined
							}
							maxWidth="100%"
							dateText={
								spanType === SpanType.DAY && waterProductionionLastDates[0].data?.data.length > 0
									? chartDateWaterProduction?.date ===
											dayjs(waterProductionionLastDates[0].data.data[0].time).format(
												'YYYY-MM-DD'
											) || chartDateWaterProduction?.date === undefined
										? t('LastReadingAtDate', {
												date: dayjs(waterProductionionLastDates[0].data?.data[0].time).format(
													'Do MMM YYYY'
												)
										  })
										: t('ReadingAtDate', {
												date: dayjs(chartDateWaterProduction?.date).format('Do MMM YYYY')
										  })
									: spanType === SpanType.WEEK && waterProductionionLastDates[1].data?.start
									? chartDateWaterProduction?.start === waterProductionionLastDates[1].data.start
										? t('LastReadingAtDate', {
												date: `${dayjs(waterProductionionLastDates[1].data.start).format(
													'DD MMM'
												)}:${dayjs(waterProductionionLastDates[1].data.start)
													.endOf(SpanType.WEEK)
													.format('DD MMM YYYY')}`
										  })
										: t('ReadingAtDate', {
												date: `${dayjs(chartDateWaterProduction?.start).format(
													'DD MMM'
												)}:${dayjs(chartDateWaterProduction?.start)
													.endOf(SpanType.WEEK)
													.format('DD MMM YYYY')}`
										  })
									: waterProductionionLastDates[2].data?.start &&
									  (chartDateWaterProduction?.start === waterProductionionLastDates[2].data.start
											? t('LastReadingAtDate', {
													date: dayjs(waterProductionionLastDates[2].data.start).format(
														'MMMM YYYY'
													)
											  })
											: t('ReadingAtDate', {
													date: dayjs(chartDateWaterProduction?.start).format('MMMM YYYY')
											  }))
							}
							lastReadingsDates={{
								day:
									waterProductionionLastDates[0]?.data?.data &&
									waterProductionionLastDates[0]?.data?.data[0]?.time,

								week: waterProductionionLastDates[1]?.data?.start,
								month: waterProductionionLastDates[2]?.data?.start
							}}
							showCustomSelectorDate
							OnDateSelect={(date: any) => setChartDateWaterProduction(date)}
							showSpanSelector
							spanType={spanType}
							setSpanType={setSpanType}
							percentage={waterProductionDateChart?.preceding_reading?.percentage_change || undefined}
							increase={
								waterProductionDateChart?.preceding_reading?.trend_direction === 'up' ? true : false
							}
							positive={
								waterProductionDateChart?.preceding_reading?.trend_direction === 'up' ? false : true
							}
							customTimeUnits={[GraphSpan.DAY, GraphSpan.WEEK, GraphSpan.MONTH]}
							sourceName={zoneName}
							deactivated={
								!(zoneFlowAnalysisConfig && zoneFlowAnalysisConfig?.water_production.is_active)
							}
							deactivatedMsg={t('WaterProductionAnalysisDeactivated')}
							linkToActivate={
								AppRoutes.ZONE_CONFIGURATION +
								`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=flow_analysis`
							}
							accessToActivateChart={hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.ZONE),
								UserRights.EDIT
							)}
						/>
					</Spin>
				</Col>
				<Col xs={24}>
					<Spin
						spinning={
							!dailySitesWaterProductionReadings?.every(card => card.isSuccess) || !measurementPoints
						}
					>
						<WaterProductionReadingsCards
							water_production_cards={
								dailySitesWaterProductionReadings?.every(card => card.isSuccess)
									? dailySitesWaterProductionReadings?.map((card: any, idx: number) => {
											return {
												name:
													measurementPoints?.length > 0 &&
													measurementPoints.find(site => site.id === card?.data.id)?.name_en,
												value:
													dailyWaterProductionSiteChartTimeSpan?.date ===
													dayjs().format('YYYY-MM-DD')
														? (card?.data && card?.data?.value) ?? undefined
														: card?.data?.data?.length > 0
														? card?.data?.data[0].value
														: undefined,
												unit: card?.data?.unit,
												current:
													dailyWaterProductionSiteChartTimeSpan?.date ===
													dayjs().format('YYYY-MM-DD')
														? true
														: false,
												id: card?.data?.id
											};
									  })
									: []
							}
							title={t('SitesDailyWaterProductionReadings')}
							lastReadingsDate={dayjs().format('YYYY-MM-DD')}
							OnDateSelect={(date: any) => setDailyWaterProductionSiteChartTimeSpan(date)}
							spanTpye={SpanType.DAY}
							showTableChart
							graphHeight={550}
							deactivated={
								!(zoneFlowAnalysisConfig && zoneFlowAnalysisConfig?.water_production.is_active)
							}
							deactivatedMsg={t('WaterProductionAnalysisDeactivated')}
							linkToActivate={
								AppRoutes.ZONE_CONFIGURATION +
								`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=flow_analysis`
							}
							accessToActivateChart={hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.ZONE),
								UserRights.EDIT
							)}
						/>
					</Spin>
				</Col>
				<Col xs={24}>
					<Spin spinning={(loadingWaterChart && fetchingWaterChart) || loadingZoneFlowAnalysisConfig}>
						<TimeSeriesBar
							IDs={[
								{
									id: selectedZone,
									name: FlowRateType.ZDWP
								}
							]}
							height={58}
							graphHeight={550}
							showBrush
							showTabular
							showAverageLine
							showStDevLine
							statistics={clearArrayUndefined(
								waterProductionTimeSpanChart && waterProductionTimeSpanChart.data
									? [
											...[
												{
													name: t('Minimum'),
													key: ChartStats.MIN,
													value: Math.min(
														...waterProductionTimeSpanChart.data.map(
															(log: any) => +log.value
														)
													),
													unit: FlowRateUnit.CMD
												},
												{
													name: t('Maximum'),
													key: ChartStats.MAX,
													value: Math.max(
														...waterProductionTimeSpanChart.data.map(
															(log: any) => +log.value
														)
													),
													unit: FlowRateUnit.CMD
												},
												{
													name: t('Average'),
													key: ChartStats.MEAN,
													value: mean(
														waterProductionTimeSpanChart.data.map((log: any) => +log.value)
													),
													unit: FlowRateUnit.CMD,
													color: colors.GRAPH_GREEN
												},
												{
													name: t('StandardDeviation'),
													key: ChartStats.STANDARD_DEVIATION,
													value: stdev(
														waterProductionTimeSpanChart.data.map((log: any) => +log.value)
													),
													unit: '',
													color: colors.WARNING
												}
											]
									  ]
									: []
							)}
							minMax={
								waterProductionAllData && {
									min: Math.min(...waterProductionAllData.data.map((log: any) => +log.value)),
									max: Math.max(...waterProductionAllData.data.map((log: any) => +log.value))
								}
							}
							onSpanSelect={(span: any) => setChartTimeSpanWaterProduction(span)}
							lastReadingDate={
								waterProductionionLastDates[0]?.data?.data?.length &&
								waterProductionionLastDates[0]?.data.data[0]?.time
							}
							dataObjects={clearArrayUndefined([
								{
									id: waterProductionTimeSpanChart?.id,
									name: t('value'),
									data: waterProductionTimeSpanChart?.data ?? [],
									allData: waterProductionAllData?.data ?? [],
									unit: FlowRateUnit.CMD,
									yAxis: {
										position: 'left',
										legend: t('WaterProductionInCMD')
									}
								}
							])}
							showSpanSelector
							title={t('ZoneWaterProduction')}
							showCustomSpanSelector={true}
							sourceName={zoneName}
							showTimeStep={true}
							deactivated={!(zoneFlowAnalysisConfig && zoneFlowAnalysisConfig.water_production.is_active)}
							deactivatedMsg={t('WaterProductionAnalysisDeactivated')}
							linkToActivate={
								AppRoutes.ZONE_CONFIGURATION +
								`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=flow_analysis`
							}
							accessToActivateChart={hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.ZONE),
								UserRights.EDIT
							)}
						/>
					</Spin>
				</Col>
				{pressureDataSources?.length > 0 ? (
					<Col xs={24}>
						<Spin
							spinning={
								!pressures?.every(pressure => pressure.isSuccess) ||
								!pressureLastReadings?.every(pressure => pressure.isSuccess)
							}
						>
							<WaiTimeSeriesChart
								IDs={clearArrayUndefined([
									...pressureDataSources.map((source: any) => {
										const measurementPoint =
											measurementPoints &&
											measurementPoints.find(point => point.id === source.measurement_point);
										return {
											id: source.id,
											name:
												measurementPoint &&
												`${
													i18n.language === 'en'
														? measurementPoint.name_en || measurementPoint.id
														: measurementPoint.name_ar ||
														  measurementPoint.name_en ||
														  measurementPoint.id
												} ${source.stream_direction.toLowerCase()} ${source.type.toLowerCase()}`
										};
									})
								])}
								flags={{ aggregate: true }}
								title={t('ZonePressureAnalysis')}
								height={45}
								graphHeight={550}
								dataObjects={
									pressures &&
									pressures.length &&
									pressureDataSources.map((dataSource: any, idx: number) => {
										const measurementPoint =
											measurementPoints &&
											measurementPoints.find(point => point.id === dataSource.measurement_point);
										const dataObj: any = {
											id: dataSource.id,
											name:
												measurementPoint &&
												`${
													i18n.language === 'en'
														? measurementPoint.name_en || measurementPoint.id
														: measurementPoint.name_ar ||
														  measurementPoint.name_en ||
														  measurementPoint.id
												} ${dataSource.stream_direction.toLowerCase()} ${dataSource.type.toLowerCase()}`,
											data: pressures[idx].data ?? [],
											allData: [],
											unit: PressureUnit.BAR
										};
										if (idx === 0)
											dataObj['yAxis'] = {
												position: 'left',
												legend: t('PressureInBAR')
											};
										return dataObj;
									})
								}
								lastReadingDate={mostRecentDate(
									pressureLastReadings?.map((item: any) => item.data?.time)
								)}
								showSpanSelector
								showCustomSpanSelector
								onSpanSelect={(span: any) => setPressureChartTimeSpan(span)}
								showTabular
								showTimeStep
								sourceName={zones.find((zone: any) => zone.id === selectedZone)?.name_en}
								deactivated={
									!(zoneFlowAnalysisConfig && zoneFlowAnalysisConfig?.water_production.is_active)
								}
								deactivatedMsg={t('WaterProductionAnalysisDeactivated')}
								linkToActivate={
									AppRoutes.ZONE_CONFIGURATION +
									`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=flow_analysis`
								}
								accessToActivateChart={hasRight(
									!!user?.user_data?.is_superAdmin,
									configurationPermessions,
									applicationIdMap.get(UserApps.ZONE),
									UserRights.EDIT
								)}
							/>
						</Spin>
					</Col>
				) : null}
				<Col xs={24}>
					<Spin spinning={!waterMeters.every(meter => meter.isSuccess) || !zones}>
						<WaiTimeSeriesChart
							IDs={
								waterMeters &&
								waterMeters.every(meter => meter.isSuccess) &&
								clearArrayUndefined([
									...waterMeters.map((meter: any, idx: number) => {
										const measurementPoint =
											measurementPoints &&
											measurementPoints.find(point => point.id === meter.data.id);
										return {
											id: meter.data.id,
											name:
												measurementPoint && i18n.language === 'en'
													? measurementPoint.name_en || measurementPoint.id
													: measurementPoint.name_ar ||
													  measurementPoint.name_en ||
													  measurementPoint.id
										};
									})
								])
							}
							flags={{ aggregate: true }}
							title={t('WaterMetersReadings')}
							height={45}
							graphHeight={550}
							dataObjects={
								waterMeters &&
								waterMeters.every(meter => meter.isSuccess) &&
								waterMeters.map((meter: any, idx: number) => {
									const measurementPoint =
										measurementPoints &&
										measurementPoints.find(point => point.id === meter.data.id);
									const dataObj: any = {
										id: meter.data.id,
										name:
											measurementPoint &&
											`${
												i18n.language === 'en'
													? measurementPoint.name_en || measurementPoint.id
													: measurementPoint.name_ar ||
													  measurementPoint.name_en ||
													  measurementPoint.id
											}`,
										data: meter.data.data ?? [],
										allData: [],
										unit: meter.data.unit ?? 'L/sec'
									};
									if (idx === 0)
										dataObj['yAxis'] = {
											position: 'left',
											legend: t('legendTitleUnit', {
												title: meter.data.name,
												in: !meter.data.name ? '' : t('in'),
												name: t('L/sec')
											})
										};
									return dataObj;
								})
							}
							lastReadingDate={mostRecentDate(
								waterMeterLastReadings?.map((item: any) => item.data?.time)
							)}
							showSpanSelector
							showCustomSpanSelector
							onSpanSelect={(span: any) => setWaterMeterLogsChartTimeSpan(span)}
							showTabular
							showTimeStep
							deactivated={
								!(zoneFlowAnalysisConfig && zoneFlowAnalysisConfig?.water_production.is_active)
							}
							deactivatedMsg={t('WaterProductionAnalysisDeactivated')}
							linkToActivate={
								AppRoutes.ZONE_CONFIGURATION +
								`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=flow_analysis`
							}
							accessToActivateChart={hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.ZONE),
								UserRights.EDIT
							)}
						/>
					</Spin>
				</Col>
				<Col xs={24}>
					<Spin spinning={loadingWaterProductionBreakoutChart}>
						<TimeSeriesBarGrouped
							IDs={[
								{
									id: selectedZone,
									name: waterProductionBreakoutChart?.name
								}
							]}
							height={48}
							graphHeight={550}
							showCustomSelectorDate
							showSpanSelector
							spanType={spanType}
							setSpanType={setSpanType}
							OnDateSelect={(date: any) => setChartTimeSpanWaterProductionBreakout(date)}
							customTimeUnits={[GraphSpan.DAY, GraphSpan.MONTH, GraphSpan.YEAR]}
							lastReadingsDates={{
								day: dayjs().format('YYYY-MM-DD'),

								month: dayjs().startOf('M').format('YYYY-MM-DD'),
								year: dayjs().startOf('year').format('YYYY-MM-DD')
							}}
							groupedChart={true}
							dataObjects={
								waterProductionBreakoutChart &&
								waterProductionBreakoutChart.data.length > 0 &&
								waterProductionBreakoutChart.data
									.map((site: any, idx: number) => {
										if (
											site.id === '20fbd011-7eee-4e3e-be52-e145ec9fa264' ||
											site.id === '2eda2b2d-cb89-41b3-98b9-cda91becc473'
										)
											site.data = site.data.map((s: any) => {
												s.value = Math.random();
												return s;
											});
										return site;
									})
									.filter(site => site.id !== '10f59a83-f222-401b-a38e-31ad84733bda')
									.map((site: any, idx: number) => {
										const measurementPoint =
											measurementPoints && measurementPoints.find(point => point.id === site.id);
										const dataObj: any = {
											id: site.id,
											name:
												measurementPoint &&
												`${
													i18n.language === 'en'
														? measurementPoint.name_en || measurementPoint.id
														: measurementPoint.name_ar ||
														  measurementPoint.name_en ||
														  measurementPoint.id
												}`,
											data: site.data ?? [],
											allData: [],
											unit: waterProductionBreakoutChart?.unit ?? undefined
										};
										if (idx === 0)
											dataObj['yAxis'] = {
												position: 'left',
												legend: t('legendTitleUnit', {
													title: waterProductionBreakoutChart?.name,
													in: !waterProductionBreakoutChart?.unit ? '' : t('in'),
													name: waterProductionBreakoutChart?.unit ?? ''
												})
											};
										return dataObj;
									})
							}
							title={t('ZoneWaterProductionBreakoutbySite')}
							showTimeStep
							showTableChart
							deactivated={
								!(zoneFlowAnalysisConfig && zoneFlowAnalysisConfig?.water_production.is_active)
							}
							deactivatedMsg={t('WaterProductionAnalysisDeactivated')}
							linkToActivate={
								AppRoutes.ZONE_CONFIGURATION +
								`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=flow_analysis`
							}
							accessToActivateChart={hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.ZONE),
								UserRights.EDIT
							)}
						/>
					</Spin>
				</Col>
			</Row>
		</>
	);
};
