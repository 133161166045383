import { HTMLAttributes, useEffect, useState } from 'react';
import { BsBellFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { WaiWarningContainer, WaiWarningIconContainer, WaiWarningLabel, WaiWarningMessage } from './styles/wai-warning';

export interface IWaiWarningProps {
	messages: string[];
}

export const WaiWarning: React.FC<HTMLAttributes<HTMLDivElement> & IWaiWarningProps> = ({ messages }) => {
	const { t, i18n } = useTranslation();
	const [index, setIndex] = useState(0);

	useEffect(() => {
		if (messages && messages.length) {
			const interval = setInterval(() => {
				setIndex((index + 1) % messages.length);
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [messages, messages?.length]);
	return messages && messages.length ? (
		<WaiWarningContainer englishSelected={i18n.language === 'en'}>
			<WaiWarningIconContainer>
				<BsBellFill />
			</WaiWarningIconContainer>
			<WaiWarningLabel> {t('Alerts')} </WaiWarningLabel>{' '}
			<WaiWarningMessage key={messages[index]}>{messages[index]}</WaiWarningMessage>
		</WaiWarningContainer>
	) : null;
};
