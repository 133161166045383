import { Table, TableColumnsType, TableProps } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { WaiAnalysisWidgetContainer, WaiAnalysisWidgetTitle } from '../components';
import { DataSources, NoiseUnit } from '../types';
import { ILatestReading } from '../types/latest-reading';
import { upperSnakeToCapitalized } from '../utils/upper-snake-to-capitalized';

export const LatestReadingsTable: React.FC<
	TableProps<ILatestReading> & {
		latestReadings: ILatestReading[];
	}
> = ({ latestReadings, loading, ...restProps }) => {
	const { t } = useTranslation();

	const tableColumns: TableColumnsType<any> = [
		{
			title: <span className="text-align-center">{t('Type')}</span>,
			dataIndex: 'type',
			key: 'type',
			align: 'center',
			render: (_: any, record: ILatestReading) =>
				`${
					record.type === DataSources.PRESSURE && record.stream_direction
						? upperSnakeToCapitalized(record.stream_direction)
						: ''
				} ${upperSnakeToCapitalized(record.type)}`.trim()
		},
		{
			title: t('Channel'),
			dataIndex: 'channel',
			key: 'channel',
			align: 'center'
		},
		// {
		// 	title: 'Primary ?',
		// 	dataIndex: 'is_primary',
		// 	key: 'is_primary',
		// 	align: 'center' ,
		// 	render: (text: boolean) => (text ? 'Yes' : 'No')
		// },
		{
			title: t('LastReading'),
			dataIndex: 'last_reading',
			key: 'last_reading_value',
			align: 'center',
			render: (_: any, record: ILatestReading) =>
				record.last_reading?.value &&
				parseFloat(record.last_reading.value).toFixed(1) +
					(record.log_unit === NoiseUnit.UNITLESS ? '' : ' ' + record.log_unit)
		},

		{
			title: t('Time'),
			dataIndex: 'last_reading',
			key: 'last_reading_time',
			align: 'center',
			render: (_: any, record: ILatestReading) =>
				record.last_reading?.time && dayjs(record.last_reading.time).format('dddd, MMMM D, YYYY h:mm A')
		}
	];

	return (
		<WaiAnalysisWidgetContainer>
			<WaiAnalysisWidgetTitle>{t('latestReadings')}</WaiAnalysisWidgetTitle>
			<Table
				rowKey="id"
				tableLayout="auto"
				columns={tableColumns}
				dataSource={latestReadings}
				size="small"
				loading={loading}
				pagination={false}
				{...restProps}
			/>
		</WaiAnalysisWidgetContainer>
	);
};
