import { SpanType } from '.';
import { DataSources } from './data-sources';
import { GraphSpan } from './graph-span';
import { PressureUnit } from './pressure-unit';

export enum chartTypes {
	PIE = 'pie',
	LINE = 'line',
	MULTI_LINE = 'multi-line',
	BAR = 'bar',
	NUMERICAL = 'numerical'
}

export type ChartData = { name: string; value: number };

export type ChartCustomSpan = { start: string; end: string };
export interface IChart {
	id?: string;
	idSecond?: string;
	title?: string;
	titleSecond?: string;
	name?: string;
	data: any[];
	dataSecond?: any[];
	legend?: string;
	allData?: any[];
	type: chartTypes;
	colorPie?: any[];
	dataKeys?: { name: string; color: string; unit?: string }[];
	range?: number[] | string[];
	minReferenceValue?: number | string;
	maxReferenceValue?: number | string;
	xReferenceValue?: number | string;
	showSpanSelector?: boolean;
	showUnitSelector?: boolean;
	onSpanSelect?: (span: ChartCustomSpan) => void;
	initialSpan?: GraphSpan;
	showTabular?: boolean;
	unit?: any;
	unitSecond?: any;
	selected?: string;
	dateText?: string;
	countRate?: boolean;
	alerts?: any;
	sourceType?: DataSources;
	onUnitChange?: (unit: PressureUnit[]) => void;
	onChartSelect?: (fieldName?: string, data?: any[]) => void;
	lastReadingDate?: Date;
	maxWidth?: any;
	height?: number;
	emphasizable?: boolean;
	emphasized?: boolean;
	fieldName?: string;
	showBrush?: boolean;
	chartTableDataSource?: { id: string[]; valve: boolean; dataType: DataSources };
	showCustomSelectorDate?: boolean;
	flags?: any;
	changeDailyData?: any;
	percentage?: number;
	increase?: boolean;
	positive?: boolean;
	analysisRedirectUri?: string;
	is_valve?: boolean;
	spanType?: SpanType;
	sourceName?: string;
	deactivated?: boolean;
	deactivatedMsg?: string;
	linkToActivate?: string;
	accessToActivateChart?: boolean;
}
