import { List, Table, TableProps } from 'antd';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import configProxy from '../../config/config';
import { colors } from '../../constants/colors';
import { NavigationContext } from '../../contexts/navigation.context';
import { useMedia } from '../../hooks/use-media.hook';
import { SpanType } from '../../types';
import { GraphCard } from '../graph-card';

export const CommercialMeterGroupTable: React.FC<
	TableProps<any> & {
		tableData: any[];
		loading: boolean;
		firstReadingsDate?: any;
		lastReadingsDate?: any;
		changeDate?: boolean;
		setTableDate?: any;
		sourceName?: string;
		tableColor?: any;
	}
> = ({ tableData, loading, firstReadingsDate, lastReadingsDate, changeDate, setTableDate, sourceName, tableColor }) => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { selectedCommercialMeterGroup } = navigationContext;

	const [displayDate, setDisplayDate] = useState<any>();

	const media = configProxy.defaultExpandScreenSize;
	const desktopView = useMedia(media);

	//TODO: change arabic name according to customer requirements
	const tableColumns: any = [
		{
			title: t('المجموعة'),
			dataIndex: 'المجموعة',
			key: 'المجموعة',
			align: 'center',
			render: (text: string) => (text.length === 0 ? '-' : text)
		},
		{
			title: t('المسار'),
			dataIndex: 'المسار',
			key: 'المسار',
			align: 'center',
			render: (text: string) => (text.length === 0 ? '-' : text)
		},
		{
			title: t('الوصف'),
			dataIndex: 'الوصف',
			key: 'الوصف',
			align: 'center',
			render: (text: string) => (text.length === 0 ? '-' : text)
		},
		{
			title: t('نوع النشاط'),
			dataIndex: 'نوع النشاط',
			key: 'نوع النشاط',
			align: 'center',
			render: (text: string) => (text.length === 0 ? '-' : text)
		},
		{
			title: t('كمية الاستهلاك'),
			dataIndex: 'كمية الاستهلاك',
			key: 'كمية الاستهلاك',
			align: 'center',
			render: (text: string) => (text.length === 0 ? '-' : text)
		},
		{
			title: t('عدد الوحدات'),
			dataIndex: 'عدد الوحدات',
			key: 'عدد الوحدات',
			align: 'center',
			render: (text: string) => (text.length === 0 ? '-' : text)
		},
		{
			title: t('عدد العملاء'),
			dataIndex: 'عدد العملاء',
			key: 'عدد العملاء',
			align: 'center',
			render: (text: string) => (text.length === 0 ? '-' : text)
		},
		{
			title: t('Other'),
			dataIndex: 'other',
			key: 'other',
			align: 'center',
			render: (obj: Object) =>
				obj !== null
					? Object.entries(obj).map(ele => {
							if (ele[1] !== null || ele[1] !== undefined) {
								return (
									<p>
										<span className="fw-bold">{ele[0]}</span> {ele[1]}
									</p>
								);
							}
					  })
					: '-'
		}
	];
	if (!changeDate) {
		tableColumns.push({
			title: t('Message'),
			dataIndex: 'messages_en',
			key: 'messages_en',
			align: 'center',
			render: (msg: any[]) => {
				return (
					<List
						itemLayout="horizontal"
						dataSource={msg}
						renderItem={item => <List.Item className="d-flex justify-content-start">{item}</List.Item>}
					/>
				);
			},
			width: '25%'
		});
	}

	return (
		<>
			{changeDate ? (
				<GraphCard
					height={50}
					title={t('CommercialMeterGroupMonthlyData')}
					className="mb-4"
					showCustomSelectorDate={changeDate}
					showSpanSelector={changeDate}
					lastReadingsDates={{
						month: lastReadingsDate?.date
					}}
					OnDateSelect={(date: any) => setTableDate(date)}
					spanType={SpanType.MONTH}
					customTimeUnits={[]}
					setDisplayDate={setDisplayDate}
					displayDate={displayDate}
					showExportTable
					CommercialGroupId={selectedCommercialMeterGroup}
					sourceName={sourceName}
				>
					{firstReadingsDate && lastReadingsDate ? (
						<div className="d-flex justify-content-around">
							<div className="d-flex justify-content-center text-muted">
								{t('FirstReadingsDate')}:{' '}
								{firstReadingsDate && dayjs(firstReadingsDate?.date).format('MMMM YYYY')}
							</div>
							<div className="d-flex justify-content-center text-muted">
								{t('LastReadingsDate')}:{' '}
								{lastReadingsDate && dayjs(lastReadingsDate?.date).format('MMMM YYYY')}
							</div>
						</div>
					) : null}
					<div
						className={`d-flex justify-content-between w-100 
							 align-items-center mt-2 ${desktopView ? 'mb-3' : 'mb-2'}`}
						style={{ paddingLeft: '5px', paddingRight: '5px', height: '90%' }}
						dir="ltr"
					>
						<div>
							<AiOutlineLeft
								size={'2rem'}
								style={{ strokeWidth: '30px' }}
								color={colors.GRAPH_PURPLE}
								className={'mb-4'}
								role={'button'}
								onClick={() => {
									setDisplayDate(
										displayDate
											? dayjs(displayDate).subtract(1, 'M').format('YYYY-MM-DD')
											: dayjs(lastReadingsDate?.date).subtract(1, 'M')
									);
								}}
							/>
						</div>

						<div style={{ height: '100%', overflow: 'hidden' }}>
							<Table
								scroll={{ y: 600 }}
								columns={tableColumns}
								dataSource={tableData}
								size="small"
								loading={loading}
								showSorterTooltip
								rowKey="id"
								pagination={false}
							/>
						</div>
						<div>
							<AiOutlineRight
								size={'2rem'}
								style={{ strokeWidth: '30px' }}
								color={colors.GRAPH_PURPLE}
								className={'mb-4'}
								role={'button'}
								onClick={() => {
									setDisplayDate(
										displayDate
											? dayjs(displayDate).add(1, 'M').format('YYYY-MM-DD')
											: dayjs(lastReadingsDate?.date).add(1, 'M')
									);
								}}
							/>
						</div>
					</div>
				</GraphCard>
			) : (
				<div style={{ height: '100%' }} className="text-center">
					<Table
						columns={tableColumns}
						dataSource={tableData}
						scroll={{ y: '100%' }}
						size="small"
						loading={loading}
						showSorterTooltip
						rowKey="id"
						pagination={changeDate && false}
						rowClassName={(record: any) => {
							if (record?.failed || tableColor === 'error') {
								return 'alert alert-danger';
							} else {
								return '';
							}
						}}
						onRow={(record: any) => {
							return { style: { pointerEvents: 'none' } };
						}}
					/>
				</div>
			)}
		</>
	);
};
