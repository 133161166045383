import { useContext, useState } from 'react';
import { Button, Spin, Collapse, Switch, Popconfirm, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../hooks/use-query';
import { useQuery } from '@tanstack/react-query';
import { AverageZonePressureProfileConfiguration } from '../average-zone-pressure-profile-configuration';
import { colors } from '../../constants/colors';
import { capitalizeWords } from '../../utils';
import { RiDeleteBinLine } from 'react-icons/ri';
import { NavigationContext } from '../../contexts/navigation.context';
import { AverageZonePressureProfile } from '../average-zone-pressure-profile';
import { Disabled } from '../component-guard';
import configService from '../../services/config.service';

const { Panel } = Collapse;

export const AverageZonePressureConfiguration: React.FC<any> = () => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	const [visibleProfileConfig, setVisibleProfileConfig] = useState<any>(false);
	const [activeProfileKey, setActiveProfileKey] = useState<any[]>([]);

	const zoneId = useQueryParam('zoneId');

	// load selected average zone pressure profiles data
	const {
		data: averageZonePressureConfig,
		isLoading: loadingAverageZonePressureConfig,
		refetch: refechAverageZonePressureConfig
	} = useQuery({
		queryKey: ['avg-zone-pressure-config', zoneId],
		queryFn: () => zoneId && configService.getAvgZonePressureConfig(zoneId),
		enabled: !!zoneId
	});

	return (
		<Spin spinning={!zoneId || loadingAverageZonePressureConfig}>
			<Collapse
				accordion
				className="my-2"
				activeKey={activeProfileKey}
				onChange={(e: any) => {
					setActiveProfileKey(e);
				}}
			>
				{averageZonePressureConfig &&
					averageZonePressureConfig.profiles.map((profile, idx) => {
						return (
							<Panel
								header={profile.name}
								key={idx}
								forceRender
								extra={
									<span className={'d-flex align-items-center'} onClick={e => e.stopPropagation()}>
										<Switch
											checkedChildren={t('Default')}
											unCheckedChildren={t('NotDefault')}
											checked={profile.is_default}
											disabled={profile.name === 'Weighted Aggregate'}
											style={{
												backgroundColor: profile.is_default ? colors.HEALTHY : colors.INACTIVE
											}}
											className="mx-3"
											onChange={async (value: boolean, event: any) => {
												try {
													const resp = await (zoneId &&
														configService.editAvgZoneConfigProfile(zoneId, profile.id, {
															is_default: value
														}));
													if (!resp)
														throw new Error(
															t('couldntEditZoneAnalysisConfig', { ns: 'validation' })
														);
													refechAverageZonePressureConfig();
												} catch (e: any) {
													errorHandler(e);
												}
											}}
										/>
										{profile.name !== 'Weighted Aggregate' ? (
											<Popconfirm
												title={t('AreYouSureToDeleteThisProfile', { ns: 'validation' })}
												onConfirm={async () => {
													try {
														const resp = await (zoneId &&
															configService.deleteAvgZoneConfigProfile(
																zoneId,
																profile.id
															));
														if (!resp)
															throw new Error(
																t('couldntEditZoneAnalysisConfig', { ns: 'validation' })
															);
														refechAverageZonePressureConfig();
													} catch (e: any) {
														errorHandler(e);
													}
												}}
												okText={t('Yes', { ns: 'common' })}
												cancelText={t('No', { ns: 'common' })}
												okButtonProps={{ loading: false }}
											>
												<Button
													type="link"
													icon={<RiDeleteBinLine size={20} color={colors.ERROR} />}
												/>
											</Popconfirm>
										) : (
											<Disabled tooltipTitle={t('CannotRemoveThisProfile', { ns: 'validation' })}>
												<Button
													type="link"
													icon={<RiDeleteBinLine size={20} color={colors.ERROR} />}
												/>
											</Disabled>
										)}
									</span>
								}
							>
								<AverageZonePressureProfile profile={profile} />
							</Panel>
						);
					})}
			</Collapse>
			<div className="d-flex justify-content-end mt-3">
				<Button type="primary" onClick={() => setVisibleProfileConfig(!visibleProfileConfig)}>
					{t('CreateAnotherProfile')}
				</Button>
			</div>
			{visibleProfileConfig ? (
				<Modal
					open={visibleProfileConfig}
					title={capitalizeWords(t('CreateAnotherProfile'))}
					onCancel={() => setVisibleProfileConfig(false)}
					footer={false}
					width={'50%'}
				>
					<AverageZonePressureProfileConfiguration setVisibleProfileConfig={setVisibleProfileConfig} />
				</Modal>
			) : null}
		</Spin>
	);
};
