import { AxiosError } from 'axios';
import { notifyError } from './notification-messages';

export const errorHandler = (e: AxiosError) => {
	notifyError(e?.message);
	if (e.cause === 'unauthorized') {
		setTimeout(() => {
			localStorage.removeItem('wai_access_token');
			window.location.reload();
		}, 1000);
	}
};
