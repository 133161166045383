export enum AppRoutes {
	ROOT = '/',
	SIGNIN = '/signin',
	SIGNUP = '/signup',
	LOGOUT = '/logout',
	FORGOT_PASSWORD = '/forgot-password',
	PASSWORD_RESET = '/password-reset',
	PASSWORD_CHANGE = '/password-change',
	MONITORING = '/dashboard/monitoring',
	ALERTS = '/dashboard/alerts',
	ANALYSIS = '/dashboard/analysis',
	CONTROL = '/dashboard/control',
	// configuration
	CONFIGURATION = '/dashboard/configuration',
	NETWORK_CONFIGURATION = '/dashboard/configuration/network',
	ZONE_CONFIGURATION = '/dashboard/configuration/zone',
	MEASURMENT_POINT_CONFIGURATION = '/dashboard/configuration/site',
	COMMERCIAL_METER_GROUP_CONFIGURATION = '/dashboard/configuration/commercial-meter-group',
	COMMERCIAL_METER_SITE_CONFIGURATION = '/dashboard/configuration/commercial-meter-site',
	DATA_SOURCE_CONFIGURATION = '/dashboard/configuration/data-source',
	ADVANCED_ANALYSIS = '/dashboard/advanced-analysis',
	// administration
	ADMINISTRATION = '/dashboard/administration',
	FOUNDATION_CONFIGURATION = '/dashboard/administration/foundation',
	USERS_CONFIGURATION = '/dashboard/administration/users',
	USER_PERMISSION = '/dashboard/administration/user/permission',
	SUBSYSTEM_CONFIGURATION = '/dashboard/administration/subsystem',
	APPLICATION_CONFIGURATION = '/dashboard/administration/application'
}
