import { useContext, useEffect, useState } from 'react';
import { Form, Spin, Row, Col, InputNumber, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../hooks/use-query';
import { useQuery } from '@tanstack/react-query';
import { notifySuccess } from '../../utils/notification-messages';
import { NavigationContext } from '../../contexts/navigation.context';
import { modalConfirm } from '../modal-confirm';
import { SubmitCanelButtons } from '../submit-cancel-buttons';
import configService from '../../services/config.service';

export const ZoneLeakageAnalysisParametersConfiguration: React.FC<any> = () => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const zoneId = useQueryParam('zoneId');
	const [form] = Form.useForm();

	// load selected zone leakage analysis configuration data
	const {
		data: zoneLeakageAnalysisConfig,
		isLoading: loadingZoneLeakageAnalysisConfig,
		refetch: refetchZoneLeakageAnalysisConfig
	} = useQuery({
		queryKey: ['zone-leakage-analysis-config', zoneId],
		queryFn: () => zoneId && configService.getZoneLeakageConfig(zoneId),
		enabled: !!zoneId
	});

	// fill the selected data for zone leakage analysis configuration
	useEffect(() => {
		if (zoneLeakageAnalysisConfig) {
			resetFields();
		}
	}, [zoneLeakageAnalysisConfig]);

	// on finish zone leakage analysis configuration
	const onFinish = async (values: any) => {
		setSubmitting(true);
		try {
			const resp = zoneId && (await configService.editZoneLeakageConfig(zoneId, values));
			if (!resp) throw new Error(t('couldntEditZoneLeakageConfig', { ns: 'validation' }));
			refetchZoneLeakageAnalysisConfig();
			notifySuccess(t('editedSuccessfully'));
		} catch (e: any) {
			errorHandler(e);
		}
		setSubmitting(false);
	};

	// confirm before submition
	const onConfirm = values => {
		try {
			modalConfirm({
				onOk: async () => {
					await onFinish(values);
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	// reset fields
	const resetFields = () => {
		zoneLeakageAnalysisConfig &&
			form.setFieldsValue({
				...zoneLeakageAnalysisConfig
			});
		setIsFormChanged(false);
	};

	return (
		<Spin spinning={!zoneId || loadingZoneLeakageAnalysisConfig || submitting}>
			<Form form={form} layout="vertical" onFinish={onConfirm} onValuesChange={() => setIsFormChanged(true)}>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item label={t('MainLosses')} style={{ marginBottom: 0 }}>
							<Row className="w-100" gutter={16}>
								<Col xs={12}>
									<Form.Item
										name="main_losses"
										rules={[
											{ required: true, message: t('PleaseAddMainLosses', { ns: 'validation' }) }
										]}
									>
										<InputNumber placeholder={t('MainLosses')} style={{ width: '100%' }} />
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item
										name="main_losses_unit"
										rules={[
											{
												required: true,
												message: t('PleaseAddMainLossesUnit', { ns: 'validation' })
											}
										]}
									>
										<Input placeholder={t('MainLossesUnit')} style={{ width: '100%' }} disabled />
									</Form.Item>
								</Col>
							</Row>
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item label={t('connectionLosses')} style={{ marginBottom: 0 }}>
							<Row className="w-100" gutter={16}>
								<Col xs={12}>
									<Form.Item
										name="connection_losses"
										rules={[
											{
												required: true,
												message: t('PleaseAddConnectionLosses', { ns: 'validation' })
											}
										]}
									>
										<InputNumber placeholder={t('connectionLosses')} style={{ width: '100%' }} />
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item
										name="connection_losses_unit"
										rules={[
											{
												required: true,
												message: t('PleaseAddConnectionLossesUnit', { ns: 'validation' })
											}
										]}
									>
										<Input
											placeholder={t('connectionLossesUnit')}
											style={{ width: '100%' }}
											disabled
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item label={t('propertyLosses')} style={{ marginBottom: 0 }}>
							<Row className="w-100" gutter={16}>
								<Col xs={12}>
									<Form.Item
										name="property_losses"
										rules={[
											{
												required: true,
												message: t('PleaseAddPropertyLosses', { ns: 'validation' })
											}
										]}
									>
										<InputNumber placeholder={t('propertyLosses')} style={{ width: '100%' }} />
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item
										name="property_losses_unit"
										rules={[
											{
												required: true,
												message: t('PleaseAddPropertyLossesUnit', { ns: 'validation' })
											}
										]}
									>
										<Input
											placeholder={t('propertyLossesUnit')}
											style={{ width: '100%' }}
											disabled
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item label={t('toiletCisternWater')} style={{ marginBottom: 0 }}>
							<Row className="w-100" gutter={16}>
								<Col xs={12}>
									<Form.Item
										name="toilet_cistern_water"
										rules={[
											{
												required: true,
												message: t('PleaseAddToiletCisternWater', { ns: 'validation' })
											}
										]}
									>
										<InputNumber placeholder={t('toiletCisternWater')} style={{ width: '100%' }} />
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item
										name="toilet_cistern_water_unit"
										rules={[
											{
												required: true,
												message: t('PleaseAddToiletCisternWaterUnit', { ns: 'validation' })
											}
										]}
									>
										<Input
											placeholder={t('toiletCisternWaterUnit')}
											style={{ width: '100%' }}
											disabled
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item label={t('averageUseForSmallNonDomesticUsers')} style={{ marginBottom: 0 }}>
							<Row className="w-100" gutter={16}>
								<Col xs={12}>
									<Form.Item
										name="average_use_for_small_non_domestic_users"
										rules={[
											{
												required: true,
												message: t('PleaseAddAaverageUseForSmallNonDomesticUsers', {
													ns: 'validation'
												})
											}
										]}
									>
										<InputNumber
											placeholder={t('averageUseForSmallNonDomesticUsers')}
											style={{ width: '100%' }}
										/>
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item
										name="average_use_for_small_non_domestic_users_unit"
										rules={[
											{
												required: true,
												message: t('PleaseAddAaverageUseForSmallNonDomesticUsersUnit', {
													ns: 'validation'
												})
											}
										]}
									>
										<Input
											placeholder={t('averageUseForSmallNonDomesticUsersUnit')}
											style={{ width: '100%' }}
											disabled
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item label={t('useByLargeNonDomesticUsers')} style={{ marginBottom: 0 }}>
							<Row className="w-100" gutter={16}>
								<Col xs={12}>
									<Form.Item
										name="use_by_large_non_domestic_users"
										rules={[
											{
												required: true,
												message: t('PleaseAddUseByLargeNonDomesticUsers', { ns: 'validation' })
											}
										]}
									>
										<InputNumber
											placeholder={t('useByLargeNonDomesticUsers')}
											style={{ width: '100%' }}
										/>
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item
										name="use_by_large_non_domestic_users_unit"
										rules={[
											{
												required: true,
												message: t('PleaseAddUseByLargeNonDomesticUsersUnit', {
													ns: 'validation'
												})
											}
										]}
									>
										<Input
											placeholder={t('useByLargeNonDomesticUsersUnit')}
											style={{ width: '100%' }}
											disabled
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item label={t('totalExpectedNightUseThreshold')} style={{ marginBottom: 0 }}>
							<Row className="w-100" gutter={16}>
								<Col xs={12}>
									<Form.Item
										name="total_expected_night_use_threshold"
										rules={[
											{
												required: true,
												message: t('PleaseAddTotalExpectedNightUseThreshold', {
													ns: 'validation'
												})
											}
										]}
									>
										<InputNumber
											placeholder={t('totalExpectedNightUseThreshold')}
											style={{ width: '100%' }}
										/>
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item
										name="total_expected_night_use_threshold_unit"
										rules={[
											{
												required: true,
												message: t('PleaseAddTotalExpectedNightUseThresholdUnit', {
													ns: 'validation'
												})
											}
										]}
									>
										<Input
											placeholder={t('totalExpectedNightUseThresholdUnit')}
											style={{ width: '100%' }}
											disabled
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item label={t('unaccountedForLeakageThreshold')} style={{ marginBottom: 0 }}>
							<Row className="w-100" gutter={16}>
								<Col xs={12}>
									<Form.Item
										name="unaccounted_for_leakage_threshold"
										rules={[
											{
												required: true,
												message: t('PleaseAddUnaccountedForLeakageThreshold', {
													ns: 'validation'
												})
											}
										]}
									>
										<InputNumber
											placeholder={t('unaccountedForLeakageThreshold')}
											style={{ width: '100%' }}
										/>
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item
										name="unaccounted_for_leakage_threshold_unit"
										rules={[
											{
												required: true,
												message: t('PleaseAddUnaccountedForLeakageThresholdUnit', {
													ns: 'validation'
												})
											}
										]}
									>
										<Input
											placeholder={t('unaccountedForLeakageThresholdUnit')}
											style={{ width: '100%' }}
											disabled
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item label={t('customerAverageRealLossThreshold')} style={{ marginBottom: 0 }}>
							<Row className="w-100" gutter={16}>
								<Col xs={12}>
									<Form.Item
										name="customer_average_real_loss_threshold"
										rules={[
											{
												required: true,
												message: t('PleaseAddCustomerAverageRealLossThreshold', {
													ns: 'validation'
												})
											}
										]}
									>
										<InputNumber
											placeholder={t('customerAverageRealLossThreshold')}
											style={{ width: '100%' }}
										/>
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item
										name="customer_average_real_loss_threshold_unit"
										rules={[
											{
												required: true,
												message: t('PleaseAddCustomerAverageRealLossThresholdUnit', {
													ns: 'validation'
												})
											}
										]}
									>
										<Input
											placeholder={t('customerAverageRealLossThresholdUnit')}
											style={{ width: '100%' }}
											disabled
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item label={t('unreportedRealLossURLThreshold')} style={{ marginBottom: 0 }}>
							<Row className="w-100" gutter={16}>
								<Col xs={12}>
									<Form.Item
										name="unreported_real_loss_URL_threshold"
										rules={[
											{
												required: true,
												message: t('PleaseAddUnreportedRealLossURLThreshold', {
													ns: 'validation'
												})
											}
										]}
									>
										<InputNumber
											placeholder={t('unreportedRealLossURLThreshold')}
											style={{ width: '100%' }}
										/>
									</Form.Item>
								</Col>
								<Col xs={12}>
									<Form.Item
										name="unreported_real_loss_URL_threshold_unit"
										rules={[
											{
												required: true,
												message: t('PleaseAddUnreportedRealLossURLThresholdUnit', {
													ns: 'validation'
												})
											}
										]}
									>
										<Input
											placeholder={t('unreportedRealLossURLThresholdUnit')}
											style={{ width: '100%' }}
											disabled
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={8}>
						<Form.Item
							label={t('populationNightActivityPercent')}
							name="population_night_activity_percent"
							rules={[
								{
									required: true,
									message: t('PleaseAddPopulationNightActivityPercent', { ns: 'validation' })
								}
							]}
						>
							<InputNumber placeholder={t('populationNightActivityPercent')} style={{ width: '100%' }} />
						</Form.Item>
					</Col>
					<Col xs={8}>
						<Form.Item
							label={t('backgroundLossesPressureExponent')}
							name="background_losses_pressure_exponent"
							rules={[
								{
									required: true,
									message: t('PleaseAddBackgroundLossesPressureExponent', { ns: 'validation' })
								}
							]}
						>
							<InputNumber
								placeholder={t('backgroundLossesPressureExponent')}
								style={{ width: '100%' }}
							/>
						</Form.Item>
					</Col>
					<Col xs={8}>
						<Form.Item
							label={t('burstsLeaksPressureExponent')}
							name="bursts_leaks_pressure_exponent"
							rules={[
								{
									required: true,
									message: t('PleaseAddBurstsLeaksPressureExponent', { ns: 'validation' })
								}
							]}
						>
							<InputNumber placeholder={t('burstsLeaksPressureExponent')} style={{ width: '100%' }} />
						</Form.Item>
					</Col>
				</Row>
				<Form.Item className="d-flex justify-content-end mt-3">
					<SubmitCanelButtons
						handleCancel={() => resetFields()}
						handleSubmit={form.submit}
						reset={true}
						disabled={!isFormChanged}
					/>
				</Form.Item>
			</Form>
		</Spin>
	);
};
