import { useContext, useState } from 'react';
import { Button, Collapse, Empty, Modal, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { colors } from '../../constants/colors';
import { capitalizeWords, notifySuccess } from '../../utils';
import { RiDeleteBinLine } from 'react-icons/ri';
import { NavigationContext } from '../../contexts/navigation.context';
import alertsService from '../../services/alerts.service';
import { AlertConfigurationForm } from '../alert-config-form';

const { Panel } = Collapse;

export const AlertsConfiguration: React.FC<any> = ({
	alertsConfig,
	refechAlertsConfig,
	alertTypes,
	createAlertConfig
}) => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	const [visibleAlertModalConfig, setVisibleAlertModalConfig] = useState<any>(false);
	const [activeProfileKey, setActiveProfileKey] = useState<any[]>([]);

	return (
		<>
			{alertsConfig && alertsConfig.length > 0 ? (
				<Collapse
					accordion
					className="my-2"
					activeKey={activeProfileKey}
					onChange={(e: any) => {
						setActiveProfileKey(e);
					}}
				>
					{alertsConfig &&
						alertsConfig.map((alert, idx) => {
							return (
								<Panel
									header={alert.name}
									key={idx}
									forceRender
									extra={
										<span
											className={'d-flex align-items-center justify-content-center'}
											onClick={e => e.stopPropagation()}
										>
											<Popconfirm
												title={t('AreYouSureToDeleteThisAlertConfig', { ns: 'validation' })}
												onConfirm={async () => {
													try {
														const resp = await alertsService.deleteAlertConfig(alert.id);
														if (!resp)
															throw new Error(
																t('couldntEditAlertConfig', { ns: 'validation' })
															);
														refechAlertsConfig();
														notifySuccess(t('RemovedSuccessfully'));
													} catch (e: any) {
														errorHandler(e);
													}
												}}
												okText={t('Yes', { ns: 'common' })}
												cancelText={t('No', { ns: 'common' })}
												okButtonProps={{ loading: false }}
											>
												<span>
													<RiDeleteBinLine size={20} color={colors.ERROR} />
												</span>
											</Popconfirm>
										</span>
									}
								>
									<AlertConfigurationForm
										alertTypes={alertTypes}
										refechAlertsConfig={refechAlertsConfig}
										alert={alert}
									/>
								</Panel>
							);
						})}
				</Collapse>
			) : (
				<Empty description={t('NoConfiguredAlerts')} />
			)}
			<div className="d-flex justify-content-end mt-3">
				<Button type="primary" onClick={() => setVisibleAlertModalConfig(!visibleAlertModalConfig)}>
					{t('AddAlertConfig')}
				</Button>
			</div>
			{visibleAlertModalConfig ? (
				<Modal
					open={visibleAlertModalConfig}
					title={capitalizeWords(t('AddAlertConfig'))}
					onCancel={() => setVisibleAlertModalConfig(false)}
					footer={false}
					centered
				>
					<AlertConfigurationForm
						alertTypes={alertTypes}
						refechAlertsConfig={refechAlertsConfig}
						createAlertConfig={values => createAlertConfig(values)}
						setVisibleAlertModalConfig={setVisibleAlertModalConfig}
					/>
				</Modal>
			) : null}
		</>
	);
};
