import { Form, Input, InputNumber, Select, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import alertsService from '../../services/alerts.service';
import { notifySuccess, upperSnakeToCapitalized } from '../../utils';
import { AlertPriority } from '../../types';
import { SubmitCanelButtons } from '../submit-cancel-buttons';

export const AlertConfigurationForm: React.FC<any> = ({
	alertTypes,
	alert,
	refechAlertsConfig,
	createAlertConfig,
	setVisibleAlertModalConfig
}) => {
	const { t } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const [form] = Form.useForm();

	// load form data for selected alert
	useEffect(() => {
		if (alert) {
			resetFields();
		}
	}, [alert]);

	const onFinish = async (values: any) => {
		setSubmitting(true);
		if (alert) {
			try {
				const resp = await alertsService.editAlertConfig(alert?.id, values);
				if (!resp) throw new Error(t('couldntEditAlertConfig', { ns: 'validation' }));
				refechAlertsConfig && refechAlertsConfig();
				notifySuccess(t('editedSuccessfully'));
			} catch (e: any) {
				errorHandler(e);
			} finally {
				setSubmitting(false);
				setVisibleAlertModalConfig && setVisibleAlertModalConfig(false);
				setIsFormChanged(false);
			}
		} else {
			try {
				const resp = await createAlertConfig(values);
				if (!resp) throw new Error(t('couldntAddAlertConfig', { ns: 'validation' }));
				refechAlertsConfig && refechAlertsConfig();
				notifySuccess(t('addedSuccessfully'));
			} catch (e: any) {
				errorHandler(e);
			} finally {
				setSubmitting(false);
				setVisibleAlertModalConfig && setVisibleAlertModalConfig(false);
				setIsFormChanged(false);
			}
		}
	};

	// close form modal without saving
	const handleCancel = () => {
		form.resetFields();
		setVisibleAlertModalConfig && setVisibleAlertModalConfig(false);
	};

	// reset fields for selected alert
	const resetFields = () => {
		if (alert) {
			form.setFieldsValue({
				...alert
			});
		}
		setIsFormChanged(false);
	};

	return (
		<>
			<Spin spinning={submitting}>
				<Form
					form={form}
					initialValues={{ alert_priority: AlertPriority.URGENT }}
					layout="vertical"
					onFinish={onFinish}
					onValuesChange={() => setIsFormChanged(true)}
				>
					<Form.Item
						name="name"
						label={t('Name')}
						rules={[{ required: true, message: t('AddAlertName', { ns: 'validation' }) }]}
					>
						<Input type="text" placeholder={t('AlertName')} />
					</Form.Item>
					<Form.Item
						name="alert_type"
						label={t('Type')}
						rules={[{ required: true, message: t('SelectAlertType', { ns: 'validation' }) }]}
					>
						<Select
							options={
								alertTypes &&
								alertTypes.map(type => {
									return {
										value: type,
										label: upperSnakeToCapitalized(type.toLowerCase())
									};
								})
							}
							disabled={!!alert?.id}
						/>
					</Form.Item>
					<Form.Item
						name="alert_priority"
						label={t('Priority')}
						rules={[{ required: true, message: t('SelectAlertPriority', { ns: 'validation' }) }]}
					>
						<Select
							options={Object.values(AlertPriority).map(priority => {
								return {
									value: priority,
									label: upperSnakeToCapitalized(t(priority.toLowerCase()))
								};
							})}
						/>
					</Form.Item>
					<Form.Item
						name="value"
						label={t('Value')}
						rules={[
							{
								required: true,
								message: t('AddAlertValue', { ns: 'validation' })
							}
						]}
					>
						<InputNumber style={{ width: '100%' }} step={'0.001'} placeholder={t('AlertValue')} />
					</Form.Item>
					<div className="d-flex justify-content-end">
						<SubmitCanelButtons
							handleCancel={() => {
								if (alert) {
									resetFields();
								} else {
									handleCancel();
								}
							}}
							handleSubmit={form.submit}
							reset={alert ? true : false}
							disabled={alert ? !isFormChanged : false}
						/>
					</div>
				</Form>
			</Spin>
		</>
	);
};
