import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle<{ label: any; language: string }>`
*, *:before, *:after {
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
  }
  body{
    margin:0;
    padding:0;
    background-color: ${({ label }) => label.dashboardColor} !important;
    direction: ${({ language }) => (language === 'en' ? 'ltr' : language === 'en-US' ? 'ltr' : 'rtl')}
  }
  p{
    margin:0;
    padding:0;
  }
  a{
    text-decoration:none;
  }
  .recharts-surface{
    overflow:visible;
  }

  .navigation-select .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${({ label }) => label.sidebarSelecedSelectorBGColor} !important;
    color: ${({ label }) => label.sidebarSelecedSelectorColor};
    transition: all 0.1s;
  }
  .navigation-select .ant-select-item {
    color: ${({ label }) => label.sidebarSelectorOptionColor};
    font-weight: 700;
    border-radius:15px;
    width:100%;
  }
  .navigation-select .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${({ label }) => label.sidebarSelectorOptionHoverColor};
  }
`;
