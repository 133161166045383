import { useQuery } from '@tanstack/react-query';
import { Spin, Table, TableProps } from 'antd';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { transformDevicesToRecord } from '../../mappers/device-to-record';
import monitoringService from '../../services/monitoring.service';
import { Vendors } from '../../types';
import { IDeviceRecord } from '../../types/device-record';
import { formatFilters } from '../../utils/get-table-filters';
import { formatSorter } from '../../utils/get-table-sorter';
import { upperSnakeToCapitalized } from '../../utils/upper-snake-to-capitalized';

export const DevicesTable: React.FC<TableProps<IDeviceRecord>> = () => {
	const { t, i18n } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { networks } = navigationContext;

	const [devicesSorters, setDevicesSorters] = useState<any>({});
	const [devicesFilters, setDevicesFilters] = useState<any>({});

	// load measurementPoints
	const { data: measurementPoints } = useQuery({
		queryKey: ['sites'],
		queryFn: () => monitoringService.getMeasurementPointsAll()
	});

	// load zones
	const { data: allZones } = useQuery<any>({
		queryKey: ['zones'],
		queryFn: () => monitoringService.getZonesAll()
	});

	// load devices
	const {
		data: devices,
		isLoading: loadingDevices,
		refetch: refetchDevices
	} = useQuery<any>({
		queryKey:
			(devicesSorters && Object.keys(devicesSorters)?.length) ||
			(devicesFilters && Object.keys(devicesFilters)?.length)
				? ['devices', devicesSorters, devicesFilters]
				: ['devices'],
		queryFn: () =>
			monitoringService.getDevices(
				(devicesSorters && Object.keys(devicesSorters)?.length) ||
					(devicesFilters && Object.keys(devicesFilters)?.length)
					? {
							sorters: { ...devicesSorters },
							filters: { ...devicesFilters }
					  }
					: undefined
			)
	});

	// filter deivces with: network, zone, measurement_point, is_active, is_online, vendor
	const onDevicesFilter = async (filters: any) => {
		if (JSON.stringify(filters) === JSON.stringify(devicesFilters)) return;
		const filteredValues = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value !== undefined));
		if (Object.keys(filteredValues).length > 0) {
			await setDevicesFilters(filteredValues);
			await refetchDevices();
		} else {
			await setDevicesFilters(undefined);
			await refetchDevices();
		}
	};

	// sort devices with: created_at, updated_at
	const onDevicesSort = async (sorters: any) => {
		if (JSON.stringify(sorters) === JSON.stringify(devicesSorters)) return;
		const sortedValues = Object.fromEntries(Object.entries(sorters).filter(([_, value]) => value !== undefined));
		if (Object.keys(sortedValues).length > 0) {
			await setDevicesSorters(sortedValues);
			await refetchDevices();
		} else {
			await setDevicesSorters(undefined);
			await refetchDevices();
		}
	};

	const handleTableChange = (newPagination: any, filters: any, sorters: any) => {
		onDevicesFilter && onDevicesFilter(formatFilters(filters));
		onDevicesSort && onDevicesSort(formatSorter(sorters));
	};

	const tableColumns: any = [
		{
			title: t('Device'),
			dataIndex: 'device_id',
			key: 'device_id',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Vendor'),
			dataIndex: 'vendor',
			key: 'vendor',
			align: 'center',
			filters:
				Vendors &&
				Object.values(Vendors).map(vendor => ({
					text: upperSnakeToCapitalized(vendor),
					value: vendor
				})),
			filterMultiple: false,
			render: (text: string) => upperSnakeToCapitalized(text)
		},
		,
		{
			title: t('ResourceType'),
			dataIndex: 'resourcetype',
			key: 'resourcetype',
			align: 'center',

			render: (text: string) => text
		},
		{
			title: t('Serial'),
			dataIndex: 'serial',
			key: 'serial',
			align: 'center',
			render: (text: string) => text
		},

		{
			title: t('RelatedMeasurementPoint'),
			dataIndex: 'measurement_point',
			key: 'measurement_point',
			align: 'center',
			filters:
				measurementPoints &&
				measurementPoints.map(measurementPoint => ({
					text: measurementPoint?.name_en.length > 0 ? measurementPoint?.name_en : measurementPoint.id,
					value: measurementPoint.id
				})),
			filterMultiple: false,
			render: (pointId: string) => {
				const matchedPoint = measurementPoints && measurementPoints.find(point => point.id === pointId);
				return matchedPoint ? matchedPoint.name_en || matchedPoint.id : '';
			}
		},
		{
			title: t('RelatedZone'),
			dataIndex: 'zones',
			key: 'zones',
			align: 'center',
			filters:
				allZones &&
				allZones.map(zone => ({
					text: i18n.language === 'en' ? zone.name_en : zone.name_ar,
					value: zone.id
				})),
			filterMultiple: false,
			render: (zonesIDs: string[]) => {
				const matchedZones = allZones && allZones.filter(zone => zonesIDs && zonesIDs.includes(zone.id));
				return matchedZones.length > 0
					? matchedZones.map((zone: any) => (i18n.language === 'en' ? zone.name_en : zone.name_ar)).join(', ')
					: '';
			}
		},
		{
			title: t('RelatedNetwork'),
			dataIndex: 'network',
			key: 'network',
			align: 'center',
			filters:
				networks &&
				networks.map(network => ({
					text: i18n.language === 'en' ? network.name_en : network.name_ar,
					value: network.id
				})),
			filterMultiple: false,
			render: (networkId: string) => {
				const matchedNetwork = networks && networks.find(network => network.id === networkId);
				return matchedNetwork ? (i18n.language === 'en' ? matchedNetwork.name_en : matchedNetwork.name_ar) : '';
			}
		},
		{
			title: t('DateCreated'),
			dataIndex: 'created_at',
			key: 'created_at',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: Date) => dayjs(text).format('dddd, MMMM D, YYYY h:mm A')
		},
		{
			title: t('DateUpdated'),
			dataIndex: 'updated_at',
			key: 'updated_at',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: Date) => dayjs(text).format('dddd, MMMM D, YYYY h:mm A')
		},

		{
			title: t('Online'),
			dataIndex: 'is_online',
			key: 'is_online',
			align: 'center',
			filters: [
				{ text: t('Online'), value: true },
				{ text: t('NotOnline'), value: false }
			],
			filterMultiple: false,
			render: (check: boolean) =>
				check ? <AiFillCheckCircle className="text-success" /> : <AiFillCloseCircle className="text-danger" />
		},
		{
			title: t('Active'),
			dataIndex: 'is_active',
			key: 'is_active',
			align: 'center',
			filters: [
				{ text: t('Active'), value: true },
				{ text: t('NotActive'), value: false }
			],
			filterMultiple: false,
			render: (check: boolean) =>
				check ? <AiFillCheckCircle className="text-success" /> : <AiFillCloseCircle className="text-danger" />
		}
	];

	return (
		<>
			<Spin spinning={loadingDevices}>
				<Table
					tableLayout="auto"
					scroll={{ x: true }}
					columns={tableColumns}
					dataSource={devices && measurementPoints ? transformDevicesToRecord(devices) : []}
					size="small"
					showSorterTooltip
					rowKey="id"
					onChange={handleTableChange}
				/>
			</Spin>
		</>
	);
};
