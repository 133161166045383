import { IDeviceRecord } from '../types/device-record';

export const transformDevicesToRecord = (devices: any): IDeviceRecord[] => {
	return devices.map((device: any) => {
		return {
			id: device.id,
			device_id: device.device_id,
			vendor: device.vendor,
			resourcetype: device.resourcetype,
			serial: device.serial,
			is_online: device.is_online,
			is_active: device.is_active,
			created_at: device.created_at,
			updated_at: device.updated_at,
			measurement_point: device.measurement_point,
			zones: device.zones,
			network: device.network
		};
	});
};
