import { IMeasurementPointRecord } from '../types/measurement-point-record';

export const transformMeasurementPointsToRecord = (measurementPoints: any): IMeasurementPointRecord[] => {
	return measurementPoints.map((measurementPoint: any) => {
		return {
			id: measurementPoint.id,
			type: measurementPoint.type,
			name_en: measurementPoint.name_en,
			name_ar: measurementPoint?.name_ar || measurementPoint.name_en,
			is_active: measurementPoint.is_active,
			latitude: measurementPoint.latitude,
			longitude: measurementPoint.longitude,
			created_at: measurementPoint.created_at,
			zone: measurementPoint.zone,
			network: measurementPoint.network
		};
	});
};
