import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { GISLayerType } from '../../types';
import { upperSnakeToCapitalized } from '../../utils/upper-snake-to-capitalized';
import { useState } from 'react';

const { TextArea } = Input;

export const GISLayerForm: React.FC<any> = ({ gisForm, onGISLayerFinish }) => {
	const { t } = useTranslation();
	const [JSONFormatError, setJSONFormatError] = useState<boolean>(false);

	// on finish adding GIS layer to zone
	const onGISFinish = async (values: any) => {
		if (checkJSONFormat(values['geojson'])) {
			onGISLayerFinish && onGISLayerFinish(values);
			setJSONFormatError(false);
			gisForm.resetFields();
		} else {
			setJSONFormatError(true);
		}
	};

	// check JSON format
	const checkJSONFormat = str => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	};

	return (
		<>
			<Form layout="vertical" form={gisForm} onFinish={onGISFinish}>
				<Form.Item
					name="name_en"
					label={t('Name')}
					rules={[{ required: true, message: t('AddGISName', { ns: 'validation' }) }]}
				>
					<Input type="text" placeholder={t('GISLayerName')} />
				</Form.Item>
				<Form.Item
					label={t('Type')}
					name="type"
					rules={[{ required: true, message: t('SelectGISLayerType', { ns: 'validation' }) }]}
				>
					<Select
						placeholder={t('GISLayerType')}
						options={Object.values(GISLayerType).map(type => {
							return {
								value: type,
								label: upperSnakeToCapitalized(t(type))
							};
						})}
					/>
				</Form.Item>
				<Form.Item
					label={t('GISLayer')}
					name="geojson"
					rules={[{ required: true, message: t('AddJSONGISLayer', { ns: 'validation' }) }]}
				>
					<TextArea
						placeholder={t('AddInJSONFormat')}
						autoSize={{ minRows: 8, maxRows: 8 }}
						onChange={() => setJSONFormatError(false)}
					/>
				</Form.Item>
				{JSONFormatError ? <p className="text-danger"> {t('AddRightJSONFormat')}</p> : null}
			</Form>
		</>
	);
};
