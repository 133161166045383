import Dragger from 'antd/es/upload/Dragger';
import React, { HTMLAttributes, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInbox } from 'react-icons/ai';
import { colors } from '../../constants/colors';
import { handleBeforeUploadExcelFile, handleUploadExcelFile } from '../../utils/handle-upload-excel-organization';
import { RcFile } from 'antd/lib/upload/interface';
import { NavigationContext } from '../../contexts/navigation.context';
import { useQueryParam } from '../../hooks/use-query';

export const CommercialMeterFileUploadButton: React.FC<
	HTMLAttributes<HTMLDivElement> & { fileList: any[]; setFileList: any; setExcelData }
> = ({ fileList, setFileList, setExcelData }) => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	const commercialMeterGroupId = useQueryParam('commercialMeterGroupId');

	// NOTE: it will be dynamic later according to back-end
	const requiredColumns = ['المجموعة', 'نوع النشاط', 'كمية الاستهلاك', 'عدد الوحدات', 'عدد العملاء'];
	const optionalColumns = ['المسار', 'الوصف'];

	const handleRemove = file => {
		const newFileList = fileList.filter(f => f.uid !== file.uid);
		setFileList(newFileList);
	};

	return (
		<Dragger
			listType="text"
			accept=".xlsx, .xls, .csv, .xlsm, 'xlsb"
			maxCount={1}
			fileList={fileList}
			beforeUpload={(file: RcFile) => {
				handleBeforeUploadExcelFile(file, errorHandler, t);
			}}
			customRequest={({ file }) => {
				handleUploadExcelFile(file, t, setFileList, setExcelData);
			}}
			onRemove={handleRemove}
			multiple={false}
		>
			<p className="ant-upload-drag-icon">
				<AiOutlineInbox size={40} color={colors.WAI_BLUE} />
			</p>
			{commercialMeterGroupId ? <p className="ant-upload-text">{t('WantToUploadFileData')}</p> : null}
			<p className="ant-upload-text">{t('ClickOrDragFileToThisAreaToUpload')}</p>
			<div className="my-1">
				<small style={{ color: colors.INACTIVE, display: 'block' }}>{t('MaxSize')}</small>
				<small style={{ color: colors.INACTIVE, display: 'block' }}>{t('SupportExcel')}</small>
				<small style={{ color: colors.INACTIVE, display: 'block' }}>
					{t('RequiredColumns', {
						columns: requiredColumns.map(column => `"${column}"`).join(', ')
					})}
				</small>
				<small style={{ color: colors.INACTIVE, display: 'block' }}>
					{t('OptionalColumns', {
						columns: optionalColumns.map(column => `"${column}"`).join(', ')
					})}
				</small>
			</div>
		</Dragger>
	);
};
