import { useQuery } from '@tanstack/react-query';
import { Button, Card, Spin, Table, TableColumnsType, TableProps } from 'antd';
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { colors } from '../../constants/colors';
import { AppRoutes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { IFoundationDto } from '../../dtos';
import userManagementService from '../../services/user-management.service';
import { Identifiable, UserApps } from '../../types';
import { upperSnakeToCapitalized } from '../../utils';
import { notifySuccess } from '../../utils/notification-messages';
import { ComponentGuard } from '../component-guard';
import { TableControls } from '../table-controls';
import { modalConfirm } from '../modal-confirm';

export const SubSystemsTable: React.FC<TableProps<Identifiable<IFoundationDto>>> = () => {
	const { t } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;
	const navigate = useNavigate();
	const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);

	// get all subsystems
	const {
		data: subsystems,
		isLoading: loadingSubsytems,
		refetch: refetchSubsystems
	} = useQuery({
		queryKey: ['subsystems'],
		queryFn: () => userManagementService.getSubsystems()
	});

	// get all applications
	const { data: applications, isLoading: loadingApplications } = useQuery({
		queryKey: ['applications'],
		queryFn: () => userManagementService.getApplications()
	});

	const tableColumns: TableColumnsType<any> = [
		{
			title: t('Subsystem'),
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			render: (text: string) => upperSnakeToCapitalized(text)
		}
	];

	tableColumns.push({
		title: t('Actions'),
		key: 'actions',
		align: 'center',
		render: (_: any, record: any) => (
			<TableControls
				onEdit={() => navigate(`${AppRoutes.SUBSYSTEM_CONFIGURATION}?subsystemId=${record.id}`)}
				onRemove={() => onRemove(record.id)}
				userApp={UserApps.SUB_SYSTEMS}
			/>
		)
	});
	const onRemove = (id: string) => {
		try {
			modalConfirm({
				content: t('YouAreAboutToDeleteSubsystem'),
				onOk: async () => {
					await userManagementService.deleteSubsystem(id);
					notifySuccess(t('RemovedSuccessfully'));
					refetchSubsystems();
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};
	const handleExpand = (expanded, record) => {
		if (expanded) {
			setExpandedRowKeys([record]);
		} else {
			setExpandedRowKeys([]);
		}
	};

	return (
		<>
			<Spin spinning={loadingSubsytems || loadingApplications}>
				<Table
					tableLayout="auto"
					scroll={{ x: true }}
					columns={
						tableColumns &&
						tableColumns.map(column => ({
							...column,
							onCell: () => ({
								onClick: event => {
									event.stopPropagation();
								}
							})
						}))
					}
					dataSource={subsystems ?? undefined}
					size="small"
					showSorterTooltip
					rowKey="id"
					expandable={{
						expandedRowKeys,
						onExpand: (expanded, record) => {
							handleExpand(expanded, record.id);
						},
						expandedRowRender: record => (
							<div key={record.id}>
								<Card
									title={
										<span
											style={{ fontSize: '0.9rem' }}
											className={'d-flex justify-content-center'}
										>
											{t('Applications')}
										</span>
									}
								>
									{applications &&
										applications
											.filter(app => app.sub_system.id === record.id)
											.map((app, idx) => {
												return (
													<div key={app.id}>
														<p>{upperSnakeToCapitalized(app.name)}</p>
														{idx + 1 !==
														applications.filter(app => app.sub_system.id === record.id)
															.length ? (
															<hr
																style={{
																	margin: '0.4rem 0',
																	color: colors.INACTIVE
																}}
															/>
														) : null}
													</div>
												);
											})}
									<ComponentGuard allowed={true}>
										<div className="d-flex justify-content-end mt-3">
											<Link
												to={`${AppRoutes.APPLICATION_CONFIGURATION}?subsystemId=${record.id}`}
											>
												<Button type="primary">{t('addApplication')}</Button>
											</Link>
										</div>
									</ComponentGuard>
								</Card>
							</div>
						),
						expandRowByClick: true
					}}
				/>
			</Spin>
			<ComponentGuard allowed={true}>
				<div className="d-flex justify-content-end mt-3">
					<Link to={AppRoutes.SUBSYSTEM_CONFIGURATION}>
						<Button type="primary">{t('addSubsystem')}</Button>
					</Link>
				</div>
			</ComponentGuard>
		</>
	);
};
