import { Descriptions } from 'antd';
import { HTMLAttributes, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
	DescriptionCardContainer,
	DescriptionCardInfoLabel,
	DescriptionCardInfoText,
	DescriptionCardTitle
} from './styles/description-card';

interface IDescriptionCardProps {
	title: string;
	items: { label: string; value: string }[];
}

export const DescriptionCard: React.FC<HTMLAttributes<HTMLDivElement> & IDescriptionCardProps> = ({
	title,
	items,
	children,
	...restProps
}) => {
	const { i18n } = useTranslation();

	return (
		<DescriptionCardContainer {...restProps}>
			<DescriptionCardTitle englishSelected={i18n.language === 'en'}>{title}</DescriptionCardTitle>
			<Descriptions layout="horizontal" column={{ xs: 1, md: 2 }} size="small">
				{items.map((item, idx) => (
					<Descriptions.Item label={item.label} key={idx}>
						<b>{item.value}</b>
					</Descriptions.Item>
				))}
			</Descriptions>
			{/* {items.map((item, idx) => (
				<div key={idx} className="d-flex align-items-center">
					<DescriptionCardInfoLabel>{item.label}</DescriptionCardInfoLabel>:&nbsp;&nbsp;
					<DescriptionCardInfoText>{item.value}</DescriptionCardInfoText>
				</div>
			))} */}
			{children}
		</DescriptionCardContainer>
	);
};
