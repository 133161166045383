const URIs: { [k: string]: string } = {
	networks_uri: '/api/networks/',
	network_uri: '/api/networks/:networkId/',
	network_gis_layers_uri: '/api/networks/:networkId/gis-layers/',
	network_gis_layer_uri: '/api/networks/:networkId/gis-layers/:gisLayerId/',
	// ========================================================================
	zones_uri_new: '/api/zones/',
	zone_uri_new: '/api/zones/:zoneId/',
	zones_with_commercial_sites: '/api/zones-with-commercial-sites-data/',
	zones_uri: '/api/networks/:networkId/zones/',
	zone_uri: '/api/networks/:networkId/zones/:zoneId/',
	zone_files_uri: '/api/zones/:zoneId/files/',
	zone_gis_layers_uri: '/api/zones/:zoneId/gis-layers/',
	zone_gis_layer_uri: '/api/zones/:zoneId/gis-layers/:gisLayerId/',
	zone_leakage_config: '/api/configurations/zones/:zoneId/leakage-analysis-configs/',
	zone_leakage_parameters: '/api/zones/:zoneId/leakage-parameters/',
	zone_leakage_status: '/api/charts/zones/:zoneId/leakage-status/',
	zone_leakage_data: '/api/charts/zones/:zoneId/leakage-analysis/',
	zone_leakage_mnf: '/api/charts/zones/:zoneId/leakage-analysis-mnf/',
	zone_leakage_ufl: '/api/charts/zones/:zoneId/leakage-analysis-ufl/',
	// ========================================================================
	get_measurement_points_new: '/api/measurement-points/',
	get_measurement_point_new: '/api/measurement-points/:measurementPointId/',
	get_measurement_points: '/api/networks/:networkId/zones/:zoneId/measurement-points/',
	get_measurement_point: '/api/measurement-points/:measurementPointId/',
	get_measurement_point_latest_readings: '/api/charts/measurement-points/:measurementPointId/last-readings/',
	measurement_point_images_uri: '/api/measurement-points/:measurementPointId/images/',
	measurement_point_files_uri: '/api/measurement-points/:measurementPointId/files/',
	// ========================================================================
	get_alerts: '/api/alerts/alerts/',
	get_all_alerts: '/api/alerts/allAlerts/',
	get_alert: '/api/alerts/alerts/:alertId/',
	get_alerts_new: '/api/alerts/alerts-new/',
	get_alert_new: '/api/alerts/alerts-new/:alertId/',
	get_alert_stats: '/api/charts/alert-statistics/',
	get_alert_type: 'api/configurations/alert-configs/types/',
	alert_configs: '/api/configurations/alert-configs/',
	alert_config: '/api/configurations/alert-configs/:alertId/',
	network_alert_configs: '/api/configurations/networks/:networkId/alert-configs/',
	zone_alert_configs: '/api/configurations/zones/:zoneId/alert-configs/',
	site_alert_configs: '/api/configurations/measurement-points/:siteId/alert-configs/',
	data_source_alert_configs: '/api/configurations/data-sources/:dataSourceId/alert-configs/',
	// ========================================================================
	get_network_data_sources_active_inactive_count:
		'/api/charts/networks/:networkId/data-source-active-inactive-count/',
	get_network_data_sources_type_count: '/api/charts/networks/:networkId/data-source-type-count/',
	get_network_sites_active_inactive_count:
		'/api/charts/networks/:networkId/measurement-points-active-inactive-count/',
	get_network_zones_active_inactive_count: '/api/charts/networks/:networkId/zone-active-inactive-count/',
	get_network_zones_type_count: '/api/charts/networks/:networkId/zone-type-count/',
	get_network_valve_maintenance_statistics: '/api/charts/networks/:networkId/valve-maintenance-statistics/',
	get_network_valve_status_statistics: '/api/charts/networks/:networkId/valve-status-statistics/',
	get_network_valve_type_statistics: '/api/charts/networks/:networkId/valve-type-statistics/',
	get_network_alert_stats: '/api/charts/networks/:networkId/alert-statistics/',
	get_zone_data_source_active_count: '/api/charts/zones/:zoneId/active-data-source-count/',
	get_zone_data_source_type_count: '/api/charts/zones/:zoneId/data-source-type-count/',
	get_zone_site_active_count: '/api/charts/zones/:zoneId/measurement-points-active-inactive-count/',
	get_zone_logs: '/api/charts/zones/:zoneId/logs/',
	get_zone_daily_consumption: '/api/charts/zones/:zoneId/daily-water-consumption/',
	get_zone_min_nightly_flow: '/api/charts/zones/:zoneId/minimum-nightly-flow/',
	get_zone_alert_stats: '/api/charts/zones/:zoneId/alert-statistics/',
	avg_zone_pressure_config_uri: '/api/configurations/zones/:zoneId/average-pressure-analysis-configs/',
	zone_flowrate_config_uri: '/api/configurations/zones/:zoneId/flow-rate-analysis-configs/',
	zone_min_nightly_flow_config_uri: '/api/charts/zones/:zoneId/minimum-nightly-flow-analysis-configs/',
	zone_water_consumption_config_uri: '/api/charts/zones/:zoneId/water-consumption-analysis-configs/',
	get_measurement_point_min_nightly_flow: '/api/charts/measurement-points/:measurementPointId/minimum-nightly-flow/',
	get_measurement_point_daily_consumption:
		'/api/charts/measurement-points/:measurementPointId/daily-water-consumption/',
	site_flowrate_config_uri: '/api/configurations/measurement-points/:siteId/flow-rate-analysis/',
	get_data_sources_logs: '/api/charts/data-sources/:dataSourceId/logs/',
	get_data_source_last_logs: '/api/charts/data-sources/:dataSourceId/last-logs/',
	get_data_source_last_log_date: '/api/charts/data-sources/:dataSourceId/last-log-date/',
	get_average_zone_pressure: '/api/charts/zones/:zoneId/average-zone-pressure/',
	get_average_zone_pressure_stats: '/api/charts/zones/:zoneId/average-zone-pressure-statistics/',
	// ========================================================================
	get_data_sources: '/api/data-sources/',
	get_data_source: '/api/data-sources/:dataSourceId/',
	get_devices: '/api/loggers/',
	get_device: '/api/loggers/:loggerId/',
	get_dashboard_loggers_graphs: '/api/iot/loggers/status/networks/:networkId/graphs/',
	get_dashboard_loggers_table: '/api/iot/loggers/status/networks/:networkId/table/',
	// ========================================================================
	commercial_meter_group_monthly_data: '/api/charts/commercial-meter-groups/:commercialGroupId/monthly-data/',
	network_commercial_first_reading_date:
		'/api/charts/networks/:networkId/commercial-meter-groups/first-reading-date/',
	commercial_meter_group_first_reading_date:
		'/api/charts/commercial-meter-groups/:commercialGroupId/first-reading-date/',
	commercial_site_first_reading_date: '/api/charts/commercial-meter-groups/:siteId/first-reading-date/', //TODO: add this to services
	network_commercial_last_reading_date: '/api/charts/networks/:networkId/commercial-meter-groups/last-reading-date/',
	commercial_meter_group_last_reading_date:
		'/api/charts/commercial-meter-groups/:commercialGroupId/last-reading-date/',
	commercial_site_last_reading_date: '/api/charts/commercial-meter-groups/:siteId/first-reading-date/', //TODO: add this to services
	network_culumative_monthly_readings_count:
		'/api/charts/networks/:networkId/commercial-meter-groups/cumulative-readings-pie-chart/',
	commercial_meter_group_culumative_monthly_readings_count:
		'/api/charts/commercial-meter-groups/:commercialGroupId/cumulative-readings-pie-chart/',
	commercial_site_culumative_monthly_readings_count:
		'/api/charts/commercial-meter-sites/:siteId/cumulative-readings-pie-chart/',
	network_commercial_cumulative_avg_readings:
		'/api/charts/networks/:networkId/commercial-meter-groups/monthly-average/',
	commercial_meter_group_cumulative_avg_readings:
		'/api/charts/commercial-meter-groups/:commercialGroupId/monthly-average/',
	commercial_site_cumulative_avg_readings: '/api/charts/commercial-meter-sites/:siteId/monthly-average/',
	network_commercial_cumulative_readings:
		'/api/charts/networks/:networkId/commercial-meter-groups/cumulative-readings/',
	commercial_meter_group_cumulative_readings:
		'/api/charts/commercial-meter-groups/:commercialGroupId/cumulative-readings/',
	commercial_site_cumulative_readings: '/api/charts/commercial-meter-sites/:siteId/cumulative-readings/',
	commercial_meter_groups: '/api/commercial-meter-groups/',
	commercial_meter_group: '/api/commercial-meter-groups/:commercialGroupId/',
	network_commercial_meter_data_confirmation:
		'/api/networks/:networkId/commercial-meter-group/data-upload-confirmation/',
	network_commercial_meter_data: '/api/networks/:networkId/commercial-meter-group/data-upload/',
	commercial_meter_data_confirmation: '/api/commercial-meter-groups/:commercialGroupId/data-upload-confirmation/',
	commercial_meter_data: '/api/commercial-meter-groups/:commercialGroupId/data-upload/',
	commercial_sites: '/api/commercial-meter-sites/',
	commercial_site: '/api/commercial-meter-sites/:siteId/',
	commercial_data_sources: '/api/commercial-meter-data-sources/',
	// ========================================================================
	site_water_meter_last_reading: '/api/charts/measurement-points/:siteId/water-production-last-reading/',
	site_water_meter_logs: '/api/charts/measurement-points/:siteId/water-production-logs/',
	zone_daily_production: '/api/charts/zones/:zoneId/daily-water-production/',
	site_daily_production: '/api/charts/measurement-points/:siteId/daily-water-production/',
	zone_current_water_production_reading: '/api/charts/zones/:zoneId/current-water-production-reading/',
	site_current_water_production_reading: '/api/charts/measurement-points/:siteId/current-water-production-reading/',
	network_water_breakout: '/api/charts/networks/:networkId/water-production-breakout-by-measurement-point/',
	zone_water_breakout: '/api/charts/zones/:zoneId/water-production-breakout-by-measurement-point/',
	// ========================================================================
	image_uri: '/api/images/:imageId/',
	file_uri: '/api/files/:fileId/',
	// ========================================================================
	signin_uri: '/api/login/',
	// ========================================================================
	foundation_management_uri: '/api/user_management/foundations/',
	// ========================================================================
	applications_management_uri: '/api/user_management/applications/',
	application_management_uri: '/api/user_management/applications/:applicationId/',
	// ========================================================================
	rights_management_uri: '/api/user_management/rights/',
	// ========================================================================
	subsystems_management_uri: '/api/user_management/subsystems/',
	subsystem_management_uri: '/api/user_management/subsystems/:subsystemId/',
	// ========================================================================
	user_management_uri: '/api/user_management/users/',
	user_permissions_uri: '/api/user_management/user_permissions/',
	user_permission_uri: '/api/user_management/user_permissions/:userPermission/'
};

export default URIs;
