export const mergeLogs = (dataObjects: any) => {
	let times: any[] = [];
	for (let i = 0; i < +dataObjects.length; i += 1) {
		dataObjects.forEach((dataObject: any) => {
			dataObject.data.forEach((d: any) => {
				if (d?.timestamp) {
					times.push(d.timestamp);
				} else {
					times.push(d.time);
				}
			});
		});
	}
	times = Array.from(new Set(times));

	times = times.sort(function (left, right) {
		return new Date(left) < new Date(right) ? -1 : 1;
	});
	const mergedLogs: any[] = [];

	times.forEach((time: any, idx: number) => {
		const mergedLog: any = { time };
		dataObjects.forEach((dataObject: any) => {
			mergedLog[dataObject.name] =
				dataObject.data.find((el: any) => el.time === time || el?.timestamp === time)?.value || null;
		});
		mergedLogs.push(mergedLog);
	});

	return mergedLogs;
};
