import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';
import { GraphCard } from '../components';
import { colors } from '../constants/colors';
import { useTranslation } from 'react-i18next';

const COLORS = [colors.GRAPH_PURPLE, colors.GRAPH_GREEN, colors.GRAPH_BLUE, colors.WARNING];

const getBarchartOptions = (englishSelected: boolean, data, dataKeys): ApexOptions => {
	return {
		xaxis: {
			categories: data.map(d => d.name)
		},
		yaxis: {
			labels: {
				show: false
			},
			opposite: !englishSelected
		},

		plotOptions: {
			bar: {
				barHeight: '100%',
				columnWidth: '30%',
				distributed: true,
				horizontal: false,
				dataLabels: {
					position: 'bottom'
				}
			}
		},

		tooltip: {
			theme: 'light',
			x: {
				show: false
			},
			y: {
				title: {
					formatter: function () {
						return '';
					}
				}
			}
		},
		colors:
			dataKeys?.map(key => {
				return key.color;
			}) ?? COLORS
	};
};

export const WaiBarChart: React.FC<any> = ({
	data,
	title,
	emphasizable,
	onChartSelect,
	fieldName,
	dataKeys,
	graphHeight,
	height,
	...restProps
}) => {
	const { i18n } = useTranslation();

	const onEmphasize = (value?: any, field?: string) => {
		if (value && field && onChartSelect) {
			onChartSelect(fieldName, dataKeys);
			// onChartSelect(fieldName, coloredData);
		} else onChartSelect && onChartSelect();
	};

	return (
		<GraphCard
			emphasizable={emphasizable}
			title={title}
			{...restProps}
			onEmphasize={onEmphasize}
			fieldName={fieldName}
			height={height}
		>
			<div style={{ width: '100%' }}>
				<Chart
					type="bar"
					options={getBarchartOptions(i18n.language === 'en', data, dataKeys)}
					series={[
						{
							data: data?.map(d => d.value)
						}
					]}
					height={graphHeight || 200}
				/>
			</div>
		</GraphCard>
	);
};
