import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Breadcrumb, Col, Collapse, Form, Row, Spin, Switch } from 'antd';
import { HTMLAttributes, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DataSourceForm } from '../components';
import { MonitoringMap } from '../containers';
import { useTranslation } from 'react-i18next';
import monitoringService from '../services/monitoring.service';
import alertsService from '../services/alerts.service';
import { useQueryParam } from '../hooks/use-query';
import { NavigationContext } from '../contexts/navigation.context';
import { TiHomeOutline } from 'react-icons/ti';
import { AppRoutes } from '../constants/routes';
import { upperSnakeToCapitalized } from '../utils';
import { colors } from '../constants/colors';
import { AlertsConfiguration } from '../components/alerts-config';

export const DataSourceConfigurationPage: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
	const { t, i18n } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { errorHandler, refetchAllMeasurementPoints } = navigationContext;

	const [selectedLocation, setSelectedLocation] = useState<any>({ latitude: undefined, longitude: undefined });
	const [activatedDataSource, setActivatedDataSource] = useState<boolean | null>(null);
	const [activePanel, setActivePanel] = useState<string[]>(['data_source_basic_info']);

	const [searchParam] = useSearchParams();
	const dataSourceId = searchParam.get('data-source');
	const panelSelected = useQueryParam('panelSelected');
	const [form] = Form.useForm();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	// data-source information
	const {
		data: dataSourceInfo,
		isLoading: loadingDataSourceInfo,
		refetch: refetchDataSourceInfo
	} = useQuery({
		queryKey: ['data-source', dataSourceId],
		queryFn: () => dataSourceId && monitoringService.getDataSource(dataSourceId),
		enabled: !!dataSourceId
	});

	// load data-source alerts configuration
	const {
		data: alertsConfig,
		isLoading: loadingAlertsConfig,
		refetch: refetchAlertsConfig
	} = useQuery<any>({
		queryKey: ['data-source-alerts-config', dataSourceId],
		queryFn: () => dataSourceId && alertsService.getDataSourcesAlertConfigs(dataSourceId),
		enabled: !!dataSourceId
	});

	// load alert types for data-source
	const { data: alertTypes, isLoading: loadingAlertTypes } = useQuery<any>({
		queryKey: ['data-source-alert-type'],
		queryFn: () => alertsService.getAlertTypes({ entity: 'data_source' }),
		enabled: true
	});

	// open panel if is selected
	useEffect(() => {
		if (panelSelected) {
			setActivePanel([panelSelected]);
		}
	}, []);

	// activate/deactivate data-source immediately when switching activation key
	useEffect(() => {
		if (dataSourceId && dataSourceInfo && activatedDataSource !== null) {
			if (activatedDataSource !== dataSourceInfo.is_active) {
				(async () => {
					try {
						const resp = await monitoringService.editDataSource(dataSourceId, {
							is_active: activatedDataSource
						});
						if (!resp) throw new Error(t('couldntEditDataSource', { ns: 'validation' }));
						refetchAllMeasurementPoints();
						refetchDataSourceInfo();
						queryClient.refetchQueries(['data-sources']);
					} catch (e: any) {
						errorHandler(e);
					}
				})();
			}
		}
	}, [activatedDataSource, dataSourceId]);

	// zoom to default data-source location
	useEffect(() => {
		if (dataSourceInfo) {
			setSelectedLocation({
				latitude: dataSourceInfo.latitude,
				longitude: dataSourceInfo.longitude
			});
			setActivatedDataSource(dataSourceInfo.is_active);
		}
	}, [dataSourceInfo]);
	return (
		<div
			style={{
				paddingLeft: i18n.language === 'en' ? '1rem' : '',
				paddingRight: i18n.language === 'en' ? '' : '1rem',
				paddingTop: '2rem'
			}}
		>
			<Spin spinning={!!dataSourceId && loadingDataSourceInfo && loadingAlertTypes && loadingAlertsConfig}>
				<Breadcrumb
					style={{ fontSize: '1rem' }}
					className="mb-3"
					items={[
						{
							title: (
								<div
									className="d-flex align-items-center"
									role="button"
									onClick={() => navigate(`${AppRoutes.CONFIGURATION}?tabSelected=data-sources`)}
								>
									<TiHomeOutline className={i18n.language === 'en' ? 'me-1' : 'ms-1'} />
									<span>{t('DataSourcesConfiguration')}</span>
								</div>
							)
						},
						{
							title: (
								<div style={{ fontWeight: 'bold' }} className="d-flex align-items-center">
									{t('ConfigurationName', {
										name: dataSourceInfo
											? upperSnakeToCapitalized(dataSourceInfo.name_en)
											: t('DataSource')
									})}
									<Switch
										checked={activatedDataSource ?? false}
										checkedChildren={t('Active')}
										unCheckedChildren={t('Inactive')}
										onChange={(value, event) => {
											event.stopPropagation();
											setActivatedDataSource(value);
										}}
										style={{ backgroundColor: activatedDataSource ? colors.HEALTHY : colors.ERROR }}
										className={'mx-2'}
										size="small"
									/>
								</div>
							)
						}
					]}
					separator=">"
				/>
				<Row gutter={24}>
					<Col xs={24} md={14}>
						<Collapse
							accordion
							activeKey={activePanel}
							onChange={(e: any) => {
								setActivePanel(e);
							}}
							size="large"
						>
							<Collapse.Panel
								key={'data_source_basic_info'}
								header={t('DataSourceBasicInfo')}
								style={{ fontWeight: 'bold' }}
							>
								<DataSourceForm
									form={form}
									dataSource={dataSourceInfo}
									setSelectedLocation={setSelectedLocation}
								/>
							</Collapse.Panel>
							{dataSourceId ? (
								<>
									<Collapse.Panel
										key={'data_source_alerts_config'}
										header={t('DataSourceAlertsConfig')}
										style={{ fontWeight: 'bold' }}
									>
										<AlertsConfiguration
											alertsConfig={alertsConfig}
											refechAlertsConfig={refetchAlertsConfig}
											alertTypes={alertTypes}
											createAlertConfig={async values =>
												dataSourceId &&
												(await alertsService.createDataSourceAlertConfig(dataSourceId, values))
											}
										/>
									</Collapse.Panel>
								</>
							) : null}
						</Collapse>
					</Col>
					<Col xs={24} md={10}>
						<MonitoringMap
							style={{
								height: '590px',
								width: 'auto',
								borderRadius: '13px',
								zIndex: 50
							}}
							pointMarker={
								selectedLocation.latitude && selectedLocation.longitude
									? [selectedLocation.latitude, selectedLocation.longitude]
									: undefined
							}
						/>
					</Col>
				</Row>
			</Spin>
		</div>
	);
};
