import { useContext, useEffect, useState } from 'react';
import { Spin, Collapse, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../hooks/use-query';
import { useQuery } from '@tanstack/react-query';
import alertsService from '../../services/alerts.service';
import { SiteForm } from '../site-form';
import { AlertsConfiguration } from '../alerts-config';
import configService from '../../services/config.service';
import { colors } from '../../constants/colors';
import { SiteFlowAnalysisConfiguration } from '../site-flow-analysis-configuration';
import { NavigationContext } from '../../contexts/navigation.context';

const { Panel } = Collapse;

export const SiteConfigurationTab: React.FC<any> = ({
	siteData,
	setSelectedLocation,
	selectedLocation,
	activatedPoint,
	setActivatedPoint
}) => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	const [activePanel, setActivePanel] = useState<string[]>(['site_basic_info']);
	const [activateFlowAnalysis, setActivateFlowAnalysis] = useState<boolean | null>(null);
	const [loadingActivateFlowAnalysis, setLoadingActivateFlowAnalysis] = useState<boolean>(false);

	const siteId = useQueryParam('measurementPointId');
	const panelSelected = useQueryParam('panelSelected');

	// load selected site flow analysis configuration data
	const {
		data: siteFlowAnalysisConfig,
		isLoading: loadingSiteFlowAnalysisConfig,
		refetch: refetchSiteFlowAnalysisConfig
	} = useQuery({
		queryKey: ['site-flow-analysis-config', siteId],
		queryFn: () => siteId && configService.getSiteFlowAnalysisConfig(siteId),
		enabled: !!siteId
	});

	// load aite alerts configuration
	const {
		data: alertsConfig,
		isLoading: loadingAlertsConfig,
		refetch: refetchAlertsConfig
	} = useQuery<any>({
		queryKey: ['site-alerts-config', siteId],
		queryFn: () => siteId && alertsService.getSitesAlertConfigs(siteId),
		enabled: !!siteId
	});

	// load alert types for site
	const { data: alertTypes, isLoading: loadingAlertTypes } = useQuery<any>({
		queryKey: ['site-alert-types'],
		queryFn: () => alertsService.getAlertTypes({ entity: 'measurement_point' }),
		enabled: true
	});

	// open panel if is selected
	useEffect(() => {
		if (panelSelected) {
			setActivePanel([panelSelected]);
		}
	}, []);

	// set activation/deactivation default data for flow analysis
	useEffect(() => {
		if (siteFlowAnalysisConfig) {
			setActivateFlowAnalysis(siteFlowAnalysisConfig.is_active);
		}
	}, [siteFlowAnalysisConfig]);

	// activate/deactivate flow analysis immediately when switching activation key
	useEffect(() => {
		(async () => {
			if (activateFlowAnalysis !== null && siteFlowAnalysisConfig) {
				if (siteFlowAnalysisConfig.is_active !== activateFlowAnalysis) {
					try {
						setLoadingActivateFlowAnalysis(true);
						const resp = await (siteId &&
							configService.editSiteFlowAnalysisConfig(siteId, {
								is_active: activateFlowAnalysis
							}));
						if (!resp) throw new Error(t('couldntEditSiteAnalysisConfig', { ns: 'validation' }));
						refetchSiteFlowAnalysisConfig();
					} catch (e: any) {
						errorHandler(e);
					} finally {
						setLoadingActivateFlowAnalysis(false);
					}
				}
			}
		})();
	}, [activateFlowAnalysis, siteFlowAnalysisConfig]);

	return (
		<Spin spinning={!!siteId && (loadingAlertTypes || loadingAlertsConfig || loadingSiteFlowAnalysisConfig)}>
			<Collapse
				accordion
				activeKey={activePanel}
				onChange={(e: any) => {
					setActivePanel(e);
				}}
				size="large"
			>
				<Panel key={'site_basic_info'} header={t('SiteBasicInfo')} style={{ fontWeight: 'bold' }}>
					<SiteForm
						siteData={siteData}
						setSelectedLocation={setSelectedLocation}
						selectedLocation={selectedLocation}
						activatedPoint={activatedPoint}
						setActivatedPoint={setActivatedPoint}
					/>
				</Panel>
				{siteId ? (
					<>
						<Panel
							header={t('FlowAnalysis')}
							key={'flow_analysis'}
							style={{ fontWeight: 'bold' }}
							extra={
								<Switch
									checked={activateFlowAnalysis ?? false}
									checkedChildren={t('Active')}
									unCheckedChildren={t('Inactive')}
									onChange={(value, event) => {
										event.stopPropagation();
										setActivateFlowAnalysis(value);
									}}
									style={{ backgroundColor: activateFlowAnalysis ? colors.HEALTHY : colors.ERROR }}
								/>
							}
						>
							<Spin spinning={loadingSiteFlowAnalysisConfig || loadingActivateFlowAnalysis}>
								<SiteFlowAnalysisConfiguration />
							</Spin>
						</Panel>
						<Panel
							key={'site_alerts_config'}
							header={t('SiteAlertsConfiguration')}
							style={{ fontWeight: 'bold' }}
						>
							<AlertsConfiguration
								alertsConfig={alertsConfig}
								refechAlertsConfig={refetchAlertsConfig}
								alertTypes={alertTypes}
								createAlertConfig={async values =>
									siteId && (await alertsService.createSiteAlertConfig(siteId, values))
								}
							/>
						</Panel>
					</>
				) : null}
			</Collapse>
		</Spin>
	);
};
