import { useQuery } from '@tanstack/react-query';
import { Button, Card, Modal, Select, Spin, Table, TableProps, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { AuthenticationContext } from '../../contexts';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { transformZonesToRecord } from '../../mappers/zone-to-record';
import monitoringService from '../../services/monitoring.service';
import { UserRights, UserApps, Zones } from '../../types';
import { IZoneRecord } from '../../types/zone-record';
import { formatFilters } from '../../utils/get-table-filters';
import { formatSorter } from '../../utils/get-table-sorter';
import { hasRight } from '../../utils/has-right';
import { notifySuccess } from '../../utils/notification-messages';
import { ComponentGuard } from '../component-guard';
import { TableControls } from '../table-controls';
import { modalConfirm } from '../modal-confirm';
import { upperSnakeToCapitalized } from '../../utils';
import { TableControlItem } from '../table-controls/styles/table-controls';
import { colors } from '../../constants/colors';
import { MdOutlineUploadFile } from 'react-icons/md';
import { CommercialSitesSummaryTable } from '../commercial-sites-summary-table';

export const ZonesTable: React.FC<TableProps<IZoneRecord>> = () => {
	const { t, i18n } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const {
		networks,
		errorHandler,
		selectedZone,
		setSelectedZone,
		setSelectedMeasurementPoint,
		refetchNetworks,
		refetchAllZones,
		refetchAllMeasurementPoints,
		applicationIdMap
	} = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user, configurationPermessions } = authContext;

	const [zonesSorters, setZonesSorters] = useState<any>({});
	const [zonesFilters, setZonesFilters] = useState<any>({});
	const [expandedZone, setExpandedZone] = useState<any>([]);
	const [selectedType, setSelectedType] = useState<Zones>(Zones.DMA);
	const [visibleModalZoneType, setVisibleModalZoneType] = useState<any>(false);

	const navigate = useNavigate();

	// load zones
	const {
		data: zonesData,
		refetch: refetchZonesConfig,
		isLoading: loadingZones
	} = useQuery<any>({
		queryKey:
			(zonesSorters && Object.keys(zonesSorters)?.length) || (zonesFilters && Object.keys(zonesFilters)?.length)
				? ['zones', zonesSorters, zonesFilters]
				: ['zones'],
		queryFn: () =>
			monitoringService.getZonesAll(
				(zonesSorters && Object.keys(zonesSorters)?.length) ||
					(zonesFilters && Object.keys(zonesFilters)?.length)
					? {
							sorters: { ...zonesSorters },
							filters: { ...zonesFilters }
					  }
					: undefined
			)
	});

	// filter zones with: network
	const onZonesFilter = async (filters: any) => {
		if (JSON.stringify(filters) === JSON.stringify(zonesFilters)) return;
		const filteredValues = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value !== undefined));
		if (Object.keys(filteredValues).length > 0) {
			await setZonesFilters(filteredValues);
			await refetchZonesConfig();
		} else {
			await setZonesFilters(undefined);
			await refetchZonesConfig();
		}
	};

	// sort zones with: created_at
	const onZonesSort = async (sorters: any) => {
		if (JSON.stringify(sorters) === JSON.stringify(zonesSorters)) return;
		const sortedValues = Object.fromEntries(Object.entries(sorters).filter(([_, value]) => value !== undefined));
		if (Object.keys(sortedValues).length > 0) {
			await setZonesSorters(sortedValues);
			await refetchZonesConfig();
		} else {
			await setZonesSorters(undefined);
			await refetchZonesConfig();
		}
	};

	const handleTableChange = (newPagination: any, filters: any, sorters: any) => {
		onZonesFilter && onZonesFilter(formatFilters(filters));
		onZonesSort && onZonesSort(formatSorter(sorters));
	};

	const zonesColumns: any = [
		{
			title: t('Zone'),
			dataIndex: i18n.language === 'en' ? 'name_en' : 'name_ar',
			key: i18n.language === 'en' ? 'name_en' : 'name_ar',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Description'),
			dataIndex: i18n.language === 'en' ? 'description_en' : 'description_ar',
			key: i18n.language === 'en' ? 'description_en' : 'description_ar',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Type'),
			dataIndex: 'type',
			key: 'type',
			align: 'center',
			filters: Object.values(Zones).map(type => ({
				text: type,
				value: type
			})),
			filterMultiple: false,
			render: (type: Zones) => (type === Zones.COMMERCIAL_METER ? upperSnakeToCapitalized(type) : type)
		},
		{
			title: t('Latitude'),
			dataIndex: 'latitude',
			key: 'latitude',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Longitude'),
			dataIndex: 'longitude',
			key: 'longitude',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('RelatedNetwork'),
			dataIndex: 'network',
			key: 'network',
			align: 'center',
			filters:
				networks &&
				networks.map(network => ({
					text: i18n.language === 'en' ? network.name_en : network.name_ar,
					value: network.id
				})),
			filterMultiple: false,
			render: (networkId: string) => {
				const matchedNetwork = networks && networks.find(network => network.id === networkId);
				return matchedNetwork ? (i18n.language === 'en' ? matchedNetwork.name_en : matchedNetwork.name_ar) : '';
			}
		},
		{
			title: t('DateCreated'),
			dataIndex: 'created_at',
			key: 'created_at',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: Date) => dayjs(text).format('dddd, MMMM D, YYYY h:mm A')
		}
	];
	if (zonesData?.find(zone => zone.type === Zones.COMMERCIAL_METER)) {
		zonesColumns.push({
			title: t('Options'),
			key: 'actions',
			align: 'center',
			render: (_: any, record: IZoneRecord) => {
				if (record.type === Zones.COMMERCIAL_METER)
					return (
						<Tooltip title={t('UploadFile')}>
							<TableControlItem
								onClick={() => {
									navigate(
										`${AppRoutes.COMMERCIAL_METER_GROUP_CONFIGURATION}?commercialMeterGroupId=${record.id}&uploadFile=true`
									);
								}}
							>
								<MdOutlineUploadFile color={colors.WAI_BLUE} />
							</TableControlItem>
						</Tooltip>
					);
			}
		});
	}

	zonesColumns.push({
		title: t('Actions'),
		key: 'actions',
		align: 'center',
		render: (_: any, record: IZoneRecord) => (
			<TableControls
				networkId={record.network}
				notShowDelete={record.type === Zones.COMMERCIAL_METER}
				onEdit={() => {
					if (record.type === Zones.COMMERCIAL_METER) {
						navigate(
							`${AppRoutes.COMMERCIAL_METER_GROUP_CONFIGURATION}?commercialMeterGroupId=${record.id}`
						);
					} else {
						navigate(`${AppRoutes.ZONE_CONFIGURATION}?zoneId=${record.id}`);
					}
				}}
				onRemove={() => onRemove(record.id, record.type)}
				userApp={UserApps.ZONE}
			/>
		)
	});

	const onRemove = (id: string, type: Zones) => {
		try {
			modalConfirm({
				content:
					type === Zones.COMMERCIAL_METER
						? t('YouAreAboutToDeleteCommercialMeterGroup')
						: t('YouAreAboutToDeleteZone'),
				onOk: async () => {
					if (type === Zones.COMMERCIAL_METER) {
						await monitoringService.deleteCommercialMeterGroup(id);
						if (id === selectedZone) {
							await setSelectedZone(undefined);
							await setSelectedMeasurementPoint(undefined);
						}
					} else {
						await monitoringService.deleteZone(id);
						if (id === selectedZone) {
							await setSelectedZone(undefined);
							await setSelectedMeasurementPoint(undefined);
						}
					}
					notifySuccess(t('RemovedSuccessfully'));
					refetchZonesConfig();
					refetchNetworks();
					refetchAllZones();
					refetchAllMeasurementPoints();
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	const handleCreation = () => {
		if (selectedType === Zones.COMMERCIAL_METER) {
			navigate(`${AppRoutes.COMMERCIAL_METER_GROUP_CONFIGURATION}?type=${selectedType}`);
		} else {
			navigate(`${AppRoutes.ZONE_CONFIGURATION}?type=${selectedType}`);
		}
	};

	return (
		<>
			<Spin spinning={loadingZones}>
				<Table
					tableLayout="auto"
					scroll={{ x: true }}
					columns={zonesColumns}
					dataSource={zonesData ? transformZonesToRecord(zonesData) : []}
					size="small"
					showSorterTooltip
					rowKey="id"
					onChange={handleTableChange}
					expandable={{
						expandedRowRender: record => {
							if (record.type === Zones.COMMERCIAL_METER)
								return (
									<div key={record.id}>
										<Card>
											<CommercialSitesSummaryTable commercialMeterGroupId={record.id} />
										</Card>
									</div>
								);
						},
						rowExpandable: record => record.type === Zones.COMMERCIAL_METER,
						expandedRowKeys: expandedZone,
						onExpand: (expanded, record) => {
							if (expanded) {
								setExpandedZone([record.id]);
							} else {
								setExpandedZone([]);
							}
						}
					}}
				/>
			</Spin>
			<ComponentGuard
				allowed={hasRight(
					!!user?.user_data?.is_superAdmin,
					configurationPermessions,
					applicationIdMap.get(UserApps.ZONE),
					UserRights.CREATE
				)}
			>
				<div className="d-flex justify-content-end mt-3">
					<Button type="primary" onClick={() => setVisibleModalZoneType(true)}>
						{t('AddZone')}
					</Button>
				</div>
			</ComponentGuard>
			<Modal
				width={'30%'}
				open={visibleModalZoneType}
				title={t('SelectTypeOfSite')}
				onCancel={() => setVisibleModalZoneType(false)}
				onOk={handleCreation}
				centered={true}
			>
				<Select
					options={Object.values(Zones).map(zone => {
						return {
							value: zone,
							label: zone === Zones.COMMERCIAL_METER ? upperSnakeToCapitalized(zone) : zone
						};
					})}
					defaultValue={Zones.DMA}
					onChange={value => setSelectedType(value)}
					style={{ width: '100%' }}
				/>
			</Modal>
		</>
	);
};
