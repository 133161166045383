import { DatePicker } from 'antd';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { PressureUnit } from '../../types/pressure-unit';
import { upperSnakeToCapitalized } from '../../utils/upper-snake-to-capitalized';
import { GraphSpanSelectorContainer } from './styles/graph-span-selector';
const { RangePicker } = DatePicker;

export const GraphUnitSelector: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		onUnitChange: (unit: PressureUnit[]) => void;
		selectedUnit: PressureUnit;
	}
> = ({ onUnitChange, selectedUnit = PressureUnit.BAR, ...restProps }) => {
	const { i18n } = useTranslation();

	return (
		<GraphSpanSelectorContainer englishSelected={i18n.language === 'en'} {...restProps}>
			{Object.values(PressureUnit).map((unit, idx) => {
				return (
					<>
						<span
							className={unit === selectedUnit ? 'selected-span' : ''}
							onClick={() => {
								onUnitChange([selectedUnit, unit]);
							}}
						>
							{upperSnakeToCapitalized(unit)}
						</span>
						{idx === Object.values(PressureUnit).length - 1 ? null : <p>|</p>}
					</>
				);
			})}
		</GraphSpanSelectorContainer>
	);
};
