export enum StationType {
	RESERVOIR = 'RESERVOIR',
	PUMPING_STATION = 'PUMPING_STATION',
	TREATMENT_STATION = 'TREATMENT_STATION',
	DESALINATION_STATION = 'DESALINATION_STATION',
	OTHER = 'OTHER'
}

export enum StationOperationalStatus {
	NORMAL = 'NORMAL',
	NEEDS_INVESTIGATION = 'NEEDS_INVESTIGATION',
	OPERATION_ERROR = 'OPERATION_ERROR',
	OTHER = 'OTHER'
}
