import { HTMLAttributes, useContext } from 'react';
import { BrandingLabelContext } from '../../contexts/branding-label';
import { useTranslation } from 'react-i18next';
import { Backdrop } from './styles/backdrop';
import { SideDrawerContainer } from './styles/side-drawer';

interface ISideDrawerProps {
	visible: boolean;
	onCollapse: (x: boolean) => void;
	collapsed: boolean;
}

export const SideDrawer = ({ visible, onClose, collapsable, onCollapse, collapsed, children, media }) => {
	const { i18n } = useTranslation();
	const labelContext = useContext(BrandingLabelContext);
	const { label } = labelContext;
	return (
		<SideDrawerContainer
			label={label}
			englishSelected={i18n.language === 'en'}
			className={[visible ? 'open' : 'closed', collapsed ? null : 'not-collapsed'].join(' ')}
			media={media}
			onMouseEnter={() => onCollapse(false)}
			onMouseLeave={() => onCollapse(true)}
		>
			<Backdrop show={visible} clicked={onClose} media={media} />
			<div className="sidedrawer-children">{children}</div>
			{/* {collapsable ? <SideDrawerToggle collapsed={collapsed} /> : null} */}
		</SideDrawerContainer>
	);
};
