import { Table, TableProps } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { upperSnakeToCapitalized } from '../../utils';
import { mergeLogs } from '../../utils/merge-logs';

export const ChartTable: React.FC<
	TableProps<any> & {
		dataObjects: any;
		horizontal?: boolean;
	}
> = ({ dataObjects, horizontal }) => {
	const { t } = useTranslation();
	const [tableData, setTableData] = useState<any>();
	const [tableColumns, setTableColumns] = useState<any>();

	useEffect(() => {
		if (dataObjects && dataObjects.length) {
			if (horizontal) {
				setTableData(dataObjects);
			} else {
				setTableData(mergeLogs(dataObjects));
			}
		}
	}, [dataObjects, dataObjects?.length, horizontal]);

	useEffect(() => {
		if (tableData && tableData.length) {
			const tableKeys = Object.keys(tableData[0]);
			setTableColumns([
				...tableKeys.map((key: string) => {
					return {
						title: key == 'time' ? upperSnakeToCapitalized(t('time')) : upperSnakeToCapitalized(key),
						dataIndex: key,
						width: '25',
						key,
						align: 'center',
						fixed: key == 'time' && !horizontal ? 'left' : 'false',
						render: text =>
							key === 'time'
								? dayjs(text).format('dddd, MMMM D, YYYY h:mm A')
								: typeof text === 'string'
								? text
								: text
								? parseFloat(text).toFixed(3)
								: '-'
					};
				})
			]);
		}
	}, [tableData, tableData?.length]);

	return tableData?.length && tableColumns?.length ? (
		<Table
			dataSource={tableData.map((el, idx) => ({ key: idx, ...el }))}
			columns={tableColumns}
			pagination={false}
			scroll={{ y: 550, x: tableColumns?.length > 6 ? 2000 : 0 }}
			style={{ height: 550 }}
		/>
	) : null;
};
