import { useContext, useEffect, useState } from 'react';
import { Form, Input, InputNumber, Switch, Select, Spin } from 'antd';
import { FiCheck } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { PressureType, DataSources, UserApps, UserRights } from '../../types';
import { hasRight, upperSnakeToCapitalized } from '../../utils';
import monitoringService from '../../services/monitoring.service';
import { notifySuccess } from '../../utils/notification-messages';
import { NavigationContext } from '../../contexts/navigation.context';
import { AppRoutes } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { AuthenticationContext } from '../../contexts';
import { SubmitCanelButtons } from '../submit-cancel-buttons';

export const DataSourceForm: React.FC<any> = ({ form, dataSource, refetchSiteData, siteData }) => {
	const { t } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { errorHandler, networks, applicationIdMap } = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user, configurationPermessions } = authContext;

	const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const navigate = useNavigate();
	const queryClient = useQueryClient();

	// load sites for accessible network
	const networksPoints: any = useQueries<any>({
		queries: (
			(networks &&
				networks.filter((network: any) =>
					siteData
						? hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.MEASUREMENT_POINT),
								UserRights.EDIT,
								network.id
						  )
						: hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.MEASUREMENT_POINT),
								UserRights.CREATE,
								network.id
						  )
				)) ||
			[]
		).map((network: any) => {
			return {
				queryKey: ['sites', network.id],
				queryFn: () => monitoringService.getMeasurementPointsAll({ network: network.id }),
				enabled: !!network.id
			};
		})
	});

	// set inital fields when edit data source
	useEffect(() => {
		if (dataSource) {
			resetFields();
		}
	}, [dataSource]);

	// submit edition or creation for data source
	const onFinishDataSource = async (values: any) => {
		setSubmitting(true);
		// to edit
		if (dataSource) {
			try {
				const resp = await monitoringService.editDataSource(dataSource.id, values);
				if (!resp) throw new Error(t('couldntAddDataSource', { ns: 'validation' }));
				queryClient.refetchQueries(['data-sources']);
				queryClient.refetchQueries(['site', values.measurement_point]);
				queryClient.refetchQueries(['data-source', dataSource.id]);
				notifySuccess(t('editedSuccessfully'));
				if (siteData) {
					refetchSiteData();
				} else {
					navigate(`${AppRoutes.CONFIGURATION}?tabSelected=data-sources`);
				}
			} catch (e: any) {
				errorHandler(e);
			}
		}
		// to create
		else {
			try {
				if (siteData?.id) {
					const resp = await monitoringService.createDataSource({
						...values,
						measurement_point: siteData.id
					});
					if (!resp) throw new Error(t('couldntEditDataSource', { ns: 'validation' }));
					refetchSiteData();
				} else {
					const resp = await monitoringService.createDataSource(values);
					if (!resp) throw new Error(t('couldntEditDataSource', { ns: 'validation' }));
					navigate(`${AppRoutes.CONFIGURATION}?tabSelected=data-sources`);
				}
				queryClient.refetchQueries(['data-sources']);
				queryClient.refetchQueries(['site', values.measurement_point]);
				notifySuccess(t('addedSuccessfully'));
			} catch (e: any) {
				errorHandler(e?.detail ? { message: e.detail } : e);
			}
		}
		setSubmitting(false);
	};

	// reset fields
	const resetFields = () => {
		if (dataSource) {
			form.setFieldsValue({
				...dataSource
			});
		}
		setIsFormChanged(false);
	};

	const handleCancel = () => {
		navigate(`${AppRoutes.CONFIGURATION}?tabSelected=data-sources`);
	};

	return (
		<Spin spinning={!(networksPoints && networksPoints?.every(network => network.isSuccess)) || submitting}>
			<Form
				layout="vertical"
				form={form}
				onFinish={onFinishDataSource}
				initialValues={{
					is_active: true,
					is_primary: true,
					channel: 1,
					type: DataSources.PRESSURE,
					stream_direction: PressureType.STREAM
				}}
				onValuesChange={() => setIsFormChanged(true)}
			>
				<Form.Item
					name="name_en"
					label={t('Name')}
					rules={[{ required: true, message: t('AddDataSourceName', { ns: 'validation' }) }]}
				>
					<Input type="text" placeholder={t('DataSourceName')} />
				</Form.Item>
				{!siteData ? (
					<Form.Item
						name="measurement_point"
						label={t('RelatedMeasurementPoint')}
						rules={[{ required: true, message: t('SelectRelatedMeasurementPoint', { ns: 'validation' }) }]}
					>
						<Select
							placeholder={t('RelatedMeasurementPoint')}
							options={
								(networksPoints &&
									networksPoints?.every(network => network.isSuccess) &&
									networksPoints
										.map(network => {
											return network.data;
										})
										.reduce((result, points) => {
											return result.concat(
												points.filter(point => !result.some(o => o.id === point.id))
											);
										}, [])
										.map((point: any) => {
											return {
												value: point?.id,
												label: point?.name_en || point.id
											};
										})) ||
								[]
							}
						/>
					</Form.Item>
				) : null}
				<Form.Item
					name="channel"
					label={t('Channel')}
					rules={[{ required: true, message: t('PleaseAddChannelNumber', { ns: 'validation' }) }]}
				>
					<InputNumber placeholder={t('Channel')} style={{ width: '100%' }} min={0} max={3} />
				</Form.Item>
				<Form.Item name={'type'} label={t('Type')}>
					<Select placeholder={t('GISLayerType')}>
						<Select.Option value={DataSources.PRESSURE}>
							{t(upperSnakeToCapitalized(DataSources.PRESSURE))}
						</Select.Option>
						<Select.Option value={DataSources.FLOW_RATE}>{t(DataSources.FLOW_RATE)}</Select.Option>
						<Select.Option value={DataSources.NOISE}>
							{t(upperSnakeToCapitalized(DataSources.NOISE))}
						</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item name={'stream_direction'} label={t('StreamDirection')}>
					<Select placeholder={t('GISLayerType')}>
						<Select.Option value={PressureType.STREAM}>
							{upperSnakeToCapitalized(t('stream'))}
						</Select.Option>
						<Select.Option value={PressureType.UPSTREAM}>
							{upperSnakeToCapitalized(t('upstream'))}
						</Select.Option>
						<Select.Option value={PressureType.DOWNSTREAM}>
							{upperSnakeToCapitalized(t('downstream'))}
						</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item name="is_active" label={t('IsActive')} valuePropName="checked">
					<Switch checkedChildren={<FiCheck />} unCheckedChildren={<AiOutlineClose />} />
				</Form.Item>
				<Form.Item name="is_primary" label={t('IsPrimary')} valuePropName="checked">
					<Switch checkedChildren={<FiCheck />} unCheckedChildren={<AiOutlineClose />} />
				</Form.Item>
				{!siteData ? (
					<div className="d-flex justify-content-end">
						<SubmitCanelButtons
							handleCancel={() => resetFields()}
							handleSubmit={form.submit}
							reset={true}
							disabled={!isFormChanged}
						/>
					</div>
				) : null}
			</Form>
		</Spin>
	);
};
