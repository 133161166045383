import { Input, InputNumber } from 'antd';
import styled, { css } from 'styled-components/macro';
import { colors } from '../../../constants/colors';

const inputStyles = css`
	min-height: 45px;
	background-color: ${colors.LIGHT_GREY6};

	&::placeholder {
	}
	&.ant-input:hover,
	&.ant-input:focus,
	&.ant-input-focused {
		border-color: ${colors.WAI_BLUE};
	}

	/* input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active  */
	.ant-form-item-has-warning &,
	.ant-form-item-has-warning &-affix-wrapper,
	.ant-form-item-has-warning &:hover,
	.ant-form-item-has-warning &-affix-wrapper:hover {
		color: ${colors.WAI_BLUE};
		background-color: transparent;
	}

	.ant-form-item-has-error &,
	.ant-form-item-has-error &-affix-wrapper,
	.ant-form-item-has-error &:hover,
	.ant-form-item-has-error &-affix-wrapper:hover {
		color: ${colors.ERROR};
		background-color: transparent !important;
	}

	&[disabled] {
		color: ${colors.LIGHT_GREY8};
		background-color: ${colors.GRAPH_BLUE};
		cursor: not-allowed;
		opacity: 1;
	}
	input,
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		background-color: transparent;
		transition: background-color 5000s ease-in-out 0s;
	}
`;

export const WaiInput = styled(Input)`
	${inputStyles}
`;

export const WaiInputNumber = styled(InputNumber)`
	${inputStyles}
	height: 45px;
	width: 100%;
	&.ant-input-number:not([disabled]):hover,
	.dark .ant-form-item-has-error .ant-picker:not([disabled]):hover {
		background-color: ${colors.LIGHT_GREY6};
	}
	&.ant-input-number:hover,
	&.ant-input-number:focus,
	&.ant-input-number-focused {
		border-color: ${colors.WAI_BLUE};
	}
	.ant-input-number-handler-wrap {
		background-color: transparent;
	}
	.ant-input-number-input-wrap {
		height: 100%;
		width: 100%;
		input {
			height: 100%;
		}
	}
`;

export const WaiPasswordInput = styled(Input.Password)`
	${inputStyles}

	&.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused,
	&.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
		border-color: ${colors.WAI_BLUE};
	}

	&.ant-input-affix-wrapper > input.ant-input {
		background-color: transparent;
	}

	.ant-input-password-icon {
		color: ${colors.LIGHT_GREY7};
	}
`;

export const WaiSearchInput = styled(Input.Search)`
	// ${inputStyles}
	.ant-input-wrapper.ant-input-group {
		input,
		.ant-input-group-addon button {
			height: ${({ height }) => height || '44px'};
		}
		input {
			border-right: none;
			border-top-left-radius: 0.3rem;
			border-bottom-left-radius: 0.3rem;
		}
		.ant-input-group-addon button {
			border-left: none;
			border-radius: 0 0.3rem 0.3rem 0 !important;
		}
	}

	.ant-input:hover,
	.ant-input:focus,
	.ant-btn:hover,
	.ant-btn:focus {
		border-color: transparent;
	}
	&:hover .ant-input,
	&:hover .ant-btn {
		border-color: ${colors.GRAPH_BLUE};
	}
	&:focus .ant-input,
	&:focus .ant-btn {
		border-color: ${colors.GRAPH_BLUE};
	}
`;

export const WaiTextArea = styled(Input.TextArea).attrs({ rows: 4 })`
	${inputStyles}
`;
