import { Col, Row } from 'antd';
import React from 'react';
import { Signin } from '../containers';

export const SigninPage: React.FC = () => (
	<div>
		<Row gutter={16} className="d-flex justify-content-center mt-5">
			<Col xs={24} sm={16} md={10} className="d-flex flex-column align-items-center mt-5">
				<div className="w-100">
					<Signin />
				</div>
			</Col>
		</Row>
	</div>
);
