import { Card, Col, Empty, Row } from 'antd';
import { useContext, useState } from 'react';
import { NavigationContext } from '../contexts/navigation.context';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { CommercialMeterGroupTable } from '../components/commercial-meter-group-table';
import chartsService from '../services/charts.service';

export const CommercialMeterAnalysisVirtualTab: React.FC<{ sourceName: string }> = ({ sourceName }) => {
	const navigationContext = useContext(NavigationContext);
	const { selectedCommercialMeterGroup } = navigationContext;

	const [tableDate, setTableDate] = useState<any>();

	const { data: firstReadingsDate, isLoading: loadingFirstReadingsDate } = useQuery({
		queryKey: ['commercial-meter-group-first-reading', selectedCommercialMeterGroup],
		queryFn: () => chartsService.getCommercialMeterGroupFirstDate(selectedCommercialMeterGroup),
		enabled: !!selectedCommercialMeterGroup
	});
	const { data: lastReadingsDate, isLoading: loadingLastReadingsDate } = useQuery({
		queryKey: ['commercial-meter-group-last-reading', selectedCommercialMeterGroup],
		queryFn: () => chartsService.getCommercialMeterGroupLastDate(selectedCommercialMeterGroup),
		enabled: !!selectedCommercialMeterGroup
	});

	// load commercial meter group monthly data
	const {
		data: commercialMeterGroupdData,
		isLoading: loadingCommercialMeterGroupdData,
		isFetching: fetchingCommercialMeterGroupdData
	} = useQuery<any>({
		queryKey: [
			'commercial-meter-group-monthly-data',
			selectedCommercialMeterGroup,
			tableDate,
			lastReadingsDate?.date
		],
		queryFn: () =>
			selectedCommercialMeterGroup &&
			chartsService.getCommercialMeterGroupMonthlyData(selectedCommercialMeterGroup, {
				date: tableDate
					? dayjs(tableDate?.date).format('YYYY-MM-DD')
					: lastReadingsDate
					? dayjs(lastReadingsDate?.date).format('YYYY-MM-DD')
					: undefined
			}),
		enabled: !!selectedCommercialMeterGroup && (!!tableDate || !!lastReadingsDate?.date)
	});

	return (
		<>
			<Row>
				<Col xs={24}>
					{!loadingFirstReadingsDate &&
					firstReadingsDate?.date !== null &&
					firstReadingsDate?.date !== undefined ? (
						<CommercialMeterGroupTable
							tableData={commercialMeterGroupdData?.data || []}
							loading={
								loadingLastReadingsDate ||
								loadingFirstReadingsDate ||
								(loadingCommercialMeterGroupdData && fetchingCommercialMeterGroupdData)
							}
							firstReadingsDate={firstReadingsDate}
							lastReadingsDate={lastReadingsDate}
							changeDate={true}
							setTableDate={setTableDate}
							sourceName={sourceName}
						/>
					) : (
						<Card>
							<Empty />
						</Card>
					)}
				</Col>
			</Row>
		</>
	);
};
