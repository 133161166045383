import { Col, Empty, Row, Spin } from 'antd';
import { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { NavigationContext } from '../contexts/navigation.context';
import chartsService from '../services/charts.service';
import { FlowRateType, SpanType, customSpanSelector, GraphSpan } from '../types';
import { FlowRateUnit } from '../types/flow-rate-unit';
import { clearArrayUndefined } from '../utils/clean-array-undefined';
import { WaiNumericalChart } from './numerical-chart';
import { TimeSeriesBar } from './time-series-bar';
import { useQuery, useQueries } from '@tanstack/react-query';
import { mean } from 'stats-lite';
import { capitalizeWords } from '../utils/capitalize-string-words';
import { useTranslation } from 'react-i18next';

export const SiteAnalysisWaterProductionTab: React.FC<{
	siteName: any;
}> = ({ siteName }) => {
	const { t } = useTranslation();
	const [chartDateWaterProduction, setChartDateWaterProduction] = useState<{ start?: string; date?: string }>();
	const [chartTimeSpanWaterConsumption, setChartTimeSpanWaterConsumption] = useState<{
		start: string;
		end: string;
	}>();
	const [chartTimeSpanWaterProduction, setChartTimeSpanWaterProduction] = useState<{
		start: string;
		end: string;
		date: string;
	}>();
	const [spanType, setSpanType] = useState<SpanType>(SpanType.DAY);
	const [spanTypeBarChart, setSpanTypeBarChart] = useState<SpanType>(SpanType.DAY);

	const navigationContext = useContext(NavigationContext);
	const { selectedMeasurementPoint } = navigationContext;

	// Last water production readings for day, week, month
	const waterProductionLastDates: any = useQueries<any>({
		queries: (Object.keys(customSpanSelector) as (keyof typeof customSpanSelector)[]).map((span: any) => {
			return {
				queryKey: ['water-production-last', selectedMeasurementPoint, span],
				queryFn: () =>
					chartsService.getSiteDailyProduction(selectedMeasurementPoint, {
						last: true,
						span_type: span.toLowerCase()
					}),
				enabled: !!selectedMeasurementPoint && !!span
			};
		})
	});
	// All water production readings regarding the days period
	const { data: waterProducionAllData } = useQuery<any>({
		queryKey: ['water-production-all-data', selectedMeasurementPoint],
		queryFn: () =>
			chartsService.getSiteDailyProduction(selectedMeasurementPoint, {
				span_type: 'day'
			}),
		enabled: !!selectedMeasurementPoint
	});

	// Water production reading relative to numerical chart
	const { data: waterProductionDateChart, isLoading: loadingWaterDate } = useQuery<any>({
		queryKey: [
			'water-production-data',
			selectedMeasurementPoint,
			spanType,
			chartDateWaterProduction,
			waterProductionLastDates[Object.values(SpanType).indexOf(spanType)]?.data
		],
		queryFn: () =>
			chartsService.getSiteDailyProduction(selectedMeasurementPoint, {
				start:
					spanType === SpanType.DAY
						? undefined
						: chartDateWaterProduction?.start
						? dayjs(chartDateWaterProduction.start).format('YYYY-MM-DD')
						: waterProductionLastDates[Object.values(SpanType).indexOf(spanType)]?.data?.start &&
						  dayjs(
								waterProductionLastDates[Object.values(SpanType).indexOf(spanType)]?.data?.start
						  ).format('YYYY-MM-DD'),
				date:
					spanType !== SpanType.DAY
						? undefined
						: chartDateWaterProduction?.date
						? dayjs(chartDateWaterProduction.date).format('YYYY-MM-DD')
						: waterProductionLastDates[0]?.data?.data?.length
						? dayjs(waterProductionLastDates[0]?.data?.data[0]?.time).format('YYYY-MM-DD')
						: dayjs().subtract(1, 'days').startOf(spanType).format('YYYY-MM-DD'),
				span_type: spanType
			}),

		enabled: (!!chartDateWaterProduction || !!waterProductionLastDates) && !!selectedMeasurementPoint && !!spanType
	});

	// water consumtion readings relative to bar chart over a period of days
	const { data: waterConsumptionTimeSpanChart, isLoading: loadingWaterChart } = useQuery<any>({
		queryKey: ['water-consumption-data', chartTimeSpanWaterConsumption, selectedMeasurementPoint],
		queryFn: () =>
			chartsService.getMeasurementPointDailyConsumption(selectedMeasurementPoint, {
				start: chartTimeSpanWaterConsumption
					? dayjs(chartTimeSpanWaterConsumption.start).format('YYYY-MM-DD')
					: undefined,
				end: chartTimeSpanWaterConsumption
					? dayjs(chartTimeSpanWaterConsumption.end).format('YYYY-MM-DD')
					: undefined,
				span_type: 'day'
			}),
		enabled: !!selectedMeasurementPoint
	});

	//TODO: add this and replace the endpoint with new one when finished from backend
	// // water production readings relative to bar chart over day, month, year
	// const { data: waterProductionDateBarChart, isLoading: loadingWaterChart } = useQuery<any>({
	// 	queryKey: ['water-produwction-data', spanTypeBarChart, chartTimeSpanWaterProduction, selectedMeasurementPoint],
	// 	queryFn: () =>
	// 		chartsService.getSiteDailyProduction(selectedMeasurementPoint, {
	// 			span_type: spanTypeBarChart,
	// 			date:
	// 				spanTypeBarChart === SpanType.DAY
	// 					? chartTimeSpanWaterProduction?.start
	// 						? dayjs(chartTimeSpanWaterProduction.start).startOf(spanTypeBarChart).format('YYYY-MM-DD')
	// 						: dayjs().startOf(spanTypeBarChart).format('YYYY-MM-DD')
	// 					: undefined,
	// 			start:
	// 				spanTypeBarChart !== SpanType.DAY
	// 					? chartTimeSpanWaterProduction?.start
	// 						? dayjs(chartTimeSpanWaterProduction.start).startOf(spanTypeBarChart).format('YYYY-MM-DD')
	// 						: dayjs().startOf(spanTypeBarChart).format('YYYY-MM-DD')
	// 					: undefined
	// 		}),
	// 	enabled: !!selectedMeasurementPoint && !!spanTypeBarChart
	// });

	return (
		<>
			<Row>
				<Col xs={24} className="mb-2">
					<Spin spinning={loadingWaterDate}>
						<WaiNumericalChart
							number={
								waterProductionDateChart && waterProductionDateChart?.data?.length > 0 ? (
									parseFloat(waterProductionDateChart?.data[0].value).toFixed(2)
								) : waterProductionDateChart?.average ? (
									parseFloat(waterProductionDateChart?.average).toFixed(2)
								) : (
									<Empty description={t('NoAvailableData')} />
								)
							}
							title={
								(spanType === SpanType.DAY &&
									waterProductionLastDates[0]?.data &&
									capitalizeWords(waterProductionLastDates[0]?.data.name)) ||
								t('WaterProduction')
							}
							unit={
								(((waterProductionDateChart?.data?.length > 0 &&
									waterProductionDateChart?.data[0]?.value) ||
									waterProductionDateChart?.average) &&
									waterProductionDateChart?.unit) ||
								undefined
							}
							maxWidth="100%"
							dateText={
								spanType === SpanType.DAY && waterProductionLastDates[0].data?.data.length > 0
									? chartDateWaterProduction?.date ===
											dayjs(waterProductionLastDates[0].data.data[0].time).format('YYYY-MM-DD') ||
									  chartDateWaterProduction?.date === undefined
										? t('LastReadingAtDate', {
												date: dayjs(waterProductionLastDates[0].data?.data[0].time).format(
													'Do MMM YYYY'
												)
										  })
										: t('ReadingAtDate', {
												date: dayjs(chartDateWaterProduction?.date).format('Do MMM YYYY')
										  })
									: spanType === SpanType.WEEK && waterProductionLastDates[1].data?.start
									? chartDateWaterProduction?.start === waterProductionLastDates[1].data.start
										? t('LastReadingAtDate', {
												date: `${dayjs(waterProductionLastDates[1].data.start).format(
													'DD MMM'
												)}:${dayjs(waterProductionLastDates[1].data.start)
													.endOf(SpanType.WEEK)
													.format('DD MMM YYYY')}`
										  })
										: t('ReadingAtDate', {
												date: `${dayjs(chartDateWaterProduction?.start).format(
													'DD MMM'
												)}:${dayjs(chartDateWaterProduction?.start)
													.endOf(SpanType.WEEK)
													.format('DD MMM YYYY')}`
										  })
									: waterProductionLastDates[2].data?.start &&
									  (chartDateWaterProduction?.start === waterProductionLastDates[2].data.start
											? t('LastReadingAtDate', {
													date: dayjs(waterProductionLastDates[2].data.start).format(
														'MMMM YYYY'
													)
											  })
											: t('ReadingAtDate', {
													date: dayjs(chartDateWaterProduction?.start).format('MMMM YYYY')
											  }))
							}
							lastReadingsDates={{
								day:
									waterProductionLastDates[0]?.data?.data &&
									waterProductionLastDates[0]?.data?.data[0]?.time,

								week: waterProductionLastDates[1]?.data?.start,
								month: waterProductionLastDates[2]?.data?.start
							}}
							showCustomSelectorDate
							OnDateSelect={(date: any) => setChartDateWaterProduction(date)}
							showSpanSelector
							spanType={spanType}
							setSpanType={setSpanType}
							percentage={waterProductionDateChart?.preceding_reading?.percentage_change || undefined}
							increase={
								waterProductionDateChart?.preceding_reading?.trend_direction === 'up' ? true : false
							}
							positive={
								waterProductionDateChart?.preceding_reading?.trend_direction === 'up' ? false : true
							}
							customTimeUnits={[GraphSpan.DAY, GraphSpan.WEEK, GraphSpan.MONTH]}
							sourceName={siteName}
						/>
					</Spin>
				</Col>
				<Col xs={24}>
					<Spin spinning={loadingWaterChart}>
						<TimeSeriesBar
							IDs={[
								{
									id: selectedMeasurementPoint,
									name: FlowRateType.DWC
								}
							]}
							height={55}
							graphHeight={550}
							showBrush
							showTabular
							onSpanSelect={(span: any) => setChartTimeSpanWaterConsumption(span)}
							lastReadingDate={
								waterProductionLastDates[0]?.data?.data?.length &&
								waterProductionLastDates[0]?.data.data[0]?.time
							}
							dataObjects={clearArrayUndefined([
								{
									id: waterConsumptionTimeSpanChart?.id,
									name: t('value'),
									data: waterConsumptionTimeSpanChart?.data ?? [],
									allData: waterProducionAllData?.data ?? [],
									unit: FlowRateUnit.CMD,
									yAxis: {
										position: 'left',
										legend: t('WaterConsumptionInCMD')
									}
								}
							])}
							showSpanSelector
							title={t('WaterConsumption')}
							showCustomSpanSelector={true}
							statistics={clearArrayUndefined(
								waterConsumptionTimeSpanChart
									? [
											{
												name: t('Minimum'),
												value: Math.min(
													...waterConsumptionTimeSpanChart?.data.map((log: any) => +log.value)
												),
												unit: FlowRateUnit.CMD
											},
											{
												name: t('Maximum'),
												value: Math.max(
													...waterConsumptionTimeSpanChart?.data.map((log: any) => +log.value)
												),
												unit: FlowRateUnit.CMD
											},
											{
												name: t('Average'),
												value: mean(
													waterConsumptionTimeSpanChart?.data.map((log: any) => +log.value)
												),
												unit: FlowRateUnit.CMD
											}
									  ]
									: []
							)}
							minMax={
								waterProducionAllData && {
									min: Math.min(...waterProducionAllData.data.map((log: any) => +log.value)),
									max: Math.max(...waterProducionAllData.data.map((log: any) => +log.value))
								}
							}
							showTimeStep
							nameSource={siteName}
						/>
					</Spin>
				</Col>
				{/*
				TODO: add this later
				 <Col xs={24}>
					<Spin spinning={loadingWaterChart}>
						<TimeSeriesBar
							IDs={[
								{
									id: selectedMeasurementPoint,
									name: FlowRateType.DWC
								}
							]}
							title={t('WaterProduction')}
							height={55}
							graphHeight={550}
							showTabular
							OnDateSelect={(span: any) => {
								return setChartTimeSpanWaterProduction(span);
							}}
							showTimeStep
							nameSource={siteName}
							showCustomSelectorDate
							showSpanSelector
							spanType={spanTypeBarChart}
							setSpanType={setSpanTypeBarChart}
							customTimeUnits={[GraphSpan.DAY, GraphSpan.MONTH, GraphSpan.YEAR]}
							lastReadingsDates={{
								day: dayjs().format('YYYY-MM-DD'),

								month: dayjs().startOf('M').format('YYYY-MM-DD'),
								year: dayjs().startOf('year').format('YYYY-MM-DD')
							}}
							dataObjects={clearArrayUndefined([
								{
									id: waterProductionTimeSpanChart?.id,
									name: t('value'),
									data: waterProductionTimeSpanChart?.data ?? [],
									allData:
										//TODO: uncomment later
										//  waterProducionAllData?.data ??
										[],
									unit: FlowRateUnit.CMD,
									yAxis: {
										position: 'left',
										legend: t('WaterProductionInCMD')
									}
								}
							])}
							statistics={clearArrayUndefined(
								waterProductionTimeSpanChart && waterProductionTimeSpanChart?.data?.length > 0
									? [
											{
												name: t('Minimum'),
												value: Math.min(
													...waterProductionTimeSpanChart?.data.map((log: any) => +log.value)
												),
												unit: FlowRateUnit.CMD
											},
											{
												name: t('Maximum'),
												value: Math.max(
													...waterProductionTimeSpanChart?.data.map((log: any) => +log.value)
												),
												unit: FlowRateUnit.CMD
											},
											{
												name: t('Average'),
												value: mean(
													waterProductionTimeSpanChart?.data.map((log: any) => +log.value)
												),
												unit: FlowRateUnit.CMD
											}
									  ]
									: []
							)}
						/>
					</Spin>
				</Col> */}
			</Row>
		</>
	);
};
