import { useContext } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AppRoutes } from '../constants/routes';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from '../layouts/auth.layout';
import { DashboardLayout } from '../layouts/dashboard.layout';
import {
	AdministrationPage,
	AdvancedAnalysisPage,
	AlertsPage,
	AnalysisPage,
	ApplicationConfigurationPage,
	CommercialMeterGroupConfigurationPage,
	CommercialSiteConfigurationPage,
	ConfigurationPage,
	DataSourceConfigurationPage,
	SiteConfigurationPage,
	MonitoringPage,
	NetworkConfigurationPage,
	SubsystemConfigurationPage,
	UserPermissionPage,
	ZoneConfigurationPage
} from '../pages';
import { SigninPage } from '../pages/signin';
import authService from '../services/auth.service';
import { UserSubSystemsApps, UserRights, UserApps } from '../types';
import { hasRight } from '../utils/has-right';
import { parseJwt } from '../utils/parse-jwt';
import { IsUserRedirect, ProtectedRoute } from '../utils/routes';
import { FoundationConfigurationPage } from '../pages/foundation-configuration';
import { UserConfigurationPage } from '../pages/users-configuration';
import { hasSubSystem } from '../utils';
import { AuthenticationContext } from '../contexts';
import userManagementService from '../services/user-management.service';
import { useQuery } from '@tanstack/react-query';
import { BiHome } from 'react-icons/bi';
import dayjs from 'dayjs';

const Logout = () => {
	localStorage.removeItem('wai_access_token');
	window.location.reload();
	return null;
};

export const AppRouter: React.FC = () => {
	const { t } = useTranslation();
	const authContext = useContext(AuthenticationContext);
	const { user, configurationPermessions, analysisPermessions } = authContext;

	// get applications if user login
	const { data: applications } = useQuery<any>({
		queryKey: ['applications'],
		queryFn: () => userManagementService.getApplications(),
		enabled: !!user?.exp && dayjs.unix(user.exp) >= dayjs()
	});
	// setup mapper for applications with names and IDs (to check the permissions in applications for the user)
	const applicationIdMap = new Map();
	applications?.forEach(item => {
		applicationIdMap.set(item.name, item.id);
	});

	return (
		<Router>
			<Routes>
				<Route
					path={AppRoutes.SIGNIN}
					element={
						<IsUserRedirect
							allowed={authService.isAuthenticated().authenticated}
							loggedInPath={AppRoutes.MONITORING}
						/>
					}
				>
					<Route
						path={AppRoutes.SIGNIN}
						element={
							<AuthLayout>
								<SigninPage />
							</AuthLayout>
						}
					/>
				</Route>

				<Route
					path={AppRoutes.LOGOUT}
					element={<ProtectedRoute allowed={authService.isAuthenticated().authenticated} />}
				>
					<Route path={AppRoutes.LOGOUT} element={<Logout />} />
				</Route>

				<Route
					path={AppRoutes.MONITORING}
					element={<ProtectedRoute allowed={authService.isAuthenticated().authenticated} />}
				>
					<Route
						path={AppRoutes.MONITORING}
						element={
							<DashboardLayout>
								<MonitoringPage />
							</DashboardLayout>
						}
					/>
				</Route>

				<Route
					path={AppRoutes.ALERTS}
					element={
						<ProtectedRoute
							allowed={
								authService.isAuthenticated().authenticated &&
								hasSubSystem(parseJwt(authService.isAuthenticated().token), UserSubSystemsApps.ALERTS)
							}
						/>
					}
				>
					<Route
						path={AppRoutes.ALERTS}
						element={
							<DashboardLayout>
								<AlertsPage />
							</DashboardLayout>
						}
					/>
				</Route>

				<Route
					path={AppRoutes.ANALYSIS}
					element={
						<ProtectedRoute
							allowed={
								authService.isAuthenticated().authenticated &&
								hasSubSystem(parseJwt(authService.isAuthenticated().token), UserSubSystemsApps.ANALYSIS)
							}
						/>
					}
				>
					<Route
						path={AppRoutes.ANALYSIS}
						element={
							<DashboardLayout>
								<AnalysisPage />
							</DashboardLayout>
						}
					/>
				</Route>

				<Route
					path={AppRoutes.CONTROL}
					element={
						<ProtectedRoute
							allowed={
								authService.isAuthenticated().authenticated &&
								hasSubSystem(parseJwt(authService.isAuthenticated().token), UserSubSystemsApps.CONTROL)
							}
						/>
					}
				>
					<Route
						path={AppRoutes.CONTROL}
						element={
							<div
								style={{ width: '100%', height: '100vh' }}
								className="d-flex  align-items-center justify-content-center"
							>
								<h1>{t('comingSoon')}</h1>
								<a href="/" className="ms-3">
									<BiHome size={40} />
								</a>
							</div>
						}
					/>
				</Route>

				<Route
					path={AppRoutes.CONFIGURATION}
					element={
						<ProtectedRoute
							allowed={
								authService.isAuthenticated().authenticated &&
								hasSubSystem(
									parseJwt(authService.isAuthenticated().token),
									UserSubSystemsApps.CONFIGURATION
								)
							}
						/>
					}
				>
					<Route
						path={AppRoutes.CONFIGURATION}
						element={
							<DashboardLayout showSidebar={false}>
								<ConfigurationPage />
							</DashboardLayout>
						}
					/>
				</Route>

				<Route
					path={AppRoutes.FOUNDATION_CONFIGURATION}
					element={
						<ProtectedRoute
							allowed={authService.isAuthenticated().authenticated && user?.user_data?.is_superAdmin}
						/>
					}
				>
					<Route
						path={AppRoutes.FOUNDATION_CONFIGURATION}
						element={
							<DashboardLayout showSidebar={false}>
								<FoundationConfigurationPage />
							</DashboardLayout>
						}
					/>
				</Route>

				<Route
					path={AppRoutes.USERS_CONFIGURATION}
					element={
						<ProtectedRoute
							allowed={
								authService.isAuthenticated().authenticated
								// &&
								// hasRight(
								// 	parseJwt(authService.isAuthenticated().token),
								// 	UserSubSystemsApps.CONFIGURATION,
								// 	UserApps.FOUNDATIONS,
								// 	UserRights.CREATE
								// )
							}
						/>
					}
				>
					<Route
						path={AppRoutes.USERS_CONFIGURATION}
						element={
							<DashboardLayout showSidebar={false}>
								<UserConfigurationPage />
							</DashboardLayout>
						}
					/>
				</Route>

				<Route
					path={AppRoutes.USER_PERMISSION}
					element={
						<ProtectedRoute
							allowed={
								authService.isAuthenticated().authenticated
								// &&
								// hasRight(
								// 	parseJwt(authService.isAuthenticated().token),
								// 	UserSubSystemsApps.CONFIGURATION,
								// 	UserApps.USER_PERMISSIONS,
								// 	UserRights.CREATE
								// )
							}
						/>
					}
				>
					<Route
						path={AppRoutes.USER_PERMISSION}
						element={
							<DashboardLayout showSidebar={false}>
								<UserPermissionPage />
							</DashboardLayout>
						}
					/>
				</Route>

				<Route
					path={AppRoutes.SUBSYSTEM_CONFIGURATION}
					element={
						<ProtectedRoute
							allowed={authService.isAuthenticated().authenticated && user?.user_data?.is_superAdmin}
						/>
					}
				>
					<Route
						path={AppRoutes.SUBSYSTEM_CONFIGURATION}
						element={
							<DashboardLayout showSidebar={false}>
								<SubsystemConfigurationPage />
							</DashboardLayout>
						}
					/>
				</Route>
				<Route
					path={AppRoutes.APPLICATION_CONFIGURATION}
					element={
						<ProtectedRoute
							allowed={authService.isAuthenticated().authenticated && user?.user_data?.is_superAdmin}
						/>
					}
				>
					<Route
						path={AppRoutes.APPLICATION_CONFIGURATION}
						element={
							<DashboardLayout showSidebar={false}>
								<ApplicationConfigurationPage />
							</DashboardLayout>
						}
					/>
				</Route>
				<Route
					path={AppRoutes.NETWORK_CONFIGURATION}
					element={
						<ProtectedRoute
							allowed={
								authService.isAuthenticated().authenticated &&
								hasRight(
									!!user?.user_data?.is_superAdmin,
									configurationPermessions,
									applicationIdMap.get(UserApps.NETWORK),
									UserRights.CREATE,
									undefined,
									UserRights.EDIT
								)
							}
						/>
					}
				>
					<Route
						path={AppRoutes.NETWORK_CONFIGURATION}
						element={
							<DashboardLayout showSidebar={false}>
								<NetworkConfigurationPage />
							</DashboardLayout>
						}
					/>
				</Route>

				<Route
					path={AppRoutes.ZONE_CONFIGURATION}
					element={
						<ProtectedRoute
							allowed={
								authService.isAuthenticated().authenticated &&
								hasRight(
									!!user?.user_data?.is_superAdmin,
									configurationPermessions,
									applicationIdMap.get(UserApps.ZONE),
									UserRights.CREATE,
									undefined,
									UserRights.EDIT
								)
							}
						/>
					}
				>
					<Route
						path={AppRoutes.ZONE_CONFIGURATION}
						element={
							<DashboardLayout showSidebar={false}>
								<ZoneConfigurationPage />
							</DashboardLayout>
						}
					/>
				</Route>
				<Route
					path={AppRoutes.COMMERCIAL_METER_GROUP_CONFIGURATION}
					element={<ProtectedRoute allowed={authService.isAuthenticated().authenticated} />}
				>
					<Route
						path={AppRoutes.COMMERCIAL_METER_GROUP_CONFIGURATION}
						element={
							<DashboardLayout showSidebar={false}>
								<CommercialMeterGroupConfigurationPage />
							</DashboardLayout>
						}
					/>
				</Route>
				<Route
					path={AppRoutes.COMMERCIAL_METER_SITE_CONFIGURATION}
					element={<ProtectedRoute allowed={authService.isAuthenticated().authenticated} />}
				>
					<Route
						path={AppRoutes.COMMERCIAL_METER_SITE_CONFIGURATION}
						element={
							<DashboardLayout showSidebar={false}>
								<CommercialSiteConfigurationPage />
							</DashboardLayout>
						}
					/>
				</Route>
				<Route
					path={AppRoutes.MEASURMENT_POINT_CONFIGURATION}
					element={
						<ProtectedRoute
							allowed={
								authService.isAuthenticated().authenticated &&
								hasRight(
									!!user?.user_data?.is_superAdmin,
									configurationPermessions,
									applicationIdMap.get(UserApps.MEASUREMENT_POINT),
									UserRights.CREATE,
									undefined,
									UserRights.EDIT
								)
							}
						/>
					}
				>
					<Route
						path={AppRoutes.MEASURMENT_POINT_CONFIGURATION}
						element={
							<DashboardLayout showSidebar={false}>
								<SiteConfigurationPage />
							</DashboardLayout>
						}
					/>
				</Route>

				<Route
					path={AppRoutes.DATA_SOURCE_CONFIGURATION}
					element={
						<ProtectedRoute
							allowed={
								authService.isAuthenticated().authenticated &&
								hasRight(
									!!user?.user_data?.is_superAdmin,
									configurationPermessions,
									applicationIdMap.get(UserApps.DATA_SOURCE),
									UserRights.CREATE,
									undefined,
									UserRights.EDIT
								)
							}
						/>
					}
				>
					<Route
						path={AppRoutes.DATA_SOURCE_CONFIGURATION}
						element={
							<DashboardLayout showSidebar={false}>
								<DataSourceConfigurationPage />
							</DashboardLayout>
						}
					/>
				</Route>

				<Route
					path={AppRoutes.ADVANCED_ANALYSIS}
					element={
						<ProtectedRoute
							allowed={
								authService.isAuthenticated().authenticated &&
								hasRight(
									!!user?.user_data?.is_superAdmin,
									analysisPermessions,
									applicationIdMap.get(UserApps.ADVANCED_ANALYSIS),
									UserRights.READ
								)
							}
						/>
					}
				>
					<Route
						path={AppRoutes.ADVANCED_ANALYSIS}
						element={
							<DashboardLayout showSidebar={false}>
								<AdvancedAnalysisPage />
							</DashboardLayout>
						}
					/>
				</Route>

				<Route
					path={AppRoutes.ADMINISTRATION}
					element={
						<ProtectedRoute
							allowed={
								authService.isAuthenticated().authenticated &&
								hasSubSystem(
									parseJwt(authService.isAuthenticated().token),
									UserSubSystemsApps.ADMINISTRATION
								)
							}
						/>
					}
				>
					<Route
						path={AppRoutes.ADMINISTRATION}
						element={
							<DashboardLayout showSidebar={false}>
								<AdministrationPage />
							</DashboardLayout>
						}
					/>
				</Route>

				<Route
					path={'/dashboard'}
					element={<ProtectedRoute allowed={authService.isAuthenticated().authenticated} />}
				>
					<Route path={'/dashboard'} element={<Navigate replace to={AppRoutes.MONITORING} />} />
				</Route>

				<Route
					path={AppRoutes.ROOT}
					element={<ProtectedRoute allowed={authService.isAuthenticated().authenticated} />}
				>
					<Route path={AppRoutes.ROOT} element={<Navigate replace to={AppRoutes.MONITORING} />} />
				</Route>

				<Route path="*" element={<ProtectedRoute allowed={authService.isAuthenticated().authenticated} />}>
					<Route
						path="*"
						element={
							<div
								style={{ width: '100%', height: '100vh' }}
								className="d-flex  align-items-center justify-content-center"
							>
								<h1>{t('nothingHere')}</h1>
							</div>
						}
					/>
				</Route>
			</Routes>
		</Router>
	);
};
