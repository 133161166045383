import { Col, Empty, Row, Spin } from 'antd';
import { useContext, useState } from 'react';
import { useQuery, useQueries } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import { FlowRateType, SpanType, customSpanSelector, GraphSpan, UserRights, UserApps } from '../types';
import dayjs from 'dayjs';
import { stdev, mean } from 'stats-lite';
import { ChartStats } from '../enum';
import { FlowRateUnit } from '../types/flow-rate-unit';
import { clearArrayUndefined } from '../utils/clean-array-undefined';
import { WaiNumericalChart } from './numerical-chart';
import { TimeSeriesBar } from './time-series-bar';
import { colors } from '../constants/colors';
import { capitalizeWords } from '../utils/capitalize-string-words';
import { AppRoutes } from '../constants/routes';
import { hasRight } from '../utils';
import { AuthenticationContext } from '../contexts';
import chartsService from '../services/charts.service';
import configService from '../services/config.service';

export const ZoneAnalysisWaterConsumptionTab: React.FC<any> = ({ zoneName }) => {
	const { t } = useTranslation();

	const [chartDateWaterConsumption, setChartDateWaterConsumption] = useState<{ start?: string; date?: string }>();
	const [chartTimeSpanWaterConsumption, setChartTimeSpanWaterConsumption] = useState<{
		start: string;
		end: string;
	}>();
	const [spanType, setSpanType] = useState<SpanType>(SpanType.DAY);

	const navigationContext = useContext(NavigationContext);
	const { selectedZone, applicationIdMap } = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user, configurationPermessions } = authContext;

	// load selected zone flow analysis configuration
	const { data: zoneFlowAnalysisConfig, isLoading: loadingZoneFlowAnalysisConfig } = useQuery({
		queryKey: ['zone-flow-analysis-config', selectedZone],
		queryFn: () => selectedZone && configService.getZoneFlowAnalysisConfig(selectedZone),
		enabled: !!selectedZone
	});

	// Last water consumption readings for day, week, month
	const waterConsumptionLastDates: any = useQueries<any>({
		queries: (Object.keys(customSpanSelector) as (keyof typeof customSpanSelector)[]).map((span: any) => {
			return {
				queryKey: ['waterConsumptionLastReading', selectedZone, span],
				queryFn: () =>
					chartsService.getZoneDailyConsumption(selectedZone, {
						last: true,
						span_type: span.toLowerCase()
					}),
				enabled: !!selectedZone && !!span
			};
		})
	});
	// All water consumption readings regarding the days period
	const { data: waterConsumptionAllData } = useQuery<any>({
		queryKey: ['waterConsumptionAllData', selectedZone],
		queryFn: () =>
			chartsService.getZoneDailyConsumption(selectedZone, {
				span_type: 'day'
			}),
		enabled: !!selectedZone
	});
	// Water consumption reading relative to numerical chart
	const {
		data: waterConsumptionDateChart,
		isLoading: loadingWaterDate,
		isFetching: fetchingWaterDate
	} = useQuery<any>({
		queryKey: [
			'waterConsumptionData',
			selectedZone,
			spanType,
			chartDateWaterConsumption,
			waterConsumptionLastDates[Object.values(SpanType).indexOf(spanType)]?.data
		],
		queryFn: () =>
			chartsService.getZoneDailyConsumption(selectedZone, {
				start:
					spanType === SpanType.DAY
						? undefined
						: chartDateWaterConsumption?.start
						? dayjs(chartDateWaterConsumption.start).format('YYYY-MM-DD')
						: waterConsumptionLastDates[Object.values(SpanType).indexOf(spanType)]?.data?.start &&
						  dayjs(
								waterConsumptionLastDates[Object.values(SpanType).indexOf(spanType)]?.data?.start
						  ).format('YYYY-MM-DD'),
				date:
					spanType !== SpanType.DAY
						? undefined
						: chartDateWaterConsumption?.date
						? dayjs(chartDateWaterConsumption.date).format('YYYY-MM-DD')
						: waterConsumptionLastDates[0]?.data?.data?.length
						? dayjs(waterConsumptionLastDates[0]?.data?.data[0]?.time).format('YYYY-MM-DD')
						: undefined,
				span_type: spanType
			}),
		enabled: (!!chartDateWaterConsumption || !!waterConsumptionLastDates) && !!selectedZone && !!spanType
	});
	// water consumtion readings relative to bar chart over a period of days
	const {
		data: waterConsumptionTimeSpanChart,
		isLoading: loadingWaterChart,
		isFetching: fetchingWaterChart
	} = useQuery<any>({
		queryKey: ['waterConsumptionData', chartTimeSpanWaterConsumption, selectedZone],
		queryFn: () =>
			chartsService.getZoneDailyConsumption(selectedZone, {
				start: chartTimeSpanWaterConsumption
					? dayjs(chartTimeSpanWaterConsumption.start).format('YYYY-MM-DD')
					: undefined,
				end: chartTimeSpanWaterConsumption
					? dayjs(chartTimeSpanWaterConsumption.end).format('YYYY-MM-DD')
					: undefined,
				span_type: 'day'
			}),
		enabled: !!selectedZone
	});

	return (
		<>
			<Row>
				<Col xs={24} className={'mb-2'}>
					<Spin spinning={(loadingWaterDate && fetchingWaterDate) || loadingZoneFlowAnalysisConfig}>
						<WaiNumericalChart
							number={
								waterConsumptionDateChart && waterConsumptionDateChart?.data?.length > 0 ? (
									parseFloat(waterConsumptionDateChart?.data[0].value).toFixed(2)
								) : waterConsumptionDateChart?.average ? (
									parseFloat(waterConsumptionDateChart?.average).toFixed(2)
								) : (
									<Empty description={t('NoAvailableData')} />
								)
							}
							title={
								(spanType === SpanType.DAY &&
									waterConsumptionLastDates[0]?.data &&
									capitalizeWords(waterConsumptionLastDates[0]?.data.name)) ||
								t('AverageWaterConsumption')
							}
							unit={
								(((waterConsumptionDateChart?.data?.length > 0 &&
									waterConsumptionDateChart?.data[0]?.value) ||
									waterConsumptionDateChart?.average) &&
									waterConsumptionDateChart?.unit) ||
								undefined
							}
							maxWidth="100%"
							dateText={
								spanType === SpanType.DAY && waterConsumptionLastDates[0].data?.data.length > 0
									? chartDateWaterConsumption?.date ===
											dayjs(waterConsumptionLastDates[0].data.data[0].time).format(
												'YYYY-MM-DD'
											) || chartDateWaterConsumption?.date === undefined
										? t('LastReadingAtDate', {
												date: dayjs(waterConsumptionLastDates[0].data?.data[0].time).format(
													'Do MMM YYYY'
												)
										  })
										: t('ReadingAtDate', {
												date: dayjs(chartDateWaterConsumption?.date).format('Do MMM YYYY')
										  })
									: spanType === SpanType.WEEK && waterConsumptionLastDates[1].data?.start
									? chartDateWaterConsumption?.start === waterConsumptionLastDates[1].data.start
										? t('LastReadingAtDate', {
												date: `${dayjs(waterConsumptionLastDates[1].data.start).format(
													'DD MMM'
												)}:${dayjs(waterConsumptionLastDates[1].data.start)
													.endOf(SpanType.WEEK)
													.format('DD MMM YYYY')}`
										  })
										: t('ReadingAtDate', {
												date: `${dayjs(chartDateWaterConsumption?.start).format(
													'DD MMM'
												)}:${dayjs(chartDateWaterConsumption?.start)
													.endOf(SpanType.WEEK)
													.format('DD MMM YYYY')}`
										  })
									: waterConsumptionLastDates[2].data?.start &&
									  (chartDateWaterConsumption?.start === waterConsumptionLastDates[2].data.start
											? t('LastReadingAtDate', {
													date: dayjs(waterConsumptionLastDates[2].data.start).format(
														'MMMM YYYY'
													)
											  })
											: t('ReadingAtDate', {
													date: dayjs(chartDateWaterConsumption?.start).format('MMMM YYYY')
											  }))
							}
							lastReadingsDates={{
								day:
									waterConsumptionLastDates[0]?.data?.data &&
									waterConsumptionLastDates[0]?.data?.data[0]?.time,

								week: waterConsumptionLastDates[1]?.data?.start,
								month: waterConsumptionLastDates[2]?.data?.start
							}}
							showCustomSelectorDate
							OnDateSelect={(date: any) => setChartDateWaterConsumption(date)}
							showSpanSelector
							spanType={spanType}
							setSpanType={setSpanType}
							percentage={waterConsumptionDateChart?.preceding_reading?.percentage_change || undefined}
							increase={
								waterConsumptionDateChart?.preceding_reading?.trend_direction === 'up' ? true : false
							}
							positive={
								waterConsumptionDateChart?.preceding_reading?.trend_direction === 'up' ? false : true
							}
							customTimeUnits={[GraphSpan.DAY, GraphSpan.WEEK, GraphSpan.MONTH]}
							sourceName={zoneName}
							deactivated={
								!(zoneFlowAnalysisConfig && zoneFlowAnalysisConfig.water_consumption.is_active)
							}
							deactivatedMsg={t('WaterConsumptionAnalysisDeactivated')}
							linkToActivate={
								AppRoutes.ZONE_CONFIGURATION +
								`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=flow_analysis`
							}
							accessToActivateChart={hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.ZONE),
								UserRights.EDIT
							)}
						/>
					</Spin>
				</Col>
				<Col xs={24}>
					<Spin spinning={(loadingWaterChart && fetchingWaterChart) || loadingZoneFlowAnalysisConfig}>
						<TimeSeriesBar
							IDs={[
								{
									id: selectedZone,
									name: FlowRateType.ZDWC
								}
							]}
							height={58}
							graphHeight={550}
							showBrush
							showTabular
							showAverageLine
							showStDevLine
							statistics={clearArrayUndefined(
								waterConsumptionTimeSpanChart && waterConsumptionTimeSpanChart.data
									? [
											...[
												{
													name: t('Minimum'),
													key: ChartStats.MIN,
													value: Math.min(
														...waterConsumptionTimeSpanChart.data.map(
															(log: any) => +log.value
														)
													),
													unit: FlowRateUnit.CMD
												},
												{
													name: t('Maximum'),
													key: ChartStats.MAX,
													value: Math.max(
														...waterConsumptionTimeSpanChart.data.map(
															(log: any) => +log.value
														)
													),
													unit: FlowRateUnit.CMD
												},
												{
													name: t('Average'),
													key: ChartStats.MEAN,
													value: mean(
														waterConsumptionTimeSpanChart.data.map((log: any) => +log.value)
													),
													unit: FlowRateUnit.CMD,
													color: colors.GRAPH_GREEN
												},
												{
													name: t('StandardDeviation'),
													key: ChartStats.STANDARD_DEVIATION,
													value: stdev(
														waterConsumptionTimeSpanChart.data.map((log: any) => +log.value)
													),
													unit: '',
													color: colors.WARNING
												}
											]
									  ]
									: []
							)}
							minMax={
								waterConsumptionAllData && {
									min: Math.min(...waterConsumptionAllData.data.map((log: any) => +log.value)),
									max: Math.max(...waterConsumptionAllData.data.map((log: any) => +log.value))
								}
							}
							onSpanSelect={(span: any) => setChartTimeSpanWaterConsumption(span)}
							lastReadingDate={
								waterConsumptionLastDates[0]?.data?.data?.length &&
								waterConsumptionLastDates[0]?.data.data[0]?.time
							}
							dataObjects={clearArrayUndefined([
								{
									id: waterConsumptionTimeSpanChart?.id,
									name: t('value'),
									data: waterConsumptionTimeSpanChart?.data ?? [],
									allData: waterConsumptionAllData?.data ?? [],
									unit: FlowRateUnit.CMD,
									yAxis: {
										position: 'left',
										legend: t('WaterConsumptionInCMD')
									}
								}
							])}
							showSpanSelector
							title={t('ZoneWaterConsumption')}
							showCustomSpanSelector={true}
							sourceName={zoneName}
							showTimeStep={true}
							deactivated={
								!(zoneFlowAnalysisConfig && zoneFlowAnalysisConfig.water_consumption.is_active)
							}
							deactivatedMsg={t('WaterConsumptionAnalysisDeactivated')}
							linkToActivate={
								AppRoutes.ZONE_CONFIGURATION +
								`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=flow_analysis`
							}
							accessToActivateChart={hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.ZONE),
								UserRights.EDIT
							)}
						/>
					</Spin>
				</Col>
			</Row>
		</>
	);
};
