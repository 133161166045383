import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { Brush, CartesianGrid, ComposedChart, Line, LineChart, ResponsiveContainer, YAxis } from 'recharts';
import { GraphCard } from '../components';
import { colors } from '../constants/colors';
import { useTranslation } from 'react-i18next';
import { ChartCustomSpan, TimeStep, TimeStepMapper } from '../types';
import { clearArrayUndefined } from '../utils/clean-array-undefined';
import { dateToQuery } from '../utils/date-to-query';
import { debounce } from '../utils/debounce';
import { mergeLogs } from '../utils/merge-logs';

const COLORS = [
	colors.GRAPH_PURPLE,
	colors.GRAPH_GREEN,
	colors.WARNING,
	colors.HEALTHY,
	colors.ERROR,
	colors.GRAPH_RED,
	colors.GRAPH_CYAN
];

const getGraphAnnotationXAxisOptions = (alerts: any, t: any) => {
	return alerts?.length && Array.isArray(alerts)
		? alerts.map((alert: any) => ({
				x: dayjs(alert.start).toDate().getTime(),
				x2: alert.start === alert.end ? undefined : dayjs(alert.end).toDate().getTime(),
				fillColor: colors.ERROR,
				borderColor: colors.ERROR,
				strokeDashArray: 0,
				opacity: 0.3,
				label: {
					borderColor: colors.WHITE,
					style: {
						fontSize: '15px',
						color: colors.WHITE,
						background: colors.ERROR
					},
					position: 'bottom',
					orientation: '90',
					text: alert.message
				}
		  }))
		: [];
};

const getGraphAnnotationPointsOptions = (seriesData: any) => {
	return seriesData?.length && Array.isArray(seriesData)
		? seriesData.flatMap(item =>
				item?.data
					.filter((d: any) => d.reset === true)
					.map((d: any) => {
						return {
							x: d.x,
							y: d.y,
							marker: {
								size: 5,
								fillColor: colors.WHITE,
								strokeColor: colors.ORANGE,
								radius: 2,
								cssClass: 'apexcharts-custom-class'
							}
						};
					})
		  )
		: [];
};

const getGraphAnnotationYAxisOptions = (alarmLevels: any, t: any) => {
	return alarmLevels?.length && Array.isArray(alarmLevels)
		? alarmLevels
				.map((alarm: any) => {
					const yAxisLevels: any = [];
					if (alarm?.min) {
						yAxisLevels.push({
							y: alarm.min,
							borderColor: colors.ERROR,
							label: {
								borderColor: colors.ERROR,
								style: {
									color: colors.WHITE,
									background: colors.ERROR
								},
								text: `${t('MinAlarmLevel')} ${alarm.type}`
							}
						});
					}
					if (alarm?.max) {
						yAxisLevels.push({
							y: alarm.max,
							borderColor: colors.ERROR,
							label: {
								borderColor: colors.ERROR,
								style: {
									color: colors.WHITE,
									background: colors.ERROR
								},
								text: `${t('MaxAlarmLevel')} ${alarm.type}`
							}
						});
					}
					return yAxisLevels;
				})
				.filter((alarm: any) => alarm.length > 0)
				.flat()
		: [];
};

const getGraphCustomTooltipOptions = (options, t) => {
	const dataPointIndex = options.dataPointIndex;
	const seriesIndex = options.seriesIndex;
	const chartColors = options.w.config.colors;
	const series = options.w.config.series;
	const calculation_ds = series[0].data[dataPointIndex]?.calculation_ds?.length;
	const zone_ds = series[0].data[dataPointIndex]?.zone_ds?.length;
	const reset_point = series[seriesIndex].data[dataPointIndex]?.reset;
	let color = '';
	if (calculation_ds >= 0 && zone_ds >= 0) {
		const percent = calculation_ds / zone_ds;
		color = percent > 0.9 ? colors.HEALTHY : percent > 0.5 ? colors.WARNING : colors.ERROR;
	}
	// sort all points for selected time according to max value
	const selectedTime = series[seriesIndex].data[dataPointIndex]?.x;
	const filteredSeries = series.filter(s => s.data.some(d => d.x === selectedTime));
	const sortedSeries = filteredSeries.sort((a, b) => {
		const aPoint = a.data.find(d => d.x === selectedTime);
		const bPoint = b.data.find(d => d.x === selectedTime);
		const aY = aPoint?.y ?? 0;
		const bY = bPoint?.y ?? 0;
		return bY - aY;
	});
	return (
		'<div style="display:flex;flex-direction:column; ">' +
		`<div style="margin-bottom:5px;background-color:${colors.LIGHT_GREY2}; padding:8px;5px;">` +
		dayjs(series[seriesIndex].data[dataPointIndex]?.x).format('dddd, MMMM D, YYYY h:mm A') +
		'</div>' +
		'<div style=padding:10px;>' +
		sortedSeries
			.map((d, idx) => {
				const point = d.data.find(p => p.x === series[seriesIndex].data[dataPointIndex]?.x);
				const unit = point?.unit ?? '';
				const originalIdx = series.findIndex(s => s.name === d.name); // to get the original color of each point

				return `<div style="display:flex; align-items:center;margin-bottom:5px;" >
					${
						point
							? `<div style="background-color:${
									chartColors[originalIdx]
							  }; width:12px;height:12px;border-radius:50%;margin-right:10px;"></div>
							  ${d.name}:  ${(point?.y && point?.y?.toFixed(3)) ?? '-'}  ${point?.y ? unit : ''}`
							: ''
					}

			</div>`;
			})
			.join(' ') +
		`<div style="display:flex;align-items:center;margin-top:30px;">

	${
		calculation_ds !== undefined && zone_ds !== undefined
			? `<span style=background-color:${color};border-radius:5px;padding:5px;width:20px;height:20px;color:${colors.WHITE};display:flex;justify-content:center;align-items:center;font-weight:700;margin-right:5px;>i</span>
			<i>Calculated from ${calculation_ds} out of  ${zone_ds} available data sources</i>`
			: ''
	}
	${
		reset_point
			? `<span style='display:block;width:100%;border-top: 1px solid ${colors.INACTIVE}; padding-top: 5px;'>
				 <span class='badge bg-warning text-dark' >
					${t('ResetPoint')}
				</span> 
			</span>`
			: ''
	}
		</div>` +
		'</div>' +
		'</div>'
	);
};

const getOptions: ({}: any) => ApexOptions = ({
	yAxis = [],
	alerts = [],
	t = {},
	noDataText = '',
	alarmLevels = {},
	seriesData = []
}) => {
	return {
		chart: {
			id: 'analysis-chart',
			type: 'line',
			stacked: false,

			zoom: {
				type: 'x',
				enabled: true,
				autoScaleYaxis: false
			},
			toolbar: {
				tools: { reset: true, zoom: true },
				autoSelected: 'zoom',
				export: {
					csv: {
						dateFormatter(timestamp?) {
							return `"${dayjs(timestamp).format('dddd, MMMM D, YYYY h:mm A')}"`;
						}
					}
				}
			}
		},
		annotations: {
			xaxis: getGraphAnnotationXAxisOptions(alerts, t),
			points: getGraphAnnotationPointsOptions(seriesData),
			yaxis: getGraphAnnotationYAxisOptions(alarmLevels, t)
		},

		colors: COLORS,

		stroke: { width: 2, curve: 'straight' },

		dataLabels: {
			enabled: false
		},

		markers: {
			size: 0
		},

		noData: { text: noDataText ? noDataText : t('NoAvailableDataDuringThisTimeSpan', { ns: 'validation' }) },

		yaxis: yAxis.length
			? yAxis.map(
					(axis: any): ApexYAxis => ({
						labels: {
							formatter: function (val: number) {
								return val?.toFixed(3) ?? undefined;
							}
						},
						title: {
							text: axis.legend
						},
						opposite: axis.position === 'right',
						tickAmount: 5
					})
			  )
			: {
					labels: {
						show: false
					}
			  },
		xaxis: {
			type: 'datetime',

			tooltip: { enabled: false },
			labels: {
				datetimeUTC: false
			}
		},

		tooltip: {
			shared: true,
			custom: options => getGraphCustomTooltipOptions(options, t)
		}
	};
};

//TODO: Fix the apex brush problem or remove this part
const getOptionsLine: ({}: any) => ApexOptions = span => ({
	chart: {
		id: 'navigation-chart',
		height: 130,
		type: 'line',
		brush: {
			target: 'analysis-chart',
			enabled: true
		},
		selection: {
			enabled: true,
			xaxis: {
				min: dayjs(span.start).toDate().getTime(),
				max: dayjs(span.end).toDate().getTime()
			}
		}
	},

	legend: { show: false },
	colors: COLORS,
	stroke: { width: 2, curve: 'smooth' },
	xaxis: {
		type: 'datetime',

		tooltip: {
			enabled: false
		}
	},
	yaxis: { labels: { show: false } }
});

export const WaiTimeSeriesChart: React.FC<any> = ({
	dataObjects,
	showSpanSelector,
	showUnitSelector,
	showTabular,
	chartTableDataSource,
	showCustomSpanSelector,
	showCustomSelectorDate,
	height,
	showBrush,
	title,
	graphHeight,
	alerts,
	alarmLevels,
	statistics,
	onSpanSelect,
	OnDateSelect,
	lastReadingDate,
	minMax,
	noDataText,
	IDs,
	flags,
	changeDate,
	customTimeUnits,
	dateText,
	analysisRedirectUri,
	showTimeStep,
	spanType,
	sourceName,
	deactivated,
	deactivatedMsg,
	linkToActivate,
	accessToActivateChart,
	profile,
	...restProps
}) => {
	const { t, i18n } = useTranslation();
	const [series, setSeries] = useState<any>([]);
	const [units, setUnits] = useState<any>();
	const [yAxis, setYAxis] = useState<any>();
	const [cleanDataObjects, setCleanDataObjects] = useState<any>([]);
	const [alertsWithVal, setAlertsWithVal] = useState<any>();
	const [brushData, setbrushData] = useState<any>([]);
	const [brushState, setBrushState] = useState<{ startIndex: number; endIndex: number }>();
	const [chartNote, setChartNote] = useState<string>('');
	const [displayDate, setDisplayDate] = useState<any>();
	const [displayTimeSpan, setDisplayTimeSpan] = useState<{ start: any; end: any }>();
	const [timeStep, setTimeStep] = useState<TimeStep | undefined>(undefined);
	const [firstDate, setFirstDate] = useState<any>();
	const [lastDate, setLastDate] = useState<any>();
	const [customBrushSpan, setCustomBrushSpan] = useState<boolean>(false);

	useEffect(() => {
		if (dataObjects && dataObjects.length) {
			setCleanDataObjects(dataObjects.filter((d: any) => !!d.id));
		} else {
			setCleanDataObjects([]);
		}
	}, [dataObjects, dataObjects?.length]);

	useEffect(() => {
		if (timeStep && timeStep.length) {
			onSpanSelect &&
				onSpanSelect({
					start:
						displayDate && displayDate?.start
							? dateToQuery(dayjs(displayDate?.start).startOf(timeStep))
							: lastReadingDate && dateToQuery(dayjs(lastReadingDate).startOf(timeStep)),
					end:
						displayDate && displayDate?.start
							? dateToQuery(dayjs(displayDate?.start).endOf(timeStep))
							: lastReadingDate && dateToQuery(dayjs(lastReadingDate).endOf(timeStep))
				});
		}
	}, [timeStep, timeStep?.length]);
	useEffect(() => {
		const cleanAlerts: any = [];
		if (cleanDataObjects && cleanDataObjects?.[0]?.data?.length && alerts?.length) {
			alerts.forEach((alert: any) => {
				const startVal = +cleanDataObjects[0].data.find((d: any) => {
					return d.time === alert.start;
				})?.value;
				const endVal = +cleanDataObjects[0].data.find((d: any) => {
					return d.time === alert.end;
				})?.value;

				cleanAlerts.push({ ...alert, startVal, endVal });
			});
			setAlertsWithVal(cleanAlerts);
		} else {
			setAlertsWithVal([]);
		}
	}, [cleanDataObjects, cleanDataObjects?.length, alerts?.length]);

	useEffect(() => {
		setSeries(
			clearArrayUndefined(
				cleanDataObjects.map((dataObject: any) => {
					if (dataObject.data?.length)
						return {
							name: dataObject.name,
							data: dataObject.data.map((d: any) => ({
								x: dayjs(d.time).toDate().getTime(),
								y: d.value ? +d.value : undefined,
								calculation_ds: d.calculation_ds,
								zone_ds: d.zone_ds,
								unit: dataObject.unit,
								reset: d?.reset || false
							}))
						};
				})
			)
		);
	}, [cleanDataObjects, cleanDataObjects?.length]);

	useEffect(() => {
		setUnits(
			clearArrayUndefined(
				cleanDataObjects.map((dataObject: any) => {
					if (dataObject.data?.length) return dataObject.unit;
				})
			)
		);
	}, [cleanDataObjects, cleanDataObjects?.length]);

	useEffect(() => {
		setYAxis(
			clearArrayUndefined(
				cleanDataObjects.map((dataObject: any) => {
					if (dataObject.data?.length && dataObject?.yAxis?.position) return dataObject.yAxis;
				})
			)
		);
	}, [cleanDataObjects, cleanDataObjects?.length]);

	useEffect(() => {
		if (cleanDataObjects?.length) {
			const allDataObjects = cleanDataObjects.map((ob: any) => {
				return { ...ob, data: ob.allData };
			});
			const allData = mergeLogs(allDataObjects);
			const data = mergeLogs(cleanDataObjects);

			setbrushData(allData);

			let dataStartIndex = 0,
				dataEndIndex = 8;

			if (data.length) dataStartIndex = allData.findIndex(d => dayjs(d.time).isSame(dayjs(data[0].time), 'd'));

			allData.forEach((d, idx) => {
				if (data.length && dayjs(d.time).isSame(dayjs(data[data.length - 1].time), 'd')) dataEndIndex = idx;
			});

			setBrushState({ startIndex: dataStartIndex, endIndex: dataEndIndex });
			let start: Date | null = null;
			let end: Date | null = null;
			for (const obj of cleanDataObjects) {
				const data = obj?.data;
				const objStart: Date = data[0]?.time;
				const objEnd: Date = data[data.length - 1]?.time;

				if (!start || objStart < start) {
					start = objStart;
				}

				if (!end || objEnd > end) {
					end = objEnd;
				}
			}
			setFirstDate(start);
			setLastDate(end);
		}
	}, [cleanDataObjects, cleanDataObjects?.length]);
	useEffect(() => {
		if (customTimeUnits && customTimeUnits?.length) {
			setTimeStep(TimeStepMapper[customTimeUnits[0]]);
		}
	}, [customTimeUnits]);

	return (
		<GraphCard
			className="mb-4"
			title={title || t('Analysis')}
			height={height}
			showSpanSelector={showSpanSelector}
			showUnitSelector={showUnitSelector}
			onSpanSelect={onSpanSelect}
			OnDateSelect={OnDateSelect}
			showTabular={showTabular}
			dataObjects={cleanDataObjects}
			selectedId={cleanDataObjects && cleanDataObjects?.length && cleanDataObjects[0] && cleanDataObjects[0]?.id}
			actualDataTimeSpan={
				cleanDataObjects && cleanDataObjects?.length && cleanDataObjects[0] && cleanDataObjects[0]?.data
					? ({
							start: firstDate && firstDate,
							end: lastDate && lastDate
					  } as ChartCustomSpan)
					: ({
							start:
								displayTimeSpan && displayTimeSpan?.start
									? dateToQuery(dayjs(displayTimeSpan?.start))
									: lastReadingDate && dateToQuery(dayjs(lastReadingDate)),
							end:
								displayTimeSpan && displayTimeSpan?.end
									? dateToQuery(dayjs(displayTimeSpan?.end))
									: lastReadingDate && dateToQuery(dayjs(lastReadingDate))
					  } as ChartCustomSpan)
			}
			actualDataDate={
				cleanDataObjects &&
				cleanDataObjects.length &&
				cleanDataObjects[0].data &&
				cleanDataObjects[0].data.length
					? cleanDataObjects[0].data[cleanDataObjects[0].data.length - 1].time
					: undefined
			}
			chartTableDataSource={chartTableDataSource}
			showCustomSpanSelector={showCustomSpanSelector}
			showCustomSelectorDate={showCustomSelectorDate}
			statistics={statistics}
			lastReadingDate={lastReadingDate}
			IDs={IDs}
			flags={flags}
			showTimeStep={showTimeStep}
			timeStep={timeStep}
			setTimeStep={setTimeStep}
			analysisRedirectUri={analysisRedirectUri}
			setDisplayDate={setDisplayDate}
			displayDate={displayDate}
			setDisplayTimeSpan={setDisplayTimeSpan}
			displayTimeSpan={displayTimeSpan}
			customTimeUnits={customTimeUnits}
			spanType={spanType}
			sourceName={sourceName}
			customBrushSpan={customBrushSpan}
			setCustomBrushSpan={setCustomBrushSpan}
			deactivated={deactivated}
			deactivatedMsg={deactivatedMsg}
			linkToActivate={linkToActivate}
			accessToActivateChart={accessToActivateChart}
			profile={profile}
			{...restProps}
		>
			<div style={{ height: '100%', width: '100%' }} dir="ltr" className="d-flex flex-column pe-2">
				{timeStep && timeStep?.length ? (
					<>
						<div className="d-flex  align-items-center ">
							<AiOutlineLeft
								role={'button'}
								size={'2rem'}
								onClick={() => {
									if (showCustomSelectorDate) {
										OnDateSelect &&
											OnDateSelect(
												displayDate
													? dayjs(displayDate).subtract(1, timeStep).format('YYYY-MM-DD')
													: lastReadingDate &&
															dayjs(lastReadingDate)
																.subtract(1, timeStep)
																.format('YYYY-MM-DD')
											);

										setDisplayDate &&
											setDisplayDate(
												displayDate
													? dayjs(displayDate).subtract(1, timeStep).format('YYYY-MM-DD')
													: lastReadingDate &&
															dayjs(lastReadingDate)
																.subtract(1, timeStep)
																.format('YYYY-MM-DD')
											);
									} else {
										setDisplayTimeSpan &&
											setDisplayTimeSpan({
												start: displayTimeSpan?.start
													? dayjs(displayTimeSpan?.start)
															.subtract(1, timeStep)
															.startOf(timeStep)
															.format('YYYY-MM-DD')
													: lastReadingDate &&
													  dayjs(lastReadingDate)
															.subtract(1, timeStep)
															.startOf(timeStep)
															.format('YYYY-MM-DD'),
												end: displayTimeSpan?.start
													? dayjs(displayTimeSpan?.start)
															.subtract(1, timeStep)
															.endOf(timeStep)
															.format('YYYY-MM-DD')
													: lastReadingDate &&
													  dayjs(lastReadingDate)
															.subtract(1, timeStep)
															.endOf(timeStep)
															.format('YYYY-MM-DD')
											});
										onSpanSelect &&
											onSpanSelect({
												start: displayTimeSpan?.start
													? dateToQuery(
															dayjs(displayTimeSpan?.start)
																.subtract(1, timeStep)
																.startOf(timeStep)
													  )
													: lastReadingDate &&
													  dateToQuery(
															dayjs(lastReadingDate)
																.subtract(1, timeStep)
																.startOf(timeStep)
													  ),
												end: displayTimeSpan?.start
													? dateToQuery(
															dayjs(displayTimeSpan?.start)
																.subtract(1, timeStep)
																.endOf(timeStep)
													  )
													: lastReadingDate &&
													  dateToQuery(
															dayjs(lastReadingDate).subtract(1, timeStep).endOf(timeStep)
													  )
											});
									}
								}}
							/>
							<div style={{ width: '100%' }}>
								<Chart
									options={getOptions({
										yAxis,
										alerts: alertsWithVal,
										t: t,
										noDataText,
										cleanDataObjects,
										alarmLevels,
										seriesData: series
									})}
									series={series}
									type="line"
									height={graphHeight || '98%'}
								/>
							</div>
							<AiOutlineRight
								size={'2rem'}
								role={'button'}
								onClick={() => {
									if (showCustomSelectorDate) {
										OnDateSelect &&
											OnDateSelect(
												displayDate
													? dayjs(displayDate).add(1, timeStep).format('YYYY-MM-DD')
													: lastReadingDate &&
															dayjs(lastReadingDate).add(1, timeStep).format('YYYY-MM-DD')
											);
										setDisplayDate &&
											setDisplayDate(
												displayDate
													? dayjs(displayDate).add(1, timeStep).format('YYYY-MM-DD')
													: lastReadingDate &&
															dayjs(lastReadingDate).add(1, timeStep).format('YYYY-MM-DD')
											);
									} else {
										setDisplayTimeSpan &&
											setDisplayTimeSpan({
												start: displayTimeSpan?.start
													? dayjs(displayTimeSpan?.start)
															.add(1, timeStep)
															.startOf(timeStep)
															.format('YYYY-MM-DD')
													: lastReadingDate &&
													  dayjs(lastReadingDate)
															.add(1, timeStep)
															.startOf(timeStep)
															.format('YYYY-MM-DD'),
												end: displayTimeSpan?.start
													? dayjs(displayTimeSpan?.start)
															.add(1, timeStep)
															.endOf(timeStep)
															.format('YYYY-MM-DD')
													: lastReadingDate &&
													  dayjs(lastReadingDate)
															.add(1, timeStep)
															.endOf(timeStep)
															.format('YYYY-MM-DD')
											});
										onSpanSelect &&
											onSpanSelect({
												start: displayTimeSpan?.start
													? dateToQuery(
															dayjs(displayTimeSpan?.start)
																.add(1, timeStep)
																.startOf(timeStep)
													  )
													: lastReadingDate &&
													  dateToQuery(
															dayjs(lastReadingDate).add(1, timeStep).startOf(timeStep)
													  ),
												end: displayTimeSpan?.start
													? dateToQuery(
															dayjs(displayTimeSpan?.start)
																.add(1, timeStep)
																.endOf(timeStep)
													  )
													: lastReadingDate &&
													  dateToQuery(
															dayjs(lastReadingDate).add(1, timeStep).endOf(timeStep)
													  )
											});
									}
								}}
							/>
						</div>
					</>
				) : (
					<Chart
						options={getOptions({
							yAxis,
							alerts: alertsWithVal,
							t: t,
							noDataText,
							cleanDataObjects,
							alarmLevels,
							seriesData: series
						})}
						series={series}
						type="line"
						height={graphHeight || '98%'}
					/>
				)}
				{dateText ? (
					<div className="d-flex align-items-center w-100 justify-content-center mt-0">
						<span
							style={{
								color: colors.GREY,
								marginRight: i18n.language === 'en' ? '15px' : '',
								marginLeft: i18n.language === 'en' ? '' : '15px',
								fontSize: '1rem'
							}}
						>
							{dateText}
						</span>
					</div>
				) : null}

				{/* {showBrush ? (
						<Chart
							options={getOptionsLine(
								timeSpan ?? {
									start: dataObjects[0].data[0].time,
									end: dataObjects[0].data[dataObjects[0].data.length - 1].time
								}
							)}
							series={series}
							type="line"
							height={150}
						/>
					) : null} */}
				<div className="mt-5">
					{showBrush && brushData?.length ? (
						<>
							<ResponsiveContainer width={i18n.language === 'en' ? '90%' : '93%'} height={100}>
								<ComposedChart
									data={brushData}
									style={i18n.language === 'en' ? { marginLeft: '5.5%' } : { direction: 'ltr' }}
								>
									<Brush
										dataKey={t('time')}
										tickFormatter={time => (time ? dayjs(time).format('DD-MMM') : '')}
										height={90}
										startIndex={brushState?.startIndex}
										endIndex={brushState?.endIndex}
										alwaysShowText
										stroke={colors.GRAPH_PURPLE}
										onChange={debounce((e: any) => {
											if (brushData?.length) {
												const startTime = brushData[e.startIndex].time;
												const endTime = brushData[e.endIndex].time;
												onSpanSelect &&
													onSpanSelect({
														start: dateToQuery(dayjs(startTime)),
														end: dateToQuery(dayjs(endTime))
													});
												setDisplayTimeSpan &&
													setDisplayTimeSpan({
														start: dayjs(startTime).format('YYYY-MM-DD'),

														end: dayjs(endTime).format('YYYY-MM-DD')
													});
												setCustomBrushSpan(true);
												setTimeStep(undefined);
											}
										}, 700)}
									>
										<LineChart>
											<CartesianGrid
												horizontal
												stroke={colors.LIGHT_GREY9}
												strokeWidth={0.5}
												strokeDasharray="3 3"
											/>
											{minMax ? (
												<YAxis hide domain={[minMax.min, minMax.max]} />
											) : (
												<YAxis hide domain={['auto', 'auto']} />
											)}
											{cleanDataObjects?.length &&
												cleanDataObjects.map((cleanDataObject: any, idx: number) => (
													<Line
														dataKey={cleanDataObject.name}
														dot={false}
														activeDot={{ r: 8 }}
														stroke={COLORS[idx % COLORS.length]}
														strokeWidth={1.2}
														key={idx}
													/>
												))}
										</LineChart>
									</Brush>
								</ComposedChart>
							</ResponsiveContainer>
							<div className="w-100 d-flex justify-content-between " dir="ltr">
								{brushData?.length ? (
									<span
										style={{ color: colors.GRAPH_PURPLE }}
										className={i18n.language === 'en' ? 'mx-3' : ''}
									>
										<small>{dayjs(brushData[0].time).format('dddd, MMMM D, YYYY h:mm A')}</small>
									</span>
								) : null}
								{brushData?.length ? (
									<span
										style={{ color: colors.GRAPH_PURPLE }}
										className={i18n.language === 'en' ? '' : 'mx-3'}
									>
										<small>
											{dayjs(brushData[brushData.length - 1].time).format(
												'dddd, MMMM D, YYYY h:mm A'
											)}
										</small>
									</span>
								) : null}
							</div>
						</>
					) : null}
				</div>
			</div>
		</GraphCard>
	);
};
