import styled from 'styled-components';
import { Input } from 'antd';
import { colors } from '../../../constants/colors';

const { Search } = Input;

export const WaiSearchBar = styled(Search)`
	.ant-input {
		background-color: transparent;
	}
	&.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
		background-color: transparent;
		border-radius: 15px 0 0 15px;
	}

	&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
		border-radius: 0 15px 15px 0;
		display: flex;
		justify-content: center;
		align-items: center;
		border-color: ${colors.CYAN2};
		background-color: ${colors.CYAN2};
	}

	&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
		background-color: transparent;
		border-radius: 15px;
	}
`;
