import { useContext, useEffect } from 'react';
import { MeasurementPointAnalysis, NetworkAnalysis, ZoneAnalysis } from '../containers';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import { useQueryParam } from '../hooks/use-query';
import { CommercialMeterGroupAnalysis } from '../containers/commercial-meter-group-analysis';

export const AnalysisPage = () => {
	const { i18n } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { selectedZone, selectedMeasurementPoint, setSelectedMeasurementPoint, setSelectedCommercialMeterGroup } =
		navigationContext;
	const zoneQuery = useQueryParam('zone');
	const measurementPointQuery = useQueryParam('point');
	const commercialMerterGroupQuery = useQueryParam('commercialMeterGroup');

	useEffect(() => {
		// if (zoneQuery) setSelectedZone(zoneQuery);
		if (measurementPointQuery) setSelectedMeasurementPoint(measurementPointQuery);
		if (commercialMerterGroupQuery) setSelectedCommercialMeterGroup(commercialMerterGroupQuery);
	}, [zoneQuery, measurementPointQuery, commercialMerterGroupQuery]);
	return (
		<div
			style={{
				paddingLeft: i18n.language === 'en' ? '1rem' : '',
				paddingRight: i18n.language === 'en' ? '' : '0.2rem',
				paddingTop: '2rem',
				paddingBottom: '2rem'
			}}
		>
			{commercialMerterGroupQuery ? (
				<CommercialMeterGroupAnalysis />
			) : selectedMeasurementPoint ? (
				<MeasurementPointAnalysis />
			) : selectedZone ? (
				<ZoneAnalysis />
			) : (
				<NetworkAnalysis />
			)}
		</div>
	);
};
