import { Collapse, CollapseProps } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	MeasurementPointInfoLabel,
	MeasurementPointInfoText
} from '../components/measurement-point-info/styles/measurement-point-info';

export const CollapsibleLoggers: React.FC<CollapseProps & { loggers: any }> = ({ loggers, ...restProps }) => {
	const { t } = useTranslation();

	return (
		<Collapse accordion style={{ minWidth: '360px' }} {...restProps}>
			{loggers &&
				loggers.length &&
				loggers.map((logger: any) => {
					return (
						<Collapse.Panel key={logger.id} header={logger.vendor || 'Logger'}>
							{/* {logger.hasOwnProperty('is_active') ? (
								<MeasurementPointInfoText>
									<MeasurementPointInfoLabel>Is Active: </MeasurementPointInfoLabel>
									{logger.is_active ? 'Yes' : 'No'}
								</MeasurementPointInfoText>
							) : null} */}

							{logger.hasOwnProperty('vendor') ? (
								<MeasurementPointInfoText>
									<MeasurementPointInfoLabel>{t('Vendor')} </MeasurementPointInfoLabel>
									{logger.vendor}
								</MeasurementPointInfoText>
							) : null}
							{logger.hasOwnProperty('serial') ? (
								<MeasurementPointInfoText>
									<MeasurementPointInfoLabel>{t('Serial')} </MeasurementPointInfoLabel>
									{logger.serial}
								</MeasurementPointInfoText>
							) : null}
							{/* {logger.hasOwnProperty('is_online') ? (
								<MeasurementPointInfoText>
									<MeasurementPointInfoLabel>Is Online: </MeasurementPointInfoLabel>
									{logger.is_online ? 'Yes' : 'No'}
								</MeasurementPointInfoText>
							) : null} */}
						</Collapse.Panel>
					);
				})}
		</Collapse>
	);
};
