import { Carousel } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { ScreenSize } from '../../../constants/screen-size';
import { ScreenWidths } from '../../../hooks/use-media.hook';

export const WaiCarousel = styled(Carousel)`
	width: fit-content;
	max-width: 85vw;
	min-width: 80vw;
	display: flex;
	align-items: center;
	margin-left: 15px;
	justify-content: between;
	.slick-prev,
	.slick-next,
	.slick-prev:hover,
	.slick-next:hover {
		font-size: inherit;
		color: ${colors.WHITE};
		font-weight: 600;
		background-color: ${colors.BLACK_RGBA5};
		padding: 5px;
		border-radius: 50%;
		width: 25px;
		height: 25px;
	}
	.slick-prev {
		left: -25px;
	}
	.slick-next {
		right: -25px;
	}

	@media (min-width: ${ScreenWidths['md']}) {
		max-width: 90vw;
		margin-left: 10px;
		.slick-prev {
			left: -30px;
		}
		.slick-next {
			right: -30px;
		}
	}

	@media (min-width: ${ScreenWidths['xxl']}) {
		max-width: 83vw;
		margin-left: 0;
		.slick-prev {
			left: -30px;
		}
		.slick-next {
			right: -30px;
		}
	}
`;

export const GraphsCarouselContainer = styled.div<{ englishSelected: any }>`
	bottom: 2.5rem;
	position: fixed !important;
	z-index: 70;
	${({ englishSelected }) => (englishSelected ? 'margin-left: 3%;' : 'margin-right: 3%;')};
	display: flex;
	align-items: center;
	justify-content: center;
`;
