import { Col, Row, Spin } from 'antd';
import { useState } from 'react';
import { useQueries, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { colors } from '../constants/colors';
import { useTranslation } from 'react-i18next';
import chartsService from '../services/charts.service';
import { DataSources, PressureType, PressureUnit } from '../types';
import { FlowRateUnit } from '../types/flow-rate-unit';
import { clearArrayUndefined } from '../utils/clean-array-undefined';
import { mostRecentDate } from '../utils/most-recent-date';
import { WaiTimeSeriesChart } from './wai-time-series';
import { mean } from 'stats-lite';

export const MeasurementPointAnalysisDataSourcesTab: React.FC<{
	flowRateSource: any;
	calculatedFlowRateSource: any;
	pressureSource: any;
	upstreamPressureSource: any;
	downstreamPressureSource: any;
	noiseSource: any;
	accumulatedFlowSource: any;
	measurementPointName: any;
}> = ({
	flowRateSource,
	calculatedFlowRateSource,
	pressureSource,
	upstreamPressureSource,
	downstreamPressureSource,
	noiseSource,
	accumulatedFlowSource,
	measurementPointName
}) => {
	const { t } = useTranslation();
	const [chartValveTimeSpan, setChartValveTimeSpan] = useState<{ start: string; end: string }>();
	const [chartDataSourcesTimeSpan, setChartDataSourcesTimeSpan] = useState<{ start: string; end: string }>();
	const [chartNoiseTimeSpan, setChartNoiseTimeSpan] = useState<{ start: string; end: string }>();
	const [chartAccumulatedFlowTimeSpan, setChartAccumulatedFlowTimeSpan] = useState<{ start: string; end: string }>();

	// Last readings
	const { data: valvePressureLastReading } = useQuery<any>({
		queryKey: ['data-source-last-log-date', upstreamPressureSource?.id],
		queryFn: () => chartsService.getDataSourceLastLogDate(upstreamPressureSource?.id),
		enabled: !!upstreamPressureSource?.id && !!downstreamPressureSource?.id
	});
	const { data: pressureLastReading } = useQuery<any>({
		queryKey: ['data-source-last-log-date', pressureSource?.id],
		queryFn: () => chartsService.getDataSourceLastLogDate(pressureSource?.id),
		enabled: !!pressureSource?.id
	});
	const { data: flowrateLastReading } = useQuery<any>({
		queryKey: ['data-source-last-log-date', flowRateSource?.id ?? calculatedFlowRateSource?.id],
		queryFn: () => chartsService.getDataSourceLastLogDate(flowRateSource?.id ?? calculatedFlowRateSource?.id),
		enabled: !!flowRateSource?.id || !!calculatedFlowRateSource?.id
	});
	const { data: accumulatedFlowLastReading } = useQuery<any>({
		queryKey: ['data-source-last-log-date', accumulatedFlowSource?.id],
		queryFn: () => chartsService.getDataSourceLastLogDate(accumulatedFlowSource?.id),
		enabled: !!accumulatedFlowSource?.id
	});
	const { data: noiseLastReading } = useQuery<any>({
		queryKey: ['data-source-last-log-date', noiseSource?.id],
		queryFn: () => chartsService.getDataSourceLastLogDate(noiseSource?.id),
		enabled: !!noiseSource?.id
	});

	// All data
	const allValvePressureLogs: any = useQueries<any>({
		queries: [
			{
				queryKey: ['data-source-logs', upstreamPressureSource?.id],
				queryFn: () =>
					chartsService.getDSLogs(upstreamPressureSource?.id, {
						aggregate: 1
					}),
				enabled: !!upstreamPressureSource?.id && !!downstreamPressureSource?.id
			},
			{
				queryKey: ['data-source-logs', downstreamPressureSource?.id],
				queryFn: () =>
					chartsService.getDSLogs(downstreamPressureSource?.id, {
						aggregate: 1
					}),
				enabled: !!upstreamPressureSource?.id && !!downstreamPressureSource?.id
			}
		]
	});
	const {
		data: allPressureLogs,
		isLoading: loadingAllPressure,
		isFetching: fetchingAllPressure
	} = useQuery<any>({
		queryKey: ['data-source-logs', pressureSource?.id],
		queryFn: () =>
			chartsService.getDSLogs(pressureSource?.id, {
				aggregate: 1
			}),
		enabled: !!pressureSource?.id
	});
	const {
		data: allFlowRateLogs,
		isLoading: loadingAllFlowRateLogs,
		isFetching: fetchingAllFlowRateLogs
	} = useQuery<any>({
		queryKey: ['data-source-logs', flowRateSource?.id ?? calculatedFlowRateSource?.id],
		queryFn: () =>
			chartsService.getDSLogs(flowRateSource?.id ?? calculatedFlowRateSource?.id, {
				aggregate: 1
			}),
		enabled: !!flowRateSource?.id || !!calculatedFlowRateSource?.id
	});
	const {
		data: allAccumulatedFlow,
		isLoading: loadingAllAccumulatedFlow,
		isFetching: fetchingAllAccumulatedFlow
	} = useQuery<any>({
		queryKey: ['data-source-logs', accumulatedFlowSource?.id],
		queryFn: () =>
			chartsService.getDSLogs(accumulatedFlowSource?.id, {
				aggregate: 1
			}),
		enabled: !!accumulatedFlowSource?.id
	});
	const {
		data: allNoiseLogs,
		isLoading: loadingAllNoise,
		isFetching: fetchingAllNoise
	} = useQuery<any>({
		queryKey: ['data-source-logs', noiseSource?.id],
		queryFn: () =>
			chartsService.getDSLogs(noiseSource?.id, {
				aggregate: 1
			}),
		enabled: !!noiseSource?.id
	});

	// data related to time span
	const valvePressureChart: any = useQueries<any>({
		queries: [
			{
				queryKey: ['data-source-logs', chartValveTimeSpan, upstreamPressureSource?.id],
				queryFn: () =>
					chartsService.getDSLogs(upstreamPressureSource?.id, {
						start: chartValveTimeSpan?.start,
						end: chartValveTimeSpan?.end,
						aggregate:
							chartValveTimeSpan?.start &&
							dayjs(chartValveTimeSpan?.end).diff(chartValveTimeSpan?.start, 'd') === 0
								? undefined
								: 1
					}),
				enabled: !!upstreamPressureSource?.id && !!downstreamPressureSource?.id
			},
			{
				queryKey: ['data-source-logs', chartValveTimeSpan, downstreamPressureSource?.id],
				queryFn: () =>
					chartsService.getDSLogs(downstreamPressureSource?.id, {
						start: chartValveTimeSpan?.start,
						end: chartValveTimeSpan?.end,
						aggregate:
							chartValveTimeSpan?.start &&
							dayjs(chartValveTimeSpan?.end).diff(chartValveTimeSpan?.start, 'd') === 0
								? undefined
								: 1
					}),
				enabled: !!upstreamPressureSource?.id && !!downstreamPressureSource?.id
			}
		]
	});
	const {
		data: pressureChart,
		isLoading: loadingPressure,
		isFetching: fetchingPressure
	} = useQuery<any>({
		queryKey: ['data-source-logs', chartDataSourcesTimeSpan, pressureSource?.id],
		queryFn: () => {
			return chartsService.getDSLogs(pressureSource?.id, {
				start: chartDataSourcesTimeSpan?.start,
				end: chartDataSourcesTimeSpan?.end,
				aggregate:
					chartDataSourcesTimeSpan?.start &&
					dayjs(chartDataSourcesTimeSpan?.end).diff(chartDataSourcesTimeSpan?.start, 'd') === 0
						? undefined
						: 1
			});
		},
		enabled: !!pressureSource?.id
	});
	const {
		data: flowRateChart,
		isLoading: loadingFlowrate,
		isFetching: fetchingFlowrate
	} = useQuery<any>({
		queryKey: ['data-source-logs', chartDataSourcesTimeSpan, flowRateSource?.id ?? calculatedFlowRateSource?.id],
		queryFn: () =>
			chartsService.getDSLogs(flowRateSource?.id ?? calculatedFlowRateSource?.id, {
				start: chartDataSourcesTimeSpan?.start,
				end: chartDataSourcesTimeSpan?.end,
				aggregate:
					chartDataSourcesTimeSpan?.start &&
					dayjs(chartDataSourcesTimeSpan?.end).diff(chartDataSourcesTimeSpan?.start, 'd') === 0
						? undefined
						: 1
			}),
		enabled: !!flowRateSource?.id || !!calculatedFlowRateSource?.id
	});
	const {
		data: accumulatedFlowChart,
		isLoading: loadingAccumulatedFlow,
		isFetching: fetchingAccumulatedFlow
	} = useQuery<any>({
		queryKey: ['data-source-logs', chartAccumulatedFlowTimeSpan, accumulatedFlowSource?.id],
		queryFn: () => {
			return chartsService.getDSLogs(accumulatedFlowSource?.id, {
				start: chartAccumulatedFlowTimeSpan?.start,
				end: chartAccumulatedFlowTimeSpan?.end,
				aggregate:
					chartAccumulatedFlowTimeSpan?.start &&
					dayjs(chartAccumulatedFlowTimeSpan?.end).diff(chartAccumulatedFlowTimeSpan?.start, 'd') === 0
						? undefined
						: 1
			});
		},
		enabled: !!accumulatedFlowSource?.id
	});
	const {
		data: noiseChart,
		isLoading: loadingNoise,
		isFetching: fetchingNoise
	} = useQuery<any>({
		queryKey: ['data-source-logs', chartNoiseTimeSpan, noiseSource?.id],
		queryFn: () =>
			chartsService.getDSLogs(noiseSource?.id, {
				start: chartNoiseTimeSpan?.start,
				end: chartNoiseTimeSpan?.end,
				aggregate:
					chartNoiseTimeSpan?.start &&
					dayjs(chartNoiseTimeSpan?.end).diff(chartNoiseTimeSpan?.start, 'd') === 1
						? undefined
						: 1
			}),
		enabled: !!noiseSource?.id
	});

	return (
		<Row>
			{!!upstreamPressureSource?.id && !!downstreamPressureSource?.id ? (
				<Col xs={24}>
					<Spin
						spinning={
							!!allValvePressureLogs.find(valve => valve.status !== 'success') ||
							!!valvePressureChart.find(valve => valve.status !== 'success')
						}
					>
						<WaiTimeSeriesChart
							IDs={[
								{ id: upstreamPressureSource?.id, name: PressureType.UPSTREAM },
								{ id: downstreamPressureSource?.id, name: PressureType.DOWNSTREAM }
							]}
							flags={{ aggregate: true }}
							title={t('ValvePressure')}
							height={55}
							graphHeight={500}
							showBrush
							showTabular
							onSpanSelect={(span: any) => setChartValveTimeSpan(span)}
							lastReadingDate={valvePressureLastReading?.time}
							alarmLevels={[
								{
									min: upstreamPressureSource?.min_value_alarm,
									max: upstreamPressureSource?.max_value_alarm,
									type: t('UpstreamPressure')
								},
								{
									min: downstreamPressureSource?.min_value_alarm,
									max: downstreamPressureSource?.max_value_alarm,
									type: t('DownstreamPressure')
								}
							]}
							statistics={clearArrayUndefined([
								...[
									valvePressureChart[0]?.data && {
										name: t('MinimumUpstream'),
										value: Math.min(...valvePressureChart[0].data.map((log: any) => +log.value)),
										unit: PressureUnit.BAR,
										color: colors.GRAPH_PURPLE
									},
									valvePressureChart[0]?.data && {
										name: t('MaximumUpstream'),
										value: Math.max(...valvePressureChart[0].data.map((log: any) => +log.value)),
										unit: PressureUnit.BAR,
										color: colors.GRAPH_PURPLE
									},
									valvePressureChart[0]?.data && {
										name: t('AverageUpstream'),
										value: mean(valvePressureChart[0].data.map((log: any) => +log.value)),
										unit: PressureUnit.BAR,
										color: colors.GRAPH_PURPLE
									}
								],
								...[
									valvePressureChart[1]?.data && {
										name: t('MinimumDownstream'),
										value: Math.min(...valvePressureChart[1].data.map((log: any) => +log.value)),
										unit: PressureUnit.BAR,
										color: colors.GRAPH_GREEN
									},
									valvePressureChart[1]?.data && {
										name: t('MaximumDownstream'),
										value: Math.max(...valvePressureChart[1].data.map((log: any) => +log.value)),
										unit: PressureUnit.BAR,
										color: colors.GRAPH_GREEN
									},
									valvePressureChart[1]?.data && {
										name: t('AverageDownstream'),
										value: mean(valvePressureChart[1].data.map((log: any) => +log.value)),
										unit: PressureUnit.BAR,
										color: colors.GRAPH_GREEN
									}
								]
							])}
							minMax={{
								min: Math.min(
									...clearArrayUndefined([
										...(allValvePressureLogs[0]?.data || []).map((log: any) => +log.value || 0),
										...(allValvePressureLogs[1]?.data || []).map((log: any) => +log.value || 0)
									])
								),
								max: Math.max(
									...clearArrayUndefined([
										...(allValvePressureLogs[0]?.data || []).map((log: any) => +log.value || 0),
										...(allValvePressureLogs[1]?.data || []).map((log: any) => +log.value || 0)
									])
								)
							}}
							dataObjects={clearArrayUndefined([
								{
									id: upstreamPressureSource?.id,
									name: t('UpstreamPressure'),
									data: valvePressureChart[0]?.data ?? [],
									allData: allValvePressureLogs[0]?.data ?? [],
									unit: PressureUnit.BAR,
									yAxis: {
										position: 'left',
										legend: t('PressureInBAR')
									}
								},
								{
									id: downstreamPressureSource?.id,
									name: t('DownstreamPressure'),
									data: valvePressureChart[1]?.data ?? [],
									allData: allValvePressureLogs[1]?.data ?? [],
									unit: PressureUnit.BAR
								}
							])}
							showSpanSelector
							showCustomSpanSelector
							showTimeStep
							sourceName={measurementPointName}
						/>
					</Spin>
				</Col>
			) : null}
			{!!pressureSource?.id || !!flowRateSource?.id || !!calculatedFlowRateSource?.id ? (
				<Col xs={24}>
					<Spin
						spinning={
							(loadingPressure && fetchingPressure) ||
							(loadingFlowrate && fetchingFlowrate) ||
							(loadingAllPressure && fetchingAllPressure) ||
							(loadingAllFlowRateLogs && fetchingAllFlowRateLogs)
						}
					>
						<WaiTimeSeriesChart
							IDs={clearArrayUndefined([
								{ id: pressureSource?.id, name: DataSources.PRESSURE },
								{ id: flowRateSource?.id, name: DataSources.FLOW_RATE },
								{ id: calculatedFlowRateSource?.id, name: DataSources.CALCULATED_FLOW_RATE }
							])}
							flags={{ aggregate: true }}
							title={`${!!pressureSource ? t('Pressure') : ''}
								${!!pressureSource && (!!flowRateSource || !!calculatedFlowRateSource) ? t('And') : ''}	
								${!!flowRateSource ? t('FlowRate') : !!calculatedFlowRateSource ? t('CalculatedFlowRate') : ''}`.trim()}
							height={55}
							graphHeight={500}
							showBrush
							showTabular
							onSpanSelect={(span: any) => setChartDataSourcesTimeSpan(span)}
							lastReadingDate={mostRecentDate([pressureLastReading?.time, flowrateLastReading?.time])}
							alarmLevels={clearArrayUndefined([
								{
									min: pressureSource?.min_value_alarm,
									max: pressureSource?.max_value_alarm,
									type: t('Pressure')
								},
								{
									min: flowRateSource?.min_value_alarm,
									max: flowRateSource?.max_value_alarm,
									type: t('FlowRate')
								},
								{
									min: calculatedFlowRateSource?.min_value_alarm,
									max: calculatedFlowRateSource?.max_value_alarm,
									type: t('CalculatedFlowRate')
								}
							])}
							statistics={clearArrayUndefined([
								{
									name: t('MinimumPressure'),
									value: pressureChart && Math.min(...pressureChart.map((log: any) => +log.value)),
									unit: PressureUnit.BAR,
									color: colors.GRAPH_PURPLE
								},
								{
									name: t('MaximumPressure'),
									value: pressureChart && Math.max(...pressureChart.map((log: any) => +log.value)),
									unit: PressureUnit.BAR,
									color: colors.GRAPH_PURPLE
								},
								{
									name: t('AveragePressure'),
									value: pressureChart && mean(pressureChart.map((log: any) => +log.value)),
									unit: PressureUnit.BAR,
									color: colors.GRAPH_PURPLE
								},
								{
									name: calculatedFlowRateSource?.id
										? t('MinimumCalculatedFlowRate')
										: t('MinimumFlowRate'),
									value: flowRateChart && Math.min(...flowRateChart.map((log: any) => +log.value)),
									unit: FlowRateUnit.CMH,
									color: pressureSource?.id ? colors.GRAPH_GREEN : colors.GRAPH_PURPLE
								},
								{
									name: calculatedFlowRateSource?.id
										? t('MaximumCalculatedFlowRate')
										: t('MaximumFlowRate'),
									value: flowRateChart && Math.max(...flowRateChart.map((log: any) => +log.value)),
									unit: FlowRateUnit.CMH,
									color: pressureSource?.id ? colors.GRAPH_GREEN : colors.GRAPH_PURPLE
								},
								{
									name: calculatedFlowRateSource?.id
										? t('AverageCalculatedFlowRate')
										: t('AverageFlowRate'),
									value: flowRateChart && mean(flowRateChart.map((log: any) => +log.value)),
									unit: FlowRateUnit.CMH,
									color: pressureSource?.id ? colors.GRAPH_GREEN : colors.GRAPH_PURPLE
								}
							])}
							minMax={{
								min: Math.min(
									...clearArrayUndefined([
										...(allPressureLogs || []).map((log: any) => +log.value || 0),
										...(allFlowRateLogs || []).map((log: any) => +log.value || 0)
									])
								),
								max: Math.max(
									...clearArrayUndefined([
										...(allPressureLogs || []).map((log: any) => +log.value || 0),
										...(allFlowRateLogs || []).map((log: any) => +log.value || 0)
									])
								)
							}}
							dataObjects={clearArrayUndefined([
								{
									id: pressureSource?.id,
									name: t('Pressure'),
									data: pressureChart ?? [],
									allData: allPressureLogs ?? [],
									unit: PressureUnit.BAR,
									yAxis: {
										position: 'left',
										legend: t('PressureInBAR')
									}
								},
								{
									id: flowRateSource?.id,
									name: t('FlowRate'),
									data: flowRateChart ?? [],
									allData: allFlowRateLogs ?? [],
									unit: FlowRateUnit.CMH,
									yAxis: {
										position: pressureSource?.id ? 'right' : 'left',
										legend: t('FlowRateInCMH')
									}
								},
								{
									id: calculatedFlowRateSource?.id,
									name: t('CalculatedFlowRate'),
									data: flowRateChart ?? [],
									allData: allFlowRateLogs ?? [],
									unit: FlowRateUnit.CMH,
									yAxis: {
										position: pressureSource?.id ? 'right' : 'left',
										legend: t('CalculatedFlowRateInCMH')
									}
								}
							])}
							showSpanSelector
							showCustomSpanSelector
							showTimeStep
							sourceName={measurementPointName}
						/>
					</Spin>
				</Col>
			) : null}
			{accumulatedFlowSource?.id ? (
				<Col xs={24}>
					<Spin
						spinning={
							(loadingAccumulatedFlow && fetchingAccumulatedFlow) ||
							(loadingAllAccumulatedFlow && fetchingAllAccumulatedFlow)
						}
					>
						<WaiTimeSeriesChart
							IDs={[
								{
									id: accumulatedFlowSource?.id,
									name: DataSources.ACCUMULATED_FLOW
								}
							]}
							flags={{ aggregate: true }}
							title={t('AccumulatedFlow')}
							height={55}
							graphHeight={500}
							showBrush
							showTabular
							onSpanSelect={(span: any) => setChartAccumulatedFlowTimeSpan(span)}
							lastReadingDate={accumulatedFlowLastReading?.time}
							statistics={clearArrayUndefined(
								accumulatedFlowChart
									? [
											{
												name: t('Minimum'),
												value: Math.min(...accumulatedFlowChart?.map((log: any) => +log.value))
											},
											{
												name: t('Maximum'),
												value: Math.max(...accumulatedFlowChart?.map((log: any) => +log.value))
											},
											{
												name: t('Average'),
												value: mean(accumulatedFlowChart?.map((log: any) => +log.value))
											}
									  ]
									: []
							)}
							minMax={
								allAccumulatedFlow
									? {
											min: Math.min(...allAccumulatedFlow.map((log: any) => +log.value)),
											max: Math.max(...allAccumulatedFlow.map((log: any) => +log.value))
									  }
									: undefined
							}
							dataObjects={clearArrayUndefined([
								{
									id: accumulatedFlowSource?.id,
									name: t('AccumulatedFlow'),
									data: accumulatedFlowChart ?? [],
									allData: allAccumulatedFlow ?? [],
									yAxis: {
										position: 'left',
										legend: t('AccumulatedFlow')
									}
								}
							])}
							showSpanSelector
							showCustomSpanSelector
							showTimeStep
							sourceName={measurementPointName}
						/>
					</Spin>
				</Col>
			) : null}
			{noiseSource?.id ? (
				<Col xs={24}>
					<Spin spinning={(loadingNoise && fetchingNoise) || (loadingAllNoise && fetchingAllNoise)}>
						<WaiTimeSeriesChart
							IDs={[
								{
									id: noiseSource?.id,
									name: DataSources.NOISE
								}
							]}
							flags={{ aggregate: true }}
							title={t('Noise')}
							height={55}
							graphHeight={500}
							showBrush
							showTabular
							onSpanSelect={(span: any) => setChartNoiseTimeSpan(span)}
							lastReadingDate={noiseLastReading?.time}
							statistics={clearArrayUndefined(
								noiseChart
									? [
											{
												name: t('Minimum'),
												value: Math.min(...noiseChart?.map((log: any) => +log.value))
											},
											{
												name: t('Maximum'),
												value: Math.max(...noiseChart?.map((log: any) => +log.value))
											},
											{
												name: t('Average'),
												value: mean(noiseChart?.map((log: any) => +log.value))
											}
									  ]
									: []
							)}
							minMax={
								allNoiseLogs
									? {
											min: Math.min(...allNoiseLogs.map((log: any) => +log.value)),
											max: Math.max(...allNoiseLogs.map((log: any) => +log.value))
									  }
									: undefined
							}
							dataObjects={clearArrayUndefined([
								{
									id: noiseSource?.id,
									name: t('Noise'),
									data: noiseChart ?? [],
									allData: allNoiseLogs ?? [],
									yAxis: {
										position: 'left',
										legend: t('Noise')
									}
								}
							])}
							showSpanSelector
							showCustomSpanSelector
							showTimeStep
							sourceName={measurementPointName}
						/>
					</Spin>
				</Col>
			) : null}
		</Row>
	);
};
