import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { AlertCardType, AlertType } from '../../../types/alerts';

const AlertCardBackgroundColorMapper = {
	[AlertCardType.OPEN]: colors.PRIMARY_WARM,
	[AlertCardType.INTERMEDIATE]: colors.WARNING_WARM,
	[AlertCardType.RESOLVED]: colors.SUCCESS_WARM,
	[AlertCardType.URGENT]: colors.ERROR_WARM
};

export const AlertsCardContainer = styled.div<{ type: AlertCardType; englishSelected: boolean }>`
	width: 180px;
	max-height: 100px;
	border-radius: 6px;
	background-color: ${({ type }) => AlertCardBackgroundColorMapper[type]};
	padding: 14px 10px;
	${({ englishSelected }) => (englishSelected ? 'margin-right: 1rem;' : 'margin-left: 1rem;')};
	margin-bottom: 1rem;
	@media (max-width: 930px) {
		height: 120px;
	}
`;

export const AlertsCardTitle = styled.h3`
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
	color: ${colors.WHITE};
	margin: 0;
`;

export const AlertsCardNumber = styled.h2`
	font-size: 3rem;
	line-height: 3.5rem;
	font-weight: 700;
	color: ${colors.WHITE};
`;
