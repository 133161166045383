import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const WaiAnalysisWidgetContainer = styled.div`
	background-color: ${colors.WHITE};
	padding: 20px 10px;
	border-radius: 5px;
	min-width: 200px;
	width: 100%;
`;

export const WaiAnalysisWidgetTitle = styled.h2`
	color: ${colors.PURPLE};
	font-size: 1.1rem;
	text-align: center;
`;

export const WaiAnalysisWidgetInclinedLine = styled.div<{ englishSelected: boolean }>`
	width: 150px;
	height: 4px;
	// background-color: ${colors.DARK_GREY4};
	position: sticky;
	transform: rotate(-50deg);
	${({ englishSelected }) => (englishSelected ? 'left: 53%;' : 'right: 53%;')};
	top: 15%;
`;
