import { useQuery } from '@tanstack/react-query';
import { Breadcrumb, Col, Row, Spin, Switch, Tabs } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { DataSourcesSummaryTable } from '../components/data-sources-summary-table';
import { DevicesSummaryTable } from '../components/devices-summary-table';
import { MonitoringMap } from '../containers';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../hooks/use-query';
import monitoringService from '../services/monitoring.service';
import { AppRoutes } from '../constants/routes';
import { TiHomeOutline } from 'react-icons/ti';
import { upperSnakeToCapitalized } from '../utils';
import { useNavigate } from 'react-router-dom';
import { NavigationContext } from '../contexts/navigation.context';
import { SiteConfigurationTab } from '../components/site-configuration-tab';
import { colors } from '../constants/colors';

export const SiteConfigurationPage: React.FC<any> = () => {
	const { t, i18n } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	const [selectedLocation, setSelectedLocation] = useState<any>({ latitude: undefined, longitude: undefined });
	const [activatedPoint, setActivatedPoint] = useState<boolean | null>(null);

	const siteId = useQueryParam('measurementPointId');
	const navigate = useNavigate();

	// load measurement point data
	const {
		data: siteData,
		isLoading: loadingSiteData,
		isFetching: fetchingSiteData,
		refetch: refetchSiteData
	} = useQuery<any>({
		queryKey: ['site', siteId],
		queryFn: () => siteId && monitoringService.getMeasurementPoint(siteId),
		enabled: !!siteId
	});

	// activate/deactivate site immediately when switching activation key
	useEffect(() => {
		if (siteId && siteData && activatedPoint !== null) {
			if (activatedPoint !== siteData.is_active) {
				(async () => {
					try {
						const resp = await monitoringService.editMeasurementPoint(siteId, {
							is_active: activatedPoint
						});
						if (!resp) throw new Error(t('couldntEditMeasurementPoint', { ns: 'validation' }));
						refetchSiteData();
					} catch (e: any) {
						errorHandler(e);
					}
				})();
			}
		}
	}, [activatedPoint, siteId]);

	// zoom to default site location
	useEffect(() => {
		if (siteData) {
			setSelectedLocation({
				latitude: siteData.latitude,
				longitude: siteData.longitude
			});
			setActivatedPoint(siteData.is_active);
		}
	}, [siteData]);

	const tabItems = [
		{
			label: t('MeasurementPointForm'),
			key: 'meaurement-point-form',
			children: (
				<SiteConfigurationTab
					siteData={siteData}
					setSelectedLocation={setSelectedLocation}
					selectedLocation={selectedLocation}
					activatedPoint={activatedPoint}
					setActivatedPoint={setActivatedPoint}
				/>
			)
		},
		siteData && {
			label: t('DataSources'),
			key: 'data-sources',
			children: <DataSourcesSummaryTable siteData={siteData && siteData} refetchSiteData={refetchSiteData} />
		},
		siteData && {
			label: t('DevicesLoggers'),
			key: 'loggers',
			children: <DevicesSummaryTable siteData={siteData && siteData} />
		}
	];
	return (
		<div
			style={{
				paddingLeft: i18n.language === 'en' ? '1rem' : '',
				paddingRight: i18n.language === 'en' ? '' : '1rem',
				paddingTop: '2rem'
			}}
		>
			<Breadcrumb
				className="mb-3"
				style={{ fontSize: '1rem' }}
				items={[
					{
						title: (
							<div
								className="d-flex align-items-center"
								role="button"
								onClick={() => navigate(`${AppRoutes.CONFIGURATION}?tabSelected=sites`)}
							>
								<TiHomeOutline className={i18n.language === 'en' ? 'me-1' : 'ms-1'} />
								<span>{t('ConfigurationName', { name: t('MeasurementPoints') })}</span>
							</div>
						)
					},
					{
						title: (
							<div style={{ fontWeight: 'bold' }} className="d-flex align-items-center">
								{t('ConfigurationName', {
									name: siteData
										? i18n.language === 'en'
											? upperSnakeToCapitalized(siteData.name_en)
											: siteData.name_ar
										: t('MeasurementPoint')
								})}
								<Switch
									checked={activatedPoint ?? false}
									checkedChildren={t('Active')}
									unCheckedChildren={t('Inactive')}
									onChange={(value, event) => {
										event.stopPropagation();
										setActivatedPoint(value);
									}}
									style={{ backgroundColor: activatedPoint ? colors.HEALTHY : colors.ERROR }}
									className={'mx-2'}
									size="small"
								/>
							</div>
						)
					}
				]}
				separator=">"
			/>
			<Row gutter={24}>
				<Col xs={24} md={14}>
					<Spin spinning={loadingSiteData && fetchingSiteData}>
						<Tabs type="card" style={{ fontWeight: '500' }} items={tabItems} />
					</Spin>
				</Col>
				<Col xs={24} md={10}>
					<MonitoringMap
						style={{
							height: '590px',
							width: 'auto',
							borderRadius: '13px',
							zIndex: 50
						}}
						pointMarker={
							selectedLocation.latitude && selectedLocation.longitude
								? [selectedLocation.latitude, selectedLocation.longitude]
								: undefined
						}
					/>
				</Col>
			</Row>
		</div>
	);
};
