import { message } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';
import * as XLSX from 'xlsx';
import monitoringService from '../services/monitoring.service';

export const handleBeforeUploadExcelFile = (file: RcFile, errorHandler: any, t: any) => {
	const allowedFileTypes = [
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
		'application/vnd.ms-excel', // XLS
		'text/csv', // CSV
		'application/vnd.ms-excel.sheet.macroEnabled.12', // XLSM
		'application/vnd.ms-excel.sheet.binary.macroEnabled.12' // XLSB
	];
	const isLt5M = file.size / 1024 / 1024 < 5;

	if (!allowedFileTypes.includes(file.type)) {
		errorHandler({ message: t('InvalidFileFormatPleaseUploadValidExcelFile', { ns: 'validation' }) });
	}
	if (!isLt5M) {
		errorHandler({ message: t('ImageMustBeSmallerThan5MB', { ns: 'validation' }) });
		return isLt5M;
	}
	return true;
};

export const handleUploadExcelFile = (file, t, setFileList, setExcelData) => {
	const reader = new FileReader();
	reader.onload = e => {
		const data = e.target?.result;
		if (data) {
			// Parse Excel data using xlsx library
			const workbook = XLSX.read(data, { type: 'binary' });
			const sheetNames = workbook.SheetNames;
			const transformedData: any = [];
			let errorValidation: boolean = false;

			// Iterate over each sheet
			sheetNames.forEach(sheetName => {
				const sheet = workbook.Sheets[sheetName];
				const jsonData: any = XLSX.utils.sheet_to_json(sheet, { header: 1 });

				// Get columns headers
				const columns: any = jsonData[0];

				// Perform column validation logic
				// NOTE: it will be dynamic later according to back-end
				const requiredColumns = ['المجموعة', 'نوع النشاط', 'كمية الاستهلاك', 'عدد الوحدات', 'عدد العملاء'];
				const optionalColumns = ['المسار', 'الوصف'];

				// Check the missing columns and number of thenm
				const missingColumns = requiredColumns.filter(col => !columns.map(c => c.trim()).includes(col));
				const hasRequiredColumns =
					columns.length >= 5 && requiredColumns.every(col => columns.map(c => c.trim()).includes(col));

				if (missingColumns.length > 0 || !hasRequiredColumns) {
					message.error(
						t('missingColumns', {
							ns: 'validation',
							columns: missingColumns.map(column => `"${column}"`).join(', ')
						})
					);
					errorValidation = true;
				} else {
					const headers: any = jsonData[0].map(header => header.trim());
					for (let i = 1; i < jsonData.length; i++) {
						const row: any = jsonData[i];
						const transformedRow: any = {};
						const otherValues: any = {};

						for (let j = 0; j < row.length; j++) {
							const value = row[j];
							if (requiredColumns.includes(headers[j]) || optionalColumns.includes(headers[j])) {
								transformedRow[headers[j]] = `${value}`;
							} else {
								otherValues[headers[j]] = value ? `${value}` : undefined;
							}
						}

						// Pass other data to "other" key if is not in required or optional columns
						if (Object.keys(otherValues).length !== 0) {
							transformedRow['other'] = otherValues;
						} else {
							transformedRow['other'] = null;
						}
						Object.keys(transformedData).forEach(key => {
							if (
								transformedData[key] === '' ||
								transformedData[key] === undefined ||
								transformedData[key] === null
							) {
								delete transformedData[key];
							}
						});
						transformedData.push(transformedRow);
					}
				}
			});

			if (!errorValidation) {
				setFileList([file]);
				setExcelData(transformedData);
			}
		}
	};

	reader.readAsBinaryString(file);
	return false;
};

export const confirmUploadExcelFile = async (values, commercialMeterGroupId, selectedNetwork) => {
	let data: any;

	// confirm file data for selected commercial meter group
	if (commercialMeterGroupId) {
		data = await monitoringService.editCommercialMeterGroupDataConfirm(commercialMeterGroupId, values);
	}
	// confirm file data for new commercial meter group
	else {
		Object.keys(values).forEach(key => {
			if (key === 'network') {
				delete values[key];
			}
		});
		data = await monitoringService.addCommercialMeterGroupDataConfirm(selectedNetwork, values);
	}
	return data;
};
