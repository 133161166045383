import { useQuery } from '@tanstack/react-query';
import { Breadcrumb, Col, Row, Spin, Tabs, Tooltip } from 'antd';
import { useState } from 'react';
import { MonitoringMap } from '../containers';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../hooks/use-query';
import monitoringService from '../services/monitoring.service';
import { AppRoutes } from '../constants/routes';
import { TiHomeOutline } from 'react-icons/ti';
import { upperSnakeToCapitalized } from '../utils';
import { useNavigate } from 'react-router-dom';
import { CommercialSitesSummaryTable } from '../components/commercial-sites-summary-table';
import {
	modalConfirm,
	UploadCommercialMeterGroupFile,
	CommercialMeterGroupForm,
	WaiAnalysisWidgetContainer,
	WaiAnalysisWidgetTitle
} from '../components';
import { AiOutlineInbox } from 'react-icons/ai';
import { colors } from '../constants/colors';

export const CommercialMeterGroupConfigurationPage: React.FC<any> = () => {
	const { t, i18n } = useTranslation();

	const [selectedLocation, setSelectedLocation] = useState<any>({ latitude: undefined, longitude: undefined });
	const [selectedUploadData, setSelectedUploadData] = useState<any>(undefined);

	const commercialMeterGroupId = useQueryParam('commercialMeterGroupId');
	const uploadFile = useQueryParam('uploadFile');

	const navigate = useNavigate();

	// load commercial meter group data
	const {
		data: commercialMeterGroupData,
		isLoading: loadingCommercialMeterGroupData,
		isFetching: fetchingCommercialMeterGroupData,
		refetch: refetchCommercialMeterGroupData
	} = useQuery<any>({
		queryKey: ['commercial-meter-group', commercialMeterGroupId],
		queryFn: () => commercialMeterGroupId && monitoringService.getCommercialMeterGroup(commercialMeterGroupId),
		enabled: !!commercialMeterGroupId
	});

	const tabItems = [
		{
			label: t('CommercialMeterGroupForm'),
			key: 'commercial-meter-group-form',
			children: (
				<CommercialMeterGroupForm
					setSelectedLocation={setSelectedLocation}
					selectedLocation={selectedLocation}
					commercialMeterGroupData={commercialMeterGroupData}
					refetchCommercialMeterGroupData={refetchCommercialMeterGroupData}
				/>
			)
		}
	];

	if (commercialMeterGroupId) {
		tabItems.push({
			label: t('CommercialSites'),
			key: 'commercial-sites',
			children: <CommercialSitesSummaryTable commercialMeterGroupId={commercialMeterGroupId} />
		});
	}

	return (
		<>
			<div
				style={{
					paddingLeft: i18n.language === 'en' ? '1rem' : '',
					paddingRight: i18n.language === 'en' ? '' : '1rem',
					paddingTop: '2rem'
				}}
			>
				<Breadcrumb
					className="mb-3"
					style={{ fontSize: '1rem' }}
					items={[
						{
							title: (
								<div
									className="d-flex align-items-center"
									role="button"
									onClick={() => navigate(`${AppRoutes.CONFIGURATION}?tabSelected=zones`)}
								>
									<TiHomeOutline className={i18n.language === 'en' ? 'me-1' : 'ms-1'} />
									<span>{t('ConfigurationName', { name: t('Zones') })}</span>
								</div>
							)
						},
						{
							title: (
								<div style={{ fontWeight: 'bold' }} className="d-flex align-items-center">
									{t('ConfigurationName', {
										name: `${t('CommercialMeterGroup')}  ${
											commercialMeterGroupData
												? i18n.language === 'en'
													? upperSnakeToCapitalized(commercialMeterGroupData.name_en)
													: commercialMeterGroupData.name_ar
												: ''
										}`
									})}
								</div>
							)
						}
					]}
					separator=">"
				/>
				<Spin spinning={loadingCommercialMeterGroupData && fetchingCommercialMeterGroupData}>
					{!uploadFile && commercialMeterGroupId ? (
						<Row gutter={[16, 16]}>
							<Col xs={24} md={14} className="mb-2">
								<Tabs type="card" style={{ fontWeight: '500' }} items={tabItems} />
							</Col>
							<Col xs={24} md={10}>
								<Row gutter={[16, 16]}>
									<Col xs={24}>
										<MonitoringMap
											style={{
												height: '590px',
												width: 'auto',
												borderRadius: '13px',
												zIndex: 50
											}}
											pointMarker={
												selectedLocation.latitude && selectedLocation.longitude
													? [selectedLocation.latitude, selectedLocation.longitude]
													: undefined
											}
											selectedZone={commercialMeterGroupId ?? undefined}
										/>
									</Col>

									<Col xs={24} className={'my-2'}>
										<WaiAnalysisWidgetContainer>
											<WaiAnalysisWidgetTitle className="text-center">
												{t('WantToUploadFileData')}
												<div
													className="d-flex justify-content-center "
													style={{
														color: colors.GRAPH_PURPLE,
														fontWeight: 'bold',
														cursor: 'pointer'
													}}
													onClick={() => {
														modalConfirm({
															content: t(
																'YouAreAboutToUploadFileForThisCommercialMeterGroup'
															),
															onOk: () => {
																navigate(
																	`${AppRoutes.COMMERCIAL_METER_GROUP_CONFIGURATION}?commercialMeterGroupId=${commercialMeterGroupId}&uploadFile=true`
																);
															}
														});
													}}
												>
													<Tooltip title={t('UploadFile')}>
														<p className="ant-upload-drag-icon">
															<AiOutlineInbox size={40} color={colors.WAI_BLUE} />
														</p>
													</Tooltip>
												</div>
											</WaiAnalysisWidgetTitle>
										</WaiAnalysisWidgetContainer>
									</Col>
								</Row>
							</Col>
						</Row>
					) : (
						<Row className="mt-2">
							<Col xs={24}>
								<UploadCommercialMeterGroupFile
									selectedUploadData={selectedUploadData}
									setSelectedUploadData={setSelectedUploadData}
									commercialMeterGroupId={commercialMeterGroupId}
								/>
							</Col>
						</Row>
					)}
				</Spin>
			</div>
		</>
	);
};
