export const colors = {
	GRAPH_PURPLE: '#5d62b5',
	GRAPH_GREEN: '#29c3be',
	GRAPH_BLUE: '#002D5B',
	GRAPH_RED: '#ff6775',
	GRAPH_CYAN: '#aefff8',
	HEALTHY: '#4bb543',
	HEALTHY_WARM: '#249F5D',
	WARNING: '#ffcc00',
	WARNING_WARM: '#d7b357',
	WARNING_COOL: '#fcba03',
	ERROR: '#ff3333',
	ERROR_DARK: '#A63232',
	INACTIVE: '#888',
	WAI_BLUE: '#1890ff',
	LIGHT_GREEN: 'rgb(28, 220, 171)',
	LIGHT_GREEN2: '#87d068',
	LIGHT_GREEN3: '#DCFBEA',
	GREY: '#999',
	LIGHT_GREY: '#F3F3F3',
	LIGHT_GREY2: '#eceff1',
	LIGHT_GREY3: '#e6e6e6',
	LIGHT_GREY4: '#e2e2e2',
	LIGHT_GREY5: '#eeeeee',
	LIGHT_GREY6: '#f8f8f8',
	LIGHT_GREY7: '#808e9b',
	LIGHT_GREY8: '#adbccb',
	LIGHT_GREY9: '#CDCDCD',
	LIGHT_GREY10: '#c9c9c9',
	LIGHT_GREY11: '#F7F7F9',
	DARK_GREY: '#858585',
	DARK_GREY2: '#797676',
	DARK_GREY3: '#575757',
	DARK_GREY4: '#777',
	DARK_GREY5: '#6F727A',
	PRIMARY_WARM: '#02b0ef',
	SUCCESS_WARM: '#7bb596',
	ERROR_WARM: '#d76363',
	PURPLE: '#383874',
	PURPLE2: '#6A0DAD',
	LIGHT_PURPLE: '#5d62b5',
	LIGHT_PURPLE_RGB2: 'rgba(93, 98, 181, 0.2)',
	CYAN: '#02bbfe',
	CYAN2: '#40a9ff',
	CYAN3: '#03a5fc',
	CYAN4: '#108ee9',
	CYAN_WARM: '#0099cc',
	LIGHT_CYAN: '#e7f7fe',
	LIGHT_CYAN2: '#e6f5ff',
	DARK_BLUE: '#001d3b',
	PINK: '#ed4377',
	PINK2: '#fd52b9',
	YELLOW: '#f7cb00',
	YELLOW_LIGHT: '#fffae6',
	PETROLEUM: '#005e9d',
	RED: 'red',
	RED2: '#F32D2D',
	LIGHT_RED: '#FFDBDB',
	ORANGE: 'orange',
	WHITE: '#fff',
	BLACK: '#000',
	DARK_BLUE_RGBA2: 'rgba(16, 36, 94, 0.2)',
	RED_RGBA2: 'rgba(237, 67, 55, 0.2)',
	WHITE_RGBA3: 'rgba(255, 255, 255, 0.3)',
	WHITE_RGBA7: 'rgba(255, 255, 255, 0.7)',
	WHITE_RGBA9: 'rgba(255, 255, 255, 0.9)',
	BLACK_RGBA1: 'rgba(0, 0, 0, 0.1)',
	BLACK_RGBA2: 'rgba(0, 0, 0, 0.2)',
	BLACK_RGBA3: 'rgba(0, 0, 0, 0.3)',
	BLACK_RGBA5: 'rgba(0, 0, 0, 0.5)',
	BLACK2_RGBA3: 'rgba(16, 16, 16, 0.3)'
};
