export enum UserApps {
	MONITORING = 'monitoring',
	ALERTS = 'alerts',
	PRESSURE = 'pressure',
	FLOW_RATE = 'flow_rate',
	WATER_CONSUMPTION = 'water_consumption',
	WATER_PRODUCTION = 'water_production',
	MINIMUM_NIGHTLY_FLOW = 'minimum_nightly_flow',
	NETWORK = 'networks',
	ZONE = 'zones',
	MEASUREMENT_POINT = 'measurement_points',
	DATA_SOURCE = 'data_sources',
	DEVICES = 'devices',
	FOUNDATIONS = 'foundations',
	SUB_SYSTEMS = 'subsystems',
	APPLICATIONS = 'applications',
	RIGHTS = 'rights',
	USERS = 'users',
	USER_PERMISSIONS = 'user_permissions',
	ADVANCED_ANALYSIS = 'advanced_analysis',
	DEVICES_STATUS_DASHBOARD = 'devices_status_dashboard',
	LEAKAGE_ANALYSIS = 'leakage_analysis'
}
