import { Empty, Image } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WaiAnalysisWidgetContainer, WaiAnalysisWidgetTitle } from '../wai-analysis-widget';
import { ImageGalleryThumbnail } from './styles/image-gallery';

export const ImageGallery: React.FC<{ images: string[] } & React.HTMLAttributes<HTMLDivElement>> = ({
	images,
	...restProps
}) => {
	const { t } = useTranslation();

	return (
		<WaiAnalysisWidgetContainer {...restProps}>
			<WaiAnalysisWidgetTitle className="text-center">{t('Gallery')}</WaiAnalysisWidgetTitle>
			<ImageGalleryThumbnail>
				{images && images.length ? (
					<Image.PreviewGroup>
						{images.map((image: any, idx: number) => (
							<div className="mb-2" key={idx}>
								<Image src={image.file} width={100} height={100} style={{ borderRadius: '5px' }} />
							</div>
						))}
					</Image.PreviewGroup>
				) : (
					<Empty description={t('NoData')} />
				)}
			</ImageGalleryThumbnail>
		</WaiAnalysisWidgetContainer>
	);
};
