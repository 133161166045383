import { Card, Col, Row, Statistic } from 'antd';
import dayjs from 'dayjs';
import { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { Disabled, GraphCard } from '../components';
import { colors } from '../constants/colors';
import { useMedia } from '../hooks/use-media.hook';
import { SpanType } from '../types';

export const CumulativeReadingsCardsChart: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		cumulativeReadings: any[];
		firstReadingsDate: any;
		lastReadingsDate: any;
		OnDateSelect: (date?: any) => void;
		title?: string;
	}
> = ({ cumulativeReadings, firstReadingsDate, lastReadingsDate, OnDateSelect, title, ...restProps }) => {
	const { t } = useTranslation();
	const [displayDate, setDisplayDate] = useState<any>();
	const largeScreen = useMedia('xxxxl');

	return (
		<Row gutter={[16, 16]}>
			<Col xs={24}>
				<GraphCard
					height={50}
					title={title ?? t('CumulativeReadings')}
					lastReadingsDates={{
						month: lastReadingsDate
					}}
					setDisplayDate={setDisplayDate}
					displayDate={displayDate}
					OnDateSelect={OnDateSelect}
					spanType={SpanType.MONTH}
					showCustomSelectorDate
					{...restProps}
				>
					{firstReadingsDate && lastReadingsDate ? (
						<div className="d-flex justify-content-around">
							<div className="d-flex justify-content-center text-muted">
								{t('FirstReadingsDate')}:{' '}
								{firstReadingsDate && dayjs(firstReadingsDate).format('MMMM YYYY')}
							</div>
							<div className="d-flex justify-content-center text-muted">
								{t('LastReadingsDate')}:{' '}
								{lastReadingsDate && dayjs(lastReadingsDate).format('MMMM YYYY')}
							</div>
						</div>
					) : null}
					<div
						className="d-flex justify-content-between align-items-center mt-2 mb-2"
						style={{ height: '80%' }}
						dir="ltr"
					>
						<div>
							<AiOutlineLeft
								size={'2rem'}
								style={{ strokeWidth: '30px' }}
								color={colors.GRAPH_PURPLE}
								className={'mb-4'}
								role={'button'}
								onClick={() => {
									OnDateSelect &&
										OnDateSelect({
											start: displayDate
												? dayjs(displayDate).subtract(1, 'M').startOf('M').format('YYYY-MM-DD')
												: dayjs(lastReadingsDate)
														.subtract(1, 'M')
														.startOf('M')
														.format('YYYY-MM-DD')
										});
									setDisplayDate(
										displayDate
											? dayjs(displayDate).subtract(1, 'M').format('YYYY-MM-DD')
											: dayjs(lastReadingsDate).subtract(1, 'M').format('YYYY-MM-DD')
									);
								}}
							/>
						</div>

						<div
							style={{
								overflowY: 'auto',
								maxHeight: '40rem',
								overflowX: 'hidden'
							}}
						>
							<Row gutter={[16, 16]}>
								{cumulativeReadings?.map((type, idx: number) => {
									return (
										<Col
											xs={24}
											sm={12}
											lg={8}
											xl={12}
											xxl={largeScreen ? 6 : 8}
											key={idx}
											className="my-2"
										>
											<Card
												bordered={false}
												style={{ height: '100%' }}
												className="shadow-sm bg-body rounded"
											>
												<div>
													<Statistic
														title={
															<>
																<span
																	style={{
																		color: 'rgba(0, 0, 0, 0.5)',
																		fontSize: '14px'
																	}}
																>
																	{t('ActivityType')}
																</span>
																<span style={{ color: 'black', fontSize: '18px' }}>
																	{' '}
																	{type.activity_type}
																</span>
															</>
														}
														value={`${type.total}${type.unit}`}
														suffix={
															<>
																<span
																	style={{
																		color: 'rgba(0, 0, 0, 0.5)',
																		fontSize: '14px'
																	}}
																>
																	{t('TotalMonth')}
																</span>
															</>
														}
														style={{ textAlign: 'center' }}
													/>
												</div>
												<div>
													<Statistic
														value={`${type.number_of_customers}`}
														suffix={
															<>
																<span
																	style={{
																		color: 'rgba(0, 0, 0, 0.5)',
																		fontSize: '14px'
																	}}
																>
																	{t('NumberOfCustomers')}
																</span>
															</>
														}
														style={{ textAlign: 'center' }}
													/>
													<Statistic
														value={`${type.number_of_units}`}
														suffix={
															<>
																<span
																	style={{
																		color: 'rgba(0, 0, 0, 0.5)',
																		fontSize: '14px'
																	}}
																>
																	{t('NumberOfUnits')}
																</span>
															</>
														}
														style={{ textAlign: 'center' }}
													/>
												</div>
											</Card>
										</Col>
									);
								})}
							</Row>
						</div>
						<div>
							{(displayDate ? dayjs(displayDate).endOf('M') : dayjs(lastReadingsDate).endOf('M')) >=
							dayjs().endOf('M') ? (
								<Disabled tooltipTitle={t('CannotChooseFutureDate', { ns: 'validation' })}>
									<AiOutlineRight
										size={'2rem'}
										style={{ strokeWidth: '30px' }}
										color={colors.GRAPH_PURPLE}
										className={'mb-4'}
										role={'button'}
										onClick={() => {
											OnDateSelect &&
												OnDateSelect({
													start: displayDate
														? dayjs(displayDate)
																.add(1, 'M')
																.startOf('M')
																.format('YYYY-MM-DD')
														: dayjs(lastReadingsDate)
																.add(1, 'M')
																.startOf('M')
																.format('YYYY-MM-DD')
												});
											setDisplayDate(
												displayDate
													? dayjs(displayDate).add(1, 'M').format('YYYY-MM-DD')
													: dayjs(lastReadingsDate).add(1, 'M').format('YYYY-MM-DD')
											);
										}}
									/>
								</Disabled>
							) : (
								<AiOutlineRight
									size={'2rem'}
									style={{ strokeWidth: '30px' }}
									color={colors.GRAPH_PURPLE}
									className={'mb-4'}
									role={'button'}
									onClick={() => {
										OnDateSelect &&
											OnDateSelect({
												start: displayDate
													? dayjs(displayDate).add(1, 'M').startOf('M').format('YYYY-MM-DD')
													: dayjs(lastReadingsDate)
															.add(1, 'M')
															.startOf('M')
															.format('YYYY-MM-DD')
											});
										setDisplayDate(
											displayDate
												? dayjs(displayDate).add(1, 'M').format('YYYY-MM-DD')
												: dayjs(lastReadingsDate).add(1, 'M').format('YYYY-MM-DD')
										);
									}}
								/>
							)}
						</div>
					</div>
				</GraphCard>
			</Col>
		</Row>
	);
};
