import { createContext } from 'react';

interface IAuthContext {
	authenticated: boolean;
	setAuthenticated: (state: boolean, token: string) => void;
	user: any;
	addUserRights: (rights: any) => void;
	monitoringPermessions: any;
	alertPermessions: any;
	analysisPermessions: any;
	configurationPermessions: any;
	controlPermessions: any;
	administrationPermessions: any;
}
export const AuthenticationContext = createContext<IAuthContext>({
	authenticated: false,
	user: null,
	setAuthenticated: (state: boolean, token?: string) => {},
	addUserRights: (rights: any) => {},
	monitoringPermessions: null,
	alertPermessions: null,
	analysisPermessions: null,
	configurationPermessions: null,
	controlPermessions: null,
	administrationPermessions: null
});
