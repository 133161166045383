import { useQuery } from '@tanstack/react-query';
import { Form, Select, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import alertsService from '../services/alerts.service';
import { AlertPriority, AlertStatus } from '../types/alerts';
import { notifySuccess } from '../utils/notification-messages';
import { upperSnakeToCapitalized } from '../utils/upper-snake-to-capitalized';

export const AlertForm: React.FC<any> = ({
	form,
	AlertId,
	setVisibleActionsForm,
	refetchAlerts,
	refetchAlertStats,
	submitting,
	setSubmitting
}) => {
	const { t } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	// get selected alert
	const {
		data: selectedAlert,
		isLoading: loadingAlert,
		refetch: refetchSelectedAlert
	} = useQuery<any>({
		queryKey: ['alert', AlertId],
		queryFn: () => alertsService.getAlertNew(AlertId),
		enabled: !!AlertId
	});

	// load form data for selected alert
	useEffect(() => {
		if (selectedAlert) {
			form.setFieldsValue({
				...selectedAlert
			});
		}
	}, [selectedAlert, AlertId]);

	const onFinish = async (values: any) => {
		setSubmitting(true);
		try {
			const resp = await alertsService.editAlertNew(AlertId, values);
			if (!resp) throw new Error(t('couldntEditActions', { ns: 'validation' }));
			notifySuccess(t('editedSuccessfully'));
			refetchAlerts();
			refetchAlertStats();
			refetchSelectedAlert();
			setVisibleActionsForm(false);
		} catch (e: any) {
			errorHandler(e);
		}
		setSubmitting(false);
	};

	return (
		<>
			<Spin spinning={loadingAlert || submitting}>
				<Form form={form} layout="vertical" onFinish={onFinish}>
					<Form.Item name="priority" label={t('Priority')}>
						<Select
							options={Object.values(AlertPriority).map(priority => {
								return {
									value: priority,
									label: upperSnakeToCapitalized(t(priority.toLowerCase()))
								};
							})}
						/>
					</Form.Item>
					<Form.Item name="status" label={t('Status', { ns: 'common' })}>
						<Select
							options={Object.values(AlertStatus).map(status => {
								return {
									value: status,
									label: upperSnakeToCapitalized(t(status.toLowerCase()))
								};
							})}
						/>
					</Form.Item>
				</Form>
			</Spin>
		</>
	);
};
