import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Col, Form, FormProps, Input, Row, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../hooks/use-query';
import userManagementService from '../../services/user-management.service';
import { upperSnakeToCapitalized } from '../../utils';
import { errorHandler } from '../../utils/error-handler';
import { notifySuccess } from '../../utils/notification-messages';
import { SubmitCanelButtons } from '../submit-cancel-buttons';

export const ApplicationForm: React.FC<FormProps> = () => {
	const { t, i18n } = useTranslation();

	const applicationId = useQueryParam('applicationId');
	const subsystemId = useQueryParam('subsystemId');

	const [submitting, setSubmitting] = useState<boolean>(false);

	const [form] = Form.useForm();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	// load application data
	const {
		data: application,
		isLoading: loadingApplication,
		isFetching: fetechingApplication,
		refetch: refetcApplication
	} = useQuery({
		queryKey: ['application', applicationId],
		queryFn: () => applicationId && userManagementService.getApplication(applicationId),
		enabled: !!applicationId
	});

	// load subsystem data
	const {
		data: subsystem,
		isLoading: loadingSubsytem,
		isFetching: fetechingSubsystem
	} = useQuery<any>({
		queryKey: ['subsystem', subsystemId],
		queryFn: () => subsystemId && userManagementService.getSubsystem(subsystemId),
		enabled: !!subsystemId
	});

	// get all subsystems
	const { data: subsystems, isLoading: loadingSubsytems } = useQuery({
		queryKey: ['subsystems'],
		queryFn: () => userManagementService.getSubsystems()
	});

	// fill form with data
	useEffect(() => {
		if (application) {
			form.setFieldsValue({
				...application
			});
		}
		if (subsystem) {
			form.setFieldsValue({
				sub_system: subsystem.id
			});
		}
	}, [application, subsystem]);

	const onFinish = async (values: any) => {
		setSubmitting(true);
		Object.keys(values).forEach(key => {
			if (values[key] === '' || values[key] === undefined || values[key] === null) {
				delete values[key];
			}
		});

		if (applicationId) {
			try {
				const resp = await userManagementService.editApplication(applicationId, values);
				if (!resp) throw new Error(t('couldntEditSubsystem', { ns: 'validation' }));
				notifySuccess(t('editedSuccessfully'));
				queryClient.refetchQueries(['applications']);
				refetcApplication();
				navigate(`${AppRoutes.ADMINISTRATION}?tabSelected=superAdmin&&subTabSelected=applications`);
			} catch (e: any) {
				if (e?.name?.length > 0) {
					e.message = e.name[0];
				}
				errorHandler(e);
			}
		} else {
			try {
				const resp = await userManagementService.createApplication(values);
				if (!resp) throw new Error(t('couldntAddApplication', { ns: 'validation' }));
				notifySuccess(t('addedSuccessfully'));
				queryClient.refetchQueries(['applications']);
				navigate(`${AppRoutes.ADMINISTRATION}?tabSelected=superAdmin&&subTabSelected=applications`);
			} catch (e: any) {
				if (e?.name?.length > 0) {
					e.message = e.name[0];
				}
				errorHandler(e);
			}
		}
		setSubmitting(false);
	};
	const handleCancel = () => {
		navigate(`${AppRoutes.ADMINISTRATION}?tabSelected=superAdmin&&subTabSelected=applications`);
	};

	return (
		<div
			style={{
				paddingLeft: i18n.language === 'en' ? '1rem' : '',
				paddingRight: i18n.language === 'en' ? '' : '1rem',
				paddingTop: '2rem'
			}}
		>
			<Row gutter={24} className="justify-content-center">
				<Col xs={24} md={14} lg={10}>
					<Row>
						<h3 className="mb-3">{t('Application')}</h3>
					</Row>
					<Row>
						<Col xs={24}>
							<Spin spinning={loadingSubsytems}>
								<Form form={form} layout="vertical" onFinish={onFinish}>
									<Spin
										spinning={
											loadingSubsytems ||
											(loadingSubsytem && fetechingSubsystem) ||
											(loadingApplication && fetechingApplication) ||
											submitting
										}
									>
										<Form.Item
											name="sub_system"
											label={t('Subsystem')}
											rules={[
												{ required: true, message: t('SelectSubsystem', { ns: 'validation' }) }
											]}
										>
											<Select
												placeholder={t('Subsystem')}
												options={
													(subsystems &&
														subsystems.map((subsystem: any) => {
															return {
																value: subsystem.id,
																label: upperSnakeToCapitalized(subsystem.name)
															};
														})) ||
													[]
												}
												disabled={!!subsystemId}
											/>
										</Form.Item>
									</Spin>

									<Form.Item
										name="name"
										label={t('Name')}
										rules={[
											{ required: true, message: t('addSubsystemName', { ns: 'validation' }) }
										]}
									>
										<Input type="text" placeholder={t('subsystemName')} />
									</Form.Item>
									<Form.Item
										name="displayName"
										label={t('DisplayName')}
										rules={[
											{
												required: true,
												message: t('addSubsystemDisplayName', { ns: 'validation' })
											}
										]}
									>
										<Input type="text" placeholder={t('subsystemDisplayName')} />
									</Form.Item>
									<Form.Item className="d-flex justify-content-end">
										<SubmitCanelButtons handleCancel={handleCancel} handleSubmit={form.submit} />
									</Form.Item>
								</Form>
							</Spin>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};
