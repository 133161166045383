import styled from 'styled-components';

export const MeasurementPointInfoContainer = styled.div`
	padding: 0;
	margin: 0;
	list-style: none;
	overflow-y: auto;
	max-height: 75vh;
`;

export const MeasurementPointInfoLabel = styled.span`
	font-weight: 600;
	margin-inline: 5px;
`;

export const MeasurementPointInfoText = styled.p`
	font-size: 0.9rem;
	margin: 0px !important;
`;
