import { useTranslation } from 'react-i18next';

interface IComponentGuardProps {
	allowed: boolean | (() => boolean);
	children: any;
	fallback?: any;
	notAllowedTooltip?: string;
}

export const ComponentGuard: React.FC<IComponentGuardProps> = ({
	allowed,
	children,
	fallback: Fallback = null,
	notAllowedTooltip
}) => {
	const { t } = useTranslation();
	return allowed ? (
		children
	) : Fallback ? (
		<Fallback tooltipTitle={t('NotAllowed', { ns: 'common' })}>{children}</Fallback>
	) : null;
};
