import { Col, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import { DataSources, GraphSpan, PressureUnit, SpanType, UserApps, UserRights } from '../types';
import { clearArrayUndefined } from '../utils/clean-array-undefined';
import { dateToQuery } from '../utils/date-to-query';
import { mostRecentDate } from '../utils/most-recent-date';
import { WaiTimeSeriesChart } from './wai-time-series';
import { mean } from 'stats-lite';
import { WaiRangeChart } from './wai-range-area';
import monitoringService from '../services/monitoring.service';
import { AppRoutes } from '../constants/routes';
import { hasRight } from '../utils';
import { AuthenticationContext } from '../contexts';
import chartsService from '../services/charts.service';
import configService from '../services/config.service';

export const ZoneAnalysisPressureTab: React.FC<any> = ({ zoneName }) => {
	const { t, i18n } = useTranslation();

	const [chartZoneDate, setChartZoneDate] = useState<any>();
	const [chartTimeSpan, setChartTimeSpan] = useState<{ start: string; end: string }>({
		start: dateToQuery(dayjs().subtract(7, 'day')),
		end: dateToQuery(dayjs())
	});
	const [averageZonePressureStatTimeSpan, setAverageZonePressureStatTimeSpan] = useState<{
		start: string;
		end: string;
	}>({
		start: dateToQuery(dayjs().subtract(70, 'day')),
		end: dateToQuery(dayjs())
	});
	const [pressureDataSources, setPressureDataSources] = useState<any>([]);

	const navigationContext = useContext(NavigationContext);
	const { zones, selectedZone, measurementPoints, applicationIdMap } = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user, configurationPermessions } = authContext;

	// load selected average zone pressure analysis config
	const {
		data: averageZonePressureConfig,
		isLoading: loadingAverageZonePressureConfig,
		isFetching: fetchingAverageZonePressureConfig
	} = useQuery({
		queryKey: ['avg-zone-pressure-config', selectedZone],
		queryFn: () => selectedZone && configService.getAvgZonePressureConfig(selectedZone),
		enabled: !!selectedZone
	});

	// Loading all data sources for selected zone
	const zoneDataSources = useQueries<any>({
		queries: (zones?.find((zone: any) => zone.id === selectedZone)?.measurement_points || []).map((point: any) => {
			return {
				queryKey: ['data-sources', point],
				queryFn: () =>
					monitoringService.getDataSources({
						filters: { measurement_point: point }
					}),
				enabled: !!point
			};
		})
	});

	// get pressure data-sources
	useEffect(() => {
		if (zoneDataSources && zoneDataSources.every(zone => zone.isSuccess)) {
			const dataSourcesPressure = clearArrayUndefined(
				zoneDataSources.flatMap(point => {
					const pressureDataSource =
						point.data &&
						point.data.length > 0 &&
						point.data.filter(source => source.type === DataSources.PRESSURE);
					return pressureDataSource;
				})
			);
			setPressureDataSources(dataSourcesPressure);
		}
	}, [zoneDataSources]);

	// Last pressures readings
	const pressureLastReadings = useQueries<any>({
		queries: pressureDataSources?.map((dataSource: any) => {
			return {
				queryKey: ['data-source-last-log-date', dataSource.id, selectedZone],
				queryFn: () => chartsService.getDataSourceLastLogDate(dataSource.id),
				enabled: !!dataSource.id && !!selectedZone
			};
		})
	});

	// Zone pressures analysis relative to chartTimeSpan
	const pressures = useQueries<any>({
		queries: pressureDataSources?.map((dataSource: any) => {
			if (chartTimeSpan?.start.length)
				return {
					queryKey: ['data-source-logs', dataSource.id, chartTimeSpan, selectedZone],
					queryFn: () =>
						chartsService.getDSLogs(dataSource.id, {
							start: chartTimeSpan?.start,
							end: chartTimeSpan?.end,
							aggregate:
								chartTimeSpan?.start && dayjs(chartTimeSpan?.end).diff(chartTimeSpan?.start, 'd') === 0
									? undefined
									: 1
						}),
					enabled: !!dataSource.id && !!selectedZone
				};
			else
				return {
					queryKey: ['data-source-logs', dataSource.id, selectedZone],
					queryFn: () =>
						chartsService.getDSLogs(dataSource.id, {
							aggregate: 1
						}),
					enabled: !!dataSource.id && !!selectedZone
				};
		})
	});

	// Last average zone pressure reading
	const { data: averagePressureLastReading } = useQuery<any>({
		queryKey: ['averageZonePressureLastReading', selectedZone],
		queryFn: () => chartsService.getAverageZonePressure(selectedZone),
		enabled: !!selectedZone
	});
	// Average zone pressure readings relative to chartZoneDate
	const {
		data: averageZonePressureChart,
		isLoading: loadingAveragePressure,
		isFetching: fetchingAveragePressure
	} = useQuery<any>({
		queryKey: ['averageZonePressure', chartZoneDate, selectedZone],
		queryFn: () =>
			chartsService.getAverageZonePressure(selectedZone, {
				start: chartZoneDate ? dayjs(chartZoneDate).format('YYYY-MM-DD') : undefined
			}),
		enabled: !!selectedZone
	});

	// Average zone pressure stats readings relative to chartZoneDate
	const {
		data: averageZonePressureChartStats,
		isLoading: loadingAveragePressureStats,
		isFetching: fetchingAveragePressureStats
	} = useQuery<any>({
		queryKey: ['averageZonePressureStats', averageZonePressureStatTimeSpan, selectedZone],
		queryFn: () =>
			chartsService.getAverageZonePressureStats(selectedZone, {
				start: dayjs(averageZonePressureStatTimeSpan.start).format('YYYY-MM-DD'),
				end: dayjs(averageZonePressureStatTimeSpan.end).format('YYYY-MM-DD')
			}),
		enabled: !!selectedZone && !!averageZonePressureStatTimeSpan
	});

	return (
		<>
			<Row>
				<Col xs={24}>
					<Spin
						spinning={
							!zoneDataSources.every(zone => zone.isSuccess) ||
							!pressures.every(pressure => pressure.isSuccess) ||
							!zones
						}
					>
						<WaiTimeSeriesChart
							IDs={clearArrayUndefined([
								...pressureDataSources.map((source: any) => {
									const measurementPoint =
										measurementPoints &&
										measurementPoints.find(point => point.id === source.measurement_point);
									return {
										id: source.id,
										name:
											measurementPoint &&
											`${
												i18n.language === 'en'
													? measurementPoint.name_en || measurementPoint.id
													: measurementPoint.name_ar ||
													  measurementPoint.name_en ||
													  measurementPoint.id
											} ${source.stream_direction.toLowerCase()} ${source.type.toLowerCase()}`
									};
								})
							])}
							flags={{ aggregate: true }}
							title={t('ZonePressureAnalysis')}
							height={45}
							graphHeight={550}
							dataObjects={
								pressures &&
								pressures.length &&
								pressureDataSources.map((dataSource: any, idx: number) => {
									const measurementPoint =
										measurementPoints &&
										measurementPoints.find(point => point.id === dataSource.measurement_point);
									const dataObj: any = {
										id: dataSource.id,
										name:
											measurementPoint &&
											`${
												i18n.language === 'en'
													? measurementPoint.name_en || measurementPoint.id
													: measurementPoint.name_ar ||
													  measurementPoint.name_en ||
													  measurementPoint.id
											} ${dataSource.stream_direction.toLowerCase()} ${dataSource.type.toLowerCase()}`,
										data: pressures[idx].data ?? [],
										allData: [],
										unit: PressureUnit.BAR
									};
									if (idx === 0)
										dataObj['yAxis'] = {
											position: 'left',
											legend: t('PressureInBAR')
										};
									return dataObj;
								})
							}
							lastReadingDate={mostRecentDate(pressureLastReadings?.map((item: any) => item.data?.time))}
							showSpanSelector
							showCustomSpanSelector
							onSpanSelect={(span: any) => setChartTimeSpan(span)}
							showTabular
							showTimeStep
							sourceName={zoneName}
						/>
					</Spin>
				</Col>
				<Col xs={24}>
					<Spin
						spinning={
							(loadingAveragePressure && fetchingAveragePressure) ||
							(loadingAverageZonePressureConfig && fetchingAverageZonePressureConfig)
						}
					>
						<WaiTimeSeriesChart
							IDs={[
								{
									id: selectedZone,
									name: t('AverageZonePressure')
								}
							]}
							changeDate={true}
							title={t('AverageZonePressure')}
							flags={{ datePicker: true }}
							height={45}
							graphHeight={550}
							showBrush={false}
							showTabular
							statistics={clearArrayUndefined(
								averageZonePressureChart &&
									Object.keys(averageZonePressureChart)?.map(
										(key: any) => averageZonePressureChart[key]
									)[0]
									? [
											{
												name: t('Minimum'),
												value: Math.min(
													...Object.keys(averageZonePressureChart)
														.map((key: any) => averageZonePressureChart[key])[0]
														?.map(log => +log.value)
												),
												unit: PressureUnit.BAR
											},
											{
												name: t('Maximum'),
												value: Math.max(
													...Object.keys(averageZonePressureChart)
														.map((key: any) => averageZonePressureChart[key])[0]
														?.map(log => +log.value)
												),
												unit: PressureUnit.BAR
											},
											{
												name: t('Average'),
												value: mean(
													Object.keys(averageZonePressureChart)
														.map((key: any) => averageZonePressureChart[key])[0]
														?.map(log => +log.value)
												),
												unit: PressureUnit.BAR
											}
									  ]
									: []
							)}
							OnDateSelect={(date: any) => setChartZoneDate(date)}
							lastReadingDate={averagePressureLastReading && Object.keys(averagePressureLastReading)?.[0]}
							dataObjects={clearArrayUndefined([
								{
									id: selectedZone,
									name: t('value'),
									data:
										(averageZonePressureChart && Object.values(averageZonePressureChart)[0]) || [],
									allData: [],
									unit: PressureUnit.BAR,
									yAxis: {
										position: 'left',
										legend: t('AverageZonePressure')
									}
								}
							])}
							showCustomSelectorDate={true}
							dateText={
								averagePressureLastReading &&
								averagePressureLastReading?.length > 0 &&
								Object.values<any>(averagePressureLastReading)?.[0].slice(-1)?.[0]?.time &&
								(chartZoneDate
									? Object.values<any>(averagePressureLastReading)?.[0].slice(-1)?.[0]?.time ===
									  chartZoneDate
										? `${t('LastReadingAt')} ${dayjs(
												Object.values<any>(averagePressureLastReading)[0].slice(-1)[0]?.time
										  ).format('Do MMM YYYY')}`
										: `${t('ReadingAt')} ${dayjs(chartZoneDate).format('Do MMM YYYY')}`
									: `${t('LastReadingAt')} ${dayjs(
											Object.values<any>(averagePressureLastReading)[0][
												Object.values<any>(averagePressureLastReading)[0]?.length - 1
											]?.time
									  ).format('Do MMM YYYY')}`)
							}
							showSpanSelector
							showTimeStep
							customTimeUnits={[GraphSpan.DAY]}
							spanType={SpanType.DAY}
							sourceName={zoneName}
							deactivated={!(averageZonePressureConfig && averageZonePressureConfig.is_active)}
							deactivatedMsg={t('AvgZonePressureAnalysisDeactivated')}
							profile={averageZonePressureConfig?.profiles.find(profile => profile.is_default)}
							linkToActivate={
								AppRoutes.ZONE_CONFIGURATION +
								`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=average_zone_pressure`
							}
							accessToActivateChart={hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.ZONE),
								UserRights.EDIT
							)}
						/>
					</Spin>
				</Col>

				<Col xs={24}>
					<Spin
						spinning={
							(loadingAveragePressureStats && fetchingAveragePressureStats) ||
							(loadingAverageZonePressureConfig && fetchingAverageZonePressureConfig)
						}
					>
						<WaiRangeChart
							IDs={[
								{
									id: selectedZone,
									name: t('AverageZonePressureStats')
								}
							]}
							title={t('AverageZonePressureStats')}
							height={45}
							graphHeight={500}
							showTabular
							onSpanSelect={(span: any) => setAverageZonePressureStatTimeSpan(span)}
							lastReadingDate={averagePressureLastReading && Object.keys(averagePressureLastReading)?.[0]}
							dataObjects={clearArrayUndefined([
								{
									id: selectedZone,
									name: t('value'),
									data: averageZonePressureChartStats ?? [],
									allData: [],
									unit: PressureUnit.BAR,
									linePropName: t('average'),
									rangePropName: { min: t('min'), max: t('max') }
								}
							])}
							showSpanSelector
							showCustomSpanSelector
							showTimeStep
							rangeDate={averageZonePressureStatTimeSpan}
							sourceName={zoneName}
							deactivated={!(averageZonePressureConfig && averageZonePressureConfig.is_active)}
							deactivatedMsg={t('AvgZonePressureAnalysisDeactivated')}
							profile={averageZonePressureConfig?.profiles.find(profile => profile.is_default)}
							linkToActivate={
								AppRoutes.ZONE_CONFIGURATION +
								`?zoneId=${selectedZone}&panelSelected=zone_analysis_config&analysisPanelSelected=average_zone_pressure`
							}
							accessToActivateChart={hasRight(
								!!user?.user_data?.is_superAdmin,
								configurationPermessions,
								applicationIdMap.get(UserApps.ZONE),
								UserRights.EDIT
							)}
						/>
					</Spin>
				</Col>
			</Row>
		</>
	);
};
