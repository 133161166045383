import { Button } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const WaiButton = styled(Button)<{ label: any }>`
	background-color: ${({ label }) => label.buttonColor};
	border: 0;
	color: ${colors.WHITE};
	font-weight: 700;
	height: auto;
	padding: 10px 30px;
	border-radius: 20px;
`;
