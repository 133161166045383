import styled from 'styled-components';
import { Calendar } from 'antd';
import { colors } from '../../../constants/colors';

export const StyledCalendar = styled(Calendar)`
	background-color: ${colors.LIGHT_CYAN};
	border-radius: 15px;
	&.ant-picker-calendar .ant-picker-panel {
		border-radius: 15px;
		background-color: ${colors.LIGHT_CYAN};
	}
`;
