import { Button, DatePicker, Form, Modal, Radio, Table, TableProps } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { TbTableExport } from 'react-icons/tb';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { useTranslation } from 'react-i18next';
import chartsService from '../../services/charts.service';
import { SpanType, ZoneAnalysisFeatures } from '../../types';
import { DataSources, FlowRateType } from '../../types/data-sources';
import { downloadFilePDF, upperSnakeToCapitalized } from '../../utils';
import { dateToQuery } from '../../utils/date-to-query';
import { downloadFile } from '../../utils/download-file';
import { errorHandler } from '../../utils/error-handler';
import { jsonToCSV } from '../../utils/jsonToCSV';
import { mergeLogs } from '../../utils/merge-logs';

const { RangePicker } = DatePicker;

const Readings = styled(Table)`
	.ant-table-content {
		height: 400px;
		overflow-y: auto;
	}
	:where(.css-dev-only-do-not-override-1me4733).ant-table-wrapper table {
		width: unset !important;
	}
`;

export const GraphDataTable: React.FC<
	TableProps<any> & {
		dataObjects: any;
		unit?: string;
		downloadTitle?: string;
		chartTableDataSource?: { id: string[]; valve: boolean; dataType: DataSources };
		onCancel: () => void;
		IDs: any;
		flags?: any;
		initialTimeRange: any;
		initialDate: any;
		sourceName?: string;
		differentKeys?: boolean;
	}
> = ({
	unit,
	dataObjects,
	downloadTitle,
	chartTableDataSource,
	onCancel,
	IDs,
	flags,
	initialTimeRange,
	initialDate,
	sourceName,
	differentKeys
}) => {
	const { t, i18n } = useTranslation();
	const [tableData, setTableData] = useState<any>();
	const [tableColumns, setTableColumns] = useState<any>();
	const [visibleModal, setVisibleModal] = useState<any>(undefined);
	const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
	const [noData, setNoData] = useState<boolean>(false);
	const [form] = Form.useForm();
	// const [dataDownload, setDataDownload] = useState<any>();
	// const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

	useEffect(() => {
		if (dataObjects && dataObjects.length) {
			setTableData(mergeLogs(dataObjects));
		}
	}, [dataObjects, dataObjects?.length]);

	useEffect(() => {
		if (tableData && tableData.length) {
			const tableKeys = Object.keys(tableData[0]);
			setTableColumns(
				tableKeys.map((key: string) => {
					return {
						title: key == 'time' ? upperSnakeToCapitalized(t('time')) : upperSnakeToCapitalized(key),
						dataIndex: key,
						key,
						width: '100',
						align: 'center',
						render: (text: any) =>
							key === 'time'
								? dayjs(text).format('dddd, MMMM D, YYYY h:mm A')
								: text
								? parseFloat(text).toFixed(3)
								: '-'
					};
				})
			);
		}
	}, [tableData, tableData?.length]);

	const showModal = () => {
		onCancel && onCancel();
		setVisibleModal(true);
		setLoadingDownload(false);
		setNoData(false);
	};

	const handleSubmit = async (values: any) => {
		try {
			setLoadingDownload(true);
			let dataObjects: any = [],
				dataSource: any,
				barChartData: any;

			for (let i = 0; i < IDs.length; i++) {
				if (IDs[i].name === FlowRateType.MNF) {
					dataSource = await chartsService.getMeasurementPointMinNightlyFlow(IDs[i].id, {
						start: dayjs(values.timeRange[0]).format('YYYY-MM-DD'),
						end: dayjs(values.timeRange[1]).format('YYYY-MM-DD'),
						span_type: SpanType.DAY
					});
					dataSource = dataSource.data;
				} else if (IDs[i].name === FlowRateType.DWC) {
					dataSource = await chartsService.getMeasurementPointDailyConsumption(IDs[i].id, {
						start: dayjs(values.timeRange[0]).format('YYYY-MM-DD'),
						end: dayjs(values.timeRange[1]).format('YYYY-MM-DD'),
						span_type: SpanType.DAY
					});
					dataSource = dataSource.data;
				} else if (IDs[i].name === FlowRateType.DWP) {
					dataSource = await chartsService.getSiteDailyProduction(IDs[i].id, {
						start: dayjs(values.timeRange[0]).format('YYYY-MM-DD'),
						end: dayjs(values.timeRange[1]).format('YYYY-MM-DD'),
						span_type: SpanType.DAY
					});
					dataSource = dataSource.data;
				} else if (IDs[i].name === FlowRateType.ZMNF) {
					dataSource = await chartsService.getZoneMinNightlyFlow(IDs[i].id, {
						start: dayjs(values.timeRange[0]).format('YYYY-MM-DD'),
						end: dayjs(values.timeRange[1]).format('YYYY-MM-DD'),
						span_type: SpanType.DAY
					});
					dataSource = dataSource.data;
				} else if (IDs[i].name === FlowRateType.ZDWC) {
					dataSource = await chartsService.getZoneDailyConsumption(IDs[i].id, {
						start: dayjs(values.timeRange[0]).format('YYYY-MM-DD'),
						end: dayjs(values.timeRange[1]).format('YYYY-MM-DD'),
						span_type: SpanType.DAY
					});
					dataSource = dataSource.data;
				} else if (IDs[i].name === FlowRateType.ZDWP) {
					dataSource = await chartsService.getZoneDailyProduction(IDs[i].id, {
						start: dayjs(values.timeRange[0]).format('YYYY-MM-DD'),
						end: dayjs(values.timeRange[1]).format('YYYY-MM-DD'),
						span_type: SpanType.DAY
					});
					dataSource = dataSource.data;
				} else if (IDs[i].name === t('AverageZonePressure')) {
					dataSource = await chartsService.getAverageZonePressure(IDs[i].id, {
						start: dayjs(values.date).format('YYYY-MM-DD')
					});
					dataSource = Object.values(dataSource)[0] || [];
				} else if (IDs[i].name === t('AverageZonePressureStats')) {
					dataSource = await chartsService.getAverageZonePressureStats(IDs[i].id, {
						start: dayjs(values.timeRange[0]).format('YYYY-MM-DD'),
						end: dayjs(values.timeRange[1]).format('YYYY-MM-DD')
					});
				} else if (IDs[i].name === ZoneAnalysisFeatures.leakageMNF) {
					dataSource = await chartsService.getZoneLeakageMNF(IDs[i].id, {
						start: dayjs(values.timeRange[0]).format('YYYY-MM-DD'),
						end: dayjs(values.timeRange[1]).format('YYYY-MM-DD')
					});
					barChartData = [
						{
							name: t('NormalNightlyUse'),
							data:
								dataSource.map(ele => {
									return {
										time: ele.date,
										value: ele.normal_night_use
									};
								}) ?? []
						},
						{
							name: t('BackgroundLeakage'),
							data:
								dataSource.map(ele => {
									return {
										time: ele.date,
										value: ele.background_leakage
									};
								}) ?? []
						},
						{
							name: t('UnaccountedForLeakage'),
							data:
								dataSource.map(ele => {
									return {
										time: ele.date,
										value: ele.unaccounted_for_leakage
									};
								}) ?? []
						}
					];
					dataSource = dataSource.map(ele => {
						return {
							time: ele.date,
							value: ele.minimum_nightly_flow
						};
					});
				} else if (IDs[i].name === ZoneAnalysisFeatures.UFL) {
					dataSource = await chartsService.getZoneLeakageUFL(IDs[i].id, {
						start: dayjs(values.timeRange[0]).format('YYYY-MM-DD'),
						end: dayjs(values.timeRange[1]).format('YYYY-MM-DD')
					});
					dataSource = dataSource.map(ele => {
						return {
							time: ele.date,
							value: ele.unaccounted_for_leakage
						};
					});
				} else {
					dataSource = await chartsService.getDSLogs(IDs[i].id, {
						start: dateToQuery(values.timeRange[0]),
						end: dateToQuery(values.timeRange[1]),
						aggregate: values.aggregate ? true : undefined
					});
				}
				dataObjects.push({ ...IDs[i], data: dataSource });
			}
			if (barChartData?.length > 0) {
				dataObjects.push(...barChartData);
			}

			if (values.exportFile) {
				if (differentKeys) {
					const expandKeys: any = [];
					for (const key in dataObjects[0].data[0]) {
						if (key !== 'time') {
							const newData = dataObjects[0].data.map(item => ({
								time: item.time,
								value: item[key]
							}));

							expandKeys.push({
								name: key,
								data: newData
							});
						}
					}
					dataObjects = expandKeys;
				}
				let data = mergeLogs(dataObjects);
				if (data) {
					downloadFilePDF({
						data: data,
						fileName: sourceName ? `${downloadTitle}_${sourceName}` : downloadTitle || 'Analysis',
						lang: i18n.language,
						t: t
					});
					setVisibleModal(undefined);
				} else {
					setNoData(true);
				}
			} else {
				let data = jsonToCSV(mergeLogs(dataObjects));
				if (data) {
					downloadFile({
						data: data,
						fileName: sourceName ? `${downloadTitle}_${sourceName}` : downloadTitle || 'Analysis',
						fileType: 'text/csv'
					});
					setVisibleModal(undefined);
				} else {
					setNoData(true);
				}
			}
			setLoadingDownload(false);
		} catch (e: any) {
			errorHandler(e);
		}
	};
	useEffect(() => {
		if (initialDate) {
			form.setFieldsValue({
				date: dayjs(dayjs(initialDate).format('YYYY-MM-DD'))
			});
		}
		if (initialTimeRange) {
			form.setFieldsValue({
				timeRange: [
					dayjs(dayjs(initialTimeRange?.start).format('YYYY-MM-DD')),
					dayjs(dayjs(initialTimeRange?.end).add(1, 'day').format('YYYY-MM-DD'))
				]
			});
		}
	}, [initialDate, initialTimeRange]);

	return (
		<div className="d-flex flex-column">
			<Modal
				open={visibleModal}
				title={t('Export')}
				okText={t('Download')}
				onCancel={() => setVisibleModal(undefined)}
				onOk={form.submit}
				confirmLoading={loadingDownload}
				forceRender
			>
				<Form layout="vertical" form={form} onFinish={handleSubmit}>
					{flags && flags?.datePicker ? (
						<Form.Item
							label={t('SetectDate')}
							name="date"
							rules={[{ required: true, message: t('AddDate', { ns: 'validation' }) }]}
							initialValue={initialDate ? dayjs(dayjs(initialDate).format('YYYY-MM-DD')) : undefined}
						>
							<DatePicker onChange={() => setNoData(false)} />
						</Form.Item>
					) : (
						<Form.Item
							label={t('SelectTimeRange')}
							name="timeRange"
							rules={[{ required: true, message: t('AddTimeRange', { ns: 'validation' }) }]}
							initialValue={
								initialTimeRange
									? [
											dayjs(dayjs(initialTimeRange?.start).format('YYYY-MM-DD')),
											dayjs(dayjs(initialTimeRange?.end).add(1, 'day').format('YYYY-MM-DD'))
									  ]
									: undefined
							}
						>
							<RangePicker onChange={() => setNoData(false)} />
						</Form.Item>
					)}
					{flags && flags?.aggregate ? (
						<Form.Item label={t('aggregateOrNot')} initialValue={true} name="aggregate">
							<Radio.Group>
								<Radio value={true}>{t('aggregate')}</Radio>
								<Radio value={false}>{t('notAggregate')}</Radio>
							</Radio.Group>
						</Form.Item>
					) : null}
					<Form.Item label={t('pdfOrCsv')} initialValue={true} name="exportFile">
						<Radio.Group>
							<Radio value={true}>{t('PDF')}</Radio>
							<Radio value={false}>{t('CSV')}</Radio>
						</Radio.Group>
					</Form.Item>
					{noData ? <p className="text-danger"> {t('noDataOnThisDate')}</p> : null}
				</Form>
			</Modal>
			{tableData?.length && tableColumns?.length ? (
				<>
					<div className="d-flex justify-content-between">
						<Button
							type="link"
							size="large"
							style={{
								width: 'fit-content',
								display: 'flex',
								alignItems: 'center',
								border: '1px solid',
								borderRadius: '5px',
								marginBottom: '10px',
								color: colors.GRAPH_PURPLE
							}}
							onClick={showModal}
							icon={<TbTableExport size={30} className={i18n.language === 'en' ? 'me-2' : 'ms-2'} />}
						>
							{t('Export')}
						</Button>
						<Button
							style={{
								width: 'fit-content',
								display: 'flex',
								alignItems: 'center',
								border: ''
							}}
							onClick={onCancel}
							type="ghost"
						>
							{t('Cancel', { ns: 'common' })}
						</Button>
					</div>
					<Readings
						dataSource={tableData.map((el, idx) => ({ key: idx, ...el }))}
						columns={tableColumns}
						pagination={false}
						scroll={{ y: 240, x: true }}
					/>
				</>
			) : null}
		</div>
	);
};
