import { useQueries } from '@tanstack/react-query';
import { Avatar, Col, Dropdown, MenuProps, Select } from 'antd';
import { HTMLAttributes, useContext } from 'react';
import { AiOutlineControl, AiOutlineSetting } from 'react-icons/ai';
import { BsSpeedometer2 } from 'react-icons/bs';
import { GiHamburgerMenu } from 'react-icons/gi';
import { HiOutlinePresentationChartBar } from 'react-icons/hi';
import { MdLogout } from 'react-icons/md';
import { RiAlertLine, RiAdminLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import profile from '../assets/img/profile.png';
import { ComponentGuard, Premium } from '../components';
import { NavbarContainer } from '../components/navbar';
import { UserProfile, WaiNavLink } from '../components/navbar/styles/navlink';
import { SideDrawerBurger } from '../components/side-drawer/styles/burger';
import configProxy from '../config/config';
import { colors } from '../constants/colors';
import { AppRoutes } from '../constants/routes';
import { AuthenticationContext } from '../contexts';
import { BrandingLabelContext } from '../contexts/branding-label';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import { useMedia } from '../hooks/use-media.hook';
import authService from '../services/auth.service';
import userManagementService from '../services/user-management.service';
import { UserSubSystemsApps } from '../types';
import { hasSubSystem } from '../utils/has-sub-system';
import { parseJwt } from '../utils/parse-jwt';
import { upperSnakeToCapitalized } from '../utils/upper-snake-to-capitalized';
import i18n from 'i18next';

const DropdownMenu = (Admin: string, Logout: string) => {
	return [
		hasSubSystem(parseJwt(authService.isAuthenticated().token), UserSubSystemsApps.ADMINISTRATION) && {
			key: 'admin',
			label: (
				<Link to={AppRoutes.ADMINISTRATION}>
					<RiAdminLine /> {Admin}
				</Link>
			)
		},
		{
			key: 'logout',
			label: (
				<Link to={AppRoutes.LOGOUT}>
					<MdLogout /> {Logout}
				</Link>
			)
		}
	].filter(Boolean) as { key: string; label: JSX.Element }[];
};

const handleLanguageText = e => {
	i18n.changeLanguage(e);
	window.location.reload();
};

const renderNavbarItem = (item, media, user, label) => {
	const children = (
		<WaiNavLink to={`/dashboard/${item.name}`} media={media} label={label}>
			<span className="mx-2" style={{ fontSize: '1.4rem' }}>
				{item.icon}
			</span>
			{`${item.nameText[0].toUpperCase()}${item.nameText.slice(1)}`}
		</WaiNavLink>
	);
	return (
		<ComponentGuard
			allowed={hasSubSystem(user, item.name) && item?.allowed}
			key={item.name}
			fallback={() => <Premium showIcon={true} children={children} customStyles={{ translate: -15 }} />}
		>
			{children}
		</ComponentGuard>
	);
};
const NavbarItems = ({ navbarItems, user, media, label }) => {
	return (
		<>
			{navbarItems.map(item => {
				return renderNavbarItem(item, media, user, label);
			})}
		</>
	);
};

const renderMobileNavbarItems: ({ navbarItems, user, media, label }: any) => MenuProps['items'] = ({
	navbarItems,
	user,
	media,
	label
}) => {
	return navbarItems.map(item => {
		return {
			key: item.name,
			label: renderNavbarItem(item, media, user, label)
		};
	});
};

export const WaiNavbar: React.FC<HTMLAttributes<HTMLHeadElement> & { onBurgerClick: () => void }> = ({
	onBurgerClick,
	...restProps
}) => {
	const { t } = useTranslation();
	let media = configProxy.defaultExpandScreenSize;
	let desktopView = useMedia(media);
	const labelContext = useContext(BrandingLabelContext);
	const { label } = labelContext;
	const authContext = useContext(AuthenticationContext);
	const { user } = authContext;
	const navigationContext = useContext(NavigationContext);
	const { selectedNetwork } = navigationContext;

	const navbarItems: any = [
		{ name: UserSubSystemsApps.MONITORING, icon: <BsSpeedometer2 />, nameText: t('monitoring') },
		{ name: UserSubSystemsApps.ALERTS, icon: <RiAlertLine />, nameText: t('alerts') },
		{ name: UserSubSystemsApps.ANALYSIS, icon: <HiOutlinePresentationChartBar />, nameText: t('analysis') },
		{ name: UserSubSystemsApps.CONFIGURATION, icon: <AiOutlineSetting />, nameText: t('configuration') },
		{ name: UserSubSystemsApps.CONTROL, icon: <AiOutlineControl />, nameText: t('control') }
	];

	// nabar subsytems permissions
	const navbarPermissions: any = useQueries<any>({
		queries: navbarItems?.map((item: any) => {
			return {
				queryKey: [`${item.name}`, user?.user_id],
				queryFn: () =>
					userManagementService.getPermissions({
						user: user?.user_id,
						subsystem: user?.sub_systems?.find(
							sub => sub.app__sub_system__displayName.toLowerCase() === item.name.toLowerCase()
						)?.id
					}),
				enabled:
					!!user?.user_id &&
					!!user?.sub_systems?.find(
						sub => sub.app__sub_system__displayName.toLowerCase() === item.name.toLowerCase()
					)?.id
			};
		})
	});

	if (!navbarPermissions?.some(navItem => navItem.isLoading && navItem.isFetching)) {
		navbarItems.map(
			(navbarItem, idx) =>
				(navbarItems[idx] = {
					...navbarItem,
					allowed:
						!!user?.user_data?.is_superAdmin ||
						!!navbarPermissions[idx]?.data?.find(permission => permission.scope === selectedNetwork)
				})
		);
	}

	return (
		<NavbarContainer media={media} {...restProps} englishSelected={i18n.language === 'en'} label={label}>
			{desktopView ? (
				<Col xs={6} md={14}>
					<NavbarItems media={media} navbarItems={navbarItems} user={user} label={label} />
				</Col>
			) : (
				<>
					<Col xs={3} md={2}>
						<SideDrawerBurger onClick={onBurgerClick} media={media} />
					</Col>
					<Col xs={3} md={12}>
						<Dropdown
							trigger={['click']}
							menu={{
								items: !navbarPermissions.some(navItem => navItem.isLoading && navItem.isFetching)
									? renderMobileNavbarItems({ media, navbarItems, selectedNetwork, user, label })
									: []
							}}
						>
							<GiHamburgerMenu size={35} color={colors.WHITE} cursor="pointer" />
						</Dropdown>
					</Col>
				</>
			)}
			<Col xs={18} md={10}>
				<div className="d-flex justify-content-end align-items-center">
					<Dropdown
						menu={{
							items: DropdownMenu(t('Administration'), t('Logout'))
						}}
						trigger={['click']}
					>
						{/* <FiUser size={23} style={{ color: label.navLinkColor, cursor: 'pointer' }} /> */}
						<UserProfile>
							<Avatar src={profile} size={30} style={{ cursor: 'pointer' }} />{' '}
							{t('welcome', { ns: 'common' })} &nbsp;{' '}
							{user ? upperSnakeToCapitalized(user?.user_data.name) : t('User')}
						</UserProfile>
					</Dropdown>
					<Select
						defaultValue={
							(i18n.language === 'en' && t('EN', { ns: 'common' })) || t('AR', { ns: 'common' })
						}
						style={{ width: 80, color: colors.WHITE }}
						bordered={false}
						onChange={e => handleLanguageText(e)}
						options={[
							{
								value: 'en',
								label: t('EN', { ns: 'common' }),
								disabled: i18n.language === 'en'
							},
							{
								value: 'ar',
								label: t('AR', { ns: 'common' }),
								disabled: i18n.language !== 'en'
							}
						]}
					/>
				</div>
			</Col>
		</NavbarContainer>
	);
};
