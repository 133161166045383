import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineWarning } from 'react-icons/ai';
import { BsQuestionLg } from 'react-icons/bs';
import { ImCross } from 'react-icons/im';
import { MdOutlineVerifiedUser } from 'react-icons/md';
import { colors } from '../../constants/colors';
import { ValveOperationStatus } from '../../types/valve';
import { upperSnakeToCapitalized } from '../../utils/upper-snake-to-capitalized';
import { WaiAnalysisWidgetContainer, WaiAnalysisWidgetTitle } from '../wai-analysis-widget';

const operationStatusColorMapper = {
	[ValveOperationStatus.NORMAL]: colors.HEALTHY,
	[ValveOperationStatus.NEEDS_INVESTIGATION]: colors.WARNING,
	[ValveOperationStatus.DATA_PROBLEM]: colors.ERROR,
	[ValveOperationStatus.OPERATION_ERROR]: colors.ERROR,
	[ValveOperationStatus.OTHER]: colors.INACTIVE
};

const operationStatusIconMapper = {
	[ValveOperationStatus.NORMAL]: <MdOutlineVerifiedUser size={60} />,
	[ValveOperationStatus.NEEDS_INVESTIGATION]: <AiOutlineWarning size={60} />,
	[ValveOperationStatus.DATA_PROBLEM]: <ImCross size={60} />,
	[ValveOperationStatus.OPERATION_ERROR]: <ImCross size={60} />,
	[ValveOperationStatus.OTHER]: <BsQuestionLg size={60} />
};

export const WaiOperationStatus: React.FC<HTMLAttributes<HTMLDivElement> & { operation: ValveOperationStatus }> = ({
	operation,
	...restProps
}) => {
	const { t } = useTranslation();

	return (
		<WaiAnalysisWidgetContainer {...restProps}>
			<WaiAnalysisWidgetTitle>{t('operationStatus')}</WaiAnalysisWidgetTitle>
			<div
				className="d-flex flex-column align-items-center justify-content-center "
				style={{ color: operationStatusColorMapper[operation] }}
			>
				{operationStatusIconMapper[operation]}
				<h5 style={{ color: 'inherit', marginTop: '10px' }} className="text-center">
					{upperSnakeToCapitalized(operation)}
				</h5>
			</div>
		</WaiAnalysisWidgetContainer>
	);
};
