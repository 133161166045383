import { useContext, useEffect, useState } from 'react';
import { Form, Switch, Spin, Select, Row, Col, TimePicker, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../hooks/use-query';
import { useQuery } from '@tanstack/react-query';
import { notifySuccess } from '../../utils/notification-messages';
import { NavigationContext } from '../../contexts/navigation.context';
import monitoringService from '../../services/monitoring.service';
import { colors } from '../../constants/colors';
import dayjs from 'dayjs';
import { modalConfirm } from '../modal-confirm';
import { SubmitCanelButtons } from '../submit-cancel-buttons';
import configService from '../../services/config.service';

export const ZoneFlowAnalysisConfiguration: React.FC<any> = () => {
	const { t } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	const [inletMeasurementPoints, setInletMeasurementPoints] = useState<any[]>([]);
	const [outletMeasurementPoints, setOutletMeasurementPoints] = useState<any[]>([]);
	const [activateMNF, setActivateMNF] = useState<boolean>(false);
	const [activateWaterConsumption, setActivateWaterConsumption] = useState<boolean>(false);
	const [activateWaterProduction, setActivateWaterProduction] = useState<boolean>(false);
	const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const zoneId = useQueryParam('zoneId');
	const [form] = Form.useForm();

	// load selected zone flow analysis configuration data
	const {
		data: zoneFlowAnalysisConfig,
		isLoading: loadingZoneFlowAnalysisConfig,
		refetch: refetchZoneFlowAnalysisConfig
	} = useQuery({
		queryKey: ['zone-flow-analysis-config', zoneId],
		queryFn: () => zoneId && configService.getZoneFlowAnalysisConfig(zoneId),
		enabled: !!zoneId
	});

	// load measurement points for selected zone
	const { data: measurementPoints, isLoading: LoadingMeasurementPoints } = useQuery({
		queryKey: ['sites', zoneId],
		queryFn: () => monitoringService.getMeasurementPointsAll({ zone: zoneId }),
		enabled: !!zoneId
	});

	// fill the selected data for zone flow analysis configuration
	useEffect(() => {
		if (zoneFlowAnalysisConfig) {
			if (measurementPoints) {
				resetFields();
			}
		}
	}, [zoneFlowAnalysisConfig, measurementPoints]);

	// change selected inlet measurement points
	const onChangeInlet = (values: string[]) => {
		setOutletMeasurementPoints(measurementPoints && measurementPoints.filter(point => !values.includes(point.id)));
	};

	// change selected outlet measurement points
	const onChangeOutlet = (values: string[]) => {
		setInletMeasurementPoints(measurementPoints && measurementPoints.filter(point => !values.includes(point.id)));
	};

	// on finish zone analysis configuration
	const onFinish = async (values: any) => {
		setSubmitting(true);
		values.minimum_nightly_flow.start_time = dayjs(values.minimum_nightly_flow.start_time).format('HH:mm:ss');
		values.minimum_nightly_flow.end_time = dayjs(values.minimum_nightly_flow.end_time).format('HH:mm:ss');
		try {
			const resp = zoneId && (await configService.editZoneFlowAnalysisConfig(zoneId, values));
			if (!resp) throw new Error(t('couldntEditZoneAnalysisConfig', { ns: 'validation' }));
			refetchZoneFlowAnalysisConfig();
			notifySuccess(t('editedSuccessfully'));
		} catch (e: any) {
			errorHandler(e);
		}
		setSubmitting(false);
	};

	// confirm before submition
	const onConfirm = values => {
		try {
			modalConfirm({
				onOk: async () => {
					await onFinish(values);
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	// reset fields
	const resetFields = () => {
		setInletMeasurementPoints(
			measurementPoints &&
				measurementPoints.filter(point => !zoneFlowAnalysisConfig.outlet_measurement_points.includes(point.id))
		);
		setOutletMeasurementPoints(
			measurementPoints &&
				measurementPoints.filter(point => !zoneFlowAnalysisConfig.inlet_measurement_points.includes(point.id))
		);
		setActivateMNF(zoneFlowAnalysisConfig.minimum_nightly_flow.is_active);
		setActivateWaterConsumption(zoneFlowAnalysisConfig.water_consumption.is_active);
		if (zoneFlowAnalysisConfig.water_production) {
			setActivateWaterProduction(zoneFlowAnalysisConfig.water_production.is_active);
		}
		form.setFieldsValue({
			...zoneFlowAnalysisConfig,
			minimum_nightly_flow: {
				is_active: zoneFlowAnalysisConfig.minimum_nightly_flow.is_active,
				start_time: dayjs(zoneFlowAnalysisConfig.minimum_nightly_flow.start_time, 'HH:mm:ss'),
				end_time: dayjs(zoneFlowAnalysisConfig.minimum_nightly_flow.end_time, 'HH:mm:ss')
			}
		});
		setIsFormChanged(false);
	};

	return (
		<Spin spinning={!zoneId || loadingZoneFlowAnalysisConfig || LoadingMeasurementPoints || submitting}>
			<Form form={form} layout="vertical" onFinish={onConfirm} onValuesChange={() => setIsFormChanged(true)}>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item name="inlet_measurement_points" label={t('InletMeasurementPoints')}>
							<Select
								mode="multiple"
								allowClear
								placeholder={t('InletMeasurementPoints')}
								options={
									inletMeasurementPoints &&
									inletMeasurementPoints.map((point: any) => {
										return {
											value: point.id,
											label: point.name_en
										};
									})
								}
								onChange={onChangeInlet}
							/>
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item name="outlet_measurement_points" label={t('OutletMeasurementPoints')}>
							<Select
								mode="multiple"
								allowClear
								placeholder={t('OutletMeasurementPoints')}
								options={
									(outletMeasurementPoints &&
										outletMeasurementPoints.map((point: any) => {
											return {
												value: point.id,
												label: point.name_en
											};
										})) ||
									[]
								}
								onChange={onChangeOutlet}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Divider />
				<Form.Item label={t('MinimumNightlyFlow')}>
					<Form.Item name={['minimum_nightly_flow', 'is_active']} valuePropName="checked">
						<Switch
							checkedChildren={t('Active')}
							unCheckedChildren={t('Inactive')}
							onChange={value => {
								setActivateMNF(value);
							}}
							style={{ backgroundColor: activateMNF ? colors.HEALTHY : colors.ERROR }}
							disabled={!zoneFlowAnalysisConfig.is_active}
						/>
					</Form.Item>
					<Row gutter={16}>
						<Col xs={12}>
							<Form.Item
								name={['minimum_nightly_flow', 'start_time']}
								label={t('startTime')}
								rules={[{ required: true, message: t('PleaseSelectTime', { ns: 'validation' }) }]}
							>
								<TimePicker />
							</Form.Item>
						</Col>
						<Col xs={12}>
							<Form.Item
								name={['minimum_nightly_flow', 'end_time']}
								label={t('endTime')}
								rules={[{ required: true, message: t('PleaseSelectTime', { ns: 'validation' }) }]}
							>
								<TimePicker />
							</Form.Item>
						</Col>
					</Row>
				</Form.Item>
				<Divider />

				<Form.Item
					name={['water_consumption', 'is_active']}
					valuePropName="checked"
					label={t('WaterConsumption')}
				>
					<Switch
						checked={activateWaterConsumption}
						checkedChildren={t('Active')}
						unCheckedChildren={t('Inactive')}
						onChange={value => setActivateWaterConsumption(value)}
						style={{ backgroundColor: activateWaterConsumption ? colors.HEALTHY : colors.ERROR }}
						disabled={!zoneFlowAnalysisConfig.is_active}
					/>
				</Form.Item>
				{zoneFlowAnalysisConfig.water_production ? (
					<Form.Item
						name={['water_production', 'is_active']}
						valuePropName="checked"
						label={t('WaterProduction')}
					>
						<Switch
							checked={activateWaterProduction}
							checkedChildren={t('Active')}
							unCheckedChildren={t('Inactive')}
							onChange={value => setActivateWaterProduction(value)}
							style={{ backgroundColor: activateWaterProduction ? colors.HEALTHY : colors.ERROR }}
							disabled={!zoneFlowAnalysisConfig.is_active}
						/>
					</Form.Item>
				) : null}
				<Form.Item className="d-flex justify-content-end mt-3">
					<SubmitCanelButtons
						handleCancel={() => resetFields()}
						handleSubmit={form.submit}
						reset={true}
						disabled={!isFormChanged}
					/>
				</Form.Item>
			</Form>
		</Spin>
	);
};
