import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DescriptionCard } from '../description-card';

export const NetworkStaticInformation: React.FC<
	HTMLAttributes<HTMLDivElement> & { network: any; setSelectedMeasurementPoint?: any }
> = ({ network, setSelectedMeasurementPoint }) => {
	const { t, i18n } = useTranslation();
	const [items, setItems] = useState<{ label: string; value: string }[]>([{ label: '', value: '' }]);

	useEffect(() => {
		if (network) {
			const info = [
				{
					label: t('Description'),
					value:
						i18n.language === 'en'
							? network.description_en
							: network?.description_ar || network.description_en
				},
				{
					label: t('SunscriberNo'),
					value: network.subscriber_no
				}
			];
			setItems(info);
		}
	}, [network]);

	return network ? (
		<DescriptionCard
			className="mb-3"
			title={i18n.language === 'en' ? network.name_en : network?.name_ar || network.name_en}
			items={items}
			onMouseOver={() => setSelectedMeasurementPoint && setSelectedMeasurementPoint(undefined)}
		/>
	) : null;
};
