import styled from 'styled-components';

export const SidebarAguamentiImage = styled.img<{ collapsed: boolean }>`
	width: ${({ collapsed }) => (collapsed ? '80px' : '140px')};
	height: ${({ collapsed }) => (collapsed ? '50px' : '90px')};
	margin-bottom: 5px;

	@media (max-width: 1200px) {
		width: ${({ collapsed }) => (collapsed ? '80px' : '90px')};
		height: ${({ collapsed }) => (collapsed ? '50px' : '60px')};
	}
	transition: all 0.4s;
`;
