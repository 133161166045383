import { useContext, useEffect, useState } from 'react';
import { FormProps, Collapse, Switch, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { colors } from '../../constants/colors';
import { NavigationContext } from '../../contexts/navigation.context';
import { useQueryParam } from '../../hooks/use-query';
import { useQuery } from '@tanstack/react-query';
import { ZoneLeakageAnalysisParametersConfiguration } from '../zone-leakage-analysis-params-configuration';
import { ZoneLeakageStaticInfoConfiguration } from '../zone-leakage-static-info-configuration';
import configService from '../../services/config.service';

const { Panel } = Collapse;

export const ZoneLeakageConfiguration: React.FC<FormProps> = () => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	const [activateLeakageAnalysis, setActivateLeakageAnalysis] = useState<boolean | null>(null);
	const [activePanel, setActivePanel] = useState<string[]>([]);

	const zoneId = useQueryParam('zoneId');

	// load selected zone leakage analysis configuration data
	const {
		data: zoneLeakageAnalysisConfig,
		isLoading: loadingZoneLeakageAnalysisConfig,
		refetch: refetchZoneLeakageAnalysisConfig
	} = useQuery({
		queryKey: ['zone-leakage-analysis-config', zoneId],
		queryFn: () => zoneId && configService.getZoneLeakageConfig(zoneId),
		enabled: !!zoneId
	});

	// set default activation/deactivation data for leakage analysis
	useEffect(() => {
		if (zoneLeakageAnalysisConfig) {
			setActivateLeakageAnalysis(zoneLeakageAnalysisConfig.is_active);
		}
	}, [zoneLeakageAnalysisConfig]);

	// activate/deactivate leakage analysis immediately when switching activation key
	useEffect(() => {
		(async () => {
			if (activateLeakageAnalysis !== null && zoneLeakageAnalysisConfig) {
				if (zoneLeakageAnalysisConfig.is_active !== activateLeakageAnalysis) {
					try {
						const resp = await (zoneId &&
							configService.editZoneLeakageConfig(zoneId, {
								is_active: activateLeakageAnalysis
							}));
						if (!resp) throw new Error(t('couldntEditZoneLeakageConfig', { ns: 'validation' }));
						refetchZoneLeakageAnalysisConfig();
					} catch (e: any) {
						errorHandler(e);
					}
				}
			}
		})();
	}, [activateLeakageAnalysis, zoneLeakageAnalysisConfig]);

	return (
		<>
			<Spin spinning={loadingZoneLeakageAnalysisConfig}>
				<Collapse
					accordion
					activeKey={activePanel}
					onChange={(e: any) => {
						setActivePanel(e);
					}}
					className="my-2"
				>
					<Panel
						header={t('LeakageAnalysisParameters')}
						key={'leakage_analysis_parameters'}
						extra={
							<Switch
								checked={activateLeakageAnalysis ?? false}
								checkedChildren={t('Active')}
								unCheckedChildren={t('Inactive')}
								onChange={(value, event) => {
									event.stopPropagation();
									setActivateLeakageAnalysis(value);
								}}
								style={{ backgroundColor: activateLeakageAnalysis ? colors.HEALTHY : colors.ERROR }}
							/>
						}
					>
						<ZoneLeakageAnalysisParametersConfiguration />
					</Panel>
					<Panel header={t('LeakageStaticInfo')} key={'leakage_static_info'}>
						<ZoneLeakageStaticInfoConfiguration />
					</Panel>
				</Collapse>
			</Spin>
		</>
	);
};
