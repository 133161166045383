import { useState, useEffect } from 'react';

export enum ScreenWidths {
	sm = '576px',
	md = '768px',
	lg = '992px',
	xl = '1200px',
	xxl = '1440px',
	xxxl = '1600px',
	xxxxl = '2560px'
}

export const useMedia = screenSize => {
	let [view, setView] = useState<boolean | undefined>(undefined);

	let mql = window.matchMedia(`(min-width:${ScreenWidths[screenSize]})`);

	const resizeHandler = () => {
		setView(mql.matches);
	};

	useEffect(() => {
		resizeHandler();
		if (mql.addEventListener) {
			mql.addEventListener('change', resizeHandler);
		} else {
			mql.addListener(resizeHandler);
		}
		return () => {
			mql.removeEventListener('change', resizeHandler);
			mql.removeListener(resizeHandler);
		};
	}, []);
	return view;
};
