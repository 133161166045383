import React from 'react';
import { Navigate, Outlet, RouteProps } from 'react-router-dom';

export interface IProtectedRouteProps {
	allowed: boolean;
}
export interface IUserRedirectProps extends IProtectedRouteProps {
	loggedInPath: string;
}

export const IsUserRedirect: React.FC<IUserRedirectProps & RouteProps> = ({ allowed, loggedInPath, children }) => {
	return !allowed ? (
		<Outlet />
	) : (
		<Navigate
			replace
			to={{
				pathname: loggedInPath
			}}
		/>
	);
};

export const ProtectedRoute: React.FC<IProtectedRouteProps & RouteProps> = ({ allowed }) => {
	return allowed ? <Outlet /> : <Navigate replace to={{ pathname: '/signin' }} />;
};
