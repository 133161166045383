import dayjs from 'dayjs';
import { HTMLAttributes, useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { GraphCard } from '../components';
import configProxy from '../config/config';
import { colors } from '../constants/colors';
import { useTranslation } from 'react-i18next';
import { useMedia } from '../hooks/use-media.hook';
import { ChartCustomSpan, IChart, TimeStep, SpanType } from '../types';
import { Empty } from 'antd';

export const WaiNumericalChart: React.FC<
	HTMLAttributes<HTMLDivElement> &
		Partial<IChart> & {
			number?: any;
			dateText?: string;
			positive?: boolean;
			increase?: boolean;
			percentage?: number;
			dataObjects?: string[];
			showTabular?: boolean;
			showCustomSelectorDate?: boolean;
			OnDateSelect?: (date?: any) => void;
			actualDataTimeSpan?: ChartCustomSpan;
			IDs?: any;
			flags?: any;
			lastReadingsDates?: object;
			spanType?: any;
			setSpanType?: any;
			customTimeUnits?: any[];
			sourceName?: string;
			deactivated?: boolean;
			deactivatedMsg?: string;
			linkToActivate?: string;
			accessToActivateChart?: boolean;
			otherText?: any;
			empty?: boolean;
		}
> = ({
	title,
	unit,
	number,
	dateText,
	positive,
	increase,
	percentage,
	dataObjects,
	showTabular,
	showCustomSelectorDate,
	OnDateSelect,
	actualDataTimeSpan,
	IDs,
	flags,
	lastReadingsDates,
	spanType,
	setSpanType,
	customTimeUnits,
	lastReadingDate,
	sourceName,
	height,
	deactivated,
	deactivatedMsg,
	linkToActivate,
	accessToActivateChart,
	otherText,
	empty,
	...restProps
}) => {
	const { t, i18n } = useTranslation();
	const [displayDate, setDisplayDate] = useState<any>();
	const [timeStep, setTimeStep] = useState<TimeStep>(TimeStep.DAY);
	const md = useMedia('md');
	const media = configProxy.defaultExpandScreenSize;
	const desktopView = useMedia(media);

	// for testing
	// percentage = 98;
	// increase = true;
	// positive = false;
	return (
		<GraphCard
			title={title}
			showTabular={showTabular}
			dataObjects={dataObjects}
			OnDateSelect={OnDateSelect}
			showCustomSelectorDate={showCustomSelectorDate}
			IDs={IDs}
			flags={flags}
			lastReadingsDates={lastReadingsDates}
			setDisplayDate={setDisplayDate}
			displayDate={displayDate}
			showTimeStep={showCustomSelectorDate}
			setTimeStep={setTimeStep}
			spanType={spanType}
			setSpanType={setSpanType}
			customTimeUnits={customTimeUnits}
			lastReadingDate={lastReadingDate}
			sourceName={sourceName}
			height={height}
			deactivated={deactivated}
			deactivatedMsg={deactivatedMsg}
			linkToActivate={linkToActivate}
			accessToActivateChart={accessToActivateChart}
			{...restProps}
		>
			{empty ? (
				<div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
					<Empty />
				</div>
			) : (
				<>
					{showCustomSelectorDate ? (
						<div
							className={`d-flex justify-content-between w-100 ${
								!number && !dateText ? 'align-items-center' : 'align-items-end'
							} ${desktopView ? 'mb-3' : 'mb-2'} ${percentage ? '' : 'mt-2'}`}
							style={
								!number && !dateText
									? i18n.language === 'en'
										? { paddingLeft: '5px' }
										: { paddingRight: '5px' }
									: i18n.language === 'en'
									? { paddingLeft: '5px', height: '65%' }
									: { paddingRight: '5px', height: '65%' }
							}
							dir="ltr"
						>
							<AiOutlineLeft
								size={'1rem'}
								className={'mb-4'}
								role={'button'}
								onClick={() => {
									OnDateSelect &&
										OnDateSelect({
											start: displayDate
												? dayjs(displayDate)
														.subtract(1, timeStep)
														.startOf(timeStep)
														.format('YYYY-MM-DD')
												: dayjs(lastReadingsDates?.[spanType])
														.subtract(1, timeStep)
														.startOf(timeStep)
														.format('YYYY-MM-DD'),
											date:
												spanType === SpanType.DAY
													? displayDate
														? dayjs(displayDate)
																.subtract(1, timeStep)
																.startOf(timeStep)
																.format('YYYY-MM-DD')
														: dayjs(lastReadingsDates?.[spanType])
																.subtract(1, timeStep)
																.startOf(timeStep)
																.format('YYYY-MM-DD')
													: undefined
										});
									setDisplayDate(
										displayDate
											? dayjs(displayDate).subtract(1, timeStep).format('YYYY-MM-DD')
											: dayjs(lastReadingsDates?.[spanType]).subtract(1, timeStep)
									);
								}}
							/>
							{number ? (
								<div className="d-flex justify-content-center align-items-center">
									<h5
										style={{ fontSize: '3.5rem', color: colors.GRAPH_PURPLE, fontWeight: 400 }}
										className="mx-3"
									>
										{number}
									</h5>
									<span style={{ fontSize: '1.5rem', color: colors.GREY, marginTop: '1.5rem' }}>
										{unit}
									</span>
								</div>
							) : null}
							{otherText ? (
								<div className="d-flex justify-content-center align-items-center">{otherText}</div>
							) : null}
							<AiOutlineRight
								size={'1rem'}
								className={'mb-4'}
								role={'button'}
								onClick={() => {
									OnDateSelect &&
										OnDateSelect({
											start: displayDate
												? dayjs(displayDate)
														.add(1, timeStep)
														.startOf(timeStep)
														.format('YYYY-MM-DD')
												: dayjs(lastReadingsDates?.[spanType])
														.add(1, timeStep)
														.startOf(timeStep)
														.format('YYYY-MM-DD'),
											date:
												spanType === SpanType.DAY
													? displayDate
														? dayjs(displayDate)
																.add(1, timeStep)
																.startOf(timeStep)
																.format('YYYY-MM-DD')
														: dayjs(lastReadingsDates?.[spanType])
																.add(1, timeStep)
																.startOf(timeStep)
																.format('YYYY-MM-DD')
													: undefined
										});
									setDisplayDate(
										displayDate
											? dayjs(displayDate).add(1, timeStep).format('YYYY-MM-DD')
											: dayjs(lastReadingsDates?.[spanType]).add(1, timeStep)
									);
								}}
							/>
						</div>
					) : (
						<div
							className={`d-flex justify-content-center align-items-end w-100 ${
								desktopView ? 'mb-3' : 'mb-2'
							} ${percentage ? '' : 'mt-2'} `}
							dir={i18n.language === 'en' ? 'ltr' : 'rtl'}
							style={{ height: '55%' }}
						>
							<h5
								style={{ fontSize: '3.5rem', color: colors.GRAPH_PURPLE, fontWeight: 400 }}
								className="mx-3 "
							>
								{number}
							</h5>
							<span style={{ fontSize: '1.5rem', color: colors.GREY, marginTop: '1rem' }}>{unit}</span>
						</div>
					)}
					{percentage ? (
						<div
							className={`d-flex align-items-center w-100 justify-content-center ${
								md ? 'mb-1' : 'mb-2'
							} `}
						>
							{increase ? (
								<>
									<span
										style={{
											color: positive ? colors.GRAPH_GREEN : colors.ERROR,
											marginRight: '15px',
											fontSize: '1rem'
										}}
									>
										{t('MoreThanPreviousSpan', { spanType: t(spanType) || t('day') })}
									</span>
									<span
										style={{
											fontSize: '1rem',
											color: positive ? colors.GRAPH_GREEN : colors.ERROR
										}}
									>
										<AiOutlineCaretUp />
										{percentage}%
									</span>
								</>
							) : (
								<>
									<span
										style={{
											color: positive ? colors.GRAPH_GREEN : colors.ERROR,
											marginRight: '15px',
											fontSize: '1rem'
										}}
									>
										{t('LessThanPreviousSpan', { spanType: t(spanType) || t('day') })}
									</span>
									<span
										style={{
											fontSize: '1rem',
											color: positive ? colors.GRAPH_GREEN : colors.ERROR
										}}
									>
										<AiOutlineCaretDown />
										{percentage}%
									</span>
								</>
							)}
						</div>
					) : null}
					{dateText ? (
						<div
							className={`d-flex align-items-center w-100 justify-content-center mb-2 ${
								percentage ? 'mt-1' : 'mt-4'
							}`}
						>
							<span
								style={{
									color: colors.GREY,
									marginRight: i18n.language === 'en' ? '15px' : '',
									marginLeft: i18n.language === 'en' ? '' : '15px',
									fontSize: '1rem'
								}}
							>
								{dateText}
							</span>
						</div>
					) : null}
				</>
			)}
		</GraphCard>
	);
};
