import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const WaiChatBot = styled.div<{ englishSelected: boolean; label: any }>`
	.rcw-full-screen .rcw-widget-container {
		bottom: 0;
		display: block;
		flex-direction: column;
		margin: 0;
		position: relative;
		right: 0;
		z-index: 1;
	}
	.rcw-widget-container {
		${({ englishSelected }) => (englishSelected ? 'right: 0;' : 'left: 0; right: unset;')};
		margin: 10px;
	}
	.rcw-conversation-container {
		max-width: 100%;
	}
	.rcw-full-screen {
		height: 84vh;
	}
	.rcw-conversation-container > .rcw-header {
		background-color: ${({ label }) => label.sliderColor};
		padding: 15px;
	}
	.rcw-conversation-container > .rcw-messages-container > .rcw-message-client .rcw-message-text {
		background-color: ${colors.WAI_BLUE};
		color: ${colors.WHITE};
	}
	.rcw-launcher {
		background-color: ${({ label }) => label.sliderColor};
	}
	.rcw-title {
		padding: 0px;
	}
	.rcw-picker-btn {
		display: none;
	}
	.rcw-new-message {
		width: calc(100% - 37px);
	}
	.rcw-sender {
		align-items: center;
	}
	.rcw-close-button {
		display: none;
	}
	.rcw-client {
		margin: 10px;
	}
	.rcw-send-icon {
		${({ englishSelected }) => (englishSelected ? '' : 'transform: scaleX(-1);')};
	}
`;
