import { useContext, useEffect, useState } from 'react';
import { Form, Spin, Modal, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import monitoringService from '../../services/monitoring.service';
import { useQueryParam } from '../../hooks/use-query';
import { notifySuccess } from '../../utils/notification-messages';
import { useNavigate } from 'react-router-dom';
import { NavigationContext } from '../../contexts/navigation.context';
import { AppRoutes } from '../../constants/routes';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { UploadFile } from 'antd/es/upload/interface';
import type { RcFile, UploadProps } from 'antd/es/upload';
import { Sites } from '../../types/sites';
import { ValveForm } from '../valve-form';
import { WaterStationForm } from '../water-station-form';
import { NetworkMeasurementPointForm } from '../network-measurement-point-form';

export const SiteForm: React.FC<any> = ({
	siteData,
	setSelectedLocation,
	selectedLocation,
	activatedPoint,
	setActivatedPoint
}) => {
	const { t } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { networks, measurementPoints, errorHandler, refetchNetworks, refetchAllZones, refetchZones } =
		navigationContext;

	const [selectedNetwork, setSelectedNetwork] = useState<any>();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const [imageList, setImageList] = useState<any>([]);
	const [fileList, setFileList] = useState<any>([]);
	const [deletedImages, setDeletedImages] = useState<string[]>([]);
	const [deletedFiles, setDeletedFiles] = useState<string[]>([]);

	const [previewOpen, setPreviewOpen] = useState<boolean>(false);
	const [previewImage, setPreviewImage] = useState<string>('');
	const [previewTitle, setPreviewTitle] = useState<string>('');

	const networkId = useQueryParam('networkId');
	const type = useQueryParam('type');
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	// load zones for a network
	const { data: zonesNetwork, isLoading: loadingZonesNetwork } = useQuery<any>({
		queryKey: ['zones', selectedNetwork],
		queryFn: () => selectedNetwork && monitoringService.getZonesAll({ network: selectedNetwork }),
		enabled: !!selectedNetwork
	});

	// load point images
	const {
		data: pointImages,
		isLoading: loadingPointImages,
		isFetching: fetchingPointImages
	} = useQuery<any>({
		queryKey: ['images', siteData?.id],
		queryFn: () => siteData?.id && monitoringService.getMeasurementPointImages(siteData.id),
		enabled: !!siteData?.id
	});
	// load point files
	const {
		data: pointFiles,
		isLoading: loadingPointFiles,
		isFetching: fetchingPointFiles
	} = useQuery<any>({
		queryKey: ['files', siteData?.id],
		queryFn: () => siteData?.id && monitoringService.getMeasurementPointFiles(siteData.id),
		enabled: !!siteData?.id
	});

	// set selectedNetwork to get its zones
	useEffect(() => {
		if (networkId) {
			setSelectedNetwork(networkId);
		}
	}, []);

	// set images, files and location for the selected measurement point
	useEffect(() => {
		if (siteData && selectedNetwork && pointImages && pointFiles) {
			setSelectedLocation({
				latitude: siteData.latitude,
				longitude: siteData.longitude
			});
			setImageList(
				pointImages.map(image => {
					return { url: image.file, uid: image.id, oldImage: true };
				})
			);
			setFileList(
				pointFiles.map(file => {
					return { url: file.file, uid: file.id, oldFile: true };
				})
			);
			setActivatedPoint(siteData.is_active);
		}
	}, [siteData, selectedNetwork, pointImages, pointFiles]);

	// reset zones when change network
	const changeNetwork = async (value: string) => {
		try {
			await setSelectedNetwork(value);
		} catch (e: any) {
			errorHandler(e);
		} finally {
			await form.setFieldsValue({ zone: undefined });
		}
	};

	// check before uploading image for the type and size of that image
	const beforeImageUpload = file => {
		const isImage = file.type.startsWith('image/');
		const isLt5M = file.size / 1024 / 1024 < 5;
		if (!isImage) {
			errorHandler({ message: t('YouMustUploadImagesOnly', { ns: 'validation' }) });
			return isImage;
		}
		if (!isLt5M) {
			errorHandler({ message: t('ImageMustBeSmallerThan5MB', { ns: 'validation' }) });
			return isLt5M;
		}
		return isImage && isLt5M;
	};

	// check before uploading file for the type and size of that file
	const beforeFileUpload = file => {
		const isLt5M = file.size / 1024 / 1024 < 5;
		const allowedFileTypes = [
			'application/pdf',
			'application/msword',
			'text/plain',
			'text/csv',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		];
		if (!isLt5M) {
			errorHandler({ message: 'File must be smaller than 5MB!' });
		}
		if (!allowedFileTypes.includes(file.type)) {
			errorHandler({ message: 'File type not supported! Please upload a PDF, DOCX, TXT, CSV, or XLSX file.' });
		}
		return isLt5M && allowedFileTypes.includes(file.type);
	};

	// handle changing of images
	const handleImageChange: UploadProps['onChange'] = ({ fileList: newImageList }) => {
		// check for the type and size of image before adding it into imageList
		if (newImageList.length > 0) {
			const newFile: any = newImageList[newImageList.length - 1];
			if (!newFile?.oldImage) {
				const isImage = newFile.type.startsWith('image/');
				const isLt5M = newFile.size / 1024 / 1024 < 5;
				if (!isImage || !isLt5M) return false;
			}
		}
		setImageList(newImageList);
	};

	// handle changing of files
	const handleFileChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		// check for the size of file before adding it into fileList
		if (newFileList.length > 0) {
			const newFile: any = newFileList[newFileList.length - 1];
			if (!newFile?.oldFile) {
				const isLt5M = newFile.size / 1024 / 1024 < 5;
				if (!isLt5M) return false;
			}
		}
		setFileList(newFileList);
	};

	// display image image as a data url
	const getBase64 = (file: RcFile): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = error => reject(error);
		});

	// handle previewing image
	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile);
		}
		setPreviewImage(file.url || (file.preview as string));
		setPreviewOpen(true);
		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
	};

	// submit edition or creation for measurement point
	const onFinish = async (values: any, type: Sites) => {
		setSubmitting(true);
		Object.keys(values).forEach(key => {
			if (values[key] === '' || values[key] === undefined || values[key] === null) {
				delete values[key];
			}
		});
		values.type = type;
		// to edit
		if (siteData) {
			try {
				const resp = await monitoringService.editMeasurementPoint(siteData.id, values);
				if (!resp) throw new Error(t('couldntEditMeasurementPoint', { ns: 'validation' }));
				// upload new images to MP if any exist
				const newImages = imageList.filter(image => !image?.oldImage);
				if (newImages.length > 0) {
					const fd_images = new FormData();
					for (const image of newImages) {
						fd_images.append('image', image.originFileObj);
					}
					const respImages = await monitoringService.addMeasurementPointImages(siteData.id, fd_images);
					if (!respImages) throw new Error(t('couldntAddMeasurementPointImages', { ns: 'validation' }));
				}
				// upload new files to MP if any exist
				const newFiles = fileList.filter(file => !file?.oldFile);
				if (newFiles.length > 0) {
					const fd_files = new FormData();
					for (const file of newFiles) {
						fd_files.append('file', file.originFileObj);
					}
					const respFiles = await monitoringService.addMeasurementPointFiles(siteData.id, fd_files);
					if (!respFiles) throw new Error(t('couldntAddMeasurementPointFiles', { ns: 'validation' }));
				}
				// delete images for MP if any exist in deletedImages
				if (deletedImages.length > 0) {
					await deletedImages.map(imageId => monitoringService.deleteImage(imageId));
				}
				// delete files for MP if any exist in deletedFiles
				if (deletedFiles.length > 0) {
					await deletedFiles.map(fileId => monitoringService.deleteFile(fileId));
				}
				const measurementPointIdx = measurementPoints.findIndex((n: any) => n.id === resp.id);
				let updatedMeasurementPoints = [...measurementPoints];
				updatedMeasurementPoints.splice(measurementPointIdx, 1, resp);
				refetchNetworks();
				refetchAllZones();
				refetchZones();
				queryClient.refetchQueries(['sites']);
				queryClient.refetchQueries(['site', siteData.id]);
				notifySuccess(t('editedSuccessfully'));
				navigate(`${AppRoutes.CONFIGURATION}?tabSelected=sites`);
			} catch (e: any) {
				errorHandler(e?.detail ? { message: e.detail } : e);
			}
		}
		// create
		else {
			try {
				const resp = await monitoringService.createMeasurementPoint(values);
				if (!resp) throw new Error(t('couldntAddMeasurementPoint', { ns: 'validation' }));
				// upload images for the new MP if any exist
				if (imageList.length > 0) {
					const fd_images = new FormData();
					for (const image of imageList) {
						fd_images.append('image', image.originFileObj);
					}
					const respImages = await monitoringService.addMeasurementPointImages(resp.id, fd_images);
					if (!respImages) throw new Error(t('couldntAddMeasurementPointImages', { ns: 'validation' }));
				}
				// upload files for the new MP if any exist
				if (fileList.length > 0) {
					const fd_files = new FormData();
					for (const file of fileList) {
						fd_files.append('file', file.originFileObj);
					}
					const respFiles = await monitoringService.addMeasurementPointFiles(resp.id, fd_files);
					if (!respFiles) throw new Error(t('couldntAddMeasurementPointFiles', { ns: 'validation' }));
				}
				refetchNetworks();
				refetchAllZones();
				refetchZones();
				queryClient.refetchQueries(['sites']);
				notifySuccess(t('addedSuccessfully'));
				navigate(`${AppRoutes.CONFIGURATION}?tabSelected=sites`);
			} catch (e: any) {
				errorHandler(e?.detail ? { message: e.detail } : e);
			}
		}
		setSubmitting(false);
	};

	const siteDataParsed = {
		measurementPointData: siteData,
		setSelectedLocation,
		selectedLocation,
		changeNetwork,
		loadingZonesNetwork,
		zonesNetwork,
		selectedNetwork,
		beforeImageUpload,
		handleImageChange,
		handlePreview,
		beforeFileUpload,
		handleFileChange,
		imageList,
		setImageList,
		fileList,
		setFileList,
		deletedImages,
		setDeletedImages,
		deletedFiles,
		setDeletedFiles,
		activatedPoint,
		setActivatedPoint,
		onFinish
	};

	return (
		<Card style={{ fontWeight: 'bold' }}>
			<h3 className="mb-3">{form.getFieldValue('name_en')}</h3>
			<Spin
				spinning={
					!!!networks ||
					submitting ||
					(loadingPointImages && fetchingPointImages) ||
					(loadingPointFiles && fetchingPointFiles)
				}
			>
				{type === Sites.VALVE || siteData?.valve ? (
					<ValveForm {...siteDataParsed} />
				) : type === Sites.WATER_STATION || siteData?.water_station ? (
					<WaterStationForm {...siteDataParsed} />
				) : (
					<NetworkMeasurementPointForm {...siteDataParsed} />
				)}
				<Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
					<img style={{ width: '100%' }} src={previewImage} />
				</Modal>
			</Spin>
		</Card>
	);
};
