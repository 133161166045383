import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import App from './app';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container!);
// Set staleTime to 5 minutes
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 5 * 1000 * 60
		}
	}
});
root.render(
	<QueryClientProvider client={queryClient}>
		<App />
	</QueryClientProvider>
);
