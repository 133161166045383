import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const GraphSpanSelectorContainer = styled.div<{ englishSelected: any }>`
	display: flex;
	font-size: 0.8rem;
	font-weight: 600;
	p,
	span {
		color: ${colors.DARK_GREY};
		${({ englishSelected }) => (englishSelected ? 'margin-right: 4px;' : 'margin-left: 4px;')};
	}
	span {
		display: flex;
		height: 20px;
		cursor: pointer;
		:hover {
			color: ${colors.LIGHT_PURPLE};
			border-bottom: 1px solid ${colors.LIGHT_PURPLE};
		}
	}
	.selected-span {
		color: ${colors.LIGHT_PURPLE};
		border-bottom: 1px solid ${colors.LIGHT_PURPLE};
	}
`;

export const GraphTimeStepSelectorContainer = styled.div<{ englishSelected: any }>`
	display: flex;
	font-size: 0.8rem;
	font-weight: 600;
	p,
	span {
		color: ${colors.DARK_GREY};
		${({ englishSelected }) => (englishSelected ? 'margin-right: 4px;' : 'margin-left: 4px;')};
	}
	span {
		display: flex;
		height: 20px;
		cursor: pointer;
		:hover {
			color: ${colors.LIGHT_PURPLE};
			border-bottom: 1px solid ${colors.LIGHT_PURPLE};
		}
	}
	.selected-span {
		color: ${colors.LIGHT_PURPLE};
		border-bottom: 1px solid ${colors.LIGHT_PURPLE};
	}
`;

export const GraphSpanCustomSelectorView = styled.span<{ englishSelected: any }>`
	cursor: pointer;
	display: flex;
	align-items: center;
	color: ${colors.RED};
	color: ${colors.GRAPH_PURPLE};
	span {
		font-size: 0.7rem;
		font-weight: 600;
		${({ englishSelected }) => (englishSelected ? 'margin-left: 5px;' : 'margin-right: 5px;')};
	}
`;
