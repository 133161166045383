import { useState, useMemo } from 'react';

export function useFileUrl(url: string, deps = []) {
	return useMemo(
		() => ({
			url
			// httpHeaders: {
			// 	'Access-Control-Request-Method': 'GET',
			// 	'Access-Control-Allow-Origin': '*',
			// 	'Cache-Control': 'no-cache',
			// 	'Pragma': 'no-cache'
			// }
			// withCredentials: true
		}),
		Array.isArray(deps) ? deps : []
	);
}

export function usePDFViewer(url: string, deps: any = null) {
	// let [visible, setVisibility] = useState(false);
	let [numberPages, setNumberPages] = useState<number>();
	let file = useFileUrl(url, deps);
	return { numberPages, setNumberPages, file };
}
