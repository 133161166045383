import { useQuery } from '@tanstack/react-query';
import { Card, Checkbox, Spin, Table } from 'antd';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import monitoringService from '../../services/monitoring.service';
import userManagementService from '../../services/user-management.service';
import { UserApps } from '../../types';
import { upperSnakeToCapitalized } from '../../utils';
import { notifySuccess } from '../../utils/notification-messages';
import { TableControls } from '../table-controls';
import { modalConfirm } from '../modal-confirm';

export const UserPermissionsTable: React.FC<any> = ({ selectedUser }) => {
	const { t, i18n } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;
	const [expandedSubSystem, setExpandedSubSystem] = useState<any>([]);
	const navigate = useNavigate();

	// get permissions
	const {
		data: permissions,
		isLoading: loadingPermissions,
		refetch: refetchPermissions
	} = useQuery<any>({
		queryKey: ['user-permissions', selectedUser],
		queryFn: () => userManagementService.getPermissions({ user: selectedUser }),
		enabled: !!selectedUser
	});
	// get networks
	const { data: networks, isLoading: loadingNetworks } = useQuery<any>({
		queryKey: ['networks'],
		queryFn: () => monitoringService.getNetworks()
	});

	// get sub_systems
	const { data: sub_systems, isLoading: loadingSubsystems } = useQuery<any>({
		queryKey: ['sub_systems'],
		queryFn: () => userManagementService.getSubsystems()
	});

	// get applications
	const { data: applications, isLoading: loadingApplications } = useQuery<any>({
		queryKey: ['applications'],
		queryFn: () => userManagementService.getApplications()
	});

	const SubsystemsTableColumns: any = [
		{
			title: t('Network'),
			dataIndex: 'scope',
			key: 'scope',
			align: 'center',
			render: (networkId: any) => {
				const network = networks?.find(network => network.id === networkId);
				return network
					? upperSnakeToCapitalized(i18n.language === 'en' ? network.name_en : network.name_ar)
					: '';
			}
		},
		{
			title: t('SubSystem'),
			dataIndex: 'sub_system',
			key: 'sub_system',
			align: 'center',
			render: (sub_systemId: any) => {
				const sub_system = sub_systems?.find(sub_system => sub_system.id === sub_systemId);
				return sub_system ? upperSnakeToCapitalized(sub_system.name) : '';
			}
		}
	];

	const RightsTableColumns: any = [
		{
			title: t('App'),
			dataIndex: 'app',
			key: 'app',
			align: 'center',
			render: (appId: any) => {
				const application = applications?.find(application => application.id === appId);
				return application ? upperSnakeToCapitalized(application.name) : '';
			}
		},
		{
			title: t('Read'),
			dataIndex: 'rights',
			key: 'rights',
			align: 'center',
			render: (rights: any) => <Checkbox checked={rights.read} defaultChecked disabled />
		},
		{
			title: t('CreateNew'),
			dataIndex: 'rights',
			key: 'rights',
			align: 'center',
			render: (rights: any) => <Checkbox checked={rights.create} defaultChecked disabled />
		},
		{
			title: t('Update'),
			dataIndex: 'rights',
			key: 'rights',
			align: 'center',
			render: (rights: any) => <Checkbox checked={rights.update} defaultChecked disabled />
		},
		{
			title: t('Delete'),
			dataIndex: 'rights',
			key: 'rights',
			align: 'center',
			render: (rights: any) => <Checkbox className="" checked={rights.delete} defaultChecked disabled />
		}
	];

	RightsTableColumns.push({
		title: t('Actions'),
		key: 'actions',
		align: 'center',
		render: (_: any, record: any) => (
			<TableControls
				networkId={record.scope}
				onEdit={() =>
					navigate(
						`${AppRoutes.USER_PERMISSION}?userId=${selectedUser}&scopeId=${record.scope}&appId=${record.app}`
					)
				}
				onRemove={() => removeRight(record?.id)}
				userApp={UserApps.USER_PERMISSIONS}
				admin={true}
			/>
		)
	});

	const removeRight = (id: string) => {
		try {
			modalConfirm({
				content: t('YouAreAboutToDeleteRightForThisUser'),
				onOk: async () => {
					await userManagementService.deleteUserPermission(id);
					refetchPermissions();
					notifySuccess(t('RemovedSuccessfully'));
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	const handleExpandSubsystem = (expanded, record) => {
		if (expanded) {
			setExpandedSubSystem([record]);
		} else {
			setExpandedSubSystem([]);
		}
	};

	return (
		<>
			<Spin spinning={loadingPermissions || loadingNetworks || loadingSubsystems || loadingApplications}>
				<Table
					key={selectedUser}
					tableLayout="auto"
					scroll={{ x: true }}
					columns={SubsystemsTableColumns}
					dataSource={
						(permissions &&
							permissions?.reduce((result, currentItem) => {
								const key = `${currentItem.sub_system}-${currentItem.scope}`;
								if (!result.some(item => `${item.sub_system}-${item.scope}` === key)) {
									result.push(currentItem);
								}

								return result;
							}, [])) ??
						undefined
					}
					size="small"
					showSorterTooltip
					rowKey="id"
					expandable={{
						expandedRowKeys: expandedSubSystem,
						onExpand: (expanded, record) => {
							handleExpandSubsystem(expanded, record.id);
						},
						expandedRowRender: record => {
							return (
								<div key={record.id}>
									<Card>
										<Table
											tableLayout="auto"
											scroll={{ x: true }}
											columns={RightsTableColumns}
											dataSource={
												(permissions &&
													permissions
														.filter(
															permission => permission.sub_system === record.sub_system
														)
														.filter(permission => permission.scope === record.scope)) ??
												undefined
											}
											size="small"
											showSorterTooltip
											rowKey="id"
											pagination={false}
											style={{ marginInline: 0 }}
										/>
									</Card>
								</div>
							);
						},
						expandRowByClick: true
					}}
				/>
			</Spin>
		</>
	);
};
