import { Col, Row } from 'antd';
import { HTMLAttributes } from 'react';
import { LoggersDashboardGraphs } from './loggers-dashboard-graphs';
import { LoggersDashboardTable } from './loggers-dashboard-table';

export const NetworkAnalysisLoggersDashboardTab: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		setSelectedMeasurementPoint?: any;
	}
> = ({ setSelectedMeasurementPoint }) => {
	return (
		<div style={{ overflowY: 'auto', overflowX: 'hidden', height: '60vh' }} className={'mb-2'}>
			<Row className="mb-3">
				<Col xs={24}>
					<LoggersDashboardGraphs setSelectedMeasurementPoint={setSelectedMeasurementPoint} />
				</Col>
			</Row>
			<Row>
				<Col xs={24}>
					<LoggersDashboardTable setSelectedMeasurementPoint={setSelectedMeasurementPoint} />
				</Col>
			</Row>
		</div>
	);
};
