import { useContext, useEffect, useState } from 'react';
import { Form, Spin, Row, Col, InputNumber, Input } from 'antd';
import { useQueryParam } from '../../hooks/use-query';
import { useQuery } from '@tanstack/react-query';
import { notifySuccess } from '../../utils/notification-messages';
import { NavigationContext } from '../../contexts/navigation.context';
import { useTranslation } from 'react-i18next';
import { modalConfirm } from '../modal-confirm';
import { SubmitCanelButtons } from '../submit-cancel-buttons';
import configService from '../../services/config.service';

export const ZoneLeakageStaticInfoConfiguration: React.FC<any> = () => {
	const { t } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const zoneId = useQueryParam('zoneId');
	const [form] = Form.useForm();

	// load selected zone leakage parameters configuration data
	const {
		data: zoneLeakageParametersConfig,
		isLoading: loadingZoneLeakageParametersConfig,
		refetch: refetchZoneLeakageParametersConfig
	} = useQuery({
		queryKey: ['zone-leakage-params-config', zoneId],
		queryFn: () => zoneId && configService.getZoneLeakageParameters(zoneId),
		enabled: !!zoneId
	});

	// fill the selected data for zone leakage parameters configuration
	useEffect(() => {
		if (zoneLeakageParametersConfig) {
			resetFields();
		}
	}, [zoneLeakageParametersConfig]);

	// on finish zone leakaage parameters configuration
	const onFinish = async (values: any) => {
		setSubmitting(true);
		try {
			const resp = zoneId && (await configService.editZoneLeakageParameters(zoneId, values));
			if (!resp) throw new Error(t('couldntEditZoneLeakageConfig', { ns: 'validation' }));
			refetchZoneLeakageParametersConfig();
			notifySuccess(t('editedSuccessfully'));
		} catch (e: any) {
			errorHandler(e);
		}
		setSubmitting(false);
	};

	// confirm before submition
	const onConfirm = values => {
		try {
			modalConfirm({
				onOk: async () => {
					await onFinish(values);
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	// reset fields
	const resetFields = () => {
		form.setFieldsValue({
			...zoneLeakageParametersConfig
		});
		setIsFormChanged(false);
	};

	return (
		<Spin spinning={!zoneId || loadingZoneLeakageParametersConfig || submitting}>
			<Form form={form} layout="vertical" onFinish={onConfirm} onValuesChange={() => setIsFormChanged(true)}>
				<Form.Item label={t('LengthOfMains')} style={{ marginBottom: 0 }}>
					<Form.Item label={t('LengthOfMmains')} style={{ marginBottom: 0 }}>
						<Row className="w-100" gutter={16}>
							<Col xs={12}>
								<Form.Item
									name="length_of_mains"
									rules={[
										{ required: true, message: t('PleaseAddLengthOfMains', { ns: 'validation' }) }
									]}
								>
									<InputNumber placeholder={t('LengthOfMmains')} style={{ width: '100%' }} />
								</Form.Item>
							</Col>
							<Col xs={12}>
								<Form.Item
									name="length_of_mains_unit"
									rules={[
										{
											required: true,
											message: t('PleaseAddLengthOfMainsUnit', { ns: 'validation' })
										}
									]}
								>
									<Input placeholder={t('lengthOfMainsUnit')} style={{ width: '100%' }} disabled />
								</Form.Item>
							</Col>
						</Row>
					</Form.Item>
				</Form.Item>
				<Row gutter={16}>
					<Col xs={12}>
						<Form.Item
							label={t('NoOfConnections')}
							name="no_of_connections"
							rules={[{ required: true, message: t('PleaseAddNoOfConnections', { ns: 'validation' }) }]}
						>
							<InputNumber
								step={1}
								parser={(value: any) => {
									return value.split(/[^0-9]/g)[0];
								}}
								placeholder={t('NoOfConnections')}
								style={{ width: '100%' }}
							/>
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item
							label={t('NoOfProperties')}
							name="no_of_properties"
							rules={[{ required: true, message: t('PleaseAddNoOfProperties', { ns: 'validation' }) }]}
						>
							<InputNumber
								step={1}
								parser={(value: any) => {
									return value.split(/[^0-9]/g)[0];
								}}
								placeholder={t('NoOfProperties')}
								style={{ width: '100%' }}
							/>
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item
							label={t('EstimatedPopulation')}
							name="estimated_population"
							rules={[
								{ required: true, message: t('PleaseAddEstimatedPopulation', { ns: 'validation' }) }
							]}
						>
							<InputNumber
								step={1}
								parser={(value: any) => {
									return value.split(/[^0-9]/g)[0];
								}}
								placeholder={t('EstimatedPopulation')}
								style={{ width: '100%' }}
							/>
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item
							label={t('NoOfSmallNonDomesticUsers')}
							name="no_of_small_non_domestic_users"
							rules={[
								{
									required: true,
									message: t('PleaseAddNoOfSmallNonDomesticUsers', { ns: 'validation' })
								}
							]}
						>
							<InputNumber
								step={1}
								parser={(value: any) => {
									return value.split(/[^0-9]/g)[0];
								}}
								placeholder={t('NoOfSmallNonDomesticUsers')}
								style={{ width: '100%' }}
							/>
						</Form.Item>
					</Col>
					<Col xs={12}>
						<Form.Item
							label={t('NoOfLargeNonDomesticUsers')}
							name="no_of_large_non_domestic_users"
							rules={[
								{
									required: true,
									message: t('PleaseAddNoOfLargeNonDomesticUsers', { ns: 'validation' })
								}
							]}
						>
							<InputNumber
								step={1}
								parser={(value: any) => {
									return value.split(/[^0-9]/g)[0];
								}}
								placeholder={t('NoOfLargeNonDomesticUsers')}
								style={{ width: '100%' }}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Form.Item className="d-flex justify-content-end mt-3">
					<SubmitCanelButtons
						handleCancel={() => resetFields()}
						handleSubmit={form.submit}
						reset={true}
						disabled={!isFormChanged}
					/>
				</Form.Item>
			</Form>
		</Spin>
	);
};
