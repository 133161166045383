import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertCardType } from '../../types/alerts';
import { capitalize } from '../../utils/capitalize-string';
import { AlertsCardContainer, AlertsCardNumber, AlertsCardTitle } from './styles/alerts-card';

export const AlertsCard: React.FC<HTMLAttributes<HTMLDivElement> & { type: AlertCardType }> = ({
	children,
	type,
	...restProps
}) => {
	const { t, i18n } = useTranslation();
	return (
		<AlertsCardContainer type={type} englishSelected={i18n.language === 'en'} {...restProps}>
			<AlertsCardTitle>{capitalize(t('AlertTypeKey', { type: t(type) }))}</AlertsCardTitle>
			<AlertsCardNumber>{children}</AlertsCardNumber>
		</AlertsCardContainer>
	);
};
