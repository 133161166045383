import { Col, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { CollapsibleDataSources } from '../containers';
import { WaiTimeSeriesChart } from '../containers/wai-time-series';
import { useTranslation } from 'react-i18next';
import { dateToQuery } from '../utils/date-to-query';
import { mostRecentDate } from '../utils/most-recent-date';
import { useQueries } from '@tanstack/react-query';
import chartsService from '../services/charts.service';
import monitoringService from '../services/monitoring.service';
import { DataSources, PressureUnit } from '../types';
import { upperSnakeToCapitalized } from '../utils/upper-snake-to-capitalized';
import { FlowRateUnit } from '../types/flow-rate-unit';
import { NavigationContext } from '../contexts/navigation.context';

export const AdvancedAnalysisPage: React.FC<any> = () => {
	const { t } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { measurementPoints } = navigationContext;

	const [checkboxSelected, setCheckboxSelected] = useState<any>([]);
	const [chartTimeSpan, setChartTimeSpan] = useState<{ start: string; end: string }>({
		start: dateToQuery(dayjs().subtract(7, 'day')),
		end: dateToQuery(dayjs())
	});
	// Last data-sources readings
	const dataSourcesLastReadings = useQueries<any>({
		queries: checkboxSelected?.map((dataSourceId: any) => {
			return {
				queryKey: ['data-source-last-log-date', dataSourceId],
				queryFn: () => chartsService.getDataSourceLastLogDate(dataSourceId),
				enabled: !!checkboxSelected
			};
		})
	});

	// Loading all data for a data source related to chartTimeSpan
	const dataSources = useQueries<any>({
		queries: checkboxSelected?.map((dataSourceId: any) => {
			return {
				queryKey: ['data-source', chartTimeSpan, dataSourceId],
				queryFn: () =>
					Promise.all([
						monitoringService.getDataSource(dataSourceId),
						chartsService.getDSLogs(dataSourceId, {
							start: chartTimeSpan?.start,
							end: chartTimeSpan?.end,
							aggregate: dayjs(chartTimeSpan?.end).diff(chartTimeSpan?.start, 'd') === 0 ? undefined : 1
						}),
						chartsService.getDataSourceLastLogDate(dataSourceId)
					]),
				enabled: !!checkboxSelected
			};
		})
	});
	return (
		<>
			<div
				style={{
					paddingLeft: 0,
					paddingRight: 0,
					paddingTop: '2rem'
				}}
			>
				<Row gutter={30}>
					<Col xs={24} lg={24} xl={6} style={{ overflowY: 'auto', height: '89vh' }} className={'mb-2'}>
						<CollapsibleDataSources
							checkboxSelected={checkboxSelected}
							setCheckboxSelected={setCheckboxSelected}
						/>
					</Col>
					<Col xs={24} lg={24} xl={18}>
						<Spin spinning={dataSources.some(pressure => pressure.isLoading && pressure.isFetching)}>
							<WaiTimeSeriesChart
								IDs={
									dataSources.every(dataSource => dataSource.data !== undefined) &&
									dataSources.map((dataSource: any, idx: number) => {
										const measurementPoint =
											measurementPoints &&
											measurementPoints.find(
												point => point.id === dataSource.data[0].measurement_point
											);

										return {
											id: dataSource.data[0]?.id,
											name:
												measurementPoint &&
												`${
													dataSource.data[0].type === DataSources.PRESSURE
														? upperSnakeToCapitalized(dataSource.data[0].stream_direction)
														: ''
												} ${upperSnakeToCapitalized(dataSource.data[0].type)} ${
													measurementPoint.name_en || measurementPoint.id
												}`
										};
									})
								}
								flags={{ aggregate: true }}
								title={t('AdvancedAnalysis')}
								height={50}
								graphHeight={650}
								dataObjects={
									dataSources.every(dataSource => dataSource.data !== undefined) &&
									dataSources.map((dataSource: any, idx: number) => {
										const measurementPoint =
											measurementPoints &&
											measurementPoints.find(
												point => point.id === dataSource.data[0].measurement_point
											);
										return {
											id: dataSource.data[0]?.id,
											name:
												measurementPoint &&
												`${
													dataSource.data[0].type === DataSources.PRESSURE
														? upperSnakeToCapitalized(dataSource.data[0].stream_direction)
														: ''
												} ${upperSnakeToCapitalized(dataSource.data[0].type)} ${
													measurementPoint.name_en || measurementPoint.id
												}`,
											data: dataSource.data[1],
											allData: [],
											unit:
												dataSource.data[0].type === DataSources.PRESSURE
													? PressureUnit.BAR
													: dataSource.data[0].type === DataSources.FLOW_RATE
													? FlowRateUnit.CMH
													: undefined,
											yAxis:
												dataSources.every(
													dataSourceData => dataSourceData.data !== undefined
												) &&
												(dataSource.data[0].type === DataSources.PRESSURE
													? mostRecentDate([
															...dataSources.map(
																dataSourceData =>
																	dataSourceData.data[0].type ===
																		DataSources.PRESSURE &&
																	dataSourceData.data[2].time
															)
													  ]) === dataSource.data[2]?.time && {
															position: 'left',
															legend: t('PressureInBAR')
													  }
													: dataSource.data[0].type === DataSources.FLOW_RATE &&
													  mostRecentDate([
															...dataSources.map(
																dataSourceData =>
																	dataSourceData.data[0].type ===
																		DataSources.FLOW_RATE &&
																	dataSourceData.data[2].time
															)
													  ]) === dataSource.data[2]?.time && {
															position: 'right',
															legend: t('FlowRateInCMH')
													  })
										};
									})
								}
								lastReadingDate={mostRecentDate(
									dataSourcesLastReadings?.map((item: any) => item.data?.time)
								)}
								showTabular
								showCustomSpanSelector
								showSpanSelector
								onSpanSelect={(span: any) => setChartTimeSpan(span)}
								noDataText={t('NoAvailableDataPleaseSelectDataSource')}
							/>
						</Spin>
					</Col>
				</Row>
			</div>
		</>
	);
};
