export enum SortDirection {
	ASCEND = 'ascend',
	DESCEND = 'descend'
}

export const sortDirectionMapper = {
	[SortDirection.ASCEND]: 1,
	[SortDirection.DESCEND]: -1
};
export const formatSorter = (tableSorter: any) => {
	const sorterObject: any = {};

	if (Array.isArray(tableSorter)) {
		tableSorter.forEach(tableSorter => {
			sorterObject[tableSorter.field] = sortDirectionMapper[tableSorter.order as SortDirection];
		});
	} else sorterObject[tableSorter.field] = sortDirectionMapper[tableSorter.order as SortDirection];
	return sorterObject;
};
