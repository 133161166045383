import configProxy from '../config/config';
import ResourceManager from '../network/resource-manager';
import jwt_decode from 'jwt-decode';

class AuthService extends ResourceManager {
	getAccessToken(): string | undefined {
		return localStorage.getItem('wai_access_token') || undefined;
	}
	setAccessToken(token: string): void {
		localStorage.setItem('wai_access_token', token);
	}
	isAuthenticated() {
		const token = this.getAccessToken();
		try {
			const decodedToken = jwt_decode<any>(token || '');
			const isTokenExpired = new Date().getTime() > (decodedToken.exp as number) * 1000;
			return { authenticated: !isTokenExpired, token };
		} catch (error) {
			return { authenticated: false, token };
		}
	}
	async signin(username: string, password: string) {
		const fd = new FormData();
		fd.append('username', username);
		fd.append('password', password);
		return await this.postResource<any, any>(configProxy.URIs.signin_uri, fd);
	}
}

export default new AuthService();
