import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { upperSnakeToCapitalized } from '../../utils';
import { DescriptionCard } from '../description-card';

export const CommercialMeterStaticInformation: React.FC<
	HTMLAttributes<HTMLDivElement> & { commercialMeterGroup: any }
> = ({ commercialMeterGroup }) => {
	const { t, i18n } = useTranslation();
	const [items, setItems] = useState<{ label: string; value: string }[]>([{ label: '', value: '' }]);

	useEffect(() => {
		if (commercialMeterGroup) {
			const info: any = [
				{
					label: t('Type'),
					value: upperSnakeToCapitalized(commercialMeterGroup.type)
				},
				{
					label: t('Active'),
					value: commercialMeterGroup.is_active ? (
						<AiFillCheckCircle className="text-success" />
					) : (
						<AiFillCloseCircle className="text-danger" />
					)
				}
			];

			setItems(info);
		}
	}, [commercialMeterGroup]);

	return commercialMeterGroup ? (
		<DescriptionCard
			className="mb-3"
			title={
				i18n.language === 'en'
					? commercialMeterGroup.name_en
					: commercialMeterGroup?.name_ar || commercialMeterGroup.name_en
			}
			items={items}
		/>
	) : null;
};
