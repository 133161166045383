import { clearArrayUndefined } from './clean-array-undefined';

export const mostRecentDate = (dates: any[]) => {
	if (!dates) return;
	const cleanDates = clearArrayUndefined(dates);
	let mostRecent = cleanDates[0],
		mostRecentObj = new Date(cleanDates[0]);
	cleanDates.forEach(function (dt, index) {
		if (new Date(dt) > mostRecentObj) {
			mostRecent = dt;
			mostRecentObj = new Date(dt);
		}
	});
	return mostRecent;
};
