import { Col, Row, Spin, Tabs } from 'antd';
import { useContext, useEffect } from 'react';
import { AnalysisTab } from '../components';
import configProxy from '../config/config';
import { colors } from '../constants/colors';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import { useMedia } from '../hooks/use-media.hook';
import monitoringService from '../services/monitoring.service';
import { MonitoringMap } from './map';
import { FiCornerDownRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useQueryParam } from '../hooks/use-query';
import { useQuery } from '@tanstack/react-query';
import { TiFlowSwitch } from 'react-icons/ti';
import { CommercialMeterAnalysisVirtualTab } from './commercial-meter-group-analysis-virtual-tab';
import { CommercialMeterStaticInformation } from '../components/commercial-meter-static-info';
import { MdOutlineDvr } from 'react-icons/md';
import { CommercialMeterGroupAnalysisCumulativeReadingsTab } from './commercial-meter-group-analysis-cumulative-readings-tab';

export const CommercialMeterGroupAnalysis: React.FC<any> = () => {
	const { t, i18n } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { networks, selectedNetwork, zones, onZoneSelect, selectedCommercialMeterGroup } = navigationContext;

	const media = configProxy.defaultExpandScreenSize;
	const desktopView = useMedia(media);
	const selectedTab = useQueryParam('selectedTab');

	// on change commercial meter group change the search params to the selected one
	useEffect(() => {
		if (selectedCommercialMeterGroup && zones?.length > 0) {
			onZoneSelect(selectedCommercialMeterGroup);
		}
	}, [selectedCommercialMeterGroup, zones]);

	// loading data for selected commercial meter group
	const { data: commercialMeterGroupData, isLoading: loadingCommercialMeterGroupData } = useQuery({
		queryKey: ['commercial-meter-group', selectedCommercialMeterGroup],
		queryFn: () => monitoringService.getCommercialMeterGroup(selectedCommercialMeterGroup),
		enabled: !!selectedCommercialMeterGroup
	});

	const tabItems = [
		{
			label: <AnalysisTab icon={<TiFlowSwitch size={20} />} title={t('VirtualCommercialMeterGroup')} />,
			key: 'virtual_commercial_meter',
			children: <CommercialMeterAnalysisVirtualTab sourceName={commercialMeterGroupData?.name_en} />
		},
		{
			label: <AnalysisTab icon={<MdOutlineDvr size={20} />} title={t('CumulativeReadings')} />,
			key: 'commercial-meter-group-cumulative-readings',
			children: <CommercialMeterGroupAnalysisCumulativeReadingsTab />
		}
	];

	return (
		<Spin spinning={!!!selectedCommercialMeterGroup || loadingCommercialMeterGroupData}>
			{commercialMeterGroupData && <h3 className="mb-3">{t('CommercialMeterGroupAnalysis')}</h3>}
			<Col xs={24} xl={12} xxl={14}>
				<CommercialMeterStaticInformation commercialMeterGroup={commercialMeterGroupData} />
			</Col>
			{desktopView ? null : (
				<Row>
					<Col xs={24} xl={12} xxl={10} className="mb-3">
						<MonitoringMap
							style={{
								height: '590px',
								width: 'auto',
								borderRadius: '13px',
								zIndex: 50
							}}
							networks={networks}
							zones={zones}
							selectedNetwork={selectedNetwork}
							selectedZone={selectedCommercialMeterGroup}
						/>
					</Col>
				</Row>
			)}
			<Row gutter={24}>
				<Col xs={24} xl={12} xxl={14}>
					<Row>
						<Tabs
							defaultActiveKey={selectedTab || undefined}
							type="card"
							style={{ fontWeight: '500' }}
							className={'w-100'}
							items={tabItems}
							tabBarExtraContent={
								<Link
									to={`/dashboard/advanced-analysis`}
									media={media}
									style={{ color: colors.GRAPH_PURPLE, fontWeight: 'bold' }}
								>
									<FiCornerDownRight
										style={i18n.language === 'en' ? {} : { transform: 'scaleX(-1)' }}
										className="me-2"
									/>

									{t('goToPlace', { place: t('AdvancedAnalysis') })}
								</Link>
							}
						/>
					</Row>
				</Col>
				<Col xs={24} xl={12} xxl={10}>
					{desktopView ? (
						<MonitoringMap
							style={{
								height: '590px',
								width: 'auto',
								borderRadius: '13px',
								zIndex: 50
							}}
							networks={networks}
							selectedNetwork={selectedNetwork}
							zones={zones}
							selectedZone={selectedCommercialMeterGroup}
							selectedMeasurementPoint={undefined}
						/>
					) : null}
				</Col>
			</Row>
		</Spin>
	);
};
