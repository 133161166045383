import React, { HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { ScreenWidths } from '../../../hooks/use-media.hook';

const BackdropDiv = styled.div<{ backgroundColor?: string; media: keyof typeof ScreenWidths }>`
	position: fixed;
	top: 0;
	left: 0;
	background-color: ${({ backgroundColor }) => backgroundColor || colors.BLACK_RGBA5};
	width: 100vw;
	height: 100vh;
	z-index: 300;
	@media (min-width: ${({ media }) => ScreenWidths[media]}) {
		display: none;
	}
`;

interface IBackdrop {
	clicked: () => void;
	media: keyof typeof ScreenWidths;
	show: boolean;
	backgroundColor?: string;
}

export const Backdrop: React.FC<HTMLAttributes<HTMLDivElement> & IBackdrop> = ({
	clicked,
	media,
	show,
	backgroundColor
}) => {
	return show
		? ReactDOM.createPortal(
				<BackdropDiv media={media} onClick={clicked} backgroundColor={backgroundColor}></BackdropDiv>,
				document.getElementById('root')!
		  )
		: null;
};
