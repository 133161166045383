import { Col, Row } from 'antd';
import { HTMLAttributes, useContext } from 'react';
import styled from 'styled-components';
import { ComponentGuard, Premium, WaiSelector } from '../components';
import { colors } from '../constants/colors';
import { BrandingLabelContext } from '../contexts/branding-label';
import { useTranslation } from 'react-i18next';

const NetworkNavigatorContainer = styled.div<{ label: any; visible?: boolean }>`
	// background-image: linear-gradient(180deg,  ${({ label }) => label.sliderColor}  0%, ${({ label }) =>
		label.sliderImgBGColor} 100%);
	border-radius: 15px;
	padding: 15px;
	display: flex;
	margin-top: 1rem;
	flex-direction: column;
	width: 220px;
`;

export const NetworkNavigator: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		networks?: any[];
		zones?: any[];
		measurementPoints?: any[];
		selectedNetwork?: any;
		selectedZone?: any;
		selectedMeasurementPoint?: any;
		onNetworkSelect?: (networkId: string) => void;
		onZoneSelect?: (zoneId: string) => void;
		onMeasurementPointSelect?: (selectedMeasurementPoints: string) => void;
	}
> = ({
	networks,
	zones,
	selectedNetwork,
	measurementPoints,
	selectedZone,
	onNetworkSelect,
	onZoneSelect,
	onMeasurementPointSelect,
	selectedMeasurementPoint,
	...restProps
}) => {
	const { t, i18n } = useTranslation();

	const handleNetworkSelect = (id: any) => {
		onNetworkSelect && onNetworkSelect(id);
	};
	const handleZoneSelect = (id: any) => {
		onZoneSelect && onZoneSelect(id);
	};
	const handleMeasurementPointSelect = (id: any) => {
		onMeasurementPointSelect && onMeasurementPointSelect(id);
	};
	const labelContext = useContext(BrandingLabelContext);
	const { label } = labelContext;

	// zone sidebar enabled with premium
	const zoneSidebar = (
		<div style={{ width: '90%' }}>
			<label
				style={{
					fontSize: '0.8rem',
					fontWeight: 500,
					color: colors.DARK_GREY,
					marginBottom: '5px',
					width: '90%'
				}}
				htmlFor="zone_selector"
			>
				{t('Zone')}
			</label>
		</div>
	);

	// measurement point sidebar enabled with premium
	const MeasurementPointSidebar = (
		<label
			style={{ fontSize: '0.8rem', fontWeight: 500, color: colors.DARK_GREY, marginBottom: '5px' }}
			htmlFor="hydraulic_point_selector"
		>
			{t('HydraulicPoint')}
		</label>
	);

	// // for testing
	// zones = [];
	// measurementPoints = [];
	return (
		<NetworkNavigatorContainer label={label} {...restProps}>
			<Row style={{ marginBottom: '7rem' }} id="network-area">
				<label
					style={{ fontSize: '0.8rem', fontWeight: 500, color: colors.DARK_GREY, marginBottom: '5px' }}
					htmlFor="network_selector"
				>
					{t('Network')}
				</label>
				<WaiSelector
					label={label}
					// getPopupContainer={() => document.getElementById('network-area')!}
					getPopupContainer={(triggerNode?: HTMLElement) =>
						triggerNode ? (triggerNode.parentNode as HTMLElement) : document.body
					}
					listHeight={90}
					open
					placeholder={t('selectNetwork')}
					value={selectedNetwork}
					onChange={handleNetworkSelect}
					id="network_selector"
					popupClassName="navigation-select"
				>
					{networks && networks.length
						? networks.map(network => (
								<WaiSelector.Option value={network.id} key={network.id}>
									{i18n.language === 'en' ? network.name_en : network?.name_ar || network.name_en}{' '}
								</WaiSelector.Option>
						  ))
						: null}
				</WaiSelector>
			</Row>
			<Row style={{ marginBottom: '7rem' }} id="zone-area">
				<Col xs={24}>
					<ComponentGuard
						allowed={!!(zones && zones.length)}
						fallback={() => (
							<Premium showIcon={true} children={zoneSidebar} customStyles={{ justifyItems: 'end' }} />
						)}
					>
						{zoneSidebar}
					</ComponentGuard>
					<WaiSelector
						label={label}
						// getPopupContainer={() => document.getElementById('zone-area')!}
						getPopupContainer={(triggerNode?: HTMLElement) =>
							triggerNode ? (triggerNode.parentNode as HTMLElement) : document.body
						}
						listHeight={90}
						open
						placeholder={t('selectZone')}
						onChange={handleZoneSelect}
						value={selectedZone}
						allowClear
						id="zone_selector"
						popupClassName="navigation-select"
					>
						{zones && zones.length
							? zones.map(zone => (
									<WaiSelector.Option value={zone.id} key={zone.id}>
										{i18n.language === 'en' ? zone.name_en : zone?.name_ar || zone.name_en}
									</WaiSelector.Option>
							  ))
							: null}
					</WaiSelector>
				</Col>
			</Row>
			<Row id="mp-area">
				<Col xs={24}>
					<ComponentGuard
						allowed={!!(measurementPoints && measurementPoints.length)}
						fallback={() => (
							<Premium
								showIcon={true}
								children={MeasurementPointSidebar}
								customStyles={{ justifyItems: 'end' }}
							/>
						)}
					>
						{MeasurementPointSidebar}
					</ComponentGuard>
					<WaiSelector
						label={label}
						listHeight={90}
						// getPopupContainer={() => document.getElementById('mp-area')!}
						getPopupContainer={(triggerNode?: HTMLElement) =>
							triggerNode ? (triggerNode.parentNode as HTMLElement) : document.body
						}
						open
						placeholder={t('selectHydraulicPoint')}
						onChange={handleMeasurementPointSelect}
						value={selectedMeasurementPoint}
						allowClear
						id="hydraulic_point_selector"
						popupClassName="navigation-select"
					>
						{measurementPoints && measurementPoints.length
							? measurementPoints.map(point => (
									<WaiSelector.Option value={point.id} key={point.id}>
										{i18n.language === 'en' ? point.name_en : point?.name_ar || point.name_en}
									</WaiSelector.Option>
							  ))
							: null}
					</WaiSelector>
				</Col>
			</Row>
			{/* <WaiCalendaer /> */}
		</NetworkNavigatorContainer>
	);
};
