import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export enum TableTagType {
	SUCCESS = 'success',
	ERROR = 'error',
	GHOST = 'ghost',
	WARNING = 'warning',
	PROGRESS = 'ghost'
}

const tableTagColorMapper = {
	success: { mainColor: colors.HEALTHY_WARM, secondaryColor: colors.LIGHT_GREEN3 },
	error: { mainColor: colors.RED2, secondaryColor: colors.LIGHT_RED },
	ghost: { mainColor: colors.DARK_GREY5, secondaryColor: colors.LIGHT_GREY11 },
	warning: { mainColor: colors.YELLOW, secondaryColor: colors.YELLOW_LIGHT },
	progress: { mainColor: colors.PETROLEUM, secondaryColor: colors.LIGHT_CYAN2 }
};

export const TableTag = styled.div<{ type: TableTagType }>`
	color: ${({ type }) => tableTagColorMapper[type].mainColor};
	background-color: ${({ type }) => tableTagColorMapper[type].secondaryColor};
	border: 1px solid ${({ type }) => tableTagColorMapper[type].mainColor};
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	font-weight: 600;
`;
