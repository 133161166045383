import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const WaiReadingsContainer = styled.div`
	background-color: ${colors.WHITE};
	border-radius: 15px;
	display: flex;
	justify-content: between;
	align-items: center;
`;

export const WaiReadingsItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
`;

export const WaiReadingsIcon = styled.span`
	font-size: 2.5rem;
	color: ${colors.CYAN3};
`;

export const WaiReadingsValue = styled.h2``;

export const WaiReadingsLabel = styled.p``;
