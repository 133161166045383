import { UserSubSystemsApps } from '../types';

export const hasSubSystem = (user: any, subSystemName: UserSubSystemsApps): boolean => {
	if (user.user_data.is_superAdmin) {
		return true;
	}
	if (user?.sub_systems) {
		const sub_system = user.sub_systems.find((sub_system: any) => {
			if (sub_system.app__sub_system__displayName.toLowerCase() === subSystemName) {
				return sub_system;
			}
		});
		return !!sub_system;
	}
	return false;
};
