import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const TableControlsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const TableControlItem = styled.span`
	font-size: 1.3rem;
	padding: 0 5px;
	cursor: pointer;
`;
