import { useQuery } from '@tanstack/react-query';
import { Button, Spin, Table, TableColumnsType } from 'antd';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { IFoundationDto } from '../../dtos';
import userManagementService from '../../services/user-management.service';
import { UserApps } from '../../types';
import { upperSnakeToCapitalized } from '../../utils';
import { notifySuccess } from '../../utils/notification-messages';
import { ComponentGuard } from '../component-guard';
import { TableControls } from '../table-controls';
import { modalConfirm } from '../modal-confirm';

export const ApplicationsTable: React.FC<any> = () => {
	const { t } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;
	const navigate = useNavigate();

	// get all applications
	const {
		data: applications,
		isLoading: loadingApplications,
		refetch: refetchApplications
	} = useQuery({
		queryKey: ['applications'],
		queryFn: () => userManagementService.getApplications()
	});

	const tableColumns: TableColumnsType<IFoundationDto> = [
		{
			title: t('Application'),
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			render: (text: string) => upperSnakeToCapitalized(text)
		}
	];
	tableColumns.push({
		title: t('Actions'),
		key: 'actions',
		align: 'center',
		render: (_: any, record: any) => (
			<TableControls
				onEdit={() =>
					navigate(
						`${AppRoutes.APPLICATION_CONFIGURATION}?subsystemId=${record.sub_system.id}&applicationId=${record.id}`
					)
				}
				onRemove={() => onRemove(record.id)}
				userApp={UserApps.NETWORK}
			/>
		)
	});
	const onRemove = (id: string) => {
		try {
			modalConfirm({
				content: t('YouAreAboutToDeleteApplication'),
				onOk: async () => {
					await userManagementService.deleteApplication(id);
					notifySuccess(t('RemovedSuccessfully'));
					refetchApplications();
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	return (
		<>
			<Spin spinning={loadingApplications}>
				<Table
					tableLayout="auto"
					scroll={{ x: true }}
					columns={tableColumns}
					dataSource={applications ?? undefined}
					size="small"
					showSorterTooltip
					rowKey="id"
				/>
			</Spin>
			<ComponentGuard allowed={true}>
				<div className="d-flex justify-content-end mt-3">
					<Link to={AppRoutes.APPLICATION_CONFIGURATION}>
						<Button type="primary">{t('addApplication')}</Button>
					</Link>
				</div>
			</ComponentGuard>
		</>
	);
};
