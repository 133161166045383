import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { Brush, CartesianGrid, ComposedChart, Line, LineChart, ResponsiveContainer, YAxis } from 'recharts';
import { GraphCard } from '../components';
import { colors } from '../constants/colors';
import { useTranslation } from 'react-i18next';
import { ChartCustomSpan, TimeStep, TimeStepMapper } from '../types';
import { clearArrayUndefined } from '../utils/clean-array-undefined';
import { dateToQuery } from '../utils/date-to-query';
import { debounce } from '../utils/debounce';
import { mergeLogs } from '../utils/merge-logs';
const COLORS = [
	colors.GRAPH_PURPLE,
	colors.GRAPH_GREEN,
	colors.WARNING,
	colors.HEALTHY,
	colors.ERROR,
	colors.GRAPH_RED,
	colors.GRAPH_CYAN
];
const getOptions: ({}: any) => ApexOptions = ({ yAxis = [], t = {}, noDataText = '', labels, series }) => {
	return {
		chart: {
			type: 'line',
			stacked: true,
			zoom: {
				type: 'x',
				enabled: true,
				autoScaleYaxis: false
			},
			toolbar: {
				tools: { reset: true, zoom: true },
				autoSelected: 'zoom',
				export: {
					csv: {
						dateFormatter(timestamp?) {
							return `"${dayjs(timestamp).format('dddd, MMMM D, YYYY h:mm A')}"`;
						}
					}
				}
			}
		},
		dataLabels: {
			enabled: false
		},
		colors: COLORS,
		labels,
		stroke: { width: 2, curve: 'smooth' },
		markers: {
			size: 0
		},
		noData: { text: noDataText ? noDataText : t('NoAvailableDataDuringThisTimeSpan', { ns: 'validation' }) },
		xaxis: {
			type: 'datetime',
			tooltip: { enabled: false },
			labels: {
				datetimeUTC: false
			}
		},
		yaxis: yAxis.length
			? yAxis.map(
					(axis: any): ApexYAxis => ({
						labels: {
							formatter: function (val: number) {
								return val?.toFixed(3) ?? null;
							}
						},
						title: {
							text: axis.legend
						},
						opposite: axis.position === 'right',
						tickAmount: 5
					})
			  )
			: {
					labels: {
						show: false
					}
			  },
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '50%'
			}
		},
		tooltip: {
			shared: true,
			custom(options) {
				const dataPointIndex = options.dataPointIndex;
				const seriesIndex = options.seriesIndex;
				const chartColors = options.w.config.colors;
				// sort all points for selected time according to max value
				const selectedTime = series[seriesIndex].data[dataPointIndex]?.x;
				const filteredSeries = series.filter(s => s.data.some(d => d.x === selectedTime));
				const sortedSeries = filteredSeries.sort((a, b) => {
					const aPoint = a.data.find(d => d.x === selectedTime);
					const bPoint = b.data.find(d => d.x === selectedTime);
					const aY = aPoint?.y ?? 0;
					const bY = bPoint?.y ?? 0;
					return bY - aY;
				});
				return (
					'<div style="display:flex;flex-direction:column; ">' +
					`<div style="margin-bottom:5px;background-color:${colors.LIGHT_GREY2}; padding:8px;5px;">` +
					dayjs(series[seriesIndex].dataToolTip[dataPointIndex]?.x).format('dddd, MMMM D') +
					'</div>' +
					'<div style=padding:10px;>' +
					sortedSeries
						.map((d, idx) => {
							const point = d.dataToolTip.find(
								p => p.x === series[seriesIndex].dataToolTip[dataPointIndex]?.x
							);
							const unit = point?.unit ?? '';
							const originalIdx = series.findIndex(s => s.name === d.name); // to get the original color of each point
							return `<div style="display:flex; align-items:center;margin-bottom:5px;" >
								${
									point
										? `<div style="background-color:${
												chartColors[originalIdx]
										  }; width:12px;height:12px;border-radius:50%;margin-right:10px;"></div>
										  ${d.name}:  ${(point?.y && point?.y?.toFixed(3)) ?? '-'}  ${point?.y ? unit : ''}`
										: ''
								}

						</div>`;
						})
						.join(' ') +
					`<div style="display:flex;align-items:center;margin-top:30px;">
					</div>` +
					'</div>' +
					'</div>'
				);
			}
		}
	};
};

export const TimeSeriesMixed: React.FC<any> = ({
	dataObjects,
	showSpanSelector,
	showUnitSelector,
	showTabular,
	chartTableDataSource,
	showCustomSpanSelector,
	showCustomSelectorDate,
	height,
	showBrush,
	title,
	graphHeight,
	statistics,
	onSpanSelect,
	lastReadingDate,
	minMax,
	showAverageLine,
	showStDevLine,
	OnDateSelect,
	customTimeUnits,
	spanType,
	sourceName,
	showTimeStep,
	customRange,
	...restProps
}) => {
	const { t, i18n } = useTranslation();
	const [series, setSeries] = useState<any>([]);
	const [labels, setLabels] = useState<number[]>([]);
	const [yAxis, setYAxis] = useState<any>();
	const [cleanDataObjects, setCleanDataObjects] = useState<any>([]);
	const [brushData, setbrushData] = useState<any>([]);
	const [brushState, setBrushState] = useState<{ startIndex: number; endIndex: number }>();
	const [displayDate, setDisplayDate] = useState<any>();
	const [displayTimeSpan, setDisplayTimeSpan] = useState<{ start: any; end: any }>();
	const [timeStep, setTimeStep] = useState<TimeStep | undefined>(undefined);
	const [firstDate, setFirstDate] = useState<any>();
	const [lastDate, setLastDate] = useState<any>();
	const [customBrushSpan, setCustomBrushSpan] = useState<boolean>(false);

	useEffect(() => {
		if (dataObjects && dataObjects.length) {
			setCleanDataObjects(dataObjects.filter((d: any) => !!d.id));
		}
	}, [dataObjects, dataObjects?.length]);

	useEffect(() => {
		const seriesData: any = [];
		const labels: number[] = [];

		cleanDataObjects.forEach((dataObject: any, idx: number) => {
			if (dataObject.data?.length) {
				seriesData.push({
					name: dataObject.name,
					type: dataObject?.type ?? 'line',
					data: dataObject.data.map((d: any) => {
						labels.push(dayjs(d.time).toDate().getTime());
						return d.value ? +d.value : null;
					}),
					dataToolTip: dataObject.data.map((d: any) => {
						labels.push(dayjs(d.time).toDate().getTime());
						return {
							x: dayjs(d.time).toDate().getTime(),
							y: d.value ? +d.value : null,
							unit: dataObject.unit
						};
					})
				});
			}
		});

		setLabels(labels);
		setSeries(clearArrayUndefined(seriesData));
	}, [cleanDataObjects, cleanDataObjects?.length, dataObjects]);

	useEffect(() => {
		setYAxis(
			clearArrayUndefined(
				cleanDataObjects.map((dataObject: any) => {
					if (dataObject.data?.length && dataObject?.yAxis?.position) return dataObject.yAxis;
				})
			)
		);
	}, [cleanDataObjects, cleanDataObjects?.length]);

	useEffect(() => {
		if (cleanDataObjects?.length) {
			const allDataObjects = cleanDataObjects.map((ob: any) => {
				return { ...ob, data: ob.allData };
			});
			const allData = mergeLogs(allDataObjects);
			const data = mergeLogs(cleanDataObjects);
			setbrushData(allData);

			let dataStartIndex = 0,
				dataEndIndex = 8;

			if (data.length) dataStartIndex = allData.findIndex(d => dayjs(d.time).isSame(dayjs(data[0].time)));

			allData.forEach((d, idx) => {
				if (data.length && dayjs(d.time).isSame(dayjs(data[data.length - 1].time))) dataEndIndex = idx;
			});

			setBrushState({ startIndex: dataStartIndex, endIndex: dataEndIndex });
			let start: Date | null = null;
			let end: Date | null = null;
			for (const obj of cleanDataObjects) {
				const data = obj?.data;
				const objStart: Date = data[0]?.time;
				const objEnd: Date = data[data.length - 1]?.time;

				if (!start || objStart < start) {
					start = objStart;
				}

				if (!end || objEnd > end) {
					end = objEnd;
				}
			}
			setFirstDate(start);
			setLastDate(end);
		}
	}, [cleanDataObjects, cleanDataObjects?.length]);

	useEffect(() => {
		if (customTimeUnits && customTimeUnits?.length) {
			setTimeStep(TimeStepMapper[customTimeUnits[0]]);
		}
	}, [customTimeUnits]);

	return (
		<GraphCard
			className="mb-4"
			title={title || t('Analysis')}
			height={height}
			showSpanSelector={showSpanSelector}
			showUnitSelector={showUnitSelector}
			onSpanSelect={onSpanSelect}
			showTabular={showTabular}
			dataObjects={cleanDataObjects}
			actualDataTimeSpan={
				cleanDataObjects &&
				cleanDataObjects?.length &&
				cleanDataObjects[0] &&
				cleanDataObjects[0]?.data &&
				cleanDataObjects[0]?.data?.length
					? ({
							start: firstDate && firstDate,
							end: lastDate && lastDate
					  } as ChartCustomSpan)
					: ({
							start:
								displayTimeSpan && displayTimeSpan?.start
									? dateToQuery(dayjs(displayTimeSpan?.start))
									: lastReadingDate && dateToQuery(dayjs(lastReadingDate)),
							end:
								displayTimeSpan && displayTimeSpan?.end
									? dateToQuery(dayjs(displayTimeSpan?.end))
									: lastReadingDate && dateToQuery(dayjs(lastReadingDate))
					  } as ChartCustomSpan)
			}
			chartTableDataSource={chartTableDataSource}
			showCustomSpanSelector={showCustomSpanSelector}
			showCustomSelectorDate={showCustomSelectorDate}
			statistics={statistics}
			lastReadingDate={lastReadingDate}
			setDisplayDate={setDisplayDate}
			displayDate={displayDate}
			setDisplayTimeSpan={setDisplayTimeSpan}
			displayTimeSpan={displayTimeSpan}
			customTimeUnits={customTimeUnits}
			spanType={spanType}
			setTimeStep={setTimeStep}
			sourceName={sourceName}
			showTimeStep={showTimeStep}
			customBrushSpan={customBrushSpan}
			setCustomBrushSpan={setCustomBrushSpan}
			customRange={customRange}
			{...restProps}
		>
			<div style={{ height: '100%', width: '100%' }} dir="ltr" className="d-flex flex-column pe-2">
				{timeStep && timeStep?.length && !customRange ? (
					<>
						<div className="d-flex  align-items-center ">
							<AiOutlineLeft
								role={'button'}
								size={'2rem'}
								onClick={() => {
									if (showCustomSelectorDate) {
										OnDateSelect &&
											OnDateSelect(
												displayDate
													? dayjs(displayDate).subtract(1, timeStep).format('YYYY-MM-DD')
													: lastReadingDate &&
															dayjs(lastReadingDate)
																.subtract(1, timeStep)
																.format('YYYY-MM-DD')
											);

										setDisplayDate &&
											setDisplayDate(
												displayDate
													? dayjs(displayDate).subtract(1, timeStep).format('YYYY-MM-DD')
													: lastReadingDate &&
															dayjs(lastReadingDate)
																.subtract(1, timeStep)
																.format('YYYY-MM-DD')
											);
									} else {
										setDisplayTimeSpan &&
											setDisplayTimeSpan({
												start: displayTimeSpan?.start
													? dayjs(displayTimeSpan?.start)
															.subtract(1, timeStep)
															.startOf(timeStep)
															.format('YYYY-MM-DD')
													: lastReadingDate &&
													  dayjs(lastReadingDate)
															.subtract(1, timeStep)
															.startOf(timeStep)
															.format('YYYY-MM-DD'),
												end: displayTimeSpan?.start
													? dayjs(displayTimeSpan?.start)
															.subtract(1, timeStep)
															.endOf(timeStep)
															.format('YYYY-MM-DD')
													: lastReadingDate &&
													  dayjs(lastReadingDate)
															.subtract(1, timeStep)
															.endOf(timeStep)
															.format('YYYY-MM-DD')
											});
										onSpanSelect &&
											onSpanSelect({
												start:
													displayTimeSpan && displayTimeSpan?.start
														? dateToQuery(
																dayjs(displayTimeSpan?.start)
																	.subtract(1, timeStep)
																	.startOf(timeStep)
														  )
														: lastReadingDate &&
														  dateToQuery(
																dayjs(lastReadingDate)
																	.subtract(1, timeStep)
																	.startOf(timeStep)
														  ),
												end:
													displayTimeSpan && displayTimeSpan?.start
														? dateToQuery(
																dayjs(displayTimeSpan?.start)
																	.subtract(1, timeStep)
																	.endOf(timeStep)
														  )
														: lastReadingDate &&
														  dateToQuery(
																dayjs(lastReadingDate)
																	.subtract(1, timeStep)
																	.endOf(timeStep)
														  )
											});
									}
								}}
							/>
							<div style={{ width: '100%' }}>
								<Chart
									options={getOptions({ yAxis, t: t, labels, series })}
									type="line"
									series={series}
									height={graphHeight || '98%'}
								/>
							</div>
							<AiOutlineRight
								size={'2rem'}
								role={'button'}
								onClick={() => {
									if (showCustomSelectorDate) {
										OnDateSelect &&
											OnDateSelect(
												displayDate
													? dayjs(displayDate).add(1, timeStep).format('YYYY-MM-DD')
													: lastReadingDate &&
															dayjs(lastReadingDate).add(1, timeStep).format('YYYY-MM-DD')
											);
										setDisplayDate &&
											setDisplayDate(
												displayDate
													? dayjs(displayDate).add(1, timeStep).format('YYYY-MM-DD')
													: lastReadingDate &&
															dayjs(lastReadingDate).add(1, timeStep).format('YYYY-MM-DD')
											);
									} else {
										setDisplayTimeSpan &&
											setDisplayTimeSpan({
												start: displayTimeSpan?.start
													? dayjs(displayTimeSpan?.start)
															.add(1, timeStep)
															.startOf(timeStep)
															.format('YYYY-MM-DD')
													: lastReadingDate &&
													  dayjs(lastReadingDate)
															.add(1, timeStep)
															.startOf(timeStep)
															.format('YYYY-MM-DD'),
												end: displayTimeSpan?.start
													? dayjs(displayTimeSpan?.start)
															.add(1, timeStep)
															.endOf(timeStep)
															.format('YYYY-MM-DD')
													: lastReadingDate &&
													  dayjs(lastReadingDate)
															.add(1, timeStep)
															.endOf(timeStep)
															.format('YYYY-MM-DD')
											});
										onSpanSelect &&
											onSpanSelect({
												start:
													displayTimeSpan && displayTimeSpan?.start
														? dateToQuery(
																dayjs(displayTimeSpan?.start)
																	.add(1, timeStep)
																	.startOf(timeStep)
														  )
														: lastReadingDate &&
														  dateToQuery(
																dayjs(lastReadingDate)
																	.add(1, timeStep)
																	.startOf(timeStep)
														  ),
												end:
													displayTimeSpan && displayTimeSpan?.start
														? dateToQuery(
																dayjs(displayTimeSpan?.start)
																	.add(1, timeStep)
																	.endOf(timeStep)
														  )
														: lastReadingDate &&
														  dateToQuery(
																dayjs(lastReadingDate).add(1, timeStep).endOf(timeStep)
														  )
											});
									}
								}}
							/>
						</div>
					</>
				) : (
					<Chart
						options={getOptions({ yAxis, t: t, labels, series })}
						type="line"
						series={series}
						height={graphHeight || '98%'}
					/>
				)}

				<div className="mt-5">
					{showBrush && brushData?.length ? (
						<>
							<ResponsiveContainer width={i18n.language === 'en' ? '90%' : '93%'} height={100}>
								<ComposedChart
									data={brushData}
									style={i18n.language === 'en' ? { marginLeft: '5.5%' } : { direction: 'ltr' }}
								>
									<Brush
										dataKey={t('time')}
										tickFormatter={time => (time ? dayjs(time).format('DD-MMM') : '')}
										height={90}
										startIndex={brushState?.startIndex}
										endIndex={brushState?.endIndex}
										alwaysShowText
										stroke={colors.GRAPH_PURPLE}
										onChange={debounce((e: any) => {
											if (brushData?.length) {
												const startTime = brushData[e.startIndex].time;
												const endTime = brushData[e.endIndex].time;
												onSpanSelect &&
													onSpanSelect({
														start: dateToQuery(dayjs(startTime)),
														end: dateToQuery(dayjs(endTime))
													});
												setDisplayTimeSpan &&
													setDisplayTimeSpan({
														start: dayjs(startTime).format('YYYY-MM-DD'),

														end: dayjs(endTime).format('YYYY-MM-DD')
													});
												setCustomBrushSpan(true);
												setTimeStep(undefined);
											}
										}, 700)}
									>
										<LineChart>
											<CartesianGrid
												horizontal
												stroke={colors.LIGHT_GREY9}
												strokeWidth={0.5}
												strokeDasharray="3 3"
											/>
											{minMax ? (
												<YAxis hide domain={[minMax.min, minMax.max]} />
											) : (
												<YAxis hide domain={['auto', 'auto']} />
											)}
											{cleanDataObjects?.length &&
												cleanDataObjects.map((cleanDataObject: any, idx: number) => (
													<Line
														dataKey={cleanDataObject.name}
														dot={false}
														activeDot={{ r: 8 }}
														stroke={COLORS[idx % COLORS.length]}
														strokeWidth={1.2}
														key={idx}
													/>
												))}
										</LineChart>
									</Brush>
								</ComposedChart>
							</ResponsiveContainer>
							<div className="w-100 d-flex justify-content-between " dir="ltr">
								{brushData?.length ? (
									<span
										style={{ color: colors.GRAPH_PURPLE }}
										className={i18n.language === 'en' ? 'mx-3' : ''}
									>
										<small>{dayjs(brushData[0].time).format('dddd, MMMM D, YYYY h:mm A')}</small>
									</span>
								) : null}
								{brushData?.length ? (
									<span
										style={{ color: colors.GRAPH_PURPLE }}
										className={i18n.language === 'en' ? '' : 'mx-3'}
									>
										<small>
											{dayjs(brushData[brushData.length - 1].time).format(
												'dddd, MMMM D, YYYY h:mm A'
											)}
										</small>
									</span>
								) : null}
							</div>
						</>
					) : null}
				</div>
			</div>
		</GraphCard>
	);
};
