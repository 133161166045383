import { PressureUnit } from '../types/pressure-unit';

export const pressureConversionMapper = {
	[PressureUnit.BAR]: { [PressureUnit.BAR]: 1, [PressureUnit.METER]: 10.197442, [PressureUnit.PSI]: 14.5038 },
	[PressureUnit.METER]: { [PressureUnit.METER]: 1, [PressureUnit.BAR]: 0.098, [PressureUnit.PSI]: 1.42197 },
	[PressureUnit.PSI]: { [PressureUnit.PSI]: 1, [PressureUnit.BAR]: 0.0689476, [PressureUnit.METER]: 0.70324961 }
};

export const convertPressure = (data: any, unit: PressureUnit[]) => {
	return [...data].map(d => {
		d.value = +d.value * pressureConversionMapper[unit[0]][unit[1]];
		return d;
	});
};
