import axios from 'axios';
import configProxy from '../config/config';
import config from '../config/config';

const DEBUG = process.env.NODE_ENV === 'development';
const accessTokenField = config.access_token_field_name || 'wai_access_token';

export const waiApiClient = axios.create({
	baseURL: config.api_url
	// timeout: 10000
});

waiApiClient.interceptors.request.use(request => {
	if (request.headers) {
		if (localStorage.getItem(accessTokenField))
			request.headers.Authorization = `Bearer ${localStorage.getItem(accessTokenField)}`;
		request.headers.accepts = 'application/json';
	}
	return request;
});

waiApiClient.interceptors.response.use(
	response => response,
	error => {
		if (DEBUG) console.error(error);
		if (error.response && error.response.status === 401) {
			//Anuthorized
			if (error.config.url !== configProxy.URIs.signin_uri) {
				localStorage.removeItem('wai_access_token');
				window.location.reload();
			}
		}
		return Promise.reject(error.response ? error.response.data : error);
	}
);

export default axios;
