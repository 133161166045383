import { useEffect, useState } from 'react';
import { Button, Modal, Spin, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../hooks/use-query';
import { useQueries, useQuery } from '@tanstack/react-query';
import { capitalizeWords, clearArrayUndefined } from '../../utils';
import monitoringService from '../../services/monitoring.service';
import { DataSources } from '../../types';
import { AverageZonePressureProfileConfiguration } from '../average-zone-pressure-profile-configuration';

export const AverageZonePressureProfile: React.FC<any> = ({ profile }) => {
	const { t } = useTranslation();

	const [zoneDataSourcesView, setZoneDataSourcesView] = useState<any[]>([]);
	const [visibleProfileConfig, setVisibleProfileConfig] = useState<any>(false);

	const zoneId = useQueryParam('zoneId');
	const zone = useQueryParam('zone');

	// load measurement points for selected zone
	const { data: measurementPoints, isLoading: LoadingMeasurementPoints } = useQuery({
		queryKey: ['sites', zoneId ?? zone],
		queryFn: () => monitoringService.getMeasurementPointsAll({ zone: zoneId ?? zone }),
		enabled: !!zoneId ?? !!zone
	});

	// load data sources for all measurement points for selected zone
	const mpDataSources: any = useQueries<any>({
		queries: (measurementPoints || []).map((point: any) => {
			return {
				queryKey: ['data-sources', point.id],
				queryFn: () => monitoringService.getDataSources({ filters: { measurement_point: point.id } }),
				enabled: !!point.id
			};
		})
	});

	// set default values for data sources tables
	useEffect(() => {
		if (measurementPoints && mpDataSources && mpDataSources?.every(point => point.isSuccess)) {
			// get zone pressure data-sources
			const dataSourcesPressure =
				mpDataSources &&
				mpDataSources?.every(point => point.isSuccess) &&
				clearArrayUndefined(
					mpDataSources.reduce((agg: any, point: any) => {
						const pressureDataSource = point.data.find(
							(source: any) => source.type === DataSources.PRESSURE
						);
						agg = [...agg, pressureDataSource];
						return agg;
					}, [])
				);
			const dataSources: any[] = dataSourcesPressure.map(dataSource => {
				return {
					data_source: dataSource.id,
					name: measurementPoints.find(point => point.id === dataSource.measurement_point).name_en,
					factor: 0.0
				};
			});
			if (!profile) {
				// set default values for pressure data sources when creating profile
				setZoneDataSourcesView(dataSources.filter(source => source.factor > 0));
			} else if (profile) {
				const updatedZoneDataSourcesTable =
					dataSources &&
					dataSources.map(item => {
						const matchingDataSource = profile.data_sources.find(
							dataSource => dataSource.data_source === item.data_source
						);

						if (matchingDataSource) {
							return {
								...item,
								factor: parseFloat(matchingDataSource.factor)
							};
						} else {
							return item;
						}
					});
				setZoneDataSourcesView(updatedZoneDataSourcesTable.filter(source => source.factor > 0));
			}
		}
	}, [profile, measurementPoints && mpDataSources && mpDataSources?.every(point => point.isSuccess)]);

	// average zone pressure data sources table
	const pressureDataSourcesViewColumns: any = [
		{
			title: t('DataSource'),
			dataIndex: 'name',
			key: 'name',
			render: (text: string) => <>{text}</>
		},
		{
			title: t('Factor'),
			dataIndex: 'factor',
			key: 'factor',
			align: 'center',

			render: (num: number) => <>{num}</>
		}
	];

	return (
		<Spin spinning={LoadingMeasurementPoints || !(mpDataSources && mpDataSources?.every(point => point.isSuccess))}>
			{profile ? (
				<>
					<div style={{ display: 'flex', overflowX: 'auto' }}>
						{zoneDataSourcesView && zoneDataSourcesView.length > 0 && (
							<>
								{(() => {
									const rowLimit = 10;
									const chunckTables: any = [];
									for (let i = 0; i < zoneDataSourcesView.length; i += rowLimit) {
										chunckTables.push(zoneDataSourcesView.slice(i, i + rowLimit));
									}

									return chunckTables.map((table, idx) => (
										<div key={idx} style={{ flex: 1 }}>
											<Table
												key={idx}
												dataSource={table}
												columns={pressureDataSourcesViewColumns}
												pagination={false}
												rowKey="id"
												style={{ whiteSpace: 'nowrap' }}
											/>
										</div>
									));
								})()}
							</>
						)}
					</div>
					{profile.name !== 'Weighted Aggregate' ? (
						<div className="d-flex justify-content-end mt-3">
							<Button onClick={() => setVisibleProfileConfig(true)} type="primary">
								{t('Edit', { ns: 'common' })}
							</Button>
						</div>
					) : null}
				</>
			) : null}
			{visibleProfileConfig ? (
				<Modal
					open={visibleProfileConfig}
					title={capitalizeWords(t('EditProfile'))}
					onCancel={() => setVisibleProfileConfig(false)}
					footer={false}
					width={'50%'}
				>
					<AverageZonePressureProfileConfiguration
						profile={profile}
						setVisibleProfileConfig={setVisibleProfileConfig}
					/>
				</Modal>
			) : null}
		</Spin>
	);
};
