import { HTMLAttributes } from 'react';
import { GiValve } from 'react-icons/gi';
import { colors } from '../../constants/colors';
import { useTranslation } from 'react-i18next';
import { ValveStatus } from '../../types/valve';
import { upperSnakeToCapitalized } from '../../utils/upper-snake-to-capitalized';
import { WaiAnalysisWidgetContainer, WaiAnalysisWidgetTitle } from '../wai-analysis-widget';

interface WaiValveStatus {
	size?: number;
	type: ValveStatus;
}

export const WaiValveStatus: React.FC<HTMLAttributes<HTMLDivElement> & WaiValveStatus> = ({
	size = 60,
	type,
	...restProps
}) => {
	const { t } = useTranslation();

	return (
		<WaiAnalysisWidgetContainer {...restProps}>
			<WaiAnalysisWidgetTitle>{t('Status', { ns: 'common' })}</WaiAnalysisWidgetTitle>
			<div
				className="d-flex flex-column align-items-center justify-content-center"
				style={{ color: type === ValveStatus.OPEN ? colors.HEALTHY : colors.DARK_GREY4 }}
			>
				<GiValve size={size} />
				<h5 style={{ color: 'inherit', marginTop: '10px' }}>{upperSnakeToCapitalized(type)}</h5>
			</div>
		</WaiAnalysisWidgetContainer>
	);
};
