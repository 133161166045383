import styled from 'styled-components';
import { ScreenWidths } from '../../../hooks/use-media.hook';

export const NavbarContainer = styled.header<{
	media: keyof typeof ScreenWidths;
	englishSelected: boolean;
	label: any;
}>`
	height: auto;
	background-color: ${({ label }) => label.sliderColor};
	position: sticky;
	top: 0;
	${({ englishSelected }) => (englishSelected ? 'left: 0;' : 'right: 0;')};
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	transition: all 0.5s;
	padding: 20px;
	flex-wrap: wrap;
	z-index: 210;
	width: 100%;

	nav {
		height: 100%;
	}

	@media (min-width: ${({ media }) => ScreenWidths[media]}) {
		padding: ${({ englishSelected }) => (englishSelected ? '20px 20px 16px 250px;' : '20px 250px 16px 20px;')};
		position: fixed;
		flex-wrap: nowrap;
		z-index: 400;
	}
`;
