import { useQueries } from '@tanstack/react-query';
import { Checkbox, Collapse, Spin } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../contexts/navigation.context';
import monitoringService from '../services/monitoring.service';
import { DataSources } from '../types';
import { upperSnakeToCapitalized } from '../utils/upper-snake-to-capitalized';
const { Panel } = Collapse;

export const CollapsibleDataSources: React.FC<any> = ({ checkboxSelected, setCheckboxSelected }) => {
	const { i18n } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { networks } = navigationContext;
	const [networksSelected, setNetworksSelected] = useState<any>([]);
	const [zonesSelected, setZonesSelected] = useState<any>([]);
	const [pointsSelected, setPointsSelected] = useState<any>([]);

	// Loading all zones for networks
	const zonesForNetworks = useQueries<any>({
		queries: networksSelected?.map((networkId: any) => {
			return {
				queryKey: ['zones', networkId],
				queryFn: () => monitoringService.getZonesAll({ network: networkId }),
				enabled: !!networksSelected
			};
		})
	});

	// Loading all measurement points for zones
	const measurementPointsForZones = useQueries<any>({
		queries: zonesSelected?.map((zoneId: any) => {
			return {
				queryKey: ['sites', zoneId],
				queryFn: () => monitoringService.getMeasurementPointsAll({ zone: zoneId }),
				enabled: !!zonesSelected
			};
		})
	});

	// Loading all data sources for points
	const dataSourcesFoPoints = useQueries<any>({
		queries: pointsSelected?.map((pointId: any) => {
			return {
				queryKey: ['data-sources', pointId],
				queryFn: () => monitoringService.getDataSources({ filters: { measurement_point: pointId } }),
				enabled: !!pointsSelected
			};
		})
	});

	const onCheckboxSelect = async (e: any, value: any) => {
		if (e.target.checked) {
			setCheckboxSelected([...checkboxSelected, value]);
		} else {
			setCheckboxSelected(checkboxSelected.filter((item: any) => item !== value));
		}
	};
	return (
		<Spin spinning={networks ? false : true}>
			<Collapse onChange={setNetworksSelected}>
				{networks &&
					networks.map(network => {
						return (
							<Panel
								header={i18n.language === 'en' ? network.name_en : network?.name_ar || network.name_en}
								key={network.id}
							>
								<Spin
									spinning={
										zonesForNetworks &&
										zonesForNetworks.some(
											zonesNetwork => zonesNetwork.isLoading && zonesNetwork.isFetching
										) &&
										!zonesForNetworks.find(
											(zonesNetwork: any) =>
												zonesNetwork.isSuccess &&
												zonesNetwork.data &&
												zonesNetwork.data.length > 0 &&
												zonesNetwork.data[0].network === network.id
										)
									}
								>
									<Collapse
										onChange={(zones: any) => {
											zones.map(zonesNetwork => {
												if (!zonesSelected.includes(zonesNetwork)) {
													setZonesSelected([...zonesSelected, zonesNetwork]);
												}
											});
										}}
										key={network.id}
									>
										{zonesForNetworks &&
											zonesForNetworks.map(zonesNetwork => {
												if (zonesNetwork.isSuccess) {
													return zonesNetwork.data.map(zone => {
														if (zone.network === network.id) {
															return (
																<Panel
																	header={
																		i18n.language === 'en'
																			? zone.name_en
																			: zone?.name_ar || zone.name_en
																	}
																	key={zone.id}
																>
																	<Spin
																		spinning={
																			measurementPointsForZones &&
																			measurementPointsForZones.some(
																				pointsZones =>
																					pointsZones.isLoading &&
																					pointsZones.isFetching
																			) &&
																			!measurementPointsForZones.find(
																				(pointsZones: any) =>
																					pointsZones.isSuccess &&
																					pointsZones.data &&
																					pointsZones.data.length > 0 &&
																					pointsZones.data[0].zone.includes(
																						zone.id
																					)
																			)
																		}
																	>
																		<Collapse
																			key={zone.id}
																			onChange={(points: any) => {
																				points.map(pointsZone => {
																					if (
																						!pointsSelected.includes(
																							pointsZone
																						)
																					) {
																						setPointsSelected([
																							...pointsSelected,
																							pointsZone
																						]);
																					}
																				});
																			}}
																		>
																			{measurementPointsForZones &&
																				measurementPointsForZones.map(
																					pointsZone => {
																						if (pointsZone?.isSuccess) {
																							return pointsZone?.data.map(
																								point => {
																									if (
																										point.zone.includes(
																											zone.id
																										)
																									) {
																										return (
																											<Panel
																												header={
																													i18n.language ===
																													'en'
																														? point?.name_en ||
																														  point.id
																														: point?.name_ar ||
																														  point?.name_en ||
																														  point.id
																												}
																												key={
																													point.id
																												}
																											>
																												<Spin
																													spinning={
																														dataSourcesFoPoints &&
																														dataSourcesFoPoints.some(
																															srcsPoints =>
																																srcsPoints.isLoading &&
																																srcsPoints.isFetching
																														) &&
																														!dataSourcesFoPoints.find(
																															(
																																srcsPoints: any
																															) =>
																																srcsPoints.isSuccess &&
																																srcsPoints.data &&
																																srcsPoints
																																	.data
																																	.length >
																																	0 &&
																																srcsPoints
																																	.data[0]
																																	.measurement_point ===
																																	point.id
																														)
																													}
																												>
																													{dataSourcesFoPoints &&
																														dataSourcesFoPoints.map(
																															srcsPoints => {
																																if (
																																	srcsPoints.isSuccess
																																) {
																																	return srcsPoints.data.map(
																																		src => {
																																			if (
																																				src.measurement_point ===
																																					point.id &&
																																				src.type !==
																																					DataSources.NOISE
																																			) {
																																				return (
																																					<Checkbox
																																						key={
																																							src.id
																																						}
																																						onChange={e => {
																																							onCheckboxSelect(
																																								e,
																																								src.id
																																							);
																																						}}
																																						checked={checkboxSelected.find(
																																							(
																																								checked: any
																																							) =>
																																								checked ===
																																								src.id
																																						)}
																																					>
																																						{src.type ===
																																							DataSources.PRESSURE &&
																																							upperSnakeToCapitalized(
																																								src.stream_direction
																																							)}{' '}
																																						{upperSnakeToCapitalized(
																																							src.type
																																						)}
																																					</Checkbox>
																																				);
																																			}
																																		}
																																	);
																																}
																															}
																														)}
																												</Spin>
																											</Panel>
																										);
																									}
																								}
																							);
																						}
																					}
																				)}
																		</Collapse>
																	</Spin>
																</Panel>
															);
														}
													});
												}
											})}
									</Collapse>
								</Spin>
							</Panel>
						);
					})}
			</Collapse>
		</Spin>
	);
};
