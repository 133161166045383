import { Modal } from 'antd';
import { CSSProperties, useState } from 'react';
import { MdOutlineWorkspacePremium } from 'react-icons/md';
import { colors } from '../../constants/colors';
import { useTranslation } from 'react-i18next';

const PremiumModal: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
	const { t } = useTranslation();
	return (
		<Modal open={open} onCancel={onClose} footer={[]}>
			<div className="d-flex flex-column justify-content-center align-items-center">
				<MdOutlineWorkspacePremium size={200} color={colors.GRAPH_GREEN} className="mb-3" />
				<p style={{ fontSize: '1.1rem', fontWeight: 600, textAlign: 'center' }}>{t('ThisFeatureIsPremium')}</p>
			</div>
		</Modal>
	);
};

export const Premium: React.FC<{ children: React.ReactNode; showIcon: boolean; customStyles?: CSSProperties }> = ({
	children,
	showIcon = true,
	customStyles
}) => {
	const [premiumModalVisible, setPremiumModalVisible] = useState<boolean>(false);
	return (
		<>
			<span
				style={{ cursor: 'pointer' }}
				className="align-items-center"
				onClick={() => setPremiumModalVisible(true)}
			>
				<div style={{ opacity: 0.5, pointerEvents: 'none', display: 'inline-block' }}>{children}</div>
				{showIcon ? (
					<MdOutlineWorkspacePremium
						size={25}
						color={colors.GRAPH_GREEN}
						style={{ transform: 'translateY(-3px)', ...customStyles }}
					/>
				) : (
					false
				)}
			</span>
			<PremiumModal open={premiumModalVisible} onClose={() => setPremiumModalVisible(false)} />
		</>
	);
};
