import { useContext, useEffect, useState } from 'react';
import { Form, FormProps, Button, Table, InputNumber, Input, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../hooks/use-query';
import { notifySuccess } from '../../utils/notification-messages';
import { NavigationContext } from '../../contexts/navigation.context';
import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { clearArrayUndefined } from '../../utils';
import monitoringService from '../../services/monitoring.service';
import { DataSources } from '../../types';
import { modalConfirm } from '../modal-confirm';
import { SubmitCanelButtons } from '../submit-cancel-buttons';
import configService from '../../services/config.service';

export const AverageZonePressureProfileConfiguration: React.FC<
	FormProps & {
		profile?: any;
		setVisibleProfileConfig?: any;
	}
> = ({ profile, setVisibleProfileConfig }) => {
	const { t } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const { errorHandler } = navigationContext;

	const [zoneDataSourcesDefaults, setZoneDataSourcesDefaults] = useState<any[]>([]);
	const [zoneDataSourcesDefaultsLoaded, setZoneDataSourcesDefaultsLoaded] = useState<any[]>([]);
	const [zoneDataSourcesTable, setZoneDataSourcesTable] = useState<any[]>([]);
	const [editingIdDataSource, setEditingIdDataSource] = useState<string | null>();
	const [editedFactor, setEditedfactor] = useState<number>(1.0);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [isFormChanged, setIsFormChanged] = useState<boolean>(false);

	const zoneId = useQueryParam('zoneId');
	const [form] = Form.useForm();
	const queryClient = useQueryClient();

	// load measurement points for selected zone
	const { data: measurementPoints, isLoading: LoadingMeasurementPoints } = useQuery({
		queryKey: ['sites', zoneId],
		queryFn: () => monitoringService.getMeasurementPointsAll({ zone: zoneId }),
		enabled: !!zoneId
	});

	// load data sources for all measurement points for selected zone
	const mpDataSources: any = useQueries<any>({
		queries: (measurementPoints || []).map((point: any) => {
			return {
				queryKey: ['data-sources', point.id],
				queryFn: () => monitoringService.getDataSources({ filters: { measurement_point: point.id } }),
				enabled: !!point.id
			};
		})
	});

	// set default values for data sources tables
	useEffect(() => {
		// get zone pressure data-sources
		const dataSourcesPressure =
			mpDataSources &&
			mpDataSources?.every(point => point.isSuccess) &&
			clearArrayUndefined(
				mpDataSources.reduce((agg: any, point: any) => {
					const pressureDataSource = point.data.find((source: any) => source.type === DataSources.PRESSURE);
					agg = [...agg, pressureDataSource];
					return agg;
				}, [])
			);
		const dataSources: any[] = dataSourcesPressure.map(dataSource => {
			return {
				data_source: dataSource.id,
				name: measurementPoints.find(point => point.id === dataSource.measurement_point).name_en,
				factor: 0.0
			};
		});
		if (profile) {
			const updatedZoneDataSourcesTable =
				dataSources &&
				dataSources.map(item => {
					const matchingDataSource = profile.data_sources.find(
						dataSource => dataSource.data_source === item.data_source
					);

					if (matchingDataSource) {
						return {
							...item,
							factor: parseFloat(matchingDataSource.factor)
						};
					} else {
						return item;
					}
				});
			setZoneDataSourcesTable(updatedZoneDataSourcesTable);
			setZoneDataSourcesDefaultsLoaded(updatedZoneDataSourcesTable);
			form.setFieldsValue({
				...profile
			});
		} else {
			setZoneDataSourcesTable(dataSources);
			setZoneDataSourcesDefaults(dataSources);
			form.resetFields();
		}
	}, []);

	// average zone pressure data sources table
	const pressureDataSourcesColumns: any = [
		{
			title: t('DataSource'),
			dataIndex: 'name',
			key: 'name',
			render: (text: string) => <a>{text}</a>
		},
		{
			title: t('Factor'),
			dataIndex: 'factor',
			key: 'factor',
			align: 'center',

			render: (num: number, record) =>
				editingIdDataSource === record.data_source ? (
					<InputNumber min={0} step={'0.01'} value={editedFactor} onChange={(e: any) => setEditedfactor(e)} />
				) : (
					num
				)
		},
		{
			title: t('Action'),
			key: 'action',
			align: 'center',
			render: (text, record) =>
				editingIdDataSource === record.data_source ? (
					<Button type="link" onClick={handleSaveFactor}>
						{t('Submit', { ns: 'common' })}
					</Button>
				) : (
					<Button type="link" onClick={() => handleEditFactor(record)}>
						{t('Edit', { ns: 'common' })}
					</Button>
				)
		}
	];

	// edit factor for specific data source
	const handleEditFactor = record => {
		setEditingIdDataSource(record.data_source);
		setEditedfactor(record.factor);
	};

	// save the edited factor for specific data source
	const handleSaveFactor = () => {
		const newData = zoneDataSourcesTable.map(item =>
			item.data_source === editingIdDataSource ? { ...item, factor: editedFactor } : item
		);
		setZoneDataSourcesTable(newData);
		setEditingIdDataSource(null);
		setEditedfactor(1.0);
		// track changing form data from the defualts
		if (
			profile &&
			(newData && JSON.stringify(newData)) !==
				(zoneDataSourcesDefaultsLoaded && JSON.stringify(zoneDataSourcesDefaultsLoaded))
		) {
			setIsFormChanged(true);
		} else if (
			!profile &&
			(newData && JSON.stringify(newData)) !==
				(zoneDataSourcesDefaults && JSON.stringify(zoneDataSourcesDefaults))
		) {
			setIsFormChanged(true);
		} else {
			setIsFormChanged(false);
		}
	};

	// on finish zone analysis configuration edit
	const onFinish = async (values: any) => {
		setSubmitting(true);
		values.data_sources = zoneDataSourcesTable;
		try {
			if (profile) {
				const resp = zoneId && (await configService.editAvgZoneConfigProfile(zoneId, profile.id, values));
				if (!resp) throw new Error(t('couldntEditZoneAnalysisConfig', { ns: 'validation' }));
				notifySuccess(t('editedSuccessfully'));
			} else {
				const resp = zoneId && (await configService.createAvgZoneConfigProfile(zoneId, values));
				if (!resp) throw new Error(t('couldntEditZoneAnalysisConfig', { ns: 'validation' }));
				notifySuccess(t('createdSuccessfully'));
			}
			queryClient.refetchQueries(['avg-zone-pressure-config', zoneId]);
		} catch (e: any) {
			errorHandler(e);
		} finally {
			setSubmitting(false);
			setVisibleProfileConfig && setVisibleProfileConfig(false);
		}
	};

	// confirm before submition
	const onConfirm = values => {
		try {
			modalConfirm({
				onOk: async () => {
					await onFinish(values);
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	// reset fields
	const resetFields = () => {
		if (profile) {
			form.setFieldsValue({
				...profile
			});

			setZoneDataSourcesTable(zoneDataSourcesDefaultsLoaded);
		} else {
			form.resetFields();
			setZoneDataSourcesTable(zoneDataSourcesDefaults);
		}

		setIsFormChanged(false);
	};

	return (
		<Spin
			spinning={
				LoadingMeasurementPoints ||
				!(mpDataSources && mpDataSources?.every(point => point.isSuccess)) ||
				!zoneDataSourcesTable ||
				(profile ? !zoneDataSourcesDefaultsLoaded : !zoneDataSourcesDefaults)
			}
		>
			<Form form={form} layout="vertical" onFinish={onConfirm} onValuesChange={() => setIsFormChanged(true)}>
				<Form.Item
					name="name"
					label={t('Name')}
					rules={[{ required: true, message: t('PleaseAddProfileName', { ns: 'validation' }) }]}
				>
					<Input type="text" placeholder={t('Name')} />
				</Form.Item>

				<div style={{ display: 'flex', overflowX: 'auto' }}>
					{zoneDataSourcesTable && zoneDataSourcesTable.length > 0 && (
						<>
							{(() => {
								const rowLimit = 10;
								const chunckTables: any = [];
								for (let i = 0; i < zoneDataSourcesTable.length; i += rowLimit) {
									chunckTables.push(zoneDataSourcesTable.slice(i, i + rowLimit));
								}

								return chunckTables.map((table, idx) => (
									<div key={idx} style={{ flex: 1 }}>
										<Table
											key={idx}
											dataSource={table}
											columns={pressureDataSourcesColumns}
											pagination={false}
											rowKey="id"
											style={{ whiteSpace: 'nowrap' }}
										/>
									</div>
								));
							})()}
						</>
					)}
				</div>
				<Form.Item className="d-flex justify-content-end mt-3">
					<SubmitCanelButtons
						handleCancel={() => resetFields()}
						handleSubmit={form.submit}
						reset={true}
						disabled={!isFormChanged}
					/>
				</Form.Item>
			</Form>
		</Spin>
	);
};
