import { GraphSpan } from './graph-span';

export enum TimeStep {
	DAY = 'd',
	WEEK = 'w',
	MONTH = 'M',
	YEAR = 'y'
}
export const TimeStepMapper = {
	[GraphSpan.DAY]: 'd',
	[GraphSpan.WEEK]: 'w',
	[GraphSpan.MONTH]: 'M',
	[GraphSpan.YEAR]: 'y'
};
