import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Amiri } from '../fonts/Amiri/amiri';
import comapnyLogoPDF from '../assets/img/company-logo-pdf.png';
import { capitalizeWords } from './capitalize-string-words';
import { upperSnakeToCapitalized } from './upper-snake-to-capitalized';

export const downloadFilePDF = ({ data, fileName, lang, t }: any) => {
	const doc = new jsPDF();
	// Support arabic font
	if (lang !== 'en') {
		doc.addFileToVFS('Amiri-Regular.ttf', Amiri.Amiri_Regular);
		doc.addFont('Amiri-Regular.ttf', 'Amiri', 'normal');
		doc.setFont('Amiri');
	}

	// Calculate page width and center
	const pageWidth = doc.internal.pageSize.getWidth();
	const centerX = pageWidth / 2;

	function addLogo() {
		const logoWidth = 30;
		const logoHeight = 20;
		const logoX = centerX - logoWidth / 2;
		doc.addImage(comapnyLogoPDF, 'PNG', logoX, 5, logoWidth, logoHeight);
	}

	function addWaiSubtitle() {
		const subtitle = t('WaiTechsReport');
		doc.setFontSize(12);
		doc.setTextColor(100);
		const subtitleWidth = doc.getTextWidth(subtitle);
		const subtitleX = centerX - subtitleWidth / 2;
		doc.text(subtitle, subtitleX, 30);
	}

	function addTitle() {
		const title = capitalizeWords(upperSnakeToCapitalized(fileName));
		doc.setFontSize(16);
		doc.setTextColor(0);
		const titleWidth = doc.getTextWidth(title);
		const titleX = centerX - titleWidth / 2;
		doc.text(title, titleX, 40);
	}

	const keys: string[] = Object.keys(data[0]);
	const valuesArray: any[] = data
		.map(item => {
			return { ...item, time: dayjs(item.time).format('dddd, MMMM D, YYYY h:mm A') };
		})
		.map(item => Object.values(item));

	autoTable(doc, {
		head: [keys.map(key => upperSnakeToCapitalized(key))],
		body: valuesArray,
		startY: 50, // position the table below the title and logo
		didDrawPage: function (data) {
			if (data.pageCount === 1) {
				addLogo();
				addWaiSubtitle();
				addTitle();
			}
		},
		styles: {
			valign: 'middle',
			halign: 'center'
		},

		headStyles: lang === 'en' ? {} : { font: 'Amiri' },
		bodyStyles: lang === 'en' ? {} : { font: 'Amiri' }
	});
	doc.save(`${fileName}.pdf`);
};
