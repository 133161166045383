import { Col, Layout, Menu, Row, Tabs } from 'antd';
import React, { useContext, useState } from 'react';
import { FoundationsTable } from '../components/foundations-table';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../hooks/use-query';
import { UsersTable } from '../components';
import { ApplicationsTable } from '../components/applications-table';
import { SubSystemsTable } from '../components/subsystems-table';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import { AuthenticationContext } from '../contexts';

export const AdministrationPage: React.FC = () => {
	const { t } = useTranslation();
	const authContext = useContext(AuthenticationContext);
	const { user } = authContext;
	const tabSelected = useQueryParam('tabSelected');
	const subTabSelected = useQueryParam('subTabSelected');
	const [superTabSelected, setSuperTabSelected] = useState<any>(subTabSelected ?? 'foundations');

	const superAdminItems = [
		{ label: t('Foundations'), key: 'foundations', children: <FoundationsTable /> },
		{ label: t('Subsystems'), key: 'subsystems', children: <SubSystemsTable /> },
		{ label: t('Applications'), key: 'applications', children: <ApplicationsTable /> }
	];
	const tabItems = [{ label: t('Users'), key: 'users', children: <UsersTable /> }];
	if (user?.user_data?.is_superAdmin) {
		tabItems.push({
			label: t('SuperAdmin'),
			key: 'superAdmin',
			children: (
				<Layout style={{ minHeight: '80vh' }}>
					<Sider>
						<Menu
							mode="inline"
							defaultSelectedKeys={[subTabSelected ?? 'foundations']}
							defaultOpenKeys={[subTabSelected ?? 'foundations']}
							style={{ height: '100%', borderRight: 0 }}
							onClick={({ key }) => setSuperTabSelected(key)}
							items={superAdminItems?.map(item => ({ key: item.key, label: item.label }))}
						/>
					</Sider>
					<Layout>
						<Content style={{ padding: '16px' }}>
							{superAdminItems.find(
								item => item.key === (superTabSelected ? superTabSelected : 'foundations')
							)?.children || null}
						</Content>
					</Layout>
				</Layout>
			)
		});
	}
	return (
		<>
			<Row className="d-flex justify-content-center">
				<Col xs={22}>
					<Tabs centered defaultActiveKey={tabSelected ? tabSelected : 'users'} items={tabItems}></Tabs>
				</Col>
			</Row>
		</>
	);
};
