import { useQuery } from '@tanstack/react-query';
import { Button, Modal, Select, Spin, Table, TableProps } from 'antd';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { AuthenticationContext } from '../../contexts';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { transformMeasurementPointsToRecord } from '../../mappers/measurement-point-to-record';
import monitoringService from '../../services/monitoring.service';
import { UserRights, UserApps } from '../../types';
import { IMeasurementPointRecord } from '../../types/measurement-point-record';
import { formatFilters } from '../../utils/get-table-filters';
import { formatSorter } from '../../utils/get-table-sorter';
import { hasRight } from '../../utils/has-right';
import { notifySuccess } from '../../utils/notification-messages';
import { ComponentGuard } from '../component-guard';
import { TableControls } from '../table-controls';
import { modalConfirm } from '../modal-confirm';
import { Sites } from '../../types/sites';
import { upperSnakeToCapitalized } from '../../utils';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';

export const MeasurementPointsTable: React.FC<TableProps<IMeasurementPointRecord>> = () => {
	const { t, i18n } = useTranslation();
	const navigationContext = useContext(NavigationContext);
	const {
		networks,
		errorHandler,
		selectedMeasurementPoint,
		setSelectedMeasurementPoint,
		refetchNetworks,
		refetchAllZones,
		refetchZones,
		applicationIdMap
	} = navigationContext;
	const authContext = useContext(AuthenticationContext);
	const { user, configurationPermessions } = authContext;
	const navigate = useNavigate();
	const [sitesSorters, setSitesSorters] = useState<any>({});
	const [sitesFilters, setSitesFilters] = useState<any>({});
	const [visibleModal, setVisibleModal] = useState<any>(false);
	const [selectedType, setSelectedType] = useState<Sites>(Sites.NETWORK_MEASUREMENT_POINT);

	// load all sites
	const {
		data: sitesData,
		refetch: refetchSitesData,
		isLoading: loadingSitesData
	} = useQuery<any>({
		queryKey:
			(sitesSorters && Object.keys(sitesSorters)?.length) || (sitesFilters && Object.keys(sitesFilters)?.length)
				? ['sites', sitesSorters, sitesFilters]
				: ['sites'],

		queryFn: () =>
			monitoringService.getMeasurementPointsAll(
				(sitesSorters && Object.keys(sitesSorters)?.length) ||
					(sitesFilters && Object.keys(sitesFilters)?.length)
					? {
							sorters: { ...sitesSorters },
							filters: { ...sitesFilters }
					  }
					: undefined
			)
	});

	// load zones
	const { data: allZones } = useQuery<any>({
		queryKey: ['zones'],
		queryFn: () => monitoringService.getZonesAll()
	});

	// filter sites with: network, zone, is_active
	const onSitesFilter = async (filters: any) => {
		if (JSON.stringify(filters) === JSON.stringify(sitesFilters)) return;
		const filteredValues = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value !== undefined));
		if (Object.keys(filteredValues).length > 0) {
			await setSitesFilters(filteredValues);
			await refetchSitesData();
		} else {
			await setSitesFilters(undefined);
			await refetchSitesData();
		}
	};

	// sort sites with: created_at
	const onSitesSort = async (sorters: any) => {
		if (JSON.stringify(sorters) === JSON.stringify(sitesSorters)) return;
		const sortedValues = Object.fromEntries(Object.entries(sorters).filter(([_, value]) => value !== undefined));
		if (Object.keys(sortedValues).length > 0) {
			await setSitesSorters(sortedValues);
			await refetchSitesData();
		} else {
			await setSitesSorters(undefined);
			await refetchSitesData();
		}
	};

	const handleTableChange = (newPagination: any, filters: any, sorters: any) => {
		onSitesFilter && onSitesFilter(formatFilters(filters));
		onSitesSort && onSitesSort(formatSorter(sorters));
	};

	const tableColumns: any = [
		{
			title: t('MeasurementPoint'),
			dataIndex: i18n.language === 'en' ? 'name_en' : 'name_ar',
			key: i18n.language === 'en' ? 'name_en' : 'name_ar',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Active'),
			dataIndex: 'is_active',
			key: 'is_active',
			align: 'center',
			filters: [
				{ text: t('Active'), value: true },
				{ text: t('NotActive'), value: false }
			],
			filterMultiple: false,
			render: (check: boolean) =>
				check ? <AiFillCheckCircle className="text-success" /> : <AiFillCloseCircle className="text-danger" />
		},
		{
			title: t('Type'),
			dataIndex: 'type',
			key: 'type',
			align: 'center',
			filters: Object.values(Sites).map(type => ({
				text: upperSnakeToCapitalized(type),
				value: type
			})),
			filterMultiple: false,
			render: (type: Sites) => upperSnakeToCapitalized(type)
		},
		{
			title: t('RelatedZone'),
			dataIndex: 'zone',
			key: 'zone',
			align: 'center',
			filters:
				allZones &&
				allZones.map(zone => ({
					text: i18n.language === 'en' ? zone.name_en : zone.name_ar,
					value: zone.id
				})),
			filterMultiple: false,
			render: (zonesIDs: string[]) => {
				const matchedZones = allZones && allZones.filter(zone => zonesIDs && zonesIDs.includes(zone.id));
				return matchedZones.length > 0
					? matchedZones.map((zone: any) => (i18n.language === 'en' ? zone.name_en : zone.name_ar)).join(', ')
					: '';
			}
		},
		{
			title: t('RelatedNetwork'),
			dataIndex: 'network',
			key: 'network',
			align: 'center',
			filters:
				networks &&
				networks.map(network => ({
					text: i18n.language === 'en' ? network.name_en : network.name_ar,
					value: network.id
				})),
			filterMultiple: false,
			render: (networkId: string) => {
				const matchedNetwork = networks && networks.find(network => network.id === networkId);
				return matchedNetwork ? (i18n.language === 'en' ? matchedNetwork.name_en : matchedNetwork.name_ar) : '';
			}
		},
		{
			title: t('Latitude'),
			dataIndex: 'latitude',
			key: 'latitude',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Longitude'),
			dataIndex: 'longitude',
			key: 'longitude',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('DateCreated'),
			dataIndex: 'created_at',
			key: 'created_at',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: Date) => dayjs(text).format('dddd, MMMM D, YYYY h:mm A')
		}
	];

	tableColumns.push({
		title: t('Actions'),
		key: 'actions',
		align: 'center',
		render: (_: any, record: IMeasurementPointRecord) => (
			<TableControls
				networkId={record.network}
				onEdit={() =>
					navigate(
						`${AppRoutes.MEASURMENT_POINT_CONFIGURATION}?measurementPointId=${record.id}&networkId=${
							record.network
						}&type=${record.type ?? Sites.NETWORK_MEASUREMENT_POINT}`
					)
				}
				onRemove={() => onRemove(record.id)}
				userApp={UserApps.MEASUREMENT_POINT}
			/>
		)
	});

	const onRemove = (id: string) => {
		try {
			modalConfirm({
				content: t('YouAreAboutToDeleteMeasurementPoint'),
				onOk: async () => {
					await monitoringService.deleteMeasurementPoint(id);
					if (id === selectedMeasurementPoint) {
						await setSelectedMeasurementPoint(undefined);
					}
					refetchSitesData();
					notifySuccess(t('RemovedSuccessfully'));
					refetchNetworks();
					refetchAllZones();
					refetchZones();
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	const handleCreation = () => {
		navigate(`${AppRoutes.MEASURMENT_POINT_CONFIGURATION}?type=${selectedType}`);
	};

	return (
		<>
			<Spin spinning={loadingSitesData}>
				<Table
					tableLayout="auto"
					scroll={{ x: true }}
					columns={tableColumns}
					dataSource={sitesData ? transformMeasurementPointsToRecord(sitesData) : []}
					size="small"
					showSorterTooltip
					rowKey="id"
					onChange={handleTableChange}
				/>
			</Spin>

			<ComponentGuard
				allowed={hasRight(
					!!user?.user_data?.is_superAdmin,
					configurationPermessions,
					applicationIdMap.get(UserApps.MEASUREMENT_POINT),
					UserRights.CREATE
				)}
			>
				<div className="d-flex justify-content-end mt-3">
					<Button type="primary" onClick={() => setVisibleModal(true)}>
						{t('AddMeasurementPoint')}
					</Button>
				</div>
			</ComponentGuard>
			<Modal
				width={'30%'}
				open={visibleModal}
				title={t('SelectTypeOfSite')}
				onCancel={() => setVisibleModal(false)}
				onOk={handleCreation}
				centered={true}
			>
				<Select
					options={Object.values(Sites).map(stie => {
						return {
							value: stie,
							label: upperSnakeToCapitalized(stie)
						};
					})}
					defaultValue={Sites.NETWORK_MEASUREMENT_POINT}
					onChange={value => setSelectedType(value)}
					style={{ width: '100%' }}
				/>
			</Modal>
		</>
	);
};
