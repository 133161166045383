import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DescriptionCard } from '../description-card';

export const MeasurementPointStaticInformation: React.FC<HTMLAttributes<HTMLDivElement> & { point: any }> = ({
	point
}) => {
	const { t, i18n } = useTranslation();
	const [items, setItems] = useState<{ label: string; value: string }[]>([{ label: '', value: '' }]);

	useEffect(() => {
		if (point) {
			const info: any[] = [];

			if (point.valve) {
				if (point.valve.control_type)
					info.push({
						label: t('ValveControlType'),
						value: point.valve.control_type
					});
			}

			setItems(info);
		}
	}, [point]);

	return (
		<DescriptionCard
			className="mb-3"
			title={i18n.language === 'en' ? point.name_en : point?.name_ar || point.name_en}
			items={items}
		/>
	);
};
