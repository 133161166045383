import { useContext, useEffect, useState } from 'react';
import { Form, Row, Col, Select, Spin, Card, Input, InputNumber, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import monitoringService from '../../services/monitoring.service';
import { useNavigate } from 'react-router-dom';
import { NavigationContext } from '../../contexts/navigation.context';
import { AppRoutes } from '../../constants/routes';
import { UserRights, UserApps } from '../../types';
import { hasRight } from '../../utils/has-right';
import { useQueryClient } from '@tanstack/react-query';
import { AuthenticationContext } from '../../contexts';
import { SubmitCanelButtons } from '../submit-cancel-buttons';
import { colors } from '../../constants/colors';
import { notifySuccess } from '../../utils/notification-messages';
import { useQueryParam } from '../../hooks/use-query';

export const CommercialSiteForm: React.FC<{
	setSelectedLocation: any;
	selectedLocation: any;
	commercialSiteData: any;
	refetchCommercialSiteData: () => void;
}> = ({ setSelectedLocation, selectedLocation, commercialSiteData, refetchCommercialSiteData }) => {
	const { t, i18n } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { networks, errorHandler, refetchNetworks, refetchAllZones, refetchZones, applicationIdMap } =
		navigationContext;

	const authContext = useContext(AuthenticationContext);
	const { user, configurationPermessions } = authContext;

	const [activatedPoint, setActivatedPoint] = useState<boolean | null>(null);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const commercialSiteId = useQueryParam('commercialSiteId');
	const [formSite] = Form.useForm();

	// set values fields for the selected commercial site
	useEffect(() => {
		if (commercialSiteData) {
			formSite.setFieldsValue({
				...commercialSiteData
			});
			setSelectedLocation({
				latitude: commercialSiteData.latitude,
				longitude: commercialSiteData.longitude
			});

			setActivatedPoint(commercialSiteData.is_active);
		}
		//DEBUG:  without that line, it returns estLint warning error
		// eslint-disable-next-line
	}, [commercialSiteData]);

	// submit edition for commercial site data info
	const onFinish = async (values: any) => {
		setSubmitting(true);

		// update site information
		if (commercialSiteId) {
			try {
				const resp = await monitoringService.editCommercialSite(commercialSiteId, values);
				if (!resp) throw new Error(t('couldntEditCommercialSite', { ns: 'validation' }));
				refetchCommercialSiteData && refetchCommercialSiteData();
				refetchNetworks();
				refetchAllZones();
				refetchZones();
				queryClient.refetchQueries(['sites']);
				queryClient.refetchQueries(['commercial-sites']);
				notifySuccess(t('addedSuccessfully'));
				navigate(`${AppRoutes.CONFIGURATION}?tabSelected=zones`);
			} catch (e: any) {
				errorHandler(e?.detail ? { message: e.detail } : e);
			}
		}
		setSubmitting(false);
	};

	// go to zones configuration table
	const handleCancel = () => {
		navigate(`${AppRoutes.CONFIGURATION}?tabSelected=zones`);
	};

	return (
		<>
			<Card style={{ fontWeight: 'bold' }}>
				<Spin spinning={!!!networks || submitting}>
					<Form
						form={formSite}
						layout="vertical"
						onFinish={val => {
							onFinish(val);
						}}
					>
						<Row gutter={16}>
							<Col xs={24}>
								<Form.Item
									label={t('RelatedNetwork')}
									name="network"
									rules={[
										{
											required: true,
											message: t('SelectRelatedNetwork', { ns: 'validation' })
										}
									]}
								>
									<Select
										style={{ fontWeight: 'normal' }}
										placeholder={t('RelatedNetwork')}
										options={
											networks &&
											networks
												.filter((network: any) =>
													hasRight(
														!!user?.user_data?.is_superAdmin,
														configurationPermessions,
														applicationIdMap.get(UserApps.MEASUREMENT_POINT),
														UserRights.CREATE,
														network.id
													)
												)
												.map((network: any) => {
													return {
														value: network.id,
														label:
															i18n.language === 'en'
																? network.name_en
																: network?.name_ar || network.name_en
													};
												})
										}
										disabled={!!commercialSiteData}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col xs={24}>
								<Form.Item
									name="name_en"
									label={t('EnglishName')}
									rules={[
										{
											required: true,
											message: t('AddCommercialSiteNameInEnglish', { ns: 'validation' })
										}
									]}
								>
									<Input
										type="text"
										placeholder={t('CommercialSiteNameInEnglish')}
										disabled={!!commercialSiteData}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Form.Item label={t('LatitudeAndLongitude')} style={{ marginBottom: 0 }}>
							<Row className="w-100">
								<Col xs={11}>
									<Form.Item
										name="latitude"
										rules={[
											{
												required: true,
												message: t('PleaseAddLatitude', { ns: 'validation' })
											}
										]}
									>
										<InputNumber
											placeholder={t('Latitude')}
											style={{ width: '100%' }}
											max={90}
											onChange={val =>
												setSelectedLocation({ ...selectedLocation, latitude: val })
											}
										/>
									</Form.Item>
								</Col>
								<Col xs={{ span: 12, offset: 1 }}>
									<Form.Item
										name="longitude"
										rules={[
											{
												required: true,
												message: t('PleaseAddLongitude', { ns: 'validation' })
											}
										]}
									>
										<InputNumber
											placeholder={t('Longitude')}
											style={{ width: '100%' }}
											max={90}
											onChange={val =>
												setSelectedLocation({ ...selectedLocation, longitude: val })
											}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form.Item>
						<Row gutter={16}>
							<Col xs={12}>
								<Form.Item name="is_active" label={t('ActivateCommercialSite')} valuePropName="checked">
									<Switch
										checked={activatedPoint ?? false}
										checkedChildren={t('Active')}
										unCheckedChildren={t('Inactive')}
										onChange={(value, event) => {
											event.stopPropagation();
											setActivatedPoint(value);
										}}
										style={{
											backgroundColor: activatedPoint ? colors.HEALTHY : colors.ERROR
										}}
										className={'mx-2'}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Form>

					<Form.Item className="d-flex justify-content-end">
						<SubmitCanelButtons handleCancel={handleCancel} handleSubmit={formSite.submit} />
					</Form.Item>
				</Spin>
			</Card>
		</>
	);
};
