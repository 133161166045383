import { Table } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { formatFilters } from '../../utils/get-table-filters';
import { formatSorter } from '../../utils/get-table-sorter';
import { upperSnakeToCapitalized } from '../../utils/upper-snake-to-capitalized';
import { useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import alertsService from '../../services/alerts.service';
import { AlertPriority } from '../../types';
import { NavigationContext } from '../../contexts/navigation.context';
import monitoringService from '../../services/monitoring.service';

export const AlertsConfigTable: React.FC<any> = ({ selectedTable }) => {
	const { t, i18n } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { networks, allZones, allMeasurementPoints } = navigationContext;

	const [alertsConfigFilters, setAlertsConfigFilters] = useState<any>({});
	const [alertsConfigSorters, setAlertsConfigSorters] = useState<any>({});

	// load alerts configurations
	const {
		data: alertConfigs,
		isLoading: loadingAlertConfigs,
		refetch: refetchAlertConfigs
	} = useQuery<any>({
		queryKey:
			(alertsConfigSorters && Object.keys(alertsConfigSorters)?.length) ||
			(alertsConfigFilters && Object.keys(alertsConfigFilters)?.length)
				? ['alerts-configs', alertsConfigSorters, alertsConfigFilters, selectedTable]
				: ['alerts-configs', selectedTable],
		queryFn: () =>
			alertsService.getAlertConfigs(
				(alertsConfigSorters && Object.keys(alertsConfigSorters)?.length) ||
					(alertsConfigFilters && Object.keys(alertsConfigFilters)?.length)
					? {
							sorters: { ...alertsConfigSorters },
							filters: { ...alertsConfigFilters },
							entity: selectedTable
					  }
					: { entity: selectedTable }
			),
		enabled: !!selectedTable
	});

	// load alert types for selected table
	const { data: alertTypes, isLoading: loadingAlertTypes } = useQuery<any>({
		queryKey: [`${selectedTable}-alert-types`],
		queryFn: () => alertsService.getAlertTypes({ entity: selectedTable }),
		enabled: !!selectedTable
	});

	// load data sources
	const {
		data: dataSources,
		isLoading: loadingDataSources,
		isFetching: fetchingDataSources
	} = useQuery<any>({
		queryKey: ['data-sources'],
		queryFn: () => monitoringService.getDataSources(),
		enabled: selectedTable === 'data_source'
	});

	// filter alerts configurations with: network, zone, measurement_point, data_source, type, priority
	const onAlertConfigsFilter = async (filters: any) => {
		if (JSON.stringify(filters) === JSON.stringify(alertsConfigFilters)) return;
		const filteredValues = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value !== undefined));
		if (Object.keys(filteredValues).length > 0) {
			await setAlertsConfigFilters(filteredValues);
			await refetchAlertConfigs();
		} else {
			await setAlertsConfigFilters(undefined);
			await refetchAlertConfigs();
		}
	};

	// sort alerts configurations with: created_at, updated_at, value
	const onAlertConfigsSort = async (sorters: any) => {
		if (JSON.stringify(sorters) === JSON.stringify(alertsConfigSorters)) return;
		const sortedValues = Object.fromEntries(Object.entries(sorters).filter(([_, value]) => value !== undefined));
		if (Object.keys(sortedValues).length > 0) {
			await setAlertsConfigSorters(sortedValues);
			await refetchAlertConfigs();
		} else {
			await setAlertsConfigSorters(undefined);
			await refetchAlertConfigs();
		}
	};

	const handleTableChange = (newPagination: any, filters: any, sorters: any) => {
		onAlertConfigsFilter && onAlertConfigsFilter(formatFilters(filters));
		onAlertConfigsSort && onAlertConfigsSort(formatSorter(sorters));
	};

	const tableColumns: any = [
		{
			title: t('Name'),
			dataIndex: 'name',
			key: 'name',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Type'),
			dataIndex: 'alert_type',
			key: 'alert_type',
			align: 'center',
			filters:
				alertTypes &&
				alertTypes.map(type => ({
					text: upperSnakeToCapitalized(type),
					value: type
				})),
			filterMultiple: false,
			render: (text: string) => upperSnakeToCapitalized(text)
		},
		{
			title: t('Priority'),
			dataIndex: 'alert_priority',
			key: 'alert_priority',
			align: 'center',
			filters: Object.keys(AlertPriority).map(key => ({
				text: upperSnakeToCapitalized(key),
				value: key
			})),
			filterMultiple: false,
			render: (text: string) => upperSnakeToCapitalized(text)
		},
		{
			title: t('DateCreated'),
			dataIndex: 'created_at',
			key: 'created_at',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: Date) => dayjs(text).format('dddd, MMMM D, YYYY h:mm A')
		},
		{
			title: t('DateUpdated'),
			dataIndex: 'updated_at',
			key: 'updated_at',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: Date) => dayjs(text).format('dddd, MMMM D, YYYY h:mm A')
		}
	];

	if (selectedTable === 'network') {
		tableColumns.push({
			title: t('RelatedNetwork'),
			dataIndex: 'network',
			key: 'network',
			align: 'center',
			filters:
				networks &&
				networks.map(network => ({
					text: i18n.language === 'en' ? network.name_en : network.name_ar,
					value: network.id
				})),
			filterMultiple: false,
			render: (networkId: string) => {
				const matchedNetwork = networks && networks.find(network => network.id === networkId);
				return matchedNetwork ? (i18n.language === 'en' ? matchedNetwork.name_en : matchedNetwork.name_ar) : '';
			}
		});
	} else if (selectedTable === 'zone') {
		tableColumns.push({
			title: t('RelatedZone'),
			dataIndex: 'zone',
			key: 'zone',
			align: 'center',
			filters:
				allZones &&
				allZones.map(zone => ({
					text: i18n.language === 'en' ? zone.name_en : zone.name_ar,
					value: zone.id
				})),
			filterMultiple: false,
			render: (zoneId: string) => {
				const matchedZone = allZones && allZones.find(zone => zone.id === zoneId);
				return matchedZone ? (i18n.language === 'en' ? matchedZone.name_en : matchedZone.name_ar) : '';
			}
		});
	} else if (selectedTable === 'measurement_point') {
		tableColumns.push({
			title: t('RelatedMeasurementPoint'),
			dataIndex: 'measurement_point',
			key: 'measurement_point',
			align: 'center',
			filters:
				allMeasurementPoints &&
				allMeasurementPoints.map(site => ({
					text: site?.name_en.length > 0 ? site?.name_en : site.id,
					value: site.id
				})),
			filterMultiple: false,
			render: (siteId: string) => {
				const matchedSite = allMeasurementPoints && allMeasurementPoints.find(site => site.id === siteId);
				return matchedSite ? matchedSite.name_en || matchedSite.id : '';
			}
		});
	} else if (selectedTable === 'data_source') {
		tableColumns.push({
			title: t('RealtedDataSource'),
			dataIndex: 'data_source',
			key: 'data_source',
			align: 'center',
			filters:
				dataSources &&
				dataSources.map(source => ({
					text: source?.name_en.length > 0 ? source?.name_en : source.id,
					value: source.id
				})),
			filterMultiple: false,
			render: (sourceId: string) => {
				const matchedSource = dataSources && dataSources.find(source => source.id === sourceId);
				return matchedSource ? matchedSource.name_en || matchedSource.id : '';
			}
		});
	}

	tableColumns.push({
		title: t('Value'),
		dataIndex: 'value',
		key: 'value',
		align: 'center',
		sorter: { multiple: 1 },
		render: (value: Number) => (value !== null && value !== undefined ? value.toFixed(3) : '')
	});

	return (
		<>
			<Table
				tableLayout="auto"
				scroll={{ x: true }}
				columns={tableColumns}
				dataSource={alertConfigs ?? []}
				size="small"
				showSorterTooltip
				rowKey="id"
				onChange={handleTableChange}
				loading={loadingAlertConfigs || loadingAlertTypes || (loadingDataSources && fetchingDataSources)}
			/>
		</>
	);
};
