import { Tooltip } from 'antd';

export const Disabled: React.FC<{ children: React.ReactNode; tooltipTitle: string }> = ({ children, tooltipTitle }) => {
	return (
		<Tooltip title={tooltipTitle}>
			<div style={{ cursor: 'not-allowed', display: 'inline-block' }}>
				<div style={{ opacity: 0.5, pointerEvents: 'none', display: 'inline-block' }}>{children}</div>
			</div>
		</Tooltip>
	);
};
