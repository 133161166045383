import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Col, Form, FormProps, Input, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../hooks/use-query';
import userManagementService from '../../services/user-management.service';
import { errorHandler } from '../../utils/error-handler';
import { notifySuccess } from '../../utils/notification-messages';
import { WaiInputNumber } from '../wai-input/styles/wai-input';
import { SubmitCanelButtons } from '../submit-cancel-buttons';

export const FoundationForm: React.FC<FormProps> = () => {
	const { t, i18n } = useTranslation();
	const foundationId = useQueryParam('foundationId');
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [submitting, setSubmitting] = useState<boolean>(false);

	// load foundation data
	const {
		data: foundation,
		isLoading: loadingFoundation,
		isFetching: fetechingFoundation,
		refetch: refetcFoundation
	} = useQuery({
		queryKey: ['foundation', foundationId],
		queryFn: () => foundationId && userManagementService.getFoundation(foundationId),
		enabled: !!foundationId
	});

	// fill form with data
	useEffect(() => {
		if (foundation) {
			form.setFieldsValue({
				...foundation
			});
		}
	}, [foundation]);

	const onFinish = async (values: any) => {
		setSubmitting(true);
		Object.keys(values).forEach(key => {
			if (values[key] === '' || values[key] === undefined || values[key] === null) {
				delete values[key];
			}
		});
		if (foundationId) {
			try {
				const resp = await userManagementService.editFoundation(foundationId, values);
				if (!resp) throw new Error(t('couldntEditFoundation', { ns: 'validation' }));
				notifySuccess(t('editedSuccessfully'));
				refetcFoundation();
				queryClient.refetchQueries(['foundations']);
				navigate(`${AppRoutes.ADMINISTRATION}?tabSelected=superAdmin&&subTabSelected=foundations`);
			} catch (e: any) {
				errorHandler(e);
			}
		} else {
			try {
				const resp = await userManagementService.createFoundation(values);
				if (!resp) throw new Error(t('couldntAddFoundation', { ns: 'validation' }));
				notifySuccess(t('addedSuccessfully'));
				queryClient.refetchQueries(['foundations']);
				navigate(`${AppRoutes.ADMINISTRATION}?tabSelected=superAdmin&&subTabSelected=foundations`);
			} catch (e: any) {
				errorHandler(e);
			}
		}
		setSubmitting(false);
	};
	const handleCancel = () => {
		navigate(`${AppRoutes.ADMINISTRATION}?tabSelected=superAdmin&&subTabSelected=foundations`);
	};

	return (
		<div
			style={{
				paddingLeft: i18n.language === 'en' ? '1rem' : '',
				paddingRight: i18n.language === 'en' ? '' : '1rem',
				paddingTop: '2rem'
			}}
		>
			<Row gutter={24} className="justify-content-center">
				<Col xs={24} md={14} lg={10}>
					<Row>
						<h3 className="mb-3">{t('Foundation')}</h3>
					</Row>
					<Row>
						<Col xs={24}>
							<Spin spinning={submitting || (loadingFoundation && fetechingFoundation)}>
								<Form form={form} layout="vertical" onFinish={onFinish}>
									<Form.Item
										name="name"
										label={t('Name')}
										rules={[
											{ required: true, message: t('addFoundationName', { ns: 'validation' }) }
										]}
									>
										<Input type="text" placeholder={t('foundationName')} />
									</Form.Item>
									<Form.Item
										name="no_of_networks"
										label={t('NumberOfNetworks')}
										rules={[
											{
												required: true,
												message: t('PleaseAddTheNumberOfNetworks', { ns: 'validation' })
											}
										]}
									>
										<WaiInputNumber min={0} placeholder={t('NumberOfNetworks')} />
									</Form.Item>
									<Form.Item
										name="no_of_zones"
										label={t('NumberOfZones')}
										rules={[
											{
												required: true,
												message: t('PleaseAddTheNumberOfZones', { ns: 'validation' })
											}
										]}
									>
										<WaiInputNumber min={0} placeholder={t('NumberOfZones')} />
									</Form.Item>
									<Form.Item
										name="no_of_measurement_points"
										label={t('NumberOfMeasurementPoints')}
										rules={[
											{
												required: true,
												message: t('PleaseAddTheNumberOfMeasurementPoints', {
													ns: 'validation'
												})
											}
										]}
									>
										<WaiInputNumber min={0} placeholder={t('NumberOfMeasurementPoints')} />
									</Form.Item>
									<Form.Item
										name="no_of_data_sources"
										label={t('NumberOfDataSources')}
										rules={[
											{
												required: true,
												message: t('PleaseAddTheNumberOfDataSources', { ns: 'validation' })
											}
										]}
									>
										<WaiInputNumber min={0} placeholder={t('NumberOfDataSources')} />
									</Form.Item>

									<Form.Item className="d-flex justify-content-end">
										<SubmitCanelButtons handleCancel={handleCancel} handleSubmit={form.submit} />
									</Form.Item>
								</Form>
							</Spin>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};
