import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Table, TableProps } from 'antd';
import { useContext } from 'react';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { useQueryParam } from '../../hooks/use-query';
import monitoringService from '../../services/monitoring.service';
import { IMeasurementPointRecord, UserApps } from '../../types';
import { notifySuccess } from '../../utils/notification-messages';
import { upperSnakeToCapitalized } from '../../utils/upper-snake-to-capitalized';
import { TableControls } from '../table-controls';
import { modalConfirm } from '../modal-confirm';
import { Sites } from '../../types/sites';
import dayjs from 'dayjs';
import { transformMeasurementPointsToRecord } from '../../mappers/measurement-point-to-record';
import { AppRoutes } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';

export const CommercialSitesSummaryTable: React.FC<
	TableProps<IMeasurementPointRecord> & {
		commercialMeterGroupId: string;
	}
> = ({ commercialMeterGroupId }) => {
	const { t, i18n } = useTranslation();

	const navigationContext = useContext(NavigationContext);
	const { networks, selectedMeasurementPoint, setSelectedMeasurementPoint, errorHandler } = navigationContext;

	const queryClient = useQueryClient();
	const networkId = useQueryParam('networkId');
	const navigate = useNavigate();

	const {
		data: commercialSites,
		isLoading: loadingCommercialSites,
		refetch: refetchCommercialSites
	} = useQuery<any>({
		queryKey: ['commercial-sites', commercialMeterGroupId],
		queryFn: () =>
			monitoringService.getCommercialSites({
				commercial_meter_group: commercialMeterGroupId
			}),
		enabled: !!commercialMeterGroupId
	});

	const tableColumns: any = [
		{
			title: t('CommercialSite'),
			dataIndex: 'name_en',
			key: 'name_en',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Active'),
			dataIndex: 'is_active',
			key: 'is_active',
			align: 'center',
			filters: [
				{ text: t('Active'), value: true },
				{ text: t('NotActive'), value: false }
			],
			filterMultiple: false,
			render: (check: boolean) =>
				check ? <AiFillCheckCircle className="text-success" /> : <AiFillCloseCircle className="text-danger" />
		},
		{
			title: t('Type'),
			dataIndex: 'type',
			key: 'type',
			align: 'center',
			filters: Object.values(Sites).map(type => ({
				text: upperSnakeToCapitalized(type),
				value: type
			})),
			filterMultiple: false,
			render: (type: Sites) => upperSnakeToCapitalized(type)
		},
		{
			title: t('RelatedNetwork'),
			dataIndex: 'network',
			key: 'network',
			align: 'center',
			filters:
				networks &&
				networks.map(network => ({
					text: i18n.language === 'en' ? network.name_en : network.name_ar,
					value: network.id
				})),
			filterMultiple: false,
			render: (networkId: string) => {
				const matchedNetwork = networks && networks.find(network => network.id === networkId);
				return matchedNetwork ? (i18n.language === 'en' ? matchedNetwork.name_en : matchedNetwork.name_ar) : '';
			}
		},
		{
			title: t('Latitude'),
			dataIndex: 'latitude',
			key: 'latitude',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('Longitude'),
			dataIndex: 'longitude',
			key: 'longitude',
			align: 'center',
			render: (text: string) => text
		},
		{
			title: t('DateCreated'),
			dataIndex: 'created_at',
			key: 'created_at',
			align: 'center',
			sorter: { multiple: 1 },
			render: (text: Date) => dayjs(text).format('dddd, MMMM D, YYYY h:mm A')
		}
	];

	tableColumns.push({
		title: t('Actions'),
		key: 'actions',
		align: 'center' as 'center',
		render: (_: any, record: IMeasurementPointRecord) => (
			<TableControls
				networkId={networkId}
				onEdit={() => {
					navigate(`${AppRoutes.COMMERCIAL_METER_SITE_CONFIGURATION}?commercialSiteId=${record.id}`);
				}}
				onRemove={() => onRemove(record.id)}
				userApp={UserApps.MEASUREMENT_POINT}
			/>
		)
	});

	// confirm deletion commercial site
	const onRemove = (id: string) => {
		try {
			modalConfirm({
				content: t('YouAreAboutToDeleteCommercialSite'),
				onOk: async () => {
					await monitoringService.deleteCommercialSite(id);
					if (id === selectedMeasurementPoint) {
						await setSelectedMeasurementPoint(undefined);
					}
					refetchCommercialSites();
					queryClient.refetchQueries(['sites']);
					notifySuccess(t('RemovedSuccessfully'));
				}
			});
		} catch (e: any) {
			errorHandler(e);
		}
	};

	return (
		<Table
			tableLayout="auto"
			scroll={{ x: true }}
			columns={tableColumns}
			dataSource={commercialSites ? transformMeasurementPointsToRecord(commercialSites) : []}
			size="small"
			showSorterTooltip
			rowKey="id"
			loading={loadingCommercialSites}
		/>
	);
};
