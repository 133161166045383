import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

export const SubmitCanelButtons: React.FC<any> = ({ handleCancel, handleSubmit, reset, disabled }) => {
	const { t } = useTranslation();
	return (
		<>
			<Button type="ghost" onClick={handleCancel} disabled={disabled}>
				{t(reset ? 'Reset' : 'Cancel', { ns: 'common' })}
			</Button>
			<Button type="primary" onClick={handleSubmit} disabled={disabled}>
				{t('Submit', { ns: 'common' })}
			</Button>
		</>
	);
};
