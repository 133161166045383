import React, { HTMLAttributes, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { SideDrawer } from '..';
import productLogo from '../../assets/img/product-logo.png';
import FoundationLogo from '../../assets/img/foundation-logo.png';
import configProxy from '../../config/config';
import { colors } from '../../constants/colors';
import { AppRoutes } from '../../constants/routes';
import { NetworkNavigator } from '../../containers';
import { WaiLogo } from '../../containers/wai-logo';
import { AuthenticationContext } from '../../contexts';
import { useTranslation } from 'react-i18next';
import { NavigationContext } from '../../contexts/navigation.context';
import { useMedia } from '../../hooks/use-media.hook';
import { ISidebarItem } from '../../types/sidebar-items';
import { SidebarAguamentiImage } from './styles/sidebar-logo';

interface SidebarProps {
	header?: any;
	footer?: any;
	selected: string;
	menuItems: ISidebarItem[];
	visible: boolean;
	close: () => void;
	collapseOnRouteChange: boolean;
}
export const SidebarHeader: React.FC<HTMLAttributes<HTMLDivElement> & { collapsed: boolean }> = ({ collapsed }) => {
	const authContext = useContext(AuthenticationContext);
	const { user } = authContext;

	return (
		<div
			style={{ color: colors.WHITE, paddingTop: '10px' }}
			className="d-flex flex-column justify-content-center align-items-center"
		>
			<img
				className="mb-2"
				style={{
					width: collapsed ? '3.5rem' : '8rem',
					transition: 'all 0.4s',
					borderRadius: '15px',
					backgroundColor: colors.WHITE,
					padding: '2px 3px'
				}}
				src={FoundationLogo}
			/>
			{collapsed || !user ? null : <p>{user.user_data.user_foundation}</p>}
			{/* Somabay */}
			{/* Static user foundation for demo */}
		</div>
	);
};

export const SidebarFooter: React.FC<HTMLAttributes<HTMLDivElement> & { collapsed: boolean }> = ({ collapsed }) => {
	const media = configProxy.defaultExpandScreenSize;
	const desktopView = useMedia(media);
	return (
		<>
			<Link
				style={{ position: 'absolute', bottom: '0rem' }}
				to={AppRoutes.MONITORING}
				className="w-100 d-flex flex-row flex-xl-column mt-0 align-items-center justify-content-center mt-4"
			>
				<SidebarAguamentiImage src={productLogo} collapsed={collapsed} className="me-0 me-md-1" />
				<WaiLogo direction={collapsed ? 'vertical' : 'horizontal'} size="small" />
			</Link>
		</>
	);
};

export const Sidebar: React.FC<SidebarProps> = ({
	header,
	footer,
	selected,
	menuItems,
	visible,
	close,
	collapseOnRouteChange
}) => {
	const { t, i18n } = useTranslation();
	const [collapsed, setCollapsed] = useState(false);
	const navigationContext = useContext(NavigationContext);
	const media = configProxy.defaultExpandScreenSize;
	const desktopView = useMedia(media);

	const {
		networks,
		selectedNetwork,
		zones,
		selectedZone,
		selectedCommercialMeterGroup,
		onNetworkSelect,
		onZoneSelect,
		sitesSideBar,
		onMeasurementPointSelect,
		selectedMeasurementPoint
	} = navigationContext;
	const [loading, setLoading] = useState<boolean>(false);

	const collapseHandler = (collapseState: boolean) => {
		//to make the sidebar collapsible
		// setCollapsed(collapseState);
		setCollapsed(false);
	};

	let headerComp = header ? (typeof header === 'function' ? header(collapsed, visible) : header) : null;

	let footerComp = footer ? (typeof footer === 'function' ? footer(collapsed, visible) : footer) : null;
	return (
		<SideDrawer
			visible={visible}
			onClose={close}
			collapsable={desktopView}
			onCollapse={collapseHandler}
			collapsed={desktopView && collapsed}
			media={media}
		>
			{headerComp}

			{collapsed ? (
				<div className="mx-2 mt-3" style={{ color: colors.WHITE, fontSize: '0.8rem' }}>
					{networks && selectedNetwork && (
						<div className="mb-4">
							<label style={{ fontSize: '0.8rem', fontWeight: 500, color: colors.DARK_GREY4 }}>
								{t('Network')}
							</label>
							<div className="d-flex flex-column align-items-center justify-content-center">
								{i18n.language === 'en'
									? networks.find((network: any) => network.id === selectedNetwork).name_en
									: networks.find((network: any) => network.id === selectedNetwork)?.name_ar ||
									  networks.find((network: any) => network.id === selectedNetwork).name_en}
							</div>
						</div>
					)}
					{zones && selectedZone && (
						<div className="mb-4">
							<label style={{ fontSize: '0.8rem', fontWeight: 500, color: colors.DARK_GREY4 }}>
								{t('Zone')}
							</label>
							<div className="d-flex flex-column align-items-center justify-content-center">
								{i18n.language === 'en'
									? zones.find((zone: any) => zone.id === selectedZone).name_en
									: zones.find((zone: any) => zone.id === selectedZone).name_ar ||
									  zones.find((zone: any) => zone.id === selectedZone).name_en}
							</div>
						</div>
					)}
					{sitesSideBar && selectedMeasurementPoint && (
						<div>
							<label style={{ fontSize: '0.8rem', fontWeight: 500, color: colors.DARK_GREY4 }}>
								{t('HydraulicPoint')}
							</label>
							<div className="d-flex flex-column align-items-center justify-content-center">
								{i18n.language === 'en'
									? sitesSideBar.find((mp: any) => mp.id === selectedMeasurementPoint).name_en
									: sitesSideBar.find((mp: any) => mp.id === selectedMeasurementPoint).name_ar ||
									  sitesSideBar.find((mp: any) => mp.id === selectedMeasurementPoint).name_en}
							</div>
						</div>
					)}
				</div>
			) : (
				<NetworkNavigator
					networks={networks}
					zones={zones}
					selectedNetwork={selectedNetwork}
					onNetworkSelect={onNetworkSelect}
					onZoneSelect={onZoneSelect}
					selectedZone={selectedZone ?? selectedCommercialMeterGroup}
					measurementPoints={sitesSideBar}
					onMeasurementPointSelect={onMeasurementPointSelect}
					selectedMeasurementPoint={selectedMeasurementPoint}
				/>
			)}
			{footerComp}
		</SideDrawer>
	);
};
