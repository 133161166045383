export enum GraphSpan {
	DAY = 'day',
	WEEK = 'week',
	MONTH = 'month',
	YEAR = 'year',
	ALL = 'all',
	RESET = 'reset'
	// CUSTOM = 'custom'
}

export const GraphSpanMapper = {
	[GraphSpan.DAY]: 'D',
	[GraphSpan.MONTH]: 'M',
	[GraphSpan.WEEK]: 'W',
	[GraphSpan.YEAR]: 'Y',
	[GraphSpan.ALL]: 'All',
	[GraphSpan.RESET]: 'Reset'
};
