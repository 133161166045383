import { useQuery } from '@tanstack/react-query';
import { Badge, Spin } from 'antd';
import { HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import monitoringService from '../../services/monitoring.service';
import { IChart } from '../../types';
import { DescriptionCardTitle } from '../description-card/styles/description-card';
import { GraphCard } from '../graph-card';
import { DataSourcesMeasurementPoint } from './data-sources';
import {
	MeasurementPointInfoContainer,
	MeasurementPointInfoLabel,
	MeasurementPointInfoText
} from './styles/measurement-point-info';

export const MPInfoNew: React.FC<
	HTMLAttributes<HTMLDivElement> &
		Partial<IChart> & {
			measurementPoint?: any;
		}
> = ({ measurementPoint, ...restProps }) => {
	const { t, i18n } = useTranslation();

	// loading data for data-sources
	const {
		data: dataSources,
		isLoading: dataSourcesLoading,
		isFetching: dataSourcsFetching
	} = useQuery({
		queryKey: ['data-sources-for-site-lastReadings', measurementPoint?.id],
		queryFn: () => monitoringService.getMeasurementPointLatestReadings(measurementPoint.id),
		enabled: !!measurementPoint?.id
	});

	return (
		<Spin spinning={dataSourcesLoading && dataSourcsFetching}>
			<GraphCard height={18} {...restProps}>
				<div
					className={'d-flex flex-column justify-content-center'}
					style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}
				>
					<MeasurementPointInfoContainer>
						<DescriptionCardTitle
							style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
							englishSelected={i18n.language === 'en'}
						>
							{measurementPoint?.name_en}
						</DescriptionCardTitle>
						{measurementPoint.hasOwnProperty('is_active') && (
							<MeasurementPointInfoText
								className="d-flex justify-content-between"
								englishSelected={i18n.language === 'en'}
							>
								<div>
									<MeasurementPointInfoLabel>
										{t('Status', { ns: 'common' })}
									</MeasurementPointInfoLabel>
									<Badge status={measurementPoint.is_active ? 'success' : 'default'} />
									{measurementPoint.is_active ? t('Active') : t('Inactive')}
								</div>
								<div>
									<Link className="mx-2" to={`${AppRoutes.ANALYSIS}?point=${measurementPoint.id}`}>
										<span>{t('viewAnalytics')}</span>
									</Link>
								</div>
							</MeasurementPointInfoText>
						)}
					</MeasurementPointInfoContainer>
					<DataSourcesMeasurementPoint dataSources={dataSources?.length > 0 ? dataSources : undefined} />
				</div>
			</GraphCard>
		</Spin>
	);
};
