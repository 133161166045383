import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const GraphCardContainer = styled.div<{ maxWidth?: string; height?: number; englishSelected: boolean }>`
	background-color: ${colors.WHITE_RGBA9};
	border-radius: 15px;
	padding: 10px;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	display: flex;
	flex-direction: column;
	position: relative;
	height: ${({ height }) => (height ? height + 1 + 'rem' : 'auto')};
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
`;

export const GraphCardTitle = styled.h2<{ englishSelected: boolean; titleBold?: boolean }>`
	font-size: 0.9rem;
	margin: 0;
	font-weight: ${({ titleBold }) => (titleBold ? 'bold' : 'normal')};
`;

export const GraphCardSubtitle = styled.p`
	font-size: 0.8rem;
`;

export const GraphCardGraph = styled.div<{ height?: number }>`
	height: ${({ height }) => (height ? height - 1 + 'rem' : '12rem')};
	display: flex;
	flex-direction: column;
`;
