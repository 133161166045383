import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { Bar, BarChart, Brush, CartesianGrid, ComposedChart, ResponsiveContainer, YAxis } from 'recharts';
import { GraphCard } from '../components';
import { colors } from '../constants/colors';
import { useTranslation } from 'react-i18next';
import { ChartCustomSpan, SpanType, TimeStep, TimeStepMapper } from '../types';
import { clearArrayUndefined } from '../utils/clean-array-undefined';
import { dateToQuery } from '../utils/date-to-query';
import { debounce } from '../utils/debounce';
import { mergeLogs } from '../utils/merge-logs';
import { ChartTable } from '../components/chart-table/chart-table';
const COLORS = [
	colors.GRAPH_PURPLE,
	colors.GRAPH_GREEN,
	colors.WARNING,
	colors.HEALTHY,
	colors.ERROR,
	colors.GRAPH_RED,
	colors.GRAPH_CYAN
];

const getGraphCustomTooltipOptions = (options, t, spanTpye) => {
	const dataPointIndex = options.dataPointIndex;
	const seriesIndex = options.seriesIndex;
	const chartColors = options.w.config.colors;
	const series = options.w.config.series;
	const calculation_ds = series[0].data[dataPointIndex]?.calculation_ds?.length;
	const zone_ds = series[0].data[dataPointIndex]?.zone_ds?.length;
	const reset_point = series[seriesIndex].data[dataPointIndex]?.reset;
	let color = '';
	if (calculation_ds >= 0 && zone_ds >= 0) {
		const percent = calculation_ds / zone_ds;
		color = percent > 0.9 ? colors.HEALTHY : percent > 0.5 ? colors.WARNING : colors.ERROR;
	}
	// sort all points for selected time according to max value
	const selectedTime = series[seriesIndex].data[dataPointIndex]?.x;
	const filteredSeries = series.filter(s => s.data.some(d => d.x === selectedTime));
	const sortedSeries = filteredSeries.sort((a, b) => {
		const aPoint = a.data.find(d => d.x === selectedTime);
		const bPoint = b.data.find(d => d.x === selectedTime);
		const aY = aPoint?.y ?? 0;
		const bY = bPoint?.y ?? 0;
		return bY - aY;
	});
	const date =
		spanTpye === SpanType.DAY
			? dayjs(series[seriesIndex].data[dataPointIndex]?.x).format('dddd, MMMM D, YYYY h:mm A')
			: spanTpye === SpanType.YEAR
			? dayjs(series[seriesIndex].data[dataPointIndex]?.x).format('MMMM, YYYY')
			: dayjs(series[seriesIndex].data[dataPointIndex]?.x).format('dddd, MMMM D, YYYY');

	return (
		'<div style="display:flex;flex-direction:column; ">' +
		`<div style="margin-bottom:5px;background-color:${colors.LIGHT_GREY2}; padding:8px;5px;">` +
		date +
		'</div>' +
		'<div style=padding:10px;>' +
		sortedSeries
			.map((d, idx) => {
				const point = d.data.find(p => p.x === series[seriesIndex].data[dataPointIndex]?.x);
				const unit = point?.unit ?? '';
				const originalIdx = series.findIndex(s => s.name === d.name); // to get the original color of each point

				return `<div style="display:flex; align-items:center;margin-bottom:5px;" >
					${
						point
							? `<div style="background-color:${
									chartColors[originalIdx]
							  }; width:12px;height:12px;border-radius:50%;margin-right:10px;"></div>
							  ${d.name}:  ${(point?.y && point?.y?.toFixed(3)) ?? '-'}  ${point?.y ? unit : ''}`
							: ''
					}

			</div>`;
			})
			.join(' ') +
		`<div style="display:flex;align-items:center;margin-top:30px;">

	${
		calculation_ds !== undefined && zone_ds !== undefined
			? `<span style=background-color:${color};border-radius:5px;padding:5px;width:20px;height:20px;color:${colors.WHITE};display:flex;justify-content:center;align-items:center;font-weight:700;margin-right:5px;>i</span>
			<i>Calculated from ${calculation_ds} out of  ${zone_ds} available data sources</i>`
			: ''
	}
	${
		reset_point
			? `<span style='display:block;width:100%;border-top: 1px solid ${colors.INACTIVE}; padding-top: 5px;'>
				 <span class='badge bg-warning text-dark' >
					${t('ResetPoint')}
				</span>
			</span>`
			: ''
	}
		</div>` +
		'</div>' +
		'</div>'
	);
};

const getOptions: ({}: any) => ApexOptions = ({ yAxis = [], t = {}, seriesData = [], labels, spanType = SpanType }) => {
	return {
		chart: {
			id: 'analysis-chart',
			type: 'line',
			stacked: false,
			zoom: {
				type: 'x',
				enabled: true,
				autoScaleYaxis: true
			},
			toolbar: {
				tools: { reset: true, zoom: true },
				autoSelected: 'zoom',
				export: {
					csv: {
						dateFormatter(timestamp?) {
							return `"${dayjs(timestamp).format('dddd, MMMM D, YYYY h:mm A')}"`;
						}
					}
				}
			}
		},
		labels,
		colors: COLORS,
		stroke: {
			width: [2, 0, 2],
			curve: 'smooth'
		},
		dataLabels: {
			enabled: false
		},
		markers: {
			size: 0
		},
		type: 'column',
		noData: { text: t('NoAvailableDataDuringThisTimeSpan', { ns: 'validation' }) },
		yaxis: yAxis.length
			? yAxis.map(
					(axis: any): ApexYAxis => ({
						labels: {
							formatter: function (val: number) {
								return val.toFixed(3);
							}
						},
						title: {
							text: axis.legend
						},
						opposite: axis.position === 'right',
						tickAmount: 5
					})
			  )
			: {
					labels: {
						show: false
					}
			  },
		xaxis: {
			type: 'datetime',
			tooltip: { enabled: false },
			labels: {
				datetimeUTC: false
			}
		},

		tooltip: {
			shared: true,
			intersect: false,
			custom: options => getGraphCustomTooltipOptions(options, t, spanType)
		}
	};
};

export const TimeSeriesBarGrouped: React.FC<any> = ({
	dataObjects,
	showSpanSelector,
	showUnitSelector,
	showTabular,
	chartTableDataSource,
	showCustomSpanSelector,
	showCustomSelectorDate,
	height,
	showBrush,
	title,
	graphHeight,
	statistics,
	onSpanSelect,
	lastReadingDate,
	lastReadingsDates,
	minMax,
	showAverageLine,
	showStDevLine,
	OnDateSelect,
	customTimeUnits,
	spanType,
	sourceName,
	showTimeStep,
	showTableChart,
	...restProps
}) => {
	const { t, i18n } = useTranslation();
	const [series, setSeries] = useState<any>([]);
	const [labels, setLabels] = useState<number[]>([]);
	const [units, setUnits] = useState<any>();
	const [yAxis, setYAxis] = useState<any>();
	const [cleanDataObjects, setCleanDataObjects] = useState<any>([]);
	const [brushData, setbrushData] = useState<any>([]);
	const [brushState, setBrushState] = useState<{ startIndex: number; endIndex: number }>();
	const [displayDate, setDisplayDate] = useState<any>();
	const [displayTimeSpan, setDisplayTimeSpan] = useState<{ start: any; end: any }>();
	const [timeStep, setTimeStep] = useState<TimeStep | undefined>(undefined);
	const [firstDate, setFirstDate] = useState<any>();
	const [lastDate, setLastDate] = useState<any>();
	const [customBrushSpan, setCustomBrushSpan] = useState<boolean>(false);
	const [chartView, setChartView] = useState<any>('table');

	useEffect(() => {
		if (dataObjects && dataObjects.length) {
			setCleanDataObjects(dataObjects.filter((d: any) => !!d.id));
		}
	}, [dataObjects, dataObjects?.length]);

	useEffect(() => {
		const seriesData: any = [];
		const labels: number[] = [];

		cleanDataObjects.forEach((dataObject: any) => {
			if (dataObject.data?.length) {
				seriesData.push({
					name: dataObject.name,
					data: dataObject.data.map((d: any) => ({
						x: dayjs(d.time).toDate().getTime(),
						y: d.value || d.value === 0 ? +d.value : undefined,
						unit: dataObject.unit,
						reset: d?.reset || false
					})),
					type: 'bar'
				});
			}
		});
		setLabels(labels);
	}, [cleanDataObjects, cleanDataObjects?.length]);
	useEffect(() => {
		setSeries(
			clearArrayUndefined(
				cleanDataObjects.map((dataObject: any) => {
					if (dataObject.data?.length)
						return {
							name: dataObject.name,
							data: dataObject.data.map((d: any) => ({
								x: dayjs(d.time).toDate().getTime(),
								y: d.value || d.value === 0 ? +d.value : undefined,
								unit: dataObject.unit,
								reset: d?.reset || false
							}))
						};
				})
			)
		);
	}, [cleanDataObjects, cleanDataObjects?.length]);

	useEffect(() => {
		setUnits(
			clearArrayUndefined(
				cleanDataObjects.map((dataObject: any) => {
					if (dataObject.data?.length) return dataObject.unit;
				})
			)
		);
	}, [cleanDataObjects, cleanDataObjects?.length]);

	useEffect(() => {
		setYAxis(
			clearArrayUndefined(
				cleanDataObjects.map((dataObject: any) => {
					if (dataObject.data?.length && dataObject?.yAxis?.position) return dataObject.yAxis;
				})
			)
		);
	}, [cleanDataObjects, cleanDataObjects?.length]);

	useEffect(() => {
		if (cleanDataObjects?.length) {
			const allDataObjects = cleanDataObjects.map((ob: any) => {
				return { ...ob, data: ob.allData };
			});
			const allData = mergeLogs(allDataObjects);
			const data = mergeLogs(cleanDataObjects);
			setbrushData(allData);

			let dataStartIndex = 0,
				dataEndIndex = 8;

			if (data.length) dataStartIndex = allData.findIndex(d => dayjs(d.time).isSame(dayjs(data[0].time)));

			allData.forEach((d, idx) => {
				if (data.length && dayjs(d.time).isSame(dayjs(data[data.length - 1].time))) dataEndIndex = idx;
			});

			setBrushState({ startIndex: dataStartIndex, endIndex: dataEndIndex });
			let start: Date | null = null;
			let end: Date | null = null;
			for (const obj of cleanDataObjects) {
				const data = obj?.data;
				const objStart: Date = data[0]?.time;
				const objEnd: Date = data[data.length - 1]?.time;

				if (!start || objStart < start) {
					start = objStart;
				}

				if (!end || objEnd > end) {
					end = objEnd;
				}
			}
			setFirstDate(start);
			setLastDate(end);
		}
	}, [cleanDataObjects, cleanDataObjects?.length]);
	useEffect(() => {
		if (customTimeUnits && customTimeUnits?.length) {
			setTimeStep(TimeStepMapper[customTimeUnits[0]]);
		}
	}, [customTimeUnits]);

	return (
		<GraphCard
			className="mb-4"
			title={title || t('Analysis')}
			height={height}
			showSpanSelector={showSpanSelector}
			showUnitSelector={showUnitSelector}
			onSpanSelect={onSpanSelect}
			OnDateSelect={OnDateSelect}
			showTabular={showTabular}
			dataObjects={cleanDataObjects}
			actualDataTimeSpan={
				cleanDataObjects &&
				cleanDataObjects?.length &&
				cleanDataObjects[0] &&
				cleanDataObjects[0]?.data &&
				cleanDataObjects[0]?.data?.length
					? ({
							start: firstDate && firstDate,
							end: lastDate && lastDate
					  } as ChartCustomSpan)
					: ({
							start:
								displayTimeSpan && displayTimeSpan?.start
									? dateToQuery(dayjs(displayTimeSpan?.start))
									: lastReadingDate && dateToQuery(dayjs(lastReadingDate)),
							end:
								displayTimeSpan && displayTimeSpan?.end
									? dateToQuery(dayjs(displayTimeSpan?.end))
									: lastReadingDate && dateToQuery(dayjs(lastReadingDate))
					  } as ChartCustomSpan)
			}
			chartTableDataSource={chartTableDataSource}
			showCustomSpanSelector={showCustomSpanSelector}
			showCustomSelectorDate={showCustomSelectorDate}
			statistics={statistics}
			lastReadingDate={lastReadingDate}
			setDisplayDate={setDisplayDate}
			displayDate={displayDate}
			setDisplayTimeSpan={setDisplayTimeSpan}
			displayTimeSpan={displayTimeSpan}
			customTimeUnits={customTimeUnits}
			spanType={spanType}
			setTimeStep={setTimeStep}
			sourceName={sourceName}
			showTimeStep={showTimeStep}
			customBrushSpan={customBrushSpan}
			setCustomBrushSpan={setCustomBrushSpan}
			showTableChart={showTableChart}
			chartView={chartView}
			setChartView={setChartView}
			graphHeight={graphHeight}
			{...restProps}
		>
			<div style={{ height: height || '98%' }} dir="ltr" className="d-flex flex-column justify-content-between">
				{timeStep && timeStep?.length ? (
					<>
						<div className="d-flex justify-content-between align-items-center ">
							<AiOutlineLeft
								size={'2rem'}
								style={{ strokeWidth: '30px' }}
								color={colors.GRAPH_PURPLE}
								className={'mb-4'}
								role={'button'}
								onClick={() => {
									OnDateSelect &&
										OnDateSelect({
											date: displayDate
												? dayjs(displayDate)
														.subtract(1, spanType)
														.startOf(spanType)
														.format('YYYY-MM-DD')
												: dayjs(lastReadingsDates?.[spanType])
														.subtract(1, spanType)
														.startOf(spanType)
														.format('YYYY-MM-DD')
										});
									setDisplayDate(
										displayDate
											? dayjs(displayDate).subtract(1, spanType).format('YYYY-MM-DD')
											: dayjs(lastReadingsDates?.[spanType]).subtract(1, spanType)
									);
								}}
							/>
							<div style={{ width: '90%' }}>
								{chartView === 'table' ? (
									<ChartTable style={{ height: graphHeight }} dataObjects={dataObjects} />
								) : (
									<Chart
										options={getOptions({ yAxis, t: t, seriesData: series, labels, spanType })}
										series={series}
										type="bar"
										height={graphHeight}
									/>
								)}
							</div>
							<AiOutlineRight
								size={'2rem'}
								style={{ strokeWidth: '30px' }}
								color={colors.GRAPH_PURPLE}
								className={'mb-4'}
								role={'button'}
								onClick={() => {
									OnDateSelect &&
										OnDateSelect({
											date: displayDate
												? dayjs(displayDate)
														.add(1, spanType)
														.startOf(spanType)
														.format('YYYY-MM-DD')
												: dayjs(lastReadingsDates?.[spanType])
														.add(1, spanType)
														.startOf(spanType)
														.format('YYYY-MM-DD')
										});
									setDisplayDate(
										displayDate
											? dayjs(displayDate).add(1, spanType).format('YYYY-MM-DD')
											: dayjs(lastReadingsDates?.[spanType]).add(1, spanType)
									);
								}}
							/>
						</div>
					</>
				) : chartView === 'table' ? (
					<ChartTable style={{ height: graphHeight }} dataObjects={dataObjects} />
				) : (
					<Chart
						options={getOptions({ yAxis, t: t, seriesData: series, labels, spanType })}
						series={series}
						type="bar"
						height={graphHeight}
					/>
				)}

				<div className="mt-5">
					{showBrush && brushData?.length ? (
						<>
							<ResponsiveContainer width={i18n.language === 'en' ? '90%' : '93%'} height={100}>
								<ComposedChart
									data={brushData}
									style={i18n.language === 'en' ? { marginLeft: '5.5%' } : { direction: 'ltr' }}
								>
									<Brush
										dataKey={t('time')}
										tickFormatter={time => (time ? dayjs(time).format('DD-MMM') : '')}
										height={90}
										startIndex={brushState?.startIndex}
										endIndex={brushState?.endIndex}
										alwaysShowText
										stroke={colors.GRAPH_PURPLE}
										onChange={debounce((e: any) => {
											if (brushData?.length) {
												const startTime = brushData[e.startIndex].time;
												const endTime = brushData[e.endIndex].time;
												onSpanSelect &&
													onSpanSelect({
														start: dateToQuery(dayjs(startTime)),
														end: dateToQuery(dayjs(endTime))
													});
												setDisplayTimeSpan &&
													setDisplayTimeSpan({
														start: dayjs(startTime).format('YYYY-MM-DD'),

														end: dayjs(endTime).format('YYYY-MM-DD')
													});
												setCustomBrushSpan(true);
												setTimeStep(undefined);
											}
										}, 700)}
									>
										<BarChart>
											<CartesianGrid
												horizontal
												stroke={colors.LIGHT_GREY9}
												strokeWidth={0.5}
												strokeDasharray="3 3"
											/>
											{minMax ? (
												<YAxis hide domain={[minMax.min, minMax.max]} />
											) : (
												<YAxis hide domain={['auto', 'auto']} />
											)}{' '}
											<Bar
												dataKey={cleanDataObjects?.length && cleanDataObjects[0].name}
												strokeWidth={1.2}
												fill={colors.GRAPH_PURPLE}
											/>
										</BarChart>
									</Brush>
								</ComposedChart>
							</ResponsiveContainer>
							<div className="w-100 d-flex justify-content-between ">
								{brushData?.length ? (
									<span style={{ color: colors.GRAPH_PURPLE }} className="mx-3">
										<small>{dayjs(brushData[0].time).format('dddd, MMMM D, YYYY h:mm A')}</small>
									</span>
								) : null}
								{brushData?.length ? (
									<span style={{ color: colors.GRAPH_PURPLE }}>
										<small>
											{dayjs(brushData[brushData.length - 1].time).format(
												'dddd, MMMM D, YYYY h:mm A'
											)}
										</small>
									</span>
								) : null}
							</div>
						</>
					) : null}
				</div>
			</div>
		</GraphCard>
	);
};
