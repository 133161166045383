export enum AlertCardType {
	OPEN = 'open',
	RESOLVED = 'resolved',
	INTERMEDIATE = 'intermediate',
	URGENT = 'urgent'
}

export enum AlertType {
	USER_DEFINED = 'USER_DEFINED',
	DATA_SOURCE_MIN_LIMIT = 'DATA_SOURCE_MIN_LIMIT',
	DATA_SOURCE_MAX_LIMIT = 'DATA_SOURCE_MAX_LIMIT'
}

export enum AlertPriority {
	URGENT = 'URGENT',
	HIGH = 'HIGH',
	INTERMEDIATE = 'INTERMEDIATE',
	NORMAL = 'NORMAL',
	LOW = 'LOW'
}

export enum AlertSource {
	USER_DEFINED = 'USER_DEFINED',
	AI_PRODUCED = 'AI_PRODUCED',
	PRECONFIGURED = 'PRECONFIGURED'
}

export enum AlertStatus {
	OPEN = 'OPEN',
	CANCELLED = 'CANCELLED',
	PENDING = 'PENDING',
	CLOSED = 'CLOSED',
	RESOLVED = 'RESOLVED'
}

export interface IAlertRecord {
	id: string;
	name: string;
	alertId: string;
	source: AlertSource;
	alertPriority: AlertPriority;
	alert_type: AlertType;
	alertStart: Date;
	alertEnd: Date;
	affected_zones: any;
	affected_networks: any;
	status: AlertStatus;
	data_source: any;
	measurementPointId: any;
	dataSourceType: any;
}

export interface IAlertStats {
	open_count: number;
	resolved_count: number;
	urgent_count: number;
	intermediate_count: number;
}
