import { Button, DatePicker, Form } from 'antd';
import dayjs from 'dayjs';
import { HTMLAttributes, useEffect, useState } from 'react';
import { BsCalendar } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { SpanType, SpanTypeMapper, TimeStepMapper } from '../../types';
import { GraphSpan, GraphSpanMapper } from '../../types/graph-span';
import { GraphSpanCustomSelectorView, GraphSpanSelectorContainer } from './styles/graph-span-selector';

export const GraphSpanSelector: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		onSpanChange: (span: GraphSpan) => void;
		selectedTimeUnit?: GraphSpan | null;
		setTimeStep?: any;
		showTimeStep?: boolean;
		setSpanType?: any;
		customGraphSpan?: GraphSpan[];
	}
> = ({ onSpanChange, selectedTimeUnit, setTimeStep, showTimeStep, setSpanType, customGraphSpan, ...restProps }) => {
	const { t, i18n } = useTranslation();

	return (
		<GraphSpanSelectorContainer englishSelected={i18n.language === 'en'} {...restProps}>
			{Object.values(customGraphSpan || GraphSpan).map((span, idx) => (
				<span key={idx}>
					{idx === Object.values(GraphSpan).length - 1 ? <p>|</p> : null}
					<span
						className={span === selectedTimeUnit ? 'selected-span' : ''}
						onClick={() => {
							onSpanChange(span);
							if (showTimeStep) {
								if (span !== GraphSpan.ALL && span !== GraphSpan.RESET) {
									setTimeStep && setTimeStep(TimeStepMapper[span]);
									setSpanType && setSpanType(SpanTypeMapper[span]);
								} else {
									setTimeStep && setTimeStep(undefined);
								}
							}
						}}
					>
						{t(GraphSpanMapper[span])}
					</span>
				</span>
			))}
		</GraphSpanSelectorContainer>
	);
};

export const GraphSpanCustomSelector: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		onCustomSpanChange: (span: any) => void;
		selectedSpan: any;
		setCustomSpan: any;
		setDisplayTimeSpan: any;
		displayTimeSpan: any;
		customSpan?: any;
		spanType?: SpanType;
	}
> = ({
	onCustomSpanChange,
	selectedSpan,
	setCustomSpan,
	setDisplayTimeSpan,
	displayTimeSpan,
	customSpan,
	spanType
}) => {
	const { t, i18n } = useTranslation();
	const [startDatePickerOpen, setStartDatePickerOpen] = useState<boolean>(false);
	const [endDatePickerOpen, setEndDatePickerOpen] = useState<boolean>(false);
	const [form] = Form.useForm();

	const onFinish = values => {
		onCustomSpanChange([values.startDate, values.endDate].map((d: any) => dayjs(d).startOf('D').add(2, 'h')));
	};

	useEffect(() => {
		setDisplayTimeSpan({ start: selectedSpan?.start, end: selectedSpan?.end });
	}, []);
	useEffect(() => {
		form.setFieldsValue({
			startDate: dayjs(displayTimeSpan?.start ? displayTimeSpan?.start : selectedSpan?.start)
		});
		form.setFieldsValue({ endDate: dayjs(displayTimeSpan?.end ? displayTimeSpan?.end : selectedSpan?.end) });
	}, [selectedSpan]);

	return (
		<div className="d-flex justify-content-end mb-3 mt-2 align-items-center">
			<GraphSpanCustomSelectorView englishSelected={i18n.language === 'en'}>
				<>
					<span
						onClick={() => {
							setStartDatePickerOpen(true);
						}}
						className="d-flex align-items-center"
						style={{ fontSize: '0.75rem' }}
					>
						<span
							style={{ fontSize: 'inherit', fontWeight: '400' }}
							className="d-flex align-items-center me-2"
						>
							<BsCalendar size={20} className="mx-1" />
							{t('From')}
						</span>
						{displayTimeSpan?.start
							? spanType
								? dayjs(displayTimeSpan?.start).startOf(spanType).format('MMMM D, YYYY')
								: dayjs(displayTimeSpan?.start).format('MMMM D, YYYY')
							: spanType
							? dayjs(selectedSpan?.start).startOf(spanType).format('MMMM D, YYYY')
							: dayjs(selectedSpan?.start).format('MMMM D, YYYY')}
					</span>
					<span style={{ fontSize: '0.9rem' }}>-</span>
					<span
						onClick={() => {
							setEndDatePickerOpen(true);
						}}
						className="d-flex align-items-center"
						style={{ fontSize: '0.9rem' }}
					>
						<span
							style={{ fontSize: 'inherit', fontWeight: '400' }}
							className="d-flex align-items-center me-2"
						>
							<BsCalendar size={20} className="mx-1" />
							{t('To')}
						</span>
						{displayTimeSpan?.end
							? spanType
								? dayjs(displayTimeSpan?.end).endOf(spanType).format('MMMM D, YYYY')
								: dayjs(displayTimeSpan?.end).format('MMMM D, YYYY')
							: spanType
							? dayjs(selectedSpan?.end).endOf(spanType).format('MMMM D, YYYY')
							: dayjs(selectedSpan?.end).format('MMMM D, YYYY')}
					</span>
				</>
			</GraphSpanCustomSelectorView>

			<Form form={form} onFinish={onFinish}>
				<Form.Item name="startDate" className="m-0 p-0">
					<DatePicker
						style={{ width: 0, opacity: 0, position: 'absolute' }}
						placement={i18n.language === 'en' ? 'bottomRight' : 'bottomLeft'}
						open={startDatePickerOpen}
						onOpenChange={state => {
							setStartDatePickerOpen(state);
						}}
						onChange={(date: any, dateString: any) => {
							if (date) {
								{
									setDisplayTimeSpan(prevState => ({
										...prevState,
										start: spanType
											? dayjs(date).startOf(spanType).format('YYYY-MM-DD')
											: dayjs(date).format('YYYY-MM-DD')
									}));
									setCustomSpan(true);
								}
							}
						}}
						picker={!spanType ? 'date' : spanType === 'day' ? 'date' : spanType}
						disabledDate={current => {
							return (current && current > dayjs().endOf(spanType ?? 'd')) ?? false;
						}}
					/>
				</Form.Item>
				<Form.Item name="endDate" className="m-0 p-0">
					<DatePicker
						placement={i18n.language === 'en' ? 'bottomRight' : 'bottomLeft'}
						style={{ width: 0, opacity: 0, position: 'absolute' }}
						open={endDatePickerOpen}
						onOpenChange={state => {
							setEndDatePickerOpen(state);
						}}
						onChange={(date: any, dateString: any) => {
							if (date) {
								{
									setDisplayTimeSpan(prevState => ({
										...prevState,
										end: spanType
											? dayjs(date).endOf(spanType).format('YYYY-MM-DD')
											: dayjs(date).format('YYYY-MM-DD')
									}));
									setCustomSpan(true);
								}
							}
						}}
						picker={!spanType ? 'date' : spanType === 'day' ? 'date' : spanType}
						disabledDate={current => {
							return (current && current > dayjs().endOf(spanType ?? 'd')) ?? false;
						}}
					/>
				</Form.Item>
			</Form>
			<Button
				className="mx-2 p-1"
				onClick={form.submit}
				disabled={
					!customSpan ||
					(dayjs(selectedSpan?.start).format('YYYY-MM-DD') ===
						dayjs(displayTimeSpan?.start).format('YYYY-MM-DD') &&
						dayjs(selectedSpan?.end).format('YYYY-MM-DD') ===
							dayjs(displayTimeSpan?.end).format('YYYY-MM-DD'))
						? true
						: false
				}
				style={{ fontSize: '0.7rem', height: '1.6rem' }}
				type="primary"
			>
				{/* <IoMdCheckmark size={20} />  */}
				{t('Apply')}
			</Button>
		</div>
	);
};

export const GraphCustomSelectorDate: React.FC<
	HTMLAttributes<HTMLDivElement> & {
		onCustomDateChange: (date: any) => void;
		selectedDate: any;
		setDisplayDate: any;
		displayDate: any;
		spanType?: any;
	}
> = ({ onCustomDateChange, selectedDate, setDisplayDate, displayDate, spanType }) => {
	const { i18n } = useTranslation();
	const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);

	return (
		<div className="d-flex justify-content-end mb-3 mt-2">
			<GraphSpanCustomSelectorView
				englishSelected={i18n.language === 'en'}
				onClick={() => {
					setDatePickerOpen(true);
				}}
				// style={{ position: 'absolute', zIndex: 500 }}
			>
				<BsCalendar size={20} />
				<span style={{ fontSize: '1.1rem' }}>
					{spanType
						? spanType === SpanType.YEAR
							? displayDate
								? dayjs(displayDate).format('YYYY')
								: dayjs(selectedDate[spanType]).format('YYYY')
							: spanType === SpanType.MONTH
							? displayDate
								? dayjs(displayDate).format('MMMM YYYY')
								: dayjs(selectedDate[spanType]).format('MMMM YYYY')
							: spanType === SpanType.WEEK
							? displayDate
								? `${dayjs(displayDate).startOf(SpanType.WEEK).format('DD MMM')}:${dayjs(displayDate)
										.endOf(SpanType.WEEK)
										.format('DD MMM YYYY')}`
								: `${dayjs(selectedDate[spanType]).startOf(SpanType.WEEK).format('DD MMM')}:${dayjs(
										selectedDate[spanType]
								  )
										.endOf(SpanType.WEEK)
										.format('DD MMMM YYYY')}`
							: spanType === SpanType.DAY &&
							  (displayDate
									? dayjs(displayDate).format('MMMM D, YYYY')
									: selectedDate && typeof selectedDate === 'object'
									? dayjs(selectedDate[spanType]).format('MMMM D, YYYY')
									: dayjs(selectedDate).format('MMMM D, YYYY'))
						: displayDate
						? dayjs(displayDate).format('MMMM D, YYYY')
						: dayjs(selectedDate).format('MMMM D, YYYY')}
				</span>
			</GraphSpanCustomSelectorView>
			<DatePicker
				onOpenChange={state => {
					setDatePickerOpen(state);
				}}
				placement={i18n.language === 'en' ? 'bottomRight' : 'bottomLeft'}
				open={datePickerOpen}
				value={
					spanType
						? displayDate
							? dayjs(displayDate).startOf(spanType)
							: selectedDate && typeof selectedDate === 'object'
							? dayjs(selectedDate[spanType]).startOf(spanType)
							: dayjs(selectedDate)
						: displayDate
						? dayjs(displayDate)
						: dayjs(selectedDate)
				}
				onChange={(date: any, dateString: any) => {
					if (date) {
						if (spanType) {
							setDisplayDate(dayjs(date).startOf(spanType).format('YYYY-MM-DD'));
							onCustomDateChange(dayjs(date).startOf(spanType).format('YYYY-MM-DD'));
						} else {
							setDisplayDate(dayjs(date).format('YYYY-MM-DD'));
							onCustomDateChange(dayjs(date).format('YYYY-MM-DD'));
						}
					}
				}}
				style={{ width: 0, opacity: 0, position: 'absolute' }}
				picker={spanType === 'day' ? 'date' : spanType}
				disabledDate={current => {
					return (current && current > dayjs().endOf(spanType)) ?? false;
				}}
			/>
		</div>
	);
};
