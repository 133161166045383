import { useQuery } from '@tanstack/react-query';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { CollapsibleLoggers } from '../../containers';
import { useTranslation } from 'react-i18next';
import monitoringService from '../../services/monitoring.service';
import { upperSnakeToCapitalized } from '../../utils/upper-snake-to-capitalized';
import { DescriptionCard } from '../description-card';

export const MeasurementPointSpecs: React.FC<HTMLAttributes<HTMLDivElement> & { point: any }> = ({ point }) => {
	const { t } = useTranslation();
	const [items, setItems] = useState<{ label: string; value: string }[]>([{ label: '', value: '' }]);

	const { data: pointLoggers } = useQuery<any>({
		queryKey: ['devices', point.id],
		queryFn: () =>
			monitoringService.getDevices({
				filters: { measurement_point: point.id }
			}),
		enabled: !!point?.id
	});

	useEffect(() => {
		if (point) {
			const info = [
				{
					label: t('HydraulicPlacement'),
					value: upperSnakeToCapitalized(point.hydraulic_placement)
				},
				{
					label: t('Status', { ns: 'common' }),
					value: point.is_active ? t('Active') : t('Inactive')
				}
			];

			if (point.valve) {
				if (point.valve.manufacturer)
					info.push({
						label: t('Manufacturer'),
						value: upperSnakeToCapitalized(point.valve.manufacturer)
					});
				if (point.valve.model_number)
					info.push({
						label: t('modelNumber'),
						value: upperSnakeToCapitalized(point.valve.model_number)
					});
				if (point.valve.serial_number)
					info.push({
						label: t('serialNumber'),
						value: upperSnakeToCapitalized(point.valve.serial_number)
					});

				if (point.valve.downstream_pressure)
					info.push({
						label: t('downstreamPressure'),
						value: upperSnakeToCapitalized(point.valve.downstream_pressure)
					});
				// if (point.valve.max_upstream_pressure)
				// 	info.push({
				// 		label: 'Max Upstream Pressure',
				// 		value: upperSnakeToCapitalized(point.valve.max_upstream_pressure)
				// 	});
				// if (point.valve.head_loss)
				// 	info.push({
				// 		label: 'Head Loss',
				// 		value: upperSnakeToCapitalized(point.valve.head_loss)
				// 	});
				// if (point.valve.maintenance_reminder_status)
				// 	info.push({
				// 		label: 'Maintenance Reminder Status',
				// 		value: upperSnakeToCapitalized(point.valve.maintenance_reminder_status)
				// 	});
			}

			setItems(info);
		}
	}, [point]);

	return (
		<DescriptionCard className="mb-3" title={t('Information')} items={items}>
			{pointLoggers && pointLoggers.length ? (
				<>
					<h5 className="mt-3">{t('Loggers')}</h5>
					<CollapsibleLoggers loggers={pointLoggers} />
				</>
			) : null}
		</DescriptionCard>
	);
};
